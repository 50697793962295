import { useTypedSelector } from 'app/redux/lib/selector'
import React from 'react'
import styled from 'styled-components/macro'
import { AnnotationType } from 'types/IAnnotations'

const StyledAnnotationListItem = styled.div<{ active?: boolean; hoverColor?: string; isHover?: boolean }>`
  color: var(--color-text-3);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 4px;
  background: ${({ active, hoverColor, isHover }) => (active || isHover ? hoverColor : 'transparent')};
  border-radius: 5px;
  padding: 8px;
  height: 100%;

  &:hover {
    background: ${({ hoverColor }) => hoverColor};
  }
  user-select: none;
`
const mitosisRGB = '11, 67, 209'

type HoverWrapperProps = {
  /** onSelect - колбэк, вызывающийся при клике на аннотацию в списке */
  onSelect: React.MouseEventHandler<HTMLDivElement>
  /** isActive - доступна ли аннотация для взаимодействия */
  isActive?: boolean
  /** id - id аннотации */
  id: number
  /** type - тип аннотации */
  type: AnnotationType
  /** hoverColor - кастомный цвет аннотации */
  hoverColor?: string
}
export const HoverWrapper: React.FC<HoverWrapperProps> = ({ children, hoverColor, id, isActive, onSelect, type }) => {
  const hoveredAnnotationId = useTypedSelector((state) => state.annotations.hoveredAnnotationId)
  return (
    <StyledAnnotationListItem
      onClick={onSelect}
      isHover={id === hoveredAnnotationId}
      hoverColor={`rgba(${type === AnnotationType.MITOSIS ? mitosisRGB : hoverColor || '114, 133, 233'}, 0.25)`}
      active={isActive}
    >
      {children}
    </StyledAnnotationListItem>
  )
}
