import { DENOMINATOR, ELLIPSIS, SYMBOLS_BEFORE_EXT } from './constans'

interface IGetTextWidth {
  (text: string, font: string): number
  canvas?: HTMLCanvasElement
}

export const truncateStringWithEllipsis = (slideName: string | undefined, charCount: number) => {
  if (!slideName || slideName.length <= charCount) {
    return slideName
  }

  const halfCount = Math.ceil(charCount / DENOMINATOR)
  const startPart = slideName.slice(0, halfCount)
  const endPart = slideName.slice(-halfCount)

  return `${startPart}${ELLIPSIS}${endPart}`
}

/**
 * Получаем ширину строки в пикселях используя font
 *
 * @param {string} text - строка.
 * @param {string} font - font.
 * @returns {number} Ширина строки в пикселях.
 * @throws {Error} Обработка ошибки.
 */
export const getTextWidth: IGetTextWidth = (text: string, font: string): number => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  } else {
    throw new Error('Canvas context is not supported.')
  }
}

/**
 * Получаем computedStyle элемента
 *
 * @param {HTMLElement} element - HTML элемент.
 * @param {string} prop - CSS пропертя .
 * @returns {string} computedStyle пропа.
 */
export const getCssStyle = (element: HTMLElement, prop: string): string =>
  window.getComputedStyle(element, null).getPropertyValue(prop)

/**
 * Получение font стилей из элемента.
 *
 * @param {HTMLElement} el - HTML элемент.
 * @returns {string} Фонт стили в формате "font-weight font-size font-family".
 */
export const getCanvasFont = (el: HTMLElement = document.body): string => {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal'
  const fontSize = getCssStyle(el, 'font-size') || '16px'
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman'

  return `${fontWeight} ${fontSize} ${fontFamily}`
}

/**
 * Получение ширины скроллбара
 *
 * @returns {number} Возвращает ширину скроллбара
 */
export function getScrollbarWidth() {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  outer.style.msOverflowStyle = 'scrollbar'
  document.body.appendChild(outer)

  const inner = document.createElement('div')
  outer.appendChild(inner)

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  if (outer.parentNode !== null) outer.parentNode.removeChild(outer)

  return scrollbarWidth
}

/**
 * Функция для определения максимального количества видимых символов текста в заданном пространстве.
 *
 * @param {string} text - Входной текст.
 * @param {number} freeSpace - Доступное пространство для отображения текста.
 * @param {string} font - Шрифт текста.
 * @returns {number} - Максимальное количество видимых символов в заданном пространстве.
 */
export function visibleSymbolsCount(text: string, freeSpace: number, font: string): number {
  let maxVisibleLength = 0
  let maxVisibleLengthWidth = 0

  for (let i = 0; i <= text.length; i++) {
    const symbolWidth = getTextWidth(text[i], font)

    if (maxVisibleLengthWidth + symbolWidth < freeSpace) {
      maxVisibleLengthWidth += symbolWidth
      maxVisibleLength++
    } else {
      break
    }
  }

  return maxVisibleLength
}

/**
 * Функция для обрезания строки
 *
 * @param {string[]} inputString - Входной массив с селекторами элементов.
 * @param {boolean} hasScrollbar - Видимость скроллбара
 * @param {HTMLElement} element - элемент, в котором находится строка
 * @param {number} otherElementsWidth - сумма ширин остальных элементов на уровне с текстом
 * @param {number} symbolsBeforeExt - количество символов до расширения или конца строки
 * @param {number} rightOffset - Отступ справа
 * @returns {string} - Обрезанная строка
 */
export const customTruncateStringWithEllipsis = (
  inputString: string,
  hasScrollbar: boolean,
  element: HTMLElement,
  otherElementsWidth: number,
  rightOffset: number,
  symbolsBeforeExt: number = SYMBOLS_BEFORE_EXT,
): string => {
  /** Селектор контейнера с названием слайда */
  const slideNameContainer = document.getElementById('slidename') || document.body
  /** Шрифт в правой панели */
  const rightPanelFont = getCanvasFont(slideNameContainer)
  /** Ширина скроллбара */
  const scrollBarWidth = hasScrollbar ? getScrollbarWidth() : 0
  /** Количество пикселей для многоточия */
  const ellipsisPixels = getTextWidth(ELLIPSIS, rightPanelFont)
  // Индекс последней точки (расширения)
  let extensionIndex = inputString.lastIndexOf('.')

  // Если нет расширения, берем длину строки
  if (extensionIndex === -1) {
    extensionIndex = inputString.length
  }

  /** Видимая часть с расширением */
  const extensionPart = inputString.substring(extensionIndex - symbolsBeforeExt)
  /** Ширина части с расширением*/
  const extensionPartWidth = getTextWidth(extensionPart, rightPanelFont)
  /** Доступное количество свободного места в пикселях */
  const freeSpaceForSymbols =
    element.clientWidth - otherElementsWidth - ellipsisPixels - scrollBarWidth - extensionPartWidth - rightOffset
  // Максимальная видимая длина
  const maxVisibleLength =
    visibleSymbolsCount(inputString, freeSpaceForSymbols, rightPanelFont) + ELLIPSIS.length + extensionPart.length

  // Если расширение слишком близко к началу строки, не обрезаем
  if (extensionIndex < maxVisibleLength - ELLIPSIS.length) {
    return inputString
  }

  /** Видимая начальная часть текста */
  const visiblePart = inputString.substring(0, maxVisibleLength - ELLIPSIS.length - extensionPart.length - 1)

  return `${visiblePart}${ELLIPSIS}${extensionPart}`
}

/**
 * Функция для сложения высот элементов
 *
 * @param {string[]} elementsSelectors - Входной массив с селекторами элементов.
 * @returns {number} - сумма высот элементов
 */
export function reduceElementsHeight<T extends HTMLElement = HTMLElement>(elementsSelectors: string[]): number {
  return elementsSelectors.reduce(
    (acc, el) => ((document.querySelector(el) as T) || document.body).clientHeight + acc,
    0,
  )
}
