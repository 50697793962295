import { Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ELLIPSIS } from 'shared/text-constants'
import { SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledSpin = styled(SpinElement)`
  background-color: var(--color-bg-2);
  height: 100%;
  width: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.ant-spin-spinning {
    height: 100%;
    width: 100%;
  }
`

const SpinWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  & .ant-spin-nested-loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-spin-blur {
    height: 100%;
  }
  .ant-spin-container {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
  }
`

const ThumbnailSpin = () => {
  const { t } = useTranslation()
  return (
    <Tooltip title={`${t('Обработка')}${ELLIPSIS}`}>
      <StyledSpin />
    </Tooltip>
  )
}

type Props = {
  spinning: boolean
}

export const ThumbnailRemoveSpin: FC<Props> = ({ children, spinning }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={spinning && `${t('Удаление')}${ELLIPSIS}`}>
      <SpinWrapper>
        <SpinElement spinning={spinning}>{children}</SpinElement>
      </SpinWrapper>
    </Tooltip>
  )
}

export default ThumbnailSpin
