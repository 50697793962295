import { useTypedSelector } from 'app/redux/lib/selector'
import slideService from 'entities/slide/api/service'
import { notices } from 'features/notices'
import { tusFilesSlice } from 'processes/tus/model/tusFilesSlice'
import { createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { ISlideGrouped } from 'types/ISlideGrouped'
import { v4 as uuidv4 } from 'uuid'

import { getFileMetaHash, uploadToServer } from './tusHelper'

type FileData = { file: File; caseId: number; fileId: string; ignoreDuplicates?: boolean }
type FileMap = Record<string, FileData | undefined>

type TContext = {
  pushFileToQueue: (file: FileData) => void
  groupId: string
  loading: boolean
  openPanel: boolean
  setOpenPanel: Dispatch<SetStateAction<boolean>>
}

export const TusContext = createContext<TContext>({
  groupId: '',
  loading: false,
  openPanel: false,
  pushFileToQueue: () => {},
  setOpenPanel: () => {},
})

export const TusFileProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [openPanel, setOpenPanel] = useState(false)
  const dispatch = useDispatch()
  const fileMap = useRef<FileMap>({})
  const queryClient = useQueryClient()
  const queue = useTypedSelector((state) => state.tusFiles.queue)
  const { t } = useTranslation()
  const groupId = useTusGroupId()

  const setFile = (uuid: string, data?: FileData) => {
    fileMap.current[uuid] = data
  }
  const pushFileToQueue = (data: FileData) => {
    const uuid = data?.fileId
    fileMap.current[uuid] = data
    if (data) {
      setOpenPanel(true)
      const { caseId, file } = data
      dispatch(tusFilesSlice.actions.pushFileToQueue(uuid))
      dispatch(
        tusFilesSlice.actions.addUploadingFile({
          caseId,
          name: file.name,
          percent: 0,
          uuid,
        }),
      )
    }
  }

  const processFile = useCallback(async (data: FileData, uuid: string, groupId: string) => {
    const { caseId, file, ignoreDuplicates } = data
    const caseRecord = queryClient.getQueryData<ISlideGrouped[]>([QUERY_TYPE.SLIDE_GROUPED, caseId])
    const slides = caseRecord?.flatMap((item) => item.slides)
    if (slides && slides.length >= 20) {
      notices.error({
        message: t('Превышено максимальное число слайдов на случай'),
      })
      dispatch(tusFilesSlice.actions.removeUploadingFile({ caseId, uuid }))
      dispatch(tusFilesSlice.actions.shiftFileFromQueue())
      setLoading(false)
      setOpenPanel(false)
      return
    }
    if (file) {
      try {
        const metaHash = getFileMetaHash(file)
        if (!ignoreDuplicates) {
          const duplicates = await slideService.findAllByMetaHash(caseId, [metaHash])
          const dSlide = duplicates[metaHash]
          if (dSlide) {
            notices.openOnSlideDuplicateNotification({
              caption: dSlide.slideMetadata?.commonMetadata?.caption,
              id: dSlide.slideId,
              key: metaHash,
              onRetry: () => {
                pushFileToQueue({
                  ...data,
                  ignoreDuplicates: true,
                })
                notices.close(metaHash)
              },
              src: dSlide.thumbnails.small,
            })
            dispatch(tusFilesSlice.actions.removeUploadingFile({ caseId, uuid }))
            dispatch(tusFilesSlice.actions.shiftFileFromQueue())
            setLoading(false)
            setOpenPanel(false)
            return
          }
        }
        const uploadInfo = await slideService.getDataForUpload(caseId)
        uploadInfo.meta.metahash = metaHash

        if (uploadInfo) {
          uploadToServer(file, uploadInfo, uuid, groupId, {
            onError: (uuid, error) => {
              notices.error({
                message: t('Не удалось загрузить файл'),
              })
              dispatch(tusFilesSlice.actions.removeUploadingFile({ caseId, uuid }))
              dispatch(tusFilesSlice.actions.shiftFileFromQueue())
              setLoading(false)
              setOpenPanel(false)
            },
            onProgress: (uuid, percent) => {
              dispatch(
                tusFilesSlice.actions.updateUploadingFile({
                  caseId,
                  name: file.name,
                  percent,
                  uuid,
                }),
              )
            },
            onStart: (uuid) => {
              console.log('start', uuid)
            },
            onSuccess: (uuid) => {
              dispatch(tusFilesSlice.actions.removeUploadingFile({ caseId, uuid }))
              setFile(uuid)
              dispatch(tusFilesSlice.actions.shiftFileFromQueue())
              setLoading(false)
            },
          })
        }
      } catch (e: any) {
        if (e?.response?.status === 418) {
          notices.error({
            message: t('Превышено максимальное количество слайдов в рамках лицензии'),
          })
        } else {
          notices.error({
            message: t('Превышено максимальное число слайдов на случай'),
          })
        }
        dispatch(tusFilesSlice.actions.removeUploadingFile({ caseId, uuid }))
        dispatch(tusFilesSlice.actions.shiftFileFromQueue())
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (queue.length && !loading) {
      const fileId = queue[0]
      const fileData = fileMap.current[fileId]

      if (fileData) {
        setLoading(true)
        processFile(fileData, fileId, groupId)
      }
    }
  }, [queue, loading])

  return (
    <TusContext.Provider
      value={{
        groupId,
        loading,
        openPanel,
        pushFileToQueue,
        setOpenPanel,
      }}
    >
      {children}
    </TusContext.Provider>
  )
}

const LOCAL_STORAGE_KEY = 'tus-group-id'
const useTusGroupId = () => {
  const token = useTypedSelector((state) => state.user.token)
  const groupId = localStorage.getItem(LOCAL_STORAGE_KEY) || uuidv4()
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, groupId)
  }, [groupId])

  useEffect(() => {
    if (!token) {
      localStorage.removeItem(LOCAL_STORAGE_KEY)
    }
  }, [token])
  return groupId
}
