import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'shared/lib/hooks'
import { TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import VIEWER_COLORS from 'viewer/container/ui/VIEWER_COLORS'

const ThumbnailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledThumbnail = styled.div<{ activeCursor?: TViewerId; selectedByViewer?: TViewerId }>`
  background-color: ${({ selectedByViewer }) => selectedByViewer && VIEWER_COLORS[selectedByViewer]};
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 1px;
  margin-bottom: 8px;
  box-shadow: ${({ selectedByViewer }) =>
    selectedByViewer ? `0 0 0 4px ${VIEWER_COLORS[selectedByViewer]}` : '0 0 0 2px var(--color-border-1)'};
  &:hover {
    box-shadow: ${({ selectedByViewer }) => !selectedByViewer && '0 0 0 2px var(--color-purple)'};
  }
`

const AdaptiveImg = styled.div<{ type?: string; isShowLabel?: boolean }>`
  position: relative;
  min-height: 80px;

  &:after {
    min-height: 80px;
    content: '';
    display: block;
    padding-bottom: ${({ isShowLabel, type }) => (type !== 'MACRO' && isShowLabel ? '50%' : '100%')};
    position: relative;
  }
`
const InnerImg = styled.div<{ type?: string }>`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1.5px;
  background-color: var(--color-white);
`

type Props = {
  /** данные слайда */
  slide?: ISlide
  /** идентификатор слайда */
  slideId?: number
  /** контент подвала */
  footer?: ReactNode
  /** основной контент слайда */
  content?: ReactNode
  /** идентификатор активного просматривающего */
  activeCursor: TViewerId
  /** флаг отслеживающий видимость этикетки и превью */
  shouldDisplayLabelAndPreview?: boolean
  /** идентификатор просматривающего, выбравшего слайд */
  selectedByViewer?: TViewerId
  /** Обработчик события клика для слайда */
  onClick?: React.MouseEventHandler<HTMLDivElement>
  /** Обработчик открытия контекстного меню */
  handleContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const AdaptiveThumbnail: FC<Props> = ({
  activeCursor,
  children,
  content,
  footer,
  handleContextMenu,
  onClick,
  selectedByViewer,
  shouldDisplayLabelAndPreview,
  slide,
}) => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const slideGroupType = slide?.groupType
  const scrollRef = useRef<HTMLDivElement>(null)
  const slideIdFromUrl = Number(queryParams.get('slideId'))

  useEffect(() => {
    if (slideIdFromUrl === slide?.slideId) {
      setTimeout(() => {
        scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 0)
    }
  }, [slideIdFromUrl])

  return (
    <TooltipElement placement={'right'} title={slide?.thumbnails.medium === 'FAILED_SLIDE' && t('Слайд не загружен')}>
      <StyledThumbnail
        activeCursor={activeCursor}
        selectedByViewer={selectedByViewer}
        onClick={(e) => slide?.thumbnails.medium !== 'FAILED_SLIDE' && onClick && onClick(e)}
        ref={scrollRef}
        onContextMenu={handleContextMenu}
      >
        <ThumbnailContent>
          <InnerContent>
            <AdaptiveImg
              data-testid={'preview-left-panel'}
              isShowLabel={shouldDisplayLabelAndPreview}
              type={slideGroupType}
            >
              {content}
              <InnerImg type={slideGroupType}>{children}</InnerImg>
            </AdaptiveImg>
            {footer}
          </InnerContent>
        </ThumbnailContent>
      </StyledThumbnail>
    </TooltipElement>
  )
}

export default AdaptiveThumbnail
