import { useTypedSelector } from 'app/redux/lib/selector'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import React, { useEffect, useRef } from 'react'
import { IMapOl } from 'types/IMapOl'
import ISlide from 'types/ISlide'
import { mitosisPointStyle, objectsPointStyle } from 'viewer/map/layers/olStyles'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'

type Props = {
  map: IMapOl
  features: Feature<Point>[]
  slide?: ISlide
}

const VectorPointsLayer: React.FC<Props> = ({ features, map, slide }) => {
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType)

  const layer = useRef<VectorLayer<any>>(
    new VectorLayer({
      source: new VectorSource({}),
      style: countingObjectType === 'MITOSIS' ? mitosisPointStyle : objectsPointStyle,
      zIndex: 10000,
    }),
  )

  useEffect(() => {
    layer.current.set(INTERNAL_TYPE_ID, LayerType.MITOSIS_POINTS)
    map.addLayer(layer.current)
    return () => {
      map.removeLayer(layer.current)
    }
  }, [])

  useEffect(() => {
    layer.current.getSource().clear()
    layer.current.getSource().addFeatures(features)
  }, [features])
  return null
}

export default VectorPointsLayer
