import { Feature } from 'ol'
import { MultiPolygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import Hover from 'ol-ext/interaction/Hover'
import Tooltip from 'ol-ext/overlay/Tooltip'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ARTEFACT_CLASS_COLOR, ARTEFACT_CLASS_NAME, ArtefactClasses } from 'types/IArtefact'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'

import { useArtefactsContext } from './ArtefactsContext'

const HUNDRED = 100

/** Props for ArtefactsLayer */
type Props = {
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
}

const ArtefactsLayer: React.FC<Props> = ({ viewerId }) => {
  const map = useViewerIdMap(viewerId)
  const { features, hoveredClass, opacity, setHoveredClass } = useArtefactsContext()
  const { t } = useTranslation()

  const tooltip = useRef<Tooltip>(
    // @ts-ignore
    new Tooltip({
      getHTML: (f: Feature<MultiPolygon>) =>
        `<span>${t(ARTEFACT_CLASS_NAME[f?.get('class') as ArtefactClasses])}</span>`,
      maximumFractionDigits: 2,
      offsetBox: 20,
      popupClass: 'custom-ol-draw-tooltip',
      positionning: 'auto',
    }),
  )

  const onHoverEnter = (evt: any) => {
    tooltip.current.setFeature(evt.feature)
    map.addOverlay(tooltip.current)
    setHoveredClass(evt.feature.get('class'))
  }

  const onHoverLeave = () => {
    tooltip.current.setFeature(null)
    map.removeOverlay(tooltip.current as any)
    setHoveredClass()
  }

  const fillLayer = useRef(
    new VectorLayer({
      opacity: opacity / HUNDRED,
      source: new VectorSource({ features }),
      style: (f) =>
        new Style({
          fill: new Fill({
            color: ARTEFACT_CLASS_COLOR[f.get('class') as ArtefactClasses],
          }),
        }),
      visible: true,
      zIndex: 1,
    }),
  )

  const strokeLayer = useRef(
    new VectorLayer({
      source: new VectorSource({ features }),
      style: (f) =>
        new Style({
          stroke: new Stroke({
            color: ARTEFACT_CLASS_COLOR[f.get('class') as ArtefactClasses],
            width: 2,
          }),
        }),
      visible: true,
      zIndex: 1,
    }),
  )

  const hover = useRef(
    // @ts-ignore
    new Hover({
      cursor: 'pointer',
      hitTolerance: 10,
      layerFilter: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.ARTEFACTS,
    }),
  )

  useEffect(() => {
    fillLayer.current.setOpacity(opacity / HUNDRED)
  }, [opacity])

  useEffect(() => {
    strokeLayer.current.setStyle(
      (f) =>
        new Style({
          stroke:
            hoveredClass === f.get('class')
              ? new Stroke({
                  color: ARTEFACT_CLASS_COLOR[f.get('class') as ArtefactClasses],
                  width: 2,
                })
              : undefined,
        }),
    )
  }, [hoveredClass])

  useEffect(() => {
    fillLayer.current.set(INTERNAL_TYPE_ID, LayerType.ARTEFACTS)
    map?.addLayer(fillLayer.current)
    map?.addLayer(strokeLayer.current)
    return () => {
      map?.removeLayer(fillLayer.current)
      map?.removeLayer(strokeLayer.current)
    }
  }, [])

  useDeepCompareEffect(() => {
    fillLayer.current.getSource().clear()
    fillLayer.current.getSource().addFeatures(features)
    strokeLayer.current.getSource().clear()
    strokeLayer.current.getSource().addFeatures(features)
    // @ts-ignore
    hover.current.on('enter', onHoverEnter)
    // @ts-ignore
    hover.current.on('leave', onHoverLeave)
    map.addInteraction(hover.current)

    return () => {
      if (hover?.current.getMap().getTargetElement()) {
        // @ts-ignore
        hover?.current?.setMap(null)
        map.removeInteraction(hover.current)
      }
    }
  }, [features])

  return null
}

export default ArtefactsLayer
