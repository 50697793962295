import { useTypedSelector } from 'app/redux/lib/selector'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButtonElement, IconElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { useAnnotationAuthorValidation } from 'viewer/map/layers/objects-counting/lib/hooks/useAnnotationAuthorValidation'

import { countingTypesText } from './countingTypes'

type Props = {
  preview: string
  isHidden: boolean
  onRemove: () => void
  onGoToMitos: () => void
}

const Thumbnail = styled.div`
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 3px;
  overflow: hidden;

  & > img {
    width: 72px;
    height: 72px;
    object-fit: cover;
  }
`

const Actions = styled.div<{ isHidden: boolean }>`
  position: absolute;
  padding: 2px;
  top: 0px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FloatingButton = styled(IconButtonElement)`
  &.ant-btn-icon-only {
    padding: 4px;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    background: var(--color-bg-1);
  }
`

const Blind = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
`

const ObjectsItem: React.FC<Props> = ({ isHidden, onGoToMitos, onRemove, preview, ...props }) => {
  const isAuthorValid = useAnnotationAuthorValidation()
  const [hover, setHover] = useState<boolean>(false)
  const onMouseOver = () => setHover(true)
  const onMouseLeave = () => setHover(false)
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType) || 'OBJECTS'
  const { t } = useTranslation()
  return (
    <Thumbnail onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <img src={preview} />
      {isHidden && <Blind />}
      {hover && (
        <Actions isHidden={isHidden}>
          {isAuthorValid && (
            <TooltipElement title={t('Удалить')} mouseEnterDelay={2}>
              <FloatingButton
                onClick={onRemove}
                shape="circle"
                icon={<IconElement name={isHidden ? 'done' : 'cross'} />}
              />
            </TooltipElement>
          )}
          <TooltipElement title={t(countingTypesText[countingObjectType].goToText)} mouseEnterDelay={2}>
            <FloatingButton onClick={onGoToMitos} shape="circle" icon={<IconElement name="goTo" />} />
          </TooltipElement>
        </Actions>
      )}
    </Thumbnail>
  )
}

export default ObjectsItem
