import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/redux'
import { workspacesSlice } from 'features/workspace/model/workspacesSlice'
import { changeLanguage } from 'i18next'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import userService from 'processes/user/api/service'
import { getUserDataFromStorage, removeUserDataFromStorage, setUserDataToStorage } from 'shared/lib/local-storage'
import IUser from 'types/IUser'
import IUserData from 'types/IUserData'
import { IWorkspace } from 'types/IWorkspaces'

type LoginState = IUserData

const initialState: Partial<IUserData> = getUserDataFromStorage()

// TODO: think about better auth
export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    setUserTokens(state, action: PayloadAction<LoginState | Record<string, never>>) {
      return action.payload
    },
  },
})

const { setUserTokens } = userSlice.actions

export const fetchUserData =
  (data: Partial<IUser>): AppThunk =>
  async (dispatch) => {
    try {
      const userData = await userService.fetchLogin(data)
      if (userData) {
        changeLanguage(userData.user?.preferredLanguage)
        setUserDataToStorage(userData)
        dispatch(setUserTokens(userData))
      }
    } catch (e) {
      throw e
    }
  }
export const confirmRegistration =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      const userData = await userService.confirmRegistration(token)
      if (userData) {
        setUserDataToStorage(userData)
        dispatch(setUserTokens(userData))
      }
    } catch (e) {
      console.log(e)
      throw e
    }
  }

export const refreshToken = (): AppThunk => async (dispatch) => {
  try {
    const userData = await userService.refreshToken()
    if (userData) {
      setUserDataToStorage(userData)
      dispatch(setUserTokens(userData))
    }
  } catch (error) {
    dispatch(logout())
  }
}

export const logout = (): AppThunk => async (dispatch) => {
  userService.logout()
  removeUserDataFromStorage()
  dispatch(setUserTokens({}))
  dispatch(viewerPageSlice.actions.resetAllViewersToInitialState())
  dispatch(workspacesSlice.actions.setCurrentWorkspace(undefined))
}

export const workspaceReLogin =
  (workspace: IWorkspace): AppThunk =>
  async (dispatch) => {
    dispatch(workspacesSlice.actions.setCurrentWorkspace(workspace))
    localStorage.setItem('workspaceId', workspace.workspaceId)
  }

export default userSlice
