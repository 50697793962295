import { TMode, useThemeContext } from 'app/styled/ThemeProvider'
import styled from 'styled-components'

type Props = {
  key1?: string
  key2?: string
  key3?: string
  text1?: string
  text2?: string
}

const HintWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`
const Key = styled.div<{ theme: TMode }>`
  min-width: 24px;
  height: 24px;
  background: ${({ theme }) =>
    theme === 'dark'
      ? 'linear-gradient(180deg,rgb(0 0 0 / 0%) 0%,rgb(255 255 255 / 12%) 100%)'
      : 'linear-gradient(180deg,rgb(0 0 0 / 8%) 0%,rgb(255 255 255 / 0%) 100%)'};
  box-shadow: ${({ theme }) =>
    theme === 'dark'
      ? '0px 1px 1px 1px rgb(0 0 0 / 76%), inset 0px 1px 0px rgb(255 255 255 / 16%)'
      : '0px 1px 1px 1px rgb(0 0 0 / 32%), inset 0px 1px 0px rgb(255, 255, 255)'};
  color: var(--color-text-1);
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 5px 6px 3px;
  font-feature-settings: 'cv05' on;
`
const Text = styled.div`
  color: var(--color-text-1);
`

export const KeyHint = ({ key1, key2, key3, text1, text2 }: Props) => {
  const colorTheme = useThemeContext()
  return (
    <HintWrapper>
      {key1 && <Key theme={colorTheme.theme}>{key1}</Key>}
      {text1 && <Text>{text1}</Text>}
      {key2 && <Key theme={colorTheme.theme}>{key2}</Key>}
      {text2 && <Text>{text2}</Text>}
      {key3 && <Key theme={colorTheme.theme}>{key3}</Key>}
    </HintWrapper>
  )
}
