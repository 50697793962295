import { useTypedSelector } from 'app/redux/lib/selector'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { ACTION_SHOW_LABELS, ACTION_SHOW_NAME_SLIDE, ACTION_SHOW_PREVIEW } from 'features/thumbnails/common/utils'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { CONFIG_CASE_PANELS_KEY } from 'shared/lib/common/storageKeys'
import { isGuestHandler } from 'shared/lib/local-storage'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { useViewerDispatch } from 'viewer/container'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

import { updateConfigLocalStorage } from './helpers'

/** Объект, представляющий набор действий, доступных для настройки конфигурации. */
type ConfigActions = {
  [key: string]: () => void
}

/**
 * Объект, представляющий значения конфигурации.
 * Ключи - строки, представляющие различные действия.
 * Значения - логические значения, представляющие текущее состояние конфигурации для соответствующего действия.
 */
export type ConfigValues = {
  [ACTION_SHOW_LABELS]: boolean
  [ACTION_SHOW_NAME_SLIDE]: boolean
  [ACTION_SHOW_PREVIEW]: boolean
  [key: string]: boolean
}

type OptionType = {
  /** Строковое значение, представляющее действие. */
  action: string
  /** Строковое значение, представляющее отображаемый текст настройки */
  value: string
}

const ContextMenuItem = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 4px 8px;
  margin-bottom: 4px;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 8px;
  }
  &:hover {
    border-radius: inherit;
    background-color: var(--color-bg-3);
    color: var(--color-text-1);
  }
`

const ContextMenuWrapper = styled.div`
  padding: 4px 0;
  width: 183px;
  ${() => modalBackground}
  ${() => modalShadow}
  border-radius: 5px;
  margin-top: 5px;
`

const StyledIcon = styled(IconElement)<{ checked: boolean; lastTrueKey?: string }>`
  margin: 0 !important;
  color: var(--color-text-3);
  & > path {
    fill: none !important;
    stroke: ${({ checked }) => (checked ? 'var(--color-text-1)' : 'none')};
    opacity: ${({ lastTrueKey }) => (lastTrueKey ? 0.2 : undefined)};
  }
`

type Props = {
  /** Текущее значение настройки */
  currentValue: boolean
  /** Список тип настройки */
  option: OptionType
  /** Обработчик события клика на настройке */
  onClick: (action: string) => void
  /** название последней оставшейся ключа, который checked  */
  lastTrueKey?: string
}

const CheckboxList = ({ currentValue, lastTrueKey, onClick, option }: Props) => (
  <ContextMenuItem onClick={() => onClick(option.action)}>
    {option.value}
    <StyledIcon lastTrueKey={lastTrueKey} checked={currentValue} size={'md'} name={'checkSmall'} />
  </ContextMenuItem>
)

type ISetupMenu = {
  /** Ссылка на кнопку меню. */
  menuButtonRef: RefObject<HTMLDivElement>
  /** Функция для установки состояния открытия меню. */
  toggleOpen: () => void
}

export const SetupMenu = ({ menuButtonRef, toggleOpen }: ISetupMenu) => {
  const queryClient = useQueryClient()
  const { activeViewerId } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(activeViewerId)
  const { caseId } = useViewerIdSlideState(activeViewerId)
  const config = useTypedSelector((state) => state.viewerPage.configPanels)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isGuest = isGuestHandler(authorities)

  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const isReadOnly = caseRecord?.permissionLevel === 'READ_ONLY'
  const isShared = caseRecord?.relation === 'SHARED'
  const isDisplayLabels = (isShared && isReadOnly) || isGuest
  const setupMenuRef = useRef(null)
  const { t } = useTranslation()

  const menuOptions: OptionType[] = [
    { action: ACTION_SHOW_LABELS, value: t('Показывать этикетки') },
    { action: ACTION_SHOW_PREVIEW, value: t('Показывать превью') },
    { action: ACTION_SHOW_NAME_SLIDE, value: t('Показывать имя слайда') },
  ]

  const applyConfigAction = (action: string) => {
    viewerDispatch(viewerPageSlice.actions.setToggleConfigPanel(action))
    updateConfigLocalStorage(action)
  }

  const configActionHandlers: ConfigActions = {
    [ACTION_SHOW_LABELS]: () => {
      if (config[ACTION_SHOW_PREVIEW] || config[ACTION_SHOW_NAME_SLIDE]) {
        applyConfigAction(ACTION_SHOW_LABELS)
      }
    },

    [ACTION_SHOW_NAME_SLIDE]: () => {
      if (config[ACTION_SHOW_PREVIEW] || config[ACTION_SHOW_LABELS]) {
        applyConfigAction(ACTION_SHOW_NAME_SLIDE)
      }
    },

    [ACTION_SHOW_PREVIEW]: () => {
      if (config[ACTION_SHOW_NAME_SLIDE] || config[ACTION_SHOW_LABELS]) {
        applyConfigAction(ACTION_SHOW_PREVIEW)
      }
    },
  }

  const handleConfigAction = (action: string) => {
    const selectedAction = configActionHandlers[action]
    selectedAction()
  }

  const findLastTrueKey = (config: ConfigValues) => {
    const trueKeys = Object.keys(config).filter((key) => config[key])

    if (trueKeys.length === 1) {
      return trueKeys[0]
    }
  }

  const lastTrueKey = findLastTrueKey(config)

  useEffect(() => {
    if (config) {
      const configString = JSON.stringify(config)
      localStorage.setItem(CONFIG_CASE_PANELS_KEY, configString)
    }
  }, [config])

  useOnClickOutside(setupMenuRef, () => toggleOpen(), menuButtonRef)

  return (
    <ContextMenuWrapper ref={setupMenuRef}>
      {menuOptions.map((item) => (
        <React.Fragment key={item.action}>
          {isDisplayLabels && item.action === ACTION_SHOW_LABELS ? null : (
            <>
              <CheckboxList
                key={item.action}
                option={item}
                lastTrueKey={lastTrueKey}
                currentValue={config[item.action]}
                onClick={handleConfigAction}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </ContextMenuWrapper>
  )
}
