import { ButtonElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

export const FormButtonElement = styled(ButtonElement)`
  @media (hover: none) {
    &:not(.ant-btn-icon-only):hover {
      opacity: 1 !important;
    }

    &:not(.ant-btn-icon-only):active {
      opacity: 0.7 !important;
    }
  }
`
