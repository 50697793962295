import { useTranslation } from 'react-i18next'
import { getSlideName } from 'shared/lib/metadata'
import { ButtonElement, ImageElement, TitleElement } from 'shared/ui/kit'
import styled, { css } from 'styled-components/macro'
import ISlide from 'types/ISlide'

type Props = {
  isSelected?: boolean
  src?: string
  slide: ISlide
  onClick: () => void
}

const StyledAttachment = styled.div`
  display: grid;
  grid-template-areas: 'slide name' 'slide button';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-gap: 4px;
  margin: 8px 0;
`

const selectedStyle = css`
  border: 2px solid var(--color-purple);
  border-radius: 5px;
  padding: 2px;
`

const ImageWrapper = styled.div<{ isSelected?: boolean }>`
  grid-area: slide;
  height: 40px;
  width: 40px;
  cursor: pointer;
  ${({ isSelected }) => isSelected && selectedStyle}
`

const Title = styled(TitleElement)`
  &.ant-typography {
    font-size: 11px;
    line-height: 12px;
    grid-area: name;
  }
`

const CommentAttachment = ({ isSelected, onClick, slide, src }: Props) => {
  const { t } = useTranslation()
  return (
    <StyledAttachment>
      <ImageWrapper onClick={onClick} isSelected={isSelected}>
        <ImageElement src={src} />
      </ImageWrapper>
      <Title ellipsis type="secondary" tooltipPlacement="left">
        {getSlideName(slide)}
      </Title>
      <ButtonElement style={{ gridArea: 'button', width: '100%' }} onClick={onClick}>
        {t('Перейти к области')}
      </ButtonElement>
    </StyledAttachment>
  )
}

export default CommentAttachment
