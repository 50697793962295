import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'
import { ModalProvider } from 'shared/ui/modal'
import { MenuItem } from 'shared/ui/navigation/MenuItem'
import styled from 'styled-components'

import MarkingBody from './MarkingBody'

export const StyledModalProvider = styled(ModalProvider)`
  width: auto !important;

  & .ant-modal-content .ant-modal-body {
    padding: 0px 16px 16px 16px;
  }
`

const MarkingButtonModal = () => {
  const markingModal = useRef<any>(null)
  const onOpen = () => markingModal.current?.open()
  const onClose = () => markingModal.current?.close()
  const { i18n, t } = useTranslation()
  return (
    <>
      {i18n.language === I18N_LANGUAGES.RUSSIAN && (
        <MenuItem
          data-testid={'marking-menu-item'}
          title={t('Маркировка')}
          icon="marking"
          path={onOpen}
          active={false}
        />
      )}
      <StyledModalProvider
        ref={markingModal}
        title={t('Маркировка')}
        onCancel={onClose}
        width={'fit-content'}
        footer={null}
        destroyOnClose
        centered
      >
        <MarkingBody />
      </StyledModalProvider>
    </>
  )
}

export default MarkingButtonModal
