export const VIEWER_COLORS = {
  A: 'var(--color-purple)',
  B: 'var(--color-yellow)',
  C: 'var(--color-green)',
  D: 'var(--color-red)',
}

export const VIEWER_COLORS_HEX = {
  A: '#4e63d2',
  B: '#f8cd76',
  C: '#7bb86f',
  D: '#dc524c',
}

export default VIEWER_COLORS
