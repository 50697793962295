import { useSlideQuery } from 'entities/slide'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISource from 'types/ISource'

const SearchBtn = styled(ButtonElement)`
  width: 100%;
`

type Props = {
  isVisible: boolean
  setVisible: (state: boolean) => void
  text: string
  slideId: number
  caseId: number
  source: ISource
}

const SearchButton = ({ caseId, isVisible, setVisible, slideId, source, text }: Props) => {
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  const { t } = useTranslation()
  const defaultModel = slide?.searchModels?.find((m) => m.default)
  if (defaultModel?.status === 'FAILED') {
    return <TextElement type="danger">{t('Ошибка при индексации.')}</TextElement>
  }

  if (defaultModel?.status === 'PROCESSING' || slide?.searchModels?.length === 0) {
    return <TextElement type="secondary">{t('Слайд ещё не проиндексирован, зайдите позднее.')}</TextElement>
  }

  return <SearchBtn onClick={() => setVisible(!isVisible)}>{text}</SearchBtn>
}

export default SearchButton
