import { useTypedSelector } from 'app/redux/lib/selector'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IAnnotation } from 'types/IAnnotations'

export const useAnnotationAuthorValidation = () => {
  const queryClient = useQueryClient()
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const { activeViewerId } = useViewerPageProvided()
  const { selectedObjectId: objectId } = useTypedSelector((state) => state.viewers[activeViewerId].viewer)
  const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, objectId])
  const isUserId = objectId ? annotation?.userId === currentUserId : true
  return isUserId
}
