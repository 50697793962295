import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { fromDegreesToRadians, fromRadiansToDegrees, rotateCoordinate } from 'pages/viewer/lib/helper'
import { useCallback, useEffect, useMemo } from 'react'
import { useEventBusProvided } from 'shared/lib/EventBus'
import TViewerId from 'types/TViewerId'

import { useCommonCalculations } from './hooks/useCommonCalculations'
import { useCoregistrationProvided } from './Provider'

export const opacityHandler = (rotation?: number) => {
  if (!rotation) return
  const degreesRotation = fromRadiansToDegrees(rotation)
  const ratio = degreesRotation / 90
  const absoluteVal = Math.abs(ratio)
  const closestValue = absoluteVal - Math.floor(absoluteVal)
  const result = 1 - closestValue
  return result > 0.5 ? 1 - closestValue : closestValue
}

const CoregistrationEventController = () => {
  const bus = useEventBusProvided()
  const { corViewingState } = useCoregistrationProvided()
  const { viewingState } = useViewerPageProvided()

  const openViewerIds = useMemo(() => Object.keys(corViewingState) as TViewerId[], [viewingState, corViewingState])

  /**
   * Вычислительная часть
   */
  const {
    computeRestViewerCenterByAffine,
    computeRestViewerRotationByAffine,
    computeRestViewerZoomByAffine,
    warpCoordinates,
  } = useCommonCalculations()

  const computeFirstViewerCenterFromAffine = useCallback(
    (initialVid: TViewerId, callback = () => {}) => {
      const vidToChange = 'A'
      const initialMap = viewingState[initialVid]?.map
      const mapToChange = viewingState[vidToChange]?.map
      const affine = corViewingState[initialVid]?.affine
      if (initialMap === undefined || mapToChange === undefined || affine === undefined) return
      const initialCenter = initialMap.getView().getCenter()
      const centerToChange = mapToChange.getView().getCenter()
      if (initialCenter === undefined || centerToChange === undefined) return
      const expectedCenter = warpCoordinates(initialVid, vidToChange, true)
      const refShift = [0, 0]
      if (expectedCenter !== undefined) {
        refShift[0] = initialCenter[0] - expectedCenter[0]
        refShift[1] = initialCenter[1] - expectedCenter[1]
      }
      const rotatedD = rotateCoordinate(refShift, -affine.angle, [0, 0], affine.scale)
      const newCenter = [centerToChange[0] + rotatedD[0], centerToChange[1] + rotatedD[1]]
      mapToChange.getView().setCenter(newCenter)
      const allViewerIds = Object.keys(viewingState)
      const restViewerIds = allViewerIds.filter((id) => id !== vidToChange && id !== initialVid)
      if (restViewerIds.length) callback(restViewerIds)
    },
    [viewingState, corViewingState],
  )
  const computeFirstViewerZoomFromAffine = useCallback(
    (initialVid: TViewerId, callback = () => {}) => {
      const vidToChange = 'A'
      const initialMap = viewingState[initialVid]?.map
      const mapToChange = viewingState[vidToChange]?.map
      const affine = corViewingState[initialVid]?.affine
      if (initialMap === undefined || mapToChange === undefined || affine === undefined) return
      const zoom = initialMap.getView().getResolution()
      if (zoom === undefined) return
      const finalZoom = zoom * affine.scale
      mapToChange.getView().setResolution(finalZoom)
      const allViewerIds = Object.keys(viewingState)
      const restViewerIds = allViewerIds.filter((id) => id !== vidToChange && id !== initialVid)
      if (restViewerIds.length) callback(restViewerIds)
    },
    [viewingState, corViewingState],
  )
  const computeFirstViewerRotationFromAffine = useCallback(
    (initialVid: TViewerId, callback = () => {}) => {
      const vidToChange = 'A'
      const initialMap = viewingState[initialVid]?.map
      const mapToChange = viewingState[vidToChange]?.map
      const affine = corViewingState[initialVid]?.affine
      if (initialMap === undefined || mapToChange === undefined || affine === undefined) return
      const rotation = initialMap.getView().getRotation()
      const radians = fromDegreesToRadians(fromRadiansToDegrees(rotation) - affine.angle)
      mapToChange.getView().setRotation(radians)
      const allViewerIds = Object.keys(viewingState)
      const restViewerIds = allViewerIds.filter((id) => id !== vidToChange && id !== initialVid)
      if (restViewerIds.length) callback(restViewerIds)
    },
    [viewingState, corViewingState],
  )

  /**
   * Программно-событийная часть
   * Теперь цепочка вызовов расчётов может быть описана без лишних рубающих условий
   * и работать нагляднее.
   */

  const onCorrCenter = useCallback(
    (initialVid: TViewerId, vidToChange: TViewerId) => {
      if (initialVid === 'A') {
        computeRestViewerCenterByAffine(vidToChange)
      } else {
        computeFirstViewerCenterFromAffine(initialVid, (restVids: TViewerId[]) => {
          restVids.forEach((id) => computeRestViewerCenterByAffine(id))
        })
      }
    },
    [viewingState, corViewingState],
  )

  const onCorrZoom = useCallback(
    (initialVid: TViewerId, vidToChange: TViewerId) => {
      if (initialVid === 'A') {
        computeRestViewerZoomByAffine(vidToChange)
      } else {
        computeFirstViewerZoomFromAffine(initialVid, (restVids: TViewerId[]) => {
          restVids.forEach((id) => computeRestViewerZoomByAffine(id))
        })
      }
    },
    [viewingState, corViewingState],
  )
  const onCorrRotation = useCallback(
    (initialVid: TViewerId, vidToChange: TViewerId) => {
      if (initialVid === 'A') {
        computeRestViewerRotationByAffine(vidToChange)
      } else {
        computeFirstViewerRotationFromAffine(initialVid, (restVids: TViewerId[]) => {
          restVids.forEach((id) => computeRestViewerRotationByAffine(id))
        })
      }
    },
    [viewingState, corViewingState],
  )

  const onDrag = useCallback(
    (initialViewerId: TViewerId) => {
      openViewerIds.forEach((id) => {
        const vid = id as TViewerId
        const loading = corViewingState[vid]?.loading
        if (vid === initialViewerId || loading === true) return
        bus.$emit('corr:center', initialViewerId, vid)
      })
    },
    [viewingState, corViewingState, openViewerIds],
  )

  const onZoom = useCallback(
    (initialViewerId: TViewerId) => {
      openViewerIds.forEach((id) => {
        const vid = id as TViewerId
        const loading = corViewingState[vid]?.loading
        if (vid === initialViewerId || loading === true) return
        bus.$emit('corr:zoom', initialViewerId, vid)
        bus.$emit('corr:center', initialViewerId, vid)
      })
    },
    [viewingState, corViewingState, openViewerIds],
  )

  const onRotate = useCallback(
    (initialViewerId: TViewerId) => {
      openViewerIds.forEach((id) => {
        const vid = id as TViewerId
        const loading = corViewingState[vid]?.loading
        if (vid === initialViewerId || loading === true) return
        bus.$emit('corr:rotation', initialViewerId, vid)
      })
    },
    [viewingState, corViewingState, openViewerIds],
  )

  useEffect(() => {
    bus.$addEventListener('afterMapCenter', onDrag)
    bus.$addEventListener('afterMapZoom', onZoom)
    bus.$addEventListener('afterMapRotation', onRotate)
    bus.$addEventListener('corr:center', onCorrCenter)
    bus.$addEventListener('corr:zoom', onCorrZoom)
    bus.$addEventListener('corr:rotation', onCorrRotation)
    return () => {
      bus.$removeEventListener('corr:center', onCorrCenter)
      bus.$removeEventListener('corr:zoom', onCorrZoom)
      bus.$removeEventListener('corr:rotation', onCorrRotation)
      bus.$removeEventListener('afterMapCenter', onDrag)
      bus.$removeEventListener('afterMapZoom', onZoom)
      bus.$removeEventListener('afterMapRotation', onRotate)
    }
  }, [bus, viewingState, corViewingState, openViewerIds])

  /**
   * При маунтинге применяем affine
   * Поскольку контроллер регистрируется только после загрузки affine
   */
  useEffect(() => {
    Object.keys(corViewingState).forEach((id) => {
      const vid = id as TViewerId
      if (vid === 'A') return
      const cState = corViewingState[vid]
      if (cState === undefined || cState.loading === true) return
      computeRestViewerCenterByAffine(vid)
      computeRestViewerZoomByAffine(vid)
      computeRestViewerRotationByAffine(vid)
    })
  }, [corViewingState])

  return null
}

export default CoregistrationEventController
