import { useTranslation } from 'react-i18next'
import { ButtonElement, Center, Column, SpaceElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledCenter = styled(Center)`
  background-color: var(--color-bg-2);
`

type Props = {
  onClick: () => void
}

const NoSlides = ({ onClick }: Props) => {
  const { t } = useTranslation()
  return (
    <StyledCenter>
      <Column>
        <TitleElement level={1}>{t('В выбранном случае пока нет ни одного слайда.')}</TitleElement>
        <SpaceElement size={16} />
        <ButtonElement type="primary" onClick={onClick}>
          {t('Перейти к случаю')}
        </ButtonElement>
      </Column>
    </StyledCenter>
  )
}

export default NoSlides
