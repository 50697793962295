import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useSearchGridQuery } from 'entities/slide-layers'
import { selectUrlSlideId } from 'pages/viewer'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext } from 'react'
import { MapContext } from 'shared/lib/map'
import ISource from 'types/ISource'
import { useSlideMapViewSelector, useViewerMainSelector } from 'viewer/container'

import GridLayer from './GridLayer'
import SelectedBboxLayer from './SelectedBboxLayer'

type Props = {
  iiif2Url?: string
  slideId: number
  caseId: number
  source: ISource
}
export const SearchLayerContainer = ({ caseId, iiif2Url, slideId, source }: Props) => {
  const { data: features, isLoading } = useSearchGridQuery(slideId, slideId, source)
  const { viewerId } = useContext(MapContext)
  const map = useViewerIdMap(viewerId)
  const mainSelectedBbox = useTypedSelector((state) => state.search.mainSelectedBbox)
  const { gridIsVisible, selectedBbox } = useViewerMainSelector(viewerId)
  const { viewZoom } = useSlideMapViewSelector({ slideId, viewerId })
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  const urlSlideId = useTypedSelector(selectUrlSlideId)
  return (
    <>
      {gridIsVisible && !isLoading && features && viewZoom && (
        <GridLayer
          features={features}
          caseId={caseId}
          slideId={slideId}
          currentZoomLevel={viewZoom}
          selectedBbox={selectedBbox}
          iiif2Url={iiif2Url}
          iiif2AuthToken={slide?.iiif2AuthToken}
        />
      )}
      {selectedBbox && !isLoading && map.isRendered() && features && (
        <SelectedBboxLayer features={features} selectedBbox={selectedBbox} map={map} slideId={slideId} animated />
      )}
      {urlSlideId === slideId && mainSelectedBbox && !isLoading && map.isRendered() && features && (
        <SelectedBboxLayer features={features} selectedBbox={mainSelectedBbox} map={map} slideId={slideId} />
      )}
    </>
  )
}

export default SearchLayerContainer
