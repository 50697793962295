import { useTranslation } from 'react-i18next'
import { TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
`

const TextWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const SecondaryNotificationText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--color-text-3);
  margin: 0;
`

const OnCoregistrationFeedbackNotification = () => {
  const { t } = useTranslation()

  return (
    <Wrapper data-testid={'slide-restore-block'}>
      <TextWrapper>
        <TitleElement level={4} ellipsis>
          {t('Ваше сообщение отправлено')}
        </TitleElement>
        <SecondaryNotificationText>{t('Спасибо за обратную связь!')}</SecondaryNotificationText>
      </TextWrapper>
    </Wrapper>
  )
}

export default OnCoregistrationFeedbackNotification
