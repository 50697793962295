import { useTypedSelector } from 'app/redux/lib/selector'
import { annotationsSlice } from 'features/annotations'
import annotationsService from 'features/annotations/api/service'
import { useAnnotationsList } from 'features/annotations/lib/useAnnotationsList'
import olMap from 'ol/Map'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import DragPan from 'shared/lib/map/lib/interactions/DragPan'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { isLeftClick, isMiddleClick } from 'viewer/map/layers/olEvents'

import usePrevious from './usePrevious'

type IProps = {
  caseId: number
  slideId: number
  selectedAnnotationsIds: number[]
  map: olMap
  menuMargin: number
  /** id текущего вьювера */
  viewerId: TViewerId
}

const useContextMenu = ({ caseId, map, menuMargin, selectedAnnotationsIds, slideId, viewerId }: IProps) => {
  const dispatch = useDispatch()
  const [isContextMenu, setContextMenu] = useState(false)
  const [isTypePanelVisible, setIsTypePanelVisible] = useState(true)
  const [annotationsContext, setAnnotationsContext] = useState<IAnnotation>()
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const { changeMode, drawMode, extendedContextMenuPosition } = useTypedSelector((state) => state.annotations)

  /** Id аннотации для которого открывается контексное меню */
  const [contextMenuAnnotationId, setContextMenuAnnotationId] = useState<number>(0)

  const queryClient = useQueryClient()
  const objectsMitosisContextMenu = useTypedSelector((state) => state.annotations.objectsMitosisContextMenu)
  /** Список аннотаций */
  const slideAnnotationList = useAnnotationsList(viewerId)
  /** Ссылка на предыдущий слайд */
  const prevSlideId = usePrevious(slideId)

  const handleSetExtendedContextMenuPosition = (
    position: { x: number; y: number } | null,
    hoveredAnnotationId?: number,
  ) => {
    const annotation =
      queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, hoveredAnnotationId]) ||
      queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, selectedAnnotationsIds[0]])
    if (
      !position ||
      (!hoveredAnnotationId && !selectedAnnotationsIds.length) ||
      annotation?.type === AnnotationType.MITOSIS
    ) {
      setContextMenu(false)
      setContextMenuAnnotationId(0)
      dispatch(annotationsSlice.actions.setObjectsMitosisContextMenuVisible({ visible: false }))
    }
    if (position && hoveredAnnotationId && annotation?.type !== AnnotationType.MITOSIS) {
      setContextMenu(true)
    }
    dispatch(annotationsSlice.actions.setExtendedContextMenuPosition(position))
    setAnnotationsContext(annotation)
  }

  const contextMenuHandler = (x: number, y: number, hoveredAnnotationId?: number) => {
    /** При обычном открытии контекстного меню нужно вернуть панель выбора аннотации */
    setIsTypePanelVisible(true)

    /** Сохраняем id аннотации в зависимости как открыли контексное меню */
    if (hoveredAnnotationId) {
      setContextMenuAnnotationId(hoveredAnnotationId)
    } else if (selectedAnnotationsIds.length) {
      setContextMenuAnnotationId(selectedAnnotationsIds[0])
    }

    const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, hoveredAnnotationId])
    handleSetExtendedContextMenuPosition({ x: x - menuMargin, y: y - menuMargin }, hoveredAnnotationId)
    if (
      (hoveredAnnotationId || selectedAnnotationsIds.length) &&
      annotation?.type !== AnnotationType.MITOSIS &&
      !MITOSIS.isVisible
    ) {
      setContextMenu(true)
    } else {
      setContextMenu(false)
    }
  }

  useEffect(() => {
    const updateDragPanEventCondition = (condition?: (e: any) => boolean) => {
      map.getInteractions().forEach((interaction) => {
        if (interaction instanceof DragPan) {
          interaction.changeCondition(condition)
        }
      })
    }
    if (drawMode || changeMode) {
      updateDragPanEventCondition((e: any) => {
        if (drawMode) {
          setContextMenu(false)
          dispatch(annotationsSlice.actions.setExtendedContextMenuPosition(null))
          return isMiddleClick(e)
        } else if (changeMode) {
          return isMiddleClick(e)
        } else {
          if (e.originalEvent.altKey === true && e.originalEvent.shiftKey === true) return false
          return isLeftClick(e)
        }
      })
    } else {
      updateDragPanEventCondition((e: any) => {
        if (e.originalEvent.altKey === true && e.originalEvent.shiftKey === true) return false
        return isLeftClick(e)
      })
    }
  }, [drawMode, changeMode])

  useDeepCompareEffect(() => {
    const objectId = objectsMitosisContextMenu?.objectId
    const annotationSlideId = objectsMitosisContextMenu?.slideId
    if (!objectId || !annotationSlideId || +annotationSlideId !== slideId || !objectsMitosisContextMenu?.visible) {
      setAnnotationsContext(undefined)
      setContextMenu(false)
      setContextMenuAnnotationId(0)
      dispatch(annotationsSlice.actions.setExtendedContextMenuPosition(null))
      return
    }
    annotationsService.getAnnotation(caseId, +annotationSlideId, +objectId).then((res) => {
      const { height, width, x, y } = map.getViewport().getBoundingClientRect()
      const center = [x + width / 2, y + height / 2]
      if (width && height) {
        /** Сохраняем id Объекта */
        setContextMenuAnnotationId(objectId)
        /** Установка новой аннотации в контекст */
        setAnnotationsContext(res)
        /** Открытие контекстного меню */
        setContextMenu(true)
        /** Скрытие панели выбора аннотации (для нового подсчета она не нужна) */
        setIsTypePanelVisible(false)
        /** Установка положения окна контекстного меню */
        dispatch(
          annotationsSlice.actions.setExtendedContextMenuPosition({
            x: center[0] - menuMargin,
            y: center[1] - menuMargin,
          }),
        )
      }
    })
  }, [objectsMitosisContextMenu || {}])

  useEffect(() => {
    /** Закрываем контексное меню при переходе по слайдам или если аннотация удалена */
    if (
      slideId !== prevSlideId ||
      (contextMenuAnnotationId &&
        !slideAnnotationList.some((annotation) => annotation.slideAnnotationId === contextMenuAnnotationId))
    ) {
      setAnnotationsContext(undefined)
      setContextMenu(false)
      setContextMenuAnnotationId(0)
      dispatch(annotationsSlice.actions.setExtendedContextMenuPosition(null))
    }
  }, [slideId, slideAnnotationList.length])

  return {
    MITOSIS,
    annotationsContext,
    contextMenuHandler,
    drawMode,
    extendedContextMenuPosition,
    handleSetExtendedContextMenuPosition,
    isContextMenu,
    isTypePanelVisible,
  }
}

export default useContextMenu
