import Image from 'antd/lib/image'
import { Link } from 'react-router-dom'
import { IconElement, SpaceElement } from 'shared/ui/kit'
import styled, { css } from 'styled-components/macro'

type Props = {
  icon?: any // IIconElementProps['name']
  image?: any
  path: (() => void) | string | Record<string, unknown>
  title: string
  disabled?: boolean
  active: boolean
  testAttr?: string
}

export const MenuItem = ({ active, disabled, icon, image, path, testAttr, title }: Props) => (
  <StyledMenuItem
    active={active}
    disabled={disabled}
    data-testid={`${(testAttr || icon || title).toLowerCase()}-menu-item`}
  >
    {icon && (
      <>
        <SpaceElement size={8} />
        <IconElement name={icon} />
      </>
    )}
    {image && (
      <>
        <SpaceElement size={8} />
        <Image src={image} preview={false} width={24} height={24} />
      </>
    )}

    {typeof path == 'string' ? (
      <Link to={path}>{title}</Link>
    ) : typeof path == 'object' ? (
      <a {...path}>{title}</a>
    ) : typeof path == 'function' ? (
      <a onClick={path}>{title}</a>
    ) : null}
  </StyledMenuItem>
)

const activeCss = css`
  background: var(--color-purple);
  color: white;

  & a {
    color: white;
  }

  &:hover,
  & a:hover {
    color: white;
  }
`

const StyledMenuItem = styled.div<{ active: boolean; disabled?: boolean }>`
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: var(--color-text-3);
  transition: none;

  & a {
    color: var(--color-text-3);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-decoration: none;
    width: 100%;
    padding: 6px 8px;
    flex: 1;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
    transition: none;
  }

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: var(--color-text-1);
    a {
      color: ${({ active }) => !active && 'var(--color-text-1)'};
    }
  }

  ${(props) => props.active && activeCss}
`
