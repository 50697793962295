import Input, { TextAreaProps } from 'antd/es/input'
import styled from 'styled-components/macro'

const { TextArea } = Input

const StyledTextArea = styled(TextArea)`
  padding: 4px 8px;
  background: var(--color-bg-3);
  color: var(--color-text-1);
  &.ant-input {
    line-height: 16px;
  }
  &::placeholder {
    -webkit-text-fill-color: var(--color-text-3);
  }
`
export const TextAreaElement = (props: TextAreaProps) => <StyledTextArea {...props} />
