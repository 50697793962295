import { useTypedSelector } from 'app/redux/lib/selector'
import { useAnnotationsList } from 'features/annotations/lib/useAnnotationsList'
import { useEffect, useMemo, useRef } from 'react'
import TViewerId from 'types/TViewerId'
import { useViewerMainSelector } from 'viewer/container'
import { useKeyUp } from 'viewer/map/layers/annotations/lib/hooks/useKeyUp'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'
import { AnnotationDescriptionAndAreaControl } from 'viewer/map/layers/annotations/ui/AnnotationDescriptionAndAreaControl'

import { StyledContextMenu } from './ExtendedAnnotationContextMenu'

/** Ширина контекстного меню */
export const CONTEXT_MENU_WIDTH = 294
/** Высота контекстного меню */
export const CONTEXT_MENU_HEIGHT = 80.85

type Props = {
  /** id созданной аннотации */
  annotationId?: number
  viewerId: TViewerId
  mppX: number
  position: number[]
  onClose: () => void
}

export const LiteAnnotationContextMenu = ({ annotationId, mppX, onClose, position, viewerId }: Props) => {
  const annotations = useAnnotationsList(viewerId)
  const { liteContextMenuVisibility } = useTypedSelector((state) => state.viewerPage)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)

  const annotation = useMemo(
    () => annotations.find((it) => it.slideAnnotationId === annotationId),
    [annotationId, annotations],
  )

  const root = useRef<HTMLDivElement | null>(null)
  useKeyUp(13, onClose)
  useOnClickOutside(root, onClose)
  useEffect(() => {
    if (!liteContextMenuVisibility) {
      onClose()
    }
  }, [liteContextMenuVisibility])

  useEffect(() => {
    /** Если создана новая аннотация ИЛИ если выделена другая аннотация, закрываем LiteAnnotationContextMenu**/
    if (selectedAnnotationsIds[0] && annotationId !== selectedAnnotationsIds[0]) {
      onClose()
    }
  }, [annotationId, selectedAnnotationsIds])

  return (
    <div ref={root}>
      <StyledContextMenu
        style={{
          height: CONTEXT_MENU_HEIGHT,
          left: `${position[0]}px`,
          padding: '16px',
          position: 'absolute',
          top: `${position[1]}px`,
          width: CONTEXT_MENU_WIDTH,
        }}
      >
        <AnnotationDescriptionAndAreaControl annotation={annotation} viewerId={viewerId} mppX={mppX} />
      </StyledContextMenu>
    </div>
  )
}
