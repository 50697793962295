import styled from 'styled-components/macro'

export const ActionWrapper = styled.div`
  width: 240px;
  height: calc(100% + 12px);
  min-width: 240px;
  border: 1px solid var(--color-border-1);
  border-right: none;
  border-bottom: none;

  h3.ant-typography {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-text-3);
  }
  h2.ant-typography {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
  }

  .ant-list-split .ant-list-header {
    border-bottom: none;
  }

  .ant-list-header {
    padding: 0;

    h3 {
      margin-bottom: 1px;
    }
  }
`
