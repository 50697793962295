import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerAttachmentsSlice } from 'features/attachments'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useDispatch } from 'react-redux'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { useViewerDispatch } from 'viewer/container'

import { commentsSlice } from './model/commentsSlice'

type Props = {
  /** id комментария */
  linkedComment: number | 'new'
  /** флаг, определяющий доступ к кнопке */
  isDisabled?: boolean
}

const StyledButton = styled(ButtonElement)`
  &.ant-btn-icon-only {
    width: 24px;
    height: 24px;
    background-color: transparent;
    padding: 0;
    display: grid;
    place-items: center;
  }
  & > * {
    width: 24px;
    height: 24px;
  }
`

const AnnotationButtonContainer = ({ isDisabled, linkedComment }: Props) => {
  const dispatch = useDispatch()
  const mode = useTypedSelector((state) => state.comments.isAttachmentMode)
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const onClick = () => {
    viewerDispatch(viewerAttachmentsSlice.actions.setAttachmentMode(!mode))
    dispatch(commentsSlice.actions.setEditingComment(linkedComment))
  }

  return (
    <StyledButton
      size={'middle'}
      disabled={isDisabled}
      icon={<IconElement name={mode ? 'deleteFrame' : 'addFrame'} size="md" />}
      onClick={onClick}
    />
  )
}

export default AnnotationButtonContainer
