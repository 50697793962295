import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useTaskSlides } from 'entities/tasks/api/query'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { VirtualList } from 'shared/ui/infinite-scroll'
import { Center, SpinElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IAtlasValidationStatus } from 'types/IAtlasSlide'
import { IMarkupResultStatus, IMarkupSlide, IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupParticipant, IMarkupTask } from 'types/IMarkupTask'

type Props = {
  selectedSlideId: number
}

const ATLAS_SLIDE_STATUS_COLORS = {
  APPROVED: 'var(--color-green)',
  DONE: 'var(--color-blue)',
  INVALID: 'var(--color-red)',
  NOT_VIEWED: 'var(--color-bg-4)',
  PAUSED: 'var(--color-bg-4)',
  REWORK: 'var(--color-red)',
  VALID: 'var(--color-green)',
  WIP: 'var(--color-bg-4)',
}

const TaskSlideItem = styled.div<{ isActive: boolean; flex: boolean }>`
  display: ${({ flex }) => (flex ? 'flex' : 'grid')};
  grid-template-columns: ${({ flex }) => (flex ? null : ' 1fr auto auto')};
  align-items: ${({ flex }) => (flex ? null : 'center')};
  flex-direction: ${({ flex }) => (flex ? 'column' : null)};
  gap: 8px;
  padding: 4px 8px;
  margin: 4px 0;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ isActive }) => isActive && 'var(--color-purple)'};
  color: ${({ isActive }) => isActive && 'white'};

  & > .ant-typography {
    color: ${({ isActive }) => isActive && 'white'};
  }

  &:hover {
    background: var(--color-purple);
    color: white;

    .ant-typography {
      color: white;
    }
  }
`
const StatusDot = styled.div<{ status?: IAtlasValidationStatus }>`
  border-radius: 50%;
  background-color: ${({ status }) => (status ? ATLAS_SLIDE_STATUS_COLORS[status] : 'var(--color-bg-4)')};
  width: 8px;
  height: 8px;
`
const StyledName = styled.p`
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-left: 8px;
  font-size: 11px;
  white-space: nowrap;
`
const StyledTextElement = styled(TextElement)`
  font-weight: 500;
  font-size: 12px;
`

const TaskNameBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const TasksSlidesListContainer = ({ selectedSlideId }: Props) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const { data, isFetching } = useTaskSlides(taskId)

  if (isFetching) {
    return (
      <Center>
        <SpinElement />
      </Center>
    )
  }

  return (
    <VirtualList
      total={Array.isArray(data) ? data.length : 60}
      defaultRowHeight={80}
      rowRenderer={({ index }) => {
        const item = Array.isArray(data) && data[index]
        if (!item) return null
        return (
          <AtlasSlideItemContainer
            slideId={item.slideId || 0}
            selectedSlideId={selectedSlideId}
            taskId={taskId}
            markupSlideData={taskData?.participants}
            status={taskData?.status}
          />
        )
      }}
    />
  )
}

const AtlasSlideItemContainer = ({
  markupSlideData,
  selectedSlideId,
  slideId,
  taskId,
}: {
  slideId: number
  selectedSlideId: number
  taskId?: number
  markupSlideData?: IMarkupParticipant[]
  status?: IMarkupResultStatus
}) => {
  const dispatch = useDispatch()
  const { changeViewerSlide } = useViewerPageProvided()
  const { slideId: currentSlideId } = useViewerIdSlideState('A')

  const queryClient = useQueryClient()
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])

  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const userData = taskData?.participants?.find((item) => item.userId === currentUserId)

  const currentSlides = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
  const currSlide = currentSlides?.find((item) => item.slideId === slideId)
  const slideResults = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, slideId])
  const currUserRes = slideResults?.find((itemResult) => itemResult.markupParticipant?.userId === currentUserId)

  const slideRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (selectedSlideId === currSlide?.slideId) {
      slideRef.current?.scrollIntoView({
        block: 'nearest',
      })
    }
  }, [selectedSlideId])

  const clickHandler = () => {
    if (currSlide && currentSlideId !== slideId) {
      dispatch(push(`/tasks-viewer/${taskId}?slideId=${currSlide?.slideId}`))
      changeViewerSlide('A', {
        caseId: NaN,
        slideGroupType: 'MICRO',
        slideId: currSlide.slideId,
        source: 'ATLAS',
        viewerMode: 'DEFAULT',
      })
    }
  }
  return currSlide?.slideId ? (
    <TaskSlideItem
      isActive={currSlide?.slideId === selectedSlideId}
      flex={!!markupSlideData && userData?.access !== 'ANNOTATE'}
      onClick={clickHandler}
      ref={slideRef}
    >
      <StyledTextElement ellipsis>{currSlide?.slide?.caseExternalId || currSlide?.slideId || '-'}</StyledTextElement>
      {userData?.access === 'ANNOTATE' ? (
        <StatusDot style={{ border: '1px solid #1C1C1E' }} status={currUserRes?.status} />
      ) : (
        taskData?.participants
          ?.filter((item) => item.access === 'ANNOTATE')
          ?.map((item) => {
            const currResult = slideResults?.find((itemResult) => itemResult.markupParticipant?.userId === item.userId)
            return (
              <TaskNameBlock key={item.userId}>
                <StyledName>{item.user?.fullname}</StyledName>
                <StatusDot style={{ border: '1px solid #1C1C1E' }} status={currResult?.status} />
              </TaskNameBlock>
            )
          })
      )}
    </TaskSlideItem>
  ) : null
}

export default TasksSlidesListContainer
