import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useCaseDocumentsQuery } from 'features/cases/api/query'
import caseService from 'features/cases/api/service'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getExternalIdUrl } from 'shared/lib/externalId'
import { useQueryParams } from 'shared/lib/hooks'
import { PageHeader } from 'shared/ui/header'
import { FullScreenModalElement, SpaceElement, SpinElement, TitleElement } from 'shared/ui/kit'
import { PdfViewer } from 'shared/ui/pdf'
import styled from 'styled-components/macro'

export const EXPIRED_EXTERNAL_MESSAGE = 'Ошибка получения случая'

const StyledLogin = styled.div`
  background-color: var(--color-bg-1);
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`
const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const CaseExternalRoute = () => {
  const userToken = useTypedSelector((state) => state.user.token)
  const dispatch = useDispatch()
  const currentLocation = useLocation()

  const workspaceId = useCurrentWorkspaceId()
  const externalId = getExternalIdUrl(window.location.href)
  const queryParams = useQueryParams()
  const mmc = queryParams.get('mmc')
  const mmcId = queryParams.get('mmcId')
  const mmcCid = queryParams.get('mmcCid')
  const isDocument = mmc === 'DOCUMENT'
  const [fileToPreview, setFileToPreview] = useState<{ url: string; type: string } | string>('')
  const [caseId, setCaseId] = useState<number | undefined>(undefined)
  const { data: caseRecordDocs } = useCaseDocumentsQuery({ caseId: caseId, source: 'PLATFORM' })
  const pdfFile = useMemo(() => caseRecordDocs?.filter((item) => item.name === mmcCid), [caseRecordDocs])

  const { t } = useTranslation()

  const fetchExternalCaseId = async (workspaceId?: string, slideId?: string) =>
    await caseService.getExternalCaseId(workspaceId, slideId)

  const jumpToUrl = () => {
    if (fileToPreview) {
      setFileToPreview('')
    }
    fetchExternalCaseId(String(workspaceId), externalId)
      .then((e) => {
        const caseId = e.caseId
        dispatch(push(`/workspace/${workspaceId}/viewer/${caseId}?${isDocument ? '' : 'slideId=' + mmcId}`))
      })
      .catch(() => {
        notices.error({
          message: EXPIRED_EXTERNAL_MESSAGE,
        })
        setTimeout(() => {
          dispatch(push(`/workspace/${workspaceId}/cases`))
        }, 1500)
      })
  }

  const fetchPdfFile = async () => {
    if (isDocument && !fileToPreview && pdfFile) {
      const getDoc = await caseService.fetchDoc(Number(pdfFile[0].uid), caseId)
      if (getDoc) {
        const attachUrl = URL.createObjectURL(new Blob([getDoc], { type: pdfFile[0].type }))
        setFileToPreview({ type: pdfFile[0].type, url: attachUrl })
      }
    }
  }

  useEffect(() => {
    fetchPdfFile()
  }, [pdfFile, caseId])

  useEffect(() => {
    if (!userToken) {
      notices.info({
        key: 'UnauthorizedInvite',
        message: t('Пожалуйста, авторизуйтесь для просмотра случая.'),
        placement: 'topRight',
      })
      dispatch(push('/login', { referrer: currentLocation }))
      return
    }

    if (!externalId || !workspaceId) {
      dispatch(push(`/workspace`))
      return
    }

    if (!isDocument) {
      jumpToUrl()
    } else {
      fetchExternalCaseId(String(workspaceId), externalId).then((e) => {
        const caseId = e.caseId
        setCaseId(caseId)
      })
    }
  }, [dispatch, workspaceId, userToken])

  if (typeof fileToPreview !== 'string') {
    return (
      <FullScreenModalElement visible={!!fileToPreview} onCancel={jumpToUrl} footer={null}>
        {fileToPreview.type === 'application/pdf' ? (
          <PdfViewer file={fileToPreview.url} />
        ) : (
          <ImageContainer>
            <StyledImage src={fileToPreview.url} />
          </ImageContainer>
        )}
      </FullScreenModalElement>
    )
  }

  return (
    <StyledLogin>
      <PageHeader />
      <SpaceElement size={100} />
      <Content>
        <>
          <SpinElement />
          <SpaceElement size={100} />
          <TitleElement level={1}>{t('Открываем случай')}</TitleElement>
        </>
      </Content>
    </StyledLogin>
  )
}
