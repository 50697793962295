import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseCache } from 'features/cases'
import dashboardSlice from 'features/dashboard/dashboardSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { formatDateTimeForDisplay } from 'shared/lib/date'
import styled from 'styled-components/macro'

const DateTime = styled.div`
  font-size: 11px;
  line-height: 12px;
  /* color: var(--color-text-3); */
`

const StyledType = styled.div<{ isActive?: boolean }>`
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: var(--color-purple);
  }
  background-color: ${({ isActive }) => (isActive ? 'var(--color-purple)' : 'var(--color-bg-3)')};
  ${DateTime} {
    color: ${({ isActive }) => (isActive ? 'var(--color-text-1)' : 'var(--color-text-3)')};
  }
`

const TypeName = styled.div`
  font-size: 14px;
  line-height: 20px;
`

type TypeProps = {
  typeName: string
  dateTime: string
  isActive?: boolean
  onClick: () => void
}

const TypeElement = ({ dateTime, isActive, onClick, typeName }: TypeProps) => (
  <StyledType isActive={isActive} onClick={onClick}>
    <TypeName>{typeName}</TypeName>
    <DateTime>{dateTime}</DateTime>
  </StyledType>
)

type Props = {
  caseId: number
}
const DiagnosticTypeContainer = ({ caseId }: Props) => {
  const data = useCaseCache(caseId)
  const dispatch = useDispatch()
  const selectedCase = useTypedSelector((state) => state.dashboard.selectedCase)
  const { t } = useTranslation()
  const onClick = () => {
    dispatch(dashboardSlice.actions.selectCase(caseId))
  }
  let type = data?.casePathologicalInfo.diagnosticProcedureType
    ? data?.casePathologicalInfo.diagnosticProcedureType.name === 'BIOPSY' ||
      data?.casePathologicalInfo.diagnosticProcedureType.name === 'SURGICAL'
      ? t('Микро')
      : data.casePathologicalInfo.diagnosticProcedureType.name
    : ''

  if (caseId === 94 || caseId === 95) {
    type = t('Макро')
  }
  return type && data?.createdAt ? (
    <TypeElement
      onClick={onClick}
      typeName={type}
      dateTime={formatDateTimeForDisplay(new Date(data.createdAt))}
      isActive={caseId === selectedCase}
    />
  ) : null
}

export default DiagnosticTypeContainer
