import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { StompClientContext, useSubscription } from 'processes/stomp'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEventBusProvided } from 'shared/lib/EventBus'

import { ACTIVE_TO_IDLE_TIME, IDLE_TO_LEFT_TIME, MPUserStatus, PING_INTERVAL_TIME } from './constants'
import { MPUserStateDto, MPUserStateUpdateDto, TUserStatusContext, TUserStatusProvider } from './types'

const UserStatusContext = createContext<TUserStatusContext>({
  subscribeToUser: () => {},
  unsubscribeFromUser: () => {},
  usersStatuses: [],
})

export const useUserStatusContext = () => useContext(UserStatusContext)

const UserStatusProvider: React.FC<TUserStatusProvider> = ({ caseId, children, slideId }) => {
  const workspaceId = useCurrentWorkspaceId()
  const bus = useEventBusProvided()

  const { publish, unsubscribe } = useContext(StompClientContext)
  const { t } = useTranslation()

  const unSubscribeMessage = t('Вы больше не следите за пользователем')

  // Топик для отправки сообщений на сервер
  const publishDestination = useMemo(() => `/app/mp/${caseId}/state`, [caseId])
  // Топик для подписки на сообщения от сервера
  const messageDestination = useMemo(() => `/topic/mp/${caseId}/state`, [caseId])
  // Заголовки
  const headers = useMemo(() => (workspaceId ? { 'x-oc-workspace-id': String(workspaceId) } : undefined), [workspaceId])

  // Текущий статус пользователя
  const [curStatus, setCurStatus] = useState<MPUserStatus>(MPUserStatus.Active)
  const [usersStatuses, setUsersStatuses] = useState<MPUserStateDto[]>([])
  // ID пользователя, на которого подписался текущий пользователь
  const [targetUserId, setTargetUserId] = useState<number | undefined>()

  // Состояние уведомления об отписке
  const isNoticeOpened = useRef<boolean>(false)

  // Таймер неактивной вкладки
  const timerBlur = useRef<any>()

  const publishWithStatus = (status: MPUserStatus) => {
    publish<MPUserStateUpdateDto>(
      publishDestination,
      {
        sid: slideId,
        sta: status,
        trg: status === MPUserStatus.Subscribed ? targetUserId : undefined,
      },
      headers,
    )
  }

  const subscribeToUser = useCallback((target: number) => {
    setTargetUserId(target)
    setCurStatus(MPUserStatus.Subscribed)
  }, [])

  const unsubscribeFromUser = (message = unSubscribeMessage) => {
    if (!targetUserId) return

    setTargetUserId(undefined)

    // Переход Subscribed -> Idle невозможен
    // Переход Subscribed -> Left делать не нужно, тк при unmout этот статус отправится автоматически
    setCurStatus(MPUserStatus.Active)
    message &&
      !isNoticeOpened.current &&
      notices.info({
        message,
        onClose: () => {
          isNoticeOpened.current = false
        },
      })
    isNoticeOpened.current = true
  }

  const onUserLeftViewer = () => {
    publishWithStatus(MPUserStatus.Left)
    unsubscribe(messageDestination)
  }

  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      timerBlur.current = setTimeout(() => {
        if (targetUserId) {
          unsubscribeFromUser(unSubscribeMessage)
        }
      }, ACTIVE_TO_IDLE_TIME)
    } else {
      timerBlur.current && clearTimeout(timerBlur.current)
    }
  }

  const onUserActivity = () => {
    if (targetUserId) {
      unsubscribeFromUser(unSubscribeMessage)
    }
  }

  const onKeyPress = (e: KeyboardEvent) => {
    if (targetUserId && ['KeyX', 'KeyZ'].includes(e.code)) {
      unsubscribeFromUser(unSubscribeMessage)
    }
  }

  useSubscription<MPUserStateDto[]>(messageDestination, (response) => setUsersStatuses(response), [caseId])

  useEffect(() => {
    window.addEventListener('beforeunload', onUserLeftViewer)
    return () => {
      window.removeEventListener('beforeunload', onUserLeftViewer)
      onUserLeftViewer()
    }
  }, [caseId])

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)

    bus.$addEventListener('afterMapCenter', onUserActivity)
    bus.$addEventListener('afterMapZoom', onUserActivity)
    document.addEventListener('keypress', onKeyPress)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)

      bus.$removeEventListener('afterMapCenter', onUserActivity)
      bus.$removeEventListener('afterMapZoom', onUserActivity)
      document.removeEventListener('keypress', onKeyPress)
    }
  }, [bus, targetUserId])

  useEffect(() => {
    let activeToIdleTimer: ReturnType<typeof setTimeout>
    let idleToLeftTimer: ReturnType<typeof setTimeout>

    /**
     * Функция обрабатывает переходы между статусами пользователя.
     * Если пользователь подпишется на кого-то, таймеры переходов отключаются.
     */
    const updateLastTimeActive = () => {
      activeToIdleTimer && clearTimeout(activeToIdleTimer)
      idleToLeftTimer && clearTimeout(idleToLeftTimer)
      curStatus !== MPUserStatus.Subscribed && setCurStatus(MPUserStatus.Active)
      // Таймер для перехода Active -> Idle. Запускается только для пользователя со статусом Active
      if (curStatus === MPUserStatus.Active) {
        activeToIdleTimer = setTimeout(() => setCurStatus(MPUserStatus.Idle), ACTIVE_TO_IDLE_TIME)
      }

      // Таймер для перехода Idle -> Left. Запускается только для пользователя со статусом Idle
      if (curStatus === MPUserStatus.Idle) {
        idleToLeftTimer = setTimeout(() => setCurStatus(MPUserStatus.Left), IDLE_TO_LEFT_TIME)
      }
      window.focus()
    }

    // Отправка нового статуса
    publishWithStatus(curStatus)
    // Запуск интервальной отправки текущего статуса.
    const interval =
      curStatus !== MPUserStatus.Left && setInterval(() => publishWithStatus(curStatus), PING_INTERVAL_TIME)
    // Регистрация отслеживания активности.
    document.addEventListener('mousemove', updateLastTimeActive)
    document.addEventListener('keydown', updateLastTimeActive)
    return () => {
      interval && clearInterval(interval)
      document.removeEventListener('mousemove', updateLastTimeActive)
      document.removeEventListener('keydown', updateLastTimeActive)
      activeToIdleTimer && clearTimeout(activeToIdleTimer)
      idleToLeftTimer && clearTimeout(idleToLeftTimer)
    }
  }, [curStatus, caseId])

  return (
    <UserStatusContext.Provider value={{ subscribeToUser, targetUserId, unsubscribeFromUser, usersStatuses }}>
      {children}
    </UserStatusContext.Provider>
  )
}

export default UserStatusProvider
