import styled from 'styled-components'

import { GroupHeader } from './TissueGroup'

const ColorMarker = styled.div<{ color: string }>`
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
  background: ${({ color }) => color};
  border-radius: 2px;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-1);
`

const THOUSAND = 1000
const ZEROS_IN_THOUSAND = 3

/** Props for TissueClassElement */
type Props = {
  /** Площадь полигона в мм2 */
  area: number
  /** Цвет полигона */
  color: string
  /** Русскоязычное наименование класса */
  title: string
  /** Табличное наименование класса */
  class_name: string
  /** Для классов Гл 3-5 существует parent класс */
  parent?: {
    /** Суммарная площадь глисон полигонов для столбика в мм2 */
    area: number
    /** Русскоязычное наименование класса */
    title: string
  }
  /** Является ли класс ацинарной аденокарциомой */
  isParent?: boolean
  /** Коллбэк для события onMouseEnter */
  onMouseEnterHandle: (val: string) => void
  /** Коллбэк для события onMouseLeave */
  onMouseLeaveHandle: () => void
  /** Коллбэк для события onClick */
  onClickHandle: (val: string) => void
}
const TissueClassElement: React.FC<Props> = ({
  area,
  class_name,
  color,
  isParent,
  onClickHandle,
  onMouseEnterHandle,
  onMouseLeaveHandle,
  parent,
  title,
}) => (
  <GroupHeader
    onMouseLeave={() => onMouseLeaveHandle()}
    onMouseEnter={() => onMouseEnterHandle(class_name)}
    onClick={() => onClickHandle(isParent ? 'parent' : class_name)}
    style={{ marginLeft: parent ? '8px' : 0 }}
  >
    <ColorMarker color={color} />
    <Text style={{ flex: '1' }}>{title}</Text>
    <Text>
      {(area / THOUSAND / THOUSAND).toFixed(ZEROS_IN_THOUSAND) === '0.000'
        ? '< 0.001'
        : (area / THOUSAND / THOUSAND).toFixed(ZEROS_IN_THOUSAND)}
    </Text>
  </GroupHeader>
)

export default TissueClassElement
