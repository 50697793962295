import { useLocation } from 'react-router-dom'

/** Example: const params = useQuery()
 * params.get('foo') // bar
 */
const useQueryParams = () => new URLSearchParams(useLocation().search)
export const useManagementTab = <T extends string>() => {
  const queryParams = useQueryParams()
  return queryParams.get('tab') as T
}
export default useQueryParams
