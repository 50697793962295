import { useTypedSelector } from 'app/redux/lib/selector'
import { useDeletePermissionMutation, useGrantPermission, usePermissionsQuery } from 'entities/permissions'
import { useChangePermissionMutation, useUsersQuery } from 'entities/permissions/api/query'
import { useCaseQuery } from 'features/cases/api/query'
import { useCurrentWorkspaceId } from 'features/workspace/lib'

import { ShareForm } from './ui/ShareForm'

type Props = {
  caseId: number
  modal: boolean
  setModal: (modal: boolean) => void
}

export const ShareFormContainer = ({ caseId, modal, setModal }: Props) => {
  const { data: caseRecord } = useCaseQuery({ caseId, source: 'PLATFORM' })
  const workspaceId = useCurrentWorkspaceId()
  const { data: users } = useUsersQuery(String(workspaceId))
  const { isLoading: isDeleting, mutate: onDelete } = useDeletePermissionMutation(caseId)
  const { mutate: onChangeLevel } = useChangePermissionMutation(caseId)
  const { isLoading: isSending, mutateAsync: onSubmit } = useGrantPermission(caseId)
  const currentUser = useTypedSelector((state) => state.user.user)
  const { data: permissions, isFetching, isLoading } = usePermissionsQuery(caseId)

  if (!caseRecord) return null

  return (
    <ShareForm
      users={users}
      caseRecord={caseRecord}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isLoading={isLoading}
      isSending={isSending}
      onDelete={onDelete}
      onChangeLevel={onChangeLevel}
      onSubmit={onSubmit}
      permissions={permissions}
      currentUser={currentUser}
      modal={modal}
      setModal={setModal}
    />
  )
}
