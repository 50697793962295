import { Tabs } from 'antd'
import type { TabPaneProps, TabsProps } from 'antd/es/tabs'
import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'

type Props = {
  tabs?: Array<
    {
      content: ReactNode
    } & TabPaneProps
  >
} & TabsProps

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    line-height: 16px;
    padding: 0;
    margin: 0;
  }
  .ant-tabs-nav {
    padding: 16px 0;
    margin: 0 16px;
    border-bottom: 1px solid var(--color-border-1);
  }
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-nav-more {
    padding: 0 !important;
  }
`

export const TabsComponent: FC<Props> = ({ children, tabs, ...rest }) => (
  <StyledTabs tabBarGutter={15} {...rest}>
    {tabs
      ? tabs.map(({ content, ...tabProps }) => (
          <Tabs.TabPane key={tabProps.tabKey} {...tabProps}>
            {content}
          </Tabs.TabPane>
        ))
      : children}
  </StyledTabs>
)

export const TabPaneComponent = Tabs.TabPane
