import { CSSProperties, forwardRef, ReactNode, useEffect, useLayoutEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector'

type Props = {
  leftPanel?: boolean
  style?: CSSProperties
  measure: () => void
  children: ReactNode
}
const VirtualListItem = forwardRef<HTMLDivElement, Props>(({ children, leftPanel, measure, style }, outerRef) => {
  const { height, ref } = useResizeDetector()
  useLayoutEffect(() => {
    !leftPanel && measure()
  }, [height, measure])
  useEffect(() => {
    leftPanel && measure()
  }, [height, measure])
  return (
    <div ref={outerRef} style={style}>
      <div ref={ref as any}>{children}</div>
    </div>
  )
})

export default VirtualListItem
