import styled from 'styled-components'

const StyledVerticalSeparator = styled.div`
  width: 2px;
  height: 24px;
  background: var(--color-border-1);
`
const StyledVerticalSeparatorSmall = styled.div`
  width: 1px;
  height: 16px;
  background: var(--color-border-1);
`

export const VerticalSeparator = ({ ...props }) => <StyledVerticalSeparator {...props} />
export const VerticalSeparatorSmall = ({ ...props }) => <StyledVerticalSeparatorSmall {...props} />
