import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export const initSentry = () => {
  if (global.window.SENTRY_DSN && global.window.SENTRY_ENV) {
    if (global.window.SENTRY_ENV === 'local') return
    Sentry.init({
      dsn: global.window.SENTRY_DSN,
      environment: global.window.SENTRY_ENV,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new Sentry.Integrations.Breadcrumbs({ console: false }),
      ],
      normalizeDepth: 10,

      release: process.env.SENTRY_RELEASE,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    })
  } else {
    console.error('Sentry is not initialized! Set SENTRY_DSN and SENTRY_ENV properties (public/config.js)')
  }
}
