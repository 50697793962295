import { RadioChangeEvent } from 'antd/es/radio/interface'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { push } from 'connected-react-router'
import { CaseInfoContainer } from 'features/case-info'
import { useCaseQuery } from 'features/cases/api/query'
import { CommentsListContainer } from 'features/comments'
import { useNotesQuery } from 'features/notes/api/query'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import { RightPanelMode } from 'pages/viewer/ui/common/ViewerRightPanel'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { IconElement, Scroll, SegmentElement } from 'shared/ui/kit'
import { TabPaneComponent, TabsComponent } from 'shared/ui/tabs'
import styled from 'styled-components/macro'
import { SettingsNameEnum } from 'types/IWorkspaces'

const StyledPanel = styled.div`
  min-width: 264px;
  width: 264px;
  overflow: auto;
  background-color: var(--color-bg-2);
  border-left: 1px solid var(--color-border-1);
  height: 100%;
  position: relative;
`
const StyledCommentGrid = styled.div<{ isComments?: boolean; isNotes?: boolean }>`
  display: ${({ isComments }) => (isComments ? 'grid' : 'block')};
  grid-template-rows: ${({ isComments }) => (isComments ? ' auto 1fr' : 'none')};
  height: ${({ isNotes }) => (isNotes ? 'auto' : '100%')};
`
const IconBox = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

type Props = {
  caseId: number
  onChangeTab: (tab: 'INFO' | 'COMMENTS') => void
  activeTab: 'INFO' | 'COMMENTS'
}
const CasesRightPanel = ({ activeTab, caseId, onChangeTab }: Props) => {
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const { data: caseRecord } = useCaseQuery({ caseId: caseId, source: 'PLATFORM' })
  const workspaceId = useCurrentWorkspaceId() || ''
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const relation = queryParams.get('rel') as 'owner' | 'shared'
  const isArchive = caseRecord?.status === 'ARCHIVE'
  const { data: notesData, isLoading: isLoadingNotes } = useNotesQuery(caseId, false)
  const isCaseNotes =
    notesData &&
    notesData.length > 0 &&
    activeTab === SideRightPanelType.COMMENTS &&
    getBooleanOrDefaultAccess(SettingsNameEnum.LisMode, false, currentWorkspace)

  useEffect(() => {
    //Сбрасывать состояние SegmentState при отсутствии notesData, оставлять при isLoadingNotes
    if (!(notesData && notesData.length > 0) && !isLoadingNotes) setSegmentState(RightPanelMode.COMMENTS)
  }, [notesData])
  const closePanel = () => {
    if (isArchive) {
      dispatch(push(`/workspace/${workspaceId}/archive?rel=${relation}`))
    } else {
      dispatch(push(`/workspace/${workspaceId}/cases?rel=${relation}`))
    }
  }

  const [segmentState, setSegmentState] = useState(RightPanelMode.COMMENTS)

  const segmentObject: { value: string; label: string }[] = [
    { label: t('Комментарии'), value: RightPanelMode.COMMENTS },
    { label: t('Заметки'), value: RightPanelMode.NOTES },
  ]
  const onSegmentChange = (e: RadioChangeEvent) => {
    if (setSegmentState) {
      setSegmentState(e.target.value)
    }
  }

  const theme = useThemeContext()

  return (
    <StyledPanel>
      <TabsComponent
        data-testid="right-panelcase-name"
        activeKey={activeTab}
        style={{ height: '100%' }}
        onChange={onChangeTab as any}
      >
        <TabPaneComponent tab={t('О случае')} key="INFO">
          <Scroll>
            <div style={{ width: 256 }}>
              <CaseInfoContainer caseId={caseId} source="PLATFORM" />
            </div>
          </Scroll>
        </TabPaneComponent>
        <TabPaneComponent tab={t('Комментарии')} key="COMMENTS">
          <StyledCommentGrid
            isComments={segmentState === RightPanelMode.COMMENTS && isCaseNotes}
            isNotes={segmentState === RightPanelMode.NOTES}
          >
            {isCaseNotes && (
              <SegmentElement
                style={{ margin: `8px 16px` }}
                options={segmentObject}
                value={segmentState}
                onChange={onSegmentChange}
                colorTheme={theme.theme}
              />
            )}
            <CommentsListContainer caseId={caseId} viewerId="A" source="PLATFORM" segmentState={segmentState} />
          </StyledCommentGrid>
        </TabPaneComponent>
      </TabsComponent>
      <IconBox>
        <IconElement name="cross" onClick={closePanel} />
      </IconBox>
    </StyledPanel>
  )
}

export default CasesRightPanel
