import { Divider, Input } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { BaseSyntheticEvent, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CustomInputElement } from 'shared/ui/kit/ui/CustomInputElement'
import styled from 'styled-components'
import { useAnnotationAuthorValidation } from 'viewer/map/layers/objects-counting/lib/hooks/useAnnotationAuthorValidation'
import { useObjectsCountingContext } from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

import { countingTypesText } from './countingTypes'

const Title = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 4px 0px;
`

const ReduceRow = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
  }
`

const Count = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  max-width: calc(100% - 64px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const StyledInputNumber = styled(CustomInputElement)`
  background-color: rgb(58 49 62 / 0%) !important;
`
const StyledPrefix = styled(Input)`
  user-select: none;
  height: 24px;
  background-color: rgb(58 49 62 / 0%) !important;
  padding: 0;
  margin-right: 10px;
  width: 24px;
`

const ObjectsReducedCount: React.FC = memo(() => {
  const { getTotalMitosisCount, totalAreaCount } = useObjectsCountingContext()
  const isAuthorValid = useAnnotationAuthorValidation()
  const { t } = useTranslation()
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType) || 'OBJECTS'

  const dispatch = useDispatch()
  const [countingArea, setCountingArea] = useState(0)
  const [isInputUntouched, setIsInputUntouched] = useState(true)

  const setIsAnyInputFocusing = (value: boolean) => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  }

  const onChange = (e: BaseSyntheticEvent) => {
    setIsInputUntouched(false)
    const value = e.target.value
    const reg = value.toString().replace(/[^.\d]/g, '')
    if (String(value).length < 3) {
      setCountingArea(+reg)
    }
  }

  const onArrowChange = (value: string) => {
    setIsInputUntouched(false)
    setCountingArea(+value)
  }

  const getMitosisReducedCount = () => {
    if (!totalAreaCount) return 0
    const totalMitosisInSpots = getTotalMitosisCount()
    const mitosisProportional = Math.floor(countingArea * (totalMitosisInSpots / totalAreaCount))
    return mitosisProportional
  }

  useEffect(() => {
    isInputUntouched && setCountingArea(totalAreaCount)
  }, [totalAreaCount, isInputUntouched])
  return (
    <div>
      <Divider />
      <Title>
        {t('Пересчитать')} {t(countingTypesText[countingObjectType].textForCounting)} {t('на площадь')}
      </Title>
      <ReduceRow>
        <Count>{isInputUntouched ? getTotalMitosisCount() : getMitosisReducedCount()}</Count>
        <Count>{t('на')}</Count>
        <StyledInputNumber
          style={{ width: 22 }}
          min={1}
          max={99}
          maxLength={6}
          disabled={!isAuthorValid}
          isArrowVisible={isAuthorValid}
          value={isInputUntouched ? totalAreaCount : countingArea}
          onChange={onChange}
          suffixElement={<StyledPrefix disabled={!isAuthorValid} suffix={' ' + t('мм²')} />}
          onFocus={(e: BaseSyntheticEvent) => {
            setIsAnyInputFocusing(true)
            e.target.select()
          }}
          onBlur={() => {
            setIsAnyInputFocusing(false)
          }}
          onClickIcon={onArrowChange}
        />
      </ReduceRow>
    </div>
  )
})

export default ObjectsReducedCount
