import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Patient = styled.div<{ isActive: boolean }>`
  display: grid;
  column-gap: 8px;
  grid-template-areas: 'color fullname btn' '_ type btn';
  grid-template-columns: 8px 1fr auto;
  padding: 8px;

  border-radius: 5px;
  background: ${({ isActive }) => (isActive ? 'var(--color-bg-3)' : 'transparent')};
  &:hover {
    background: var(--color-bg-3);
    cursor: pointer;
  }
`

const Color = styled.div`
  width: 8px;
  height: 8px;
  place-self: center;
  grid-area: color;
  border-radius: 50%;
  background-color: var(--color-green);
`

const FullName = styled.div`
  grid-area: fullname;
  font-size: 12px;
  line-height: 16px;
`
const Type = styled.div`
  grid-area: type;
  font-size: 11px;
  line-height: 12px;
  color: var(--color-text-3);
`

const ButtonWrapper = styled.div`
  grid-area: btn;
  svg {
    transform: rotate(0.75turn);
  }
`

type Props = {
  fullname: string
  type: string
  color: string
  isActive: boolean
  onClick: () => void
}
const PatientListItem = ({ color, fullname, isActive, onClick, type }: Props) => (
  <Patient onClick={onClick} isActive={isActive}>
    <Color style={{ backgroundColor: color }} />
    <FullName>{fullname}</FullName>
    <Type>{type}</Type>
    <ButtonWrapper>
      <IconElement name="dropDown" />
    </ButtonWrapper>
  </Patient>
)

export default PatientListItem
