import { Form } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import AnnotationButtonContainer from 'features/comments/AnnotationButtonContainer'
import { TOOLBAR_BTN_OPACITY } from 'features/toolbar/lib/constants'
import { viewerPageSlice } from 'pages/viewer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { parseErrorsFromServerForm } from 'shared/lib/form'
import { ButtonElement, SpaceElement, SpinElement, TextAreaElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const { Item, useForm } = Form

const StyledComments = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  height: 100%;
  overflow: auto;
`
const WideButton = styled(ButtonElement)<{ disable?: boolean }>`
  opacity: ${({ disable }) => (disable ? TOOLBAR_BTN_OPACITY : undefined)};
  width: 100%;
`
const StyledItem = styled(Item)`
  div {
    max-width: 100%;
  }
`

const NewComment = styled.div`
  padding: 16px;
  box-shadow: 0px 0px 16px var(--color-bg-3), inset 0px 1px 0px var(--color-bg-3);
`

const CommentActions = styled.div<{ withAttachments: boolean }>`
  display: grid;
  grid-template-columns: ${({ withAttachments }) => (withAttachments ? '1fr auto' : '100%')};
  width: 100%;
  grid-gap: 8px;
`

type Props = {
  onSendComment: (text: string) => Promise<any>
  isDisabled?: boolean
  isCommentMode?: boolean
  setCommentMode: (mode: 'new' | null) => void
  withAttachments: boolean
}

const CommentsList: FC<Props> = ({
  children,
  isCommentMode,
  isDisabled,
  onSendComment,
  setCommentMode,
  withAttachments,
}) => {
  const dispatch = useDispatch()

  const [newCommentValue, setCommentValue] = useState('')
  const [isLoading, setLoading] = useState(false)

  const { isAnyInputFocusing } = useTypedSelector((state) => state.viewerPage)

  const toggleInputFocus = () => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(!isAnyInputFocusing))

  const { t } = useTranslation()

  const onSendClick = async () => {
    form.getFieldValue('body')
    try {
      setLoading(true)
      await onSendComment(form.getFieldValue('body'))
      setCommentValue('')
      form.resetFields()
      setCommentMode(null)
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      if (e?.response?.status === 400) {
        form.setFields(parseErrorsFromServerForm(e.response.data.errors))
      }
    }
  }
  const [form] = useForm()

  useEffect(() => {
    if (!isCommentMode && isAnyInputFocusing) toggleInputFocus()
  }, [isCommentMode])

  return (
    <StyledComments>
      {children}
      {isCommentMode ? (
        <SpinElement spinning={isLoading}>
          <NewComment>
            <Form
              form={form}
              layout="horizontal"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              colon={false}
              onFieldsChange={(changed) => {
                form.setFields(changed.map((field) => ({ ...field, errors: [] })))
              }}
            >
              <StyledItem name="body">
                <TextAreaElement
                  onFocus={toggleInputFocus}
                  maxLength={10240}
                  autoFocus
                  onBlur={toggleInputFocus}
                  autoSize={{ maxRows: 5, minRows: 5 }}
                  onChange={(e) => setCommentValue(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && onSendClick()}
                  draggable={false}
                />
              </StyledItem>
            </Form>
            {newCommentValue.length > 10240 && (
              <TextElement type="danger">
                {t('Максимум 10240 символов')} ({newCommentValue.length})
              </TextElement>
            )}
            <SpaceElement size={8} />
            <CommentActions withAttachments={withAttachments}>
              <WideButton type={newCommentValue ? 'primary' : 'default'} onClick={onSendClick}>
                {t('Опубликовать')}
              </WideButton>
              {withAttachments ? <AnnotationButtonContainer isDisabled={isDisabled} linkedComment="new" /> : null}
            </CommentActions>
          </NewComment>
        </SpinElement>
      ) : (
        <div style={{ padding: 16 }}>
          <CommentActions withAttachments={withAttachments}>
            <WideButton disable={isDisabled} onClick={() => setCommentMode('new')} disabled={isDisabled}>
              {t('Комментировать')}
            </WideButton>
            {withAttachments ? <AnnotationButtonContainer isDisabled={isDisabled} linkedComment="new" /> : null}
          </CommentActions>
        </div>
      )}
    </StyledComments>
  )
}

export default CommentsList
