import { useMutateSlideViewed, useSlideViewedQuery } from 'entities/slide-layers'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import TViewerId from 'types/TViewerId'
import { SlideLayerContext } from 'viewer/map/layers/slide/SlideLayerProvider'
import { getFeaturesFromGeoJson } from 'viewer/map/lib/utils'

import EditBox from './EditBox'
import OverviewMap from './OverviewMap'

interface Props {
  /** viewerId - id вьювера */
  viewerId: TViewerId
  /** slideId - id слайда в текущем вьювере */
  slideId: number
  /** caseId - id случая */
  caseId: number
  /** viewRotation - угол поворота карты во вьювре */
  viewRotation?: number
  /** backGroundSizes - параметры блока подложки миникарты */
  backGroundSizes: number[]
  /** setBackGroundSizes - установка параметров блока подложки миникарты */
  setBackGroundSizes: (a: number[]) => void
  /** mapSizes - параметры миникарты */
  mapSizes: number[]
  /** setMapSizes - установка параметров миникарты */
  setMapSizes: (a: number[]) => void
  /** resizeIsActive - стейт операции onResize */
  resizeIsActive: boolean
}

const OverviewMapContainer: FC<Props> = ({
  backGroundSizes,
  caseId,
  mapSizes,
  resizeIsActive,
  setBackGroundSizes,
  setMapSizes,
  slideId,
  viewRotation,
  viewerId,
}) => {
  const map = useViewerIdMap(viewerId)

  const { config } = useContext(SlideLayerContext)
  const { data: slideViewed, isFetched } = !isNaN(caseId)
    ? useSlideViewedQuery(caseId, slideId)
    : { data: undefined, isFetched: false }
  const { mutate } = useMutateSlideViewed(caseId, slideId)

  const controllViewCallback = useCallback(
    (formattedFeatures: any) => {
      mutate(formattedFeatures)
    },
    [caseId, slideId],
  )

  const initialViewedFeatures = useMemo(
    () => (slideViewed?.data ? getFeaturesFromGeoJson(slideViewed?.data) : undefined),
    [slideViewed?.data],
  )

  return config ? (
    <EditBox
      rotation={viewRotation}
      mapSizes={mapSizes}
      setBackGroundSizes={setBackGroundSizes}
      backGroundSizes={backGroundSizes}
      resizeIsActive={resizeIsActive}
    >
      <OverviewMap
        setBackGroundSizes={setBackGroundSizes}
        mapSizes={mapSizes}
        setMapSizes={setMapSizes}
        map={map}
        slideLayerConfig={config}
        viewerId={viewerId}
        slideId={slideId}
        initialViewedFeatures={isFetched && initialViewedFeatures}
        controllViewCallback={controllViewCallback}
      />
    </EditBox>
  ) : null
}
export default OverviewMapContainer
