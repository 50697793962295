import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

type Props = {
  path: string
  title: string
  active: boolean
}
const activeCss = css`
  background: var(--color-purple);

  & a {
    color: white;
  }
`

const StyledMenuItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  transition: color 0.3s;

  & a {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-3);
    text-decoration: none;
    width: 100%;
    padding: 6px 8px;
    transition: none;
  }

  &:hover {
    a {
      color: ${({ active }) => !active && 'var(--color-text-1)'};
    }
  }

  ${(props) => props.active && activeCss}
`

const SumpMenuItem: React.FC<Props> = memo(({ active, path, title }) => (
  <StyledMenuItem active={active}>
    <Link to={path}>{title}</Link>
  </StyledMenuItem>
))

export default SumpMenuItem
