import { useTranslation } from 'react-i18next'
import { ButtonElement } from 'shared/ui/kit'
import styled from 'styled-components'
import ICase from 'types/ICase'

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > *:first-child {
    margin-bottom: 10px;
  }
`

type Props = {
  onClose: () => void
  search: string
  onMoveUploadFiles: (targetCase?: ICase) => Promise<void>
}

const CaseCreationAlertPanel = ({ onClose, onMoveUploadFiles, search }: Props) => {
  const onClickHandle = () => {
    onMoveUploadFiles().finally(() => onClose())
  }
  const { t } = useTranslation()
  return (
    <>
      <TextWrapper>
        {t('Будет создан новый случай')} <br /> {t('с именем')} {search}
      </TextWrapper>
      <ButtonsWrapper>
        <ButtonElement type={'default'} onClick={onClose}>
          {t('Выбрать уже существующий случай')}
        </ButtonElement>
        <ButtonElement type={'primary'} onClick={onClickHandle}>
          {t('Продолжить')}
        </ButtonElement>
      </ButtonsWrapper>
    </>
  )
}

export default CaseCreationAlertPanel
