import { ICasePermission, IPermissionLevel } from 'entities/permissions'
import { useTranslation } from 'react-i18next'
import { EmptyElement, Scroll, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase, { ICaseRelation } from 'types/ICase'
import IUser from 'types/IUser'

import { UserInfo } from './UserInfo'

type Props = {
  /** данные случая */
  caseRecord: ICase
  /** функция для удаления пользователя */
  handleDelete: (casePermissionId: number) => void
  /** фукнция для изменения уровня пользователя */
  handleLevelChange: (permissionId: number, permissionLevel: IPermissionLevel) => void
  /** флаг отслеживающий загрузку */
  isLoading: boolean
  /** флаг отслеживающий запрос */
  isFetching: boolean
  /** информация о доступах пользователей */
  otherPermissions?: ICasePermission[]
  /** данные о текущем пользователе */
  currentUser?: IUser
}

export const LinkedUsers = ({
  caseRecord,
  currentUser,
  handleDelete,
  handleLevelChange,
  isFetching,
  isLoading,
  otherPermissions,
}: Props) => {
  const { t } = useTranslation()

  return (
    <SpinElement spinning={isFetching && !isLoading}>
      <StyledScroll style={{ overflowX: 'hidden', overflowY: 'auto' }}>
        {caseRecord?.user && (
          <UserInfo
            numberValue={caseRecord?.user.userId}
            fullname={caseRecord?.user.fullname}
            secondaryText={caseRecord?.workspace?.name}
            iconShow={false}
            stateText={t('Создатель')}
          />
        )}
        {otherPermissions?.map((permission, index) => {
          const { casePermissionId, contact, permissionLevel, sourceUser, targetUser, targetWorkspace, type } =
            permission

          /** флаг определяющий доступна ли кнопка изменения удаления пользователя */
          const deleteUserDisabled = !(
            sourceUser?.userId === currentUser?.userId || caseRecord?.relation === ICaseRelation.OWNER
          )
          /** флаг определяющий доступна ли кнопка изменения уровня пользователя */
          const isChangedLevels =
            targetWorkspace?.workspaceId === caseRecord?.workspace?.workspaceId ||
            caseRecord?.relation !== ICaseRelation.OWNER
          let numberValue, stateText
          if (type === 'USER_ACQUIRED') {
            numberValue = targetUser.userId
          } else {
            numberValue = index + 1
            stateText = t('Приглашение отправлено')
          }

          return (
            <UserInfo
              key={casePermissionId}
              permissionId={casePermissionId}
              isChangedLevels={isChangedLevels}
              numberValue={numberValue}
              permissionLevel={permissionLevel}
              fullname={type === 'USER_ACQUIRED' ? targetUser.fullname : contact || targetUser?.fullname}
              secondaryText={targetWorkspace?.name}
              iconShow={true}
              iconDisabled={deleteUserDisabled}
              handleLevelChange={handleLevelChange}
              onClickIcon={() => handleDelete(casePermissionId)}
              stateText={stateText}
            />
          )
        })}

        {!caseRecord.user && otherPermissions?.length === 0 && (
          <EmptyElement
            style={{ marginBottom: 16, marginTop: 8 }}
            description={<span>{t('Случай никто не отслеживает')}</span>}
          />
        )}
      </StyledScroll>
    </SpinElement>
  )
}

const StyledScroll = styled(Scroll)`
  max-height: calc(100vh - 234px);
`
