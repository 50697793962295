import { ReactComponent as LogoIcon } from 'assets/icons/logo/logo.svg'
import { Link } from 'react-router-dom'

const Logo = () => (
  <Link style={{ display: 'flex' }} to="/">
    <LogoIcon style={{ color: 'var(--color-text-1)' }} />
  </Link>
)

export default Logo
