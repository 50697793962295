import { useTypedSelector } from 'app/redux/lib/selector'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IconElement, TooltipElement } from 'shared/ui/kit'
import { ToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import { VerticalSeparator } from 'shared/ui/kit/ui/VerticalSeparator'
import styled from 'styled-components'
import { IAnnotation } from 'types/IAnnotations'

const PanelBlock = styled.div`
  position: relative;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const MINIMAL_COORDINATES_LENGTH = 10
export const SimplifyButton = () => {
  const { t } = useTranslation()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const queryClient = useQueryClient()
  const selectedAnnotationId = useTypedSelector((state) => state.viewers[viewerId].viewer.selectedAnnotationsIds[0])
  const isTolerance = !!useTypedSelector((state) => state.viewerPage.geometryTolerance)
  const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, selectedAnnotationId])
  const type = annotation?.type
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  const isDisable = true
  // Скрыто в рамках ONECELL-4719
  // !!annotationType ||
  // ![AnnotationType.PEN, AnnotationType.POLYGON].includes(type as AnnotationType) ||
  // (annotation && MINIMAL_COORDINATES_LENGTH >= getCoordinatesLength(simplifyOperation(1, annotation)))

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const modalHandler = () => {
    annotation && setModalIsOpen(!modalIsOpen)
  }
  useEffect(() => {
    setModalIsOpen(false)
  }, [annotation])
  const modalCloseHandler = () => {
    setModalIsOpen(false)
  }
  return (
    <>
      <VerticalSeparator />
      <PanelBlock>
        <TooltipElement title={isDisable ? t('Инструмент недоступен') : t('Упрощение геометрии')}>
          <ToolbarButton
            active={modalIsOpen || isTolerance}
            onClick={modalHandler}
            icon={<IconElement name={'simplification'} />}
            disable={isDisable}
            disabled={isDisable}
          />
        </TooltipElement>
        {
          // Скрыто в рамках ONECELL-4719
          /*{modalIsOpen && annotation && <SimplifyChangerModal annotation={annotation} close={modalCloseHandler} />}*/
        }
      </PanelBlock>
    </>
  )
}
