export const CONFIG_CASE_PANELS_KEY = 'configCasePanels'
export const VIEWING_STATE_KEY = 'viewingState'
export const AUTHORITIES_KEY = 'authorities'
export const TOKEN_KEY = 'token'
export const INVITE_TOKENS_KEY = 'inviteTokens'
export const EXPIRATION_TIME_KEY = 'expirationTime'
export const USER_KEY = 'user'
export const WORKSPACE_ID_KEY = 'workspaceId'
export const TASKS_SLIDE_DATA = 'tasksSlideData'
/** Пользователь согласен, что на его устройстве платформа может работать некорректно */
export const UNSUPPORTED_DEVICE_ACCEPTED = 'unsupportedDeviceAccepted'
/** Пользователь согласен, что в его браузере платформа может работать некорректно */
export const UNSUPPORTED_BROWSER_ACCEPTED = 'unsupportedBrowserAccepted'
/** Последняя посещенная вкладка из менеджмента случаев */
export const LAST_MANAGEMENT_LOCATION = 'lastManagementLocation'
/** Параметры для запросов сортировки табов */
export const TABS_QUERY_PARAMS_KEY = 'tabsQueryParams'
/** Ключ для хранения данных текущего состояния слайда перед первым переключением на другой случай пациента. */
export const ORIGIN_CASE_DATA_KEY = 'originCaseData'
/** Ключ для хранения данных состояния слайдов перед последующими переключениями на другие случаи пациента. */
export const ORIGIN_CASES_DATA_KEY = 'originCasesData'
