import { modalBackground } from 'app/styled/GlobalStyles'
import styled, { FlattenSimpleInterpolation } from 'styled-components'

/** Props for StyledDraggable component */
interface Props {
  /** top - координата по оси OY */
  top: number
  /** left - координата по оси OX */
  left: number
  /** isMagnetize - "примагничена" ли текущая панель */
  isMagnetize?: boolean
  /** canBeMagnetize - сигнал, что панель можно примагнитить, если отпустить */
  canBeMagnetize?: boolean
  /** zIndex - zIndex текущей панели */
  zIndex?: number
  /** style - дополнительные стили для текущей панели */
  extraStyle?: FlattenSimpleInterpolation
  /** Размеры миникарты */
  mapSizes?: number[]
  /** Настройка для fit-content стиля, требуется для миникарты */
  isFitContent?: boolean
  /** slideInfo имеет свой padding */
  isSlideInfo?: boolean
}

export const StyledDraggable = styled.div<Props>`
  width: ${({ isFitContent, mapSizes }) =>
    isFitContent ? 'fit-content' : mapSizes ? `${mapSizes[0] + 32}px` : `248px`};
  z-index: ${({ zIndex }) => zIndex};
  border-left: ${({ canBeMagnetize }) => (canBeMagnetize ? '2px solid #4099F7' : undefined)};
  border-top: ${({ canBeMagnetize }) => (canBeMagnetize ? '2px solid #4099F7' : undefined)};
  position: ${({ isMagnetize }) => (isMagnetize ? 'relative' : 'fixed')};
  top: ${({ isMagnetize, top }) => (isMagnetize ? 0 : top + 'px')};
  left: ${({ isMagnetize, left }) => (isMagnetize ? 0 : left + 'px')};
  ${modalBackground}
  box-shadow: 0 0 0 1px var(--color-modal-outline);
  border-radius: 5px;
  touch-action: none;
  padding: ${({ canBeMagnetize, isSlideInfo }) =>
    isSlideInfo
      ? canBeMagnetize
        ? '6px 16px 16px 14px'
        : '8px 16px 16px'
      : canBeMagnetize
      ? '6px 8px 8px 6px'
      : '8px'};
  ${({ extraStyle }) => extraStyle}
`
