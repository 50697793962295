/**
 * Module description
 */

import { createContext, useContext } from 'react'

type TEventName =
  | 'corr:center'
  | 'corr:zoom'
  | 'corr:rotation'
  | 'afterMapCenter'
  | 'afterMapZoom'
  | 'afterMapRotation'
  | 'tool:zoom'
  | 'tool:rotate'

type TEventsMap = Array<(...args: any) => void>

export class _EventBus {
  bus: Map<TEventName, TEventsMap>
  constructor() {
    this.bus = new Map()
  }

  $removeEventListener(id: TEventName, callback: (...args: any) => void) {
    if (!this.bus.has(id)) return
    const listeners = this.bus.get(id)
    if (listeners === undefined) return
    this.bus.set(
      id,
      listeners.filter((listener) => listener !== callback),
    )
  }

  $addEventListener(id: TEventName, callback: (...args: any) => void) {
    const callbacks = this.bus.get(id)
    if (callbacks === undefined) {
      this.bus.set(id, [callback])
    } else {
      this.bus.set(id, [callback, ...callbacks])
    }
  }

  $emit(id: TEventName, ...params: any[]) {
    const callbacks = this.bus.get(id)
    if (callbacks === undefined) return
    callbacks.forEach((cb) => cb(...params))
  }
}

const bus = new _EventBus()

const EventBusContext = createContext(bus)

export const useEventBusProvided = () => useContext(EventBusContext)

type Props = {
  children: any
}
export const EventBusProvider = ({ children }: Props) => (
  <EventBusContext.Provider value={bus}>{children}</EventBusContext.Provider>
)
