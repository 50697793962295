import React, { memo } from 'react'
import { IconElement } from 'shared/ui/kit'
import { ToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import styled from 'styled-components'
import { ObjectsCountingMethod } from 'viewer/container/model/viewerSlice'
import { useAnnotationAuthorValidation } from 'viewer/map/layers/objects-counting/lib/hooks/useAnnotationAuthorValidation'

type Props = {
  name: ObjectsCountingMethod
  label: string
  setCountingMethod: (method: ObjectsCountingMethod) => void
  collapsed: boolean
  active: boolean
  withSup?: boolean
  animate?: boolean
}

const Option = styled.div<{ collapsed: boolean; disable: boolean }>`
  padding: ${({ collapsed }) => (collapsed ? '0px' : '4px')};
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  margin-bottom: ${({ collapsed }) => (collapsed ? '0px' : '4px')};
  margin-right: ${({ collapsed }) => (collapsed ? '12px' : '0px')};
  border-radius: 5px;
  transition: background-color ease-in 0.2s;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: ${({ collapsed, disable }) => (collapsed || disable ? '' : 'var(--color-bg-3)')};
    transition: background-color ease-out 0.2s;
  }

  & > *:first-child {
    margin-right: ${({ collapsed }) => (collapsed ? '0px' : '8px')};
  }
`

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const StyledIconElement = styled(IconElement)<{ disable?: boolean }>`
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
`

const AnimatedIconElement = styled(IconElement)`
  & > path {
    animation-name: borderAnimation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    fill: var(--color-text-1);
  }
  & > circle {
    animation-name: centerAnimation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    fill: #dc524c;
  }

  @keyframes borderAnimation {
    0% {
      fill: var(--color-text-1);
      opacity: 100%;
    }
    50% {
      fill: var(--color-text-1);
      opacity: 20%;
    }
    70% {
      fill: var(--color-text-1);
      opacity: 100%;
    }
  }
  @keyframes centerAnimation {
    0% {
      r: 3;
    }
    50% {
      r: 6;
    }
    80% {
      r: 3;
    }
  }
`

const CountingOption: React.FC<Props> = memo(
  ({ active, animate, collapsed, label, name, setCountingMethod, withSup }) => {
    const isAuthorValid = useAnnotationAuthorValidation()

    const onClick = () => {
      if (isAuthorValid) setCountingMethod(name)
    }
    return (
      <Option disable={!isAuthorValid} onClick={onClick} collapsed={collapsed}>
        {collapsed ? (
          <ToolbarButton
            disable={!isAuthorValid}
            active={active}
            icon={animate && isAuthorValid ? <AnimatedIconElement name={name} /> : <IconElement name={name} />}
          />
        ) : (
          <>
            <StyledIconElement disable={!isAuthorValid} name={name} fill="var(--color-text-3)" />
            <Label>{withSup ? label + '²' : label}</Label>
          </>
        )}
      </Option>
    )
  },
)

export default CountingOption
