import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useCaseQuery, useChangeCaseStatusMutation, useDeleteCaseMutation } from 'features/cases/api/query'
import { casesSlice } from 'features/cases/model/casesSlice'
import CaseCard from 'features/cases/ui/CaseCard'
import { getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import { EMyCasesTabType } from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import { memo, useCallback, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getBooleanOrDefaultAccess, useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { SettingsNameEnum } from 'types/IWorkspaces'

type Props = {
  id: number
  selected?: boolean
  relations: string
}

const CaseCardContainer = memo(({ id, relations, selected }: Props) => {
  const dispatch = useDispatch()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace) || null
  const { data: caseRecord } = useCaseQuery({ caseId: id, source: 'PLATFORM' })
  const isArchive = caseRecord?.status === 'ARCHIVE'
  const cardRef = useRef<HTMLDivElement>(null)
  const { menuTab } = useCaseManagementRouteParam()
  const { filterParams, visibleRowsCount } = useCaseManagementContext()
  const { tabsQueryParams } = useCaseManagementTabContext()
  const queryClient = useQueryClient()
  const wsId = Number(useCurrentWorkspaceId())
  const { isCaseRouting } = useSettingsAndUserRoles()

  const onClick = useCallback(() => {
    if (isArchive) {
      dispatch(push(`/workspace/${currentWorkspace?.workspaceId}/archive?caseId=${id}&rel=${relations}`))
    } else {
      dispatch(push(`/workspace/${currentWorkspace?.workspaceId}/cases?caseId=${id}&rel=${relations}`))
    }
  }, [id, relations, isArchive])

  const { mutate: changeStatus } = useChangeCaseStatusMutation(id)
  const { mutate: deleteCase } = useDeleteCaseMutation(id, () =>
    refetchPages(
      queryClient,
      menuTab,
      {
        tab: EMyCasesTabType.FAVORITES,
        ...getQueryParams(tabsQueryParams, menuTab, EMyCasesTabType.FAVORITES, isCaseRouting),
      },
      filterParams,
      wsId,
      visibleRowsCount,
    ),
  )
  const onCommentsClick = useCallback(() => {
    dispatch(casesSlice.actions.setRightPanelTab(SideRightPanelType.COMMENTS))
    onClick()
  }, [onClick])
  const { pathname } = useLocation()

  if (!caseRecord) {
    return null
  }

  return (
    <CaseCard
      ref={cardRef}
      case={caseRecord}
      isSelected={selected}
      onClick={onClick}
      onCommentsClick={onCommentsClick}
      changeStatus={changeStatus}
      access={getBooleanOrDefaultAccess(SettingsNameEnum.CanRemoveCase, true, currentWorkspace)}
      deleteCase={deleteCase as any}
      pathname={pathname}
    />
  )
})

export default CaseCardContainer
