import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { VIEWER_ID_PLACEHOLDER } from 'shared/lib/viewer'

type State = {
  isHelpShown: boolean
  text?: string
}

const initialState: State = {
  isHelpShown: false,
}

// eslint-disable-next-line import/no-named-as-default-member
const t = i18next.t

export const createViewerHelpSlice = (id: string) =>
  createSlice({
    extraReducers: {
      'viewerPageSlice/resetAllViewersState': (state) => initialState,
      [`${id}-viewerAttachmentsSlice/setAttachmentMode`]: (state, { payload }: PayloadAction<boolean>) => {
        state.isHelpShown = payload
        state.text = payload ? t('Кликните по слайду и выделите область для аннотации к комментарию.') : undefined
      },
      [`${id}-viewerSlice/resetViewer`]: (state) => initialState,
    },
    initialState,
    name: id + '-viewerHelpSlice',
    reducers: {
      hideHelpMessage(state, { payload }: PayloadAction<boolean | undefined>) {
        state.isHelpShown = payload || false
      },
      setHelpMessage(state, { payload }: PayloadAction<string>) {
        state.text = payload
        state.isHelpShown = true
      },
    },
  })

export const viewerHelpSlice = createViewerHelpSlice(VIEWER_ID_PLACEHOLDER)
