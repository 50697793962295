import i18next from 'shared/lib/i18n/i18n'

const t = i18next.t

/** Перечисление с типами слоев */
export enum DataLayerTypes {
  /** Слой митозов */
  MITOSIS = 'MITOSIS',
  /** Слой сегментации от PathVision */
  PV_PROSTATE = 'PV_PROSTATE',
  /** Слой детекции артефактов пробоподготовки */
  ARTEFACTS = 'ARTEFACTS',
  /** Слой детекции метастазов колоректального рака в лимфоузлах */
  MEDICALNEURONETS_CRC = 'MEDICALNEURONETS_CRC',
}

/** Перечисление, представляющее статус операции с уровнем данных.
 * @enum {string} */
export enum DatalayerStatus {
  /** Указывает, что операция с уровнем данных в настоящее время выполняется.
   * @type {string} */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Указывает, что операция с уровнем данных успешно завершена.
   * @type {string} */
  DONE = 'DONE',
  /** Указывает, что операция с уровнем данных завершилась неудачей.
   * @type {string} */
  FAILED = 'FAILED',
}

export type ISlideDataLayer = {
  /** Идентификатор слоя данных слайда. */
  slideDataLayerId: number
  /** Представление слоя данных. */
  showAs: string
  /** Тип слоя данных. */
  type: DataLayerTypes
  /** Версия слоя данных. */
  version: number
  /** Флаг, указывающий на пустой результат. */
  emptyResult: boolean
  /** Флаг, указывающий на то, что операция находится в процессе выполнения. */
  inProgress: boolean
  /** Дата и время создания слоя данных. */
  createdAt: string
  /** Статус слоя данных. */
  status?: DatalayerStatus
}

export type ISlideDataLayerGeoJSON = number[][]
