import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { PaginatedAtlasSlidesFilters, useAtlasSlides, usePaginatedAtlasSlidesQuery } from 'entities/atlas'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { ScrollParams } from 'react-virtualized'
import { QUERY_TYPE } from 'shared/api'
import { VirtualInfiniteScroll } from 'shared/ui/infinite-scroll'
import { IconElement, SpinElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IAtlasSlide, IAtlasValidationStatus } from 'types/IAtlasSlide'
import { useViewerDispatch, viewerSlice } from 'viewer/container'

type Props = {
  selectedSlideId: number
}

const ATLAS_SLIDE_STATUS_COLORS = {
  APPROVED: 'var(--color-green)',
  DONE: 'var(--color-blue)',
  INVALID: 'var(--color-red)',
  NOT_VIEWED: 'var(--color-bg-4)',
  PAUSED: 'var(--color-bg-4)',
  REWORK: 'var(--color-red)',
  VALID: 'var(--color-green)',
  WIP: 'var(--color-bg-4)',
}

const AtlasSlideItem = styled.div<{ isActive: boolean; flex: boolean }>`
  display: ${({ flex }) => (flex ? 'flex' : 'grid')};
  grid-template-columns: ${({ flex }) => (flex ? null : ' 1fr auto auto')};
  align-items: ${({ flex }) => (flex ? null : 'center')};
  flex-direction: ${({ flex }) => (flex ? 'column' : null)};
  gap: 8px;
  padding: 4px 8px;
  margin: 4px 0;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ isActive }) => isActive && 'var(--color-purple)'};

  &:hover {
    background: var(--color-purple);
  }
`
const StatusDot = styled.div<{ status?: IAtlasValidationStatus }>`
  border-radius: 50%;
  background-color: ${({ status }) => (status ? ATLAS_SLIDE_STATUS_COLORS[status] : 'var(--color-bg-4)')};
  width: 8px;
  height: 8px;
`

const BottomLoader = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0px);
`

const AtlasSlidesListContainer = memo(({ selectedSlideId }: Props) => {
  const filters = useTypedSelector((state) => state.viewerPage.atlasFilters)
  const slideId = useRouteMatch<{ slideId: string }>().params.slideId

  const { refetch } = usePaginatedAtlasSlidesQuery(filters)

  const scrollToIndexOn = useRef(true)
  const { data, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, ids, isFetching, remove } =
    useAtlasSlides(
      {
        ...filters,
        after: undefined,
        before: undefined,
      },
      slideId,
    )

  useEffect(() => {
    refetch()
    return () => {
      remove()
    }
  }, [])
  const [firstRender, setFirsRender] = useState(true)

  useEffect(() => {
    if (firstRender && hasPreviousPage && data?.pages[1]?.previousCursor) {
      setFirsRender(false)
      fetchPreviousPage()
    }
    if (data && data.pages?.length == 2 && data.pages[1]?.last) {
      hasPreviousPage && fetchPreviousPage()
    }
    if (data && data.pages?.length == 2 && !data.pages[1]?.last) {
      setTimeout(() => {
        scrollToIndexOn.current = false
      }, 50)
    }
    if (data && data.pages?.length === 3 && data.pages[2]?.last) {
      setTimeout(() => {
        scrollToIndexOn.current = false
      }, 50)
    }
    // @ts-ignore
    ref?._child?.parentNode?.children &&
      hasPreviousPage &&
      // @ts-ignore
      ref?._child?.parentNode?.children[2]?.scrollIntoView({
        block: 'start',
      })
  }, [data])

  const [ref, setRef] = useState()
  const topTrigger = useRef<HTMLDivElement>(null)
  const scrollTop = useRef(0)
  const scrollDirection = useRef('down')

  const onListScroll = (e: ScrollParams) => {
    if (!e.clientHeight) return
    if (e.scrollTop !== scrollTop.current) {
      scrollDirection.current = e.scrollTop > scrollTop.current ? 'down' : 'up'
      scrollTop.current = e.scrollTop
    }
    if (scrollTop.current === topTrigger.current?.scrollTop && scrollDirection.current === 'up') {
      hasPreviousPage && (data?.pages[1]?.previousCursor || data?.pages[0]?.previousCursor) && fetchPreviousPage()
    }
  }
  return (
    <>
      <div ref={topTrigger} />
      <VirtualInfiniteScroll
        total={ids.length + 1}
        defaultRowHeight={32}
        fetchMore={hasNextPage ? fetchNextPage : async () => null}
        setRef={setRef}
        list={ids}
        scrollToIndex={scrollToIndexOn.current ? ids.findIndex((it) => it === selectedSlideId) : undefined}
        threshold={5}
        rowRenderer={({ index }) => {
          const item = ids[index]
          if (!item) return null
          return (
            <AtlasSlideItemContainer
              data={data}
              slideId={item || 0}
              selectedSlideId={selectedSlideId}
              filters={filters}
            />
          )
        }}
        onScroll={onListScroll}
      />
      {isFetching && (
        <BottomLoader>
          <SpinElement />
        </BottomLoader>
      )}
    </>
  )
})

const AtlasSlideItemContainer = ({
  data,
  filters,
  selectedSlideId,
  slideId,
}: {
  slideId: number
  selectedSlideId: number
  filters: PaginatedAtlasSlidesFilters
  data?: any
}) => {
  const dispatch = useDispatch()
  const { changeViewerSlide } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch('A')
  const { slideId: currentSlideId } = useViewerIdSlideState('A')

  const queryClient = useQueryClient()
  const slide = queryClient.getQueryData<IAtlasSlide>([QUERY_TYPE.ATLAS, slideId])
  const unusedSlides: number[] | undefined = queryClient.getQueryData([QUERY_TYPE.ATLAS, 'unusedSlides'])

  const [fetchCounter, setFetchCounter] = useState(0)

  const fieldRef = React.useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (slide?.slideId === selectedSlideId && fetchCounter < 6) {
      fieldRef?.current?.scrollIntoView({
        block: 'center',
      })
      setFetchCounter(fetchCounter + 1)
    }
  }, [data])
  const clickHandler = () => {
    if (slide && currentSlideId !== slideId) {
      changeViewerSlide('A', {
        caseId: NaN,
        slideGroupType: 'MICRO',
        slideId: slide.slideId,
        source: 'ATLAS',
        viewerMode: 'DEFAULT',
      })
      viewerDispatch(viewerSlice.actions.setSelectedBbox())
      dispatch(push(`/atlas-viewer/${slide.slideId}`))
      dispatch(
        viewerPageSlice.actions.setAtlasFilters({
          ...filters,
          after: slide.slideId,
          direction: 'next',
        }),
      )
    }
  }
  return slide ? (
    <AtlasSlideItem ref={fieldRef} isActive={slide?.slideId === selectedSlideId} flex={false} onClick={clickHandler}>
      <TextElement ellipsis>{slide.caption || slide.slideId}</TextElement>
      <StatusDot status={slide.validationStatus} />
      {unusedSlides?.includes(slide.slideId) && <IconElement size={'md'} name="eye" />}
    </AtlasSlideItem>
  ) : null
}

export default AtlasSlidesListContainer
