import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerAttachmentsSlice } from 'features/attachments'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { MPUserStatus } from 'features/multiplayer/lib/user-status'
import { MPUserStateDto } from 'features/multiplayer/lib/user-status/types'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useViewerDispatch } from 'viewer/container'
import { viewerToolsSlice } from 'viewer/tools/model/viewerToolsSlice'

import ActiveUsers from './ActiveUsers'

const ActiveUsersContainer = () => {
  const currentUser = useTypedSelector((state) => state.user.user)
  const { subscribeToUser, targetUserId, unsubscribeFromUser, usersStatuses } = useUserStatusContext()

  const { activeViewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(activeViewerId)
  const dispatch = useDispatch()

  const onUserClick = useCallback(
    (user: MPUserStateDto) => {
      /** Если кликаем на себя */
      if (user.uid === currentUser?.userId) {
        return
      }
      /** Если кликаем на того, на кого подписаны */
      if (user.uid === targetUserId) {
        unsubscribeFromUser()
      } else {
        // Закрыть все панели из инструментов AI
        dispatch(viewerPageSlice.actions.closeAllAITools())
        // Закрыть панель цветокоррекции
        viewerDispatch(viewerPageSlice.actions.setColorsModal(false))

        viewerDispatch(viewerToolsSlice.actions.setInitialState())
        viewerDispatch(viewerAttachmentsSlice.actions.setInitialState())
        dispatch(viewerPageSlice.actions.toggleViewerRightPanel())
        subscribeToUser(user.uid)
      }
    },
    [targetUserId],
  )

  let users: MPUserStateDto[] = usersStatuses.length
    ? usersStatuses.filter((status) => status.sta !== MPUserStatus.Left)
    : []
  users = users.sort((x, y) => (x.uid === currentUser?.userId ? -1 : y.uid === currentUser?.userId ? 1 : 0))

  return users.length ? <ActiveUsers users={users} onUserClick={onUserClick} targetUserId={targetUserId} /> : null
}

export default ActiveUsersContainer
