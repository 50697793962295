import { useTypedSelector } from 'app/redux/lib/selector'
import { casesSlice } from 'features/cases'
import { useDispatch } from 'react-redux'

import CasesRightPanel from './ui/CasesRightPanel'

type Props = {
  caseId: number
}

const CasesRightPanelContainer = ({ caseId }: Props) => {
  const dispatch = useDispatch()
  const activeTab = useTypedSelector((state) => state.cases.rightPanelTab)

  return (
    <CasesRightPanel
      caseId={caseId}
      onChangeTab={(key) => dispatch(casesSlice.actions.setRightPanelTab(key))}
      activeTab={activeTab}
    />
  )
}

export default CasesRightPanelContainer
