import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'
import { VerticalSeparator } from 'shared/ui/kit/ui/VerticalSeparator'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`

const StyledName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 4px;
  border-radius: 4px;
  padding-right: 6px;
  color: var(--color-text-1);

  :hover {
    background: var(--color-bg-3);
  }
`

export type OnOtherPatientCaseNotificationProps = {
  onReturnToOriginCase: () => void
}

const OnOtherPatientCaseNotification = ({ onReturnToOriginCase }: OnOtherPatientCaseNotificationProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper onClick={onReturnToOriginCase}>
      <BackButton>
        <IconElement name={'arrowReturn'} />
        <StyledName>{t('К изначальному случаю')}</StyledName>
      </BackButton>
      <VerticalSeparator />
    </Wrapper>
  )
}

export default OnOtherPatientCaseNotification
