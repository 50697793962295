import { Divider } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { CSSProperties } from 'react'
import { ButtonElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type ButtonProps = {
  text: string
  onClick: () => void
}

type Props = {
  /** Заголовок. */
  title: string
  /** Массив свойств кнопок. */
  buttons: ButtonProps[]
}

const ConfirmationModal = ({ buttons, title }: Props) => (
  <Overlay>
    <StyledModal>
      <TitleElement style={titleStyles}>{title}</TitleElement>
      <Divider style={{ margin: '16px 0' }} />
      <ButtonContainer>
        {buttons.map((button, index) => (
          <StyledButton key={index} onClick={button.onClick}>
            {button.text}
          </StyledButton>
        ))}
      </ButtonContainer>
    </StyledModal>
  </Overlay>
)

export default ConfirmationModal

const titleStyles: CSSProperties = {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  textAlign: 'center',
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const StyledButton = styled(ButtonElement)`
  margin: 0 !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  height: 24px;
  width: 280px;
`

const ButtonContainer = styled.div`
  display: inline-grid;
  row-gap: 8px;
  width: 100%;
`

const StyledModal = styled.div`
  ${() => modalBackground}
  ${() => modalShadow}
  padding: 16px;
  border-radius: 5px;
  text-align: center;
  z-index: 1001;
`
