import { useTranslation } from 'react-i18next'
import { declensionWord } from 'shared/lib/text'
import { getStorageWorkspaceId } from 'shared/lib/workspaces'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

const TextWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const Wrapper = styled.div`
  display: grid;
  gap: 4px;
`
const LinkWrapper = styled.div`
  display: flex;
  gap: 2px;
  width: 100%;
`
const Link = styled.a``

export type OnLinkingSlidesNotificationProps = {
  count: number
  caseId: number
  caseName?: string | null
}

const OnLinkingSlidesNotification = ({ caseId, caseName, count }: OnLinkingSlidesNotificationProps) => {
  const { t } = useTranslation()
  const workspaceId = getStorageWorkspaceId()

  return (
    <Wrapper>
      <TextWrapper>
        {count} {declensionWord(count, [t('изображение'), t('изображения'), t('изображений')])}{' '}
        {count === 1 ? t('привязано') : t('привязаны')} {t('к случаю')} {caseName}
      </TextWrapper>
      <Link target="_blank" href={`/workspace/${workspaceId}/viewer/${caseId}`}>
        <LinkWrapper>
          <TextWrapper>{t('Открыть случай')}</TextWrapper>
          <IconElement size={'md'} name="newWindow" />
        </LinkWrapper>
      </Link>
    </Wrapper>
  )
}

export default OnLinkingSlidesNotification
