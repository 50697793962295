import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, SmallIconButtonElement, StyledText, TextElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { Ki67Context } from 'viewer/map/layers/ki67/Ki67Context'

type ItemProps = {
  id: string
  info?: {
    cellsTotal: number
    cellsPositive: number
    cellsNegative: number
    cellsOther: number
  }
  isHidden: boolean
  isActive: boolean
  onSelect: (id: string) => void
  onHide: (id: string, isHidden: boolean) => void
}

const ItemWrapper = styled.div<{ isActive: boolean }>`
  border-radius: 5px;
  padding: 4px;
  background-color: ${({ isActive }) => (isActive ? 'var(--color-bg-3)' : 'transparent')};
  &:hover {
    background: var(--color-bg-3);
    cursor: pointer;
  }
`
const StyledItemInfo = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr auto;
  color: var(--color-text-3);
  gap: 4px;
  align-items: center;
`

const Numbers = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr auto;
  align-items: center;
  gap: 4px;
  padding-right: 4px;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  ${StyledText} {
    color: var(--color-text-3);
  }
`

const Ki67Icon = styled(IconElement)`
  justify-self: center;
`
const Count = styled(TextElement)`
  justify-self: end;
`

const Ki67ItemContainer = ({ id, info, isActive, isHidden, onHide, onSelect }: ItemProps) => {
  const { t } = useTranslation()
  const { removeKi67 } = useContext(Ki67Context)
  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation()
      removeKi67(id)
    },
    [id],
  )

  const onSelectClick = useCallback(() => {
    onSelect(id)
  }, [onSelect, id])

  const onHideClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation()
      onHide(id, !isHidden)
    },
    [onHide, id, isHidden],
  )

  if (!info) {
    return (
      <ItemWrapper isActive={false}>
        <StyledItemInfo>
          <IconElement size="md" name="ki67" />
          <TextElement>{t('Вычисление')}...</TextElement>
          <SmallIconButtonElement icon="delete" onClick={onDelete} />
        </StyledItemInfo>
      </ItemWrapper>
    )
  }
  const { cellsNegative, cellsOther, cellsPositive, cellsTotal } = info
  const percent = Math.round(100 * (cellsPositive / (cellsPositive + cellsNegative))) || 0

  return (
    <ItemWrapper isActive={isActive} onClick={onSelectClick}>
      <StyledItemInfo>
        <IconElement size="md" name="ki67" />
        <TextElement>{percent} %</TextElement>
        <div>
          <TooltipElement title={t('Скрыть')}>
            <SmallIconButtonElement
              icon="eye"
              style={{ color: isHidden ? 'var(--color-text-3)' : 'var(--color-text-1)' }}
              onClick={onHideClick}
            />
          </TooltipElement>
          <TooltipElement title={t('Удалить')}>
            <SmallIconButtonElement icon="delete" onClick={onDelete} />
          </TooltipElement>
        </div>
      </StyledItemInfo>
      <Numbers>
        <Ki67Icon name="ki67_red_plus" size="xs" />
        <TextElement>{t('Позитивные клетки')}</TextElement>
        <Count>{cellsPositive}</Count>
        <Ki67Icon name="ki67_green_dot" size="xs" />
        <TextElement>{t('Негативные клетки')}</TextElement>
        <Count>{<Count>{cellsNegative}</Count>}</Count>
        <Ki67Icon name="ki67_white_dot" size="xs" />
        <TextElement>{t('Здоровые клетки')}</TextElement>
        <Count>{cellsOther}</Count>
      </Numbers>
    </ItemWrapper>
  )
}

export default Ki67ItemContainer
