import { notices } from 'features/notices'
import { Notification_Tabs, useNotificationQuery, useReadNotificationMutation } from 'features/notifications/api/query'
import { useWorkspacesQuery, workspacesSlice } from 'features/workspace'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { ComponentType, FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import INotificationMessage, {
  NotificationMessageContentCaseShared,
  NotificationMessageContentCaseSharedByToken,
} from 'types/INotificationMessage'
import { IWorkspace } from 'types/IWorkspaces'

import { NewCommentMessage, NewReportMessage, NewSharedCaseLinkMessage, NewSharedCaseMessage } from './ui/Messages'

type Props = {
  notificationId: number
  activeTab: string
}

const MessageContainer = ({ activeTab, notificationId }: Props) => {
  const { data: workspaces } = useWorkspacesQuery()
  const { data } = useNotificationQuery(notificationId)
  const dispatch = useDispatch()
  const { isLoading, mutate: onRead } = useReadNotificationMutation()
  const workspaceId = useCurrentWorkspaceId()
  const { t } = useTranslation()
  //TODO refactor with workspaces
  const onNewCommentClick = useCallback((caseId: number, sharedWorkspaceId?: number) => {
    if (data?.content?.type !== 'NEW_COMMENT') return
    const { origin } = window.location
    if (!data?.content || !data?.content?.caseRelation) {
      notices.error({
        message: t('Отсутствует информация о взаимосвязи (caseRelation)'),
      })
      return
    }
    const newWorkspace = workspaces?.find((item) => +item.workspaceId === sharedWorkspaceId)
    !(workspaces?.length === 1) &&
      notices.redirectToWorkspace({
        key: 'redirectToWorkspace',
        workspaceName: newWorkspace?.name,
      })

    if (newWorkspace) {
      dispatch(workspacesSlice.actions.setCurrentWorkspace(newWorkspace))
      localStorage.setItem('workspaceId', `${sharedWorkspaceId || workspaceId}`)
      window.open(`${origin}/workspace/${newWorkspace}/viewer/${caseId}?commentId=1`, '_blank')
    } else {
      window.open(`${origin}/workspace/${workspaceId}/viewer/${caseId}?commentId=1`, '_blank')
    }
  }, [])

  const onSharedCaseClick = useCallback((caseId: number, sharedWorkspaceId?: number) => {
    const newWorkspace = workspaces?.find((item) => +item.workspaceId === sharedWorkspaceId)
    if (newWorkspace) {
      dispatch(workspacesSlice.actions.setCurrentWorkspace(newWorkspace))
      localStorage.setItem('workspaceId', `${sharedWorkspaceId || workspaceId}`)
      window.open(`${origin}/workspace/${newWorkspace.workspaceId}/viewer/${caseId}`, '_blank')
    } else {
      window.open(`${origin}/workspace/${workspaceId}/viewer/${caseId}`, '_blank')
    }
  }, [])

  const onSharedCaseLinkClick = useCallback(
    (caseId: number, sharedWorkspaceId?: number, token?: string, canAcquirePermission?: boolean) => {
      if (!canAcquirePermission) {
        const newWorkspace = workspaces?.find((item) => +item.workspaceId === sharedWorkspaceId)
        if (newWorkspace) {
          dispatch(workspacesSlice.actions.setCurrentWorkspace(newWorkspace))
          localStorage.setItem('workspaceId', `${sharedWorkspaceId || workspaceId}`)
          window.open(`${origin}/workspace/${newWorkspace.workspaceId}/viewer/${caseId}`, '_blank')
        }
        !(workspaces?.length === 1) &&
          notices.redirectToWorkspace({ key: 'redirectToWorkspace', workspaceName: newWorkspace?.name })
      } else {
        workspaces && workspaces.length > 1
          ? notices.selectWorkspaceNotification({
              callback: () => {
                window.open(`/invite/${token}`, '_blank')
              },
              dispatch,
              key: 'WorkspaceNotification',
              workspaces,
            })
          : token && token.length && window.open(`/invite/${token}`, '_blank')
      }
    },
    [],
  )
  const onNewReportClick = useCallback((caseId: number, sharedWorkspaceId?: number) => {
    if (data?.content?.type !== 'NEW_MEDICAL_REPORT') return
    if (!data?.content?.caseRelation)
      notices.error({
        message: t('Отсутствует информация о взаимосвязи (caseRelation)'),
      })
    const newWorkspace = workspaces?.find((item) => +item.workspaceId === sharedWorkspaceId)
    if (newWorkspace) {
      dispatch(workspacesSlice.actions.setCurrentWorkspace(newWorkspace))
      localStorage.setItem('workspaceId', `${sharedWorkspaceId || workspaceId}`)
      window.open(`${origin}/workspace/${newWorkspace.workspaceId}/viewer/${caseId}?medicalReportId=1`, '_blank')
    } else {
      window.open(`${origin}/workspace/${workspaceId}/viewer/${caseId}?medicalReportId=1`, '_blank')
    }
  }, [])

  const handleClickRead = useCallback(
    (notificationId: number) => {
      if (data && !data.isRead && !isLoading) {
        onRead(notificationId)
      }
    },
    [data, isLoading],
  )

  if (!data) {
    return null
  }

  const { content, isRead } = data
  const { caseId, type } = content

  const onClick = () => {
    onRead(notificationId)
    if (type === 'NEW_MEDICAL_REPORT') return onNewReportClick(caseId, content?.workspaceId)
    if (type === 'NEW_COMMENT') return onNewCommentClick(caseId, content?.workspaceId)
    if (type === 'CASE_PERMITTED')
      return onSharedCaseLinkClick(
        caseId,
        content?.workspaceId,
        content?.permissionToken,
        content?.canAcquirePermission,
      )
    if (type === 'CASE_SHARED') return onSharedCaseClick(caseId, content?.workspaceId)
  }

  if (activeTab === Notification_Tabs.UNREAD && !isRead) {
    return (
      <MessageComponent
        notificationId={notificationId}
        type={type}
        data={data}
        onRead={handleClickRead}
        workspaces={workspaces}
        onClick={onClick}
      />
    )
  }
  if (activeTab === Notification_Tabs.NOT_ALL) {
    return (
      <MessageComponent
        notificationId={notificationId}
        type={type}
        data={data}
        onRead={handleClickRead}
        workspaces={workspaces}
        onClick={onClick}
      />
    )
  }

  return null
}

export default MessageContainer

type MessageComponentProps = {
  /** тип сообщения */
  type: string
  /** данные сообщения */
  data: INotificationMessage
  /** идентификатор сообщения */
  notificationId: number
  /** данные воркспейса */
  workspaces?: IWorkspace[]
  /** обработчик клика */
  onClick: () => void
  /** обработчик чтения */
  onRead: (notificationId: number) => void
}

const messageComponents: { [key: string]: ComponentType<any> } = {
  CASE_PERMITTED: NewSharedCaseLinkMessage,
  CASE_SHARED: NewSharedCaseMessage,
  NEW_COMMENT: NewCommentMessage,
  NEW_MEDICAL_REPORT: NewReportMessage,
}

// Определение защитных функций типа
const isCaseShared = (content: INotificationMessage['content']): content is NotificationMessageContentCaseShared =>
  content.type === 'CASE_SHARED'

const isCasePermitted = (
  content: INotificationMessage['content'],
): content is NotificationMessageContentCaseSharedByToken => content.type === 'CASE_PERMITTED'

const MessageComponent: FC<MessageComponentProps> = ({ data, notificationId, onClick, onRead, type, workspaces }) => {
  const Component = messageComponents[type]
  const [isHover, setHover] = useState(false)

  const handleMouseEnter = () => setHover(true)

  const handleMouseLeave = () => setHover(false)

  const { content, createdAt, isRead } = data
  const { canOpenCase, caseId, caseName, srcUserName } = content

  const commonProps = {
    canOpenCase: canOpenCase,
    caseId: caseId,
    caseName: caseName,
    createdAt: createdAt,
    handleMouseEnter,
    handleMouseLeave,
    isHover,
    isRead,
    key: notificationId,
    notificationId,
    onRead,
    userName: srcUserName,
    workspaceId: content?.workspaceId,
  }

  if (!Component) {
    return null
  }

  // Условно добавляем srcWorkspaceName и srcWorkspaceId, если они существуют в content
  const specificProps = {
    ...commonProps,
    ...((isCaseShared(content) || isCasePermitted(content)) && {
      srcWorkspaceId: content.srcWorkspaceId,
      srcWorkspaceName: content.srcWorkspaceName,
    }),
  }

  return <Component {...specificProps} onClick={onClick} content={content} workspaces={workspaces} />
}
