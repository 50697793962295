import { Layout, Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { compareDesc } from 'date-fns'
import { useCasesRecordsQuery } from 'features/cases'
import dashboardSlice from 'features/dashboard/dashboardSlice'
import DashboardThumbnailsListContainer from 'features/dashboard/DashboardThumbnailsListContainer'
import { dashboardMockCases } from 'features/dashboard/data/dashboardMockCases'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'

import DiagnosticTypeContainer from './diagniostic/DiagnosticTypeContainer'
import PatientInfoContainer from './PatientInfoContainer'

const { Content, Header, Sider } = Layout
const TypesHeader = styled(Header)`
  height: 64px;
  display: flex;
  background-color: var(--color-bg-2);
  border-bottom: 1px solid var(--color-border-1);
  padding: 8px;
`

const StyledContent = styled(Content)`
  background: var(--color-bg-2);
  height: 100%;
`

type Props = {
  ids: number[]
}

const PatientContent = ({ ids }: Props) => (
  <Layout style={{ height: '100%' }}>
    <TypesHeader>
      <Space direction="horizontal" size={8}>
        {ids.map((id) => (
          <DiagnosticTypeContainer key={id} caseId={id} />
        ))}
      </Space>
    </TypesHeader>
    <Layout style={{ height: '100%' }}>
      <StyledContent>
        <PatientInfoContainer />
      </StyledContent>
      <Sider width={222}>
        <DashboardThumbnailsListContainer />
      </Sider>
    </Layout>
  </Layout>
)

export const PatientContentContainer = () => {
  const dispatch = useDispatch()
  const selectedPatient = useTypedSelector((state) => state.dashboard.selectedPatient)
  const fullName = selectedPatient?.fullname
  const selectedCase = useTypedSelector((state) => state.dashboard.selectedCase)
  const casesRecords = useCasesRecordsQuery({ relation: 'owner', status: 'open' })
  const ids = [...casesRecords, ...dashboardMockCases]
    .filter((record) => record.patient?.fullname === selectedPatient?.fullname)
    .sort((a, b) => compareDesc(new Date(a.createdAt || ''), new Date(b.createdAt || '')))
    .map((record) => record.caseId)

  useEffect(() => {
    const caseFromSelectedPatient = ids.find((id) => id === selectedCase)
    if (ids && !caseFromSelectedPatient) {
      const firstCaseId = ids[0]
      dispatch(dashboardSlice.actions.selectCase(firstCaseId))
    }
  }, [fullName, selectedCase])

  return <PatientContent ids={ids} />
}

export default PatientContentContainer
