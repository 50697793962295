import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageElement, LinkElement, SpaceElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
`

const ThumbnailWrapper = styled(ImageElement)`
  min-width: 48px;
  max-height: 48px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 160px;
`

export type OnSlideRemoveNotificationProps = {
  src?: string
  id?: number
  caption?: string
  onRestore: (slideId: number) => void
  restoreDisabled?: boolean
}

const OnSlideRemoveNotification = ({
  caption,
  id,
  onRestore,
  restoreDisabled,
  src,
}: OnSlideRemoveNotificationProps) => {
  const { t } = useTranslation()
  const onRestoreClick = useCallback(() => {
    id && !restoreDisabled && onRestore(id)
  }, [id, restoreDisabled])

  return (
    <Wrapper data-testid={'slide-restore-block'}>
      <div>
        <ThumbnailWrapper data-testid={'slide-restore-block-image'} src={src} />
      </div>
      <SpaceElement size={10} />
      <TextWrapper>
        <TitleElement level={4} ellipsis>
          {t('Слайд')} {caption || id}
        </TitleElement>
        <TitleElement level={4} style={{ margin: 0 }}>
          {t('удален')}
        </TitleElement>
        <LinkElement onClick={onRestoreClick} disabled={restoreDisabled}>
          {t('Восстановить')}
        </LinkElement>
      </TextWrapper>
    </Wrapper>
  )
}

export default OnSlideRemoveNotification
