import { Row } from 'antd'
import { useMutateSlideCaseSpecificMetadata, useSlideQuery } from 'entities/slide'
import { useCaseCache } from 'features/cases'
import { useTranslation } from 'react-i18next'
import { getSlideGleasonData } from 'shared/lib/metadata'
import { MINUS, PLUS } from 'shared/text-constants'
import { SelectElement, SpaceElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { ICaseRelation } from 'types/ICase'
import ISource from 'types/ISource'

const StyledGleason = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-gap: 8px;
`

type Props = {
  slideId: number
  caseId: number
  source: ISource
}

const options = [
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
]

const GleasonContainer = ({ caseId, slideId, source }: Props) => {
  const { data: slide } = useSlideQuery({ slideId, source })
  const caseRecord = useCaseCache(caseId)
  const caseSpecificMetadata = slide?.slideMetadata?.caseSpecificMetadata
  const { mutate } = useMutateSlideCaseSpecificMetadata(caseId, slideId, source)
  const { t } = useTranslation()
  const { gGroup, gPrimary, gSecondary } = getSlideGleasonData(caseSpecificMetadata)
  if (caseRecord?.relation !== ICaseRelation.OWNER) {
    return (
      <Row>
        <TextElement type="secondary">{t('Сумма Глиссона')}:</TextElement>
        {gPrimary && gSecondary ? (
          <TextElement>
            {gPrimary}
            {PLUS}
            {gSecondary}
          </TextElement>
        ) : (
          <TextElement>{MINUS}</TextElement>
        )}
      </Row>
    )
  }

  const onPrimaryChange = async (value: number, property: string) => {
    mutate({ [property]: value })
  }

  return (
    <>
      <StyledGleason>
        <SelectElement
          value={gPrimary || ''}
          options={options}
          onChange={(value: number) => onPrimaryChange(value, 'userGleasonPatternPrimary')}
        />
        <TextElement>{PLUS}</TextElement>
        <SelectElement
          value={gSecondary || ''}
          options={options}
          onChange={(value: number) => onPrimaryChange(value, 'userGleasonPatternSecondary')}
        />
        <SpaceElement size={8} />
      </StyledGleason>
      <div>
        <TextElement>ISUP Grade Group</TextElement>
        &nbsp;
        <TextElement>{gGroup}</TextElement>
      </div>
    </>
  )
}

export default GleasonContainer
