import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useSlideQuery } from 'entities/slide'
import { viewerAttachmentsSlice } from 'features/attachments/model/viewerAttachmentsSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { isEqual } from 'lodash'
import { viewerPageSlice } from 'pages/viewer'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { useDispatch } from 'react-redux'
import ICommentAttachment from 'types/ICommentAttachment'
import { createThumbnailLink, useViewerAttachmentsSelector, useViewerDispatch } from 'viewer/container'

import CommentAttachment from './CommentAttachment'

type Props = {
  attachment?: ICommentAttachment
  caseId: number
}

const CommentAttachmentContainer = ({ attachment, caseId }: Props) => {
  const dispatch = useDispatch()
  const { activeViewerId: viewerId } = useOpenViewers()
  const pathname = useTypedSelector((state) => state.router.location.pathname)
  const workspaceId = useCurrentWorkspaceId()
  const { selectedBbox } = useViewerAttachmentsSelector(viewerId)
  const { changeViewerSlide } = useViewerPageProvided()
  const { slideId: currentViewerSlideId } = useViewerIdSlideState(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)
  const { HEAT_MAP } = useTypedSelector((state) => state.viewerPage.tools)
  const metadata = attachment?.metadata
  const { data: slide } = useSlideQuery({ caseId, slideId: metadata?.slideId, source: 'PLATFORM' })
  const isSelected = isEqual(selectedBbox, metadata?.bbox)

  if (!slide || slide.deleted) {
    return null
  }
  let src = ''
  if (slide && metadata && metadata.bbox) {
    src = createThumbnailLink(slide.iiif2Url, metadata.bbox, true, slide.iiif2AuthToken)
  }
  const onClick = () => {
    if (!metadata?.slideId) {
      return
    }
    if (pathname.includes('/cases') || pathname.includes('/archive')) {
      dispatch(push(`/workspace/${workspaceId}/viewer/${caseId}?slideId=${metadata.slideId}`))
    }
    if (isSelected) {
      viewerDispatch(viewerAttachmentsSlice.actions.selectBbox(null))
      return
    }
    if (currentViewerSlideId !== metadata.slideId) {
      changeViewerSlide(viewerId, {
        caseId,
        slideGroupType: slide.groupType,
        slideId: metadata.slideId,
        source: 'PLATFORM',
        viewerMode: 'DEFAULT',
      })
      HEAT_MAP.isVisible && viewerDispatch(viewerPageSlice.actions.setTool({ tool: 'HEAT_MAP', value: false }))
      setTimeout(() => {
        viewerDispatch(viewerAttachmentsSlice.actions.selectSlide(metadata.slideId))
        viewerDispatch(viewerAttachmentsSlice.actions.selectBbox(metadata.bbox))
      }, 1000)
    } else {
      viewerDispatch(viewerAttachmentsSlice.actions.selectSlide(metadata.slideId))
      viewerDispatch(viewerAttachmentsSlice.actions.selectBbox(metadata.bbox))
    }
  }

  return <CommentAttachment isSelected={isSelected} onClick={onClick} src={src} slide={slide} />
}

export default CommentAttachmentContainer
