import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useTaskQuery, useTaskSlides } from 'entities/tasks/api/query'
import { NoticesList } from 'features/notifications/NotificationsList'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import { findCanSeeOtherResults } from 'pages/tasks/help'
import { selectTasksUrlTaskId, SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTasksSlideData } from 'shared/lib/local-storage'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import { MainToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import styled from 'styled-components/macro'
import { ILocalStorageTasksSlide } from 'types/IMarkupSlide'

import TaskSlideItem from './TaskSlideItem'

const TaskHeader = styled.div`
  padding: 18px 8px;
  border-bottom: 1px solid var(--color-bg-3);
  display: flex;
  align-items: center;
`

const TaskTitle = styled.div`
  border-left: 1px solid var(--color-bg-3);
  font-size: 14px;
  line-height: 20px;
  padding-left: 12px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

const SlidesList = styled.div`
  display: grid;
  padding: 16px 36px;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`

const ContinueButton = styled(ButtonElement)`
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  height: 32px;
`
type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

const TaskRoute = ({ notificationButton }: Props) => {
  const taskId = useSelector(selectTasksUrlTaskId)
  const userId = useTypedSelector((state) => state.user?.user?.userId)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { data: task } = useTaskQuery(taskId)
  const { data: taskSlides } = useTaskSlides(taskId)
  const dispatch = useDispatch()
  const prevTasksSlidesData: ILocalStorageTasksSlide[] | [] = JSON.parse(getTasksSlideData() || '[]')
  const prevTasksSlideData = prevTasksSlidesData.find((item) => item.task === taskId && item.userId === userId)
  const continueHandler = () => dispatch(push(`/tasks-viewer/${taskId}?slideId=${prevTasksSlideData?.slide}`))
  const canSeeOtherResult = findCanSeeOtherResults(task?.participants, userId)
  const isPaused = task?.status === 'PAUSED'
  const { t } = useTranslation()

  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
    >
      <Content>
        <TaskHeader>
          <Link style={{ display: 'flex' }} to={'/tasks'}>
            <MainToolbarButton icon={<IconElement name="arrowBack" />} />
          </Link>
          <TaskTitle style={{ flexGrow: 1 }}>{task?.name}</TaskTitle>
          {!isPaused && (
            <ContinueButton disabled={!prevTasksSlideData} type="primary" onClick={continueHandler}>
              {t('Продолжить разметку')}
            </ContinueButton>
          )}
        </TaskHeader>
        <SlidesList>
          {taskSlides?.map((slide) => (
            <TaskSlideItem
              key={slide.slideId}
              slideData={slide}
              canSeeOtherResult={canSeeOtherResult}
              userId={userId}
            />
          ))}
        </SlidesList>
      </Content>
    </ContentLayout>
  )
}
export default TaskRoute
