import { IAnnotation, ISlideAnnotation } from 'types/IAnnotations'

export const reorder = (list: Array<IAnnotation | string>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const hasAnnotations = (annotations?: ISlideAnnotation[], slideId?: number): boolean => {
  if (!(annotations?.length && slideId)) return false

  const annotationGroup = annotations.find((group) => group?.slideId === slideId)

  return !!annotationGroup?.annotations?.length
}
