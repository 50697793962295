import { useTypedSelector } from 'app/redux/lib/selector'
import { isChatAccess, SupportChat } from 'features/support-chat'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { MarkingButtonModal } from 'widgets'

import { MenuItem } from './MenuItem'

export const NavigationBottom = () => {
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const { t } = useTranslation()

  const manualOpen = {
    href: 'https://notion.onecell.ru/OneCell-e283d1b0c7aa4f42978526d701da86cb ',
    rel: 'noreferrer',
    target: '_blank',
  }
  return (
    <StyledNavigationBottom>
      <MarkingButtonModal />
      {isChatAccess(currentWorkspace) ? <SupportChat /> : null}
      <Guide>
        <MenuItem
          title={t('Руководство')}
          icon="questionCircleSolid"
          testAttr="guide"
          path={manualOpen}
          active={false}
        />
        <StyledIconElement name={'newWindow'} size={'md'} />
      </Guide>
    </StyledNavigationBottom>
  )
}

const StyledNavigationBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid var(--color-border-1);
`
const Guide = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 16px;
`
const StyledIconElement = styled(IconElement)`
  color: var(--color-text-3);
`
