import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { usePaginatedTasksQuery } from 'entities/tasks'
import { NoticesList } from 'features/notifications/NotificationsList'
import ResTable from 'pages/atlas/ui/ResTable'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import { SideRightPanelType, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconElement } from 'shared/ui/kit'
import { Column, dateRenderer } from 'shared/ui/table'
import { progressContainerRenderer } from 'shared/ui/table/ui/columnRenderers'
import { Participants } from 'shared/ui/table/ui/Participants'
import styled from 'styled-components'
import { IMarkupParticipant, IMarkupTask } from 'types/IMarkupTask'

const Content = styled.div`
  flex: 3;
  width: 100%;

  // avoid shift when no pagination
  & .ant-table:only-child {
    margin-top: 56px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 0 16px;
  width: 100%;

  @media (max-width: 1440px) {
    word-wrap: anywhere;
  }
`
const StyledTable = styled(ResTable)`
  .ant-table table {
    width: 100% !important;
  }

  .ant-table-container {
    height: 86vh;
    overflow: auto;
  }
`

const INITIAL_USERS_COUNT = 2
const COUNT_COLUMN = 6
const DEFAULT_WIDTH_TABLE = 143

type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

const TasksRoute = ({ notificationButton }: Props) => {
  const dispatch = useDispatch()
  const [openedRows, setOpenedRows] = useState<Record<number, boolean>>({})
  const filters = useTypedSelector((state) => state.viewerPage.tasksFilters)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { data, isFetching } = usePaginatedTasksQuery(filters)
  const tableRef = useRef<HTMLDivElement>(null)
  const width = tableRef?.current?.offsetWidth
  const widthColum = (width && width / COUNT_COLUMN) || DEFAULT_WIDTH_TABLE

  const filteredContent = data?.content.map((item, index) => {
    const participants: IMarkupParticipant[] = []
    let validatorsCount = 0
    let annotatorsCount = 0
    item?.participants?.forEach((participant) => {
      if (participant.access === 'VALIDATE' && validatorsCount < INITIAL_USERS_COUNT) {
        participants.push(participant)
        validatorsCount++
      }
      if (participant.access === 'ANNOTATE' && annotatorsCount < INITIAL_USERS_COUNT) {
        participants.push(participant)
        annotatorsCount++
      }
    })
    return {
      ...item,
      participants: openedRows[index] ? item.participants : participants,
    }
  })

  const participantsList = data?.content.map((item) => item.participants ?? []) ?? []

  const validatorsList = data?.content.map((item) => item.participants?.filter((item) => item.access === 'VALIDATE'))
  const annotatorsList = data?.content.map((item) => item.participants?.filter((item) => item.access === 'ANNOTATE'))

  const toggleOpen = (idx: number) => {
    setOpenedRows((prev) => ({
      ...prev,
      [idx]: !prev[idx],
    }))
  }

  const { t } = useTranslation()

  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
    >
      <ContentWrapper>
        <Content ref={tableRef}>
          <StyledTable
            data-testid={'task-table-common'}
            tableLayout={'auto'}
            dataSource={filteredContent}
            loading={isFetching}
            onChange={(pagination: { current: number; pageSize: any }, filters: { [x: string]: any }) => {
              const { ...rest } = filters
              dispatch(
                viewerPageSlice.actions.setTasksFilters({
                  page: pagination.current && pagination.current - 1,
                  size: pagination.pageSize,
                  ...rest,
                }),
              )
            }}
            pagination={{
              current: typeof filters?.page === 'number' ? filters?.page + 1 : undefined,
              pageSize: filters?.size,
              position: ['topRight'],
              showTotal: (total: any) => `${t('Всего задач')}: ${total}`,
              total: data?.totalElements,
            }}
            onRow={(record: IMarkupParticipant) => ({
              onClick: () => dispatch(push(`/tasks/${record.markupTaskId}`)),
            })}
          >
            <Column
              width={widthColum}
              title={t('Название')}
              dataIndex="name"
              key="name"
              render={(value: string, record: IMarkupTask) => {
                const isPaused = record?.status === 'PAUSED'

                return (
                  <>
                    {isPaused && <IconElement style={{ marginBottom: -3, marginRight: 8 }} size="md" name="paused" />}
                    {value}
                  </>
                )
              }}
            />
            <Column
              width={widthColum}
              title={t('Создано')}
              dataIndex="createdAt"
              key="createdAt"
              render={dateRenderer}
            />
            <Column
              width={widthColum}
              title={t('Изменено')}
              dataIndex="updatedAt"
              key="updatedAt"
              render={dateRenderer}
            />
            <Column
              width={widthColum}
              title={t('Ревьюверы')}
              dataIndex="participants"
              render={(value, record, index) => {
                if (!validatorsList?.length) return
                return (
                  <Participants
                    open={openedRows[index]}
                    toggleOpen={() => toggleOpen(index)}
                    participants={validatorsList[index]}
                  />
                )
              }}
              shouldCellUpdate={(record, prevRecord) => record !== prevRecord}
            />
            <Column
              width={widthColum}
              title={t('Разметчики')}
              dataIndex="participants"
              render={(value, record, index) => {
                if (!annotatorsList?.length) return
                return (
                  <Participants
                    open={openedRows[index]}
                    toggleOpen={() => toggleOpen(index)}
                    participants={annotatorsList[index]}
                  />
                )
              }}
              shouldCellUpdate={(record, prevRecord) => record !== prevRecord}
            />
            <Column
              width={widthColum}
              title={t('Проверено / Размечено')}
              dataIndex="participants"
              render={(data: IMarkupParticipant[], taskData: IMarkupTask, index) =>
                progressContainerRenderer(participantsList[index], taskData.totalSlides, openedRows[index])
              }
              shouldCellUpdate={(record, prevRecord) => record !== prevRecord}
            />
          </StyledTable>
        </Content>
      </ContentWrapper>
    </ContentLayout>
  )
}

export default TasksRoute
