import { MPClearPanelState, MPSerializedClearPanelState } from './types'

/** Пустое состояние всех вьюверов */
export const DEFAULT_PANEL_STATE: MPClearPanelState = {
  A: '-',
  B: '-',
  C: '-',
  D: '-',
}

/** Пустое сериализованное состояние всех вьюверов */
export const DEFAULT_SERIALIZED_PANEL_STATE: MPSerializedClearPanelState = {
  A: undefined,
  B: undefined,
  C: undefined,
  D: undefined,
}

/** Версия формата данных */
export const CURRENT_VERSION = 1

/** Константа для перевода с -> мс */
const FROM_SECOND = 1000
/** Временное окно, мс */
export const THROTTLE_TIME = 100
/** Время для таймера повторной отправки неизмененного состояния
 * (когда вместо + надо снова отправить строку с состоянием), мс */
export const STATE_RESEND_TIME = 10 * FROM_SECOND
