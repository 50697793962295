import { useTypedSelector } from 'app/redux/lib/selector'
import { setTaskSlideResult, useTaskSlideResult, useTaskSlides } from 'entities/tasks/api/query'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId, selectUrlSlideId } from 'pages/viewer/model/viewerPageSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { nextSlideHandler } from 'shared/lib/map'
import { ButtonElement, IconButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IMarkupTask } from 'types/IMarkupTask'
import IUserRole from 'types/IUserRole'

const ToolbarButton = styled(IconButtonElement)<{ active?: boolean }>`
  margin: 0 4px;
  color: ${({ active }) => (active ? 'var(--color-white) !important' : 'transparent')};
`
const Counter = styled.span`
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-3);
`
const CustomDivider = styled.div`
  height: 32px;
  width: 1px;
  background: var(--color-bg-3);
  margin: 0 5px;
`
const StyledButtonElement = styled(ButtonElement)<{ active?: boolean }>`
  height: 32px;
  margin-left: 8px;
  background: ${({ active }) => (active ? 'var(--color-blue) !important' : 'var(--color-bg-4) !important')};
  color: ${({ active }) => (active ? 'white !important' : 'var(--color-text-1) !important')};
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`
const MarkupToolbarContainer = () => {
  const { changeViewerSlide } = useViewerPageProvided()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const urlSlideId = useSelector(selectUrlSlideId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isTaskAvailable = authorities?.includes(IUserRole.ROLE_MARKUP_USER)
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const userData = taskData?.participants?.find((item) => item.userId === currentUserId)
  const { data: currentSlidesArr } = useTaskSlides(taskId)
  const currSlide = currentSlidesArr?.find((item) => item.slideId === urlSlideId)
  const { data: slideResults } = useTaskSlideResult(
    currSlide?.markupTaskId,
    currSlide?.markupSlideId,
    currSlide?.slideId,
  )
  const currResult = slideResults?.find((itemResult) => itemResult.markupParticipant?.userId === currentUserId)
  const currentSlides = currentSlidesArr?.map((item) => item.slideId)
  const currSlidePosition = currentSlides?.indexOf(urlSlideId || 0) || 0
  const { mutate: setStatus } = setTaskSlideResult()
  const isPausedTask = taskData?.status === 'PAUSED'
  const { t } = useTranslation()

  const changeSlideHandler = (isNext: boolean) => {
    currentSlides &&
      nextSlideHandler({
        changeViewerSlide,
        currSlidePosition,
        currentSlides,
        dispatch,
        isNext,
        taskId,
      })
  }
  const statusHandler = (status: boolean, markupResultId: number, taskSlideId: number) => {
    setStatus(
      {
        markupResultId,
        markupResultStatus: status ? 'DONE' : 'WIP',
        taskId,
        taskSlideId,
        urlSlideId,
      },
      status
        ? {
            onSuccess: () => changeSlideHandler(true),
          }
        : undefined,
    )
  }
  return (
    (isTaskAvailable && taskId && (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        {!isPausedTask &&
          currResult &&
          userData?.access === 'ANNOTATE' &&
          (currResult?.status === 'WIP' || currResult?.status === 'REWORK' ? (
            <StyledButtonElement
              disabled={!slideResults}
              active={true}
              onClick={() => statusHandler(true, currResult?.markupResultId, currResult?.markupSlideId)}
              type="primary"
            >
              {t('Готово')}
            </StyledButtonElement>
          ) : (
            <StyledButtonElement
              disabled={!slideResults}
              active={false}
              onClick={() => statusHandler(false, currResult?.markupResultId, currResult?.markupSlideId)}
              type="primary"
            >
              {t('Отменить')}
            </StyledButtonElement>
          ))}
        {currentSlides && (
          <>
            <ToolbarButton onClick={() => changeSlideHandler(false)} icon={<IconElement name="arrowBack" />} />
            <Counter>{currSlidePosition + 1 + ' ' + t('из') + ' ' + currentSlides?.length}</Counter>
            <ToolbarButton onClick={() => changeSlideHandler(true)} icon={<IconElement name="arrowRight" />} />
          </>
        )}
        <CustomDivider />
      </div>
    )) ||
    null
  )
}

export default MarkupToolbarContainer
