import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import {
  deleteAnnotationsMutation,
  useAddAnnotationsMutation,
  useChangeAnnotationMutation,
} from 'features/annotations/api'
import { notices } from 'features/notices'
import * as jsts from 'jsts'
import GeoJSON from 'ol/format/GeoJSON'
import { LinearRing, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom'
import { selectAtlasViewerUrlSlideId } from 'pages/viewer/model/viewerPageSlice'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import i18next from 'shared/lib/i18n/i18n'
import { getSlideMppx } from 'shared/lib/metadata'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { MergeType } from 'types/MergeType'
import { getFeaturesFromGeoJson } from 'viewer/map'
import { getPolygonArea } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { unsetSlidePositioningForAnnotation } from 'viewer/map/layers/annotations/lib/helpers'
import {
  getClearFeature,
  getDisunionGeom,
  getExcludeGeom,
  getInteriorGeom,
  getSingleFeatureFromGeoJson,
  getUnionGeom,
} from 'viewer/map/lib/utils'

const t = i18next.t

export const useIntersectAnnotationMutation = ({ caseId, slideId }: { caseId: number; slideId: number }) => {
  const queryClient = useQueryClient()
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const isAtlas = !useSelector(selectAtlasViewerUrlSlideId)
  const { mutateAsync: deleteAnnotations } = deleteAnnotationsMutation(
    {
      caseId,
      currentUserId,
      needQueryUpdate: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
      },
    },
  )
  const { mutateAsync: addAnnotations } = useAddAnnotationsMutation({
    caseId,
    slideId,
  })
  const { mutateAsync: editAnnotationAsync } = useChangeAnnotationMutation({
    caseId,
    slideId,
  })

  const { data: slide } = useSlideQuery({ caseId, slideId, source: isAtlas ? 'ATLAS' : 'PLATFORM' })
  const mppX = getSlideMppx(slide)

  const getIsGeometrySimple = (geometry: jsts.geom.Geometry) => {
    const geomFactory = new jsts.geom.GeometryFactory()
    try {
      const linearRing = geomFactory.createLinearRing(geometry?.getCoordinates())
      // self-intersection check
      return linearRing.isSimple()
    } catch (e) {
      // if geometry is not closed, then this is a union of primitives
      return true
    }
  }
  return useMutation(
    async ({ annotationsIds, mergeType }: { caseId: number; annotationsIds: number[]; mergeType: MergeType }) => {
      const annotations = annotationsIds.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
      const features = annotations.flatMap((annotation) =>
        getSingleFeatureFromGeoJson(annotation?.data?.formattedFeature),
      )
      const parser = new jsts.io.OL3Parser()
      //@ts-ignore
      parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)

      const featuresJstsGeom = features.map((feature) => parser.read(feature.getGeometry()))
      const areAllGeomSimple = featuresJstsGeom.filter((geom) => !getIsGeometrySimple(geom)).length === 0
      if (!areAllGeomSimple) {
        notices.error({
          message: t('Невозможно выполнить операцию, одна из аннотаций пересекает саму себя'),
        })
        throw Error
      }

      let mergeRes
      switch (mergeType) {
        case MergeType.EXCLUDE: {
          mergeRes = getExcludeGeom(featuresJstsGeom)
          break
        }
        case MergeType.INTERSECT: {
          featuresJstsGeom.forEach((item) => {
            featuresJstsGeom.forEach((feature) => {
              if (!item.intersects(feature)) {
                notices.error({
                  message: t('Аннотации не пересекаются'),
                })
                throw Error
              }
            })
          })
          featuresJstsGeom.reduce((acc, feature) => {
            if (!acc.intersects(feature)) {
              notices.error({
                message: t('Аннотации не пересекаются'),
              })
              throw Error
            }
            return acc
          }, featuresJstsGeom[0])
          mergeRes = featuresJstsGeom.reduce((acc, feature, i) => {
            acc = i > 0 ? acc.intersection(feature) : acc
            return acc
          }, featuresJstsGeom[0])
          break
        }
        case MergeType.SUBTACT: {
          const sortedByArea = featuresJstsGeom.sort((item1, item2) => item2.getArea() - item1.getArea())
          mergeRes = sortedByArea.reduce((acc, feature, i) => {
            acc = i > 0 ? acc.difference(feature) : acc
            return acc
          }, featuresJstsGeom[0])
          break
        }
        case MergeType.UNION: {
          mergeRes = getUnionGeom(featuresJstsGeom)
          break
        }
        case MergeType.DISUNION: {
          mergeRes = getDisunionGeom(featuresJstsGeom)
          break
        }
        case MergeType.INTERIOR: {
          mergeRes = getInteriorGeom(featuresJstsGeom)
          break
        }
      }
      const format = new GeoJSON()
      const getAnnotationAreaByFormattedFeature = (formattedFeature?: string) => {
        let metric = 0
        if (!formattedFeature) return 0
        const annotationF = getFeaturesFromGeoJson(formattedFeature)[0]
        const geom = annotationF.getGeometry()
        if (geom) {
          metric = getPolygonArea(geom, mppX)
        }
        return metric
      }

      if (Array.isArray(mergeRes)) {
        const firstAnnotation = annotations[0]
        const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, annotationsIds[0]])
        annotation && (await deleteAnnotations({ annotations: [annotation] }))
        const annotationsData = mergeRes
          .flat()
          .map((item) => {
            if (item) {
              features[0].setGeometry(parser.write(item))
              unsetSlidePositioningForAnnotation(features[0])
              const formattedFeature = format.writeFeatures([features[0]])

              return {
                data: getClearFeature({
                  formattedFeature: formattedFeature,
                  type: 'ANNOTATION',
                }),
                metric: getAnnotationAreaByFormattedFeature(formattedFeature),
                type: AnnotationType.POLYGON,
                zindex: firstAnnotation?.zindex || 0,
              }
            }
          })
          .filter(Boolean) as Partial<IAnnotation>[]

        if (annotationsData.length) {
          await addAnnotations({
            annotations: annotationsData,
          })
        }
      } else {
        const geometry = parser.write(mergeRes)
        const firstAnnotation = annotations[0]
        const { slideAnnotationId, zindex } = firstAnnotation || {}
        if (geometry.flatCoordinates.length === 0) {
          notices.error({
            message: t('Ошибка операции. Отсутствует геометрия'),
          })
          throw Error
        }
        mergeRes && features[0].setGeometry(geometry)
        unsetSlidePositioningForAnnotation(features[0])

        const data = {
          formattedFeature: format.writeFeatures([features[0]]),
          type: 'ANNOTATION',
        }
        slideAnnotationId &&
          (await editAnnotationAsync({
            caption: firstAnnotation?.caption,
            data: getClearFeature(data),
            metric: getAnnotationAreaByFormattedFeature(data?.formattedFeature),
            slideAnnotationId,
            type: AnnotationType.POLYGON,
            zindex,
          }))
      }
    },
    {
      onSuccess: async (
        _,
        {
          annotationsIds,
        }: {
          caseId: number
          annotationsIds: number[]
          mergeType: MergeType
        },
      ) => {
        const [__, ...annotations] = annotationsIds.map((id) =>
          queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]),
        )
        if (!annotations || annotations.length === 0) {
          return
        }
        const deletedAnnotations = []
        for (const item of annotations) {
          item && deletedAnnotations.push(item)
        }
        await deleteAnnotations({ annotations: deletedAnnotations })
      },
    },
  )
}
