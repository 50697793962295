import { notices } from 'features/notices'
import React, { useEffect } from 'react'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import { IMapOl } from 'types/IMapOl'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, viewerSlice } from 'viewer/container'

import { getFeatureByExtent, getViewportExtent } from './lib/helpers/helpers'
import { useAnnotationAuthorValidation } from './lib/hooks/useAnnotationAuthorValidation'
import { SCREEN_RECORD_ZOOM_LVL, useObjectsCountingContext } from './ui/ObjectsCountingContext'

type Props = {
  map: IMapOl
  slide?: ISlide
  viewerId: TViewerId
}

const ScreenRecordLayer: React.FC<Props> = ({ map, slide, viewerId }) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const { addSpot, addViewedTrack } = useObjectsCountingContext()
  const isAuthorValid = useAnnotationAuthorValidation()

  const changeCenterHandle = () => {
    const resolution = map?.getView()?.getResolution()
    const zoomLvl = getResolutionByZoomLevel(resolution || 0, slide)
    const newExtent = getViewportExtent(map, slide, 8)
    const viewedTrackExtent = getViewportExtent(map, slide, 0)

    if (!newExtent || !viewedTrackExtent || zoomLvl < SCREEN_RECORD_ZOOM_LVL || !isAuthorValid) return

    const feature = getFeatureByExtent(newExtent)
    const viewedTrack = getFeatureByExtent(viewedTrackExtent)
    feature.set('type', 'viewport')
    try {
      addSpot(feature)
      addViewedTrack(viewedTrack)
    } catch (err: any) {
      viewerDispatch(viewerSlice.actions.setObjectsMode({ active: false }))
      notices.error({
        message: err.message,
      })
    }
  }

  useEffect(() => {
    changeCenterHandle()
    map.on('moveend', changeCenterHandle)
    return () => {
      map.un('moveend', changeCenterHandle)
    }
  }, [])
  return null
}

export default ScreenRecordLayer
