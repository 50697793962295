import { useDepartmentsQuery } from 'entities/department/api/query'
import { Department } from 'entities/department/types'
import { useCallback, useMemo } from 'react'
import i18next from 'shared/lib/i18n/i18n'
import { SelectElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const t = i18next.t

type Props = {
  onChange?: (procedureType?: Department) => void
  value?: Department
  placeholder?: string
}
const StyleSelectElement = styled(SelectElement)`
  width: 100%;
`
const DepartmentsSelect = ({ onChange, placeholder = t('Заполняется при наличии'), value, ...props }: Props) => {
  const query = useDepartmentsQuery()
  const createOptions = useMemo(
    () =>
      query.data?.map((it) => ({
        label: it.name,
        value: it.departmentId,
      })),
    [query],
  )

  const onSelect = useCallback(
    (id: number) => {
      if (query.data) {
        const department = query.data.find((it) => it.departmentId === id)
        if (onChange && department) {
          onChange(department)
        }
      }
    },
    [query.data],
  )

  return (
    <StyleSelectElement
      value={value?.departmentId}
      onChange={onSelect}
      options={createOptions}
      placeholder={placeholder}
      loading={query.isLoading}
      {...props}
    />
  )
}

export default DepartmentsSelect
