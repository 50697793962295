export enum MPUserStatus {
  /**Пользователь активен на слайде, его аватарка должна быть цветной */
  Active = 'ACT',
  /** Пользователь подписался на кого-то другого на слайде */
  Subscribed = 'SUB',
  /** Пользователь неактивен 10 минут, аватарка серая */
  Idle = 'IDL',
  /** Пользователь покинул кейс или закрыл вкладку */
  Left = 'LFT',
}

/** Константа для перевода с -> мс */
const FROM_SECOND = 1000
/** Константа для перевода мин -> с */
const FROM_MINUTE = 60

/** Общее время бездействия для перехода из Active -> Idle, мс*/
export const ACTIVE_TO_IDLE_TIME = 10 * FROM_MINUTE * FROM_SECOND
/** Общее время бездействия для перехода из Idle -> Left, мс*/
export const IDLE_TO_LEFT_TIME = 10 * FROM_MINUTE * FROM_SECOND
/** Время для интервальной отправки статуса пользователя на сервер, мс*/
export const PING_INTERVAL_TIME = 5 * FROM_MINUTE * FROM_SECOND
