import { useTypedSelector } from 'app/redux/lib/selector'
import React, { useState } from 'react'
import { css } from 'styled-components'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import PathVisionSegmentationContainer from './PathVisionSegmentationContainer'

/** Props for PathVisionSegmentationToolPanel */
type Props = {
  /** Ссылка на DOM-элемент панели Сегментации опухолевой ткани для простаты */
  pathVisionSegmentationRef: React.RefObject<HTMLDivElement>
  /** Координаты начального расположения панели */
  initPosition: number[]
  /** Коллбэк для установки координат расположения панели */
  onPositionChange: (position: number[]) => void
}

const PathVisionSegmentationToolPanel = ({ initPosition, onPositionChange, pathVisionSegmentationRef }: Props) => {
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const isMagnetize = useTypedSelector((state) => state.viewerPage.isMagnetize['PV_PROSTATE'])

  return (
    <ViewerInfoToolPanelContainer
      id={'PV_PROSTATE'}
      panelRef={pathVisionSegmentationRef}
      type={'PV_PROSTATE'}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      position={position}
      setPosition={setPosition}
      style={css`
        max-height: ${isMagnetize ? '' : 'calc(100% - 96px)'};
        overflow: hidden auto;
      `}
    >
      <PathVisionSegmentationContainer />
    </ViewerInfoToolPanelContainer>
  )
}

export default PathVisionSegmentationToolPanel
