import { ButtonElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

export const FormButton = styled(ButtonElement)`
  @media (max-width: 576px), (hover: none) {
    padding: 4px 16px;
    height: 30px;
  }

  @media (hover: none) {
    &:not(.ant-btn-icon-only):hover {
      opacity: 1 !important;
    }

    &:not(.ant-btn-icon-only):active {
      opacity: 0.7 !important;
    }
  }
`
