import { AvatarElement, ParagraphElement, SpinElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import IUser from 'types/IUser'

type Props = {
  user: IUser
  body: string
}

const Header = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
`

const GhostComment = ({ body, user }: Props) => (
  <SpinElement spinning>
    <Header>
      <AvatarElement numberValue={user.userId} fullname={user.fullname} style={{ gridArea: 'avatar' }} />
      <TitleElement type="secondary" level={4} style={{ gridArea: 'fullname' }} ellipsis>
        {user.fullname}
      </TitleElement>
    </Header>
    <ParagraphElement>{body}</ParagraphElement>
  </SpinElement>
)

export default GhostComment
