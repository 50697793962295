import { Children, FC } from 'react'
import styled from 'styled-components/macro'

const StyledThumbnailList = styled.div`
  background: var(--color-bg-3);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(auto-fill, 54px);
`

const ListItem = styled.div`
  height: 54px;
  width: 54px;
`

const SmallThumbnailsList: FC = ({ children }) => (
  <StyledThumbnailList>
    {Children.map(
      children,
      (item) =>
        item && (
          <ListItem data-testid="thumbnails-slides-in-case" onClick={(e) => e.stopPropagation()}>
            {item}
          </ListItem>
        ),
    )}
  </StyledThumbnailList>
)

export default SmallThumbnailsList
