import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { updatePreviewRotate } from 'features/thumbnails/common/utils'
import { viewerPageSlice } from 'pages/viewer'
import { Resizable } from 're-resizable'
import { Direction } from 're-resizable/lib/resizer'
import React, { useEffect, useState } from 'react'
import { SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector } from 'viewer/container'
import { MARGIN_BORDER, RESIZABLE_OFFSET } from 'viewer/tools/ui/lib/constans'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import { GlassPreviewContainer } from './GlassPreviewContainer'

/** минимальная ширина панели */
const MIN_WIDTH_LABEL = 220
/** начальное состояние */
const INITIAL_SIZES = [248, 0]
/** начальное состояние */
const PIXEL_LENGTH = 2
/** таймаут лоадинга */
const LOADING_TIMEOUT = 1000

const StyledResizable = styled(Resizable)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

type Props = {
  /** slideLabelRef - ссылка на элемент штрихкода */
  slideLabelRef: React.RefObject<HTMLDivElement>
  /** slideId - идентификатор слайда в текущем вьювере */
  slideId: number
  /** caseId - идентификатор случая */
  caseId: number
  /** viewerId - идентификатор вьювера */
  viewerId: TViewerId
  /** source - источник слайда */
  source: ISource
  /** initPosition - координаты блока */
  initPosition: number[]
  /** onPositionChange - устновка параметров координат блока */
  onPositionChange: (position: number[]) => void
}
export const GlassPreviewToolPanel = ({
  caseId,
  initPosition,
  onPositionChange,
  slideId,
  slideLabelRef,
  source,
  viewerId,
}: Props) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const { data: slide } = useSlideQuery({ slideId, source })
  const { isScreenshotModalOpen } = useViewerMainSelector(viewerId)
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const [glassPreviewState, setGlassPreviewState] = useState(slide?.previewUrl || '')
  const [isLoading, setIsLoading] = useState(false)
  const [rotation, setRotation] = useState<number>()
  const glassPreview = useTypedSelector((state) => state.viewerPage.glassPreview)
  const previewRotate = glassPreview[slideId]?.previewRotate || 0
  const [backgroundSizes, setBackgroundSizes] = useState(INITIAL_SIZES)
  const getParentBlock = () => slideLabelRef?.current?.parentElement?.parentElement?.parentElement?.parentElement
  const [maxHeight, setMaxHeight] = useState<number>(0)
  const [maxWidth, setMaxWidth] = useState<number>(0)
  const parentBlockBottomCoordinate = getParentBlock()?.getBoundingClientRect().bottom || 0
  const parentBlockRightCoordinate = getParentBlock()?.getBoundingClientRect().right || 0

  useEffect(() => {
    setRotation(previewRotate)
  }, [previewRotate, slideId])

  const resizeTool = (el?: HTMLElement) => {
    const editBoxElement = document.getElementsByClassName('edit-box')[0]
    const toolElement = el || editBoxElement?.parentElement
    // @ts-ignore
    const { height, left, top, width } = toolElement.getBoundingClientRect()
    const bottomCoordinate = height + top
    const rightCoordinate = width + left

    if (
      (bottomCoordinate > parentBlockBottomCoordinate - MARGIN_BORDER ||
        rightCoordinate > parentBlockRightCoordinate - MARGIN_BORDER) &&
      toolElement?.style.width &&
      toolElement?.style.height
    ) {
      const newWidth = +toolElement?.style?.width.slice(0, -PIXEL_LENGTH)
      const newHeight = +toolElement?.style?.height.slice(0, -PIXEL_LENGTH)
      setBackgroundSizes([newWidth, newHeight])
    } else {
      setBackgroundSizes([width, height])
    }
  }
  const onResizeHandler = (w: MouseEvent | TouchEvent, direction: Direction, el: HTMLElement) => resizeTool(el)

  const getParentBlockSizes = () => {
    const parentBlock = getParentBlock()

    return [parentBlock?.getBoundingClientRect().width || 0, parentBlock?.getBoundingClientRect().height || 0]
  }
  const parentBlockHeight = getParentBlockSizes()[1]
  const parentBlockWidth = getParentBlockSizes()[0]

  useEffect(() => {
    setMaxWidth(parentBlockWidth - RESIZABLE_OFFSET)
    setMaxHeight(parentBlockHeight - RESIZABLE_OFFSET)
  }, [parentBlockWidth, parentBlockHeight])

  const onClickHandlerRotate = () => {
    const updatedRotate = updatePreviewRotate(glassPreview, slideId)

    return viewerDispatch(viewerPageSlice.actions.setGlassPreview(updatedRotate))
  }

  useEffect(() => {
    const { previewUrl, proxiedPreviewUrl } = slide || {}

    if (proxiedPreviewUrl && isScreenshotModalOpen === true) {
      setGlassPreviewState(proxiedPreviewUrl)
      return
    }
    if (previewUrl && glassPreviewState === proxiedPreviewUrl && isScreenshotModalOpen === false) {
      setIsLoading(true)
      setGlassPreviewState(previewUrl)

      const timeoutId = setTimeout(() => {
        setIsLoading(false)
      }, LOADING_TIMEOUT)

      return () => clearTimeout(timeoutId)
    }

    if (previewUrl && previewUrl !== glassPreviewState) {
      setGlassPreviewState(previewUrl)
    }
  }, [slideId, isScreenshotModalOpen])

  if (!slide?.previewUrl && !slide?.proxiedPreviewUrl) return null

  return (
    <ViewerInfoToolPanelContainer
      panelRef={slideLabelRef}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      onClickHandlerRotate={onClickHandlerRotate}
      type={'GLASS_PREVIEW'}
      id={'GLASS_PREVIEW'}
      position={position}
      setPosition={setPosition}
      mapSizes={backgroundSizes}
    >
      <SpinElement spinning={isLoading}>
        <StyledResizable
          minWidth={MIN_WIDTH_LABEL}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          lockAspectRatio={true}
          size={{
            height: backgroundSizes[1] ? backgroundSizes[1] : '',
            width: backgroundSizes[0] ? backgroundSizes[0] : '',
          }}
          handleStyles={{ bottomRight: { bottom: -5, right: -5, zIndex: 10 } }}
          onResize={onResizeHandler}
          enable={{ bottomRight: true }}
        >
          <GlassPreviewContainer
            rotation={rotation}
            setRotation={setRotation}
            slideId={slideId}
            caseId={caseId}
            source={source}
            glassPreviewSrc={glassPreviewState}
            backGroundSizes={backgroundSizes}
            setBackGroundSizes={setBackgroundSizes}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
        </StyledResizable>
      </SpinElement>
    </ViewerInfoToolPanelContainer>
  )
}
