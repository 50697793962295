import { useTypedSelector } from 'app/redux/lib/selector'
import { workspacesSlice } from 'features/workspace/model/workspacesSlice'
import { workspaceReLogin } from 'processes/user/model/userSlice'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { QUERY_TYPE } from 'shared/api'
import { getStorageWorkspaceId, getWorkspaceIdUrl } from 'shared/lib/workspaces'
import { IWorkspace } from 'types/IWorkspaces'

import workspacesService from './service'

export const useWorkspacesQuery = () => {
  const currentLocation = useLocation()
  const dispatch = useDispatch()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const workspaceIdUrl = getWorkspaceIdUrl(currentLocation.pathname.toString())
  const storageWorkspaceId = getStorageWorkspaceId()
  return useQuery<IWorkspace[] | undefined>([QUERY_TYPE.WORKSPACES], async () => workspacesService.getWorkspaces(), {
    cacheTime: Infinity,
    onSuccess: (workspaces) => {
      if (!workspaces) return
      if (workspaceIdUrl && workspaceIdUrl !== 'undefined') {
        const newCurrentWorkspace = workspaces.find((item) => +item.workspaceId === +workspaceIdUrl)
        const defaultWorkspace = workspaces.find((item) => item.personal) || workspaces[0]
        if (!newCurrentWorkspace) return defaultWorkspace && dispatch(workspaceReLogin(defaultWorkspace))
        localStorage.setItem('workspaceId', newCurrentWorkspace.workspaceId)
        dispatch(workspacesSlice.actions.setCurrentWorkspace(newCurrentWorkspace))
        return
      }
      if (storageWorkspaceId) {
        const newCurrentWorkspace = workspaces.find((item) => +item.workspaceId === +storageWorkspaceId)
        const defaultWorkspace = workspaces.find((item) => item.personal) || workspaces[0]
        if (!newCurrentWorkspace) return defaultWorkspace && dispatch(workspaceReLogin(defaultWorkspace))
        dispatch(workspacesSlice.actions.setCurrentWorkspace(newCurrentWorkspace))
        return
      }
      if (!currentWorkspace) {
        const newCurrentWorkspace = workspaces.find((item) => item.personal)
        const defaultWorkspace = workspaces.find((item) => item.personal) || workspaces[0]
        if (!newCurrentWorkspace) return defaultWorkspace && dispatch(workspaceReLogin(defaultWorkspace))
        localStorage.setItem('workspaceId', newCurrentWorkspace.workspaceId)
        dispatch(workspacesSlice.actions.setCurrentWorkspace(newCurrentWorkspace))
        return
      }
    },
    staleTime: Infinity,
  })
}
