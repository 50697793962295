import { del, get, post } from 'shared/api'
import IListOfItems from 'types/api/IListOfItems'
import IComment from 'types/IComment'
import ICommentReadStatus from 'types/ICommentReadStatus'

const fetchComments = async (
  caseId: number,
  params?: {
    page?: number
    size?: number
  },
) =>
  get<IListOfItems<IComment>>({
    config: {
      params,
    },
    url: `/case/${caseId}/comment`,
  })

export type PostCommentPayload = Pick<IComment, 'attachments' | 'body'>

const postComment = async (caseId: number, payload: PostCommentPayload) =>
  post<IComment>({
    data: payload,
    url: `/case/${caseId}/comment`,
  })

const updateComment = async (caseId: number, commentId: number, payload: PostCommentPayload) =>
  post<IComment>({
    data: payload,
    url: `/case/${caseId}/comment/${commentId}`,
  })

const deleteComment = async (caseId: number, commentId: number) =>
  del<IComment>({
    url: `/case/${caseId}/comment/${commentId}`,
  })

const setLastReadCommentId = async (caseId: number, commentInfo: ICommentReadStatus) =>
  post<ICommentReadStatus>({
    data: commentInfo,
    url: `/case/${caseId}/comment/read`,
  })

const commentsService = {
  deleteComment,
  fetchComments,
  postComment,
  setLastReadCommentId,
  updateComment,
}
export default commentsService
