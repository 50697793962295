import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery } from 'features/cases/api/query'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { SideRightPanelType, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'
import { Center, SpinElement } from 'shared/ui/kit'

import ViewerRightPanel from './ViewerRightPanel'

const RightPanelContainer: FC = () => {
  const queryParams = useQueryParams()
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { isLoading } = useCaseQuery({ caseId, source })
  const dispatch = useDispatch()

  useEffect(() => {
    const commentId: string | null = queryParams.get('commentId')
    const reportId: string | null = queryParams.get('medicalReportId')
    if (commentId !== null && reportId !== null) return
    if (commentId !== null) {
      dispatch(viewerPageSlice.actions.setViewerRightPanel(SideRightPanelType.COMMENTS))
    } else if (reportId !== null) {
      dispatch(viewerPageSlice.actions.setViewerRightPanel(SideRightPanelType.CASE))
    }
  }, [])

  if (isLoading) {
    return (
      <Center>
        <SpinElement />
      </Center>
    )
  }
  return (
    <>
      <ViewerRightPanel
        urlSlideId={slideId}
        urlCaseId={caseId}
        source={source}
        activeTab={rightPanelType}
        viewerId={viewerId}
        activeViewerCaseId={caseId}
        activeViewerSlideId={slideId}
        activeViewerSource={source}
        type={'DEFAULT'}
      />
    </>
  )
}

export default RightPanelContainer
