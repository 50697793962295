import { Feature } from 'ol'
import { Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import type olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import React, { useEffect, useRef } from 'react'
import TViewerId from 'types/TViewerId'
import { defaultStyle } from 'viewer/map/layers/olStyles'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'

type Props = {
  map: olMap
  viewerId: TViewerId
  features: Feature<Polygon>[]
}

const VectorViewedTracksLayer: React.FC<Props> = ({ features, map }) => {
  const layer = useRef<VectorLayer<any>>(
    new VectorLayer({
      source: new VectorSource({}),
      style: [defaultStyle],
      zIndex: 20000,
    }),
  )

  useEffect(() => {
    layer.current.set(INTERNAL_TYPE_ID, LayerType.MITOSIS_VIEWED_TRACK)
    map.addLayer(layer.current)
    return () => {
      map.removeLayer(layer.current)
    }
  }, [])

  useEffect(() => {
    layer.current?.setSource(
      new VectorSource({
        features,
      }),
    )
  }, [features])

  return null
}

export default VectorViewedTracksLayer
