import { Coordinate } from 'ol/coordinate'

export enum AnnotationType {
  PEN = 'PEN',
  RULER = 'RULER',
  RECTANGLE = 'RECTANGLE',
  POLYGON = 'POLYGON',
  MULTIPOLYGON = 'MultiPolygon',
  CIRCLE = 'CIRCLE',
  POINT = 'POINT',
  ARROW = 'ARROW',
  MITOSIS = 'MITOSIS',
  OBJECTS = 'OBJECTS',
  PEN_POLYGON = 'PEN_POLYGON',
}

export enum AnnotationTypeName {
  PEN = 'Рисование',
  RULER = 'Линейка',
  RECTANGLE = 'Прямоугольник',
  POLYGON = 'Полигон',
  CIRCLE = 'Круг',
  POINT = 'Точка',
  ARROW = 'Стрелка',
}

export type IAnnotationInfo = {
  // количество точек митозов
  mitosis: number
}

export type FilterPeriod = {
  /** Дата начала выбора */
  fromDate: Date | null
  /** Дата окончания выбора */
  toDate: Date | null
}

export type AnnotationFilter = {
  // список IDs выбраных поьзоветлей авторов аннотаций
  userIds?: number[] | null
  // выбранный период дат создания аннотаций
  period?: FilterPeriod | null
}

export type ISimpleAnnotation = {
  /** идентификатор аннотации */
  slideAnnotationId: number
  /** идентификатор результата разметки */
  markupResultId: number
  /** описание аннотации */
  description: string
  /** автор аннотации */
  user: string
  /** идентификатор автора аннотации */
  userId: number
  /** идентификатор слайда */
  slideId: number
  /** тип аннотации */
  type: AnnotationType
  /** дата/время обновления аннотаци **/
  updatedAt: string //'2021-05-31T13:04:32'
  /** дата/время создания аннотации */
  createdAt: string //'2021-05-31T13:04:32'
  /** метрика аннотации */
  metric: number
  /** класс аннотации */
  class: string
  // дополнительная инфа для митозов (количество)
  info: IAnnotationInfo
  /** подпись аннотации */
  caption?: string
  /** слой аннотации */
  zindex?: number
}

/**
 * Интерфейс импорта аннотаций задачи.
 * @interface
 */
export type ITaskAnnotationsImport = {
  /** Дата создания аннотации. */
  createdAt: string
  /** Данные новой аннотации. */
  data: INewAnnotationDataObject
  /** Идентификатор результата разметки. */
  markupResultId: number
  /** Метрика аннотации. */
  metric: number
  /** Идентификатор аннотации. */
  slideAnnotationId: number
  /** Идентификатор слайда. */
  slideId: number
  /** Тип аннотации. */
  type: AnnotationType
  /** Дата обновления аннотации. */
  updatedAt: string
  /** Идентификатор пользователя. */
  userId: number
}

export type IAnnotation = {
  data?: INewAnnotationDataObject
} & ISimpleAnnotation

export type INewAnnotationDataObject = {
  type: 'ANNOTATION' | string
  formattedFeature?: string
}

/** аннотации для слайда */
export interface ISlideAnnotation {
  /** идентификатор для слайда */
  slideId: number
  /** массив аннотаций */
  annotations: IAnnotation[]
}

export type IAnnotationTooltip = {
  text: string
  position: Coordinate
}

export type ITaskAnnotation = {
  type: string
  geometry: {
    type: string
    coordinates: []
  }
  properties: {
    description: string
    annotation_type: AnnotationType
    annotationZindex: number
    class: string
    slideAnnotationId: number
    userId: number
    user: string
    changed: boolean
  }
  features?: ITaskAnnotation[]
}

export type IAnnotationTaskViewer = {
  markupAnnotationId: number
  markupResultId: number
  annotation: ITaskAnnotation
  annotationType: AnnotationType
  createdBy: number
  updatedBy: number
  createdAt: string
  updatedAt: string
}

export type IAnnotationStack = {
  annotation: IAnnotation[] | IAnnotation
  type: 'add' | 'del' | 'edit'
}
export type IClickConditionType = 'singleClick' | 'dbClick' | 'default'
