import { Avatar, Space } from 'antd'
import { MPUserStatus } from 'features/multiplayer/lib/user-status'
import { MPUserStateDto } from 'features/multiplayer/lib/user-status/types'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AvatarElement, DropdownElement, MenuElement, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

/** MAX_USER_VISIBLE_COUNT - Максимальное количество отображаемых пользователей, если они равны или меньше 3 */
const MAX_USER_VISIBLE_COUNT = 3
/** ADDITIONAL_USERS - Максимальное количество отображаемых пользователей, если они больше 3 */
const ADDITIONAL_USERS = 2

const StyledAvatar = styled(Avatar)`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: 2px var(--color-text-3) solid;
`

const StyledMenuContainer = styled.div`
  margin-top: 16px;
`
const StyledMenuItem = styled.div`
  padding: 0 8px;
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 28px;
  width: 232px;
  &:hover {
    background-color: var(--color-bg-3);
  }
`
const StyledTitleContainer = styled.div`
  align-items: center;
  display: inline-grid;
  height: 28px;
  width: 171px;
`
const StyledTitleElement = styled(TitleElement)`
  color: var(--color-text-1) !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
`
const StyledTitleEmail = styled(TitleElement)`
  margin: 0 !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: var(--color-text-3) !important;
`
const StyledTitleName = styled(TitleElement)`
  margin: 0 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
`

type Props = {
  users: MPUserStateDto[]
  targetUserId?: number
  onUserClick: (user: MPUserStateDto) => void
}

const ActiveUsers = ({ onUserClick, targetUserId, users }: Props) => {
  const menuRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const userValue = Object.values(users)
  const usersLength = users.length
  const usersOverflow = userValue.length > MAX_USER_VISIBLE_COUNT ? userValue.slice(ADDITIONAL_USERS) : userValue
  const usersVisible = userValue.length > MAX_USER_VISIBLE_COUNT ? userValue.slice(0, ADDITIONAL_USERS) : userValue
  const { t } = useTranslation()
  useOnClickOutside(menuRef, () => setOpenMenu(false))

  const menu = (
    <StyledMenuContainer>
      <MenuElement style={{ gap: 10, maxHeight: 596, overflowX: 'hidden', overflowY: 'auto' }} selectable={false}>
        {usersOverflow.map((user) => {
          const { name, sta, uid } = user

          return (
            <StyledMenuItem onClick={() => onUserClick(user)} key={uid}>
              <AvatarElement
                fullname={name}
                numberValue={uid}
                ghost={sta === MPUserStatus.Idle}
                eye={uid === targetUserId}
              />
              <StyledTitleContainer>
                <StyledTitleName level={5} ellipsis>
                  {name}
                </StyledTitleName>
                <StyledTitleEmail level={5} ellipsis>
                  {t('Добавьте почту')}
                </StyledTitleEmail>
              </StyledTitleContainer>
            </StyledMenuItem>
          )
        })}
      </MenuElement>
    </StyledMenuContainer>
  )
  return (
    <Space size={8}>
      {usersVisible.map((user, i) => {
        const { name, opn, sta, uid } = user
        return (
          <TooltipElement
            title={
              i === 0 || sta === MPUserStatus.Idle ? (
                name
              ) : (
                <>
                  {name}
                  <br />
                  <TitleElement style={{ color: 'white' }} level={4}>
                    {t('Кликните, чтобы увидеть область.')}
                  </TitleElement>
                </>
              )
            }
            placement="top"
            key={uid}
          >
            <AvatarElement
              onClick={() => {
                onUserClick(user)
              }}
              fullname={name}
              numberValue={uid}
              ghost={sta === MPUserStatus.Idle}
              eye={uid === targetUserId}
            />
          </TooltipElement>
        )
      })}
      {usersLength > MAX_USER_VISIBLE_COUNT && (
        <DropdownElement placement={'bottomCenter'} visible={openMenu} overlay={menu} trigger={['click']}>
          <div ref={menuRef} onClick={() => setOpenMenu(!openMenu)}>
            <StyledAvatar size="small" shape="circle">
              <StyledTitleElement>{usersLength - ADDITIONAL_USERS}</StyledTitleElement>
            </StyledAvatar>
          </div>
        </DropdownElement>
      )}
    </Space>
  )
}

export default ActiveUsers
