import Avatar, { AvatarProps } from 'antd/es/avatar/avatar'
import styled from 'styled-components/macro'

import { IconElement } from './IconElement'

const styles = {
  0: {
    backgroundColor: 'var(--color-blue)',
    color: 'white',
  },
  1: {
    backgroundColor: 'var(--color-red)',
    color: 'white',
  },
  2: {
    backgroundColor: 'var(--color-green)',
    color: 'white',
  },
  3: {
    backgroundColor: 'var(--color-yellow)',
    color: 'black',
  },
  4: {
    backgroundColor: 'var(--color-emerald)',
    color: 'white',
  },
} as Record<number, any>

const invertedStyles = {
  0: {
    backgroundColor: 'var(--color-bg-3)',
    color: 'var(--color-blue)',
  },
  1: {
    backgroundColor: 'var(--color-bg-3)',
    color: 'var(--color-red)',
  },
  2: {
    backgroundColor: 'var(--color-bg-3)',
    color: 'var(--color-green)',
  },
  3: {
    backgroundColor: 'var(--color-bg-3)',
    color: 'var(--color-yellow)',
  },
  4: {
    backgroundColor: 'var(--color-bg-3)',
    color: 'var(--color-emerald)',
  },
} as Record<number, any>

const borderStyles = {
  0: {
    borderColor: 'var(--color-blue)',
  },
  1: {
    borderColor: 'var(--color-red)',
  },
  2: {
    borderColor: 'var(--color-green)',
  },
  3: {
    borderColor: 'var(--color-yellow)',
  },
  4: {
    borderColor: 'var(--color-emerald)',
  },
} as Record<number, any>

type Props = {
  numberValue: number
  fullname: string
  ghost?: boolean
  eye?: boolean
  onClick?: () => void
  noInteract?: boolean
} & AvatarProps

const StyledAvatar = styled(Avatar)`
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  display: grid;
  place-items: center;

  span {
    display: grid;
    place-items: center;
    font-weight: 700;
  }
`

export const AvatarElement = ({ eye, fullname, ghost, noInteract, numberValue, onClick, ...rest }: Props) => {
  const style = getUserColorStyles(numberValue, ghost)
  return (
    <StyledAvatar size="small" shape="circle" {...rest} style={{ cursor: 'pointer', ...style, ...rest.style }}>
      {eye ? <IconElement name="eye" onClick={onClick} /> : <span onClick={onClick}>{getValue(fullname)}</span>}
    </StyledAvatar>
  )
}

export const getUserColorStyles = (userId: number, ghost?: boolean) =>
  ghost ? invertedStyles[userId % 5] : styles[userId % 5]

export const getUserColorBorderStyles = (userId: number) => borderStyles[userId % 5]

const getValue = (fullname?: string) =>
  fullname
    ?.toUpperCase()
    ?.split(' ')
    .map((item) => item[0])
    .filter((item, i) => i < 1)
    .join('')
