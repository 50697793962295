import Empty, { EmptyProps } from 'antd/es/empty'
import { TMode, useThemeContext } from 'app/styled/ThemeProvider'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const StyledEmpty = styled(Empty)<{ theme: TMode }>`
  .ant-empty-img-simple-path {
    fill: ${({ theme }) => (theme === 'dark' ? 'var(--color-bg-4)' : 'var(--color-bg-1)')};
  }
  .ant-empty-img-simple-ellipse {
    fill: ${({ theme }) => (theme === 'dark' ? 'var(--color-bg-4)' : 'var(--color-bg-1)')};
  }
  .ant-empty-img-simple-g {
    stroke: ${({ theme }) => (theme === 'dark' ? 'var(--color-text-1)' : 'var(--color-border-1)')};
  }
  &.ant-empty-normal {
    color: var(--color-text-3);
  }
`

export const EmptyElement = (props: EmptyProps) => {
  const colorTheme = useThemeContext()
  const { t } = useTranslation()
  return (
    <StyledEmpty
      theme={colorTheme.theme}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>{t('Ничего нет')}</span>}
      {...props}
    />
  )
}
