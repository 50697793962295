import { FormInstance } from 'antd'
import { useValidations } from 'features/reports/ui/lib/useValidations'
import { ReportFormData } from 'features/reports/ui/ReportCreationForm'
import { Footer } from 'features/reports/ui/styled'
import { useTranslation } from 'react-i18next'
import { ButtonElement } from 'shared/ui/kit'
import IReport from 'types/IReport'

type Props = {
  /** Опциональный начальный отчет, который может быть частично заполнен и используется для предзаполнения формы. */
  initialReport: Partial<IReport>
  /** Экземпляр формы, используемой для редактирования заключения. */
  form: FormInstance<ReportFormData>
  /** Функция, вызываемая при сохранении заключения. */
  onSave: () => void
  /** Функция, вызываемая при удалении заключения. Принимает идентификатор заключения в качестве параметра. */
  onDelete: (reportId: number) => void
  /** Флаг, который указывает, что отчет в данный момент сохраняется. */
  isSaving: boolean
  /** Флаг, который указывает, что отчет в данный момент удаляется. ы*/
  isRemoveLoading: boolean
  /** Опциональная функция, вызываемая при отмене редактирования заключения.*/
  onCancel?: () => void
}

export const CommonFooter = ({ form, initialReport, isRemoveLoading, isSaving, onCancel, onDelete, onSave }: Props) => {
  const { t } = useTranslation()
  const { isValidCommonRequired } = useValidations(initialReport, form)
  return (
    <Footer>
      {initialReport?.medicalReportId && (
        <ButtonElement
          danger
          style={{ gridColumn: 1 }}
          onClick={() => initialReport.medicalReportId && onDelete(initialReport.medicalReportId)}
          loading={isRemoveLoading}
          disabled={isRemoveLoading}
        >
          {t('Удалить')}
        </ButtonElement>
      )}
      <ButtonElement style={{ gridColumn: 5 }} onClick={onCancel}>
        {t('Отменить')}
      </ButtonElement>
      <ButtonElement
        type="primary"
        style={{ gridColumn: 6 }}
        onClick={onSave}
        loading={isSaving}
        disabled={!isValidCommonRequired() || isSaving}
      >
        {t('Сохранить')}
      </ButtonElement>
    </Footer>
  )
}
