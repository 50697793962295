import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import Dashboard from 'features/dashboard/Dashboard'
import dashboardSlice from 'features/dashboard/dashboardSlice'
import { useInitMockCases } from 'features/dashboard/data/dashboardMockCases'
import DashboardNavigationType from 'features/dashboard/navigation/DashboardNavigationType'
import useGetPatientsMap from 'features/dashboard/patient/useGetPatientsMap'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'

export const DashboardRoute = () => {
  const dispatch = useDispatch()
  const selectedPatient = useTypedSelector((state) => state.dashboard.selectedPatient)
  const patientsMap = useGetPatientsMap()
  const queryParams = useQueryParams()
  const patientsLength = Object.values(patientsMap).length
  const filter = queryParams.get('filter') as DashboardNavigationType
  useEffect(() => {
    if (!filter) {
      dispatch(push(`/dashboard?filter=${DashboardNavigationType.New}`))
    }
  }, [filter])
  useInitMockCases()
  useEffect(() => {
    const firstPatient = Object.values(patientsMap)[0]
    if (firstPatient && !selectedPatient) {
      dispatch(dashboardSlice.actions.selectPatient(firstPatient))
    }
  }, [patientsLength, selectedPatient])
  return <Dashboard />
}
