import { SumpSlidesFilters } from 'features/sump/api/query'
import { useEffect, useMemo, useState } from 'react'

export const useSortState = (filtersClose: SumpSlidesFilters) => {
  const [sortState, setSortState] = useState<SumpSlidesFilters>({})

  useEffect(() => {
    setSortState(filtersClose)
  }, [filtersClose])

  const sortOrderState = useMemo(() => {
    if (sortState?.sortBy) {
      if (sortState.ascDirection) {
        return 'ascend'
      } else if (!sortState.ascDirection) {
        return 'descend'
      }
    } else {
      return null
    }
  }, [sortState])

  return sortOrderState
}
