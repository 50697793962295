import TViewerId from 'types/TViewerId'

export const initFilteredConsoleError = () => {
  const error = console.error

  const filterError = (message: any, ...rest: any[]) => {
    if (!message || typeof message !== 'string' || typeof message.match !== 'function') {
      error(message, ...rest)
      return
    }
    if (message.match('Warning: Function components cannot be given refs.')) {
      console.log('%c Hidden warning --- react ref in ant', 'background: #222; color: #bada55')
      return
    }
    if (rest[0] === 'findDOMNode') {
      console.log('%c Hidden warning --- react findDOMNode in ant', 'background: #222; color: #bada55')
      return
    }
    if (message.match('Warning: Received `%s` for a non-boolean attribute')) {
      console.log('%c Hidden warning --- styled-components forward props warning', 'background: #222; color: #bada55')
      return
    }
    error(message, ...rest)
  }

  console.error = filterError
}

export const logFileStoragePath = (slideId: number, slidePreview: string, viewerId: TViewerId, viewerColor: string) => {
  console.log(
    `\%cSLIDE-ID - ${slideId} ` + ` \%cPATH ---> ${getFilePath(slidePreview)}` + ` \%c(viewer: ${viewerId})`,
    'background: #222; color: #dc524c',
    'background: #222; color: #f8cd76',
    `background: #222; color: ${viewerColor}`,
  )
}

const getFilePath = (slidePreview: string) => {
  const noPrefix = decodeURIComponent(slidePreview).split('iiif/2/')[1]
  if (noPrefix) {
    const noSuffix = noPrefix.split('/full')[0]
    return noSuffix || slidePreview
  }
  return slidePreview
}
