import { Space } from 'antd'
import React from 'react'
import { formatDateForDisplay, formatDateTimeForDisplay } from 'shared/lib/date'
import i18next from 'shared/lib/i18n/i18n'
import { IconElement, ImageElement } from 'shared/ui/kit'
import { PopoverComponent } from 'shared/ui/popover'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'
import { IMarkupParticipant, IStats } from 'types/IMarkupTask'

import { ProgressBar } from './ProgressBar'

const t = i18next.t

const DEFAULT_VALUE = '-'
// максимальное количество разметчиков для отображения в ячейке по умолчанию
export const MAX_PARTICIPANT_COUNT = 2

export const stainRenderer = (stain: DictionaryItem) => stain?.name

const Image = styled(ImageElement)`
  width: 256px;
  height: 256px;
`
const PreviewContent = styled.div`
  cursor: pointer;
`

export const GoodStatus = styled.div`
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  padding: 0px 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  background: var(--color-green);
`

export const BadStatus = styled(GoodStatus)`
  color: var(--color-text-1);
  background: var(--color-red);
`

const OverButtonStyled = styled.div`
  min-width: 24px;
  width: fit-content;
  height: 24px;
  border-radius: 12px;
  background: var(--color-bg-4);
  padding: 0 4px;

  color: var(--color-text-1);
  font-size: 12px;
  font-weight: 600;

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  display: grid;
  place-items: center;
  cursor: pointer;
`

export const previewPopupRenderer = (src: string) => (
  <PopoverComponent trigger={'hover'} placement="leftBottom" content={<Image src={src} />}>
    <PreviewContent>
      <IconElement name={'dot'} />
    </PreviewContent>
  </PopoverComponent>
)

const ProgressWrapper = styled.div`
  font-size: 0;
  line-height: 0;
`

const ProgressTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 12px;
`

const ProgressBarWrapper = styled.div`
  margin-top: 1px;
`

export const dateRenderer = (timestampString: string) =>
  timestampString ? formatDateForDisplay(timestampString) : DEFAULT_VALUE
export const dateTimeRenderer = (timestampString: string) =>
  timestampString ? formatDateTimeForDisplay(new Date(timestampString)) : DEFAULT_VALUE

export const progressContainerRenderer = (participants: IMarkupParticipant[], total: number, open: boolean) => {
  if (!participants.length) {
    return null
  }

  const annotators = participants.filter((item) => item.access === 'ANNOTATE')
  const totalMarkups = participants?.reduce<IStats>(
    (total, { stats }) => ({
      approved: total.approved + stats.approved,
      done: total.done + stats.done,
      rework: total.rework + stats.approved,
      wip: total.wip + stats.wip,
      withoutResult: total.withoutResult + stats.withoutResult,
    }),
    {
      approved: 0,
      done: 0,
      rework: 0,
      wip: 0,
      withoutResult: 0,
    },
  )
  const totalAnnotateUsers = annotators.length

  return (
    <Space size={20} style={{ width: '100%' }} direction="vertical">
      {annotators
        ?.filter((participant, index) => open || index < MAX_PARTICIPANT_COUNT)
        .map((item, i) => (
          <ProgressWrapper key={i}>
            <ProgressTextWrapper>
              <div>{`${item.stats.approved}/${item.stats.approved + item.stats?.done}`}</div>
              <div>{`${t('из')} ${total}`}</div>
            </ProgressTextWrapper>
            <ProgressBarWrapper>
              <ProgressBar approved={item.stats?.approved} done={item.stats?.done} total={total} />
            </ProgressBarWrapper>
          </ProgressWrapper>
        ))}
      {totalAnnotateUsers > MAX_PARTICIPANT_COUNT && (
        <ProgressWrapper>
          <ProgressTextWrapper>
            <div>{`${totalMarkups?.approved}/${totalMarkups?.approved + totalMarkups?.done}`}</div>
            <div>{`${t('из')} ${total * totalAnnotateUsers}`}</div>
          </ProgressTextWrapper>
          <ProgressBarWrapper>
            <ProgressBar
              approved={totalMarkups?.approved}
              done={totalMarkups?.done}
              total={total * totalAnnotateUsers}
            />
          </ProgressBarWrapper>
        </ProgressWrapper>
      )}
    </Space>
  )
}

export const overButton = (open: boolean, onClick: () => void, count: number) => (
  <OverButtonStyled
    onClick={(e) => {
      e.stopPropagation()
      onClick()
    }}
  >
    {open ? '-' : `+${count}`}
  </OverButtonStyled>
)

export const caseRenderer = (caseName: string) => caseName?.split('_')[0]
