import { useSlideQuery } from 'entities/slide'
import { useSegmentationQuery } from 'entities/slide-layers'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext } from 'react'
import { MapContext } from 'shared/lib/map'
import { IResultType } from 'types/ISlideMetadata'

import SegmentationLayer from './SegmentationLayer'

const SegmentationLayersContainer = () => {
  const { viewerId } = useContext(MapContext)
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const { data: slide } = useSlideQuery({
    caseId,
    slideId,
    source,
  })
  const result = slide?.slideMetadata?.caseSpecificMetadata?.result || {}
  const { data: segmentationFeatures } = useSegmentationQuery(caseId, slideId)
  return (
    <>
      {Object.keys(result).map((key) => {
        const classId = result[key as IResultType]?.classId
        const filteredFeatures = segmentationFeatures?.filter(
          (feature) => feature.getProperties().class_id === Number(result[key as IResultType]?.classId),
        )
        return (
          classId &&
          filteredFeatures && (
            <SegmentationLayer features={filteredFeatures} type={key as IResultType} key={key} classId={classId} />
          )
        )
      })}
    </>
  )
}

export default SegmentationLayersContainer
