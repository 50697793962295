import { AnyAction, CaseReducer } from '@reduxjs/toolkit'
/**
 * Use this method to create listeners for all viewers in nonviewer slices. Like comments, viewerLayout, etc.
 */
function createViewerExtraReducers<S extends any, Action extends AnyAction>(
  actionType: string,
  reducer: CaseReducer<S, Action>,
) {
  return {
    [`A-${actionType}`]: reducer,
    [`B-${actionType}`]: reducer,
    [`C-${actionType}`]: reducer,
    [`D-${actionType}`]: reducer,
  }
}

export default createViewerExtraReducers
