import { UserFilter } from 'pages/sump/lib/types'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import { del, get, post, put } from 'shared/api'
import IListOfItems from 'types/api/IListOfItems'
import { EUploadedFileAttachmentState, EUploadedFileType } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

type FetchUploadedFilesParams = {
  uploadedFileTab?: string
  /** Состояние для поиска */
  uploadedFileAttachmentStates?: string
  page?: number
  size?: number
  query?: string
  /** Штрихкод слайда */
  barcode?: string
}

export type AttachUploadedFilesDataType = {
  uploadedFileIds: number[]
  targetCaseId: number
  targetCaseSlideReferenceId?: number
  caseName?: string | null
}

export type DeleteUploadFilesDataType = {
  uploadedFileIds: number[]
}

export type SumpFilesCount = {
  attachedCount: number
  awaitedCaseCount: number
  deletedCount: number
  notAttachedCount: number
  workspaceId: number
}

export type UploadedFilesAvailableFilters = {
  fileTypes: EUploadedFileType[]
  states: EUploadedFileAttachmentState[]
  users: UserFilter[]
  uploadedFileTab: SUMP_TAB
  workspaceId: number
}

const BASE = '/uploaded-file'

const fetchUploadedFiles = (params: FetchUploadedFilesParams) =>
  get<IListOfItems<IUploadedFileDTO>>({
    config: {
      params,
    },
    url: BASE,
  })

const fetchUploadedDuplicateFiles = (params: FetchUploadedFilesParams) =>
  get<IListOfItems<IUploadedFileDTO>>({
    config: {
      params,
    },
    url: BASE,
  })

const fetchAvailableFilters = (params: FetchUploadedFilesParams) =>
  get<UploadedFilesAvailableFilters>({
    config: {
      params,
    },
    url: BASE + '/filter',
  })

const deletedUploadedFiles = (data: DeleteUploadFilesDataType) =>
  del({
    config: {
      data,
    },
    url: BASE,
  })

const attachUploadedFiles = (data: AttachUploadedFilesDataType) =>
  post({
    data,
    url: BASE + '/attach',
  })

const listUploadedFiles = (params: FetchUploadedFilesParams) =>
  get<IListOfItems<IUploadedFileDTO>>({
    config: {
      params,
    },
    url: BASE + '/trash',
  })

const moveToTrash = (data: DeleteUploadFilesDataType) =>
  put({
    config: {},
    data,
    url: BASE + '/trash',
  })

const restoreFromTrash = (data: DeleteUploadFilesDataType) =>
  put({
    config: {},
    data,
    url: BASE + '/restore',
  })

const fetchFilesCount = () =>
  get<SumpFilesCount>({
    url: BASE + '/count',
  })

const sumpService = {
  attachUploadedFiles,
  deletedUploadedFiles,
  fetchAvailableFilters,
  fetchFilesCount,
  fetchUploadedDuplicateFiles,
  fetchUploadedFiles,
  listUploadedFiles,
  moveToTrash,
  restoreFromTrash,
}

export default sumpService
