import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { selectUrlWorkspaceId } from 'pages/viewer/model/viewerPageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getStorageWorkspaceId } from 'shared/lib/workspaces'
import { IWorkspace } from 'types/IWorkspaces'

export const useCurrentWorkspaceId = (): number | undefined => {
  const urlWorkspace: number | undefined = useSelector(selectUrlWorkspaceId)
  const lsWorkspace: string | null = getStorageWorkspaceId()
  const persistedWorkspace: IWorkspace | undefined = useTypedSelector((state) => state.workspaces.currentWorkspace)
  if (persistedWorkspace !== undefined) return +persistedWorkspace.workspaceId
  else if (lsWorkspace !== null) return +lsWorkspace
  else if (urlWorkspace !== undefined) return urlWorkspace
  else {
    console.warn('No workspace id found')
    return undefined
  }
}

export const useResetFromCachedUrlWorkspace = () => {
  const dispatch = useDispatch()
  const urlWorkspace: number | undefined = useSelector(selectUrlWorkspaceId)
  const lsWorkspace: string | null = getStorageWorkspaceId()
  const persistedWorkspace: IWorkspace | undefined = useTypedSelector((state) => state.workspaces.currentWorkspace)
  return () => {
    if (typeof urlWorkspace === 'number' && lsWorkspace === null && persistedWorkspace === undefined) {
      dispatch(push('/'))
    }
  }
}
