import { Button } from 'antd'
import { IDropdownProps } from 'shared/ui/table/lib/common'
import styled from 'styled-components'

export const DropdownFilterButton = styled(Button)<IDropdownProps>`
  margin-right: ${(props) => (props.isFiltersPicked ? '16px' : '0')};
  margin-bottom: ${(props) => (props.isFiltersPicked ? '8px' : '0')};
  width: ${(props) => (props.isFiltersPicked ? '32px' : '96px')};
  height: 32px;
  top: 7px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: ${(props) => (props.isFiltersPicked ? 'static' : 'absolute')};
  font-weight: 600;
  border-radius: 16px;
  font-size: 13px;
  line-height: 18px;
  background-color: var(--color-border-1);
  color: var(--color-text-1);
  border: 1px solid transparent;
  box-shadow: none;
  border-color: ${(props) => (props.isOpen ? 'var(--color-purple)' : 'transparent')};
  padding: ${(props) => (props.isFiltersPicked ? '0' : '4px 16px 4px 4px')};
  transition: none;

  &:hover {
    background-color: var(--color-bg-3);
    color: var(--color-text-1);
    border: 1px solid transparent;
  }
  &:active,
  :focus {
    border: 1px solid var(--color-purple);
    background-color: var(--color-border-1);
    color: var(--color-text-1);
  }
`
