import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'

import { SelectElement } from './SelectElement'

export const LanguageSelect: FC = () => {
  const { i18n } = useTranslation()
  return (
    <SelectElement
      value={i18n.language}
      onSelect={(value: string) => {
        i18n.changeLanguage(value)
      }}
      options={[
        {
          label: 'Русский',
          value: I18N_LANGUAGES.RUSSIAN,
        },
        {
          label: 'English',
          value: I18N_LANGUAGES.ENGLISH,
        },
      ]}
    />
  )
}
