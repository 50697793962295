import { Button } from 'antd'
import styled from 'styled-components/macro'

export const FormControl = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;

  .ant-input {
    background: none;
  }

  @media (hover: none) {
    &.ant-btn-primary:hover {
      border-color: #4e63d2;
      background: #4e63d2;
    }

    &.ant-btn-primary:active {
      border-color: #3746ab;
      background: #3746ab;
    }
  }

  @media (max-width: 576px), (hover: none) {
    height: 44px;
  }
`
