import { Progress } from 'antd'
import styled from 'styled-components'

const ProgressBarWrapper = styled(Progress)`
  &.ant-progress-line,
  .ant-progress-bg,
  .ant-progress-success-bg {
    height: 4px !important;
  }

  &,
  .ant-progress-line,
  .ant-progress-outer {
    line-height: 4px !important;
  }
`

export const ProgressBar: React.FC<{ approved: number; done: number; total: number }> = ({ approved, done, total }) => (
  <ProgressBarWrapper
    size="small"
    percent={Math.floor((done * 100) / total + (approved * 100) / total)}
    success={{ percent: (approved * 100) / total }}
    showInfo={false}
  />
)
