import { useTypedSelector } from 'app/redux/lib/selector'
import { useChangeAnnotationMutation } from 'features/annotations/api/query'
import { checkLocalAnnotation } from 'features/annotations/lib/helpers'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import GeoJSON from 'ol/format/GeoJSON'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import React, { memo, useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { areEqual, FixedSizeList as List } from 'react-window'
import { QUERY_TYPE } from 'shared/api'
import { SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { IMarkupTask } from 'types/IMarkupTask'
import { useViewerDispatch, useViewerMainSelector } from 'viewer/container'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

import AnnotationListItemContainer from './AnnotationListItemContainer'
import { useWatchKeyPress } from './platform/PlatformAnnotationsList/PlatformAnnotationsList'

const AnnotationListContainer = styled.div`
  overflow-y: auto;
  padding: 0 8px;
  height: 100%;
`

type Props = {
  annotations: IAnnotation[] | undefined
  caseId: number
  slideId: number
}

const DraggableWrapper = styled.div`
  outline: none;
  height: 100%;
`
const reorder = (list: IAnnotation[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const getAnnotationClass = (annotation: IAnnotation) => {
  const updatedFeature = getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)
  return updatedFeature.get('class')
}

export const AnnotationList = ({ annotations, caseId, slideId }: Props) => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const currentAnnotationUsers = useTypedSelector((state) => state.annotations.currentAnnotationUsers)

  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const currTaskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const filteredParticipants = currTaskData?.participants?.filter((item) =>
    currentAnnotationUsers?.includes(item?.user?.fullname || ''),
  )
  const { mutateAsync: editAnnotation } = useChangeAnnotationMutation({
    caseId,
    slideId,
  })
  useWatchKeyPress(viewerId)
  const [isLoading, setIsLoading] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(0)

  const filteredAnnotationsByUser =
    currTaskData && filteredParticipants?.length
      ? annotations
          ?.filter((annotation) => filteredParticipants?.find((item) => item.userId === annotation.userId))
          .reverse()
      : annotations?.filter((item) => item)?.reverse()

  const ref = useRef<List | null>(null)

  const onDragEnd = async (result: DropResult, provider: ResponderProvided) => {
    // dropped outside the list
    if (!result.destination || !filteredAnnotationsByUser) {
      return
    }
    const items = reorder(filteredAnnotationsByUser, result.source.index, result.destination.index).reverse()

    // @ts-ignore
    ref?.current?.children[0] &&
      // @ts-ignore
      setScrollOffset(ref?.current?.children[0]?.offsetTop + 82)
    //"smart" update
    await Promise.all(
      items.map(async (item, index) => {
        const annotationZindex = getSingleFeatureFromGeoJson(item.data?.formattedFeature).get(
          'annotationZindex',
        ) as number

        const reorderedZindex = index + 1
        if (annotationZindex !== reorderedZindex) {
          const updatedFeature = getSingleFeatureFromGeoJson(item.data?.formattedFeature)
          updatedFeature.set('annotationZindex', reorderedZindex)

          if (item.data && item.type !== AnnotationType.MITOSIS) {
            await editAnnotation({
              ...item,
              data: {
                ...item.data,
                formattedFeature: new GeoJSON().writeFeature(updatedFeature),
              },
            })
          }
        }
      }),
    )
  }

  // ref for scroll
  const listRef = useRef<List<any>>(null)

  useEffect(() => {
    if (!selectedAnnotationsIds || selectedAnnotationsIds.length !== 1) return
    const index = filteredAnnotationsByUser?.findIndex((it) => it.slideAnnotationId === selectedAnnotationsIds[0])
    if (index === undefined || !ref.current) return
    listRef.current?.scrollToItem(index, 'smart')
  }, [selectedAnnotationsIds])

  type ListChildComponentProps = {
    index: number
    data: { filteredAnnotationsByUser: IAnnotation[] | undefined }
    style: any
  }
  const Row = memo((props: ListChildComponentProps) => {
    const { data, index, style } = props
    const annotation = data.filteredAnnotationsByUser ? data.filteredAnnotationsByUser[index] : undefined

    if (!annotation) {
      return null
    }

    const hoverHandler = (annotationId: number) => {
      viewerDispatch(annotationsSlice.actions.setHoveredAnnotationId(annotationId))
    }
    const leaveHandler = () => {
      viewerDispatch(annotationsSlice.actions.setHoveredAnnotationId())
    }
    const isDraggable = taskId ? annotation.userId !== currentUserId : annotation.type === AnnotationType.MITOSIS

    return (
      <Draggable
        draggableId={`${annotation.slideAnnotationId}`}
        index={index}
        key={annotation.slideAnnotationId}
        isDragDisabled={isDraggable}
      >
        {(provided) => (
          <DraggableWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={style}
            onMouseLeave={() => leaveHandler()}
            onMouseEnter={() => hoverHandler(annotation.slideAnnotationId)}
          >
            <AnnotationListContainer>
              <AnnotationListItemContainer
                key={annotation.slideAnnotationId}
                annotationId={annotation.slideAnnotationId}
                annotationClass={getAnnotationClass(annotation)}
                onClassSelect={(selectedClass) => {
                  if (checkLocalAnnotation(annotation)) return

                  const annotation$ = queryClient.getQueryData<IAnnotation>([
                    QUERY_TYPE.ANNOTATION,
                    annotation.slideAnnotationId,
                  ])
                  const updatedFeature = getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)
                  updatedFeature.set('class', selectedClass)
                  viewerDispatch(annotationsSlice.actions.setCurrentClass(selectedClass || null))

                  annotation$ &&
                    editAnnotation({
                      data: {
                        formattedFeature: new GeoJSON().writeFeature(updatedFeature),
                        type: 'ANNOTATION',
                      },
                      metric: annotation.metric,
                      slideAnnotationId: annotation.slideAnnotationId,
                      type: annotation.type,
                    })
                }}
              />
            </AnnotationListContainer>
          </DraggableWrapper>
        )}
      </Draggable>
    )
  }, areEqual)

  return (
    <DragDropContext
      onDragEnd={(result, provided) => {
        setIsLoading(true)
        onDragEnd(result, provided).finally(() => setIsLoading(false))
      }}
    >
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          const annotation = filteredAnnotationsByUser ? filteredAnnotationsByUser[rubric.source.index] : undefined
          if (!annotation) return <div>no item</div>
          return (
            <DraggableWrapper
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
            >
              <AnnotationListItemContainer
                key={annotation.slideAnnotationId}
                annotationId={annotation.slideAnnotationId}
                annotationClass={getAnnotationClass(annotation)}
                onClassSelect={(selectedClass) => {
                  if (checkLocalAnnotation(annotation)) return

                  const annotation$ = queryClient.getQueryData<IAnnotation>([
                    QUERY_TYPE.ANNOTATION,
                    annotation.slideAnnotationId,
                  ])

                  const updatedFeature = getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)
                  updatedFeature.set('class', selectedClass)

                  annotation$ &&
                    editAnnotation({
                      data: {
                        formattedFeature: new GeoJSON().writeFeature(updatedFeature),
                        type: 'ANNOTATION',
                      },
                      metric: annotation.metric,
                      slideAnnotationId: annotation.slideAnnotationId,
                      sortedIds: filteredAnnotationsByUser?.map((item) => item.slideAnnotationId),
                      type: annotation.type,
                    })
                }}
              />
            </DraggableWrapper>
          )
        }}
      >
        {(droppableProvided, droppableSnapshot) => {
          const itemCount: number = droppableSnapshot.isUsingPlaceholder
            ? (filteredAnnotationsByUser?.length || 0) + 1
            : filteredAnnotationsByUser?.length || 0

          return (
            <>
              {isLoading && (
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '64px',
                    justifyContent: 'center',
                    padding: '16px 0',
                    position: 'absolute',
                    right: '0px',
                    top: '-58px',
                    width: '64px',
                    zIndex: '2',
                  }}
                >
                  <SpinElement />
                </div>
              )}
              <AutoSizer defaultHeight={1} defaultWidth={1}>
                {({ height, width }: Size) => (
                  <List
                    height={height}
                    itemCount={itemCount}
                    itemSize={82}
                    width={width}
                    ref={listRef}
                    outerRef={ref}
                    innerRef={droppableProvided.innerRef}
                    itemData={{ filteredAnnotationsByUser }}
                    initialScrollOffset={scrollOffset}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}
