import Hover from 'ol-ext/interaction/Hover'
import { useEffect, useRef } from 'react'
import { IMapOl } from 'types/IMapOl'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'

type Props = {
  map: IMapOl
}

const Ki67HoverInteraction = ({ map }: Props) => {
  const currentHover = useRef<Hover>()
  useEffect(() => {
    //@ts-ignore
    const hover = new Hover({
      cursor: 'pointer',
      hitTolerance: 20,
      layerFilter: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.KI_67,
    })
    map.addInteraction(hover as any)

    return () => {
      currentHover.current && map.removeInteraction(currentHover.current as any)
    }
  }, [])

  return null
}

export default Ki67HoverInteraction
