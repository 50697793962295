import { Feature, MapBrowserEvent } from 'ol'
import { Point } from 'ol/geom'
import { Select } from 'ol/interaction'
import { SelectEvent } from 'ol/interaction/Select'
import { useEffect } from 'react'
import { DoubleClickZoom } from 'shared/lib/map/lib/interactions'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { mitosisPointStyle } from 'viewer/map/layers/olStyles'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'

import { MITOSIS_POINTS_HIT_TOLERANCE } from './lib/helpers/constants'
import { useAnnotationAuthorValidation } from './lib/hooks/useAnnotationAuthorValidation'
import { useObjectsCountingContext } from './ui/ObjectsCountingContext'

type Props = {
  features: Feature<any>[]
  map: IMapOl
  viewerId: TViewerId
}

const InteractionLayer = ({ features, map }: Props) => {
  const { addMitos, checkSpotAreaByCoord, removeMitos } = useObjectsCountingContext()
  const isAuthorValid = useAnnotationAuthorValidation()
  const featureCount = features.length
  const { updateCursor } = useObjectsCountingContext()

  const removeMitosHandle = (evt: SelectEvent, callback: () => void) => {
    if (isAuthorValid) {
      evt.selected[0] && removeMitos(evt.selected[0]?.get('id'))
      callback()
    }
  }

  const dblClickHandle = (evt: MapBrowserEvent<MouseEvent>) => {
    if (isAuthorValid) {
      if (!checkSpotAreaByCoord(evt.coordinate)) return

      const mitosisLayer = map
        .getAllLayers()
        .filter((layer) => layer.get(INTERNAL_TYPE_ID) === LayerType.MITOSIS_POINTS)[0]

      addMitos(new Feature(new Point(evt?.coordinate)))

      mitosisLayer.getSource().once('addfeature', () => {
        map.renderSync()
        updateCursor(map.getEventCoordinate(evt.originalEvent))
      })
    }
  }

  const onPointerMove = (evt: MapBrowserEvent<PointerEvent>) => {
    updateCursor(evt.coordinate)
  }

  const singleClickHandle = (evt: MapBrowserEvent<MouseEvent>) => {
    updateCursor(evt.coordinate, true)
  }

  useEffect(() => {
    const mitosisSelect = new Select({
      filter: (feature) => !!features.find((f) => f.get('id') === feature.get('id')),
      hitTolerance: MITOSIS_POINTS_HIT_TOLERANCE,
      layers: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.MITOSIS_POINTS,
      style: mitosisPointStyle,
    })

    const selectHandle = (evt: SelectEvent) => removeMitosHandle(evt, () => mitosisSelect.getFeatures().clear())

    mitosisSelect?.on('select', selectHandle)
    map?.on('dblclick', dblClickHandle)
    map?.on('pointermove', onPointerMove)
    map?.on('singleclick', singleClickHandle)

    map?.addInteraction(mitosisSelect)
    return () => {
      mitosisSelect?.un('select', selectHandle)
      map?.un('dblclick', dblClickHandle)
      map?.un('pointermove', onPointerMove)
      map?.un('singleclick', singleClickHandle)

      map?.removeInteraction(mitosisSelect)
    }
  }, [featureCount])

  useDeepCompareEffect(() => {
    const doubleClickZoomInteractions = map
      .getInteractions()
      .getArray()
      .filter((it) => it instanceof DoubleClickZoom)

    doubleClickZoomInteractions.forEach((it) => it.setActive(false))
    return () => {
      doubleClickZoomInteractions.forEach((it) => it.setActive(true))
    }
  }, [map])

  return null
}

export default InteractionLayer
