import { Form } from 'antd'
import { FormControl } from 'features/form'
import { notices } from 'features/notices'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, InputElement, SpaceElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const { Item: FormItem, useForm } = Form

const ResetFormControl = styled(FormControl)`
  &.ant-input-affix-wrapper > input.ant-input {
    background: none;
  }
`

type Props = {
  onSubmit: (email: string) => void
}

export const ResetForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [form] = useForm()

  const onFinish = async (payload: { email: string }) => {
    if (!payload.email) {
      return
    }
    try {
      setLoading(true)
      await onSubmit(payload.email?.trim())
      // setLoading(false)
      // TODO: add error type
    } catch (e: any) {
      setLoading(false)
      if (e.response?.status === 400) {
        setErrorMessage(t('Учетная запись не найдена'))
      } else {
        notices.error({
          key: 'login-network-error',
          message: t('Сервер недоступен, попробуйте позже'),
        })
      }
    }
  }

  return (
    <Form onFinish={onFinish as any} form={form} onChange={() => setErrorMessage('')}>
      <FormItem name="email">
        <ResetFormControl
          as={InputElement}
          prefix={<IconElement name="mail" />}
          placeholder={t('Электронная почта')}
          autoComplete="on"
        />
      </FormItem>
      <SpaceElement size={8} />
      {errorMessage && <TextElement type="danger">{errorMessage}</TextElement>}
      <SpaceElement size={12} />
      <FormItem>
        <ResetFormControl type="primary" htmlType="submit" loading={isLoading}>
          {t('Отправить письмо с инструкциями')}
        </ResetFormControl>
      </FormItem>
    </Form>
  )
}
