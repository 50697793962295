import { useTranslation } from 'react-i18next'
import { declensionWord } from 'shared/lib/text'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

type Props = {
  selectedCasesCount: number
  doctorName: string
}

const DistributionNotification = ({ doctorName, selectedCasesCount }: Props) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <TextWrapper>
        <TextElement data-testid={'distribution-notification'}>{`${selectedCasesCount} ${declensionWord(
          selectedCasesCount,
          [t('случай'), t('случая'), t('случаев')],
        )}${' '}
        ${selectedCasesCount === 1 ? t('распределён') : t('распределены')}${' '}
         ${t(`на ${doctorName}`)}`}</TextElement>
      </TextWrapper>
    </Wrapper>
  )
}

export default DistributionNotification
