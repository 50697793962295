import { ReactNode, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  buttonType: 'icon' | 'primary' | 'default'
  accept: string
  disabled?: boolean
  slideList?: boolean
  processFileList: (event: any) => void
  children?: ReactNode
}

const AddSlideButtonIcon = styled(ButtonElement)`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  &:hover {
    color: white !important;
  }
`
const AddSlidesButton = styled(ButtonElement)`
  margin: 8px;
`

const AddButtonWrapper = styled.div<{ slideList?: boolean }>`
  ${({ slideList }) =>
    slideList
      ? `  margin-bottom: 8px;
  margin-right: 8px;
  margin-left: 8px;`
      : ` padding: 2px;
  `}

  .ant-btn-icon-only {
    background: var(--color-purple);
    width: 50px;
    height: 50px;
    border-radius: 3px;
    color: white;
  }
  &:hover {
    opacity: 0.7;
  }
`

const SlideUploadButton = ({ accept, buttonType, children, disabled, processFileList, slideList }: Props) => {
  const input = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const handleFileSelect = useCallback(
    (event: any) => {
      switch (event.type) {
        case 'change':
          processFileList(event)
          event.target.value = ''
          return
        default:
          return
      }
    },
    [processFileList],
  )

  return (
    <AddButtonWrapper slideList={slideList}>
      <input ref={input} hidden accept={accept} type="file" multiple={true} onChange={handleFileSelect} />
      {buttonType === 'default' && (
        <div
          onClick={() => {
            if (input.current) input.current.click()
          }}
        >
          {children}
        </div>
      )}
      {buttonType === 'icon' && (
        <AddSlideButtonIcon
          disabled={disabled}
          icon={<IconElement name="add" />}
          size="large"
          onClick={() => {
            if (input.current) input.current.click()
          }}
          type="primary"
        />
      )}
      {buttonType === 'primary' && (
        <AddSlidesButton
          data-testid="add-slide-to-case-btn"
          disabled={disabled}
          size="large"
          onClick={() => {
            if (input.current) input.current.click()
          }}
          type="primary"
        >
          {t('Добавить изображения')}
        </AddSlidesButton>
      )}
    </AddButtonWrapper>
  )
}

export default SlideUploadButton
