import { useQuery } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { ICacher } from 'types/ISlideCache'

import cacheService from './service'

type CachersQuery = {
  isAtlas: boolean
  workspaceId?: string
}

export const useCachersQuery = ({ isAtlas, workspaceId }: CachersQuery) =>
  useQuery<ICacher[] | undefined>([QUERY_TYPE.CACHERS, { isAtlas, workspaceId }], async () => {
    if (!workspaceId) return

    return await cacheService.fetchCachers(isAtlas, workspaceId)
  })
