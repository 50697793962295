import Dexie from 'dexie'

/** Версия для базы */
const version = 3

const db = new Dexie('database')

db.version(version)
  .stores({
    dictionaries: 'id, data, hash',
  })
  .upgrade(async (trx) => {
    console.log('Очистка базы данных при обновлении до новой версии')
    await trx.table('dictionaries').clear()
  })

// Проверяем что база открыта
async function openDatabase() {
  if (!db.isOpen) {
    try {
      await db.open()
      console.log('База данных успешно открыта')
      return true
    } catch (error) {
      console.error('Ошибка при открытии базы данных:', error)
      return false
    }
  } else {
    return true
  }
}
openDatabase()

// Получение данных из словарей
async function getDataFromDB(id: string) {
  try {
    // @ts-ignore
    const dpt = await db.dictionaries.where('id').equals(id).toArray()
    if (!dpt.length) return null
    return dpt[0].data
  } catch (error) {
    console.error('Ошибка при доступе к базе данных:', error)
    return null
  }
}

export { db, getDataFromDB }
