import { Input } from 'antd'
import { SearchProps } from 'antd/es/input'
import styled from 'styled-components/macro'

import { IconElement } from './IconElement'

type Props = SearchProps

const StyledSearch = styled(Input)`
  height: 32px;
  border-radius: 16px;
  background: var(--color-border-1);

  .ant-input {
    background: none;
    color: var(--color-text-1);
  }

  .ant-input-group-addon {
    visibility: hidden;
  }

  .ant-input-prefix {
    color: var(--color-text-3);
  }

  .anticon.ant-input-clear-icon,
  .ant-input-clear-icon {
    margin-top: 1px;
    color: var(--color-text-3);
  }
`

export const SearchElement = (props: Props) => (
  <StyledSearch allowClear prefix={<IconElement name="search" />} {...props} />
)
