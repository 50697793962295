import { createSlice } from '@reduxjs/toolkit'
import { VIEWER_ID_PLACEHOLDER } from 'shared/lib/viewer'

export type TGridTool =
  | 'SEGMENTATION'
  | 'KI67'
  | 'MITOSIS'
  | 'HEAT_MAP'
  | 'VALIDATION'
  | 'PV_PROSTATE'
  | 'ARTEFACTS'
  | 'MEDICALNEURONETS_CRC'

export type ToolOption = {
  isVisible: boolean
  position: number[]
}

const defaultOptions = {
  isExpanded: true,
  isVisible: false,
  position: [0, 0],
}

export const GRID_TOOLS = {}

type State = {
  SEGMENTATION: ToolOption
  KI67: ToolOption
  MITOSIS: ToolOption
  HEAT_MAP: ToolOption
  VALIDATION: ToolOption
  /** AI инструмент Сегментация ткани предстательной железы */
  PV_PROSTATE: ToolOption
  /** AI инструмент Детекция артефактов пробоподготовки */
  ARTEFACTS: ToolOption
  /** AI инструмент Детекция метастазов колоректального рака в лимфоузлах */
  MEDICALNEURONETS_CRC: ToolOption
}

const initialState: State = {
  ARTEFACTS: defaultOptions,
  HEAT_MAP: defaultOptions,
  KI67: defaultOptions,
  MEDICALNEURONETS_CRC: defaultOptions,
  MITOSIS: defaultOptions,
  PV_PROSTATE: defaultOptions,
  SEGMENTATION: defaultOptions,
  VALIDATION: {
    isVisible: true,
    position: [0, 0],
  },
}

export const createViewerToolsGridSlice = (id: string) =>
  createSlice({
    extraReducers: {
      'viewerPageSlice/resetAllViewersState': (state) => {
        const finalState: any = {}
        Object.entries(initialState).forEach((row) => {
          const key = row[0] as TGridTool
          const position = state[key].position
          const value = { ...row[1] }
          finalState[key] = { ...value, position }
        })
        return finalState
      },
      [`${id}-viewerSlice/resetViewer`]: (state) => {
        const finalState: any = {}
        Object.entries(initialState).forEach((row) => {
          const key = row[0] as TGridTool
          const position = state[key].position
          const value = { ...row[1] }
          finalState[key] = { ...value, position }
        })
        return finalState
      },
    },
    initialState,
    name: id + '-viewerToolsGridSlice',
    reducers: {
      setInitialState(state) {
        const finalState: any = {}
        Object.entries(initialState).forEach((row) => {
          const key = row[0] as TGridTool
          const position = state[key].position
          const value = { ...row[1] }
          finalState[key] = { ...value, position }
        })
        return finalState
      },
    },
  })

export const viewerToolsSlice = createViewerToolsGridSlice(VIEWER_ID_PLACEHOLDER)
