import { ICasePermission } from 'entities/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, Scroll, SpaceElement, SpinElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IOptions } from 'types/IOptions'
import IUser from 'types/IUser'

import { UserInfo } from './UserInfo'

type Props = {
  /** воркспейсы доступные для шеринга */
  options: IOptions
  /** функция выбора пользователя */
  onSelect(arg0: number, arg1?: string): void
  /** По умолчанию раскрытый воркспейс */
  initialWorkspaceName?: string
  /** информация доступности пользователей, к которым отправил кейс */
  otherPermissions?: ICasePermission[]
  /** Id владельца случая */
  caseOwnerId: number
}

type OpenStates = {
  [key: string]: boolean
}

export const UsersToInvite = ({ caseOwnerId, initialWorkspaceName, onSelect, options, otherPermissions }: Props) => {
  const { t } = useTranslation()
  const { usersGroupByWorkspaceName } = options
  const isNotEmptyUsers = usersGroupByWorkspaceName && !!Object.keys(usersGroupByWorkspaceName).length
  const toggleWorkspace = (workspaceName: string) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [workspaceName]: !prevState[workspaceName],
    }))
  }
  /** Сортировка по алфавиту */
  const usersTiInvite = Object.entries(usersGroupByWorkspaceName || []).sort(([keyA], [keyB]) => {
    /** Перемещаем initialWorkspaceName в начало */
    if (keyA === initialWorkspaceName) return -1
    if (keyB === initialWorkspaceName) return 1
    /** Перемещаем ключ с пустой строкой в конец */
    if (keyA === '') return 1
    if (keyB === '') return -1
    /** Затем сортируем остальные ключи алфавитно */
    return keyA.localeCompare(keyB)
  })
  const getAllOpen = (usersGroupByWorkspaceName: { [p: string]: IUser[] }) => {
    const newObj: OpenStates = {}
    for (const item in usersGroupByWorkspaceName) {
      newObj[item] = true
    }
    return newObj
  }

  useEffect(() => {
    if (options.isAutocompleteSource) setIsOpen(getAllOpen(usersGroupByWorkspaceName))
    else if (initialWorkspaceName) setIsOpen({ [initialWorkspaceName]: true })
  }, [options.isAutocompleteSource])

  const [isOpen, setIsOpen] = useState<OpenStates>({ '': true })
  /** Отфильтровываем владельца случая */
  const getFilteredByCaseOwner = (isAutocompleteSource: boolean, users: IUser[]) =>
    options.isAutocompleteSource ? users.filter((user) => user.userId !== caseOwnerId) : users
  /** Отфильтровываем по уровню доступа */
  const getFilteredByPermission = (users: IUser[]) =>
    users.filter((option) => !otherPermissions?.find((item) => item.targetUser?.userId === option.userId))

  const needEmail = t('Для приглашения нового пользователя необходимо указать его электронную почту')
  return (
    <SpinElement spinning={!usersGroupByWorkspaceName}>
      <StyledScroll
        style={{
          overflow: 'hidden auto',
        }}
      >
        {usersGroupByWorkspaceName && isNotEmptyUsers ? (
          usersTiInvite.map(([workspaceName, users]) => {
            const filteredUsers = getFilteredByPermission(getFilteredByCaseOwner(options.isAutocompleteSource, users))
            return (
              <UserWorkspaceItem
                key={workspaceName}
                isOpen={isOpen[workspaceName]}
                workspaceName={workspaceName}
                users={filteredUsers}
                toggleWorkspace={toggleWorkspace}
                onSelect={onSelect}
                initialWorkspaceName={initialWorkspaceName}
              />
            )
          })
        ) : (
          <NoResults>{needEmail}</NoResults>
        )}
      </StyledScroll>
    </SpinElement>
  )
}

const NoResults = styled.div`
  max-width: 400px;
  font-size: 12px;
  color: var(--color-text-1);
`

type WsItemProps = {
  /** отслеживает развернут ли блок */
  isOpen: OpenStates[string]
  /** функция выбора пользователя */
  onSelect(arg0: number, arg1?: string): void
  /** функция развертывания/сворачивание блока */
  toggleWorkspace: (wsName: string) => void
  /** пользователи доступные для шеринга */
  users: IUser[]
  /** название воркспейса */
  workspaceName: string
  /** название воркспейса по умолчанию */
  initialWorkspaceName?: string
}

const UserWorkspaceItem = ({
  initialWorkspaceName,
  isOpen,
  onSelect,
  toggleWorkspace,
  users,
  workspaceName,
}: WsItemProps) => {
  const { t } = useTranslation()
  const currentWorkspaceName = workspaceName === initialWorkspaceName ? t('Врачи вашего медучреждения') : workspaceName
  return (
    <StyledContainer>
      <WorkspaceContainer onClick={() => toggleWorkspace(workspaceName)}>
        <StyledIconElement name={isOpen ? 'sectionIsOpen' : 'sectionIsClose'} fill={'var(--color-text-3)'} size="md" />
        <TextElement style={{ fontWeight: 500 }} ellipsis>
          {currentWorkspaceName || t('Внешние пользователи')}
        </TextElement>
      </WorkspaceContainer>
      {isOpen && (
        <>
          <SpaceElement size={8} />
          {Array.isArray(users) &&
            users.map((user) => (
              <UserWrapper key={user.userId}>
                <UserInfo
                  onClick={() => onSelect(user.userId, workspaceName)}
                  numberValue={user.userId}
                  fullname={user.fullname}
                  secondaryText={user.login || user.email}
                  iconShow={false}
                />
              </UserWrapper>
            ))}
        </>
      )}
    </StyledContainer>
  )
}

const WorkspaceContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  margin-bottom: 5px;
  user-select: none;
  &:only-child {
    margin-bottom: 0;
  }
`

const UserWrapper = styled.div`
  padding-left: 8px;
`
const StyledContainer = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`

const StyledScroll = styled(Scroll)`
  max-height: calc(100vh - 234px);
`

const StyledIconElement = styled(IconElement)`
  overflow: visible;
`
