import { useTypedSelector } from 'app/redux/lib/selector'
import { useContext } from 'react'
import { MapContext } from 'shared/lib/map'
import { useViewerAttachmentsSelector } from 'viewer/container'

import AttachmentDrawInteraction from './AttachmentDrawInteraction'
import SelectedAttachment from './SelectedAttachment'

type Props = {
  displayedSlideId: number
}
const AttachmentLayerContainer = ({ displayedSlideId }: Props) => {
  const { viewerId } = useContext(MapContext)

  const { selectedBbox, slideId } = useViewerAttachmentsSelector(viewerId)
  const isAttachmentMode = useTypedSelector((state) => state.comments.isAttachmentMode)

  return (
    <>
      {isAttachmentMode && <AttachmentDrawInteraction />}
      {selectedBbox && slideId === displayedSlideId && <SelectedAttachment selectedBbox={selectedBbox} />}
    </>
  )
}

export default AttachmentLayerContainer
