import { GlobalAntNotificationStyle } from 'features/notices/GlobalAntNotificationStyle'
import { GlobalOhivStyles } from 'pages/ohiv'
import { createContext, FC, useContext, useState } from 'react'
import { GlobalAntPickerStyle, GlobalAntSelectStyle, GlobalAntTitleStyle } from 'shared/ui/kit'
import { GlobalOlStyles } from 'viewer/map/layers/annotations/ui/GlobalOlStyles'

import GlobalAntDesignStyles from './GlobalAntDesignStyles'
import GlobalStyles from './GlobalStyles'

export type TMode = 'light' | 'dark'

export const ThemeContext = createContext({
  setTheme: (theme: TMode) => {},
  theme: 'dark',
})

export const useThemeContext = () => useContext(ThemeContext)

export const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<TMode>((localStorage.getItem('theme') as TMode) || 'dark')
  localStorage.setItem('theme', theme)
  return (
    <ThemeContext.Provider value={{ setTheme, theme }}>
      <GlobalAntDesignStyles />
      <GlobalAntTitleStyle />
      <GlobalAntNotificationStyle />
      <GlobalAntSelectStyle />
      <GlobalAntPickerStyle />
      <GlobalOlStyles />
      <GlobalStyles mode={theme} />
      <GlobalOhivStyles />
      {children}
    </ThemeContext.Provider>
  )
}
