import { Feature } from 'ol'
import { MultiPolygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import Hover from 'ol-ext/interaction/Hover'
import Tooltip from 'ol-ext/overlay/Tooltip'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useRef } from 'react'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'

import { usePathVisionSegmentationContext } from './PathVisionSegmentationContext'

const HUNDRED = 100

/** Props for PathVisionSegmentationLayer */
type Props = {
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
}

const PathVisionSegmentationLayer: React.FC<Props> = ({ viewerId }) => {
  const map = useViewerIdMap(viewerId)
  const { features, hoveredClassId, opacity, setHoveredClassId } = usePathVisionSegmentationContext()

  const tooltip = useRef<Tooltip>(
    // @ts-ignore
    new Tooltip({
      getHTML: (feature: Feature<MultiPolygon>) => `<span>${feature?.get('title')}</span>`,
      maximumFractionDigits: 2,
      offsetBox: 20,
      popupClass: 'custom-ol-draw-tooltip',
      positionning: 'auto',
    }),
  )

  const onHoverEnter = (evt: any) => {
    tooltip.current.setFeature(evt.feature)
    map.addOverlay(tooltip.current)
    setHoveredClassId(`${evt.feature.get('tissue')}-${evt.feature.get('class_name')}`)
  }

  const onHoverLeave = () => {
    tooltip.current.setFeature(null)
    map.removeOverlay(tooltip.current as any)
    setHoveredClassId()
  }

  const fillLayer = useRef(
    new VectorLayer({
      opacity: opacity / HUNDRED,
      source: new VectorSource({
        features: features.filter((feature) => !feature.get('gleason')),
      }),
      style: (feature) =>
        new Style({
          fill: new Fill({
            color: feature.get('color'),
          }),
        }),
      visible: true,
      zIndex: 1,
    }),
  )

  const strokeLayer = useRef(
    new VectorLayer({
      source: new VectorSource({
        features: features.filter((feature) => !feature.get('gleason')),
      }),
      style: (f) =>
        new Style({
          stroke: new Stroke({
            color: f.get('color'),
            width: 2,
          }),
        }),
      visible: true,
      zIndex: 1,
    }),
  )

  const hover = useRef(
    // @ts-ignore
    new Hover({
      cursor: 'pointer',
      hitTolerance: 10,
      layerFilter: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.PV_PROSTATE,
    }),
  )

  useEffect(() => {
    fillLayer.current.setOpacity(opacity / HUNDRED)
  }, [opacity])

  useEffect(() => {
    strokeLayer.current.setStyle(
      (f) =>
        new Style({
          stroke:
            hoveredClassId === `${f.get('tissue')}-${f.get('class_name')}`
              ? new Stroke({
                  color: f.get('color'),
                  width: 2,
                })
              : undefined,
        }),
    )
  }, [hoveredClassId])

  useEffect(() => {
    fillLayer.current.set(INTERNAL_TYPE_ID, LayerType.PV_PROSTATE)
    map?.addLayer(fillLayer.current)
    map?.addLayer(strokeLayer.current)
    return () => {
      map?.removeLayer(fillLayer.current)
      map?.removeLayer(strokeLayer.current)
      map?.removeOverlay(tooltip.current)
    }
  }, [])

  useDeepCompareEffect(() => {
    fillLayer.current.getSource().clear()
    fillLayer.current.getSource().addFeatures(features.filter((feature) => !feature.get('gleason')))
    strokeLayer.current.getSource().clear()
    strokeLayer.current.getSource().addFeatures(features.filter((feature) => !feature.get('gleason')))
    // @ts-ignore
    hover.current.on('enter', onHoverEnter)
    // @ts-ignore
    hover.current.on('leave', onHoverLeave)
    map.addInteraction(hover.current)

    return () => {
      if (hover?.current?.getMap()?.getTargetElement()) {
        // @ts-ignore
        hover?.current?.setMap(null)
        map.removeInteraction(hover.current)
      }
    }
  }, [features])

  return null
}

export default PathVisionSegmentationLayer
