import { Button } from 'antd'
import React, { FC } from 'react'
import type { ISize } from 'shared/ui/kit'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  /** Название браузера дял отображения */
  name: string
  /** Иконка браузера (Должна быть экспортирована в assets/icons/index.ts) */
  icon: 'yandexBrowser' | 'googleChrome'
  /** Размер иконки (по дефолту "m") */
  iconSize?: ISize
  /** Текст для отображения (по дефолту "Перейти на сайт") */
  text?: string
  /** Ссылка на страницу скачивания */
  link: string
}

const BrowserDownloadButtonWrapper = styled(Button)`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: var(--color-bg-3);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-bg-4);
    }
  }

  @media (hover: none) {
    &:active {
      background-color: var(--color-bg-4);
    }
  }
`

const DownloadButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 16px;
  flex: 1;
  row-gap: 4px;
`

const DownloadButtonTitle = styled.div`
  color: var(--color-text-1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

const DownloadButtonText = styled.div`
  color: var(--color-text-3);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

export const BrowserDownloadButton: FC<Props> = ({ icon, iconSize = 'm', link, name, text = 'Перейти на сайт' }) => (
  <a href={link}>
    <BrowserDownloadButtonWrapper>
      <IconElement name={icon} size={iconSize} />
      <DownloadButtonTextWrapper>
        <DownloadButtonTitle>{name}</DownloadButtonTitle>
        <DownloadButtonText>{text}</DownloadButtonText>
      </DownloadButtonTextWrapper>
    </BrowserDownloadButtonWrapper>
  </a>
)
