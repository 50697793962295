import { createGlobalStyle } from 'styled-components/macro'

import { modalBackground, modalShadow } from './GlobalStyles'

// Styles for components in portals and so on
// TODO: Split to different objects
const GlobalAntDesignStyles = createGlobalStyle`

  .ant-slider-rail{
    background: var(--color-border-1);
  }

  .ant-slider-handle {
    box-shadow: 0px 1px 2.5px 0.5px rgb(0 0 0 / 70%);
  }

  .ant-tabs-ink-bar {
    display: none; 
  }

  .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    color: var(--color-text-3);
    font-weight: 500;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-text-1);
    text-shadow: none;
  }

  .ant-divider {
    border-color: var(--color-border-1);
  }

  .ant-divider-horizontal {
    margin: 8px 0;
    border-width: 1px;
  }

  .ant-input:focus, .ant-input-focused, .ant-picker-focused {
    box-shadow: 0 0 0 1px var(--color-purple);
  }

  .ant-table-filter-dropdown-search > .ant-input-affix-wrapper > .ant-input {
    background: transparent none;
  }

  .ant-input-affix-wrapper > input.ant-input::placeholder {
    color:var(--color-text-3);
  }
  
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-title {
    border-bottom: none;
    padding: 16px 16px 0 16px;
  }

  .ant-popover-inner {
    ${() => modalBackground}
    ${() => modalShadow}
  }

  .ant-popover-inner-content {
    padding: 12px 16px 16px;
  }

  .ant-table-filter-dropdown {
    ${() => modalBackground}
    ${() => modalShadow}
  }

  .ant-checkbox-inner{
    background: var(--color-bg-4);
    box-shadow: 0 0 0 1px var(--color-bg-1);
  }

  .ant-dropdown-menu-item:hover {
    background-color: transparent;
  }

  .ant-dropdown-menu {
    background: var(--color-modal);
    margin: 0; 
  }

  .ant-dropdown-menu-submenu-popup {
    ${() => modalBackground}
    ${() => modalShadow}
    margin: 0; 
    padding: 0;
  }

 
  .ant-dropdown-menu-submenu-popup ul {
    margin: 0
  }
  
  .alerts-popover .ant-popover-inner-content {
    padding: 0;
  }

  .ant-slider-track {
    background-color: var(--color-purple);
  }

  .ant-form-item-explain, .ant-form-item-extra {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ant-message {
  position: absolute;
  bottom: 20px !important;
    top: unset !important;
    left: 20px !important;
    width: auto;
  }

  .ant-message > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-spin-spinning {
    width: 22px;
    height: 22px;
  }

  .submenu-filter-style {
    ${() => modalBackground}
    ${() => modalShadow}
    
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .submenu-filter-style .ant-dropdown-menu-item:hover {
    background-color: var(--color-bg-3);
  }
  .bubble-button-submenu{
    max-height: 299px;
    overflow-x: auto;
  }
  
  .bubble-button-submenu .ant-dropdown-menu-item:hover {
    background-color: var(--color-bg-3);
  }
  
  // Добавил в глобал стили, потому что дата пикеры порталятся вне основного блока
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: var(--color-purple-light);
  }


  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: var(--color-purple-light);
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .ant-col-20 {
    max-width: 58%;
  }


  // Стили тултипа
  .ant-tooltip-inner {
      color: var(--text-1);
      padding: 4px 6px;
      min-height: 20px;
      border-radius: 3px;
      ${() => modalBackground}
      ${() => modalShadow}
  }

  .ant-tooltip {
      font-size: 11px;
      line-height: 14px;
      font-weight: 500;
  }

  .ant-tooltip-arrow {
      width: 18px;
      height: 18px;
  }

  .ant-tooltip-arrow-content::before {
      ${() => modalBackground}
  }

  .ant-tooltip-placement-top .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topRight .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content,
  .ant-tooltip-placement-left .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-right .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
      box-shadow: 4px 4px 8px rgb(0 0 0 / 10%), 0 0 0 1px var(--color-modal-outline);
  }
`

export default GlobalAntDesignStyles
