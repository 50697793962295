import { Select } from 'antd'
import { differenceInYears } from 'date-fns'
import { casesSlice } from 'features/cases/model/casesSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { StompClientContext, useSubscription } from 'processes/stomp'
import { WsResponsePatientUser } from 'processes/stomp/types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { formatDateStringForDisplay } from 'shared/lib/date'
import { ELLIPSIS } from 'shared/text-constants'
import { ButtonElement, SelectElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { IPatientDTO } from 'types/IPatient'
import { v4 as uuidv4 } from 'uuid'

const { Option } = Select

const StyledPatientName = styled.div`
  font-size: 12px;
  line-height: 16px;
`
const StyledPatientData = styled.div`
  font-size: 12px;
  color: #99989f;
  line-height: 16px;
`

const StyledButton = styled(ButtonElement)`
  width: 95%;
  margin: 10px 8px 8px 8px;
  background: #706f76;
`

type PatientSelectProps = {
  onOpenPatientModal?: () => void
  value?: IPatientDTO
  onChange?: (patient?: IPatientDTO) => void
}

export const PatientSelect = ({ onChange, onOpenPatientModal, value, ...props }: PatientSelectProps) => {
  const uuid = uuidv4()
  const [options, setOptions] = useState<IPatientDTO[]>([])
  const workspaceId = useCurrentWorkspaceId()
  const { publish } = useContext(StompClientContext)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useSubscription<WsResponsePatientUser>(`/user/topic/autocomplete/patients/${uuid}`, (result) => {
    setTimeout(() => {
      setOptions(result.payload)
    }, 10)
  })

  const onSearch = (query: string) => {
    if (query.length > 2) {
      publish(`/app/autocomplete/workspace/${workspaceId}/patient-user/${uuid}`, { query })
    }
  }

  /**
   * Here we get an object with fields key: string, value: string, label: React.Node[]
   * by labelInValue prop
   * key is patient.patientProfileId | value is patient.fullname
   * it is necessary because with value = patient.patientProfileId dropdown doesn't rerender
   * when socket pushes new list
   * */

  const onSelect = (selected: any) => {
    if (options.length > 0) {
      const patient = options.find((it) => it.patientProfileId === +selected.key)
      if (onChange && patient) {
        onChange(patient)
      }
    }
  }

  return (
    <SelectElement
      placeholder={t('Поиск по имени или ID пациента')}
      optionLabelProp={'value'}
      value={value?.fullname || null}
      onSelect={onSelect}
      onSearch={onSearch}
      labelInValue
      showSearch
      dropdownStyle={{ zIndex: 1000 }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <StyledButton
            data-testid="create-patient-btn"
            onClick={() => dispatch(casesSlice.actions.setPatientModal(true))}
          >
            {t('Создать нового пациента')}
            {ELLIPSIS}
          </StyledButton>
        </>
      )}
      {...props}
    >
      {options.map((patient) => (
        <Option
          value={patient.fullname + patient.patientProfileId + patient.externalId} // this make value unique
          label={patient.patientProfileId}
          key={patient.patientProfileId}
        >
          <StyledPatientName>{patient.fullname}</StyledPatientName>
          <StyledPatientData>{`№ ${patient.patientProfileId} • ${formatDateStringForDisplay(
            patient.birthDate,
          )} / ${differenceInYears(new Date(), new Date(patient.birthDate))} ${t('год')}`}</StyledPatientData>
        </Option>
      ))}
    </SelectElement>
  )
}
