import { useTypedSelector } from 'app/redux/lib/selector'
import { useReportQuery, useReportsQuery } from 'features/reports/api/query'
import { reportsSlice, selectedReportByCaseSelector } from 'features/reports/model/reportsSlice'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconButtonElement, IconElement, SpaceElement, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISource from 'types/ISource'

import PatientReportInfo from './ui/PatientReportInfo'

const Actions = styled.div`
  display: flex;
`

type Props = {
  caseId: number
  mock?: boolean
  source: ISource
}

const PatientReportsContainer = ({ caseId, source }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const index = useTypedSelector(selectedReportByCaseSelector(caseId))
  const { data: allReports } = useReportsQuery(caseId, source)
  const selectedReportId = allReports?.[index]
  const { data: selectedReport } = useReportQuery(caseId, selectedReportId, source)
  const reportsLength = allReports?.length || 0
  if (!allReports?.length || !selectedReport) {
    return <TitleElement>{t('Нет заключений')}</TitleElement>
  }
  if (allReports?.length === 1 && selectedReport) {
    return (
      <>
        <TitleElement level={2}>{t('Заключение')}</TitleElement>
        <SpaceElement size={4} />
        <PatientReportInfo report={selectedReport} />
      </>
    )
  }
  const leftDisabled = index === 0
  const rightDisabled = index === reportsLength - 1

  const next = useCallback(() => {
    const reportsLength = allReports?.length || 0
    if (index + 1 < reportsLength) dispatch(reportsSlice.actions.setSelectedReportIndex({ caseId, index: index + 1 }))
  }, [allReports?.length, index])
  const back = useCallback(() => {
    if (index - 1 >= 0) dispatch(reportsSlice.actions.setSelectedReportIndex({ caseId, index: index - 1 }))
  }, [index])
  return (
    <>
      <Actions onClick={(e) => e.stopPropagation()}>
        <TitleElement level={2}>
          {t('Заключение')} {index + 1} / {allReports.length}
        </TitleElement>
        <TooltipElement title={t('Предыдущее')}>
          <IconButtonElement
            icon={<IconElement name="arrowLeftSmall" />}
            onClick={back}
            style={{ color: leftDisabled ? 'var(--color-text-3)' : 'var(--color-text-1)' }}
          />
        </TooltipElement>
        <TooltipElement title={t('Следующее')}>
          <IconButtonElement
            icon={<IconElement name="arrowRightSmall" />}
            onClick={next}
            style={{ color: rightDisabled ? 'var(--color-text-3)' : 'var(--color-text-1)' }}
          />
        </TooltipElement>
      </Actions>
      <SpaceElement size={4} />
      <PatientReportInfo report={selectedReport} />
    </>
  )
}

export default PatientReportsContainer
