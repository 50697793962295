import { RefObject, useEffect } from 'react'

export const useOnClickOutside = (
  containerRef: RefObject<HTMLDivElement | null>,
  callback: () => void,
  secondContainerRef?: RefObject<HTMLDivElement | null>,
  /** Зависимости для пересоздания события */
  dependencies?: number[],
) => {
  const handleClick = (e: any) => {
    let clickOnMenu = false
    const eventPath = e.composedPath()
    eventPath &&
      eventPath.forEach((el: any) => {
        if (el.nodeType !== 1) return
        if (containerRef?.current?.isSameNode(el) || secondContainerRef?.current?.isSameNode(el)) {
          clickOnMenu = true
        }
      })
    if (!clickOnMenu) callback()
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [dependencies])
}
