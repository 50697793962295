import { useTypedSelector } from 'app/redux/lib/selector'
import { useUserStatusContext } from 'features/multiplayer/lib'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { useColorCorrectionActive } from 'pages/viewer/model/slideMapViewSlice'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { MouseEventHandler, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { IconElement, IIconElementProps, TooltipElement } from 'shared/ui/kit'
import { ToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import styled from 'styled-components'
import { useViewerDispatch, viewerSlice } from 'viewer/container'
import { ColorChangerModal } from 'viewer/tools/ui/colorChanger/ColorChangerModal'
import { useAccessRightsMap } from 'viewer/tools/ui/modal/hooks/useAccessRightsMap'

const PanelBlock = styled.div`
  position: relative;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
  disable: boolean
}

export const ToolsPanelContainer = ({ disable }: Props) => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const { accessRightsMap } = useAccessRightsMap()
  const viewerDispatch = useViewerDispatch(viewerId)
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isColorsModalOpen = useTypedSelector((state) => state.viewerPage.isColorsModalOpen)
  const { slideId } = useViewerIdSlideState(viewerId)
  const { isFastTravel } = useViewerPageProvided()
  const colorCorrectionIsActive = useColorCorrectionActive({ slideId, viewerId })
  const { unsubscribeFromUser } = useUserStatusContext()

  const onPrintScreenClick = useCallback(() => {
    viewerDispatch(viewerSlice.actions.setScreenshotModal(true))
  }, [viewerDispatch])

  const onColorsClick = useCallback(() => {
    unsubscribeFromUser()
    viewerDispatch(viewerPageSlice.actions.setColorsModal(!isColorsModalOpen))
  }, [viewerDispatch])

  const onToolsClick = useCallback(() => {
    unsubscribeFromUser()
    isOneOfAiToolsEnabled() && viewerDispatch(viewerSlice.actions.setMlModal(true))
  }, [viewerDispatch])

  const isOneOfAiToolsEnabled = useCallback(() => {
    if (accessRightsMap.current.size < 0) return false
    let status = false
    accessRightsMap.current.forEach((value: boolean) => {
      if (value === true) status = true
    })
    return status
  }, [currentWorkspace, accessRightsMap.current])

  const ToolButton = (props: {
    title: string
    iconName: IIconElementProps['name']
    hotkey: string
    onClick: MouseEventHandler<HTMLElement>
    active?: boolean
    disable?: boolean
  }) => {
    useHotkeys(props.hotkey, () => {
      if (isFastTravel === false) {
        props.onClick === onColorsClick && onColorsClick()
        props.onClick === onToolsClick && onToolsClick()
        props.onClick === onPrintScreenClick && onPrintScreenClick()
      }
    })

    const { t } = useTranslation()
    return (
      <TooltipElement title={props.disable ? t('Инструмент недоступен') : `${props.title} (${props.hotkey})`}>
        <ToolbarButton
          data-testid={`${props.iconName.toLowerCase()}-btn`}
          disable={props.disable}
          disabled={props.disable}
          icon={<IconElement name={props.iconName} />}
          active={props.active}
          onClick={props.onClick}
        />
      </TooltipElement>
    )
  }
  const { t } = useTranslation()
  return (
    <PanelBlock>
      <ToolButton
        title={t('Цветокоррекция')}
        iconName="colorCorrection"
        hotkey="G"
        onClick={onColorsClick}
        active={colorCorrectionIsActive || isColorsModalOpen}
      />
      {isOneOfAiToolsEnabled() && (
        <ToolButton title={t('Инструменты')} iconName="toolsAI" hotkey="T" onClick={onToolsClick} disable={disable} />
      )}
      <ToolButton title={t('Снимок экрана')} iconName="printScreen" hotkey="S" onClick={onPrintScreenClick} />
      {isColorsModalOpen && <ColorChangerModal />}
    </PanelBlock>
  )
}
