export const diagnosticProcedureOptions = [
  {
    label: 'Биопсия',
    value: 'BIOPSY',
  },
  {
    label: 'Операционный',
    value: 'SURGICAL',
  },
  {
    label: 'Радиология',
    value: 'RADIOLOGY',
  },
  {
    label: 'Макроскопия',
    value: 'MACRO',
  },
  {
    label: 'Маммография',
    value: 'MAMMOGRAPHY',
  },
  {
    label: 'КТ',
    value: 'CT',
  },

  {
    label: 'МРТ',
    value: 'MRT',
  },
  {
    label: 'УЗИ',
    value: 'UZI',
  },
]

export type TDiagnosticProcedure = 'BIOPSY' | 'SURGICAL' | 'RADIOLOGY' | 'MACRO' | 'MAMMOGRAPHY' | 'CT' | 'MRT' | 'UZI'

export const diagnosticProcedureTypesMap: Record<TDiagnosticProcedure, string> = diagnosticProcedureOptions.reduce(
  (acc, item) => ({
    ...acc,
    [item.value]: item.label,
  }),
  {} as Record<TDiagnosticProcedure, string>,
)
