import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'shared/lib/hooks'
import NavigationBody from 'shared/ui/navigation/NavigationBody'

import DashboardNavigationType from './DashboardNavigationType'

const DashboardNavigationCasesContainer = () => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const navItems = [
    {
      icon: 'inbox',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.New,
      path: `/dashboard?filter=${DashboardNavigationType.New}`,
      title: t('Новые случаи'),
    },
    {
      icon: 'addExam',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.AddExam,
      path: `/dashboard?filter=${DashboardNavigationType.AddExam}`,
      title: t('Доп. исследов-ия'),
    },
    {
      icon: 'comment',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Comment,
      path: `/dashboard?filter=${DashboardNavigationType.Comment}`,
      title: t('Консультации'),
    },
    {
      icon: 'mdt',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.MDT,
      path: `/dashboard?filter=${DashboardNavigationType.MDT}`,
      title: t('MDT'),
    },
    {
      icon: 'pause',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Pause,
      path: `/dashboard?filter=${DashboardNavigationType.Pause}`,
      title: t('На паузе'),
    },
    {
      icon: 'favorites',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Favorites,
      path: `/dashboard?filter=${DashboardNavigationType.Favorites}`,
      title: t('Избранное'),
    },
  ]
  return <NavigationBody navItems={navItems} title={t('Мои случаи')} />
}

export default DashboardNavigationCasesContainer
