import { TablePaginationConfig } from 'antd'
import type { FilterValue, SorterResult, SortOrder } from 'antd/lib/table/interface'
import { DependencyList, useState } from 'react'
import { useVT } from 'virtualizedtableforantd4'
import { VtOpts } from 'virtualizedtableforantd4/dist/esm/vt'

/** Оффсет количества элементов, после которых идет подгрузка следующей страницы */
const NEXT_PAGE_DOWNLOAD_OFFSET = 5
/** Высота строки в таблице */
export const ROW_HEIGHT = 44

/** Виртуальная таблица */
export const useCustomVt = (
  {
    nextPageDownloadOffset,
    onScroll,
    rowHeight,
    scroll,
  }: {
    onScroll: VtOpts['onScroll']
    rowHeight?: number
    nextPageDownloadOffset?: number
    scroll?: { y: number }
  },
  deps: DependencyList,
) =>
  useVT(
    () => ({
      offset: (rowHeight || ROW_HEIGHT) * (nextPageDownloadOffset || NEXT_PAGE_DOWNLOAD_OFFSET),
      onScroll,
      scroll: scroll || { y: (rowHeight || ROW_HEIGHT) * NEXT_PAGE_DOWNLOAD_OFFSET },
    }),
    deps,
  )

type TSortState = {
  field: string
  order: NonNullable<SortOrder>
}

export const useTableSort = <T>(initialState: TSortState) => {
  const [sortConfig, setSortConfig] = useState<TSortState>(initialState)
  const { field, order } = initialState

  const handleSortChange = (
    _pagination: TablePaginationConfig,
    _: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => {
    //Сохранение сортировки при перерендере
    const newSortConfig = Array.isArray(sorter) ? sorter[0] : sorter
    setSortConfig({
      field: (newSortConfig.field as string) || field,
      order: newSortConfig.order || order,
    })
  }

  return { handleSortChange, sortConfig }
}
