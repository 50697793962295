import { useTypedSelector } from 'app/redux/lib/selector'
import * as jsts from 'jsts'
import { Feature } from 'ol'
import { LinearRing, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContext } from 'shared/lib/map'
import ToolOpacityController from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components'
import { PVProstateTissue, PVProstateTissueChild } from 'types/IPVProstate'
import { usePathVisionSegmentationContext } from 'viewer/map/layers/path-vision-segmentation/PathVisionSegmentationContext'
import { getCenterOfExtent } from 'viewer/map/lib/utils'

import TissueGroup from './TissueGroup'

const StyledText = styled.span`
  display: block;
  width: 100%;
  font-size: 11px;
  text-align: right;
  color: var(--color-text-3);
`

const Wrapper = styled.div<{ isMagnetize: boolean }>`
  height: calc(100% - 33px);
`
const ScrollWrapper = styled.div`
  max-height: calc(100% - 33px);
  overflow: hidden auto;
`

const filterGeometryFromFeature: (value: Feature<Polygon>) => PVProstateTissue = (value) => ({
  area: value.get('area'),
  class_id: value.get('class_id'),
  class_name: value.get('class_name'),
  color: value.get('color'),
  gleason: value.get('gleason'),
  group: value.get('group'),
  parent: value.get('parent'),
  tissue: value.get('tissue'),
  title: value.get('title'),
})

/** Продолжительность анимации в милисекундах */
const ANIMATION_DURATION = 250

/** Наибольшая точка координаты вьювера */
const HIGHEST_POINT = 9999999999

const PathVisionSegmentationContainer: React.FC = () => {
  const { map } = useContext(MapContext)
  const { features, opacity, setOpacity } = usePathVisionSegmentationContext()
  const isMagnetize = useTypedSelector((state) => state.viewerPage.isMagnetize['PV_PROSTATE'])

  /** Получение из плоского GeoJSON-массива вложенной структуры */
  const formattedFeaturesProperties = useMemo(() => {
    const featuresProperties = features?.map(filterGeometryFromFeature)
    /** Родительские элементы (столбики) */
    const tissues = featuresProperties?.filter((it) => it?.gleason)
    return tissues?.map((tissue) => {
      /** Дочерние элементы (классы). Доп. фильтрация !it.tissie, чтобы родитель не попал в дочерние элементы */
      const itClasses = featuresProperties?.filter(
        (it) => it?.tissue === tissue?.tissue && !it?.gleason,
      ) as PVProstateTissueChild[]

      /** Создание вложенности для глисон классов */
      const firstGleasonClassIdx = itClasses.findIndex((it) => it.parent)
      if (firstGleasonClassIdx !== -1) {
        const parent = {
          ...itClasses[firstGleasonClassIdx].parent,
          isParent: true,
        }
        itClasses.splice(firstGleasonClassIdx, 0, parent as PVProstateTissueChild)
      }

      return { ...tissue, children: itClasses }
    })
  }, [features])

  const onSelectElementHandle = (selectedClassId: string) => {
    const parser = new jsts.io.OL3Parser()
    // @ts-ignore
    parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)
    let featuresToSelect: Feature<Polygon>[] = []
    if (selectedClassId.includes('parent')) {
      const [firstPart, secondPart] = selectedClassId.split('-')
      const newSelectedClassId = firstPart || secondPart
      featuresToSelect = features.filter((f) => newSelectedClassId === f.get('tissue').toString() && f.get('parent'))
    } else {
      featuresToSelect = features.filter((f) => selectedClassId === `${f.get('tissue')}-${f.get('class_name')}`)
    }
    /** Получаем массив Extent из features, т.к. feature может быть как мультиполигоном[][][], так и простым полигоном[] */
    const extents = featuresToSelect.map((it) => it.getGeometry()?.getExtent())

    /** Получаем наивысшие и наименьшие точки из массива Extents */
    const getMergeExtent = (extents: (number[] | undefined)[]) => {
      let point1 = [HIGHEST_POINT, HIGHEST_POINT]
      let point2 = [-HIGHEST_POINT, -HIGHEST_POINT]

      extents.forEach((item) => {
        if (!item) return
        if (point1[0] > item[0] && point1[1] > item[1]) {
          point1 = [item[0], item[1]]
        }
        if (point2[0] < item[2] && point2[1] < item[3]) {
          point2 = [item[2], item[3]]
        }
      })
      return [point1[0], point1[1], point2[0], point2[1]]
    }
    const mergeExtent = getMergeExtent(extents)

    if (extents) {
      map.getView().fit(mergeExtent, {
        duration: ANIMATION_DURATION,
      })
      map.getView().animate({
        center: getCenterOfExtent(mergeExtent),
        duration: ANIMATION_DURATION,
      })
    }
  }
  const { t } = useTranslation()

  return (
    <Wrapper isMagnetize={isMagnetize}>
      <StyledText>{t('Площадь, мм²')}</StyledText>
      <ScrollWrapper>
        {formattedFeaturesProperties
          .filter((it) => it?.children?.length)
          .map((it) => (
            <TissueGroup
              key={it?.tissue}
              gleason={it?.gleason}
              group={it?.group}
              tissue={it?.tissue}
              class_name={it.class_name}
              classes={it?.children}
              onSelectElementHandle={onSelectElementHandle}
            />
          ))}
      </ScrollWrapper>
      <ToolOpacityController value={opacity} onChange={setOpacity} />
    </Wrapper>
  )
}

export default PathVisionSegmentationContainer
