import { TitleElement } from 'shared/ui/kit'
import { AvatarElement } from 'shared/ui/kit/ui/AvatarElement'
import styled from 'styled-components'
import IUser from 'types/IUser'

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
`

export const Participant: React.FC<{ user?: IUser }> = ({ user }) => {
  if (!user) return null

  return (
    <UserInfo>
      <AvatarElement fullname={user.fullname} numberValue={user.userId} />
      <TitleElement ellipsis level={4}>
        {user.fullname}
      </TitleElement>
    </UserInfo>
  )
}
