import { Map } from 'ol'
import { rotate } from 'ol/coordinate'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useViewerDispatch, useViewerMainSelector } from 'viewer/container'
import { viewerHelpSlice } from 'viewer/help/model/viewerHelpSlice'

type Props = {
  map: Map
}

const DEEP_ZOOM_FAST_FACTOR = 2
const LOW_ZOOM_FAST_FACTOR = 2
const TRANSITIONAL_RESOLUTION = 2
export const FastTravelInteraction = ({ map }: Props) => {
  const { activeViewerId: viewerId, isFastTravel, setFastTravel } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(viewerId)
  const requestedElement = useRef(map.getViewport())
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const totalDelta = useRef<number[]>([0, 0])

  const { t } = useTranslation()

  const EXIT_MESSAGE = t('Для выхода из режима быстрого перемещения нажмите')

  const mousemoveHandler = (e: any): void => {
    const mapCenterPoint = map.getView().getCenter()
    const angle = map.getView().getRotation() // angle in radians
    const zoom = map.getView().getResolution()

    if (!mapCenterPoint || angle === undefined || !zoom) return

    const fastFactor = zoom < TRANSITIONAL_RESOLUTION ? DEEP_ZOOM_FAST_FACTOR : LOW_ZOOM_FAST_FACTOR
    const x = e.movementX || e.mozMovementX || e.webkitMovementX || 0
    const y = e.movementY || e.mozMovementY || e.webkitMovementY || 0

    totalDelta.current = [totalDelta.current[0] + Math.abs(x), totalDelta.current[1] + Math.abs(y)]

    const rotatedDelta = rotate([x * zoom * fastFactor, y * zoom * fastFactor], -angle)

    map.getView().setCenter([mapCenterPoint[0] + rotatedDelta[0], mapCenterPoint[1] - rotatedDelta[1]])
  }

  const handlePointerLockChange = (evt: any) => {
    setFastTravel(!!evt.target.pointerLockElement)
  }

  const havePointerLock = useMemo(
    () =>
      'pointerLockElement' in document || 'mozPointerLockElement' in document || 'webkitPointerLockElement' in document,
    [],
  )

  useEffect(() => {
    if (!havePointerLock) {
      alert(t('Ваш браузер не поддерживает такой функционал'))
      return
    }
    if (selectedAnnotationsIds.length > 0) return
    document.addEventListener('pointerlockchange', handlePointerLockChange)
    if (isFastTravel) {
      if (!document.pointerLockElement) {
        requestedElement.current.requestPointerLock()
      }
      viewerDispatch(viewerHelpSlice.actions.setHelpMessage(EXIT_MESSAGE))
      document.addEventListener('mousemove', mousemoveHandler)
    }
    return () => {
      viewerDispatch(viewerHelpSlice.actions.hideHelpMessage(false))
      document.removeEventListener('pointerlockchange', handlePointerLockChange)
      document.removeEventListener('mousemove', mousemoveHandler)
    }
  }, [isFastTravel, selectedAnnotationsIds])

  useEffect(() => {
    if (!map) return
    requestedElement.current = map.getViewport()

    // prefixes
    requestedElement.current.requestPointerLock =
      requestedElement.current.requestPointerLock ||
      //@ts-ignore
      requestedElement.current.mozRequestPointerLock ||
      //@ts-ignore
      requestedElement.current.webkitRequestPointerLock

    //@ts-ignore
    document.exitPointerLock = document.exitPointerLock || document.mozExitPointerLock || document.webkitExitPointerLock
  }, [map])

  return null
}
