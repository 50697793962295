import { Feature } from 'ol'
import { Geometry } from 'ol/geom'
import Select, { SelectEvent } from 'ol/interaction/Select'
import VectorLayer from 'ol/layer/Vector'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { selectedStyle } from 'viewer/map/layers/olStyles'

import { Ki67Context } from './Ki67Context'

type Props = {
  map: IMapOl
  viewerId: TViewerId
  features: Feature<Geometry>[]
  layer: VectorLayer<any>
}

const Ki67SelectInteraction = ({ features, layer, map, viewerId }: Props) => {
  const selectedFeatureId = useRef<string>()
  const { removeKi67 } = useContext(Ki67Context)
  const { activeViewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { selectedKi67Id } = useViewerMainSelector(viewerId)
  const { slideId } = useViewerIdSlideState(viewerId)
  const selectAnnotation = useRef<Select | undefined>(
    new Select({
      hitTolerance: 20,
      layers: [layer],
      style: [selectedStyle],
    }),
  )

  useHotkeys(
    'del, backspace',
    () => {
      selectedKi67Id && removeKi67(selectedKi67Id)
    },
    {
      enabled: !!(selectedKi67Id && activeViewerId === viewerId),
    },
    [selectedKi67Id],
  )

  useEffect(() => {
    selectAnnotation.current && map.addInteraction(selectAnnotation.current)

    selectAnnotation.current?.on('select', (e: SelectEvent) => {
      if (!e.selected[0]) {
        selectedFeatureId.current = undefined
        viewerDispatch(viewerSlice.actions.selectKi67Id(undefined))
        return
      }

      if (e.selected[0].get('ki67Id')) {
        selectedFeatureId.current = e.selected[0].get('ki67Id')
        const ki67Id = e.selected[0]?.get('ki67Id')
        viewerDispatch(viewerSlice.actions.selectKi67Id(ki67Id))
      }
    })

    return () => {
      selectAnnotation.current && map.removeInteraction(selectAnnotation.current)
    }
  }, [slideId])

  useEffect(() => {
    if (selectedFeatureId.current === selectedKi67Id) {
      return
    }
    const newFeature = features.find((item) => item.get('ki67Id') === selectedKi67Id)

    selectedFeatureId.current = selectedKi67Id
    if (newFeature) {
      selectAnnotation.current?.getFeatures().clear()
      selectAnnotation.current?.getFeatures().push(newFeature)
      const geometry = newFeature?.getGeometry()
      if (geometry) {
        const extent = geometry.getExtent()

        map.getView().fit(extent, {
          duration: 500,
          padding: [100, 100, 100, 100],
        })
      }
    }
  }, [features, selectedKi67Id])

  return null
}

export default Ki67SelectInteraction
