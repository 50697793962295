/** Табы в загрузке слайдов */
export enum EUploadedFileTab {
  /** Ожидает */
  AWAITING_CASE = 'AWAITING_CASE',
  /** Ошибки */
  NOT_ATTACHED_TO_CASE = 'NOT_ATTACHED_TO_CASE',
  /** Разобрано */
  ATTACHED_TO_CASE = 'ATTACHED_TO_CASE',
  /** Корзина*/
  DELETED = 'DELETED',
  /** Поиск*/
  SEARCH = 'SEARCH',
  /** Дубли слайда */
  DUPLICATE = 'DUPLICATE',
  /** Выбранные файла в корзине*/
  SELECTED = 'SELECTED',
}

/** Статус загрузки слайдов (на русском метод getBarcodeFailText) */
export enum EUploadedFileAttachmentState {
  CONVERSION_IN_PROGRESS = 'CONVERSION_IN_PROGRESS',
  CONVERSION_IS_FAILED = 'CONVERSION_IS_FAILED',
  LABEL_NOT_EXIST = 'LABEL_NOT_EXIST',
  BARCODE_NOT_RECOGNIZED = 'BARCODE_NOT_RECOGNIZED',
  WORKSPACE_HAS_NO_MASK_TO_PARSE_BARCODE = 'WORKSPACE_HAS_NO_MASK_TO_PARSE_BARCODE',
  CAN_NOT_PARSE_BARCODE_DATA = 'CAN_NOT_PARSE_BARCODE_DATA',
  AWAITING_CASE = 'AWAITING_CASE',
  CAN_NOT_SELECT_CASE_SEARCH_STRATEGY = 'CAN_NOT_SELECT_CASE_SEARCH_STRATEGY',
  CAN_NOT_SELECT_FILE_ATTACH_STRATEGY = 'CAN_NOT_SELECT_FILE_ATTACH_STRATEGY',
  LICENSE_CASE_EXCEEDED = 'LICENSE_CASE_EXCEEDED',
  SUCCESS = 'SUCCESS',
  DUPLICATE = 'DUPLICATE',
}

/** Типы загруженного слайда (на русском getFileTypeText)*/
export enum EUploadedFileType {
  MICRO = 'MICRO',
  MACRO = 'MACRO',
  DOCUMENT = 'DOCUMENT',
}

export enum EUploadedFileSortBy {
  CREATED_AT = 'CREATED_AT',
  ATTACHED_TO_CASE_AT = 'ATTACHED_TO_CASE_AT',
  DELETED_AT = 'DELETED_AT',
  PATH = 'PATH',
  STATE = 'STATE',
  FILE_TYPE = 'FILE_TYPE',
  ATTACHED_TO_CASE_BY_USER = 'ATTACHED_TO_CASE_BY_USER',
  DELETED_BY_USER = 'DELETED_BY_USER',
}
