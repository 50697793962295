import { get } from 'shared/api'
import { ISlideDataLayer, ISlideDataLayerGeoJSON } from 'types/ISlideDataLayer'

export type SlideDataLayersParams = {
  caseId: number
  slideId: number
}
export type SlideDataLayerGeoJSONParams = SlideDataLayersParams & {
  slideDataLayerId?: number
}

const fetchSlideDataLayers = (params: SlideDataLayersParams) => {
  const url = `/case/${params.caseId}/slide/${params.slideId}/data-layer`
  return get<ISlideDataLayer[]>({ url })
}

const fetchSlideDataLayerGeoJSON = (params: SlideDataLayerGeoJSONParams) => {
  const url = `/case/${params.caseId}/slide/${params.slideId}/data-layer/${params.slideDataLayerId}`
  return get<ISlideDataLayerGeoJSON>({ url })
}

export const slideDataLayersService = {
  fetchSlideDataLayerGeoJSON,
  fetchSlideDataLayers,
}
