import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'
import { SpaceElement, TextElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components'
import IReport from 'types/IReport'

const Info = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`

type InfoProps = {
  title: string
  value: string
}

export const InfoElement = ({ title, value }: InfoProps) => (
  <Info>
    <TextElement style={{ color: 'var(--color-text-3)' }}>{title}</TextElement>
    <TextElement>{value}</TextElement>
  </Info>
)

type ReportInfoProps = {
  report?: IReport
}

const PatientReportInfo = ({ report }: ReportInfoProps) => {
  const { t } = useTranslation()
  return report ? (
    <div>
      <TitleElement level={4}>
        {formatDateForDisplay(report.createdAt)} {report.createdBy?.fullname}
      </TitleElement>

      {report.icd10 && (
        <>
          <SpaceElement size={4} />
          <InfoElement title={t('Топографический код')} value={`${report.icd10.shortName} (${report.icd10.name})`} />
        </>
      )}
      {report.morphologyCode?.shortName && (
        <>
          <InfoElement
            title={t('Гистологический код')}
            value={`${report.morphologyCode.shortName} (${report.morphologyCode.name})`}
          />
          {/* <TitleElement level={4}>Гистологический код</TitleElement>
          <TextElement>{report.morphologyCode.description + '   ' + report.morphologyCode.code}</TextElement> */}
        </>
      )}
      {report.report && (
        <>
          <SpaceElement size={16} />
          <TextElement>{report.report}</TextElement>
          <br />
        </>
      )}
      {report.comment && (
        <>
          <SpaceElement size={16} />
          <TitleElement level={4}>{t('Комментарий')}</TitleElement>
          <SpaceElement size={4} />
          <TextElement>{report.comment}</TextElement>
          <br />
        </>
      )}
      {report.microDescription && (
        <>
          <SpaceElement size={16} />
          <TitleElement level={4}>{t('Микроописание')}</TitleElement>
          <SpaceElement size={4} />
          <TextElement>{report.microDescription}</TextElement>
          <br />
        </>
      )}

      {report.morphologyCode?.shortName && (
        <TextElement>
          {t('ICD-O код')} {report.morphologyCode?.shortName}
        </TextElement>
      )}
    </div>
  ) : null
}

export default PatientReportInfo
