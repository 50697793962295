import { AutoComplete } from 'antd'
import { IPermissionActionType } from 'entities/permissions'
import { useSubscription } from 'processes/stomp'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { IOptions, IUsersGroupByWorkspaceName } from 'types/IOptions'
import IUser from 'types/IUser'
import { v4 as uuidv4 } from 'uuid'

import { findWorkspaceByUser } from './lib/common'

const LoginFormControl = styled(AutoComplete)`
  width: 280px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
  &.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    & .ant-select-selection-placeholder {
      display: inline-flex;
      align-items: center;
    }
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: var(--color-bg-3);
    color: var(--color-text-1);
  }
  &.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    height: 40px;
  }
`

type WsResponse = {
  timestamp: number
  payload: IOptions
  opId: string
  success: boolean
  type: 'AutocompleteUserMessageResult'
}

const uuid = uuidv4()

type Props = {
  value?: IPermissionActionType
  onChange?: (value: IPermissionActionType) => void
  onSearch: (query: string, uuid: string) => void
  /** Колбэк фокусировки на инпут */
  onFocus: () => void
  /** функция выбора пользователя */
  onSelect: (userId: number, workspaceName: string) => void
  /** воркспейсы доступные для шеринга */
  options: IOptions
  /** функция для изменения списка */
  setOptions: (value: IOptions | ((prevState: IOptions) => IOptions)) => void
  close: () => void
}

const UserAutoCompleteContainer = ({
  close,
  onChange,
  onFocus,
  onSearch,
  onSelect,
  options,
  setOptions,
  value,
}: Props) => {
  const { t } = useTranslation()
  const selectedUser =
    value?.type === 'USER' || value?.type === 'USER_ACQUIRED'
      ? options?.usersGroupByWorkspaceName[
          findWorkspaceByUser({ criteria: 'userId', options, searchKey: Number(value.userId) }) || ''
        ]?.find((o: IUser) => o.userId === Number(value.userId))
      : undefined

  useSubscription<WsResponse>(`/user/topic/autocomplete/users/${uuid}`, (result) => {
    const payload: IUsersGroupByWorkspaceName = result.payload
    const optionsPayload: IOptions = {
      isAutocompleteSource: true,
      usersGroupByWorkspaceName: payload.usersGroupByWorkspaceName,
    }
    setOptions(optionsPayload)
  })
  const userSelectHandler = (v: any) => {
    const workspaceName = findWorkspaceByUser({ criteria: 'userId', options, searchKey: v })
    workspaceName && onSelect(v, workspaceName)
  }

  return (
    <LoginFormControl
      value={selectedUser?.fullname || (value?.type === 'EMAIL' && value?.email)}
      onSelect={userSelectHandler}
      onSearch={(val) => onSearch(val, uuid)}
      onFocus={onFocus}
      placeholder={t('ФИО, электронная почта, логин')}
      style={{ alignItems: 'center', display: 'inline-flex', height: '40px', marginRight: '8px' }}
    />
  )
}

export default UserAutoCompleteContainer
