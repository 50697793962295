import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { Feature } from 'ol'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { pluralText } from 'shared/lib/text'
import { CollapseComponent, CollapsePanel } from 'shared/ui/collapse'
import { SmallIconButtonElement } from 'shared/ui/kit'
import styled from 'styled-components'
import ISlide from 'types/ISlide'
import { useObjectsCountingContext } from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

import { countingTypesText } from './countingTypes'
import ObjectsPreviewField from './ObjectsPreviewField'

type Props = {
  spot: Feature<any>
  spotId: string
  slide?: ISlide
}

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const Count = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-1);
`

const StyledCollapse = styled(CollapseComponent)`
  border-radius: 5px;
  .ant-collapse-item {
    border-bottom: 0;
  }
  .ant-select-dropdown {
    width: 100px;
  }

  .ant-collapse-header {
    padding: 0px !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }
`

const Spot: React.FC<Props> = ({ slide, spot, spotId }) => {
  const { data, getMitosisInSpot, removeSpot } = useObjectsCountingContext()
  const points = getMitosisInSpot(spotId)
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType) || 'OBJECTS'
  const { t } = useTranslation()

  const onRemove = () => {
    points.forEach((it) => it.set('isHidden', false))
    removeSpot(spotId)
  }

  const text = pluralText(points.length, countingTypesText[countingObjectType].pluralText)

  return (
    <Wrapper>
      {Object.keys(data).length > 1 ? (
        <StyledCollapse ghost bordered={false} defaultActiveKey={Object.keys(data)}>
          <CollapsePanel
            key={spotId}
            showArrow
            header={
              <Header>
                <Count>
                  {points.length} {t(text)}
                </Count>
                <SmallIconButtonElement icon={'delete'} onClick={onRemove} fill="#DC524C" />
              </Header>
            }
          >
            <ObjectsPreviewField points={points} slide={slide} />
            <Divider />
          </CollapsePanel>
        </StyledCollapse>
      ) : (
        <ObjectsPreviewField points={points} slide={slide} />
      )}
    </Wrapper>
  )
}

export default Spot
