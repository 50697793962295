import { FormButton } from 'features/form'
import { MobileLandscape } from 'features/mobile-control'
import { ChatContainer, SupportChat } from 'features/support-chat'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UNSUPPORTED_DEVICE_ACCEPTED } from 'shared/lib/common/storageKeys'
import { IconElement } from 'shared/ui/kit'
import Logo from 'shared/ui/logo'
import styled from 'styled-components/macro'

const PlaceholderContainer = styled(MobileLandscape)`
  background-color: var(--color-bg-2);
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  height: 100svh;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const PlaceholderWrapperBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  margin-top: 24vh;
  height: 100%;
`

const StyledTextElement = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 16px 29px 0 29px;

  @media (max-width: 375px) {
    font-size: 12px;
  }
`

const StyledTitleElement = styled.div`
  color: var(--color-text-1);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 24px;

  @media (max-width: 375px) {
    font-size: 20px;
  }
`

type Props = {
  setIsAccessBrowser: (access: boolean) => void
}

export const MobilePlaceholder: FC<Props> = ({ setIsAccessBrowser }) => {
  const { t } = useTranslation()

  const onConfirmUnsupportedDevice = () => {
    localStorage.setItem(UNSUPPORTED_DEVICE_ACCEPTED, 'true')
    setIsAccessBrowser(true)
  }

  return (
    <PlaceholderContainer>
      <Logo />
      <PlaceholderWrapperBody>
        <IconElement name="sadEmoji" size="xxl" />
        <StyledTitleElement>{t('На мобильных устройствах платформа может работать некорректно')}</StyledTitleElement>
        <StyledTextElement>{t('Для корректной работы платформы необходим компьютер')}</StyledTextElement>
        <FormButton style={{ marginTop: '50px' }} onClick={onConfirmUnsupportedDevice}>
          {t('Все равно продолжить')}
        </FormButton>
      </PlaceholderWrapperBody>
      <ChatContainer>
        <SupportChat />
      </ChatContainer>
    </PlaceholderContainer>
  )
}
