import { TabPaneProps, Tabs, TabsProps } from 'antd'
import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { trimTextWithLastChar } from 'shared/lib/text'
import styled from 'styled-components'
import { EUploadedFileTab } from 'types/IUploadedFile'
import { TCasesManagementTabs } from 'types/TTab'

/** Пропсы для таба */
export type TTabItem = {
  /** Лейбл */
  label: string | ReactNode
  /** Количество кейсов в табе для отображения в название таба */
  count?: number
  /** id таба */
  id: string
  /** Цвет для количества */
  color?: string
  /** Тип таба */
  tabType: TCasesManagementTabs | EUploadedFileTab
  /** Дополнительная информация о количество кейсов */
  specialCount?: number | string
  dataTestId?: string
}

export type TTableTabsProps = {
  tabsItems: TTabItem[]
  activeTabType: any
} & TabPaneProps &
  TabsProps

export const TableTabs: FC<PropsWithChildren<TTableTabsProps>> = ({
  activeTabType,
  children,
  onChange,
  onTabClick,
  tabsItems,
  ...rest
}) => (
  <StyledTabs
    {...rest}
    defaultActiveKey={activeTabType}
    type="card"
    activeKey={activeTabType}
    tabBarGutter={15}
    size="small"
    onTabClick={onTabClick}
    onChange={onChange}
    destroyInactiveTabPane
  >
    {tabsItems.map(({ color, count, dataTestId, label, specialCount, tabType }) => {
      const customCount = specialCount ?? count
      return (
        <Tabs.TabPane
          key={tabType}
          tab={
            <LabelWrapper data-testid={`${(dataTestId ?? tabType).toLowerCase()}-tab`}>
              <div className="custom-tab-border-left" />
              <p style={{ marginRight: '8px' }}>{label}</p>
              <p
                data-testid={`${tabType.toLowerCase()}-tab-extra-label`}
                style={{ color: color || 'var(--color-text-1)' }}
              >
                {trimTextWithLastChar(customCount)}
              </p>
              <div className="custom-tab-border-right" />
            </LabelWrapper>
          }
        >
          <ContentWrapper>{children}</ContentWrapper>
        </Tabs.TabPane>
      )
    })}
  </StyledTabs>
)

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;

  & .ant-tabs-content {
    height: 100%;
  }

  && .ant-tabs {
    width: 100vw;
  }

  .ant-tabs-nav {
    margin: 0;
  }
  && .ant-tabs-nav-list {
    margin-left: 20px;
  }

  && .ant-tabs-tab {
    background: var(--color-bg-1);
    color: var(--color-text-1);
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0 6px;
    border-radius: 10px 10px 0px 0px;
    transition-duration: 0s;
  }
  && .ant-tabs-tab.ant-tabs-tab-active {
    position: relative;
    color: var(--color-text-1);
    background: var(--color-bg-2);
    border-radius: 10px 10px 0px 0px;
  }

  .ant-tabs-tab-btn:hover {
    color: var(--color-text-1);
  }

  .ant-tabs-nav-wrap {
    background-color: var(--color-bg-1);
  }

  && .ant-tabs-nav-operations {
    display: none;
  }

  && .ant-tabs-tab.ant-tabs-tab-active .custom-tab-border-left {
    position: absolute;
    width: 10px;
    height: 10px;
    left: -10px;
    bottom: 0px;
    background: var(--color-bg-2);

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 0px;
      bottom: 0px;
      background: var(--color-bg-1);
      border-bottom-right-radius: 10px;
    }
  }
  && .ant-tabs-tab.ant-tabs-tab-active .custom-tab-border-right {
    position: absolute;
    width: 10px;
    height: 10px;
    right: -10px;
    bottom: 0px;
    background: var(--color-bg-2);

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      right: 0px;
      bottom: 0px;
      background: var(--color-bg-1);
      border-bottom-left-radius: 10px;
    }
  }
  .ant-tabs-nav-list {
    margin-top: 16px;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`
const ContentWrapper = styled.div`
  padding: 16px 16px 0;
  display: flex;
  height: 100%;
  flex-direction: row;
`
