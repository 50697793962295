import { SEGMENTATION } from 'entities/slide-layers'
import { Feature } from 'ol'
import { Geometry } from 'ol/geom'
import VectorImageLayer from 'ol/layer/VectorImage'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect } from 'react'
import { MapContext } from 'shared/lib/map'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import { IResultType } from 'types/ISlideMetadata'
import { useViewerMainSelector } from 'viewer/container'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'

type Props = {
  features: Feature<Geometry>[]
  type: IResultType
  classId: number
}
const baseStyle = new Style({
  stroke: new Stroke({
    color: '#000',
    width: 2,
  }),
})

const SegmentationLayer = ({ classId, features, type }: Props) => {
  const { viewerId } = useContext(MapContext)
  const map = useViewerIdMap(viewerId)
  const { segmentationLayers, segmentationOpacity } = useViewerMainSelector(viewerId)
  const isVisible = segmentationLayers[classId]?.visible
  const fillLayer = new VectorImageLayer({
    opacity: MAX_OPACITY,
    source: new VectorSource({
      features: features,
    }),
    style: new Style({
      fill: new Fill({
        color: SEGMENTATION[type].color,
      }),
    }),
    visible: true,
  })

  const layer = new VectorImageLayer({
    source: new VectorSource({
      features: features,
    }),
    style: [
      new Style({
        stroke: new Stroke({
          color: '#000',
          width: 4,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: SEGMENTATION[type].color,
          width: 2,
        }),
      }),
    ],
    visible: true,
  })

  useEffect(() => {
    layer.set(INTERNAL_TYPE_ID, LayerType.SEGMENTATION)
    map?.addLayer(layer)
    map?.addLayer(fillLayer)
    fillLayer.setOpacity(segmentationOpacity / 100)
    fillLayer.setVisible(isVisible)
    layer.setVisible(isVisible)
    return () => {
      map?.removeLayer(layer)
      map?.removeLayer(fillLayer)
    }
  }, [features, segmentationOpacity, isVisible])
  return null
}

export default SegmentationLayer
