import { Popover, PopoverProps } from 'antd'
import React, { useState } from 'react'

type Props = {
  disable?: boolean
}

const HoverWithClickPopover: React.FC<PopoverProps & Props> = ({ children, disable, ...props }) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const [clicked, setClicked] = useState<boolean>(false)

  const hide = () => {
    setClicked(false)
    setHovered(false)
  }

  const handleHoverChange = (visible: boolean) => {
    if (!disable) {
      setHovered(visible)
      setClicked(false)
    }
  }

  const handleClickChange = (visible: boolean) => {
    if (!disable) {
      setHovered(false)
      setClicked(visible)
    }
  }

  return (
    <Popover
      color="var(--color-bg-1)"
      placement="bottomRight"
      trigger="hover"
      visible={hovered}
      onVisibleChange={handleHoverChange}
      mouseLeaveDelay={0}
      destroyTooltipOnHide
      {...props}
    >
      <Popover
        color="var(--color-bg-1)"
        placement="bottomRight"
        trigger="click"
        visible={clicked}
        onVisibleChange={handleClickChange}
        mouseEnterDelay={0}
        {...props}
      >
        {children}
      </Popover>
    </Popover>
  )
}

export default HoverWithClickPopover
