import styled from 'styled-components/macro'

const ProgressWrapper = styled.div`
  position: relative;
  background-color: var(--color-bg-2);
  height: 100%;
  width: 100%;
  padding: 4px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledProgress = styled.div<{ percent: number }>`
  height: 2px;
  width: 100%;
  background: linear-gradient(
    90deg,
    var(--color-purple) 0%,
    var(--color-purple) ${({ percent }) => percent || 0}%,
    var(--color-bg-3) ${({ percent }) => percent || 0}%,
    var(--color-bg-3) 100%
  );
`

const InfoText = styled.div`
  position: absolute;
  bottom: 4px;
  left: 4px;
  font-size: 11px;
  line-height: 12px;
  color: var(--color-text-3);
`

type Props = {
  percent: number
}

const ThumbnailProgress = ({ percent }: Props) => (
  <ProgressWrapper>
    <StyledProgress percent={percent} />
    <InfoText>{percent}%</InfoText>
  </ProgressWrapper>
)

export default ThumbnailProgress
