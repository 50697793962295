import React from 'react'
import { useTranslation } from 'react-i18next'
import { TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  margin: 4px 8px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const TitleBarCode = styled.p`
  & {
    margin: 3px 0 0 0;
    font-size: 12px;
    line-height: 16px;
  }
`

export type openOnBarCodeCopyProps = {
  barcode: any
}

const SumpWarningCopy = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <TextWrapper>
        <TitleElement level={4}>{t('Штрихкод скопирован в буфер обмена')}</TitleElement>
      </TextWrapper>
    </Wrapper>
  )
}
export default SumpWarningCopy
