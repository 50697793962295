import { push } from 'connected-react-router'
import { MobileLandscape } from 'features/mobile-control'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { confirmRegistration } from 'processes/user'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'shared/ui/header'
import { SpaceElement, SpinElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledLogin = styled(MobileLandscape)`
  background-color: var(--color-bg-1);
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const ConfirmRoute = () => {
  const isActivated = useConfirmAndRedirect()
  const { t } = useTranslation()
  return (
    <StyledLogin>
      <PageHeader />
      <SpaceElement size={100} style={{ width: '100%' }} />
      <Content>
        {isActivated && (
          <TitleElement level={1} style={{ textAlign: 'center' }}>
            {t('Учетная запись активирована.')}
          </TitleElement>
        )}
        {!isActivated && <SpinElement />}
      </Content>
    </StyledLogin>
  )
}

export const useConfirmAndRedirect = () => {
  const [isActivated, setActivated] = useState(false)
  const { params } = useRouteMatch<{ userId: string; token: string }>()
  const workspaceId = useCurrentWorkspaceId()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!params.token) return
    dispatch(confirmRegistration(params.token))
    setActivated(true)
    setTimeout(() => {
      if (workspaceId === null) {
        dispatch(push('/login'))
        return
      }
      if (workspaceId) {
        dispatch(push(`/workspace/${workspaceId}/cases?rel=owner`))
      }
    }, 1500)
  }, [params.token])

  return isActivated
}
