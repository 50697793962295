import { useUserStatusContext } from 'features/multiplayer/lib/user-status'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { ISlideState, useOpenViewers, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { StompClientContext } from 'processes/stomp'
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useThrottle } from 'viewer/map/lib/hooks'

import { CURRENT_VERSION, DEFAULT_PANEL_STATE, DEFAULT_SERIALIZED_PANEL_STATE, THROTTLE_TIME } from './constants'
import {
  formatSlideForSlideState,
  formatStringToViewerState,
  formatViewerStateToString,
  getClearPanelState,
} from './heplers'
import {
  MPClearPanelState,
  MPPaneStateDto,
  MPSerializedClearPanelState,
  MPViewerState,
  TUserActivityContext,
  TUserActivityProvider,
} from './types'

const UserActivityContext = createContext<TUserActivityContext>({
  panelState: DEFAULT_PANEL_STATE,
  publishUserActivity: () => {},
})

export const useUserActivityContext = () => useContext(UserActivityContext)

const UserActivityProvider: React.FC<TUserActivityProvider> = ({ caseId, children }) => {
  const workspaceId = useCurrentWorkspaceId()
  const throttle = useThrottle()

  const { publish, subscribe, unsubscribe } = useContext(StompClientContext)
  const { addViewers, changeViewersSlide, removeViewers, setActiveViewerId, viewingState } = useViewerPageProvided()

  const { activeViewerId, openViewerIds } = useOpenViewers()
  const { targetUserId, usersStatuses } = useUserStatusContext()

  const [panelState, setPanelState] = useState<MPClearPanelState>(DEFAULT_PANEL_STATE)
  const panelStateRef = useRef<MPClearPanelState>(DEFAULT_PANEL_STATE)

  const openViewerIdsRef = useRef(openViewerIds)

  const usersCount = useRef(usersStatuses.length)
  useEffect(() => {
    usersCount.current = usersStatuses.length
  }, [usersStatuses.length])

  // Здесь и ниже ref-ы используются для доступа к актуальному viewingState
  const viewingStateRef = useRef(viewingState)
  useDeepCompareEffect(() => {
    viewingStateRef.current = viewingState
  }, [viewingState])

  const addViewersRef = useRef(addViewers)
  useEffect(() => {
    addViewersRef.current = addViewers
  }, [addViewers, viewingState])

  const changeViewersSlideRef = useRef(changeViewersSlide)
  useEffect(() => {
    changeViewersSlideRef.current = changeViewersSlide
  }, [changeViewersSlide, viewingState])

  const removeViewersRef = useRef(removeViewers)
  useEffect(() => {
    removeViewersRef.current = removeViewers
  }, [removeViewers, viewingState])

  // Топик для отправки сообщений на сервер
  const publishDestination = useMemo(() => `/app/mp/${caseId}/p`, [caseId])
  // Топик для подписки на сообщения от сервера
  const messageDestination = useMemo(() => `/topic/mp/${caseId}/p/${targetUserId}`, [caseId, targetUserId])
  // Заголовки
  const headers = useMemo(() => (workspaceId ? { 'x-oc-workspace-id': String(workspaceId) } : undefined), [workspaceId])

  /**
   * syncByChangeSlides
   * @param serializedPanelState состояние всех вьюверов
   * @returns Массив слайдов ISlideState[] для замены у приемника
   * @description Функция находит несовпадения слайдов у источника и приемника по соответствующим вьюверам
   */
  const syncByChangeSlides = (serializedPanelState: MPSerializedClearPanelState) => {
    const slideStatesToChange: ISlideState[] = []
    for (const key in serializedPanelState) {
      const viewerId = key as TViewerId
      const sourceViewer = serializedPanelState[viewerId]
      if (!sourceViewer) continue
      const recieveViewer = viewingStateRef.current[viewerId]
      if (sourceViewer.slideId !== recieveViewer?.slide.slideId) {
        slideStatesToChange.push(formatSlideForSlideState(sourceViewer, caseId))
      }
    }
    return slideStatesToChange
  }

  /**
   * syncByAddSlides
   * @param serializedPanelState состояние всех вьюверов
   * @returns Массив слайдов ISlideState[], которые нужно добавить приемнику
   * @description Функция находит слайды, которые есть у истночника, но отсутствуют у приемника
   */
  const syncByAddSlides = (serializedPanelState: MPSerializedClearPanelState) => {
    const slideStatesToAdd: ISlideState[] = []
    for (const key in serializedPanelState) {
      const viewerId = key as TViewerId
      const sourceViewer = serializedPanelState[viewerId]
      if (!sourceViewer) continue
      const recieveViewer = viewingStateRef.current[viewerId]
      if (!recieveViewer) {
        slideStatesToAdd.push(formatSlideForSlideState(sourceViewer, caseId))
      }
    }
    return slideStatesToAdd
  }

  /**
   * syncByRemoveSlides
   * @param serializedPanelState состояние всех вьюверов
   * @returns removeCount number: количество вьюверов, которые необходимо закрыть
   * @description Функция считает разницу открытых вьюверов у источника и приемника
   */
  const syncByRemoveSlides = (serializedPanelState: MPSerializedClearPanelState) => {
    let removeCount = 0
    for (const key in serializedPanelState) {
      const viewerId = key as TViewerId
      const sourceViewer = serializedPanelState[viewerId]
      const recieveViewer = viewingStateRef.current[viewerId]
      if (!sourceViewer && recieveViewer) removeCount++
    }
    return removeCount
  }

  /**
   * getConditionsForSync
   * @param serializedPanelState состояние всех вьюверов
   * @returns [viewersDif, viewerIdsToChange] as [number, TViewerId[]]
   * @description Функция находит разность открытых вьюверов у источника и приемника и несовпадения слайдов в конкретных вьюверах
   */
  const getConditionsForSync = (serializedPanelState: MPSerializedClearPanelState) => {
    const sourceOpenedCount = Object.values(serializedPanelState).filter((it) => it).length
    const recieveOpenedCount = Object.keys(viewingStateRef.current).length
    const viewersDif = sourceOpenedCount - recieveOpenedCount
    const viewerIdsToChange: TViewerId[] = []

    if (viewersDif === 0) {
      for (const key in serializedPanelState) {
        const viewerId = key as TViewerId
        const sourceViewer = serializedPanelState[viewerId]
        if (!sourceViewer) continue
        const recieveViewer = viewingStateRef.current[viewerId]
        if (sourceViewer.slideId !== recieveViewer?.slide.slideId) {
          viewerIdsToChange.push(viewerId)
        }
      }
    }
    return [viewersDif, viewerIdsToChange] as [number, TViewerId[]]
  }

  const onGetMessage = (payload: MPPaneStateDto) => {
    // Только данные по вьюверам в виде строк
    const clearPanelState = getClearPanelState(payload)
    // Только данные по вьюверам в виде объектов MPViewerState
    const serializedPanelState: MPSerializedClearPanelState = { ...DEFAULT_SERIALIZED_PANEL_STATE }

    // Сериализация
    for (const key in clearPanelState) {
      const viewerId = key as TViewerId
      serializedPanelState[viewerId] = formatStringToViewerState(clearPanelState[viewerId])
    }

    const [viewersDif, viewerIdsToChange] = getConditionsForSync(serializedPanelState)

    if (viewersDif === 0 && viewerIdsToChange.length > 0) {
      // Синхронизация, когда число открытых вьюверов равно, но слайды в них разные
      const slideStatesToChange = syncByChangeSlides(serializedPanelState)
      changeViewersSlideRef.current(viewerIdsToChange, slideStatesToChange)
    } else if (viewersDif > 0) {
      // Синхронизация, когда у источника больше открытых вьюверов
      const slideStatesToAdd = syncByAddSlides(serializedPanelState)
      // Сначала нужно уравнять кол-во открытых вьюверов
      addViewersRef.current(slideStatesToAdd)
      // Таймаут нужен для создания макрозадачи на изменение слайдов после микрозадачи добавления слайдов
      setTimeout(() => {
        // Затем проверить слайды во вьюверах и заменить, при необходимости
        const [_, viewerIdsToChange] = getConditionsForSync(serializedPanelState)
        const slideStatesToChange = syncByChangeSlides(serializedPanelState)
        viewerIdsToChange.length > 0 && changeViewersSlideRef.current(viewerIdsToChange, slideStatesToChange)
      })
    } else if (viewersDif < 0) {
      // Синхронизация, когда у источника меньше открытых вьюверов
      const removeCount = syncByRemoveSlides(serializedPanelState)
      // Сначала нужно уравнять кол-во открытых вьюверов
      removeViewersRef.current(removeCount)
      // Таймаут нужен для создания макрозадачи на изменение слайдов после микрозадачи удаления слайдов
      setTimeout(() => {
        // Затем проверить слайды во вьюверах и заменить, при необходимости
        const [_, viewerIdsToChange] = getConditionsForSync(serializedPanelState)
        const slideStatesToChange = syncByChangeSlides(serializedPanelState)
        viewerIdsToChange.length > 0 && changeViewersSlideRef.current(viewerIdsToChange, slideStatesToChange)
      })
    }

    setActiveViewerId(payload.act)

    panelStateRef.current = clearPanelState
    setPanelState(panelStateRef.current)
  }

  const onSetPanelState = (viewerId: TViewerId, payload?: MPViewerState) => {
    panelStateRef.current = {
      ...panelStateRef.current,
      [viewerId]: formatViewerStateToString(payload),
    }
    setPanelState(panelStateRef.current)
  }

  const onPublishUserActivity = throttle(() => {
    if (Object.values(panelStateRef.current).every((it) => it === '-')) return
    publish<MPPaneStateDto>(
      publishDestination,
      {
        act: activeViewerId,
        v: CURRENT_VERSION,
        ...panelStateRef.current,
      },
      headers,
    )
  }, THROTTLE_TIME)

  const publishUserActivity = (viewerId: TViewerId, payload?: MPViewerState) => {
    if (!openViewerIdsRef.current.includes(viewerId) || usersCount.current === 1 || !payload?.centerX) return
    onSetPanelState(viewerId, payload)

    onPublishUserActivity()
  }

  useEffect(() => {
    if (targetUserId) {
      subscribe<MPPaneStateDto>(messageDestination, onGetMessage, headers)
    }
    return () => {
      unsubscribe(messageDestination)
    }
  }, [targetUserId])

  useDeepCompareEffect(() => {
    openViewerIdsRef.current = openViewerIds
    if (usersCount.current === 1) return
    const newPanelState = { ...DEFAULT_PANEL_STATE }

    openViewerIdsRef.current.forEach((it) => {
      const slideId = viewingStateRef.current[it]?.slide.slideId
      if (!slideId) return
      const payload = Object.values(panelStateRef.current).find((it) => it.startsWith(slideId.toString()))
      newPanelState[it] = payload || '-'
    })

    panelStateRef.current = { ...newPanelState }
    setPanelState(panelStateRef.current)

    onPublishUserActivity()
  }, [openViewerIds])

  return (
    <UserActivityContext.Provider value={{ panelState, publishUserActivity }}>{children}</UserActivityContext.Provider>
  )
}

export default UserActivityProvider
