import qs from 'qs'
import { get } from 'shared/api'
import IListOfItemsCursor from 'types/api/IListOfItemsCursor'
import { IAtlasSlide } from 'types/IAtlasSlide'

type FetchParams = {
  clientCode?: string[]
  before?: number
  after?: number
  stainCode?: string[]
  direction?: 'next' | 'prev'
  uploadedDateFrom?: string
  uploadedDateTo?: string
  validationStatus?: boolean[]
}

const fetchAtlasSlides = async (params: FetchParams) =>
  get<IListOfItemsCursor<IAtlasSlide>>({
    config: {
      params,
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
    },
    url: '/atlas/slide',
  })

const atlasService = {
  fetchAtlasSlides,
}
export default atlasService
