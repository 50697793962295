import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import Polygon from 'ol/geom/Polygon'
import { viewerPageSlice } from 'pages/viewer'
import { createContext, useContext, useEffect, useState } from 'react'
import { DataLayerTypes } from 'types/ISlideDataLayer'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch } from 'viewer/container'
import { useCurrentDataLayer, useSlideDataLayers } from 'viewer/tools/api/query'

/** Контекст Сегментации опухолевой ткани для простаты  */
type IPathVisionSegmentation = {
  /** Массив features из GeoJSON-а dataLayer PV_PROSTATE */
  features: Feature<Polygon>[]
  /** Уровень прозрачность полигонов на карте */
  opacity: number
  /** Коллбэк для установки уровня прозрачности полигонов на карте */
  setOpacity: (val: number) => void
  /** Идентификатор подсчечиваемой фичи */
  hoveredClassId?: string
  /** Коллбэк для установки идентификатора подсчечиваемой фичи */
  setHoveredClassId: (val?: string) => void
}

/** Начальный уровень прозрачности полигонов */
const DEFAULT_SEGMENTATION_OPACITY = 50

/** Функция для перевода значения цвета из hex в rgb
 * Не используется, но может пригодится в будущем
 */
export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

const PathVisionSegmentationContext = createContext<IPathVisionSegmentation>({
  features: [],
  opacity: DEFAULT_SEGMENTATION_OPACITY,
  setHoveredClassId: () => {},
  setOpacity: () => {},
})

export const usePathVisionSegmentationContext = () => useContext(PathVisionSegmentationContext)

/** Props for PathVisionSegmentationProvider */
type Props = {
  /** Идентификатор текущего случая */
  caseId: number
  /** Идентификатор текущего слайда */
  slideId: number
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
  /** Флаг, отслеживающий видимость панели */
  isVisible: boolean
}

export const PathVisionSegmentationProvider: React.FC<Props> = ({ caseId, children, isVisible, slideId, viewerId }) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const [features, setFeatures] = useState<Feature<Polygon>[]>([])
  const [opacity, setOpacity] = useState(DEFAULT_SEGMENTATION_OPACITY)
  const [hoveredClassId, setHoveredClassId] = useState<string | undefined>()

  const { data: dataLayers } = useSlideDataLayers({ caseId, slideId })
  const pvProstateDataLayer = dataLayers?.find(
    (dataLayer) => isVisible && dataLayer.type === DataLayerTypes.PV_PROSTATE,
  )

  const { data: pvProstateGeoJSON } = useCurrentDataLayer({
    caseId,
    slideDataLayerId: pvProstateDataLayer?.slideDataLayerId,
    slideId,
  })

  useEffect(() => {
    viewerDispatch(viewerPageSlice.actions.setTool({ tool: 'PV_PROSTATE', value: false }))
  }, [slideId])

  useEffect(() => {
    if (pvProstateGeoJSON) {
      const features = new GeoJSON().readFeatures(pvProstateGeoJSON)
      setFeatures(features)
    }
  }, [pvProstateGeoJSON])

  return (
    <PathVisionSegmentationContext.Provider
      value={{ features, hoveredClassId, opacity, setHoveredClassId, setOpacity }}
    >
      {children}
    </PathVisionSegmentationContext.Provider>
  )
}
