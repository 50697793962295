import GeoJSON from 'ol/format/GeoJSON'
import { CONFIG_CASE_PANELS_KEY } from 'shared/lib/common/storageKeys'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { unsetSlidePositioningForAnnotation } from 'viewer/map/layers/annotations/lib/helpers'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

type BaseAnnotationDTO = {
  data: any
  metric: number
  type: AnnotationType
  caption?: string
  zindex?: number
}
interface FormatBaseAnnotation {
  (annotation: IAnnotation): BaseAnnotationDTO
}
export const formatToBaseAnnotation: FormatBaseAnnotation = (annotation: IAnnotation) => {
  const feature = getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)
  unsetSlidePositioningForAnnotation(feature)
  return {
    caption: annotation.caption || feature.get('description'),
    data: {
      formattedFeature: new GeoJSON().writeFeature(feature),
      type: 'ANNOTATION',
    },
    metric: annotation.metric,
    type: annotation.type,
    zindex: annotation.zindex || 0,
  }
}

export const updateConfigLocalStorage = (propertyName: string) => {
  const storedDataString = localStorage.getItem(CONFIG_CASE_PANELS_KEY)

  if (storedDataString) {
    const storedDataObject = JSON.parse(storedDataString)

    if (propertyName in storedDataObject) {
      storedDataObject[propertyName] = !storedDataObject[propertyName]

      const updatedDataString = JSON.stringify(storedDataObject)

      localStorage.setItem(CONFIG_CASE_PANELS_KEY, updatedDataString)
    }
  }
}
