import React, { FC } from 'react'
import { isDesktop } from 'react-device-detect'

import { BrowserPlaceholder } from './BrowserPlaceholder'
import { MobilePlaceholder } from './MobilePlaceholder'

type Props = {
  setIsAccessBrowser: (access: boolean) => void
}

export const PlaceholderRoute: FC<Props> = ({ setIsAccessBrowser }) => (
  <>
    {!isDesktop ? (
      <MobilePlaceholder setIsAccessBrowser={setIsAccessBrowser} />
    ) : (
      <BrowserPlaceholder setIsAccessBrowser={setIsAccessBrowser} />
    )}
  </>
)
