import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { createContext, useContext, useState } from 'react'
import ISlideLayerConfig from 'types/ISlideLayerConfig'
import TViewerId from 'types/TViewerId'

import { SlideLayerContainer } from './SlideLayerContainer'

export type ISlideLayerContext = {
  setConfig: (config: ISlideLayerConfig) => void
  config: ISlideLayerConfig | undefined
}
export const SlideLayerContext = createContext<ISlideLayerContext>({
  config: undefined,
  setConfig: () => {},
})

type Props = {
  children: any
  viewerId: TViewerId
}

export const useSlideLayerProvided = () => useContext(SlideLayerContext)

const SlideLayerProvider = ({ children, viewerId }: Props) => {
  const [config, setConfig] = useState<ISlideLayerConfig>()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)

  return (
    <SlideLayerContext.Provider value={{ config, setConfig }}>
      <SlideLayerContainer caseId={caseId} slideId={slideId} source={source} viewerId={viewerId} />
      {children}
    </SlideLayerContext.Provider>
  )
}

export default SlideLayerProvider
