import { ShareFormContainer } from 'features/share/ShareFormContainer'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PopoverWithCloseBtn } from 'shared/ui/popover'

type Props = {
  caseId: number
}

export const SharePopover: FC<Props> = ({ caseId, children }) => {
  const [isVisible, setVisible] = useState(false)
  const [modal, setModal] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <PopoverWithCloseBtn
      content={<ShareFormContainer caseId={caseId} modal={modal} setModal={setModal} />}
      destroyTooltipOnHide
      titleText={modal ? t('Выберите врача') : t('Добавить врачей к просмотру случая')}
      visible={isVisible}
      onClose={() => setVisible(false)}
      onClick={() => setModal(false)}
      modal={modal}
      shareModal
    >
      <div onClick={() => setVisible(!isVisible)}>{children}</div>
    </PopoverWithCloseBtn>
  )
}
