import { Group, RadioGroupProps, RadioProps } from 'antd/es/radio'
import styled from 'styled-components/macro'

const StyledGroupElemet = styled(Group)`
  .ant-radio-wrapper {
    padding-bottom: 8px;
  }
  .ant-radio-inner {
    background-color: var(--color-bg-4);
    border: none;
    width: 14px;
    height: 14px;
    margin: 1px;
  }
  .ant-radio-inner::after {
    background-color: white;
    height: 4px;
    width: 4px;
    top: 5px;
    left: 5px;
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: var(--color-purple);
  }
  .ant-radio + span {
    padding-left: 4px;
  }
`

export type RadioGroupElementsProps = RadioProps & RadioGroupProps

export const RadioGroupElement = (props: RadioGroupElementsProps) => <StyledGroupElemet {...props} />
