import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  position: fixed;
  top: 88px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
`

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 1px solid #000;
`

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AreaText = styled.span`
  display: block;
  font-size: 34px;
  font-weight: 900;
  line-height: 34px;
`

const LabelText = styled.span`
  display: block;
  font-weight: 500;
  line-height: 16px;
`

type Props = {
  /** Текущая площадь  */
  area: number
  /** Видимость уведомления  */
  open: boolean
  /** Коллбэк для скрытия уведомления  */
  onClose: () => void
  /** Время видимости уведомления  */
  duration?: number
}

const ChangeMitosisAreaNotification: React.FC<Props> = ({ area, duration, onClose, open }) => {
  const { t } = useTranslation()
  useEffect(() => {
    open && setTimeout(onClose, duration)
  }, [open])

  if (!open) return null
  return (
    <Wrapper>
      <Inner>
        <Center>
          <AreaText>{area}</AreaText> <LabelText>{t('мм²')}</LabelText>
        </Center>
      </Inner>
    </Wrapper>
  )
}

export default ChangeMitosisAreaNotification
