import { useSlideQuery } from 'entities/slide'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useEventBusProvided } from 'shared/lib/EventBus'
import { getResolutionByZoomLevel, getSlideZoomLevelsOptionGroups } from 'shared/lib/metadata'
import { ButtonElement, IconElement, InputNumberElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { useSlideMapViewSelector } from 'viewer/container'

const StyledZoomToolsPanel = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
  }
  justify-content: flex-end;
`

const StyledZoomButton = styled(ButtonElement)`
  position: relative;
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
`

const StyledInputNumber = styled(InputNumberElement)`
  flex: 0 0 50px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 0 5px 5px 0;
  margin-right: 50px !important;

  &.ant-input-number-affix-wrapper {
    padding-left: 4px;
    flex: 0 0 22px;
    border-radius: 5px 0 0 5px;
  }

  &.ant-input-number-affix-wrapper-focused {
    box-shadow: none;
  }

  & span.ant-input-number-prefix {
    margin-inline-end: 2px;
  }
`

const StyledName = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: var(--color-text-3);
`

const StyledLine = styled.div<{ color: string; position: 'top' | 'bottom' }>`
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: ${({ position }) => position === 'top' && 0};
  bottom: ${({ position }) => position === 'bottom' && 0};
  background: ${({ color }) => color};
`

const MAX_ZOOM_VALUE = 90
const MAX_LENGTH_VALUE = 5
const ZOOM_CHANGE_TIMEOUT = 500

type ZoomButtonProps = {
  name: string
  color: string
  zoomLevel: number
  handleChangeZoom: (zoom: number) => void
}

const ZoomButton = ({ color, handleChangeZoom, name, zoomLevel }: ZoomButtonProps) => (
  <StyledZoomButton onClick={() => handleChangeZoom(zoomLevel)}>
    <StyledLine color={color} position={'top'} />
    <StyledName>{name}</StyledName>
    <StyledLine color={color} position={'bottom'} />
  </StyledZoomButton>
)

export const ZoomToolsPanel = () => {
  const bus = useEventBusProvided()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const { viewZoom } = useSlideMapViewSelector({ slideId, viewerId })
  const dispatch = useDispatch()

  const setIsAnyInputFocusing = (value: boolean) => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  }

  const { data: currentSlide } = useSlideQuery({ caseId, slideId, source })

  const zoomGroups = getSlideZoomLevelsOptionGroups(currentSlide).flatMap((lvl) => [...lvl.options])

  const getZoomLevel = (zoom = 1) => {
    const result = getResolutionByZoomLevel(zoom || 0, currentSlide)
    return result.toFixed(2)
  }

  //@ts-ignore cause Cannot find name 'Timeout' - strange
  const zoomApplyInterval = useRef<Timeout | null>(null)

  return (
    <StyledZoomToolsPanel>
      {zoomGroups.reverse().map((zoom) => (
        <ZoomButton
          key={zoom.key}
          name={zoom.label}
          color={zoom.color}
          zoomLevel={zoom.value}
          handleChangeZoom={(zoom) => {
            bus.$emit('tool:zoom', viewerId, zoom)
          }}
        />
      ))}
      <StyledInputNumber
        min={0}
        max={MAX_ZOOM_VALUE}
        type={'text'}
        maxLength={MAX_LENGTH_VALUE}
        value={getZoomLevel(viewZoom)}
        onChange={(zoom) => {
          if (zoomApplyInterval.current) clearTimeout(zoomApplyInterval.current)

          zoomApplyInterval.current = setTimeout(() => {
            bus.$emit('tool:zoom', viewerId, getResolutionByZoomLevel(Number(zoom) || 0, currentSlide))
          }, ZOOM_CHANGE_TIMEOUT)
        }}
        prefix={<IconElement name={'loupe'} size={'md'} fill={'var(--color-text-3)'} />}
        onFocus={(e) => {
          setIsAnyInputFocusing(true)
          e.target.select()
        }}
        onBlur={() => {
          setIsAnyInputFocusing(false)
        }}
      />
    </StyledZoomToolsPanel>
  )
}
