import { IntegratedBlock, Integration } from 'entities/lis-integration/types'
import i18next from 'shared/lib/i18n/i18n'

const t = i18next.t

/**
 * Мап интеграций на наименований полей в информации по случаю, в которых они будут присутствовать.
 * В зависимости от того, есть ли интеграция для конкретного поля,
 * поле будет показываться, либо скрываться.
 */
const caseInfoIntegrations = new Map<string, Integration[]>()
caseInfoIntegrations
  .set('name', ['none', 'gemotest', 'DZM', 'DeGenie'])
  .set('orderNumber', ['gemotest'])
  .set('department', ['none', 'gemotest', 'DZM'])
  .set('caseDate', ['gemotest', 'DZM', 'DeGenie'])
  .set('services', ['gemotest'])
  .set('incomingIcd10', ['none', 'gemotest', 'DZM'])
  .set('materialTakeoutDate', ['none', 'gemotest', 'DZM'])
  .set('materialType', ['gemotest', 'DZM'])
  .set('task', ['DZM'])
  .set('diagnosticProcedureType', ['none', 'gemotest', 'DZM'])
  .set('caseResearchType', ['gemotest', 'DZM'])
  .set('sites', ['none'])
  .set('biomaterial', ['gemotest', 'DZM'])
  .set('clinicalInfo', ['none', 'gemotest', 'DZM'])
  .set('additionalInfo', ['none', 'gemotest'])
  .set('macroDescription', ['none', 'gemotest', 'DZM'])

const patientIntegrations = new Map<string, Integration[]>()
patientIntegrations
  .set('birthDate', ['none', 'gemotest', 'DZM', 'DeGenie'])
  .set('fullname', ['none', 'gemotest', 'DZM', 'DeGenie'])
  .set('sex', ['none', 'gemotest', 'DZM', 'DeGenie'])
  .set('externalId', ['gemotest'])

const reportIntegrations = new Map<string, Integration[]>()
reportIntegrations
  .set('createdBy-createdAt', ['none', 'gemotest', 'DZM'])
  .set('icd10', ['none', 'gemotest'])
  .set('morphologyCode', ['none', 'gemotest'])
  .set('report', ['none', 'gemotest', 'DZM'])
  .set('caseMacroDescription', ['gemotest', 'DZM'])
  .set('microDescription', ['none', 'gemotest', 'DZM'])
  .set('comment', ['none', 'gemotest', 'DZM'])
  .set('ihcResult', ['gemotest', 'DZM'])
  .set('complexity', ['gemotest'])
  .set('additionalResearchResult', ['gemotest'])
  .set('icd10DetailedList', ['DZM'])
  .set('createdBy', ['DZM', 'DeGenie'])
  .set('consultedBy', ['DZM'])

const fieldLabelsOnLisMode: any = {
  ['biomaterial-DZM']: t('Локализация процесса'),
  ['biomaterial-gemotest']: t('Локализация'),
  ['caseDate-DZM']: t('Дата исследования'),
  ['caseDate-DeGenie']: t('Дата исследования'),
  ['caseDate-gemotest']: t('Дата заказа'),
  ['clinicalInfo-DZM']: t('Клинические сведения'),
  ['clinicalInfo-gemotest']: t('Диагноз основного заболевания'),
  ['clinicalInfo-none']: t('Диагноз основного заболевания'),
  ['comment-DZM']: t('Комментарии к заключению и рекомендации'),
  ['comment-gemotest']: t('Доп. замечания и рекомендации'),
  ['comment-none']: t('Доп. замечания и рекомендации'),
  ['department-DZM']: t('Медицинская организация'),
  ['department-gemotest']: t('Отделение, направившее материал'),
  ['department-none']: t('Отделение, направившее материал'),
  ['diagnosticProcedureType-DZM']: t('Способ получения'),
  ['diagnosticProcedureType-gemotest']: t('Способ получения материала'),
  ['diagnosticProcedureType-none']: t('Способ получения материала'),
  ['ihcResult-DZM']: t('Результат ИГХ исследования'),
  ['ihcResult-gemotest']: t('Результаты ИГХ'),
  ['incomingIcd10-DZM']: t('Диагноз направления'),
  ['incomingIcd10-gemotest']: t('МКБ-10'),
  ['incomingIcd10-none']: t('МКБ-10'),
  ['materialTakeoutDate-DZM']: t('Дата забора'),
  ['materialTakeoutDate-gemotest']: t('Дата забора материала'),
  ['materialTakeoutDate-none']: t('Дата забора материала'),
}

export const checkIntegration = (
  block: IntegratedBlock,
  fieldName: string,
  integration?: Integration,
  addCondition = true,
) => {
  if (!integration) return false
  let integrations: Integration[] = []
  switch (block) {
    case 'caseInfo':
      integrations = caseInfoIntegrations.get(fieldName) || []
      return integrations?.includes(integration) && addCondition
    case 'report':
      integrations = reportIntegrations.get(fieldName) || []
      return integrations?.includes(integration) && addCondition
    case 'patient':
      integrations = patientIntegrations.get(fieldName) || []
      return integrations?.includes(integration) && addCondition
    default:
      return false
  }
}

export const getFieldLabelBylisMode = (fieldName: string, integration?: Integration) =>
  t(fieldLabelsOnLisMode[`${fieldName}-${integration}`]) || ''
