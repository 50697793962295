import TViewerId from 'types/TViewerId'

/** Тип коэффициентов для позиционирования */
type PositionFactor = [number, number]

/** Набор вьюверов с неизменными коэффициентами */
const FIXED_CONTEXT_MENU_POSITION_FACTORS: Record<Exclude<TViewerId, 'C'>, PositionFactor> = {
  A: [0, 0],
  B: [1, 0],
  D: [1, 1],
}

/** Вьювер с переменной позицией. Ключи 3, 4 - количества открытых вью, при которых позиция меняется */
const VARIATIVE_CONTEXT_MENU_POSITION_FACTOR: Record<number, PositionFactor> = {
  3: [1, 0],
  4: [0, 1],
}

/**
 * Функция вычисляет коэффициенты, необходимые для преобразования координат таким образом,
 * чтобы полученная точка находилась над активным вьювером.
 * @param viewerId - идентификатор активного вьювера
 * @param totalOpened - количество открытых вьюверов
 * @returns [xFactor: number, yFactor: number] - массив коэффициентов для смещения в нужный вьювер
 */
export function getFactorsByViewerId(viewerId: TViewerId, totalOpened: number) {
  return viewerId === 'C'
    ? VARIATIVE_CONTEXT_MENU_POSITION_FACTOR[totalOpened]
    : FIXED_CONTEXT_MENU_POSITION_FACTORS[viewerId]
}
