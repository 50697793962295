import { ESortDir } from 'features/cases-management/types/TCasePagination'
import { UploadedFilesAvailableFilters } from 'features/sump/api/service'
import { get } from 'shared/api'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import { EUploadedFileSortBy, EUploadedFileTab } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'
import { TTabCount } from 'types/TTab'

/** Дополнительный оффсет для размера страницы */
const PAGE_COUNT_OFFSET = 2

export type FetchUploadedFilesParams = {
  uploadedFileTab?: string
  sortBy?: EUploadedFileSortBy
  sortDir?: ESortDir
  /** Состояние для поиска */
  uploadedFileAttachmentStates?: string
  size?: number
  /** Штрих код слайда */
  query?: string
}

const initialParams = {
  sortBy: EUploadedFileSortBy.CREATED_AT,
  sortDir: ESortDir.ASC,
}

const fetchUploadedFile = (wsId: number, queryParams: FetchUploadedFilesParams, cursor?: string, size?: number) =>
  cursor === null
    ? undefined
    : get<IListOfItemsCursorCases<IUploadedFileDTO>>({
        config: {
          params: {
            ...(queryParams.query
              ? { ...queryParams }
              : {
                  ...initialParams,
                  ...queryParams,
                }),
            cursor,
            size: size ? size + PAGE_COUNT_OFFSET : undefined,
          },
        },
        url: `/workspace/${wsId}/uploaded-file`,
      })

const fetchUploadedFileCounts = async (wsId: number) =>
  get<TTabCount<EUploadedFileTab>>({
    url: `workspace/${wsId}/uploaded-file/count`,
  })

const fetchAvailableFilters = (wsId: number, uploadedFileTab: EUploadedFileTab) =>
  get<UploadedFilesAvailableFilters>({
    config: {
      params: {
        uploadedFileTab,
      },
    },
    url: `/workspace/${wsId}/uploaded-file/filter`,
  })

export const uploadedFileService = { fetchAvailableFilters, fetchUploadedFile, fetchUploadedFileCounts }
