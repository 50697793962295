import { useTranslation } from 'react-i18next'
import { ParagraphElement, SmallIconButtonElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'

const SiteGrid = styled.div`
  width: 100%;
  display: grid;
  padding: 0 0 4px 8px;
  grid-template-areas: 'code actions' 'desc desc';
  grid-template-columns: 1fr auto;
  align-items: center;
  background: var(--color-bg-3);
  border-radius: 5px;
`

const Actions = styled.div`
  grid-area: actions;
  color: var(--color-text-3);
`

type Props = {
  icd10: DictionaryItem
  onDeleteSite: () => void
  id?: string
}

const SelectedIcd10 = ({ icd10, id, onDeleteSite }: Props) => {
  const { t } = useTranslation()

  return (
    <SiteGrid id={id}>
      <TitleElement level={3} style={{ fontWeight: 400, gridArea: 'code' }} type="secondary">
        {icd10.shortName}
      </TitleElement>
      <Actions>
        <SmallIconButtonElement icon="delete" title={t('Удалить')} placement="left" onClick={onDeleteSite} />
      </Actions>
      <ParagraphElement style={{ gridArea: 'desc', paddingRight: 8 }}>{icd10.name}</ParagraphElement>
    </SiteGrid>
  )
}
export default SelectedIcd10
