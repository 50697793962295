import { slideService } from 'entities/slide'
import { useMutation } from 'react-query'

type IProps = {
  /** Функция обратного вызова, которая будет выполнена при успешном восстановлении. */
  onSuccess: () => void
  /** Идентификатор кейса, к которому принадлежит слайд. */
  caseId?: number
  /** Идентификатор восстанавливаемого слайда. */
  slideId?: number
}

const useRestoreSlide = ({ caseId, onSuccess, slideId }: IProps) => {
  const { mutateAsync: mutateRestoreSlide, ...mutationResult } = useMutation(
    async () => {
      if (caseId && slideId) await slideService.restoreSingleSlide(caseId, slideId)
    },
    {
      onSuccess: onSuccess,
    },
  )

  return { mutateRestoreSlide, ...mutationResult }
}

export default useRestoreSlide
