import i18next from 'shared/lib/i18n/i18n'

const t = i18next.t

/** Используем t для генерации json файла */
export const countingTypesText = {
  MITOSIS: {
    goToText: t('Перейти к митозу'),
    pluralText: [t('митоз'), t('митоза'), t('митозов')],
    textForCounting: t('митозы'),
    textToContinue: t(`Для продолжения регистрации митозов приблизьтесь до увеличения`),
    textToMethod: t('Выберите способ фиксации площади для подсчета митозов'),
    textToStart: t(`Для начала регистрации митозов приблизьтесь до увеличения`),
  },
  OBJECTS: {
    goToText: t('Перейти к клетке'),
    pluralText: [t('клетка'), t('клетки'), t('клеток')],
    textForCounting: t('клетки'),
    textToContinue: t(`Для продолжения регистрации клеток приблизьтесь до увеличения`),
    textToMethod: t('Выберите способ фиксации площади для подсчета клеток'),
    textToStart: t(`Для начала регистрации клеток приблизьтесь до увеличения`),
  },
}
