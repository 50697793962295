import { useSlideQuery } from 'entities/slide'
import { useIiifConfigQuery } from 'entities/slide-layers'
import { FC } from 'react'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'

import SlideLayer from './SlideLayer'

type Props = {
  slideId: number
  caseId: number
  source: ISource
  viewerId: TViewerId
}

export const SlideLayerContainer: FC<Props> = ({ caseId, slideId, source, viewerId }) => {
  const { data: iiifInfo, isLoading } = useIiifConfigQuery(slideId)
  const { data: slide } = useSlideQuery({
    caseId,
    slideId,
    source,
  })

  return (
    <SlideLayer
      caseId={caseId}
      iiifInfo={iiifInfo}
      isInfoLoading={isLoading}
      objectivePower={slide?.slideMetadata?.commonMetadata?.objectivePower}
      adapterLensPower={slide?.slideMetadata?.commonMetadata?.adapterLensPower}
      iiif2AuthToken={slide?.iiif2AuthToken}
      viewerId={viewerId}
    />
  )
}
