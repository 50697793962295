// дефотные размеры миникарты
export const INITIAL_SIZES = [248, 248]
export const INITIAL_BACKGROUND_SIZES = [248, 248]

/** RIGHT_ANGLE - Прямой угол */
export const RIGHT_ANGLE = 90
/** STRAIGHT_ANGLE_X_3 - Прямой угол */
export const RIGHT_ANGLE_X_3 = 90 * 3
/** STRAIGHT_ANGLE - Развернутый угол */
export const STRAIGHT_ANGLE = 180
// ключ локалсторейджа сохранения размера самой длинной стороны слайда
export const MINIMAP_LONG_SIDE_STORAGE_KEY = 'minimapVerticalHeight'
// максимальный размер стороны миникарты
export const MAX_MINIMAP_SIZE = 512
// минимальный размер стороны миникарты
export const MIN_MINIMAP_SIZE = 110
// отступ от границ рабочей области для миникарты
export const RESIZABLE_OFFSET = 100
// максимальное отношение по высоте для миникарты от рабочей области
export const MAX_HEIGHT_MINIMAP_RATIO = 2
// максимальное отношение по ширине для миникарты от рабочей области
export const MAX_WIDTH_MINIMAP_RATIO = 4
// отступы границ
export const MARGIN_BORDER = 40
// минимальная ширина панели
export const MIN_WIDTH_PANEL = 216
/** PIXELS_FOR_SYMBOL - Количество пикселей для одного символа */
export const PIXELS_FOR_SYMBOL = 6
/** ELLIPSIS_PIXELS - Количество пикселей для многоточия и отступов */
export const ELLIPSIS_PIXELS = 24
/** DENOMINATOR - Делитель на две части */
export const DENOMINATOR = 2
/** ELLIPSIS - Многоточие */
export const ELLIPSIS = '...'
/** Кол-во символов видимых до расширения */
export const SYMBOLS_BEFORE_EXT = 10
