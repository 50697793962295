import { TabPaneProps, TabsProps } from 'antd'
import { IButtonData } from 'features/new-preview-panel/common'
import React from 'react'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import IReport from 'types/IReport'

import ButtonsGroupHeader from './ButtonGroupHeader'

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-left: 1px solid var(--color-border-1);
`

const StyledIconResize = styled(IconElement)`
  width: 16px;
  height: 4px;
`

type Props = {
  /** Массив данных о кнопках. */
  buttonsData: IButtonData[]
  /** Массив заключений */
  reports: IReport[] | undefined
  /** Дочерние свойства, включающие в себя TabPaneProps и TabsProps. */
  children?: TabPaneProps & TabsProps
  /** Хендлер кнопки закрытия */
  onClose: () => void
}

const HeaderPreview = ({ buttonsData, children, onClose }: Props) => (
  <HeaderContainer>
    {children}
    <StyledWrapper>
      <StyledIconResize name="resizeVertical" />
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <ButtonsGroupHeader buttons={buttonsData} onClose={onClose} />
      </div>
    </StyledWrapper>
  </HeaderContainer>
)

export default HeaderPreview

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: space-between;
  position: absolute;
  right: 0px;
  width: 50%;
`
