import { useTypedSelector } from 'app/redux/lib/selector'
import { notices } from 'features/notices'
import ChangeMitosisAreaNotification from 'features/notices/ui/ChangeMitosisAreaNotification'
import * as jsts from 'jsts'
import * as _ from 'lodash'
import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import { LinearRing, LineString, MultiLineString, MultiPoint, Point, Polygon } from 'ol/geom'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { getArea } from 'ol/sphere'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { createContext, FC, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { MapContext } from 'shared/lib/map'
import { IAnnotation } from 'types/IAnnotations'
import { DataLayerTypes } from 'types/ISlideDataLayer'
import TViewerId from 'types/TViewerId'
import { v4 as uuidv4 } from 'uuid'
import { getPolygonArea } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { MITOSIS_POINTS_HIT_TOLERANCE } from 'viewer/map/layers/objects-counting/lib/helpers/constants'
import {
  completedMitosisSpotStyle,
  defaultMitosisSpotStyle,
  viewedTrackMitosisSpotStyle,
} from 'viewer/map/layers/olStyles'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'
import { getCenterOfExtent, getFeaturesFromGeoJson } from 'viewer/map/lib/utils'
import { useCurrentDataLayer, useSlideDataLayers } from 'viewer/tools/api/query'

const EMPTY_OBJECT = {}
const EMPTY_ARRAY: any[] = []
const MAX_TOTAL_AREA = 10
const THOUSAND = 1000
export const DEFAULT_COUNTING_AREA = 2
const ANIMATION_DURATION = 500
const TIMEOUT = 400

export const SCREEN_RECORD_ZOOM_LVL = 20

type FeaturesMap = Record<
  string,
  {
    spot: Feature<any>
    viewedTrack: Feature<any>
    mitosis: Feature<Point>[]
  }
>

/** Тип курсора */
type TObjectCountingCursor = 'add' | 'delete' | 'default'

export const ObjectsCountingContext = createContext<{
  data: FeaturesMap
  mitosis: Feature<Point>[]
  addSpot: (feature: Feature<any>) => void
  addViewedTrack: (feature: Feature<any>) => void
  addMitos: (feature: Feature<Point>) => void
  removeSpot: (id: string) => void
  getMitosisInSpot: (id: string) => Feature<Point>[]
  getAllMitosisInSpots: () => Feature<Point>[]
  getTotalMitosisCount: () => number
  /** Флаг на проинициалиризованные митозы*/
  isMitosisInitialized: boolean
  removeMitos: (id: string) => void
  /** Общая площадь полей зрения */
  totalAreaCount: number
  onSaveAnnotation: () => {
    area: number
    mitosis: number
    objectId?: number
    fFeatures: Feature<any>[]
  }
  checkSpotAreaByCoord: (coordinates: number[]) => boolean
  /** Пиксель курсора, чтобы использовать в KeyboardEvent (Зум, перемещение стрелками) */
  lastCursorPositionPixel: number[]
  setLastCursorPositionPixel: (pixel: number[]) => void
  updateCursor: (coords: number[], mouseClick?: boolean) => void
  setCursor: (cursor: TObjectCountingCursor) => void
  /** Сброс стиля курсора на дефолтный */
  handleSetCursorDefault: () => void
}>({
  addMitos: () => EMPTY_OBJECT,
  addSpot: () => EMPTY_OBJECT,
  addViewedTrack: () => EMPTY_OBJECT,
  checkSpotAreaByCoord: () => false,
  data: EMPTY_OBJECT,
  getAllMitosisInSpots: () => EMPTY_ARRAY,
  getMitosisInSpot: (id) => EMPTY_ARRAY,
  getTotalMitosisCount: () => 0,

  handleSetCursorDefault: () => EMPTY_OBJECT,
  isMitosisInitialized: false,

  lastCursorPositionPixel: [0, 0],
  mitosis: EMPTY_ARRAY,
  onSaveAnnotation: () => ({
    area: 0,
    fFeatures: [],
    mitosis: 0,
  }),

  removeMitos: (id) => EMPTY_OBJECT,
  removeSpot: (id) => EMPTY_OBJECT,
  setCursor: () => EMPTY_OBJECT,
  setLastCursorPositionPixel: () => [0, 0],
  totalAreaCount: 0,
  updateCursor: () => 0,
})

export const useObjectsCountingContext = () => useContext(ObjectsCountingContext)

type Props = {
  /** Идентификатор текущего случая */
  caseId: number
  /** Идентификатор текущего слайда */
  slideId: number
  /** Разрешение слайда */
  mppX: number
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
  /** Флаг, отслеживающий видимость панели */
  isVisible: boolean
}

export const ObjectsCountingProvider: FC<Props> = ({ caseId, children, isVisible, mppX, slideId, viewerId }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { objectsCountingMethod, selectedObjectId: objectId } = useTypedSelector(
    (state) => state.viewers[viewerId].viewer,
  )
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType)
  const { map } = useContext(MapContext)

  const mAnnotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, objectId])
  const { data: dataLayers } = useSlideDataLayers({ caseId, slideId })
  const mitosisDataLayer = dataLayers?.find((dataLayer) => isVisible && dataLayer.type === DataLayerTypes.MITOSIS)

  const { data: mitosisLayer } = useCurrentDataLayer({
    caseId,
    slideDataLayerId: mitosisDataLayer?.slideDataLayerId,
    slideId,
  })
  const [data, setData] = useState<FeaturesMap>(EMPTY_OBJECT)
  const prevData = useRef<FeaturesMap>(EMPTY_OBJECT)
  const [mitosis, setMitosis] = useState<Feature<Point>[]>(EMPTY_ARRAY)
  const prevMitosis = useRef<Feature<Point>[]>(EMPTY_ARRAY)
  const [totalSpotsArea, setTotalSpotsArea] = useState(0)
  const totalArea = useRef<number>(0)
  const fixedTotalArea = useRef<number>(0)
  const prevFixedTotalArea = useRef<number>(0)
  const [isChangeAreaNoticeOpen, setIsChangeAreaNoticeOpen] = useState(false)
  const parser = useRef(new jsts.io.OL3Parser())
  const { t } = useTranslation()
  /** Пиксель курсора, чтобы использовать в KeyboardEvent (Зум, перемещение стрелками) */
  const [lastCursorPositionPixel, setLastCursorPositionPixel] = useState<number[]>([0, 0])
  const [isMitosisInitialized, setIsMitosisInitialized] = useState<boolean>(false)
  //@ts-ignore
  parser.current.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)
  useEffect(() => {
    // TODO: Избавиться от timeout-ов в рамках ONECELL-3505
    setTimeout(() => {
      if (mAnnotation) {
        const features = getFeaturesFromGeoJson(mAnnotation?.data?.formattedFeature)
        const mitosisBboxes = features.filter((it) => it.get('element') === 'bbox')
        const mitosisMultiPolygon = new MultiPolygon([])
        mitosisBboxes.forEach((it) => mitosisMultiPolygon.appendPolygon(it.getGeometry()))
        map.getView().fit(mitosisMultiPolygon.getExtent(), {
          duration: ANIMATION_DURATION,
        })
        map.getView().animate(
          {
            center: getCenterOfExtent(mitosisMultiPolygon.getExtent()),
            duration: ANIMATION_DURATION,
          },
          () => {
            /** После анимации обновляем курсор */
            map.renderSync()
            updateCursor(map.getCoordinateFromPixel(lastCursorPositionPixel))
          },
        )
      }
    }, TIMEOUT)
  }, [mAnnotation])

  useEffect(() => {
    if (!countingObjectType || (countingObjectType === 'MITOSIS' && mitosisLayer === undefined))
      return setIsMitosisInitialized(false)
    // TODO: Избавиться от timeout-ов в рамках ONECELL-3505
    setTimeout(() => {
      if (countingObjectType) {
        clearData()
        _initMitosis()
        setIsMitosisInitialized(true)
      }
    }, TIMEOUT)
  }, [objectId, countingObjectType, mitosisLayer])

  useEffect(() => {
    dispatch(viewerPageSlice.actions.setTool({ tool: 'MITOSIS', value: false }))
  }, [slideId])

  const clearData = () => {
    totalArea.current = 0
    setTotalSpotsArea(totalArea.current)
    prevData.current = {}
    setData(prevData.current)
    prevMitosis.current = []
    setMitosis(prevMitosis.current)
    dispatch(viewerPageSlice.actions.setIsObjectsDeleting(false))
    dispatch(viewerPageSlice.actions.setWhetherToOpenObjectsContext(true))
  }

  const _initMitosis = () => {
    if (countingObjectType === 'MITOSIS') {
      if (!mitosisLayer || !Array.isArray(mitosisLayer)) return
      prevMitosis.current = mitosisLayer.map((it) => {
        const feature = new Feature(new Point([it[0], it[1]]))
        const id = uuidv4()
        feature.set('id', id)
        return feature
      })
      setMitosis(prevMitosis.current)
    }

    const fFeature = mAnnotation?.data?.formattedFeature
    if (!fFeature) return
    const features = new GeoJSON().readFeatures(fFeature)

    features.forEach((f) => {
      if (f.get('element') === 'bbox') {
        try {
          addSpot(f)
        } catch (err: any) {
          notices.error({
            message: err.message,
          })
        }

        return
      }
      if (f.get('element') === 'track') {
        !!f?.getGeometry() && addViewedTrack(f)
        return
      }
      if (f.get('element') === 'extra_cells') {
        const multiPoint = f.getGeometry() as MultiPoint
        const points = multiPoint.getPoints()
        points.forEach((p) => addMitos(new Feature(p)))
        return
      }
      if (f.get('element') === 'excluded_cells') {
        const multiPoint = f.getGeometry() as MultiPoint
        const excluded_cells = multiPoint.getPoints().map((p) => p.getCoordinates())
        const dataById = prevData.current[f.get('bboxId')]
        const newMitosis = prevMitosis.current.map((m) => {
          const mCoord = m.getGeometry()?.getCoordinates()
          if (!mCoord) return m
          if (excluded_cells.find((exCell) => _.isEqual(exCell, mCoord))) {
            m.set('isHidden', true)
            return m
          }
          return m
        })
        const newSpotMitosis = _getMitosisInSpot(parser.current.read(dataById.spot.getGeometry()))

        prevMitosis.current = newMitosis
        setMitosis(prevMitosis.current)
        prevData.current = {
          ...prevData.current,
          [f.get('bboxId')]: {
            ...dataById,
            mitosis: newSpotMitosis,
          },
        }
        setData(prevData.current)
        return
      }
    })
  }

  const _getSpots = () => Object.values(prevData.current).map((record) => record.spot)
  const _getSpotsJstsGeom = () => _getSpots().map((spot) => parser.current.read(spot?.getGeometry()))

  const _updateStylesByViewedArea = (feature: Feature<any>) => {
    feature.get('trackPercent') > 80
      ? feature.setStyle(completedMitosisSpotStyle)
      : feature.setStyle(defaultMitosisSpotStyle)
  }

  const _getIntersectedSpot = (geom: jsts.geom.Geometry) => {
    const spots = _getSpots()
    let id = null
    const intersectedSpot = spots.find((spot) => {
      const spotGeom = parser.current.read(spot.getGeometry())
      const isIntersects = spotGeom.intersects(geom)
      if (isIntersects) id = spot.get('id')
      return isIntersects
    })
    return {
      id,
      spot: intersectedSpot,
    }
  }

  const _getAreaRatio = (f1: Feature<any>, f2: Feature<any>) => {
    const f1Area = getArea(f1.getGeometry())
    const f2Area = getArea(f2.getGeometry())
    const [min, max] = [Math.min(f1Area, f2Area), Math.max(f1Area, f2Area)]
    return (min * 100) / max
  }

  const _getMitosisInSpot = (spotGeom: jsts.geom.Geometry) =>
    Object.values(prevMitosis.current).filter((fPoint) => {
      const pointGeom = parser.current.read(fPoint.getGeometry())
      return spotGeom.contains(pointGeom)
    })

  const _findSpotIdByMitosId = (mitosId: string) => {
    const spot = Object.values(prevData.current).find((record) =>
      record.mitosis.find((mitos) => mitos.get('id') === mitosId),
    )
    return spot?.spot.get('id')
  }

  const addSpot = (spot: Feature<any>) => {
    const spotArea = +(getPolygonArea(spot.getGeometry(), mppX) / THOUSAND / THOUSAND).toFixed(2)
    if (spotArea + totalArea.current > MAX_TOTAL_AREA) {
      throw new Error(t('Превышена общая площадь подсчета'))
    }

    if (!spot.get('id')) {
      const id = uuidv4()
      spot.set('id', id)
    }
    spot.setStyle(defaultMitosisSpotStyle)
    const spotGeom = parser?.current?.read(spot?.getGeometry())
    const filterList: number[] = []

    // Пересекающиеся области
    const intersectedSpots = _getSpotsJstsGeom().filter((spot, idx) => {
      const isIntersects = spot.intersects(spotGeom)
      if (isIntersects) filterList.push(idx)
      return isIntersects
    })
    // Пересекающиеся области

    const mergedSpotGeom = intersectedSpots.reduce((acc, feature, i) => acc.union(feature), spotGeom)
    spot.setGeometry(parser.current.write(mergedSpotGeom))

    // Слияние просмотренных областей
    const viewedTracksGeom = Object.values(prevData.current)
      .filter((record, idx) => !!record.viewedTrack && filterList.includes(idx))
      .map((record) => parser.current.read(record.viewedTrack.getGeometry()))
    const mergedViewedTrackGeom = viewedTracksGeom.reduce((acc, feature, i) => acc.union(feature), viewedTracksGeom[0])
    const newViewedTrack = new Feature()
    if (mergedViewedTrackGeom) {
      newViewedTrack.setGeometry(parser.current.write(mergedViewedTrackGeom))
      newViewedTrack.setStyle(viewedTrackMitosisSpotStyle)
      const percent = _getAreaRatio(spot, newViewedTrack)
      spot.set('trackPercent', percent)
      _updateStylesByViewedArea(spot)
    }
    // Слияние просмотренных областей

    // Удаление пересекающихся областей
    const newData = { ...prevData.current }
    Object.keys(newData).forEach((id, idx) => filterList.includes(idx) && delete newData[id])
    // Удаление пересекающихся областей

    const mitosis = _getMitosisInSpot(mergedSpotGeom)

    prevData.current = {
      ...newData,
      [spot.get('id')]: {
        mitosis,
        spot,
        viewedTrack: mergedViewedTrackGeom ? newViewedTrack : undefined,
      },
    }
    setData(prevData.current)
    totalArea.current = +(_getSpotsTotalArea() / THOUSAND / THOUSAND).toFixed(2)
    setTotalSpotsArea(totalArea.current)
    prevFixedTotalArea.current = fixedTotalArea.current
    fixedTotalArea.current = Math.floor(totalArea.current)
    /** Подсказка появляется только в режиме подсчета на видимой области при повышении площади */
    if (objectsCountingMethod === 'screenRecord' && fixedTotalArea.current > prevFixedTotalArea.current) {
      setIsChangeAreaNoticeOpen(true)
    }
  }

  const addViewedTrack = (viewedTrack: Feature<any>) => {
    viewedTrack.setStyle(viewedTrackMitosisSpotStyle)

    // @ts-ignore
    if (!parser.current.ol) return

    const viewedTrackGeom = parser.current.read(viewedTrack.getGeometry())
    const { id, spot } = _getIntersectedSpot(viewedTrackGeom)
    if (!id || !spot) return

    const currentViewedTrack = prevData.current[id].viewedTrack
    if (currentViewedTrack) {
      const currentGeom = parser.current.read(currentViewedTrack?.getGeometry())
      const newGeometry = parser.current.read(spot?.getGeometry()).intersection(viewedTrackGeom).union(currentGeom)
      viewedTrack.setGeometry(parser.current.write(newGeometry))
    } else {
      const newGeometry = parser.current.read(spot?.getGeometry()).intersection(viewedTrackGeom)
      viewedTrack.setGeometry(parser.current.write(newGeometry))
    }
    const percent = _getAreaRatio(viewedTrack, spot)
    spot.set('trackPercent', percent)
    _updateStylesByViewedArea(spot)

    const dataById = prevData.current[id]
    prevData.current = {
      ...prevData.current,
      [id]: {
        ...dataById,
        spot,
        viewedTrack,
      },
    }
    setData(prevData.current)
  }

  /** Функция проверки на пересечение площади аннотации митозов по координатам */
  const checkSpotAreaByCoord = (coordinates: number[]) => {
    const spots = _getSpots()
    return spots.some((spot) => spot.getGeometry().intersectsCoordinate(coordinates))
  }

  const addMitos = (mitos: Feature<Point>) => {
    if (!mitos.get('id')) {
      const id = uuidv4()
      mitos.set('id', id)
    }
    mitos.set('isUser', true)
    const spots = _getSpots()
    const mitosGeom = mitos.getGeometry()
    const spot = spots.find((spot) => spot.getGeometry().intersectsCoordinate(mitosGeom?.getCoordinates()))
    if (!spot) return

    const dataById = prevData.current[spot.get('id')]
    prevData.current = {
      ...prevData.current,
      [spot.get('id')]: {
        ...dataById,
        mitosis: [...dataById.mitosis, mitos],
      },
    }
    setData(prevData.current)
    prevMitosis.current = [...prevMitosis.current, mitos]
    setMitosis(prevMitosis.current)
  }

  const removeSpot = (id: string) => {
    const newData = { ...prevData.current }
    const spot = newData[id].spot
    removeUsersMitosisInSpot(id)
    const spotArea = +(getPolygonArea(spot.getGeometry(), mppX) / THOUSAND / THOUSAND).toFixed(2)
    delete newData[id]
    prevData.current = newData
    setData(prevData.current)
    totalArea.current = +(totalArea.current - spotArea).toFixed(2)
    setTotalSpotsArea(totalArea.current)
  }

  const removeUsersMitosisInSpot = (spotId: string) => {
    const newData = { ...prevData.current }
    const spotMitosis = newData[spotId].mitosis
    const usersMitosisIds = spotMitosis.filter((it) => it.get('isUser')).map((it) => it.get('id'))
    prevMitosis.current = prevMitosis.current.filter((it) => !usersMitosisIds.includes(it.get('id')))
    setMitosis(prevMitosis.current)
  }

  const getMitosisInSpot = (id: string) => prevData.current[id].mitosis

  const getAllMitosisInSpots = () =>
    Object.values(prevData.current).reduce((prev: Feature<Point>[], cur) => prev.concat(cur.mitosis), [])

  const getTotalMitosisCount = () =>
    Object.values(prevData.current)
      .reduce((prev: Feature<Point>[], cur) => prev.concat(cur.mitosis), [])
      .filter((f) => !f.get('isHidden')).length

  const removeMitos = (id: string) => {
    const mitosById = getAllMitosisInSpots().find((mitos) => mitos.get('id') === id)
    if (!mitosById) return
    const spotId = _findSpotIdByMitosId(id)
    const dataById = prevData.current[spotId]
    if (mitosById.get('isUser')) {
      prevMitosis.current = prevMitosis.current.filter((mitos) => mitos.get('id') !== id)
      prevData.current = {
        ...prevData.current,
        [spotId]: {
          ...dataById,
          mitosis: dataById.mitosis.filter((mitos) => mitos.get('id') !== id),
        },
      }
    } else {
      mitosById.set('isHidden', !mitosById.get('isHidden'))
      prevMitosis.current = prevMitosis.current.map((mitos) => {
        if (mitos.get('id') !== id) return mitos
        return mitosById
      })
      prevData.current = {
        ...prevData.current,
        [spotId]: {
          ...dataById,
          mitosis: dataById.mitosis.map((mitos) => {
            if (mitos.get('id') !== id) return mitos
            return mitosById
          }),
        },
      }
    }
    setMitosis(prevMitosis.current)
    setData(prevData.current)
  }

  const _getSpotsTotalArea = () => {
    const spots = _getSpots()
    if (!spots) return 0
    return spots.reduce((acc, feature, i) => acc + getPolygonArea(feature.getGeometry(), mppX), 0)
  }

  const onSaveAnnotation = () => {
    const fFeatures = Object.values(prevData.current).reduce((prev: Feature<any>[], cur) => {
      const collection: Feature<any>[] = []
      const bbox = cur.spot
      bbox.set('element', 'bbox')
      bbox.set('viewed', bbox.get('trackPercent') >= 80)
      bbox.set('num', cur.mitosis.length)
      bbox.set('area', getArea(bbox.getGeometry()))
      collection.push(bbox)

      const track = cur?.viewedTrack
      if (track) {
        track.set('element', 'track')
        track.set('bboxId', bbox.get('id'))
        collection.push(track)
      }

      const extra_cells_geom = cur.mitosis
        .filter((it) => it.get('isUser'))
        .map((it) => it.getGeometry()?.getCoordinates() as number[])
      if (extra_cells_geom.length > 0) {
        const extra_cells = new Feature(new MultiPoint(extra_cells_geom))
        extra_cells.set('element', 'extra_cells')
        extra_cells.set('bboxId', bbox.get('id'))
        collection.push(extra_cells)
      }

      const excluded_cells_geom = cur.mitosis
        .filter((it) => it.get('isHidden'))
        .map((it) => it.getGeometry()?.getCoordinates() as number[])
      if (excluded_cells_geom.length > 0) {
        const excluded_cells = new Feature(new MultiPoint(excluded_cells_geom))
        excluded_cells.set('element', 'excluded_cells')
        excluded_cells.set('bboxId', bbox.get('id'))
        collection.push(excluded_cells)
      }

      return prev.concat(collection)
    }, [])

    setIsMitosisInitialized(false)

    const mitosisData = {
      area: totalArea.current,
      fFeatures,
      mitosis: getTotalMitosisCount(),
      objectId: objectId,
    }

    clearData()

    return mitosisData
  }

  const setCursor = (cursor: TObjectCountingCursor) => {
    switch (cursor) {
      case 'add':
        map.getViewport().classList.add('cursor-add')
        map.getViewport().classList.remove('cursor-delete')
        break
      case 'delete':
        map.getViewport().classList.add('cursor-delete')
        map.getViewport().classList.remove('cursor-add')
        break
      case 'default':
        map.getViewport().classList.remove('cursor-delete')
        map.getViewport().classList.remove('cursor-add')
    }
  }

  /** Функция обновления курсора */
  const updateCursor = (coords: number[], mouseClick = false) => {
    const pixel = map?.getPixelFromCoordinate(coords)
    const isSpotArea = checkSpotAreaByCoord(coords)

    if (mouseClick) {
      handleCursorOnMouseClick(pixel, isSpotArea)
    } else {
      handleCursorOnMouseMove(pixel, isSpotArea)
    }
  }

  /** Функция для смены курсора на MouseClick */
  const handleCursorOnMouseClick = (pixel: number[], isSpotArea: boolean) => {
    const features = map.getFeaturesAtPixel(pixel, {
      hitTolerance: MITOSIS_POINTS_HIT_TOLERANCE,
      layerFilter: (layer) => layer.get(INTERNAL_TYPE_ID) === LayerType.MITOSIS_POINTS,
    })

    if (!isSpotArea && !features?.length) return

    !isSpotArea ? setCursor('default') : features?.length === 1 && setCursor('add')
  }

  /** Функция для смены курсора на MouseMove */
  const handleCursorOnMouseMove = (pixel: number[], isSpotArea: boolean) => {
    const mitosisPointsHit = map.hasFeatureAtPixel(pixel, {
      hitTolerance: MITOSIS_POINTS_HIT_TOLERANCE,
      layerFilter: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.MITOSIS_POINTS,
    })

    if (mitosisPointsHit) {
      setCursor('delete')
    } else {
      setCursor(isSpotArea ? 'add' : 'default')
    }
  }

  /** Функция для смены курсора на default */
  const handleSetCursorDefault = () => {
    setCursor('default')
  }

  return (
    <ObjectsCountingContext.Provider
      value={{
        addMitos,
        addSpot,
        addViewedTrack,
        checkSpotAreaByCoord,
        data,
        getAllMitosisInSpots,
        getMitosisInSpot,
        getTotalMitosisCount,
        handleSetCursorDefault,
        isMitosisInitialized,
        lastCursorPositionPixel: lastCursorPositionPixel,
        mitosis,
        onSaveAnnotation,
        removeMitos,
        removeSpot,
        setCursor,
        setLastCursorPositionPixel: setLastCursorPositionPixel,
        totalAreaCount: totalSpotsArea,
        updateCursor,
      }}
    >
      {children}
      <ChangeMitosisAreaNotification
        open={isChangeAreaNoticeOpen}
        onClose={() => setIsChangeAreaNoticeOpen(false)}
        area={fixedTotalArea.current}
        duration={3000}
      />
    </ObjectsCountingContext.Provider>
  )
}
