import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'shared/lib/hooks'
import NavigationBody from 'shared/ui/navigation/NavigationBody'

import DashboardNavigationType from './DashboardNavigationType'

const DashboardNavigationMainContainer = () => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const navItems = [
    {
      disabled: true,
      icon: 'stats',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Stats,
      path: `/dashboard?filter=${DashboardNavigationType.Stats}`,
      title: t('Статистика'),
    },
    {
      disabled: true,
      icon: 'history',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.History,
      path: `/dashboard?filter=${DashboardNavigationType.History}`,
      title: t('История'),
    },
    {
      icon: 'management',
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Settings,
      path: `/dashboard?filter=${DashboardNavigationType.Settings}&subpage=mash`,
      title: t('Управление'),
    },
  ]
  return <NavigationBody navItems={navItems} />
}

export default DashboardNavigationMainContainer
