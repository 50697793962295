import { Menu } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import styled from 'styled-components'

export const FilterTitle = styled.span`
  font-weight: 600;
`

export const FilterText = styled.span`
  margin-left: 3px;
  font-weight: 400;
`

export const StyledMenu = styled(Menu)`
  ${() => modalBackground}
  ${() => modalShadow}
`
