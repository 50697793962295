// ключ для сохрания фильтров/сортировок общего поиска
export const SEARCH_KEY = 'SEARCH'
export const ATTACHED_TO_CASE_TAB = 'ATTACHED_TO_CASE'
export const NOT_ATTACHED_TO_CASE_TAB = 'NOT_ATTACHED_TO_CASE'
export const DELETED_TAB = 'DELETED'
export const AWAITING_CASE_TAB = 'AWAITING_CASE'
// минимальная длина для общего поиска по случаям
export const SEARCH_MIN_LENGTH = 1
// размер страницы пейджинга
export const PAGE_SIZE = 50
// дебаунс для поиска
export const SEARCH_BUFFER = 500
// минимальная длина строки для подсказок автокпомлита
export const AUTOCOMPLETE_MIN_LENGTH = 2
