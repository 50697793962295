import { IAnnotationQuery } from 'features/annotations/api/type'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IAnnotation } from 'types/IAnnotations'
import TViewerId from 'types/TViewerId'

export const useAnnotationsList = (viewerId: TViewerId) => {
  const queryClient = useQueryClient()
  const { slideId } = useViewerIdSlideState(viewerId)
  const annotationsIds = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
  const annotations: IAnnotation[] =
    (annotationsIds?.ids
      ?.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
      .filter((item) => item?.data) as IAnnotation[]) || []
  return annotations
}
