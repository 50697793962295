import * as Sentry from '@sentry/react'
import { Client, StompHeaders } from '@stomp/stompjs'
import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { createContext, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getSocketUrl } from 'shared/api'
import { getTokenFromStorage } from 'shared/lib/local-storage'
import SockJS from 'sockjs-client'
import { v4 as uuidv4 } from 'uuid'

/** задержка перед попыткой повторного подключения к серверу */
const RECONNECT_INTERVAL = 5000
/** интервал для отправки "heartbeat" сообщений на сервер (входящие). */
const HEARTBEAT_INCOMING_INTERVAL = 4000
/** интервал для отправки "heartbeat" сообщений на сервер (исходящие). */
const HEARTBEAT_OUTGOING_INTERVAL = 4000

type Context = {
  stompClient: Client | undefined
  isConnected: boolean
  subscribe<Message>(
    destination: string,
    callback: (message: Message, unsubscribe: () => void) => void,
    headers?: StompHeaders,
  ): () => void
  unsubscribe: (destination: string) => void
  publish<Body = any>(destination: string, body: Body, headers?: StompHeaders): void
  singleRequest<Message>(args: { subscriptionDest: string; publishDest: string; payload: any }): Promise<Message>
}

const isNanString = (str: string) => {
  if (str.includes('NaN')) {
    const errorMessage = 'WS ERROR: ' + str
    console.error(errorMessage)
    Sentry.captureException(errorMessage)
    return true
  }
}

export const StompClientContext = createContext<Context>({
  isConnected: false,
  publish: (destination, body, headers) => {},
  singleRequest: () => new Promise(() => {}),
  stompClient: undefined,
  subscribe: (destination, callback, headers) => () => {},
  unsubscribe: () => {},
})

export const StompClientProvider: FC = ({ children }) => {
  const dispatch = useDispatch()
  const pathname = location.pathname
  const [isConnected, setConnected] = useState(false)
  const queue = useRef<Array<(...args: any[]) => void>>([])
  const subscriptionsMap = useMemo(() => new Map<string, string>(), [])
  const token = useTypedSelector((state) => state.user.token)
  const stompClient = useMemo(
    () =>
      new Client({
        debug: console.log,
        heartbeatIncoming: HEARTBEAT_INCOMING_INTERVAL,
        heartbeatOutgoing: HEARTBEAT_OUTGOING_INTERVAL,
        onStompError: (frame) => {
          const errorMessage = 'WS ERROR: ' + frame?.headers?.message
          console.error(errorMessage)
          Sentry.captureException(errorMessage)
        },
        onWebSocketClose: () => {
          const actualToken = getTokenFromStorage()
          if (!actualToken && !['/registration', '/request-reset'].includes(pathname)) {
            dispatch(push('/login'))
          }
        },
        reconnectDelay: RECONNECT_INTERVAL,
        webSocketFactory: () => (getTokenFromStorage() ? new SockJS(getSocketUrl()) : undefined),
      }),
    [token],
  )

  // connect and disconnect wia token
  useEffect(() => {
    if (token && getTokenFromStorage()) {
      stompClient.activate()
      const interval = setInterval(() => {
        if (stompClient.connected && stompClient.active) {
          clearInterval(interval)
          setConnected(true)
        }
      }, 500)
    } else {
      if (stompClient?.connected) {
        stompClient.deactivate()
      }
      setConnected(false)
    }
    return () => {
      if (stompClient?.connected) {
        stompClient.deactivate()
      }
    }
  }, [token])

  useEffect(() => {
    if (isConnected) {
      for (const callback of queue.current) {
        callback()
        queue.current.pop()
      }
    }
  }, [isConnected])

  function subscribe<Message>(
    destination: string,
    callback: (message: Message, unsubscribe: () => void) => void,
    headers?: StompHeaders,
  ) {
    if (!isNanString(destination)) {
      unsubscribe(destination)
      if (stompClient?.connected) {
        const sub = stompClient.subscribe(
          destination,
          (response) => {
            const message: Message = JSON.parse(response.body)
            console.log('message', destination, message)
            callback(message, () => unsubscribe(destination))
          },
          headers,
        )
        subscriptionsMap.set(destination, sub.id)
        console.log('subscribe', destination)
        // console.log('subscriptions', subscriptionsMap)
      } else {
        queue.current.push(subscribe.bind(null, destination, callback as any))
      }
    }
    return () => unsubscribe(destination)
  }

  function unsubscribe(destination: string) {
    if (!isNanString(destination)) {
      if (stompClient?.connected) {
        const subId = subscriptionsMap.get(destination)
        if (subId) {
          subscriptionsMap.delete(destination)
          stompClient.unsubscribe(subId)
          console.log('unsubscribe', destination)
          // console.log('subscriptions', subscriptionsMap)
        }
      } else {
        queue.current.push(unsubscribe.bind(null, destination))
      }
    }
  }

  function publish<Body = any>(destination: string, body: Body, headers?: StompHeaders) {
    if (!isNanString(destination)) {
      if (stompClient?.connected) {
        stompClient.publish({
          body: JSON.stringify(body),
          destination,
          headers,
        })
      } else {
        queue.current.push(publish.bind(null, destination, body, headers))
      }
    }
  }
  function singleRequest<Message>({
    payload,
    publishDest,
    subscriptionDest,
  }: {
    subscriptionDest: string
    publishDest: string
    payload: any
  }) {
    const opId = uuidv4()
    publish(publishDest + opId, payload)
    return new Promise<Message>((resolve, reject) => {
      subscribe<Message>(subscriptionDest + opId, (response, unsubscribe) => {
        resolve(response)
        unsubscribe()
      })
    })
  }

  return (
    <StompClientContext.Provider value={{ isConnected, publish, singleRequest, stompClient, subscribe, unsubscribe }}>
      {children}
    </StompClientContext.Provider>
  )
}
