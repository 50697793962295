import { useTypedSelector } from 'app/redux/lib/selector'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { uniq } from 'lodash'
import { useSubscription, WsResponseComment } from 'processes/stomp'
import { CommentMessageEnum } from 'processes/stomp/types'
import { useMemo } from 'react'
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import IListOfItems from 'types/api/IListOfItems'
import IComment, { ICommentDTO } from 'types/IComment'
import IUser from 'types/IUser'

import commentsService from './service'

const COMMENTS_PER_PAGE = 8

export const useCommentsQuery = (caseId: number) => {
  const currentUser = useTypedSelector((state) => state.user.user)
  const workspaceId = useCurrentWorkspaceId()

  const queryClient = useQueryClient()
  useSubscription<WsResponseComment>(
    `/queue/case/${caseId}/comment`,
    (result) => {
      if (result.type === CommentMessageEnum.Deleted) {
        const deletedComment = queryClient.getQueryData<IComment>([QUERY_TYPE.COMMENT, result.payload])
        deletedComment && queryClient.invalidateQueries([QUERY_TYPE.COMMENT, { caseId }])
      } else queryClient.invalidateQueries([QUERY_TYPE.COMMENT, { caseId }])
    },
    [],
    workspaceId ? { 'x-oc-workspace-id': String(workspaceId) } : undefined,
  )

  const query = useInfiniteQuery<IListOfItems<IComment> | undefined, unknown, number[]>(
    [QUERY_TYPE.COMMENT, { caseId }],
    async (data) => {
      if (!data) return
      const { pageParam = 0 } = data
      const response = await commentsService.fetchComments(caseId, {
        page: pageParam,
        size: COMMENTS_PER_PAGE,
      })
      if (!response) return
      if (response.content?.length) {
        response.content.map((comment) => {
          queryClient.setQueryData([QUERY_TYPE.COMMENT, comment.commentId], () =>
            createCommentFromDto(comment, currentUser),
          )
        })
      }
      return response
    },
    {
      getNextPageParam: (currentPage) => {
        if (currentPage && !currentPage.last) {
          return currentPage.number + 1
        }
      },
      keepPreviousData: true,
      //@ts-ignore
      select: (data) => ({
        pageParams: data.pages?.map((list) => {
          if (list) {
            const { content, ...params } = list
            return params
          }
        }),
        pages: data.pages.map((list) => list?.content.map((item) => item.commentId)),
      }),
    },
  )
  const pages = query.data?.pages || []
  const ids: number[] = useMemo(() => uniq(pages.flatMap((page) => page)), [pages])
  //@ts-ignore
  const total = query.data?.pageParams && query.data?.pageParams[0] ? query.data?.pageParams[0].totalElements : null
  return {
    ...query,
    ids,
    total,
  }
}

export const useCommentQuery = (commentId: number) => {
  const queryClient = useQueryClient()
  return useQuery<IComment | undefined>([QUERY_TYPE.COMMENT, commentId], async () =>
    queryClient.getQueryData<IComment>([QUERY_TYPE.COMMENT, commentId]),
  )
}

const createCommentFromDto = (comment: ICommentDTO, currentUser?: IUser): IComment => ({
  ...comment,
  isOwner: comment.user?.userId === currentUser?.userId,
})
