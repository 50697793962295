import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type TProps = {
  /** Родительский случай, иначе считаем что дочерний */
  isParent: boolean
}

const CaseLinkIcon: FC<TProps> = ({ isParent }) => {
  const { t } = useTranslation()

  return (
    <TooltipElement title={t(isParent ? 'Родительский случай' : 'Дочерний случай')}>
      {/* Тултип не цепляется за IconElement, поэтому добавил обертку */}
      <IconWrapper>
        <StyledIconElement name="caseLink" size="md" isParent={isParent} />
      </IconWrapper>
    </TooltipElement>
  )
}

export default CaseLinkIcon

const StyledIconElement = styled(IconElement)<{ isParent: boolean }>`
  color: var(--color-emerald);
  transform: ${({ isParent }) => (isParent ? 'rotate(90deg)' : 'none')};

  &:hover {
    color: var(--color-emerald);
  }
`

const IconWrapper = styled.span`
  line-height: 0;
`
