import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import React from 'react'

import UploadedFileSwitcher from './UploadedFileSwitcher'

const UploadedFileSwitcherContainer = () => {
  const { isTableMode, setTableMode } = useUploadedFileContext()

  const handleChecked = () => setTableMode((prev) => !prev)

  return <UploadedFileSwitcher checked={isTableMode} handleChecked={handleChecked} />
}

export default UploadedFileSwitcherContainer
