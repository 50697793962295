import { CaseCursors } from 'features/cases/api/service'
import {
  EAllCasesTabType,
  ECaseTableType,
  EFavoriteList,
  EMyCasesTabType,
} from 'features/cases-management/types/ECaseTableType'
import { IOperationResult, TCaseFavorites, TCaseFavoritesOperationType } from 'features/cases-management/types/TCaseApi'
import {
  ECaseSortBy,
  ESortDir,
  TCaseManagementFilterParams,
  TCaseManagementQueryParams,
} from 'features/cases-management/types/TCasePagination'
import { del, get, post } from 'shared/api'
import IListOfItemsCursor, { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import { ICaseDTO } from 'types/ICase'
import { IDoctor } from 'types/IUser'
import { TCasesManagementTabs, TTabCount } from 'types/TTab'

const PAGE_COUNT_OFFSET = 2

/** Тело для распределения случаев */
export type ICaseAssignPayload = { caseIds: number[]; userId: number }

const getNextPrevCasesCursor = async (
  workspaceId: number,
  tableType: ECaseTableType,
  queryParams: {
    caseId: number
    sortDir?: ESortDir
    sortBy?: ECaseSortBy
  },
) =>
  get<CaseCursors>({
    config: {
      params: {
        sortBy: ECaseSortBy.DIAGNOSTIC_PROCEDURE_TYPE,
        sortDir: ESortDir.ASC,
        ...queryParams,
      },
    },
    url: `/workspace/${workspaceId}/case/list/${tableType}/cursor`,
  })

const fetchCasesForTable = async (
  wsId: number,
  /** Доступно ли распределние */
  isCaseRouting: boolean,
  tableType: ECaseTableType,
  queryParams: TCaseManagementQueryParams,
  /** Размер страницы */
  size: number,
  filterParams?: TCaseManagementFilterParams,
  cursor?: string,
) => {
  const localQueryParams = { ...queryParams }
  let specialTableType: ECaseTableType = tableType
  /** На первой итерации не реализуем 3 уровень вкладок, поэтому получаем данные для отслеживаемых */
  if (localQueryParams?.tab === EMyCasesTabType.FAVORITES) {
    localQueryParams['favoritesList'] = EFavoriteList.WATCHING
  }
  /** В случае, если !caseRouting, фронт будет запрашивать для этой вкладки данные из вкладки “Все случаи - Открытые” */
  if (localQueryParams?.tab === EMyCasesTabType.ASSIGNED_TO_ME && !isCaseRouting) {
    localQueryParams['tab'] = EAllCasesTabType.OPENED
    specialTableType = ECaseTableType.WORKSPACE
  }

  return get<IListOfItemsCursorCases<ICaseDTO>>({
    config: {
      params: {
        ...localQueryParams,
        ...filterParams,
        cursor,
        size: size + PAGE_COUNT_OFFSET,
      },
    },
    url: `workspace/${wsId}/case/list/${specialTableType}`,
  })
}

const fetchCasesCounts = async (wsId: number, tableType: ECaseTableType) =>
  get<TTabCount<TCasesManagementTabs>>({
    url: `workspace/${wsId}/case/list/${tableType}/counts`,
  })

/** Распределяем кейсы для доктора */
const assignCasesToDoctor = (wsId: number, payload: ICaseAssignPayload) =>
  post<IOperationResult>({ data: payload, url: `/workspace/${wsId}/case/management/assign` })

/** Получение списка докторов в менеджменте */
const fetchDoctors = async (wsId: number /** Текущий воркспейс юзера */) =>
  get<IListOfItemsCursor<IDoctor>>({ url: `/workspace/${wsId}/case/list/routing/doctors` })

/** Добавление или удаление кейсов в/из отслеживаемое */
const addOrDeleteCasesToFavorites = (
  wsId: number,
  payload: TCaseFavorites,
  operationType: TCaseFavoritesOperationType,
) => {
  const url = `/workspace/${wsId}/case/favorite`
  return operationType === 'add'
    ? post<IOperationResult>({ data: payload, url })
    : del<IOperationResult>({ config: { data: payload }, url })
}

const caseManagementService = {
  addOrDeleteCasesToFavorites,
  assignCasesToDoctor,
  fetchCasesCounts,
  fetchCasesForTable,
  fetchDoctors,
  getNextPrevCasesCursor,
}

export default caseManagementService
