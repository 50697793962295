import { useDrag } from '@use-gesture/react'
import { useTypedSelector } from 'app/redux/lib/selector'
import { TGridInfoToolId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LeftPanelContext } from 'shared/lib/viewer/LeftPanelContext'
import { FlattenSimpleInterpolation } from 'styled-components'

import { StyledDraggable } from './StyledDraggable'

/** Делитель панели, используемый для вычисления средней точки панели. */
const PANEL_DIVIDER = 2
/** Верхни отступы окна. */
const PAGE_MARGIN_Y = 80
/** Отступы по ширине окна. */
const PAGE_MARGIN_X = 166
/** Отступы для примагничивания (до левой панели) */
const MAGNETIZE_OFFSET_X = 15
/** Отступы для примагничивания. (До других панелей) */
const MINIMAL_MAGNETIZE_OFFSET = 8

/** Props for Draggable component */
type Props = {
  /** id - id текущей панели */
  id?: TGridInfoToolId | undefined
  /** panelRef -  ссылка на элемент текущей панели */
  panelRef?: React.RefObject<HTMLDivElement>
  /** initPosition - начальные координаты положения текущей панели */
  initPosition?: number[]
  /** onPositionChange - коллбэк для изменения позиции текущей панели */
  onPositionChange?: (position: number[]) => void
  /** disableDrag - можно ли перетаскивать панель */
  disableDrag?: boolean
  /** parentBlock - родительский элемент текущей панели */
  parentBlock?: HTMLElement | null
  /** style - дополнительные стили для текущей панели */
  style?: FlattenSimpleInterpolation
  mapSizes?: number[]
  position: number[]
  setPosition: (sizes: number[]) => void
  styles?: React.CSSProperties
  /** Настройка для fit-content стиля, требуется для миникарты */
  isFitContent?: boolean
}

export const Draggable: React.FC<Props> = ({
  children,
  disableDrag,
  id,
  initPosition,
  isFitContent,
  mapSizes,
  onPositionChange,
  panelRef,
  parentBlock,
  position,
  setPosition,
  style,
}) => {
  const dispatch = useDispatch()
  //TODO: уточнить про distance
  /** distance - расстояние от панели до места "примагничивания" */
  const [distance, setDistance] = useState<number | undefined>(undefined)

  const [enabled, setEnabled] = useState<boolean>(true)
  const { value } = useContext(LeftPanelContext)
  const zIndexPanel = useTypedSelector((state) => state.viewerPage.zIndexPanel)
  const panelInFlex = useTypedSelector((state) => state.viewerPage.panelInFlex)
  const isMagnetize = useTypedSelector((state) => state.viewerPage.isMagnetize)
  const panelBoundingRect = panelRef?.current?.getBoundingClientRect()
  const panelRefTop = panelBoundingRect?.top || 0
  const panelRefLeft = panelBoundingRect?.left || 0
  const panelRefHeight = panelBoundingRect?.height || 0
  const panelRefWidth = panelBoundingRect?.width || 0
  const initOffset = useRef<number[]>([0, 0])
  const panelXCoord = position[0]
  const panelYCoord = position[1]

  const heightWindow = window.innerHeight - PAGE_MARGIN_Y
  const widthWindow = window.innerWidth - PAGE_MARGIN_X
  /** left - координата по оси OX */
  const left = position ? panelXCoord : 0
  /** isPanelMagnetize - "примагничена" ли текущая панель */
  const isPanelMagnetize = id ? isMagnetize[id] : false
  /** widthLeftPanel - ширина левой панели */
  const widthLeftPanel = value || 0
  /** canBeMagnetize - сигнал, что панель можно примагнитить, если отпустить */
  const canBeMagnetize =
    (distance && distance < MINIMAL_MAGNETIZE_OFFSET && !isPanelMagnetize) ||
    (left <= widthLeftPanel + MAGNETIZE_OFFSET_X &&
      left !== widthLeftPanel + MINIMAL_MAGNETIZE_OFFSET &&
      !isPanelMagnetize)

  const onResetOffset: () => [number, number] = () => [0, 0]
  const bindPosition = useDrag(
    ({ last, offset, target, values, ...props }) => {
      if (!panelRef?.current) return
      // TODO убрать динамические стили
      const blockHeader = panelRef.current.querySelector('.sc-kstqJO')
      const blockHeaderContainer = panelRef.current.querySelector('.sc-hBEYId')
      const headerToolPanel = panelRef.current.querySelector('#header_tool_panel')

      if (
        blockHeader === target ||
        blockHeaderContainer === target ||
        (headerToolPanel?.contains(target as Element) && !(target as Element)?.closest('button'))
      ) {
        const { height, left, top, width } = panelRef.current.getBoundingClientRect()
        const parentBlockRight = parentBlock?.getBoundingClientRect().right || 0
        const parentBlockLeft = parentBlock?.getBoundingClientRect().left || 0
        const parentBlockTop = parentBlock?.getBoundingClientRect().top || 0
        const parentBlockBottom = parentBlock?.getBoundingClientRect().bottom || 0
        const positionInBlock = [values[0] - left, values[1] - top]
        if (offset[0] === 0 && offset[1] === 0) {
          initOffset.current = [values[0] - positionInBlock[0], values[1] - positionInBlock[1]]
          return
        }
        const finalPosition = [offset[0] + initOffset.current[0], offset[1] + initOffset.current[1]]
        const x = () => {
          if (finalPosition[0] <= parentBlockLeft) {
            return parentBlockLeft + 5
          } else if (finalPosition[0] + width >= parentBlockRight) {
            return parentBlockRight - width - 5
          } else {
            return finalPosition[0]
          }
        }
        const y = () => {
          if (finalPosition[1] <= parentBlockTop) {
            return parentBlockTop + 5
          } else if (finalPosition[1] + height >= parentBlockBottom) {
            return parentBlockBottom - height - 5
          } else {
            return finalPosition[1]
          }
        }
        setPosition([x(), y()])
        if (last) {
          onPositionChange && onPositionChange([x(), y()])
        }
        if (id && isMagnetize[id]) {
          dispatch(viewerPageSlice.actions.setIsMagnetize({ id: id, value: false }))
        }
      }
    },
    { enabled, from: onResetOffset },
  )

  const getSmallestPositiveNumber = (arr: number[]): number | undefined => {
    let smallestPositiveNumber: number | undefined = undefined
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === 'number' && arr[i] > 0) {
        if (smallestPositiveNumber === undefined || arr[i] < smallestPositiveNumber) {
          smallestPositiveNumber = arr[i]
        }
      }
    }
    return smallestPositiveNumber
  }
  const distances = useMemo(() => {
    if (!panelInFlex || panelInFlex.length === 0 || !panelRef || !panelRef.current) {
      return []
    }
    return panelInFlex.map((flexItem) => {
      const distanceXRight = Math.round(flexItem.x - panelXCoord) * -1
      const distanceYBottom = Math.round(flexItem.y - panelYCoord) * -1

      const distances = []
      if (distanceYBottom < -20) {
        distances.push(distanceXRight)
      }
      if (distanceXRight < -20) {
        distances.push(distanceYBottom)
      }

      const filteredArr = distances.filter((number) => number >= 0)
      const sortedArr = filteredArr.sort((a, b) => a - b)
      return sortedArr[0]
    })
  }, [panelInFlex, position])

  const enableDragHandler = (e: any) => {
    setEnabled(!(e?.target instanceof HTMLCanvasElement || !!e.target.closest('.ant-collapse-header')))
  }

  const handleClickDown = (id: TGridInfoToolId) => {
    dispatch(viewerPageSlice.actions.setZIndexPanel(id))
  }

  const handleClick = useCallback(() => {
    if (!id) return
    if (panelXCoord <= Number(value) + MAGNETIZE_OFFSET_X) {
      dispatch(viewerPageSlice.actions.setIsMagnetize({ id: id, value: true }))
    }
    if (Number(distance) <= MINIMAL_MAGNETIZE_OFFSET) {
      dispatch(viewerPageSlice.actions.setIsMagnetize({ id: id, value: true }))
    }
  }, [position, panelRefTop])

  const updatePosition = async (newPosition: number[]) => {
    if (onPositionChange) {
      await onPositionChange(newPosition)
    }
    setPosition(newPosition)
  }

  useEffect(() => {
    const minDistance = getSmallestPositiveNumber(distances)
    setDistance(minDistance)
  }, [distances])

  useEffect(() => {
    setEnabled(!disableDrag)
  }, [disableDrag])

  useEffect(() => {
    if (id && isMagnetize[id]) {
      initOffset.current = [0, 0]
      setPosition([panelRefLeft, panelRefTop])
      onPositionChange && onPositionChange([panelRefLeft, panelRefTop])
    }
  }, [isMagnetize, panelRefTop])

  useEffect(() => {
    if (value && panelXCoord < value) {
      setPosition([value + 30, panelYCoord])
    }
    return
  }, [value])

  useEffect(() => {
    if (initPosition && onPositionChange && id) {
      if (initPosition[0] > widthWindow || initPosition[1] > heightWindow) {
        dispatch(viewerPageSlice.actions.setIsMagnetize({ id: id, value: true }))
        onPositionChange([0, 0])
      }
    }
    if (panelXCoord === 0 && id) {
      dispatch(viewerPageSlice.actions.setIsMagnetize({ id: id, value: true }))
    }
    if (id !== undefined) {
      dispatch(viewerPageSlice.actions.setZIndexPanel(id))
    }
  }, [])

  useEffect(() => {
    const middlePanel = panelRefWidth / PANEL_DIVIDER

    let widthScaleFactor = 0
    if (widthWindow < panelXCoord + middlePanel) {
      widthScaleFactor = widthWindow - panelXCoord - middlePanel
    }

    let heightScaleFactor = 0
    if (heightWindow < panelYCoord + panelRefHeight) {
      heightScaleFactor = heightWindow - panelYCoord - panelRefHeight
    }

    const adjustedWidth = panelXCoord + widthScaleFactor
    const adjustedHeight = panelYCoord + heightScaleFactor

    updatePosition([adjustedWidth, adjustedHeight])
  }, [widthWindow, heightWindow, panelRefWidth])

  return (
    <StyledDraggable
      id={id || undefined}
      top={position ? panelYCoord : 0}
      left={left}
      isMagnetize={isPanelMagnetize}
      canBeMagnetize={canBeMagnetize}
      zIndex={id ? zIndexPanel[id] : undefined}
      {...bindPosition()}
      onMouseDown={() => {
        if (id === undefined) return
        handleClickDown(id)
      }}
      onClick={handleClick}
      onMouseOver={enableDragHandler}
      ref={panelRef}
      extraStyle={style}
      mapSizes={mapSizes}
      isFitContent={isFitContent}
      isSlideInfo={id === 'IGNORE_IN_SCREENSHOT'}
    >
      {children}
    </StyledDraggable>
  )
}
