import { useTypedSelector } from 'app/redux/lib/selector'
import { useTranslation } from 'react-i18next'
import { formatAge } from 'shared/lib/date'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'
import { TextElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import IPatient from 'types/IPatient'

const SubInfoGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
`

const Info = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`

type InfoProps = {
  title: string
  value: string
}

export const InfoElement = ({ title, value }: InfoProps) => (
  <Info>
    <TextElement style={{ color: 'var(--color-text-3)' }}>{title}</TextElement>
    <TextElement>{value}</TextElement>
  </Info>
)

type PatientHeaderProps = {
  patient: IPatient
}

const PatientHeader = ({ patient }: PatientHeaderProps) => {
  const { i18n, t } = useTranslation()
  return (
    <div>
      <TitleElement>{patient.fullname}</TitleElement>
      <SubInfoGrid>
        <InfoElement title={t('Номер пациента')} value="20021961-2323" />
        <InfoElement
          title={t('Дата рождения')}
          value={formatAge(patient.birthDate, i18n.language !== I18N_LANGUAGES.RUSSIAN)}
        />
        <InfoElement title={t('Пол')} value={patient.sex === 'FEMALE' ? t('Женщина') : t('Мужчина')} />
      </SubInfoGrid>
    </div>
  )
}

const PatientHeaderContainer = () => {
  const selectedPatient = useTypedSelector((state) => state.dashboard.selectedPatient)
  return selectedPatient ? <PatientHeader patient={selectedPatient} /> : null
}

export default PatientHeaderContainer
