import { Divider } from 'antd'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { TFunction } from 'i18next'
import { StompClientContext, useSubscription } from 'processes/stomp'
import { WsResponseCasesList } from 'processes/stomp/types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'
import Highlighted from 'shared/ui/highlighted'
import { ButtonElement, InputElement } from 'shared/ui/kit'
import styled from 'styled-components'
import ICase from 'types/ICase'
import { v4 as uuidv4 } from 'uuid'

const Body = styled.div`
  margin-top: 12px;
  display: flex;
`

const StyledInputElement = styled(InputElement)`
  flex-grow: 1;
  margin-right: 8px;
`

const ContentWrapper = styled.div`
  height: 180px;
  min-width: 300px;
`

const FirstLineSearchOption = styled.div`
  display: flex;
`

const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-1);
`

const OptionsWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow-y: auto;
`

type Props = {
  searchValue: string
  setSearchValue: (searchValue: string) => void
  toggleIsAlertPanelOpened: () => void
  onAttachUploadFiles: (targetCase?: ICase) => Promise<void>
  isLoadingRequest: boolean
}

const uuid = uuidv4()

const LinkingSlidePanel = ({
  isLoadingRequest,
  onAttachUploadFiles,
  searchValue,
  setSearchValue,
  toggleIsAlertPanelOpened,
}: Props) => {
  const { publish } = useContext(StompClientContext)
  const workspaceId = useCurrentWorkspaceId()
  const [options, setOptions] = useState<ICase[]>([])
  const [selected, setSelected] = useState<ICase | undefined>()
  const lisIntegration = useLisMode()
  const { t } = useTranslation()
  useSubscription<WsResponseCasesList>(`/user/topic/autocomplete/cases/${uuid}`, (result) => {
    setOptions(result?.payload)
  })

  const onSearch = (query: string) => {
    setSearchValue(query)
    setSelected(undefined)
    if (query.length > 1) {
      publish(`/app/workspace/${workspaceId}/case/search/${uuid}`, {
        query,
        searchArchivedCases: true,
        searchOwnCases: true,
        searchSharedCases: false,
      })
    }
  }

  const onSelect = (option: ICase) => {
    onSearch(option?.name || '')
    setSelected(option)
  }

  const onSubmit = () => {
    if (!selected) {
      if (lisIntegration === 'none') {
        toggleIsAlertPanelOpened()
      }
      return
    }
    onAttachUploadFiles(selected)
  }

  useEffect(() => {
    if (searchValue.length < 2) {
      setOptions([])
    }
    return
  }, [searchValue.length])

  return (
    <>
      <Divider style={{ marginTop: 0 }} />
      <Body>
        <StyledInputElement
          autoFocus
          placeholder={t('Поиск по случаю, заказу или пациенту')}
          value={searchValue}
          onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
          onChange={(e) => onSearch(e?.target?.value)}
        />
        <ButtonElement
          type={'primary'}
          disabled={(lisIntegration !== 'none' ? !selected?.caseId : !searchValue) || isLoadingRequest}
          onClick={onSubmit}
        >
          {t('Готово')}
        </ButtonElement>
      </Body>
      <ContentWrapper>
        {searchValue.length > 1 ? (
          <OptionsWrapper> {options.map((it) => createOption(it, searchValue, onSelect, t))}</OptionsWrapper>
        ) : lisIntegration === 'none' ? (
          <TextWrapper>
            {t('Выберите уже существующий')} <br /> {t('случай или создайте новый')}
          </TextWrapper>
        ) : (
          <TextWrapper>{t('Выберите существующий случай')}</TextWrapper>
        )}
      </ContentWrapper>
    </>
  )
}

const StyledOption = styled.div`
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 12px;
  color: var(--color-text-3);
  &:hover {
    background-color: var(--color-bg-3);
    border-radius: 4px;
  }
`

const createOption = (option: ICase, searchValue: string, onClick: (option: ICase) => void, t: TFunction) => {
  //@ts-ignore
  const mapper = [...Array(1).keys()].map(() => ({
    name: option.name,
    orderNumber: option.orderNumber,
    patient: option.patient,
  }))

  return (
    <StyledOption key={option.caseId} onClick={() => onClick(option)}>
      {mapper.map(({ name, orderNumber, patient: { birthDate, fullname } = { birthDate: '', fullname: '' } }) => (
        <>
          <FirstLineSearchOption>
            <Highlighted key={name} text={name} search={searchValue} />
            {birthDate && (
              <>
                &nbsp;&nbsp;&nbsp;
                <Highlighted key={birthDate} text={formatDateForDisplay(birthDate)} search={searchValue} />
              </>
            )}
          </FirstLineSearchOption>
          {orderNumber && (
            <Highlighted key={orderNumber} text={`${t('Номер заказа')}: ${orderNumber}`} search={searchValue} />
          )}
          {fullname && <Highlighted key={fullname} text={fullname} search={searchValue} />}
        </>
      ))}
    </StyledOption>
  )
}

export default LinkingSlidePanel
