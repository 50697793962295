import React from 'react'
import { useTranslation } from 'react-i18next'
import { TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISlide, { SlideState } from 'types/ISlide'

import PreviewImageContainer, { MICRO_SLIDE_IMAGE_SIZE } from './PreviewImageContainer'

export const TitleBlock = styled.div<{ hasTitleBlock: any; index: number }>`
  margin-left: ${({ hasTitleBlock, index }) => (hasTitleBlock && index !== 0 ? '16px' : '0')};
  margin-top: ${({ hasTitleBlock }) => (hasTitleBlock ? '4px' : '44px')};
  margin-bottom: 6px;
  max-width: ${MICRO_SLIDE_IMAGE_SIZE}px;
`

const ImageGrid = styled.div<{ hasTitleBlock: boolean; index: number }>`
  margin-top: ${({ hasTitleBlock }) => (hasTitleBlock ? '0px' : '28px')};
  margin-bottom: 16px;
  margin-left: ${({ hasTitleBlock, index }) => (hasTitleBlock && index ? '16px' : '0')};
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const StyledContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin: 0 16px 0 0;
  height: 100%;
`

type Props = {
  /** Индентификатор случая. */
  caseId: number
  /** Список слайдов в блоке. */
  blocks?: ISlide[]
}

const PanelPreviewContainer = ({ blocks, caseId }: Props) => {
  const { t } = useTranslation()
  const newBlock = blocks?.filter((item) => item.state !== SlideState.PROCESSING)

  // Функция для определения, нужен ли блок заголовка
  const shouldRenderTitleBlock = (index: number, blockData: ISlide) => {
    if (index === firstFailedBlockIndex) {
      return createTitleBlock(index, t('Ошибка'))
    }

    if (index === firstMacroBlockIndex) {
      return createTitleBlock(index, t('Макро'))
    }

    if (index === firstNullBlockIndex) {
      return createTitleBlock(index, t('Нет блока'))
    }

    const key = `${blockData.block}-${blockData.container}`

    if (blockData.block && blockData.container && blockContainerIndices[key] === index) {
      return createTitleBlock(index, `${blockData.container} - ${blockData.block}`)
    }

    if (
      blockData.block &&
      (blockData.container === null || blockData.container === '') &&
      index === firstBlockNullContainerIndex
    ) {
      return createTitleBlock(index, blockData.block)
    }

    if (
      blockData.container &&
      (blockData.block === null || blockData.block === '') &&
      index === firstContainerNullBlockIndex
    ) {
      return createTitleBlock(index, blockData.container)
    }

    return null
  }

  // Индексы для оптимизации рендеринга
  const firstFailedBlockIndex = newBlock?.findIndex((block) => block.state === SlideState.FAILED)
  const firstMacroBlockIndex = newBlock?.findIndex((block) => block.groupType === 'MACRO')
  const firstNullBlockIndex = newBlock?.findIndex((block) => !block.block && !block.container)
  const blockContainerIndices: Record<string, number> = {}
  newBlock?.forEach((blockData, index) => {
    const { block, container } = blockData
    const key = `${block}-${container}`
    if (!blockContainerIndices[key] && block && container) {
      blockContainerIndices[key] = index
    }
  })
  const firstBlockNullContainerIndex = newBlock?.findIndex(
    (block) => block.block && (block.container === null || block.container === ''),
  )
  const firstContainerNullBlockIndex = newBlock?.findIndex(
    (block) => block.container && (block.block === null || block.block === ''),
  )

  return (
    <StyledContainer>
      {newBlock?.map((blockData, index) => (
        <div key={blockData.slideId}>
          {shouldRenderTitleBlock(index, blockData)}
          <ImageGrid index={index} hasTitleBlock={shouldRenderTitleBlock(index, blockData) !== null}>
            <PreviewImageContainer slide={blockData} caseId={caseId} />
          </ImageGrid>
        </div>
      ))}
    </StyledContainer>
  )
}

const createTitleBlock = (index: number, text: string, hasTitleBlock = true) => (
  <TitleBlock index={index} hasTitleBlock>
    <TitleElement level={3} ellipsis>
      {text}
    </TitleElement>
  </TitleBlock>
)

export default PanelPreviewContainer
