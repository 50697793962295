import { combineReducers } from '@reduxjs/toolkit'
import { createAttachmentsSlice } from 'features/attachments/model/viewerAttachmentsSlice'
import { createViewerSlice } from 'viewer/container/model/viewerSlice'
import { createViewerHelpSlice } from 'viewer/help/model/viewerHelpSlice'
import { createViewerToolsGridSlice } from 'viewer/tools/model/viewerToolsSlice'

const createViewerRootReducer = (id: string) =>
  combineReducers({
    viewer: createViewerSlice(id).reducer,
    viewerAttachments: createAttachmentsSlice(id).reducer,
    viewerHelp: createViewerHelpSlice(id).reducer,
    viewerToolsGridSlice: createViewerToolsGridSlice(id).reducer,
  })

export default createViewerRootReducer
