import artefacts from 'assets/thumbnails/artefact.png'
import glison from 'assets/thumbnails/glison.png'
import ki67 from 'assets/thumbnails/ki67.png'
import mitosis from 'assets/thumbnails/mitosis.png'
import pathVisionSegmentation from 'assets/thumbnails/path-vision-segmentation.png'
import i18next from 'shared/lib/i18n/i18n'
import { TGridTool } from 'viewer/tools/model/viewerToolsSlice'

const t = i18next.t

type Tool = {
  id: TGridTool
  title: string
  src: string
  description: string
  /** Текст для состояния недоступного инструмента */
  infoDisabledText?: string
  /** Текст для состояния просчета слоя для текущего инструмента */
  infoInProgressText?: string
  /** Текст для состояния кол-ва открытых вью > 1 */
  infoMaxViewCountText?: string
  /** Текст для состояния пустого результата */
  infoEmptyResultText?: string
  /** Текст для статуса ошибки */
  infoStatusFailedText?: string
  /** Текст под заголовком */
  subtitle?: string
}

const STATUS_FAILED = t('Не удалось рассчитать слайд')

//@ts-ignore
export const ML_TOOLS_MAP: Record<TGridTool, Tool> = {
  ARTEFACTS: {
    description: t(
      'Модель искусственного интеллекта детектирует артефакты пробоподготовки - пузырь, складки, маркер. На основании полученных данных рассчитывается площадь, которую занимают артефакты на ткани.',
    ),
    id: 'ARTEFACTS',
    infoDisabledText: t('Для данного слайда расчет недоступен'),
    infoEmptyResultText: t('На препарате не обнаружено артефактов'),
    infoInProgressText: t('Расчет артефактов еще не окончен'),
    infoMaxViewCountText: t('Инструмент недоступен в сплит-вью'),
    infoStatusFailedText: t(STATUS_FAILED),
    src: artefacts,
    title: t('Детекция артефактов пробоподготовки'),
  },
  KI67: {
    description: t(
      'Инструмент позволяет выделить область, в которой будут обнаружены все опухолевые клетки. Клетки будут разделены на позитивные и негативные. В результате будет подсчитано соотношение позитивных ко всем опухолевым клеткам.',
    ),
    id: 'KI67',
    infoStatusFailedText: t(STATUS_FAILED),
    src: ki67,
    title: t('Оценка экспрессии ядерных биомаркеров'),
  },
  MEDICALNEURONETS_CRC: {
    description: t(
      `Модуль Synapse Neuro [CRC] анализирует лимфоузлы на послеоперационном материале с целью сегментации метастазов колоректального рака. Результат работы модели отображен в виде маски.`,
    ),
    id: 'MEDICALNEURONETS_CRC',
    infoDisabledText: t('Для данного слайда расчет недоступен'),
    infoEmptyResultText: t('Метастазов не обнаружено'),
    infoInProgressText: t('Расчет модели еще не окончен'),
    infoMaxViewCountText: t('Инструмент недоступен в сплит-вью'),
    infoStatusFailedText: t(STATUS_FAILED),
    src: pathVisionSegmentation,
    title: t('Детекция метастазов колоректального рака в лимфоузлах'),
  },
  MITOSIS: {
    description: t(
      'Модель подсветит на слайде места с наибольшим скоплением митозов. Выберите на слайде зону интереса, в которой нужно обнаружить все митозы и модель задетектирует их. Результаты, предложенные моделью можно откорректировать вручную.',
    ),
    id: 'MITOSIS',
    infoDisabledText: t('Инструмент доступен для локализации "Молочная железа" и окраски "H&E"'),
    infoInProgressText: t('Расчет митозов для данного слайда еще не завершен'),
    infoMaxViewCountText: t('Инструмент недоступен в сплит-вью'),
    infoStatusFailedText: t(STATUS_FAILED),
    src: mitosis,
    title: t('Детекция митозов'),
  },
  PV_PROSTATE: {
    description: t(
      'Модель искусственного интеллекта PathVision сегментирует ткань предстательной железы. На основании полученных данных инструмент рассчитывает сумму баллов по Глисону в соответсвии с актуальной классификацией ВОЗ.',
    ),
    id: 'PV_PROSTATE',
    infoDisabledText: t('Доступно для биопсии предстательной железы и окраски "H&E"'),
    infoEmptyResultText: t('Опухолевой ткани не обнаружено'),
    infoInProgressText: t('Расчет сегментации опухолевой ткани  еще не окончен'),
    infoMaxViewCountText: t('Инструмент недоступен в сплит-вью'),
    infoStatusFailedText: t(STATUS_FAILED),
    src: pathVisionSegmentation,
    title: t('Сегментация ткани предстательной железы (ПЖ)'),
  },
  SEGMENTATION: {
    description: t(
      'Искусственный интеллект детектирует опухоль в ткани предстательной железы. В случае ацинарной аденокарциномы опухоль сегментируется на паттерны по Глисону. На основании полученных данных рассчитывается сумма баллов по Глисону в соответствии с актуальной классификацией ВОЗ.',
    ),
    id: 'SEGMENTATION',
    infoStatusFailedText: t(STATUS_FAILED),
    src: glison,
    title: t('Подсчет суммы баллов по Глисону'),
  },
}
