import { Form } from 'antd'
import { FormControl } from 'features/form'
import { notices } from 'features/notices'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HttpStatus } from 'shared/api/httpStatusCode'
import { IconElement, InputPasswordElement, SpaceElement, TextElement } from 'shared/ui/kit'

const { Item: FormItem, useForm } = Form

type Props = {
  onSubmit: (newPassword: string) => void
}

export const NewPasswordForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [form] = useForm()
  // TODO: move outside?
  const onFinish = async (payload: { confirmPassword: string; password: string }) => {
    if (!payload.confirmPassword || !payload.password) {
      return
    }
    if (payload.password.length < 8) {
      setErrorMessage(t('Минимум 8 символов'))
      return
    }
    if (payload.password.length > 35) {
      setErrorMessage(t('Максимум 35 символов'))
      return
    }
    if (payload.confirmPassword !== payload.password) {
      setErrorMessage(t('Пароли должны совпадать'))
      return
    }
    try {
      setLoading(true)
      await onSubmit(payload.password)
      // TODO: add error type
    } catch (e: any) {
      setLoading(false)
      if (e.response?.status === HttpStatus.FORBIDDEN) {
        setErrorMessage(t('Учетная запись не активирована'))
      } else if (e.response?.status === HttpStatus.UNAUTHORIZED) {
        setErrorMessage(t('Неправильный логин или пароль'))
      } else if (e.name === 'oldPasswordResetLink') {
        setErrorMessage(e.message)
      } else {
        notices.error({
          key: 'login-network-error',
          message: t('Сервер недоступен, попробуйте позже'),
        })
      }
    }
  }

  return (
    <Form onFinish={onFinish as any} form={form} onChange={() => setErrorMessage('')}>
      <FormItem name="password">
        <FormControl
          as={InputPasswordElement}
          prefix={<IconElement name="password" />}
          placeholder={t('Новый пароль')}
        />
      </FormItem>
      <SpaceElement size={8} />
      <FormItem name="confirmPassword">
        <FormControl
          as={InputPasswordElement}
          prefix={<IconElement name="password" />}
          placeholder={t('Новый пароль еще раз')}
        />
      </FormItem>
      {errorMessage && <TextElement type="danger">{errorMessage}</TextElement>}
      <SpaceElement size={32} />
      <FormItem>
        <FormControl type="primary" htmlType="submit" loading={isLoading}>
          {t('Поменять пароль')}
        </FormControl>
      </FormItem>
    </Form>
  )
}
