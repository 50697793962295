import { useCurrentWorkspaceId } from 'features/workspace/lib'
import DuplicateShowBtn from 'pages/sump/ui/tables/ui/button/DuplicateShowBtn'
import { getBarcodeFailText } from 'pages/uploaded-file/lib/renderHandlers'
import { viewerPageSlice } from 'pages/viewer'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouterLink, TextElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { EUploadedFileAttachmentState } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'
import { useViewerDispatch } from 'viewer/container'

const StyledRouterSpan = styled.span`
  .ant-typography:hover {
    opacity: 0.7;
  }
`

export const ColumnProblemText = ({
  conversionFailureCodeDzi,
  conversionFailureCodeTiff,
  duplicateOfSlide = {
    barcode: '',
    caseId: 0,
    slideId: 0,
  },
  duplicatesCount = 0,
  state = EUploadedFileAttachmentState.SUCCESS,
}: Partial<IUploadedFileDTO>) => {
  const { t } = useTranslation()
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { barcode, caseId, slideId } = duplicateOfSlide || {}
  const workspaceId = useCurrentWorkspaceId()

  const mrxsError = conversionFailureCodeDzi || conversionFailureCodeTiff ? t('Файл поврежден') : undefined

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
      <StyledWrap>
        <TextElement ellipsis tooltipPlacement={'topLeft'}>
          {mrxsError || getBarcodeFailText(state)}
        </TextElement>
        {!!slideId && !!caseId && (
          <>
            <StyledPoint> • </StyledPoint>
            <StyledRouterSpan data-testid="open-duplicate-original-btn">
              <RouterLink to={`/workspace/${workspaceId}/viewer/${caseId}?slideId=${slideId}`} target="_blank">
                {t('Открыть оригинал')}
              </RouterLink>
            </StyledRouterSpan>
          </>
        )}
      </StyledWrap>
      {duplicatesCount > 1 && !!barcode && (
        <StyledDuplicateShowBtn
          data-testid="duplicate-show-btn"
          type="link"
          onClick={() => viewerDispatch(viewerPageSlice.actions.setSumpDuplicateBarCodeShowNumber(barcode))}
        >
          {`${t('Все дубли')}: ${duplicatesCount}`}
        </StyledDuplicateShowBtn>
      )}
    </div>
  )
}

const StyledPoint = styled.span`
  color: var(--color-text-1);
  margin: 0 2px;
`

const StyledWrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-blue);
`
const StyledDuplicateShowBtn = styled(DuplicateShowBtn)`
  height: 12px;
  line-height: 12px;

  span {
    line-height: 12px;
  }
`
