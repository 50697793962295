import Feature from 'ol/Feature'
import { Geometry } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { useEffect } from 'react'
import { IMapOl } from 'types/IMapOl'
import { ISelectedBbox } from 'types/ISimilarRegion'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'
import { highlightStyle } from 'viewer/map/layers/olStyles'
import { removeLayersByInternalType } from 'viewer/map/lib/utils'

type SelectedBboxLayerProps = {
  selectedBbox: ISelectedBbox
  map: IMapOl
  features: Feature<Geometry>[]
  animated?: boolean
  slideId: number
}
const SelectedBboxLayer = ({ animated = false, features, map, selectedBbox, slideId }: SelectedBboxLayerProps) => {
  useEffect(() => {
    const { feature, layer } = createBboxLayer(features, selectedBbox, slideId)
    layer && map.addLayer(layer)
    const geometry = feature?.getGeometry()
    if (geometry && animated) {
      setTimeout(() => {
        map.getView().fit(geometry.getExtent(), {
          duration: 300,
          padding: [100, 100, 100, 100],
          size: map.getSize(),
        })
      }, 1000)
    }
    return () => {
      removeLayersByInternalType(map, LayerType.RESULT_BBOX)
    }
  }, [selectedBbox])
  return null
}

const createBboxLayer = (features: Feature<Geometry>[], selectedBbox: ISelectedBbox, slideId: number) => {
  const feature = features.find((feature) => `${slideId}-${feature?.getProperties().bbox_id}` === selectedBbox.bboxId)

  if (!feature) {
    return { feature: undefined, layer: undefined }
  }

  const source = new VectorSource({
    features: [feature],
  })

  const layer = new VectorLayer({
    source: source,
    style: highlightStyle,
    zIndex: 3,
  })

  layer.set(INTERNAL_TYPE_ID, LayerType.RESULT_BBOX)

  return { feature, layer }
}

export default SelectedBboxLayer
