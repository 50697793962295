import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerAttachmentsSlice } from 'features/attachments'
import { useCaseQuery } from 'features/cases/api/query'
import { useNotesQuery } from 'features/notes/api/query'
import Note from 'features/notes/ui/Note'
import { RightPanelMode } from 'pages/viewer/ui/common/ViewerRightPanel'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { ReverseInfiniteScroll } from 'shared/ui/infinite-scroll'
import { Center, SpinElement } from 'shared/ui/kit'
import INote from 'types/INotes'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'
import { useViewerAttachmentsSelector, useViewerDispatch } from 'viewer/container'

import { useCommentsQuery } from './api/query'
import commentsService from './api/service'
import CommentContainer from './CommentContainer'
import { commentsSlice } from './model/commentsSlice'
import CommentsList from './ui/CommentsList'
import GhostComment from './ui/GhostComment'

type Props = {
  caseId: number
  slideId?: number
  viewerId: TViewerId
  source: ISource
  segmentState: RightPanelMode
}

export const CommentsListContainer = ({ caseId, segmentState, slideId, source, viewerId }: Props) => {
  const queryClient = useQueryClient()
  const caseQuery = useCaseQuery({ caseId, source })
  const caseRecord = caseQuery.data
  const { permissionLevel } = caseRecord || {}
  const isDisabled = source === 'ATLAS' || permissionLevel === 'READ_ONLY'
  const [ghostComment, setGhostComment] = useState('')
  const [ghostCommentId, setGhostCommentId] = useState<number>()
  const dispatch = useDispatch()
  const viewerDispatch = useViewerDispatch(viewerId)
  const editingCommentId = useTypedSelector((state) => state.comments.editingCommentId)
  const currentUser = useTypedSelector((state) => state.user.user)
  const { newBbox } = useViewerAttachmentsSelector(viewerId)
  const attachments =
    newBbox && slideId
      ? [
          {
            metadata: {
              bbox: newBbox,
              slideId,
              type: 'VIEWPORT',
            },
          },
        ]
      : []
  // TODO: move mutations on query/mutations
  const { mutateAsync: onSendComment } = useMutation(async (body: string) => {
    setGhostComment(body)
    const res = await commentsService.postComment(caseId, {
      attachments,
      body,
    })
    setGhostCommentId(res.commentId)
  })

  const { fetchNextPage, hasNextPage, ids, isFetching, isLoading } = useCommentsQuery(caseId)
  const { data: notesData, isLoading: isLoadingNotes } = useNotesQuery(caseId, source === 'ATLAS')
  const setCommentMode = (mode: 'new' | null) => {
    if (!mode) {
      viewerDispatch(viewerAttachmentsSlice.actions.setAttachmentMode(false))
    }
    dispatch(commentsSlice.actions.setEditingComment(mode))
  }

  useEffect(() => {
    const sendLastComment = async () => {
      const commentInfo = await commentsService.setLastReadCommentId(caseId, {
        lastReadCommentId: ids[0],
        unreadCount: 0,
      })
      if (caseRecord) {
        queryClient.setQueryData([QUERY_TYPE.CASE, caseId], {
          ...caseRecord,
          commentInfo,
        })
      }
    }
    if (ids.length && caseRecord?.commentInfo?.lastReadCommentId !== ids[0]) {
      sendLastComment()
    }
  }, [caseId, ids.length])

  useEffect(
    () => () => {
      dispatch(commentsSlice.actions.setEditingComment(null))
    },
    [],
  )

  useEffect(() => {
    if (ids.find((id) => id === ghostCommentId)) {
      setGhostComment('')
      setGhostCommentId(undefined)
    }
  }, [ids])

  return (
    <>
      {segmentState === 'Комментарии' ? (
        <CommentsList
          onSendComment={onSendComment}
          key={caseId}
          isDisabled={isDisabled}
          isCommentMode={editingCommentId === 'new'}
          setCommentMode={setCommentMode}
          withAttachments={!!slideId}
        >
          {isLoading ? (
            <Center>
              <SpinElement />
            </Center>
          ) : (
            <ReverseInfiniteScroll fetchMore={fetchNextPage} isLastPage={!hasNextPage} itemsLength={ids.length}>
              <SpinElement spinning={isFetching}>
                {ids
                  .map((commentId) => (
                    <CommentContainer commentId={commentId} caseId={caseId} key={commentId} slideId={slideId} />
                  ))
                  .reverse()}
                {ghostComment && currentUser && <GhostComment body={ghostComment} user={currentUser} />}
              </SpinElement>
            </ReverseInfiniteScroll>
          )}
        </CommentsList>
      ) : isLoadingNotes ? (
        <Center>
          <SpinElement />
        </Center>
      ) : (
        <>
          <Divider />
          {notesData?.map((note: INote, i) => (
            <Note key={i} note={note} />
          ))}
        </>
      )}
    </>
  )
}
