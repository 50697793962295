import SearchForm from 'features/search/ui/SearchForm'
import React, { useState } from 'react'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

type Props = {
  searchMorphologyRef: React.RefObject<HTMLDivElement>
  initPosition: number[]
  onPositionChange: (position: number[]) => void
}

const SearchMorphologyTool = ({ initPosition, onPositionChange, searchMorphologyRef }: Props) => {
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  return (
    <ViewerInfoToolPanelContainer
      id="SEARCH_MORPHOLOGY"
      panelRef={searchMorphologyRef}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      type={'SEARCH_MORPHOLOGY'}
      position={position}
      setPosition={setPosition}
    >
      <SearchForm />
    </ViewerInfoToolPanelContainer>
  )
}

export default SearchMorphologyTool
