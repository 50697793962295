import { ViewerPageProvider } from 'pages/viewer/lib/common/ViewerPageProvider'
import { EventBusProvider } from 'shared/lib/EventBus'

type Props = {
  children: any
}

const CommonViewerRouteLayout = ({ children }: Props) => (
  <ViewerPageProvider>
    <EventBusProvider>{children}</EventBusProvider>
  </ViewerPageProvider>
)

export default CommonViewerRouteLayout
