import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VIEWER_ID_PLACEHOLDER } from 'shared/lib/viewer'
import { ISelectedBbox } from 'types/ISimilarRegion'

type State = {
  selectedBbox: ISelectedBbox['bbox'] | null
  slideId: number | null
  newBbox: ISelectedBbox['bbox'] | null
  isCreatingBbox: boolean
}

const initialState: State = {
  isCreatingBbox: false,
  newBbox: null,
  selectedBbox: null,
  slideId: null,
}

export const createAttachmentsSlice = (id: string) =>
  createSlice({
    extraReducers: {
      'viewerPageSlice/resetAllViewersState': (state) => initialState,
      [`${id}-viewerSlice/resetViewer`]: (state) => initialState,
    },
    initialState,
    name: id + '-viewerAttachmentsSlice',
    reducers: {
      selectBbox(state, { payload }: PayloadAction<ISelectedBbox['bbox'] | null>) {
        state.selectedBbox = payload
      },
      selectSlide(state, { payload }: PayloadAction<number | null>) {
        state.slideId = payload
      },
      setAttachmentMode(state, { payload }: PayloadAction<boolean>) {
        if (!payload) {
          state.newBbox = null
        }
        state.isCreatingBbox = payload
      },
      setInitialState(state) {
        return initialState
      },
      setNewBbox(state, { payload }: PayloadAction<ISelectedBbox['bbox'] | null>) {
        state.newBbox = payload
      },
    },
  })

export const viewerAttachmentsSlice = createAttachmentsSlice(VIEWER_ID_PLACEHOLDER)
