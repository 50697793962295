import trackUploadService from 'processes/tus/api/trackUploadService'
import * as tus from 'tus-js-client'
import IUploadInfo from 'types/ISlideUploadInfo'

type Callbacks = {
  onProgress?: (uuid: string, percent: number) => void
  onSuccess?: (uuid: string) => void
  onError?: (uuid: string, error?: Error) => void
  onStart?: (uuid: string) => void
}

export const uploadToServer = async (
  file: File,
  uploadInfo: IUploadInfo,
  uuid: string,
  groupId: string,
  callbacks: Callbacks = {},
) => {
  const { onError, onProgress, onStart, onSuccess } = callbacks
  let started = false
  let attemptNumber = 1
  let isAlreadyPartiallyUploaded = false
  const startedAt = new Date().toISOString()
  const upload = new tus.Upload(file, {
    chunkSize: 1024 * 1024 * 15,
    endpoint: uploadInfo?.url || '',
    metadata: { ...uploadInfo.meta, filename: file.name, filetype: file.type } || {},
    onBeforeRequest: () => {
      if (uuid && !started && onStart) {
        onStart(uuid)
        started = true
      }
    },
    onError: (error) => {
      onError && onError(uuid, error)
      trackUploadService.updateFileAttempt({
        data: {
          attemptNumber,
          error: error.message,
          fileName: file.name,
          fileSize: file.size,
          fileUuid: uuid,
          finishedAt: new Date().toISOString(),
          isAlreadyPartiallyUploaded,
          startedAt,
        },
        fileId: uuid,
        uploadId: groupId,
      })
      console.log('Ошибка при загрузке слайда', error)
    },
    onProgress: (bytesUploaded: any, bytesTotal: any) => {
      onProgress && onProgress(uuid, parseInt(((bytesUploaded / bytesTotal) * 100).toFixed(2), 10))
    },
    // @ts-ignore
    onShouldRetry: (err, retryAttempt, options) => {
      attemptNumber = retryAttempt + 1
      trackUploadService.updateFileAttempt({
        data: {
          attemptNumber,
          fileName: file.name,
          fileSize: file.size,
          fileUuid: uuid,
          finishedAt: new Date().toISOString(),
          isAlreadyPartiallyUploaded,
          startedAt,
        },
        fileId: uuid,
        uploadId: groupId,
      })
      return true
    },

    onSuccess: () => {
      onSuccess && onSuccess(uuid)
      trackUploadService.updateFileAttempt({
        data: {
          attemptNumber,
          fileName: file.name,
          fileSize: file.size,
          fileUuid: uuid,
          finishedAt: new Date().toISOString(),
          isAlreadyPartiallyUploaded,
          startedAt,
        },
        fileId: uuid,
        uploadId: groupId,
      })
      console.log('Файл загружен!!!')
    },

    removeFingerprintOnSuccess: true,
    retryDelays: [0, 2000, 6000, 10000],
  })

  const previousUploads = await upload.findPreviousUploads()
  if (previousUploads.length) {
    isAlreadyPartiallyUploaded = true
    const prev = previousUploads[previousUploads.length - 1] as any
    if (uploadInfo.meta.caseId === prev.metadata.caseId) {
      upload.resumeFromPreviousUpload(previousUploads[previousUploads.length - 1])
    }
  }
  upload.start()
}

export const clearExpiredUploads = async (userId?: number) => {
  const uploads = await tus.defaultOptions.urlStorage?.findAllUploads()
  uploads?.forEach((item: any) => {
    if (userId && item.metadata.uploaderUserId !== userId.toString()) {
      tus.defaultOptions.urlStorage?.removeUpload(item.urlStorageKey)
    }
  })
}

export const getFileMetaHash = (file: File) => `${file.name}-${file.size}-${file.lastModified}`
