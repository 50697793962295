import { SmartCaptcha } from '@yandex/smart-captcha'
import { Form } from 'antd'
import { FormControl } from 'features/form'
import { notices } from 'features/notices'
import { chatOpen } from 'features/support-chat'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HttpStatus } from 'shared/api/httpStatusCode'
import { declensionWord } from 'shared/lib/text'
import { DOT_WITH_SPACE } from 'shared/text-constants'
import { IconElement, InputElement, InputPasswordElement, SpaceElement, TextElement } from 'shared/ui/kit'
import { ModalComponent } from 'shared/ui/modal'

const { Item: FormItem, useForm } = Form

type Props = {
  isActiveDirectoryConfigured: boolean
  onSubmit: (payload: { login: string; password: string; captchaToken?: string }) => void
}

export const LoginForm = ({ isActiveDirectoryConfigured, onSubmit }: Props) => {
  const SITE_KEY = global.window.YANDEX_CAPTCHA_SITE_KEY === '' ? undefined : global.window.YANDEX_CAPTCHA_SITE_KEY
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | ReactElement<any, any>>('')
  const [form] = useForm()

  const [captchaOpen, setCaptchaOpen] = useState(false)
  const [captchaActiveMode, setCaptchaActiveMode] = useState(false)
  const [captchaToken, setToken] = useState<string>()

  const tryToLogin = async (payload: { login: string; password: string }, token?: string) => {
    try {
      setLoading(true)
      await onSubmit({
        ...payload,
        captchaToken: token || captchaToken,
        login: payload.login?.trim(),
      })
    } catch (e: any) {
      setToken(undefined)
      setLoading(false)
      if (e.response?.status === HttpStatus.FORBIDDEN) {
        setErrorMessage(t('Учетная запись не активирована'))
      } else if (e.response?.status === HttpStatus.UNAUTHORIZED) {
        setErrorMessage(t('Неправильный логин или пароль'))
      } else if (e.response?.status === HttpStatus.TEAPOT && SITE_KEY) {
        setCaptchaOpen(true)
        setCaptchaActiveMode(true)
        setErrorMessage(t('Введите капчу'))
      } else if (e.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
        setErrorMessage(
          <>
            <span>
              {t('Пользователь заблокирован')}
              {e.response?.data?.tryAgainInMinutes
                ? ` ${t('на')} ${e.response?.data?.tryAgainInMinutes} ${declensionWord(
                    e.response?.data?.tryAgainInMinutes,
                    [t('минуту'), t('минуты'), t('минут')],
                  )}`
                : ''}
              {DOT_WITH_SPACE}
              {t('Превышено количество попыток входа')}
              {DOT_WITH_SPACE}
              {t('Для ускорения разблокировки Вы можете обратиться в')}
            </span>
            <u onClick={chatOpen} style={{ cursor: 'pointer' }}>
              {t('техническую поддержку')}
              {DOT_WITH_SPACE}
            </u>
          </>,
        )
      } else {
        notices.error({
          key: 'login-network-error',
          message: t('Сервер недоступен, попробуйте позже'),
        })
      }
    }
  }
  const onFinish = async (payload: { login: string; password: string }) => {
    if (!payload.login) {
      setErrorMessage(t('Введите email'))
      return
    }
    if (!payload.password) {
      setErrorMessage(t('Введите пароль!'))
      return
    }
    if (!captchaToken && captchaActiveMode) {
      setCaptchaOpen(true)
      return
    }
    await tryToLogin(payload)
  }
  const onCaptchaSuccess = async (token: string) => {
    setToken(token)
    setCaptchaOpen(false)
    setErrorMessage('')
    const login = form.getFieldValue('login')
    const password = form.getFieldValue('password')
    await tryToLogin({ login, password }, token)
  }

  const { t } = useTranslation()
  return (
    <>
      <Form onFinish={onFinish as any} form={form} onChange={() => setErrorMessage('')}>
        <FormItem name="login">
          <FormControl
            as={InputElement}
            prefix={<IconElement name="mail" />}
            data-testid={'login'}
            placeholder={isActiveDirectoryConfigured ? t('Логин или Электронная почта') : t('Логин')}
            autoComplete="on"
          />
        </FormItem>
        <SpaceElement size={8} />
        <FormItem name="password">
          <FormControl
            as={InputPasswordElement}
            prefix={<IconElement name="password" />}
            placeholder={t('Пароль')}
            data-testid={'password'}
          />
        </FormItem>
        {errorMessage && (
          <TextElement data-testid="form-error" type="danger">
            {errorMessage}
          </TextElement>
        )}
        <SpaceElement size={32} />
        <FormItem>
          <FormControl data-testid={'submit'} type="primary" htmlType="submit" loading={isLoading}>
            {t('Войти')}
          </FormControl>
        </FormItem>
      </Form>
      <ModalComponent visible={captchaOpen} footer={null} closable={false} centered={true} destroyOnClose>
        {SITE_KEY && <SmartCaptcha sitekey={SITE_KEY} onSuccess={onCaptchaSuccess} />}
      </ModalComponent>
    </>
  )
}
