export type ServerValidationFieldCode = 'NotNull' | 'NotBlank'

export type IServerError = {
  code: ServerValidationFieldCode
  field: string
  message: string
}

export const parseErrorsFromServerForm = (errors?: IServerError[]) =>
  errors?.map((error) => ({
    errors: [error.message],
    name: error.field.split('.').slice(0, 2),
  })) || []
