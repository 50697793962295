import { Space } from 'antd'
import { push } from 'connected-react-router'
import { FormButton, FormButtonElement, FormTopTitle, FormWrapper } from 'features/form'
import { MobileLandscape } from 'features/mobile-control'
import { chatOpen } from 'features/support-chat'
import { userService } from 'processes/user'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { PageHeader } from 'shared/ui/header'
import { SpaceElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import { ResetForm } from './ui'

const StyledLogin = styled(MobileLandscape)`
  background-color: var(--color-bg-1);
  height: 100%;
  width: 100%;
`

const MessageContainer = styled.div`
  margin-top: 15%;
`
const StyledTitle = styled(TitleElement)`
  &.ant-typography {
    color: var(--color-text-3);
  }
`
const StyledLink = styled.a`
  color: var(--color-text-3) !important;
  text-decoration: underline !important;
`

export const ResetRoute = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isMessage, setMessage] = useState(false)
  const { PASSWORD_RECOVERY_ENABLED, REGISTRATION_ENABLED } = global.window

  const onReset = async (email: string) => {
    try {
      const userData = await userService.requestReset(email)
      setMessage(true)
    } catch (e) {
      throw e
    }
  }
  const goToRegistration = useCallback(() => {
    dispatch(push('/registration'))
  }, [])
  const goToLogin = useCallback(() => {
    dispatch(push('/login'))
  }, [])

  useEffect(() => {
    if (!PASSWORD_RECOVERY_ENABLED) dispatch(push('/login'))
  }, [])

  return (
    <StyledLogin>
      <PageHeader>
        {REGISTRATION_ENABLED && (
          <Space size={16}>
            <FormTopTitle level={4}>{t('Нет аккаунта?')}</FormTopTitle>
            <FormButton onClick={goToRegistration}>{t('Зарегистрироваться')}</FormButton>
          </Space>
        )}
      </PageHeader>
      <SpaceElement size={98} />
      <FormWrapper>
        <TitleElement level={1}>
          {isMessage ? t('Инструкции отправлены на почту') : t('Восстановление пароля')}
        </TitleElement>
        <SpaceElement size={40} />
        {!isMessage && <ResetForm onSubmit={onReset} />}
        <div>
          <FormButtonElement type="link" onClick={goToLogin}>
            {t('Вернуться к авторизации')}
          </FormButtonElement>
        </div>
        <MessageContainer>
          <StyledTitle level={5}>
            {t('Если вы не помните, на какой email был зарегистрирован')} <br /> {t('Ваш аккаунт — обратитесь в')}
            <StyledLink onClick={chatOpen}> {t('службу поддержки')}</StyledLink>
          </StyledTitle>
        </MessageContainer>
      </FormWrapper>
    </StyledLogin>
  )
}
