import { Menu } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useWorkspacesQuery } from 'features/workspace'
import { workspaceReLogin } from 'processes/user/model/userSlice'
import { useDispatch } from 'react-redux'
import { IconElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import { MenuSubItemWrapper, PlaceKeeper, StyledSubMenu, Text } from './UserOptions'

const WorkspaceLabel = styled.div`
  display: flex;
  align-items: center;
  width: 166px;
  height: 22px;
`

const UserWorkspaces = () => {
  const { data } = useWorkspacesQuery()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const dispatch = useDispatch()

  const onSelect = (workspaceId: string) => {
    const newCurrentWorkspace = data?.find((item) => +item.workspaceId === +workspaceId)
    const defaultWorkspace = data?.find((item) => item.personal)
    if (!newCurrentWorkspace) return defaultWorkspace && dispatch(workspaceReLogin(defaultWorkspace))
    dispatch(workspaceReLogin(newCurrentWorkspace))
  }

  if (data?.length === 1) return null

  return (
    <StyledSubMenu
      key="workspacesItem"
      title={
        <WorkspaceLabel>
          <Text style={{ overflow: 'hidden', paddingLeft: '17px', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {currentWorkspace?.name}
          </Text>
        </WorkspaceLabel>
      }
    >
      {data?.map(({ name, workspaceId }) => (
        <Menu.Item key={'sub_ws_' + workspaceId} style={{ margin: 0, padding: 0 }}>
          <TooltipElement placement={'left'} title={name} mouseEnterDelay={1}>
            <MenuSubItemWrapper onClick={() => onSelect(workspaceId)}>
              <Text style={{ maxWidth: '252px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {name}
              </Text>
              {currentWorkspace?.workspaceId === workspaceId ? (
                <IconElement name="checkSmall2" size="md" />
              ) : (
                <PlaceKeeper />
              )}
            </MenuSubItemWrapper>
          </TooltipElement>
        </Menu.Item>
      ))}
    </StyledSubMenu>
  )
}

export default UserWorkspaces
