import { useEffect, useState } from 'react'
import { FilterPeriod } from 'types/IAnnotations'

import { DatePickerElement } from './DatePickerElement'

type Props = {
  // значение периода
  value?: FilterPeriod
  // Обработчик смены периода
  onChange: (datePeriod: FilterPeriod) => void
}

export const DatePeriod = ({ onChange, value }: Props) => {
  const [fromDate, setFromDate] = useState<Date | null>(value?.fromDate || null)
  const [toDate, setToDate] = useState<Date | null>(value?.toDate || null)

  useEffect(() => {
    setFromDate(value?.fromDate || null)
    setToDate(value?.toDate || null)
  }, [value])

  const onFromDateChange = (date: Date | null) => {
    date?.setHours(0, 0, 0)
    setFromDate(date)
    onChange({ fromDate: date, toDate })
  }

  const onToDateChange = (date: Date | null) => {
    date?.setHours(23, 59, 59)
    setToDate(date)
    onChange({ fromDate, toDate: date })
  }

  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <DatePickerElement style={{ flex: 1 }} value={fromDate} onChange={onFromDateChange} allowClear />
      <div style={{ margin: '0 4px' }}>{' - '}</div>
      <DatePickerElement style={{ flex: 1 }} value={toDate} onChange={onToDateChange} allowClear />
    </div>
  )
}
