import { useTypedSelector } from 'app/redux/lib/selector'
import dashboardSlice from 'features/dashboard/dashboardSlice'
import DashboardNavigationType from 'features/dashboard/navigation/DashboardNavigationType'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'
import IPatient from 'types/IPatient'

import PatientList from './PatientList'
import useGetPatientsMap from './useGetPatientsMap'

const PatientListContainer = () => {
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const filter = queryParams.get('filter') as DashboardNavigationType
  const selectedPatient = useTypedSelector((state) => state.dashboard.selectedPatient)
  const patientsMap = useGetPatientsMap()

  const onPatientClick = (patient: IPatient) => {
    dispatch(dashboardSlice.actions.selectPatient(patient))
  }
  return (
    <PatientList
      patients={Object.values(patientsMap)}
      onClick={onPatientClick}
      patient={selectedPatient}
      filter={filter}
    />
  )
}

export default PatientListContainer
