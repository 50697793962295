import { createGlobalStyle } from 'styled-components/macro'

export const GlobalOlStyles = createGlobalStyle`
  .custom-ol-draw-sm-popup {
    button {
      display: none;
    }
    .ol-popup-content {
      background: var(--color-purple);
      border-radius: 5px;
      padding: 4px 8px;
    }
  }
  .custom-ol-draw-tooltip {
    button {
      display: none;
    }
    .ol-popup-content {
      background: var(--color-purple);
      border-radius: 5px;
      padding: 8px;
    }
  }
  .custom-ol-draw-tooltip-two{
    display: block;
    .ol-popup-content {
      margin: 30px;
    }
  }
`
