import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext } from 'react'
import { MapContext } from 'shared/lib/map'
import { useViewerMainSelector } from 'viewer/container'

import DrawInteraction from './DrawInteraction'
import { Ki67Context } from './Ki67Context'
import PointsLayer from './PointsLayer'
import VectorKi67Layer from './VectorKi67Layer'

type Props = {
  slideId: number
  mppX: number
}

const Ki67Layer = ({ mppX, slideId }: Props) => {
  const { viewerId } = useContext(MapContext)
  const map = useViewerIdMap(viewerId)
  const { data } = useContext(Ki67Context)
  const { isKi67DrawActive } = useViewerMainSelector(viewerId)
  const circles = Object.keys(data)
    .filter((key) => !data[key].isHidden)
    .map((key) => {
      const feature = data[key].circle
      feature.set('ki67Id', key)
      return feature
    })
  return (
    <>
      {map && isKi67DrawActive && <DrawInteraction map={map} slideId={slideId} viewerId={viewerId} mppX={mppX} />}
      {map && circles.length > 0 && <PointsLayer map={map} />}
      {map && circles.length > 0 && <VectorKi67Layer map={map} features={circles} viewerId={viewerId} />}
    </>
  )
}

export default Ki67Layer
