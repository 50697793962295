import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseReferencesQuery } from 'features/cases/api/query'
import { viewerPageSlice } from 'pages/viewer'
import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CONFIG_CASE_PANELS_KEY } from 'shared/lib/common/storageKeys'
import { getWorkspaceIdUrl } from 'shared/lib/workspaces'
import { IconElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { SlideState } from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'
import { getSlideSummary, isAllSlides } from 'viewer/map/layers/slide/helpers'

import { BlockContainer } from './BlockContatiner'
import { ACTION_SHOW_LABELS, ACTION_SHOW_NAME_SLIDE, ACTION_SHOW_PREVIEW } from './common/utils'
import { useSlideGrouped } from './hooks'
import { slideBlocksSlice } from './model/BlocksSlice'
import TusFileLoaderContainer from './TusFileLoaderContainer'
import AdaptiveThumbnail from './ui/AdaptiveThumbnail'

type Props = {
  caseId: number
}

const Wrapper = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TitleCount = styled(TitleElement)`
  font-size: 12px !important;
  line-height: 16px !important;
`
const ContainerCount = styled.div`
  display: flex;
  padding: 4px 0;
`
const StyledIcon = styled(IconElement)<{ isAllSlides?: boolean }>`
  margin-right: 4px;
  color: ${({ isAllSlides }) => (isAllSlides ? 'var(--Light-Text-text-3, #595b5d)' : '#DC524C')};
`

/** возвращает значение, на основе которого будет сгенерирована заголовок группы слайдов  */
const getKeyBlock = (isMicro: boolean, slideGroup: ISlideGrouped) => {
  if (!isMicro) return 'macroSlidesIds'

  if (slideGroup.container && slideGroup.block) {
    return `${slideGroup.container}-${slideGroup.block}`
  }

  if (slideGroup.container) {
    return `${slideGroup.container}`
  }

  if (slideGroup.block) {
    return `${slideGroup.block}`
  }

  return ''
}

const AdaptiveThumbnailListContainer = memo(({ caseId }: Props) => {
  const dispatch = useDispatch()
  const workspaceId =
    useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId ||
    getWorkspaceIdUrl(window.location.href)
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId: caseId,
    workspaceId: Number(workspaceId),
  })
  const { filesList, slideGrouped, slides } = useSlideGrouped(caseId)

  useEffect(() => {
    const configString = localStorage.getItem(CONFIG_CASE_PANELS_KEY)
    if (configString) {
      const configParse = JSON.parse(configString)
      const configCasePanels = {
        [ACTION_SHOW_LABELS]: configParse[ACTION_SHOW_LABELS],
        [ACTION_SHOW_NAME_SLIDE]: configParse[ACTION_SHOW_NAME_SLIDE],
        [ACTION_SHOW_PREVIEW]: configParse[ACTION_SHOW_PREVIEW],
      }
      dispatch(viewerPageSlice.actions.setConfigPanel(configCasePanels))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(slideBlocksSlice.actions.setInitialState())
    },
    [],
  )

  const slideSummary = getSlideSummary(slides, caseReferencesSlides)
  const areAllSlidesEqual = isAllSlides(slides, caseReferencesSlides)

  return (
    <div>
      <ContainerCount style={{ display: 'flex', padding: '4px 0' }}>
        <StyledIcon isAllSlides={areAllSlidesEqual} size={'md'} name={'imagesSmall'} />
        {!!slideSummary && <TitleCount>{slideSummary}</TitleCount>}
      </ContainerCount>
      <Divider />

      {slideGrouped?.map((item, index) => {
        /** slideGrouped - Массив объектов слайдов, сгруппированных по типу. */
        const isMicro = item.type === 'MICRO'
        const key = getKeyBlock(isMicro, item)
        const blocks = item.slides
        /** failedBlock - Флаг, указывающий на наличие слайда с type === null и state === "FAILED" */
        const failedBlock = item.type === null && item.slides.some((slide) => slide.state === SlideState.FAILED)
        /** emptyBlocks - Флаг, указывающий, что контейнер и блок не определены для типа MICRO. */
        const emptyBlocks = !!(isMicro && !item.container && !item.block)
        /** macroSlidesIds – Флаг контейнера слайдов в зависимости от их типа (MICRO или MACRO). */
        const macroSlidesIds = !isMicro
        const INDEX_MACRO = 10000

        return (
          <BlockContainer
            key={index}
            i={isMicro ? index : macroSlidesIds ? INDEX_MACRO : 9999}
            blocks={blocks}
            caseId={caseId}
            typedKey={key}
            emptyBlocks={emptyBlocks}
            failedBlock={failedBlock}
            macroSlidesIds={macroSlidesIds}
          />
        )
      })}

      {filesList.length > 0 && (
        <Wrapper>
          {filesList.map((fileData) => (
            <AdaptiveThumbnail activeCursor="A" key={fileData}>
              <TusFileLoaderContainer tusFileId={fileData} key={fileData} />
            </AdaptiveThumbnail>
          ))}
        </Wrapper>
      )}
    </div>
  )
})

export default AdaptiveThumbnailListContainer
