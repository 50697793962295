import { Divider } from 'antd'
import caseService, { IFile } from 'features/cases/api/service'
import React, { useState } from 'react'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { FullScreenModalElement, IconElement, SpinElement, TextElement } from 'shared/ui/kit'
import { PdfViewer } from 'shared/ui/pdf'
import styled from 'styled-components/macro'

type Props = {
  files?: IFile[]
  caseId: number
}

const PDF_MIME_TYPE = 'application/pdf'

const FileItem = styled.div`
  display: flex;
  gap: 4px;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  padding: 5px 8px 5px 4px;
  white-space: nowrap;
  cursor: pointer;
  background: var(--color-bg-3);

  & > svg {
    color: white;
    flex-shrink: 0;
  }
`

const IconWrapper = styled.div`
  color: var(--color-text-3);
  display: grid;
  place-items: center;

  &:hover {
    color: var(--color-text-1);
    cursor: pointer;
  }
`

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

const StyledTextElement = styled(TextElement)`
  font-weight: 500;
`

const StyledDivider = styled(Divider)`
  margin: 8px 0 8px;
`
const FilesInfo = ({ caseId, files }: Props) => {
  const [fileToPreview, setFileToPreview] = useState<{ url: string; type: string }>()
  const [isLoading, setLoading] = useState<string | number>()

  const downloadFileHandler = async (fileToLoad: IFile) => {
    setLoading(fileToLoad.uid)
    try {
      const res = await caseService.fetchDoc(Number(fileToLoad.uid), caseId)
      const attachUrl = URL.createObjectURL(new Blob([res], { type: fileToLoad.type }))
      setFileToPreview({ type: fileToLoad.type, url: attachUrl })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(undefined)
    }
  }

  return (
    <>
      <StyledDescriptions colon={false}>
        <DescriptionsItem span={3}>
          {files?.map((item) =>
            isLoading === item.uid ? (
              <SpinElement key={item.uid}>
                <IconWrapper>
                  <IconElement name="inbox" />
                </IconWrapper>
              </SpinElement>
            ) : (
              <FileItem onClick={() => downloadFileHandler(item)} key={item.uid}>
                {item.type === PDF_MIME_TYPE ? (
                  <IconElement name="pdf" />
                ) : (
                  <IconElement fill="var(--color-text-3)" name="jpeg" />
                )}
                <StyledTextElement ellipsis>{item.name}</StyledTextElement>
              </FileItem>
            ),
          )}
        </DescriptionsItem>
      </StyledDescriptions>
      {!!files?.length && <StyledDivider />}
      <FullScreenModalElement visible={!!fileToPreview} onCancel={() => setFileToPreview(undefined)} footer={null}>
        {fileToPreview?.type === PDF_MIME_TYPE ? (
          <div>
            <PdfViewer file={fileToPreview.url} />
          </div>
        ) : (
          <div style={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
            <img src={fileToPreview?.url} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </div>
        )}
      </FullScreenModalElement>
    </>
  )
}

export default FilesInfo
