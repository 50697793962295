import { CaseReference } from 'features/cases/api/service'
import i18next from 'shared/lib/i18n/i18n'
import ISlide from 'types/ISlide'

const t = i18next.t

export const isAllSlides = (slides?: ISlide[], caseReferencesSlides?: CaseReference[]) => {
  if (!slides || !caseReferencesSlides) {
    return true
  }

  const numSlidesInCase = slides.filter((slide) => slide.groupType === 'MICRO').length

  return numSlidesInCase >= caseReferencesSlides.length
}

export const getSlideSummary = (slides?: ISlide[], caseReferencesSlides?: CaseReference[]) => {
  const sumSlideInCase = slides?.filter((slide) => slide.groupType === 'MICRO').length
  const sumReferenceSlides = caseReferencesSlides?.length

  return sumReferenceSlides ? `${sumSlideInCase} ${t('из')} ${sumReferenceSlides}` : sumSlideInCase
}
