import { useTypedSelector } from 'app/redux/lib/selector'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect, useMemo } from 'react'
import { MapContext } from 'shared/lib/map'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import ISlide from 'types/ISlide'
import { useViewerMainSelector } from 'viewer/container'

import InteractionLayer from './InteractionLayer'
import { removeViewportCursor } from './lib/helpers/helpers'
import DrawInteraction from './lib/interactions/DrawInteraction'
import ZoomInteraction from './lib/interactions/ZoomInteraction'
import ScreenRecordLayer from './ScreenRecordLayer'
import { SCREEN_RECORD_ZOOM_LVL, useObjectsCountingContext } from './ui/ObjectsCountingContext'
import VectorHeatMapLayer from './VectorHeatMapLayer'
import VectorPointsLayer from './VectorPointsLayer'
import VectorSpotsLayer from './VectorSpotsLayer'
import VectorViewedTracksLayer from './VectorViewedTracksLayer'
import ViewedTrackingLayer from './ViewedTrackingLayer'

type Props = {
  mppX: number
  slide?: ISlide
}

const ObjectsCountingLayer = ({ mppX, slide }: Props) => {
  const { viewerId } = useContext(MapContext)
  const map = useViewerIdMap(viewerId)
  const { data, getAllMitosisInSpots, handleSetCursorDefault, lastCursorPositionPixel, mitosis, updateCursor } =
    useObjectsCountingContext()
  const { isObjectsDrawActive, objectsCountingMethod } = useViewerMainSelector(viewerId)
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType)

  const spots = Object.values(data).map((it) => it?.spot)

  const viewedTracks = Object.keys(data)
    .filter((key) => !!data[key].viewedTrack && data[key].spot.get('trackPercent') < 80)
    .map((key) => data[key].viewedTrack)

  const filteredMitosis = mitosis.filter((it) => !it.get('isHidden'))

  const viewedMitosis = getAllMitosisInSpots()

  const resolution = map?.getView()?.getResolution()
  const zoomLvl = getResolutionByZoomLevel(resolution || 0, slide)

  const isActiveDrawViewportSpot = useMemo(
    () =>
      isObjectsDrawActive &&
      objectsCountingMethod === 'screenRecord' &&
      Number.isFinite(zoomLvl) &&
      zoomLvl >= SCREEN_RECORD_ZOOM_LVL,
    [zoomLvl, objectsCountingMethod],
  )

  const isActiveDrawCircleSpot = useMemo(
    () => isObjectsDrawActive && (objectsCountingMethod === 'one_mm' || objectsCountingMethod === 'two_mm'),
    [objectsCountingMethod],
  )

  const isActiveTracking = useMemo(
    () =>
      countingObjectType === 'MITOSIS' &&
      !isObjectsDrawActive &&
      Number.isFinite(zoomLvl) &&
      zoomLvl >= SCREEN_RECORD_ZOOM_LVL,
    [zoomLvl, isObjectsDrawActive, countingObjectType],
  )

  const isActiveInteraction = useMemo(
    () =>
      isActiveDrawViewportSpot ||
      (!isActiveDrawCircleSpot && Number.isFinite(zoomLvl) && zoomLvl >= SCREEN_RECORD_ZOOM_LVL),
    [zoomLvl, isActiveDrawViewportSpot, isActiveDrawCircleSpot],
  )
  const areVisibleSpots = useMemo(() => map && spots.length > 0, [spots])

  const areVisibleTracks = useMemo(
    () =>
      countingObjectType === 'MITOSIS' &&
      map &&
      viewedTracks.length > 0 &&
      Number.isFinite(zoomLvl) &&
      zoomLvl < SCREEN_RECORD_ZOOM_LVL,
    [viewedTracks, zoomLvl, countingObjectType],
  )

  const isVisibleHeatMap = useMemo(() => countingObjectType === 'MITOSIS', [countingObjectType])

  const isVisiblePoints = useMemo(() => countingObjectType === 'OBJECTS' || zoomLvl > 15, [countingObjectType, zoomLvl])

  useEffect(() => {
    /** Сбрасываем курсор вьюпорта и обновляем курсор */
    if (map.getViewport().style.cursor === 'auto') {
      removeViewportCursor(map)
      updateCursor(lastCursorPositionPixel)
    }

    return () => {
      /** При выходе из подсчета обновляем курсор */
      map.getViewport().style.cursor = ''
      handleSetCursorDefault()
    }
  }, [])

  return (
    <>
      {isActiveDrawCircleSpot && <DrawInteraction map={map} viewerId={viewerId} mppX={mppX} />}
      {isActiveDrawViewportSpot && <ScreenRecordLayer map={map} slide={slide} viewerId={viewerId} />}
      {isActiveTracking && <ViewedTrackingLayer map={map} slide={slide} />}
      {isActiveInteraction && <InteractionLayer map={map} viewerId={viewerId} features={viewedMitosis} />}
      <ZoomInteraction />

      {areVisibleSpots && <VectorSpotsLayer map={map} features={spots} viewerId={viewerId} />}
      {areVisibleTracks && <VectorViewedTracksLayer map={map} features={viewedTracks} viewerId={viewerId} />}
      {isVisiblePoints && <VectorPointsLayer map={map} features={filteredMitosis} slide={slide} />}
      {isVisibleHeatMap && <VectorHeatMapLayer map={map} features={filteredMitosis} slide={slide} />}
    </>
  )
}

export default ObjectsCountingLayer
