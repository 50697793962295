import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IPatient from 'types/IPatient'

type State = {
  selectedPatient?: IPatient
  selectedCase?: number
}

const initialState: State = {}

const dashboardSlice = createSlice({
  initialState,
  name: 'dashboardSlice',
  reducers: {
    selectCase(state, { payload }: PayloadAction<number>) {
      state.selectedCase = payload
    },
    selectPatient(state, { payload }: PayloadAction<IPatient>) {
      state.selectedPatient = payload
    },
  },
})

export default dashboardSlice
