import { Divider } from 'antd'
import { useContext } from 'react'
import { MapContext } from 'shared/lib/map'
import { SpinElement } from 'shared/ui/kit'
import ToolOpacityController from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components'
import { useMedicalNeuronetsContext } from 'viewer/map/layers/medicalNeuronets/MedicalNeuronetsContext'
import { getCenterOfExtent } from 'viewer/map/lib/utils'

import MedicalNeuronetsElement from './MedicalNeuronetsElement'

const Wrapper = styled.div`
  height: calc(100% - 33px);
`
const ScrollWrapper = styled.div`
  max-height: calc(100% - 33px);
  overflow: hidden auto;
`
const Loader = styled.div`
  width: 100%;
  height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
`

/** Продолжительность анимации в милисекундах */
const ANIMATION_DURATION = 250

const MedicalNeuronetsContainer: React.FC = () => {
  const { map } = useContext(MapContext)
  const { features, infoMessage, isLoading, opacity, setHoveredClass, setOpacity } = useMedicalNeuronetsContext()

  const onSelectElementHandle = (selectedClass: string) => {
    const feature = features.find((f) => f.get('class_name') === selectedClass)
    const geometry = feature?.getGeometry()
    if (geometry) {
      map.getView().fit(geometry.getExtent(), {
        duration: ANIMATION_DURATION,
      })
      map.getView().animate({
        center: getCenterOfExtent(geometry.getExtent()),
        duration: ANIMATION_DURATION,
      })
    }
  }

  return (
    <Wrapper>
      {isLoading ? (
        <Loader>
          <SpinElement />
        </Loader>
      ) : (
        <>
          {features.length ? (
            <>
              <ScrollWrapper>
                {features.map((f, idx) => (
                  <MedicalNeuronetsElement
                    key={f.get('class)id')}
                    color={f.get('color')}
                    title={f.get('title')}
                    className={f.get('class_name')}
                    onMouseEnterHandle={setHoveredClass}
                    onMouseLeaveHandle={setHoveredClass}
                    onClickHandle={onSelectElementHandle}
                  />
                ))}
              </ScrollWrapper>
              <Divider />
              <ToolOpacityController value={opacity} onChange={setOpacity} />
            </>
          ) : (
            infoMessage
          )}
        </>
      )}
    </Wrapper>
  )
}

export default MedicalNeuronetsContainer
