import { useTypedSelector } from 'app/redux/lib/selector'
import { useAtlasSlideQuery } from 'entities/atlas'
import { useSlideQuery } from 'entities/slide'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { notices } from 'features/notices'
import html2canvas from 'html2canvas'
import { useViewerIdMap, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { getSlideName } from 'shared/lib/metadata'
import { ButtonElement, SpinElement } from 'shared/ui/kit'
import { ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { IMarkupSlide } from 'types/IMarkupSlide'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'

import OptionsContainer from './OptionsContainer'

type Props = {
  mapContainerRef: React.RefObject<HTMLDivElement>
  viewerId: TViewerId
  annotationsId?: number[]
}

const StyledImage = styled.img`
  border: 1px solid var(--color-bg-1);
  border-radius: 5px;
`

const LeftPanel = styled.div`
  width: 192px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 16px 16px;
  justify-content: center;
`

const StyledButtonElement = styled(ButtonElement)`
  width: 100%;
`
const Loading = styled.div`
  color: var(--color-text-1);
  width: 200px;
  display: flex;
  gap: 8px;
`

export const IGNORE_IN_SCREENSHOT_ID = 'IGNORE_IN_SCREENSHOT'

export const ScreenShotModal = memo(({ annotationsId, mapContainerRef, viewerId }: Props) => {
  const dispatch = useDispatch()
  const [image, setImage] = useState<string>()
  const queryClient = useQueryClient()
  const viewerDispatch = useViewerDispatch(viewerId)
  const map = useViewerIdMap(viewerId)
  const canvas = useRef<HTMLCanvasElement>()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { isScreenshotModalOpen } = useViewerMainSelector(viewerId)
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const modalRef = useRef<any>()
  const currentSlides = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const permissionLevel = caseRecord?.permissionLevel
  const OVERVIEW = useTypedSelector((state) => state.viewerPage.tools?.OVERVIEW)
  const TOOLS = useTypedSelector((state) => state.viewerPage.tools)
  const annotationsIsVisible = useTypedSelector((state) => state.annotations.annotationsIsVisible)
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  const { t } = useTranslation()

  const [isChecked, setIsChecked] = useState({
    annotations: false,
    scaleLine: true,
  })

  useEffect(() => {
    const annotationBySlideIds = annotationsIsVisible?.filter((id) => annotationsId?.indexOf(id) !== -1)

    annotationBySlideIds?.length &&
      setIsChecked((prevState) => ({
        ...prevState,
        annotations: true,
      }))
  }, [annotationsIsVisible, annotationsId])

  const onClose = () => {
    viewerDispatch(viewerSlice.actions.setScreenshotModal(false))
    viewerDispatch(viewerSlice.actions.setModalOpen(false))
  }

  const { data: atlasSlide } = useAtlasSlideQuery(slideId)
  const currSlide = currentSlides?.find((item) => item.slideId === slideId)

  const { data: displayedSlide } = useSlideQuery({
    slideId: slideId,
    source,
  })

  const slideName = atlasSlide?.caption || currSlide?.slide?.caseExternalId || getSlideName(displayedSlide)

  const onCopyToClipboard = () => {
    canvas.current?.toBlob(
      (blob) =>
        blob &&
        navigator.clipboard
          //@ts-ignore
          .write([new ClipboardItem({ 'image/png': blob })])
          .then(() => notices.info({ message: t('Скопировано!') })),
    )
  }
  const onButtonClick = useCallback(() => {
    image && downloadImage(image, slideName + '_viewer.png')
  }, [image])

  const exportMapToImage = async (el: HTMLDivElement) => {
    canvas.current = await html2canvas(el, {
      allowTaint: true,
      backgroundColor: 'none',
      ignoreElements: function (element) {
        const elementId = element.id || ''
        const elementClassName = element.className || ''
        if (elementId === IGNORE_IN_SCREENSHOT_ID) {
          return true
        }
        if (elementId === 'FLOATING_ACTION') {
          return true
        }
        if (elementId === 'VIEWER_WARNING') {
          return true
        }
        if (elementClassName === 'custom-ol-draw-tooltip') {
          return true
        }
        if (elementId === 'MINIMAP') {
          return !OVERVIEW.isVisible
        }
        if (
          typeof elementClassName === 'string' &&
          elementClassName?.includes('ol-scale-line') &&
          !isChecked.scaleLine
        ) {
          return true
        }
        return false
      },
      useCORS: true,
    })
    const image = canvas.current.toDataURL('image/jpg')
    setImage(image)
  }
  const downloadImage = (blob: string, fileName: string) => {
    const fakeLink = window.document.createElement('a')
    //@ts-ignore
    fakeLink.style = 'display:none;'
    fakeLink.download = fileName

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)
    fakeLink.remove()
  }

  useEffect(() => {
    if (!image) {
      viewerDispatch(viewerSlice.actions.setModalOpen(true))
    }
    if (isChecked.annotations && annotationsId) {
      dispatch(annotationsSlice.actions.setAnnotationsIsVisible(annotationsId))
    }
    if (!isChecked.annotations && annotationsId?.length === annotationsIsVisible?.length) {
      dispatch(annotationsSlice.actions.setAnnotationsIsVisible([]))
      viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
      viewerDispatch(viewerSlice.actions.setBuffer(undefined))
    }
    setTimeout(() => {
      mapContainerRef.current && exportMapToImage(mapContainerRef.current)
    }, 1000)
    map.updateSize()
  }, [map, mapContainerRef, isChecked, TOOLS])

  useEffect(() => {
    isScreenshotModalOpen ? modalRef.current.open() : modalRef.current.close()
  }, [isScreenshotModalOpen])

  useEffect(() => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(true))
    return () => {
      dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(false))
    }
  }, [])

  return (
    <ModalProvider
      ref={modalRef}
      title={t('Снимок рабочей области')}
      destroyOnClose
      onCancel={onClose}
      footer={null}
      width="fit-content"
    >
      <Wrapper>
        {!image ? (
          <Loading>
            <SpinElement />
            {t('Картинка загружается')}
          </Loading>
        ) : (
          <>
            <LeftPanel>
              <OptionsContainer
                permissionLevel={permissionLevel}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                annotationsId={annotationsId}
                labelUrl={slide?.labelUrl}
              />
              <StyledButtonElement type="default" onClick={onCopyToClipboard}>
                {t('Копировать в буфер обмена')}
              </StyledButtonElement>
              <StyledButtonElement type="primary" onClick={onButtonClick}>
                {t('Сохранить')}...
              </StyledButtonElement>
            </LeftPanel>
            <StyledImage src={image} height="420px" />
          </>
        )}
      </Wrapper>
    </ModalProvider>
  )
})
