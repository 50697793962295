import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch } from 'viewer/container'
import { ObjectsCountingMethod, viewerSlice } from 'viewer/container/model/viewerSlice'
import { viewerHelpSlice } from 'viewer/help/model/viewerHelpSlice'
import {
  SCREEN_RECORD_ZOOM_LVL,
  useObjectsCountingContext,
} from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

import CountingOption from './CountingOption'
import { countingTypesText } from './countingTypes'

type Props = {
  viewerId: TViewerId
  zoomLvl: number
}

const CountingWrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: ${({ collapsed }) => (collapsed ? 'row' : 'column')};
  background-color: ${({ collapsed }) => (collapsed ? 'var(--color-bg-3)' : '')};
  padding: ${({ collapsed }) => (collapsed ? '8px' : '0px')};
  border-radius: 5px;
  margin-bottom: 8px;
`

const TextInfo = styled.div`
  padding: 4px;
  font-size: 13px;
  line-height: 18px;
  margin: 4px 0px;
`

const StyledTotalArea = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-1);
  text-align: right;
  flex: 1 0 auto;
`

const CountingMethods: React.FC<Props> = ({ viewerId, zoomLvl }) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const { isObjectsDrawActive, objectsCountingMethod } = useTypedSelector((state) => state.viewers[viewerId].viewer)
  const { data, totalAreaCount } = useObjectsCountingContext()
  const { hideHelpMessage, setHelpMessage } = viewerHelpSlice?.actions || {}
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType) || 'OBJECTS'
  const { t } = useTranslation()
  const isAnySpot = Object.keys(data).length > 0

  const CONTINUE_ADDED_MITOSIS_TEXT = `${t(
    'Для ручной детекции объекта двойной клик по слайду, удалить объект со слайда - один клик на увеличении',
  )} ${SCREEN_RECORD_ZOOM_LVL}x`

  const setCountingMethod = (method: ObjectsCountingMethod) => {
    viewerDispatch(
      viewerSlice.actions.setObjectsMode({
        active: objectsCountingMethod !== method,
        countingMethod: objectsCountingMethod !== method ? method : undefined,
      }),
    )
    viewerDispatch(setHelpMessage(CONTINUE_ADDED_MITOSIS_TEXT))
  }
  useEffect(() => {
    if (!isObjectsDrawActive) viewerDispatch(hideHelpMessage(false))
  }, [isObjectsDrawActive])

  useEffect(
    () => () => {
      viewerDispatch(viewerSlice.actions.setObjectsMode({ active: false }))
    },
    [],
  )

  return (
    <CountingWrapper collapsed={isObjectsDrawActive || isAnySpot}>
      {!isObjectsDrawActive && !isAnySpot && (
        <>
          <TextInfo>{t(countingTypesText[countingObjectType].textToMethod)}</TextInfo>
          <Divider style={{ marginBottom: 14 }} />
        </>
      )}

      <CountingOption
        name={'screenRecord'}
        label={t('На видимой площади')}
        setCountingMethod={setCountingMethod}
        collapsed={isObjectsDrawActive || isAnySpot}
        active={objectsCountingMethod === 'screenRecord'}
        animate={objectsCountingMethod === 'screenRecord' && zoomLvl >= SCREEN_RECORD_ZOOM_LVL}
      />
      <CountingOption
        name={'one_mm'}
        label={`${t('Поле зрения')} 1 ${t('мм')}`}
        setCountingMethod={setCountingMethod}
        collapsed={isObjectsDrawActive || isAnySpot}
        active={objectsCountingMethod === 'one_mm'}
        withSup
      />
      <CountingOption
        name={'two_mm'}
        label={`${t('Поле зрения')} 2 ${t('мм')}`}
        setCountingMethod={setCountingMethod}
        collapsed={isObjectsDrawActive || isAnySpot}
        active={objectsCountingMethod === 'two_mm'}
        withSup
      />
      {isAnySpot && (
        <StyledTotalArea
          dangerouslySetInnerHTML={{
            __html: `${totalAreaCount} ${t('мм')}²`,
          }}
        />
      )}
    </CountingWrapper>
  )
}

export default CountingMethods
