import { useMapParamsProvided } from 'pages/viewer/lib/common/MapsProvider'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useCoregistrationProvided } from 'pages/viewer/lib/coregistration/Provider'
import { fromDegreesToRadians, fromRadiansToDegrees, rotateCoordinate } from 'pages/viewer/lib/helper'
import { useCallback } from 'react'
import TViewerId from 'types/TViewerId'

export const useCommonCalculations = () => {
  const { corViewingState } = useCoregistrationProvided()
  const { viewingState } = useViewerPageProvided()
  const { getOriginParams } = useMapParamsProvided()
  const warpCoordinates = useCallback(
    (initialVid: TViewerId, vidToChange: TViewerId, reverse = false) => {
      const refMap = viewingState['A']?.map
      const affine = corViewingState[reverse ? initialVid : vidToChange]?.affine
      if (refMap === undefined || affine === undefined) return
      const refOriginParams = getOriginParams('A')
      const initialOriginParams = getOriginParams(initialVid)
      const origParamsOfVidToChange = getOriginParams(vidToChange)
      const refCenter = refMap.getView().getCenter()
      if (refCenter === undefined) return
      const refShift = [0, 0]
      refShift[0] = refCenter[0] - refOriginParams.origCenter[0]
      refShift[1] = refCenter[1] - refOriginParams.origCenter[1]
      const rotatedOriginCenter = rotateCoordinate(
        reverse ? initialOriginParams.origCenter : origParamsOfVidToChange.origCenter,
        affine.angle,
        [affine.rotX, -affine.rotY],
        1 / affine.scale,
      )
      const rotatedRefShift = rotateCoordinate(refShift, affine.angle, [0, 0], 1 / affine.scale) // + for double Y-axis flip
      const rotatedDelta = rotateCoordinate([affine.dx, -affine.dy], affine.angle, [0, 0], 1 / affine.scale)
      const finalCenter = [
        rotatedOriginCenter[0] + rotatedRefShift[0] - rotatedDelta[0],
        rotatedOriginCenter[1] + rotatedRefShift[1] - rotatedDelta[1],
      ]
      return finalCenter
    },
    [corViewingState],
  )

  const computeRestViewerCenterByAffine = useCallback(
    (vidToChange: TViewerId, onChange?: (center: number[]) => void) => {
      const mapToChange = viewingState[vidToChange]?.map
      if (mapToChange === undefined) return
      const center = warpCoordinates('A', vidToChange)
      if (center === undefined) return
      mapToChange.getView().setCenter(center)
      if (onChange !== undefined) onChange(center)
    },
    [corViewingState],
  )
  const computeRestViewerZoomByAffine = useCallback(
    (vidToChange: TViewerId, onChange?: (zoom: number) => void) => {
      const mapToChange = viewingState[vidToChange]?.map
      const refMap = viewingState['A']?.map
      const affine = corViewingState[vidToChange]?.affine
      if (refMap === undefined || mapToChange === undefined || affine === undefined) return
      const zoom = refMap.getView().getResolution()
      if (zoom === undefined) return
      const result = zoom / affine.scale
      mapToChange.getView().setResolution(result)
      if (onChange !== undefined) onChange(result)
    },
    [corViewingState],
  )
  const computeRestViewerRotationByAffine = useCallback(
    (vidToChange: TViewerId, onChange?: (radians: number) => void) => {
      const mapToChange = viewingState[vidToChange]?.map
      const refMap = viewingState['A']?.map
      const affine = corViewingState[vidToChange]?.affine
      if (refMap === undefined || mapToChange === undefined || affine === undefined) return
      const refRotation = refMap.getView().getRotation()
      const radians = fromDegreesToRadians(fromRadiansToDegrees(refRotation) + affine.angle)
      mapToChange.getView().setRotation(radians)
      if (onChange !== undefined) onChange(radians)
    },
    [corViewingState],
  )
  return {
    computeRestViewerCenterByAffine,
    computeRestViewerRotationByAffine,
    computeRestViewerZoomByAffine,
    warpCoordinates,
  }
}
