import TileLayer from 'ol/layer/Tile'
import { IIIF } from 'ol/source'
import { Options } from 'ol/source/IIIF'
import TileState from 'ol/TileState'
import ISlideLayerConfig from 'types/ISlideLayerConfig'

import { adjustScaleIfRequired, isScaledTiles, scaledIIIOptions, TILE_PIXEL_RATIO } from './helpers/scaledIIIOptions'

const MIN_VIEWER_SIZE = 256
const CACHE_SIZE = 512

export const dzi = (function () {
  function loadUrl(
    opt_options: any, // attributions (defaults to undefined), crossOrigin (defaults to 'anonymous')
    setConfig: (config: ISlideLayerConfig) => void,
    dziErrorHandler: () => void,
    url?: string | string[] | URL,
  ) {
    if (!url) return
    const options = opt_options || {}
    const crossOrigin = options.crossOrigin === undefined ? 'anonymous' : options.crossOrigin

    const layer = new TileLayer({ preload: 3 })

    const last = (url as string[]).lastIndexOf('.')
    const path = (url as string[]).slice(0, last)

    const xhr = new XMLHttpRequest()
    xhr.open('GET', url as string)
    xhr.onload = function () {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xhr.responseText, 'text/xml')
      const elements = xmlDoc.getElementsByTagName('Image')
      const tileSize = Number(elements[0].getAttribute('TileSize'))
      const format = elements[0].getAttribute('Format')
      const width = Number(elements[0].getElementsByTagName('Size')[0].getAttribute('Width'))
      const height = Number(elements[0].getElementsByTagName('Size')[0].getAttribute('Height'))
      const isSmallSlide = width <= MIN_VIEWER_SIZE || height <= MIN_VIEWER_SIZE
      const urlTemplate = path + '_files/{z}/{x}_{y}.' + format

      const newOptions: Options = {
        attributions: options.attributions,
        cacheSize: CACHE_SIZE,
        crossOrigin: crossOrigin,
        interpolate: false,
        reprojectionErrorThreshold: 1,
        size:
          width >= MIN_VIEWER_SIZE || height >= MIN_VIEWER_SIZE ? [width, height] : [MIN_VIEWER_SIZE, MIN_VIEWER_SIZE],
        tilePixelRatio: width >= MIN_VIEWER_SIZE ? TILE_PIXEL_RATIO : 1,
        tileSize: width >= MIN_VIEWER_SIZE ? adjustScaleIfRequired(tileSize) : MIN_VIEWER_SIZE,
        url: urlTemplate,
        zDirection: -1,
      }

      const scaledOptions = scaledIIIOptions(newOptions)
      const source = new IIIF(newOptions)
      isScaledTiles &&
        source.setTileLoadFunction((tile, src) => {
          const tileZIndex = tile.getTileCoord()[0]
          const maxZ = source.getTileGrid().getMaxZoom()
          const isValidZ = maxZ <= 1 || maxZ > tileZIndex
          const isValidZForSmall = isSmallSlide && maxZ === 1 ? tileZIndex < 1 : true
          if (isValidZ && isValidZForSmall)
            // @ts-ignore
            tile.getImage().src = src
          else tile.setState(TileState.ERROR)
        })
      source.setTileUrlFunction(([coordZ, coordX, coordY]) =>
        urlTemplate
          .replace('{z}', coordZ.toString())
          .replace('{x}', coordX.toString())
          .replace('{y}', coordY.toString()),
      )
      layer.setExtent([0, -height, width, 0])
      layer.set('type', 'main')
      layer.setSource(source)

      setConfig({
        layer,
        options: scaledOptions,
        source,
      })
    }

    xhr.onerror = function () {
      dziErrorHandler()
    }
    xhr.send()

    return layer
  }

  return {
    loadUrl,
  }
})()
