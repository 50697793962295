import { useTypedSelector } from 'app/redux/lib/selector'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const HelpContainer = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  width: 258px;
  height: 68px;
  ${() => modalBackground}
  ${() => modalShadow}

  & > span {
    white-space: nowrap;
  }
`

const IconBox = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

const MessageContainer = styled.div<{ isLeftPanelVisible: boolean }>`
  position: absolute;
  z-index: 11;
  /** Позиция уведомления в зависимости от видимости левой панели со списком слайдов*/
  transform: ${({ isLeftPanelVisible }) => (isLeftPanelVisible ? 'translate(-29.85%, 0)' : 'translate(14.35%, 0)')};
  bottom: 16px;
`

const Title = styled.div`
  color: var(--color-text-3);
  line-height: 16px;
`
type Props = {
  status: any
}

const ViewerWarningContainer = ({ status }: Props) => {
  const isLeftPanelVisible = !!useTypedSelector((state) => state.viewerPage.leftPanelType)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const closePanel = async () => {
    dispatch(viewerPageSlice.actions.setCacheMessage(false))
  }
  useEffect(() => {
    setTimeout(() => closePanel(), 5000)
  }, [])

  return (
    <MessageContainer id={'VIEWER_WARNING'} isLeftPanelVisible={isLeftPanelVisible}>
      <HelpContainer>
        <div>
          {status === 'ARCHIVE' ? (
            <TextElement>{t('Случай находится в архиве')}</TextElement>
          ) : (
            <TextElement>{t('Подготовка кеша еще не окончена')}</TextElement>
          )}
          <Title>{t('Скорость прогрузки изображения значительно снижена')}</Title>
        </div>
        <IconBox>
          <IconElement name="cross" onClick={closePanel} />
        </IconBox>
      </HelpContainer>
    </MessageContainer>
  )
}

export default ViewerWarningContainer
