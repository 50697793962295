export enum MergeType {
  /** операция объединения */
  UNION = 'UNION',
  /** операция исключения */
  EXCLUDE = 'EXCLUDE',
  /** операция пересечения */
  INTERSECT = 'INTERSECT',
  /** операция получения различий */
  SUBTACT = 'SUBTACT',
  /** операция разбиения */
  DISUNION = 'DISUNION',
  /** операция получения внутреннего контура */
  INTERIOR = 'INTERIOR',
}
