import { useTypedSelector } from 'app/redux/lib/selector'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { notices } from 'features/notices'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  selectTasksViewerUrlTaskId,
  selectUrlSlideId,
  SideRightPanelType,
  viewerPageSlice,
} from 'pages/viewer/model/viewerPageSlice'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { IconElement, IIconElementProps, TooltipElement } from 'shared/ui/kit'
import { ToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import styled from 'styled-components/macro'
import { AnnotationType, AnnotationTypeName } from 'types/IAnnotations'
import { IMarkupClass } from 'types/IMarkupSlide'
import ISlide from 'types/ISlide'

export const ANNOTATIONS_ICONS_MAP: Record<AnnotationType, IIconElementProps['name']> = {
  ARROW: 'drawArrow',
  CIRCLE: 'circle',
  MITOSIS: 'mitosisAnnotation',
  MultiPolygon: 'pen',
  OBJECTS: 'objects',
  PEN: 'drawPen',
  PEN_POLYGON: 'pen',
  POINT: 'dot',
  POLYGON: 'pen',
  RECTANGLE: 'rectangle',
  RULER: 'ruler',
}

const StyledPanel = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledTooltipElement = styled(TooltipElement)`
  margin-right: 12px;
  &:last-child {
    margin-right: 0px;
  }
`

type AnnotationButtonProps = {
  name: string
  type: AnnotationType
  active: boolean
  hotkey: string
  disable: boolean
  callback?: () => void
}

const AnnotationButton = ({ active, callback, disable, hotkey, name, type }: AnnotationButtonProps) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const urlSlideId = useSelector(selectUrlSlideId)
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, urlSlideId])
  const mppX = slide?.slideMetadata?.commonMetadata?.mppX

  const { isFastTravel } = useViewerPageProvided()
  const { t } = useTranslation()

  const { targetUserId, unsubscribeFromUser } = useUserStatusContext()

  const setAnnotation = () => {
    unsubscribeFromUser()
    if (!disable) {
      if (!mppX && type !== 'POINT') {
        notices.error({
          message: t('Морфометрия недоступна, невозможно определить разрешение слайда'),
        })
      }
      dispatch(viewerPageSlice.actions.setViewerRightPanel(SideRightPanelType.ANNOTATIONS))
      dispatch(annotationsSlice.actions.toggleAnnotationType(type))
      callback?.()
    }
  }

  useHotkeys(
    hotkey,
    () => {
      if (!isFastTravel) {
        dispatch(viewerPageSlice.actions.setViewerRightPanel(SideRightPanelType.ANNOTATIONS))
        setAnnotation()
      }
    },
    [disable, isFastTravel, targetUserId],
  )

  return (
    <StyledTooltipElement title={disable ? t('Инструмент недоступен') : `${name} (${hotkey})`} placement="bottom">
      <ToolbarButton
        active={active}
        disable={disable}
        disabled={disable}
        onClick={setAnnotation}
        className={`${type?.toLowerCase()}-btn`}
        data-testid={`${type?.toLowerCase()}-btn`}
        icon={<IconElement name={ANNOTATIONS_ICONS_MAP[type]} />}
      />
    </StyledTooltipElement>
  )
}

type Props = {
  disabled: boolean
}
export const RulerAnnotationButton = ({ disabled }: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const isCustom = !!currTaskClasses && currTaskClasses.length > 0
  const currTaskClassesName = currTaskClasses?.map((item) => item.annotationType)
  const isDisabledButton = (isCustom && !currTaskClassesName?.includes(AnnotationType.RULER)) || disabled

  return (
    <StyledPanel style={{ padding: '0, 12px' }}>
      <AnnotationButton
        name={t('Линейка')}
        type={AnnotationType.RULER}
        disable={isDisabledButton}
        active={annotationType === AnnotationType.RULER}
        hotkey={'L'}
      />
    </StyledPanel>
  )
}

type PropsTypePanel = {
  isMacro?: boolean
  openContextMenu?: boolean
  onTypeSelect?: () => void
  disabled?: boolean
}

const AnnotationsTypePanel = ({ disabled, isMacro, onTypeSelect, openContextMenu }: PropsTypePanel) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const isCustom = !!currTaskClasses && currTaskClasses.length > 0
  const currTaskClassesName = currTaskClasses?.map((item) => item.annotationType)

  const { t } = useTranslation()
  const isDisable = (type: AnnotationType) => disabled || (isCustom && !currTaskClassesName?.includes(type))
  return (
    <StyledPanel
      style={{
        justifyContent: openContextMenu ? 'space-between' : undefined,
        padding: openContextMenu ? 0 : '0, 12px',
        width: openContextMenu ? '100%' : undefined,
      }}
    >
      {openContextMenu && (
        <AnnotationButton
          name={t(AnnotationTypeName.RULER)}
          type={AnnotationType.RULER}
          disable={isDisable(AnnotationType.RULER) || !!isMacro}
          active={annotationType === AnnotationType.RULER}
          hotkey={'L'}
          callback={onTypeSelect}
        />
      )}
      {!taskId && (
        <AnnotationButton
          name={t(AnnotationTypeName.ARROW)}
          type={AnnotationType.ARROW}
          disable={isDisable(AnnotationType.ARROW) || !!isMacro}
          active={annotationType === AnnotationType.ARROW}
          hotkey={'V'}
          callback={onTypeSelect}
        />
      )}
      <AnnotationButton
        name={t(AnnotationTypeName.PEN)}
        type={AnnotationType.PEN}
        disable={isDisable(AnnotationType.POLYGON)}
        active={annotationType === AnnotationType.PEN}
        hotkey={'Y'}
        callback={onTypeSelect}
      />

      {/*// Скрыто в рамках ONECELL-4719*/}
      {/*{annotationType === AnnotationType.PEN && taskId && (*/}
      {/*  <CheckboxElement style={{ userSelect: 'none' }} checked={penSimplifyMode} onClick={penSimplifyModeHandler}>*/}
      {/*    {t('Упрощение')}*/}
      {/*  </CheckboxElement>*/}
      {/*)}*/}
      <AnnotationButton
        name={t(AnnotationTypeName.POINT)}
        type={AnnotationType.POINT}
        disable={isDisable(AnnotationType.POINT)}
        active={annotationType === AnnotationType.POINT}
        hotkey={'D'}
        callback={onTypeSelect}
      />
      <AnnotationButton
        name={t(AnnotationTypeName.RECTANGLE)}
        type={AnnotationType.RECTANGLE}
        disable={isDisable(AnnotationType.RECTANGLE)}
        active={annotationType === AnnotationType.RECTANGLE}
        hotkey={'R'}
        callback={onTypeSelect}
      />
      <AnnotationButton
        name={t(AnnotationTypeName.CIRCLE)}
        type={AnnotationType.CIRCLE}
        disable={isDisable(AnnotationType.CIRCLE)}
        active={annotationType === AnnotationType.CIRCLE}
        hotkey={'O'}
        callback={onTypeSelect}
      />
      <AnnotationButton
        name={t(AnnotationTypeName.POLYGON)}
        type={AnnotationType.POLYGON}
        disable={isDisable(AnnotationType.POLYGON)}
        active={annotationType === AnnotationType.POLYGON}
        hotkey={'P'}
        callback={onTypeSelect}
      />
    </StyledPanel>
  )
}

export default AnnotationsTypePanel
