import { AtlasViewerRoute } from './ui/atlas-viewer/AtlasViewerRoute'
import { ViewerRoute } from './ui/default-viewer/ViewerRoute'
import { TasksViewerRoute } from './ui/tasks-viewer/TasksViewerRoute'

export type { ViewerLeftPanelType, ViewerRightPanelType } from './model/viewerPageSlice'
export {
  selectAtlasViewerUrlSlideId,
  selectTasksViewerUrlTaskId,
  selectUrlCaseId,
  selectUrlSlideId,
  viewerPageSlice,
} from './model/viewerPageSlice'

export { AtlasViewerRoute, TasksViewerRoute, ViewerRoute }
