import { searchSlice } from 'features/search/model/searchSlice'
import { pointerMove } from 'ol/events/condition'
import Feature from 'ol/Feature'
import { Geometry, Polygon } from 'ol/geom'
import Select, { SelectEvent } from 'ol/interaction/Select'
import VectorImageLayer from 'ol/layer/VectorImage'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MapContext } from 'shared/lib/map'
import { ISelectedBbox } from 'types/ISimilarRegion'
import { useViewerDispatch } from 'viewer/container/lib/useViewerSelector'
import { createThumbnailLink, viewerSlice } from 'viewer/container/model/viewerSlice'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'
import { highlightStyle, selectedStyle } from 'viewer/map/layers/olStyles'
import { removeLayersByInternalType } from 'viewer/map/lib/utils'

type Props = {
  currentZoomLevel: number
  selectedBbox: ISelectedBbox | undefined
  features: Feature<Geometry>[]
  iiif2Url?: string
  slideId: number
  caseId: number
  iiif2AuthToken?: string
}

export const GridLayer = ({ currentZoomLevel, features, iiif2AuthToken, iiif2Url, slideId }: Props) => {
  const { viewerId } = useContext(MapContext)
  const map = useViewerIdMap(viewerId)
  const isInactive = false
  const [gridZoomLevels, setGridZoomLevels] = useState<number[]>([])
  const viewerDispatch = useViewerDispatch(viewerId)
  const dispatch = useDispatch()

  /// Add grid Layer
  useEffect(() => {
    if (!map || features.length === 0) {
      return
    }
    const arrayOfGridZoomLevels = getGridZooms(features)
    setGridZoomLevels(arrayOfGridZoomLevels)

    arrayOfGridZoomLevels.forEach((resolution) => {
      const featuresByResolution = features.filter((feature) => feature.getProperties().zoom === resolution)
      if (!featuresByResolution.length) {
        return
      }
      const gridLayer = createGridLayer(featuresByResolution, resolution)

      map.addLayer(gridLayer)
    })

    return () => {
      removeLayersByInternalType(map, LayerType.GRID)
    }
  }, [features])

  useEffect(() => {
    if (isInactive || !map || gridZoomLevels.length === 0) {
      return
    }

    const closestGridZoom = gridZoomLevels.reduce((a, b) =>
      Math.abs(b - currentZoomLevel) < Math.abs(a - currentZoomLevel) ? b : a,
    )

    //show current grid
    map.getLayers().forEach((layer) => {
      if (layer && layer.get(INTERNAL_TYPE_ID) === LayerType.GRID) {
        if (layer && layer.get('zoom') === closestGridZoom) {
          layer.setVisible(true)
        }
      }
    })

    const selectIteraction = new Select({
      layers: (layer) => (layer.get('zoom') === closestGridZoom ? true : false),
      style: selectedStyle,
    })
    map.addInteraction(selectIteraction)

    selectIteraction.on('select', (e: SelectEvent) => {
      if (!e.selected[0]) {
        viewerDispatch(viewerSlice.actions.setSelectedBbox(undefined))
        return
      }

      const coord = (e.selected[0].getGeometry() as Polygon)?.getCoordinates()[0]
      const feature = e.selected[0].getProperties()
      const bboxCoordinates = { x1: coord[0][0], x2: coord[2][0], y1: coord[0][1], y2: coord[2][1] }

      const bbox: ISelectedBbox = {
        bbox: bboxCoordinates,
        bboxId: feature.bbox_id,
        score: feature.valid_mean,
        thumbnailUrl: createThumbnailLink(iiif2Url!, bboxCoordinates, false, iiif2AuthToken),
        zoomLevel: feature.zoom,
      }

      dispatch(searchSlice.actions.setMainSelectedBbox(bbox))
    })

    const select = new Select({
      condition: pointerMove,
      layers: (layer) => (layer.get('zoom') === closestGridZoom ? true : false),
      style: highlightStyle,
    })

    map.addInteraction(select)

    return () => {
      map.removeInteraction(selectIteraction)
      map.removeInteraction(select)
      map.getLayers().forEach((layer) => {
        if (layer && layer.get(INTERNAL_TYPE_ID) === LayerType.GRID) {
          layer.setVisible(false)
        }
      })
    }
  }, [map, currentZoomLevel, gridZoomLevels, slideId, isInactive])

  return null
}

const getGridZooms = (features: Feature<Geometry>[]) => {
  const zoomLevels = new Set<number>()
  features.forEach((feature) => {
    zoomLevels.add(feature.getProperties().zoom)
  })
  return Array.from(zoomLevels)
}

const createGridLayer = (features: Feature<Geometry>[], resolution: number) => {
  const gridLayer = new VectorImageLayer({
    source: new VectorSource({
      features,
    }),
    style: new Style({
      fill: new Fill({
        color: 'rgba(0,0,255,0)',
      }),
      stroke: new Stroke({
        color: 'rgba(255,0,0,0.2)',
        width: 2,
      }),
    }),
    visible: false,
    zIndex: 3,
  })

  gridLayer.set('zoom', resolution)
  gridLayer.set(INTERNAL_TYPE_ID, LayerType.GRID)
  return gridLayer
}

export default GridLayer
