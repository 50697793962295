import { Divider } from 'antd'
import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import CaseInfoContainer from 'features/case-info/CaseInfoContainer'
import WarningPreview from 'features/new-preview-panel/WarningPreview'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

type Props = {
  /** Идентификатор случая */
  caseId: number
  /** Данные о случае */
  caseRecord?: ICase
}

const DescriptionTabContent = ({ caseId, caseRecord }: Props) => {
  const lisMode = useLisMode()
  const { t } = useTranslation()
  const { casePathologicalInfo, declinementComment } = caseRecord || {}
  const clinicalInfo = casePathologicalInfo?.clinicalInfo
  const macroDescription = casePathologicalInfo?.macroDescription
  /** Показывает, отображается ли клиническая информация */
  const isClinicalInfoVisible = checkIntegration('caseInfo', 'clinicalInfo', lisMode) && clinicalInfo
  /** Показывает, отображается ли макроописание */
  const isMacroDescriptionVisible = checkIntegration('caseInfo', 'macroDescription', lisMode) && macroDescription

  return (
    <>
      {declinementComment && <WarningPreview title={declinementComment} />}
      <div style={{ display: 'flex', minHeight: '100%' }}>
        <InfoContainer>
          <CaseInfoContainer caseId={caseId} source={'PLATFORM'} skipReports skipLongData />
        </InfoContainer>
        <Divider style={{ margin: 0 }} type={'vertical'} />
        <DescriptionWrapper>
          <div style={{ maxWidth: '820px' }}>
            {isClinicalInfoVisible && (
              <>
                <StyledTitle data-testid={`description-panel-clinicalinfo`}>
                  {' '}
                  {getFieldLabelBylisMode('clinicalInfo', lisMode)}
                </StyledTitle>
                <StyledDescription>{clinicalInfo}</StyledDescription>
              </>
            )}
            {isMacroDescriptionVisible && (
              <>
                <StyledTitle data-testid="description-panel-macrodescription" style={{ marginTop: 16 }}>
                  {t('Макроописание')}
                </StyledTitle>
                <StyledDescription>{macroDescription}</StyledDescription>
              </>
            )}
          </div>
          {!isClinicalInfoVisible && !isMacroDescriptionVisible && (
            <EmptyElement description={<span>{t('Клинические сведения по случаю отсутствуют')}</span>} />
          )}
        </DescriptionWrapper>
      </div>
    </>
  )
}

export default DescriptionTabContent

const InfoContainer = styled.div`
  width: 272px;
  padding: 16px;
  overflow: auto;
`
const StyledDescription = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
`

const DescriptionWrapper = styled.div`
  border-left: 1px solid var(--color-border-1);
  flex: 1;
  padding: 16px;
`

const StyledTitle = styled(TitleElement)`
  &.ant-typography {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-text-3);
  }
`
