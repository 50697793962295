import { getBarcodeFailText } from 'pages/uploaded-file/lib/renderHandlers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import styled from 'styled-components/macro'
import { EUploadedFileAttachmentState } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

type Props = {
  /** Данные выбранного файла. */
  selectedFile?: IUploadedFileDTO
}

const UploadedFileDescription = ({ selectedFile }: Props) => {
  const { t } = useTranslation()
  const { barcodes, conversionFailureCodeDzi, conversionFailureCodeTiff, fileName, path, state } = selectedFile || {}
  const barcodeValue = barcodes?.[0]?.barcode
  const mrxsError = conversionFailureCodeDzi || conversionFailureCodeTiff ? t('Файл поврежден') : undefined

  return (
    <StyledDescriptions>
      <DescriptionsItem label={t('Имя')} span={3}>
        {fileName}
      </DescriptionsItem>
      {!!barcodes?.length && (
        <DescriptionsItem label={t('ШК')} span={3}>
          {barcodeValue}
        </DescriptionsItem>
      )}
      <DescriptionsItem label={t('Путь')} span={3}>
        {path}
      </DescriptionsItem>
      <DescriptionsItem label={t('Проблема')} span={3}>
        {mrxsError || getBarcodeFailText(state || EUploadedFileAttachmentState.SUCCESS)}
      </DescriptionsItem>
    </StyledDescriptions>
  )
}

export default UploadedFileDescription

const StyledDescriptions = styled(Descriptions)`
  tbody {
    display: grid;
    gap: 4px;
  }
  .ant-descriptions-item-container {
    line-height: initial;
  }
`
