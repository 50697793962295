import _ from 'lodash'
import { ICurrentMapsParams } from 'pages/viewer/lib/common/MapsProvider'
import { useOpenViewers, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { fromRadiansToDegrees, rotateCoordinate } from 'pages/viewer/lib/helper'
import { useEffect, useRef } from 'react'
import { useEventBusProvided } from 'shared/lib/EventBus'
import { usePrevious } from 'shared/lib/hooks'
import TViewerId from 'types/TViewerId'

import { useCommonCalculations } from './hooks/useCommonCalculations'
import { useCoregistrationProvided } from './Provider'

export const HandleModCoregistrationEventController = () => {
  const { viewingState } = useViewerPageProvided()
  const { corViewingState } = useCoregistrationProvided()
  const prevCorViewingState = usePrevious(corViewingState)
  const { computeRestViewerCenterByAffine, computeRestViewerRotationByAffine, computeRestViewerZoomByAffine } =
    useCommonCalculations()
  /**
   * prevParams: Ref(   ( function() {} )  ( )   )
   * Это типа как beforeMount только для рефа. Ловите лайфхак пацаны.
   * В useRef нельзя прокинуть функцию для вычисления состояния. Но если очень хочется -
   * то никто не запрещает воскользоваться self invokingом, который при регистрации создаст и вернёт initial state из ранее зареганого.
   */
  const prevParams = useRef<ICurrentMapsParams>(
    (function () {
      const params: ICurrentMapsParams = {}
      Object.keys(viewingState).forEach((id) => {
        const vid = id as TViewerId
        const vState = viewingState[vid]
        const map = vState?.map
        if (vState === undefined || map === undefined) return
        params[vid] = {
          center: map.getView().getCenter() || [0, 0],
          rotation: map.getView().getRotation(),
          zoom: map.getView().getResolution() || 1,
        }
      })
      return params
    })(),
  )
  const _setPrevParams = (value: ICurrentMapsParams) => (prevParams.current = value)

  const bus = useEventBusProvided()
  const { openViewerIds } = useOpenViewers()

  const setMapCenter = (initialVid: TViewerId, vidsToChange: TViewerId[]) => {
    const refMap = viewingState[initialVid]?.map
    const prevInitialParams = prevParams.current[initialVid]
    const initialCenter = refMap?.getView().getCenter()
    if (
      refMap === undefined ||
      initialCenter === undefined ||
      prevInitialParams === undefined ||
      _.isEqual(initialCenter, prevInitialParams.center)
    )
      return
    const initialZoom = refMap.getView().getResolution()
    const initialRotation = refMap.getView().getRotation()
    vidsToChange.forEach((vidToChange) => {
      const mapToChange = viewingState[vidToChange]?.map
      const center = mapToChange?.getView().getCenter()
      if (mapToChange === undefined || center === undefined) return
      const zoom = mapToChange.getView().getResolution()
      const rotation = mapToChange.getView().getRotation()
      const angle = fromRadiansToDegrees(rotation - initialRotation)
      const delta = [initialCenter[0] - prevInitialParams.center[0], initialCenter[1] - prevInitialParams.center[1]]
      const scale = (initialZoom || 1) / (zoom || 1)
      const rotatedDelta = rotateCoordinate(delta, angle, [0, 0], 1 / scale)
      const finalCenter = [center[0] + rotatedDelta[0], center[1] + rotatedDelta[1]]
      mapToChange.getView().setCenter(finalCenter)
      _setPrevParams({
        ...prevParams.current,
        [vidToChange]: {
          ...prevParams.current[vidToChange],
          center: finalCenter,
        },
      })
    })
    _setPrevParams({
      ...prevParams.current,
      [initialVid]: {
        ...prevParams.current[initialVid],
        center: initialCenter,
      },
    })
  }
  const setMapZoom = (initialVid: TViewerId, vidsToChange: TViewerId[]) => {
    const refMap = viewingState[initialVid]?.map
    const prevInitialParams = prevParams.current[initialVid]
    const oldInitialZoom = prevInitialParams?.zoom
    const initialZoom = refMap?.getView().getResolution()
    if (
      refMap === undefined ||
      prevInitialParams === undefined ||
      initialZoom === undefined ||
      oldInitialZoom === undefined
    )
      return
    vidsToChange.forEach((vidToChange: TViewerId) => {
      const mapToChange = viewingState[vidToChange]?.map
      const zoom = mapToChange?.getView().getResolution()
      if (zoom === undefined || mapToChange === undefined) return
      const scale = initialZoom / oldInitialZoom
      const finalZoom = zoom * scale
      mapToChange.getView().setResolution(finalZoom)
      _setPrevParams({
        ...prevParams.current,
        [vidToChange]: {
          ...prevParams.current[vidToChange],
          zoom: finalZoom,
        },
      })
    })
    _setPrevParams({
      ...prevParams.current,
      [initialVid]: {
        ...prevParams.current[initialVid],
        zoom: initialZoom,
      },
    })
  }
  const setMapRotation = (initialVid: TViewerId, vidsToChange: TViewerId[]) => {
    const refMap = viewingState[initialVid]?.map
    const prevInitialParams = prevParams.current[initialVid]
    if (refMap === undefined || prevInitialParams === undefined) return
    const initialRotation = refMap.getView().getRotation()
    const oldInitialRotation = prevInitialParams.rotation
    const delta = initialRotation - oldInitialRotation
    vidsToChange.forEach((vidToChange: TViewerId) => {
      const mapToChange = viewingState[vidToChange]?.map
      if (mapToChange === undefined) return
      const rotation = mapToChange.getView().getRotation()
      const finalRotation = rotation + delta
      mapToChange.getView().setRotation(finalRotation)
      _setPrevParams({
        ...prevParams.current,
        [vidToChange]: {
          ...prevParams.current[vidToChange],
          rotation: finalRotation,
        },
      })
    })
    _setPrevParams({
      ...prevParams.current,
      [initialVid]: {
        ...prevParams.current[initialVid],
        rotation: initialRotation,
      },
    })
  }

  const onDrag = (initialVid: TViewerId) => {
    const vidsToChange = openViewerIds.filter((id) => id !== initialVid)
    setMapCenter(initialVid, vidsToChange)
  }
  const onZoom = (initialVid: TViewerId) => {
    const vidsToChange = openViewerIds.filter((id) => id !== initialVid)
    setMapZoom(initialVid, vidsToChange)
    setMapCenter(initialVid, vidsToChange)
  }
  const onRotate = (initialVid: TViewerId) => {
    const vidsToChange = openViewerIds.filter((id) => id !== initialVid)
    setMapRotation(initialVid, vidsToChange)
  }

  useEffect(() => {
    bus.$addEventListener('afterMapCenter', onDrag)
    bus.$addEventListener('afterMapZoom', onZoom)
    bus.$addEventListener('afterMapRotation', onRotate)
    return () => {
      bus.$removeEventListener('afterMapCenter', onDrag)
      bus.$removeEventListener('afterMapZoom', onZoom)
      bus.$removeEventListener('afterMapRotation', onRotate)
    }
  }, [bus, corViewingState])

  /**
   * Отслеживаем изменения в подгружаемых affine.
   * Сам алгоритм подгрузки прячется в провайдере. Тут мы ориентируемся по изменению loading
   **/
  useEffect(() => {
    if (prevCorViewingState === undefined) return
    const corKeys = Object.keys(corViewingState)
    corKeys.forEach((key) => {
      const vid = key as TViewerId
      const state = corViewingState[vid]
      const prevState = prevCorViewingState[vid]
      if (state === undefined || prevState === undefined) return
      if (prevState.loading === true && state.loading === false) {
        computeRestViewerCenterByAffine(vid, (newCenter) => {
          _setPrevParams({
            ...prevParams.current,
            [vid]: {
              ...prevParams.current[vid],
              center: newCenter,
            },
          })
        })
        computeRestViewerZoomByAffine(vid, (newZoom) => {
          _setPrevParams({
            ...prevParams.current,
            [vid]: {
              ...prevParams.current[vid],
              zoom: newZoom,
            },
          })
        })
        computeRestViewerRotationByAffine(vid, (newRotation) => {
          _setPrevParams({
            ...prevParams.current,
            [vid]: {
              ...prevParams.current[vid],
              rotation: newRotation,
            },
          })
        })
      }
    })
  }, [corViewingState])
  return null
}
