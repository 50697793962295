import { Divider } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContext } from 'shared/lib/map'
import ToolOpacityController from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components'
import { ARTEFACT_CLASS_COLOR, ARTEFACT_CLASS_NAME, ArtefactClasses } from 'types/IArtefact'
import { useArtefactsContext } from 'viewer/map/layers/artefacts/ArtefactsContext'
import { getCenterOfExtent } from 'viewer/map/lib/utils'

import ArtefactsClassElement, { ArtefactsSumElement } from './ArtefactsClassElement'

const StyledText = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 11px;
  text-align: right;
  color: var(--color-text-3);
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  width: 100%;
`

/** Продолжительность анимации в милисекундах */
const ANIMATION_DURATION = 250

const ArtefactsContainer: React.FC = () => {
  const { t } = useTranslation()
  const { map } = useContext(MapContext)
  const { features, opacity, setHoveredClass, setOpacity } = useArtefactsContext()

  const onSelectElementHandle = (selectedClass: string) => {
    const feature = features.find((f) => f.get('class') === selectedClass)
    const geometry = feature?.getGeometry()
    if (geometry) {
      map.getView().fit(geometry.getExtent(), {
        duration: ANIMATION_DURATION,
      })
      map.getView().animate({
        center: getCenterOfExtent(geometry.getExtent()),
        duration: ANIMATION_DURATION,
      })
    }
  }
  return (
    <Wrapper>
      <StyledText>{t('Площадь пересечения с тканью, мм²')}</StyledText>
      <div>
        {features.map((it) =>
          it.get('class') === ArtefactClasses.artefact_union ? (
            <ArtefactsSumElement key={it.get('class')} totalArea={it.get('area_tissue')} />
          ) : (
            <ArtefactsClassElement
              key={it.get('class')}
              area={it.get('area_tissue') || 0}
              color={ARTEFACT_CLASS_COLOR[it.get('class') as ArtefactClasses]}
              title={t(ARTEFACT_CLASS_NAME[it.get('class') as ArtefactClasses])}
              className={it.get('class')}
              onMouseEnterHandle={setHoveredClass}
              onMouseLeaveHandle={setHoveredClass}
              onClickHandle={onSelectElementHandle}
            />
          ),
        )}
      </div>
      <Divider />
      <ToolOpacityController value={opacity} onChange={setOpacity} />
    </Wrapper>
  )
}

export default ArtefactsContainer
