import { push } from 'connected-react-router'
import { notices } from 'features/notices'
import { TFunction } from 'i18next'
import { Dispatch } from 'react'
import { getInviteTokensFromStorage, removeInviteTokenFromStorage } from 'shared/lib/local-storage'

export const checkInvites = (dispatch: Dispatch<any>, t: TFunction) => {
  const invites = getInviteTokensFromStorage()
  if (invites.length) {
    invites.map(async (invite) => {
      try {
        dispatch(push(`/invite/${invite}`))
      } catch (error) {
        notices.error({
          message: t('Пожалуйста, авторизуйтесь для просмотра случая.'),
        })
      } finally {
        removeInviteTokenFromStorage(invite)
      }
    })
  }
}
