import { Layout } from 'antd'
import { modalShadow } from 'app/styled/GlobalStyles'
import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'

const { Content, Header, Sider } = Layout

const StyledHeader = styled(Header)`
  background-color: var(--color-bg-2);
  height: auto;
  padding: 0;
  border-bottom: 1px solid var(--color-border-1);
`

const StyledContent = styled(Content)`
  overflow-y: hidden;
  background-color: var(--color-bg-2);
  overflow-x: auto;
`

const StyledLayout = styled(Layout)`
  height: 100%;
  width: 100%;
`

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
`

const NotificationPanel = styled.div`
  position: absolute;
  right: 0;
  height: 94%;
  width: 264px !important;
  background-color: var(--color-bg-2);
  z-index: 99;

  ${() => modalShadow}
`

type Props = {
  header?: ReactNode
  rightPanel?: ReactNode
}

const ContentLayout: FC<Props> = ({ children, header, rightPanel }) => (
  <ContentContainer>
    <StyledLayout>
      {header && <StyledHeader>{header}</StyledHeader>}
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
    {rightPanel && <NotificationPanel>{rightPanel}</NotificationPanel>}
  </ContentContainer>
)

export default ContentLayout
