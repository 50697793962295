import Checkbox, { CheckboxProps } from 'antd/es/checkbox'
import styled from 'styled-components/macro'

const StyledCheckboxElement = styled(Checkbox)`
  & {
    line-height: 16px;
    align-items: center;
  }

  .ant-checkbox-inner {
    background-color: var(--color-bg-4);
    box-shadow: none;
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-purple);
    border: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(0.75) translate(-90%, -60%);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: var(--color-purple);
    border: none;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    height: 2px;
    background-color: #ffffff;
  }

  .ant-checkbox + span {
    padding-left: 4px;
    line-height: 16px;
    user-select: none;
    color: var(--color-text-1);
  }

  .ant-checkbox {
    display: flex;
    top: 0;
  }
`

export const CheckboxElement = (props: CheckboxProps) => <StyledCheckboxElement {...props} />
