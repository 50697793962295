import { Modal, ModalProps } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { FC, forwardRef, useImperativeHandle, useState } from 'react'
import { IconElement } from 'shared/ui/kit/ui/IconElement'
import styled from 'styled-components/macro'

type Props = ModalProps
const StyledModal = styled(Modal)`
  width: min-content !important;

  .ant-modal-content {
    border-radius: 5px;
    ${() => modalBackground}
    ${() => modalShadow}

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-header {
      background: transparent;
      padding: 16px 16px 10px 16px;
    }

    .ant-modal-title {
      color: var(--color-text-1);
    }

    .ant-modal-close {
      color: var(--color-text-3);
      top: 14px;
      right: 12px;
    }

    .ant-modal-close-x {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }

    .ant-modal-close:hover {
      color: var(--color-text-1);
    }
  }
`

export const ModalComponent: FC<Props> = (props) => (
  <StyledModal closeIcon={<IconElement name="cross" size="lg" />} {...props} />
)

export type ModalHandle = {
  open: () => void
  close: () => void
  toggle: () => void
  isOpen: boolean
}

export const ModalProvider = forwardRef<ModalHandle, ModalProps>((props, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  useImperativeHandle(
    ref,
    () => ({
      close: () => setIsOpen(false),
      isOpen,
      open: () => setIsOpen(true),
      toggle: () => setIsOpen((_) => !_),
    }),
    [],
  )
  return (
    <ModalComponent visible={isOpen} {...props}>
      {props.children}
    </ModalComponent>
  )
})
