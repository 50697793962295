import { useTypedSelector } from 'app/redux/lib/selector'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { selectTasksViewerUrlTaskId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useOS } from 'shared/lib/hooks'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { TopBorder } from 'shared/ui/divider'
import { ModalComponent } from 'shared/ui/modal'
import styled from 'styled-components'
import { SettingsNameEnum } from 'types/IWorkspaces'

import { HotkeyRow } from './HotkeyRow'

type Props = {
  visible: boolean
}

const StyledModal = styled(ModalComponent)`
  .ant-modal-body {
    width: 864px;
    max-height: calc(100vh - 128px);
    overflow-y: auto;
  }

  & .ant-modal-content .ant-modal-body {
    padding: 0px 16px 16px 16px;
  }
`

const HotkeyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  margin-top: 16px;
`

const HotkeyColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const HotkeyBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const HotkeyHeader = styled.div`
  padding: 6px 0 8px 4px;
  border-bottom: 1px solid var(--color-border-1);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-3);
`

export const HotkeysModal = ({ visible }: Props) => {
  const theme = useThemeContext()
  const { t } = useTranslation()
  const MouseIcon = (mouse?: string) => {
    if (!mouse) return
    else if (theme.theme === 'dark') return mouse
    else if (theme.theme === 'light') return mouse + 'Light'
  }
  const dispatch = useDispatch()
  const onCancel = () => dispatch(viewerPageSlice.actions.setHotkeysModal(false))
  const ctrl = useOS() === 'MacOS' ? 'Cmd' : 'Ctrl'
  const hotKeys = {
    annotation: [
      {
        icon: 'ruler',
        key1: 'L',
        title: t('Линейка'),
      },
      {
        icon: 'drawArrow',
        key1: 'V',
        title: t('Стрелка'),
      },
      {
        icon: 'drawPen',
        key1: 'Y',
        title: t('Рисование'),
      },
      {
        icon: 'dot',
        key1: 'D',
        title: t('Точка'),
      },
      {
        icon: 'rectangle',
        key1: 'R',
        title: t('Прямоугольник'),
      },
      {
        icon: 'circle',
        key1: 'O',
        title: t('Круг'),
      },
      {
        icon: 'pen',
        key1: 'P',
        title: t('Полигон'),
      },
      {
        icon: 'objects',
        key1: 'B',
        title: t('Ручной подсчёт клеток'),
      },
      {
        key1: ctrl,
        key2: 'Z',
        text1: '+',
        title: t('Отмена последнего действия'),
      },
      {
        key1: ctrl,
        key2: 'C',
        text1: '+',
        title: t('Скопировать аннотацию'),
      },
      {
        key1: ctrl,
        key2: 'V',
        text1: '+',
        title: t('Вставить аннотацию'),
      },
      {
        key1: ctrl,
        mouse: 'mouseLeft',
        text1: '+',
        title: t('Выделить несколько аннотаций'),
      },
      {
        key1: 'H',
        title: t('Скрыть / показать аннотации'),
      },
      {
        key1: 'J',
        title: `${t('Выставить / убрать прозрачность')} 100%`,
      },
      {
        mouse: 'mouseRight',
        title: t('Отмена последней нарисованной точки'),
      },
    ],
    display: [
      {
        icon: 'map',
        key1: 'N',
        title: t('Обзор слайда'),
      },
      {
        icon: 'label',
        key1: 'E',
        title: t('Этикетка'),
      },
      {
        icon: 'info',
        key1: 'U',
        title: t('Информация о слайде'),
      },
      {
        icon: 'imageList',
        key1: 'C',
        title: t('Слайды'),
      },
      {
        icon: 'searchAtlas',
        key1: 'F',
        title: t('Поиск по морфологии'),
      },
      {
        icon: 'Case',
        key1: 'I',
        title: t('Информация о случае'),
      },
      {
        icon: 'annotationsList',
        key1: 'A',
        title: t('Аннотации'),
      },
      {
        icon: 'feedBack',
        key1: 'K',
        title: t('Комментарии'),
      },
      {
        icon: 'colorCorrection',
        key1: 'G',
        title: t('Цветокоррекция'),
      },
      {
        icon: 'toolsAI',
        key1: 'T',
        title: t('Инструменты'),
      },
      {
        icon: 'printScreen',
        key1: 'S',
        title: t('Снимок экрана'),
      },
      {
        icon: 'fullScreen',
        key1: 'M',
        title: t('Полноэкранный режим'),
      },
    ],
    move: [
      {
        key1: '← / → / ↑ / ↓',
        title: t('Перемещение по слайду'),
        //keyContainer
      },
      {
        key1: 'Shift',
        key2: '← / → / ↑ / ↓',
        text1: '+',
        title: t('Быстрое перемещение'),
      },
      {
        key1: 'F2',
        mouse: 'mouseCenter',
        text1: t('или'),
        title: t('Режим захвата курсора'),
      },
      {
        key1: 'Z',
        title: t('Предыдущий слайд'),
      },
      {
        key1: 'X',
        title: t('Следующий слайд'),
      },
    ],
    rotate: [
      {
        key1: ctrl,
        key2: 'R / L',
        text1: '+',
        title: `${t('Вращение на')} 1°`,
      },
      {
        key1: 'Shift',
        key2: ctrl,
        key3: 'R / L',
        text1: '+',
        text2: '+',
        title: `${t('Вращение на')} 90°`,
      },
      {
        key1: 'Shift',
        key2: 'Q',
        text1: '+',
        title: `${t('Сбросить вращение на')} 0°`,
      },
      {
        key1: 'Alt',
        key2: 'Shift',
        mouse: 'mouseLeft',
        text1: '+',
        text2: '+',
        title: t('Вращение слайда мышью'),
      },
    ],
    viewNavigator: [
      {
        key1: 'Tab',
        title: t('Переключение активного окна'),
      },
      {
        key1: 'W',
        title: t('Автоматическая корегистрация окон'),
      },
      {
        key1: 'Q',
        title: t('Ручная корегистрация окон'),
      },
    ],
    zoom: [
      {
        key1: '+ / -',
        mouse: 'mouseCenter',
        text1: t('или'),
        title: t('Масштабирование слайда'),
      },
      {
        key1: 'Shift',
        key2: '0',
        text1: '+',
        title: t('Вписать весь слайд в экран'),
      },
      {
        key1: 'Shift',
        key2: '1',
        text1: '+',
        title: '2×',
      },
      {
        key1: 'Shift',
        key2: '2',
        text1: '+',
        title: '5×',
      },
      {
        key1: 'Shift',
        key2: '3',
        text1: '+',
        title: '10×',
      },
      {
        key1: 'Shift',
        key2: '4',
        text1: '+',
        title: '20×',
      },
      {
        key1: 'Shift',
        key2: '5',
        text1: '+',
        title: '40×',
      },
      {
        key1: 'Shift',
        key2: '6',
        text1: '+',
        title: '80×',
      },
      {
        key1: 'Shift',
        key2: '7',
        text1: '+',
        title: '1:1',
      },
      {
        mouse: 'mouseLeft',
        text1: '2 ×',
        title: t('Быстрое приближение'),
      },
    ],
  }

  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  /** Флаг, указывающий на таск-вьювер. */
  const isTaskViewer = !!useTypedSelector(selectTasksViewerUrlTaskId)
  /** Массив с Title инструментов, которые нужно скрыть из модалки хоткеев в таск-вьювере */
  const taskViewerHiddenToolsTitles = [t('Стрелка'), t('Ручной подсчёт клеток')]
  /**  Отфильтрованный список хоткеев для инструментов в зависимости от вьювера. */
  const filteredAnnotationHotkeys = isTaskViewer
    ? hotKeys.annotation.filter((hotkey) => !taskViewerHiddenToolsTitles.includes(hotkey.title))
    : hotKeys.annotation
  /** Отфильтрованный список хоткеев в зависимости включена ли корегистрация на WS или находимся в таск-вьювере */
  const filteredViewNavigator =
    isTaskViewer || !getBooleanOrDefaultAccess(SettingsNameEnum.EnableAiSlideCoreg, true, currentWorkspace)
      ? [hotKeys.viewNavigator[0]]
      : hotKeys.viewNavigator

  return (
    <StyledModal title={t('Горячие клавиши')} footer={null} visible={visible} onCancel={onCancel} centered={true}>
      <TopBorder />
      <HotkeyWrapper>
        <HotkeyColumn>
          <HotkeyBlock>
            <HotkeyHeader>{t('Отображение')}</HotkeyHeader>
            {hotKeys.display.map((hotKey, index) => (
              <HotkeyRow key={index} icon={hotKey.icon} title={hotKey.title} key1={hotKey.key1} />
            ))}
          </HotkeyBlock>
          <HotkeyBlock>
            <HotkeyHeader>{t('Аннотирование')}</HotkeyHeader>
            {filteredAnnotationHotkeys.map((hotKey, index) => (
              <HotkeyRow
                key={index}
                icon={hotKey.icon}
                title={hotKey.title}
                key1={hotKey.key1}
                text1={hotKey.text1}
                key2={hotKey.key2}
                mouse={MouseIcon(hotKey.mouse)}
              />
            ))}
          </HotkeyBlock>
        </HotkeyColumn>
        <HotkeyColumn>
          <HotkeyBlock>
            <HotkeyHeader>{t('Перемещение')}</HotkeyHeader>
            {hotKeys.move.map((hotKey, index) => (
              <HotkeyRow
                key={index}
                title={hotKey.title}
                key1={hotKey.key1}
                text1={hotKey.text1}
                key2={hotKey.key2}
                mouse={MouseIcon(hotKey.mouse)}
              />
            ))}
          </HotkeyBlock>
          <HotkeyBlock>
            <HotkeyHeader>{t('Вращение')}</HotkeyHeader>
            {hotKeys.rotate.map((hotKey, index) => (
              <HotkeyRow
                key={index}
                title={hotKey.title}
                key1={hotKey.key1}
                text1={hotKey.text1}
                key2={hotKey.key2}
                text2={hotKey.text2}
                key3={hotKey.key3}
                mouse={MouseIcon(hotKey.mouse)}
              />
            ))}
          </HotkeyBlock>
          <HotkeyBlock>
            <HotkeyHeader>{t('Масштабирование')}</HotkeyHeader>
            {hotKeys.zoom.map((hotKey, index) => (
              <HotkeyRow
                key={index}
                title={hotKey.title}
                key1={hotKey.key1}
                text1={hotKey.text1}
                key2={hotKey.key2}
                mouse={MouseIcon(hotKey.mouse)}
              />
            ))}
          </HotkeyBlock>
          <HotkeyBlock>
            <HotkeyHeader>{t('Навигация между картами')}</HotkeyHeader>
            {filteredViewNavigator.map((hotKey, index) => (
              <HotkeyRow key={index} title={hotKey.title} key1={hotKey.key1} />
            ))}
          </HotkeyBlock>
        </HotkeyColumn>
      </HotkeyWrapper>
    </StyledModal>
  )
}
