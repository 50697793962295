import { Feature } from 'ol'
import React, { useContext, useMemo } from 'react'
import { MapContext } from 'shared/lib/map'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import ISlide from 'types/ISlide'
import { createThumbnailLink } from 'viewer/container'
import { createBboxForPoint, Point2D } from 'viewer/map/layers/objects-counting/lib/helpers/helpers'
import {
  SCREEN_RECORD_ZOOM_LVL,
  useObjectsCountingContext,
} from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

import ObjectsItem from './ObjectsItem'

type Props = {
  point: Feature<any>
  slide?: ISlide
}

const ObjectsItemContainer: React.FC<Props> = ({ point, slide, ...props }) => {
  const { map } = useContext(MapContext)
  const { removeMitos } = useObjectsCountingContext()
  const preview = useMemo(() => {
    if (!slide) return ''
    const center = point.getGeometry()?.getCoordinates()
    if (!center) return ''
    const bbox = createBboxForPoint(center as Point2D, SCREEN_RECORD_ZOOM_LVL)
    return createThumbnailLink(slide!.iiif2Url, bbox, false, slide?.iiif2AuthToken)
  }, [])

  const onRemove = () => {
    removeMitos(point.get('id'))
  }

  const onGoToMitos = () => {
    const resolution = getResolutionByZoomLevel(SCREEN_RECORD_ZOOM_LVL, slide)
    const center = point?.getGeometry()?.getCoordinates()

    map.getView().animate({
      center,
      duration: 500,
      resolution,
    })
  }

  return (
    <ObjectsItem preview={preview} isHidden={point.get('isHidden')} onRemove={onRemove} onGoToMitos={onGoToMitos} />
  )
}

export default ObjectsItemContainer
