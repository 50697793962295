import { useTypedSelector } from 'app/redux/lib/selector'
import { searchSlice } from 'features/search/model/searchSlice'
import { StompClientContext, useSubscription } from 'processes/stomp'
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ISimilarSlide } from 'types/ISimilarRegion'
import ISource from 'types/ISource'
import { v4 as uuidv4 } from 'uuid'

export const SEARCHERS_COUNT = 2
export type WsResponseSimilarSlides = {
  opId: string // '5fd32f83-899c-4cab-9cb2-be64edc83e53'
  payload: ISimilarSlide[]
  source: ISource
  success: boolean
  timestamp: number
  type: 'SearchSimilarSlideResultMessage'
}

export const SimilarSlidesContext = createContext<{
  similarSlides?: ISimilarSlide[]
  isLoading: boolean
  searcher: number
  totalSearchers: number
  cancelSearch: () => void
}>({
  cancelSearch: () => {},
  isLoading: false,
  searcher: 0,
  similarSlides: [],
  totalSearchers: SEARCHERS_COUNT,
})

const SimilarSlidesProvider: React.FC = ({ children }) => {
  const mainSelectedSimilarRegion = useTypedSelector((state) => state.search.mainSelectedSimilarRegion)
  const [similarSlides, setSimilarSlides] = useState<ISimilarSlide[]>()
  const [isLoading, setLoading] = useState(false)
  const [searcher, setSearcher] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    if (searcher === SEARCHERS_COUNT) {
      setLoading(false)
      setSearcher(0)
    }
  }, [searcher])

  const cancelSearch = () => {
    dispatch(searchSlice.actions.setMainSelectedSimilarRegion(undefined))
    setSearcher(0)
    setLoading(false)
  }
  return (
    <SimilarSlidesContext.Provider
      value={{ cancelSearch, isLoading, searcher, similarSlides, totalSearchers: SEARCHERS_COUNT }}
    >
      {children}
      {mainSelectedSimilarRegion && (
        <Subscription
          setLoading={setLoading}
          setSimilarSlides={setSimilarSlides}
          setSearcher={setSearcher}
          searcher={searcher}
        />
      )}
    </SimilarSlidesContext.Provider>
  )
}

type SubscriptionProps = {
  searcher: number
  setLoading: (flag: boolean) => void
  setSimilarSlides: Dispatch<SetStateAction<ISimilarSlide[] | undefined>>
  setSearcher: Dispatch<SetStateAction<number>>
}

function Subscription({ setLoading, setSearcher, setSimilarSlides }: SubscriptionProps) {
  const { isSearching, mainSelectedSimilarRegion } = useTypedSelector((state) => state.search)
  const { publish, unsubscribe } = useContext(StompClientContext)
  const opId = useMemo(() => uuidv4(), [mainSelectedSimilarRegion])
  const dispatch = useDispatch()

  /** send region to searcher */
  useEffect(() => {
    if (isSearching) {
      setLoading(true)
      setSimilarSlides(undefined)
      publish('/app/search-similar-region/' + opId, mainSelectedSimilarRegion)
      /** unsubscribe after 10 seconds */
      setTimeout(() => {
        unsubscribe('/user/topic/search-similar-region/' + opId)
        setSearcher(0)
      }, 10000)
      dispatch(searchSlice.actions.setIsSearching(false))
    }
    return () => {
      dispatch(searchSlice.actions.setIsSearching(false))
      /** This is used to display a spinner in the UI during image loading */
      setTimeout(() => setLoading(false), 1000)
    }
  }, [isSearching])

  useSubscription<WsResponseSimilarSlides>('/user/topic/search-similar-region/' + opId, (response) => {
    setSimilarSlides((prev) => {
      const newSlides = response.payload.map((slide) => ({ ...slide, source: response.source }))
      return [...(prev || []), ...newSlides]
    })
    setSearcher((prev) => prev + 1)
    setLoading(false)
  })

  return null
}

export default SimilarSlidesProvider
