import { IAnnotation } from 'types/IAnnotations'
import ICase from 'types/ICase'
import { ICommentDTO } from 'types/IComment'
import ICoregistration from 'types/ICoregistration'
import { IDziCache } from 'types/IDziCache'
import IHeatmap from 'types/IHeatmap'
import { IPatientDTO } from 'types/IPatient'

export enum CommentMessageEnum {
  Created = 'CommentCreatedMessage',
  Updated = 'CommentUpdatedMessage',
  Deleted = 'CommentDeletedMessage',
}

export type WsResponseComment =
  | {
      payload: ICommentDTO
      timestamp: number
      type: CommentMessageEnum.Created | CommentMessageEnum.Updated
    }
  | {
      payload: number
      timestamp: number
      type: CommentMessageEnum.Deleted
    }

export type WsResponseDziCache = {
  timestamp: number
  type: string
  payload: IDziCache
}

export type WsResponseCase = {
  payload: ICase
  timestamp: number
  type: 'CaseUpdatedMessage'
}

export type WsResponseKi67 = {
  timestamp: number
  payload: {
    cellsTotal: number
    cellsPositive: number
    cellsNegative: number
    cellsOther: number
    cellsRoisGeo: any
  }
  opId: string
  success: boolean
  type: string
}

export type WsResponseMitosis = {
  timestamp: number
  payload: {
    cellsTotal: number
    cellsHardMitosis: number
    cellsSoftMitosis: number
    cellsRoisGeo: any
  }
  opId: string
  success: boolean
  type: string
}

export type WsResponseIHeatmap = {
  payload: IHeatmap
  timestamp: number
  type: string
}

export type WsResponseCoregistration = {
  timestamp: number
  payload: ICoregistration
  opId: string
  success: boolean
  type: string
}

export type WsResponsePatientUser = {
  opId: string
  payload: IPatientDTO[]
  success: boolean
  timestamp: number
  type: string
}

export type WsResponseCasesList = {
  payload: ICase[]
  timestamp: number
  type: 'AutocompleteCaseMessageResult'
}

export type WsResponseAnnotation =
  | {
      payload: IAnnotation
      timestamp: number
      type: 'AnnotationCreatedMessage' | 'AnnotationUpdatedMessage'
    }
  | {
      payload: number
      timestamp: number
      type: 'AnnotationDeletedMessage'
    }

export type WsResponseSumpSearchList = {
  type: 'AutocompleteUploadedFileMessageResult'
  timestamp: number
  payload: string[]
}
