import { RefObject, useCallback, useEffect, useState } from 'react'

type Payload = {
  anchor: RefObject<HTMLDivElement>
  isLastPage: boolean
  fetchMore: () => Promise<void>
  itemsLength: number
}

export const useInfiniteScroll = ({ anchor, fetchMore, isLastPage, itemsLength }: Payload) => {
  const [isAnchorHidden, hideAnchor] = useState(false)

  const loadMore = useCallback(
    async (entries) => {
      const target = entries[0]
      if (target.isIntersecting && !isLastPage) {
        hideAnchor(true)
        await fetchMore()
        hideAnchor(false)
      }
    },
    [fetchMore, isLastPage],
  )

  useEffect(() => {
    hideAnchor(false)
  }, [isLastPage])

  useEffect(() => {
    if (isLastPage) {
      return () => {
        hideAnchor(true)
      }
    }
    const options = {
      root: null, // window by default
      rootMargin: '0px',
      threshold: 0.25,
    }
    const anchorRef = anchor?.current
    // Create observer
    const observer = new IntersectionObserver(loadMore, options)

    // observer the loader
    if (anchorRef) {
      observer.observe(anchorRef)
    }
    // clean up on willUnMount
    return () => {
      if (anchorRef) {
        observer.unobserve(anchorRef)
      }
    }
  }, [anchor, isAnchorHidden, loadMore, isLastPage, itemsLength]) // depends on isAnchorHidden don't remove!!

  return isAnchorHidden
}
