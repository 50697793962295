import { Divider } from 'antd'
import { useRef } from 'react'
import { formatDateTimeForDisplay } from 'shared/lib/date'
import { ParagraphElement, SpaceElement, TextElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import INote from 'types/INotes'

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas: 'fullname actions' 'time edited';
  column-gap: 8px;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  & > * {
    visibility: hidden;
  }
`

const StyledComment = styled.div`
  margin: 8px 16px;
  &:hover {
    ${Actions} {
      & > * {
        visibility: visible;
      }
    }
  }
`

type Props = {
  note: INote
}

const Note = ({ note }: Props) => {
  const commentRef = useRef<HTMLDivElement>(null)

  return (
    <StyledComment ref={commentRef}>
      <Header>
        <TitleElement type="secondary" level={4} style={{ gridArea: 'fullname' }} ellipsis>
          {note.user?.fullname || note.userExternalName}
        </TitleElement>
        <TextElement type="secondary" ellipsis style={{ gridArea: 'time' }}>
          {formatDateTimeForDisplay(new Date(note.createdAt))}
        </TextElement>
        <SpaceElement size={4} />
      </Header>
      <div>
        <ParagraphElement>{note.body}</ParagraphElement>
      </div>
      <Divider />
    </StyledComment>
  )
}

export default Note
