import * as Sentry from '@sentry/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Center, TextElement } from 'shared/ui/kit'

export const SentryErrorBoundary: React.FC = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Sentry.ErrorBoundary
      fallback={
        <Center style={{ height: `90vh` }}>
          <TextElement>{t('Произошла ошибка. Мы уже работаем над её исправлением')}</TextElement>
        </Center>
      }
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
