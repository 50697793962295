import { selectAtlasViewerUrlSlideId, selectTasksViewerUrlTaskId } from 'pages/viewer'
import { useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { DatalayerStatus, ISlideDataLayer } from 'types/ISlideDataLayer'

import { SlideDataLayerGeoJSONParams, SlideDataLayersParams, slideDataLayersService } from './servie'

export const useSlideDataLayers = ({ caseId, slideId }: SlideDataLayersParams) => {
  const queryClient = useQueryClient()
  const isTaskViewer = !!useSelector(selectTasksViewerUrlTaskId)
  const isAtlasViewer = !!useSelector(selectAtlasViewerUrlSlideId)

  return useQuery([QUERY_TYPE.SLIDE_DATA_LAYERS, { slideId }], async (data) => {
    if (!data || isTaskViewer || isAtlasViewer) return
    const result = await slideDataLayersService.fetchSlideDataLayers({ caseId, slideId })
    result.forEach((dataLayer) =>
      queryClient.setQueryData([QUERY_TYPE.SLIDE_DATA_LAYERS, dataLayer.slideDataLayerId], dataLayer),
    )
    return result
  })
}

export const useCurrentDataLayer = ({ caseId, slideDataLayerId, slideId }: SlideDataLayerGeoJSONParams) => {
  const queryClient = useQueryClient()
  const layer = queryClient.getQueryData<ISlideDataLayer>([QUERY_TYPE.SLIDE_DATA_LAYERS, slideDataLayerId])

  return useQuery([QUERY_TYPE.DATA_LAYER_GEO_JSON, { slideDataLayerId: slideDataLayerId }], async (data) => {
    if (!slideDataLayerId || layer?.inProgress || layer?.emptyResult || layer?.status === DatalayerStatus.FAILED) return

    const result = await slideDataLayersService.fetchSlideDataLayerGeoJSON({
      caseId,
      slideDataLayerId,
      slideId,
    })
    queryClient.setQueryData([QUERY_TYPE.DATA_LAYER_GEO_JSON, slideDataLayerId], result)
    return result
  })
}
