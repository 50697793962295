import { ReactComponent as NoSlide } from 'assets/thumbnails/noSlide.svg'
import { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

const NoSlideWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: var(--color-bg-2);
  object-fit: contain;
`

export const SkeletonImageElement = (props: HTMLAttributes<any>) => (
  <NoSlideWrapper {...props}>
    <NoSlide />
  </NoSlideWrapper>
)
