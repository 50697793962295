import { useDispatch } from 'react-redux'
import { VIEWER_ID_PLACEHOLDER } from 'shared/lib/viewer'
import TViewerId from 'types/TViewerId'

const useViewerDispatch = (id: TViewerId) => {
  const dispatch = useDispatch()
  return (action: any) => {
    const customAction = action
    customAction.type = action.type.replace(VIEWER_ID_PLACEHOLDER, id)
    dispatch(customAction)
  }
}
export default useViewerDispatch
