import { useCasesRecordsQuery } from 'features/cases'
import { dashboardMockCases } from 'features/dashboard/data/dashboardMockCases'
import { useTranslation } from 'react-i18next'
import { createCapitalString } from 'shared/lib/metadata'
import IPatient from 'types/IPatient'

const useGetPatientsMap = () => {
  const cases = useCasesRecordsQuery({ relation: 'owner', status: 'open' })
  const patientsMap: Record<string, IPatient> = {}
  const casesWithFake = [...cases, ...dashboardMockCases]
  const { t } = useTranslation()
  casesWithFake.forEach((caseRecord) => {
    const patient = caseRecord.patient
    if (patient?.fullname) {
      const pat = patientsMap[patient.fullname] || patient
      const diagnosticTypes = pat.diagnosticTypes || []
      const siteName =
        caseRecord.casePathologicalInfo.sites && caseRecord.casePathologicalInfo.sites.length > 0
          ? caseRecord.casePathologicalInfo.sites[0]?.site?.name
          : t('не указано')
      patientsMap[patient.fullname] = {
        ...pat,
        diagnosticTypes: caseRecord.casePathologicalInfo.diagnosticProcedureType
          ? [...diagnosticTypes, caseRecord.casePathologicalInfo.diagnosticProcedureType.name as any]
          : diagnosticTypes,
        siteName: createCapitalString(siteName || ''),
      }
    }
  })
  return patientsMap
}

export default useGetPatientsMap
