import { Switch } from 'antd'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { memo } from 'react'
import styled from 'styled-components'

import { IconElement } from './IconElement'

const StyledSwitch = styled(Switch)`
  height: 24px;
  background: var(--color-yellow);
  ::after {
    border: 2px solid red;
  }
  &.ant-switch-checked {
    background: var(--color-purple);
  }
  &.ant-switch-checked .ant-switch-inner {
    margin: 0 22px 0 0px;
  }
  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 4px);
  }
  .ant-switch-inner {
    margin: 0 0 0 22px;
  }
  .ant-switch-handle {
    width: 20px;
    height: 20px;
  }
  .ant-switch-handle::before {
    background: var(--color-bg-2);
    border-radius: 10px;
  }
  &.ant-switch-checked:focus,
  &.ant-switch:focus {
    box-shadow: none;
  }
`

export const ThemeSwitcher = memo(() => {
  const theme = useThemeContext()

  const toggleTheme = () => {
    const isDarkMode = theme.theme === 'dark'
    theme.setTheme(isDarkMode ? 'light' : 'dark')

    const mqStandAlone = '(display-mode: standalone)'

    /** Если запущено PWA, меняем цвет окна приложения */
    if (window.matchMedia(mqStandAlone).matches) {
      document.getElementById('theme-color-meta')?.setAttribute('content', isDarkMode ? 'white' : 'black')
    }
  }

  return (
    <StyledSwitch
      data-testid={'theme-switcher'}
      checkedChildren={<IconElement name="moon" />}
      unCheckedChildren={<IconElement name="sun" />}
      checked={theme.theme == 'dark'}
      onChange={toggleTheme}
    />
  )
})
