import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { StainSelectContainer, useMutateSlideStain } from 'entities/stains'
import { useMutateSlideBlock, useMutateSlideContainer } from 'entities/stains/api/query'
import { useCaseQuery } from 'features/cases/api/query'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { viewerPageSlice } from 'pages/viewer'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { NON_CONTEXT_ID } from 'shared/lib/map/ui/MapProvider'
import { COLON_WITH_SPACE, DOT_WITH_SPACE } from 'shared/text-constants'
import { InputElement, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { ICaseRelation } from 'types/ICase'
import { DictionaryItem } from 'types/IDictionary'
import ISource from 'types/ISource'
import IUserRole from 'types/IUserRole'
import TViewerId from 'types/TViewerId'
import { ELLIPSIS_PIXELS, PIXELS_FOR_SYMBOL } from 'viewer/tools/ui/lib/constans'
import { truncateStringWithEllipsis } from 'viewer/tools/ui/lib/utils'

const InfoContainer = styled.div`
  overflow: hidden;
`

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 11px;
  }
`
const InfoItemVertical = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`
const Label = styled.div`
  margin-right: 8px;
`
const TitleSlide = styled.div`
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  margin-right: 8px;
`
const Info = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
`

export type SlideInfoContainerProps = {
  /** viewerId - Идентификатор вьювера */
  viewerId: TViewerId
  /** slideId - Идентификатор слайда */
  slideId: number
  /** caseId - Идентификатор случая */
  caseId: number
  /** source - Идентификатор платформы */
  source: ISource
  /** backGroundSizes - Размеры фона */
  backGroundSizes?: number[]
  /** size - Размеры панели */
  size?: [number, number]
}

const SlideInfoContainer = ({ backGroundSizes, caseId, size, slideId, source }: SlideInfoContainerProps) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const authorities = useTypedSelector((state) => state.user.authorities)
  const { data: slide } = useSlideQuery({ slideId, source })
  const { data: caseRecord } = useCaseQuery({ caseId, source })
  const [width, height] = size || [0, 0]
  const { mutate: selectStain } = useMutateSlideStain(caseId, slideId, source)
  const { mutateAsync: selectBlock } = useMutateSlideBlock(caseId, slideId, source)
  const { mutateAsync: selectContainer } = useMutateSlideContainer(caseId, slideId, source)
  const { viewerType } = useViewerPageProvided()
  const { unsubscribeFromUser } = useUserStatusContext()
  const slideName = slide?.slideMetadata?.commonMetadata?.caption
  const isImageManager = authorities?.includes(IUserRole.ROLE_IMAGE_MANAGER)
  const [blockNumber, setBlockNumber] = useState<string>('')
  const [containerNumber, setContainerNumber] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    slide?.block ? setBlockNumber(slide?.block) : setBlockNumber('')
    slide?.container ? setContainerNumber(slide?.container) : setContainerNumber('')
  }, [slideId, slide])

  const toggleInputFocus = (payload: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(payload))

  const pxToMm = (value?: number) => {
    if (slide?.slideMetadata?.commonMetadata?.mppX && value)
      return Number(((value * slide?.slideMetadata?.commonMetadata?.mppX) / 1000).toFixed(2))
    return undefined
  }
  const getMPPX = () => {
    if (slide?.slideMetadata?.commonMetadata?.mppX)
      return Number((slide?.slideMetadata?.commonMetadata?.mppX).toString())
    return undefined
  }

  const objectivePower = slide?.slideMetadata?.commonMetadata?.objectivePower
  const adapterLensPower = slide?.slideMetadata?.commonMetadata?.adapterLensPower
  const slideStainName = slide?.stain?.name

  const updateSlide = async (stain?: DictionaryItem) => {
    unsubscribeFromUser()
    await selectStain(stain)
  }

  const updateBlock = async () => {
    unsubscribeFromUser()
    const payload = {
      block: blockNumber.trim(),
    }
    if (slide?.block === payload.block) return
    await setIsLoading(true)
    await selectBlock(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
        queryClient.invalidateQueries([QUERY_TYPE.SLIDE_GROUPED, caseId])
      },
    })
    await setIsLoading(false)
    await toggleInputFocus(false)
  }

  const updateContainer = async () => {
    unsubscribeFromUser()
    const payload = {
      container: containerNumber.trim(),
    }
    if (slide?.container === payload.container) return
    await setIsLoading(true)
    await selectContainer(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
        queryClient.invalidateQueries([QUERY_TYPE.SLIDE_GROUPED, caseId])
      },
    })
    await setIsLoading(false)
    await toggleInputFocus(false)
  }

  const charCount = Math.ceil(((backGroundSizes ? backGroundSizes[0] : 0) - ELLIPSIS_PIXELS) / PIXELS_FOR_SYMBOL)
  const ellipsisSlideName = truncateStringWithEllipsis(slideName, charCount)

  const STR_CONST = '×'

  const { t } = useTranslation()

  return (
    <InfoContainer
      data-testid={'info_container'}
      id={'info_container'}
      style={{
        height: backGroundSizes && backGroundSizes[1] ? backGroundSizes[1] : 'auto',
        width: backGroundSizes && backGroundSizes[0] ? backGroundSizes[0] : undefined,
      }}
    >
      <SpinElement spinning={isLoading}>
        {slideName && (
          <InfoItem>
            <TitleSlide>{ellipsisSlideName}</TitleSlide>
          </InfoItem>
        )}
        {slide?.groupType !== 'MACRO' &&
        isImageManager &&
        (caseRecord?.relation === ICaseRelation.OWNER || viewerType !== 'DEFAULT') ? (
          <InfoItemVertical>
            <Label style={{ marginBottom: 4 }}>{t('Окраска')}</Label>
            <StainSelectContainer
              onChange={updateSlide}
              value={slide?.stain}
              isDisabled={isLoading}
              onFocus={() => toggleInputFocus(true)}
              onBlur={() => toggleInputFocus(false)}
            />
          </InfoItemVertical>
        ) : (
          slideStainName && (
            <InfoItem>
              <Label>{t('Окраска')}</Label>
              <Info>{slide?.stain?.name}</Info>
            </InfoItem>
          )
        )}
        {viewerType === 'DEFAULT' &&
        isImageManager &&
        caseRecord?.relation === ICaseRelation.OWNER &&
        slide?.groupType !== 'MACRO' ? (
          <InfoItem>
            <InfoItemVertical data-testid="slide-info-container">
              <Label>{t('Контейнер')}</Label>
              <InputElement
                onBlur={updateContainer}
                onFocus={() => toggleInputFocus(true)}
                disabled={isLoading}
                onChange={(e) => setContainerNumber(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && updateContainer()}
                value={containerNumber}
                placeholder={t('Не указан')}
                id={NON_CONTEXT_ID}
              />
            </InfoItemVertical>
            <InfoItemVertical data-testid="slide-info-block">
              <Label>{t('Блок')}</Label>
              <InputElement
                onBlur={updateBlock}
                onFocus={() => toggleInputFocus(true)}
                disabled={isLoading}
                onChange={(e) => setBlockNumber(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && updateBlock()}
                value={blockNumber}
                placeholder={t('Не указан')}
                id={NON_CONTEXT_ID}
              />
            </InfoItemVertical>
          </InfoItem>
        ) : (
          <>
            {containerNumber && (
              <InfoItem>
                <Label>{t('Контейнер')}</Label>
                <Info>{containerNumber}</Info>
              </InfoItem>
            )}
            {blockNumber && (
              <InfoItem>
                <Label>{t('Блок')}</Label>
                <Info>{blockNumber}</Info>
              </InfoItem>
            )}
          </>
        )}
        {objectivePower && (
          <InfoItem>
            <Label>{t('Увеличение')}</Label>
            <Info>{objectivePower + STR_CONST}</Info>
          </InfoItem>
        )}
        {adapterLensPower && (
          <InfoItem>
            <Label>
              {t('Доп')}
              {DOT_WITH_SPACE}
              {t('линза')}
              {COLON_WITH_SPACE}
            </Label>
            <Info>{adapterLensPower + '×'}</Info>
          </InfoItem>
        )}
        <InfoItem>
          <Label>{t('Ширина')}</Label>
          <Info>
            {width.toLocaleString()} px {pxToMm(width) && `(${pxToMm(width)?.toLocaleString()} mm)`}
          </Info>
        </InfoItem>
        <InfoItem>
          <Label>{t('Высота')}</Label>
          <Info>
            {height.toLocaleString()} px {pxToMm(height) && `(${pxToMm(height)?.toLocaleString()} mm)`}
          </Info>
        </InfoItem>
        {getMPPX() && (
          <InfoItem>
            <Label>{t('Разрешение')}</Label>
            <Info> {getMPPX()?.toLocaleString()} μm/px</Info>
          </InfoItem>
        )}
      </SpinElement>
    </InfoContainer>
  )
}

export default SlideInfoContainer
