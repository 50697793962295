import { CaseName } from 'features/cases-management/ui/cases-table/CaseName'
import { TagItem } from 'features/cases-management/ui/cases-table/CasesTable'
import WarningPreview from 'features/new-preview-panel/WarningPreview'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'
import CaseLinkIcon from 'shared/ui/icons/CaseLinkIcon'
import { Column } from 'shared/ui/table'
import { ICaseTableProps, StyledCasesResTable } from 'shared/ui/table/ui/Table.styled'
import { ECaseLinkType, ECaseStageQuery, TCaseLinkDTO } from 'types/ICase'
import IUser from 'types/IUser'
import { IWorkspace } from 'types/IWorkspaces'

type Props = {
  /** Выбранные связанные случаи. */
  selectedRelated?: TCaseLinkDTO[]
  /** Комментарий к отказу. */
  declinementComment?: string
  /** Флаг загрузки данных. */
  isFetching: boolean
}

const RelatedCasesTabContent: FC<Props> = ({ declinementComment, isFetching, selectedRelated }) => {
  const { t } = useTranslation()

  return (
    <div>
      {declinementComment && <WarningPreview title={declinementComment} />}
      <div style={{ display: 'flex' }}>
        <StyledCasesResTable<FC<ICaseTableProps<TCaseLinkDTO>>>
          style={{ border: `none` }}
          tableLayout={'fixed'}
          dataSource={selectedRelated}
          loading={isFetching}
          pagination={false}
        >
          <Column
            title={t('МЕДУЧРЕЖДЕНИЕ')}
            dataIndex="workspace"
            key="workspace"
            render={(workspace: IWorkspace) => <>{workspace?.name || ''}</>}
            className="has-divider"
          />
          <Column<TCaseLinkDTO>
            className="has-divider"
            title={t('Случай')}
            dataIndex="name"
            key="name"
            render={(text: string, record, index) => {
              const { caseId, linkType, slideStats, status } = record
              return (
                <div style={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                  {linkType && <CaseLinkIcon isParent={linkType === ECaseLinkType.PARENT} />}
                  <CaseName
                    isArchive={status === 'ARCHIVE'}
                    key={caseId ?? index}
                    countDone={slideStats?.slidesCount}
                    countTotal={slideStats?.referencesCount}
                    caseName={text}
                  />
                </div>
              )
            }}
          />
          <Column
            title={t('ВРАЧ')}
            className="has-divider"
            dataIndex="user"
            key="user"
            render={(user: IUser) => <>{user?.fullname ?? t('Автоматически')}</>}
          />
          <Column<TCaseLinkDTO>
            title={t('СПОСОБ ПОЛУЧЕНИЯ')}
            className="has-divider"
            dataIndex="diagnosticProcedureType"
            key="diagnosticProcedureType"
            render={(diagnosticProcedureType) => <>{diagnosticProcedureType?.name || ''}</>}
          />
          <Column<TCaseLinkDTO>
            title={t('МКБ-10')}
            className="has-divider"
            dataIndex="incomingIcd10"
            key="incomingIcd10"
            render={(incomingIcd10) => <>{incomingIcd10?.name || ''}</>}
          />
          <Column<TCaseLinkDTO>
            title={t('ДАТА')}
            dataIndex="caseDate"
            key="caseDate"
            render={(caseDate: string, record) => (
              <>{caseDate || record?.createdAt ? formatDateForDisplay(caseDate || record?.createdAt) : ''}</>
            )}
            className="has-divider"
          />
          <Column
            title={t('СТАТУС')}
            dataIndex="stage"
            key="stage"
            render={(text: ECaseStageQuery) => <TagItem tag={text} />}
          />
        </StyledCasesResTable>
      </div>
    </div>
  )
}

export default RelatedCasesTabContent
