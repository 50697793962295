import React, { useEffect, useMemo } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListRowRenderer,
  ScrollParams,
  Size,
} from 'react-virtualized'

import VirtualListItem from './VirtualListItem'

type Props = {
  fetchMore: () => Promise<any>
  defaultRowHeight: number
  total: number
  list: any[]
  rowRenderer: ListRowRenderer
  onScroll?: (e: ScrollParams) => void
  setRef?: (a: any) => void
  scrollToIndex?: number
  threshold?: number
}

export const VirtualInfiniteScroll = ({
  defaultRowHeight,
  fetchMore,
  list,
  onScroll,
  rowRenderer,
  scrollToIndex,
  setRef,
  threshold,
  total = 0,
}: Props) => {
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: defaultRowHeight,
        fixedWidth: true,
      }),
    [defaultRowHeight],
  )

  const fieldRef = React.useRef(null)
  useEffect(() => {
    if (setRef) {
      fieldRef && setRef(fieldRef)
    }
  }, [fieldRef])
  return (
    <AutoSizer>
      {({ height, width }: Size) => (
        <InfiniteLoader
          isRowLoaded={(index) => !!list[index.index]}
          loadMoreRows={() => fetchMore()}
          rowCount={total}
          threshold={threshold}
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              style={{ outline: 'none' }}
              width={width}
              onRowsRendered={onRowsRendered}
              ref={registerChild}
              height={height}
              rowCount={total}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              overscanRowCount={5}
              onScroll={onScroll}
              scrollToIndex={scrollToIndex}
              rowRenderer={(args) => {
                const { index, key, parent, style } = args
                return (
                  <CellMeasurer
                    ref={(e) => {
                      if (setRef) {
                        setRef(e)
                      }
                      return fieldRef
                    }}
                    cache={cache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                  >
                    {({ measure, registerChild }) => (
                      <VirtualListItem ref={registerChild as any} style={style} measure={measure}>
                        {rowRenderer(args)}
                      </VirtualListItem>
                    )}
                  </CellMeasurer>
                )
              }}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}
