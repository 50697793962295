import { Department } from 'entities/department/types'
import { useQuery } from 'react-query'
import { get, QUERY_TYPE } from 'shared/api'

const getDepartments = async () => {
  const res = await get<Department[]>({
    url: '/department',
  })
  return res
}

export const useDepartmentsQuery = () => useQuery(QUERY_TYPE.DEPARTMENT, getDepartments)
