import { useTypedSelector } from 'app/redux/lib/selector'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { useViewerIdMap, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, IIconElementProps, TooltipElement } from 'shared/ui/kit'
import { ToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import styled from 'styled-components'
import { useViewerMainSelector } from 'viewer/container'

const StyledPanel = styled.div`
  position: relative;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const FastMoveContainer = () => {
  const { activeViewerId: viewerId, isFastTravel } = useViewerPageProvided()
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const drawMode = useTypedSelector((state) => state.annotations.drawMode)
  const map = useViewerIdMap(viewerId)
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const { unsubscribeFromUser } = useUserStatusContext()
  const isDisable = drawMode || selectedAnnotationsIds.length > 0 || MITOSIS.isVisible
  const onFastMove = () => {
    unsubscribeFromUser()
    if (isDisable) return
    isFastTravel ? document.exitPointerLock() : map.getViewport().requestPointerLock()
  }

  const ToolButton = (props: {
    title: string
    iconName: IIconElementProps['name']
    hotkey: string
    onClick: MouseEventHandler<HTMLElement>
    active?: boolean
    disable?: boolean
  }) => {
    const { t } = useTranslation()
    return (
      <TooltipElement title={props.disable ? t('Инструмент недоступен') : `${props.title} (${props.hotkey})`}>
        <ToolbarButton icon={<IconElement name={props.iconName} />} {...props} />
      </TooltipElement>
    )
  }

  const { t } = useTranslation()
  return (
    <StyledPanel>
      <ToolButton
        title={t('Режим захвата курсора')}
        iconName="fastMove"
        hotkey={t('СКМ')}
        disable={isDisable}
        onClick={onFastMove}
        active={isFastTravel}
      />
    </StyledPanel>
  )
}
