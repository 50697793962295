import { useCallback, useEffect } from 'react'

export const useKeyUp = (keyCode: number, callback: () => void) => {
  const onKeyUp = useCallback((e) => {
    if (e.keyCode === keyCode) callback()
  }, [])

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])
}
