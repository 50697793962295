import type { SortOrder } from 'antd/lib/table/interface'
import { SumpSlidesFilters } from 'features/sump/api/query'
import { EUploadedFileSortBy, EUploadedFileTab } from 'types/IUploadedFile'

export const setSortOrder = (filters: SumpSlidesFilters, tabType: EUploadedFileTab, sortBy: EUploadedFileSortBy) =>
  filters?.sortBy === sortBy
    ? filters?.sortDir === 'ASC'
      ? ('ascend' as SortOrder)
      : ('descend' as SortOrder)
    : undefined
