import { Divider, Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery } from 'features/cases/api/query'
import { PatientReportsContainer } from 'features/reports'
import { useTranslation } from 'react-i18next'
import { formatDateTimeForDisplay } from 'shared/lib/date'
import { createCapitalString } from 'shared/lib/metadata'
import { ButtonElement, IconElement, SpaceElement, TextElement, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

import { InfoElement } from './PatientHeaderContainer'

const StyledInfoGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 100%;
  gap: 16px;
`

const InfoWrapper = styled.div`
  height: 100%;
  padding: 24px 56px 24px 16px;
`

const TextInfo = styled.div`
  padding: 0 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const ScrollWrapper = styled.div`
  flex: 1;
  padding-right: 8px;
  overflow-y: auto;
`
type PatientInfoProps = {
  caseRecord: ICase
}

const PatientInfo = ({ caseRecord }: PatientInfoProps) => {
  const type = caseRecord.casePathologicalInfo?.diagnosticProcedureType?.name
  const { t } = useTranslation()
  return (
    <InfoWrapper>
      <StyledInfoGrid>
        <Space direction="vertical" size={8}>
          <TooltipElement title={t('Архив')}>
            <ButtonElement icon={<IconElement name="archive" />} shape="circle" size="large" />
          </TooltipElement>
          <TooltipElement title={t('Редактировать')}>
            <ButtonElement icon={<IconElement name="edit" />} shape="circle" size="large" />
          </TooltipElement>
          <TooltipElement title={t('Другое')}>
            <ButtonElement icon={<IconElement name="more" />} shape="circle" size="large" />
          </TooltipElement>
        </Space>
        <TextInfo>
          {type && <TitleElement level={2}>{type}</TitleElement>}
          <SpaceElement size={16} />
          {caseRecord.casePathologicalInfo?.sites?.map((item, i) => (
            <InfoElement
              key={i}
              title={t('Локализация')}
              value={createCapitalString(item?.site?.name || t('не указано'))}
            />
          ))}
          <InfoElement title={t('Номер исследования')} value={caseRecord.name || ''} />
          {caseRecord.createdAt && (
            <>
              <SpaceElement size={4} />
              <InfoElement title={t('Дата')} value={formatDateTimeForDisplay(new Date(caseRecord.createdAt))} />
              <SpaceElement size={4} />
            </>
          )}
          <InfoElement title={t('Клинические сведения')} value=" " />
          <SpaceElement size={4} />
          <ScrollWrapper>
            <TextElement>{caseRecord.description}</TextElement>
            <SpaceElement size={24} />
            <Divider />
            <SpaceElement size={24} />
            <PatientReportsContainer caseId={caseRecord.caseId} source={caseRecord.source} />
          </ScrollWrapper>
        </TextInfo>
      </StyledInfoGrid>
    </InfoWrapper>
  )
}

const PatientInfoContainer = () => {
  const selectedCase = useTypedSelector((state) => state.dashboard.selectedCase)
  const { data } = useCaseQuery({ caseId: selectedCase, source: 'PLATFORM' })
  return data ? <PatientInfo caseRecord={data} /> : null
}

export default PatientInfoContainer
