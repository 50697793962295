import { Feature } from 'ol'
import { Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import type olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import React, { useEffect, useRef } from 'react'
import TViewerId from 'types/TViewerId'
import { defaultStyle } from 'viewer/map/layers/olStyles'

type Props = {
  map: olMap
  viewerId: TViewerId
  features: Feature<Polygon>[]
}

const VectorSpotsLayer: React.FC<Props> = ({ features, map, viewerId }) => {
  const layer = useRef<VectorLayer<any>>(
    new VectorLayer({
      source: new VectorSource({}),
      style: [defaultStyle],
      zIndex: 10000,
    }),
  )

  useEffect(() => {
    map.addLayer(layer.current)
    return () => {
      map.removeLayer(layer.current)
    }
  }, [])

  useEffect(() => {
    const source = new VectorSource({
      features,
    })
    layer.current?.setSource(source)
  }, [features])

  return null
}

export default VectorSpotsLayer
