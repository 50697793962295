import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'
import { ButtonElement, DropdownElement, IconElement, MenuElement, MenuItemElement } from 'shared/ui/kit'
import ICase from 'types/ICase'

type Props = {
  /** данные случая */
  caseRecord: ICase
  /** функция для изменения статуса */
  changeStatus: (status: ICase['status']) => void
  /** функция для удаления случая */
  deleteCase: () => void
  /** флаг, определяющий доступ пользователя добавить/восстановить кейс в/с архив(-а). */
  accessArchive?: boolean
  /** флаг, определяющий доступ пользователя к удалению. */
  access?: { fieldName: string; value: boolean } | any
}

const CaseActions = memo(({ access, accessArchive, caseRecord, changeStatus, deleteCase }: Props) => {
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const { status } = caseRecord
  const [open, setOpen] = useState(Boolean)
  const menuRef = useRef<any>()
  const buttonRef = useRef<any>()
  const workspaceId = useCurrentWorkspaceId() || ''
  const relation = queryParams.get('rel') as 'owner' | 'shared'
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace) || null
  const lisMode = currentWorkspace?.settings.find((key: { fieldName: string }) => key.fieldName === 'lisMode')
  const { t } = useTranslation()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        open && setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleClick = async () => {
    await changeStatus(status === 'OPEN' ? 'ARCHIVE' : 'OPEN')
    await setOpen(false)
    if (status === 'OPEN') {
      dispatch(push(`/workspace/${workspaceId}/cases?rel=${relation}`))
    } else {
      dispatch(push(`/workspace/${workspaceId}/archive?rel=${relation}`))
    }
  }

  const menu = (
    <div ref={menuRef}>
      <MenuElement selectable={false}>
        {accessArchive && (
          <MenuItemElement
            key="1"
            data-testid={'move-to-archive-item'}
            icon={<IconElement size="md" name="archiveSmall" />}
            onClick={handleClick}
          >
            {status === 'OPEN' ? t('Отправить в архив') : t('Вернуть из архива')}
          </MenuItemElement>
        )}
        {access && (
          <MenuItemElement
            key="2"
            icon={<IconElement size="md" name="delete" />}
            disabled={lisMode?.value === 'gemotest'}
            data-testid="delete-case-menu-item"
            danger={true}
            onClick={() => {
              deleteCase(), setOpen(false)
            }}
          >
            {t('Удалить')}
          </MenuItemElement>
        )}
      </MenuElement>
    </div>
  )
  return (
    <div ref={buttonRef}>
      <DropdownElement visible={open} overlay={menu} trigger={['click']}>
        <ButtonElement
          data-testid="three-points-case-actions"
          onClick={() => (open ? setOpen(false) : setOpen(true))}
          icon={<IconElement name="more" />}
          shape="circle"
          size="large"
        />
      </DropdownElement>
    </div>
  )
})

export default CaseActions
