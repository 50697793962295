import React from 'react'
import { FilterType, IActiveFilter, ISubMenu, t } from 'shared/ui/table/lib/common'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import { EUploadedFileTab } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

export enum EUploadedFileFilterType {
  UPLOADED_FILE_TYPES = 'uploadedFileTypes',
  UPLOADED_FILE_ATTACHMENT_STATES = 'uploadedFileAttachmentStates',
  CREATED_DATE = 'createdDate',
  ATTACHED_TO_CASE_DATE = 'attachedToCaseDate',
  DELETED_DATE = 'deletedDate',
}

/** Тип представления: "сетка" или "список". */
export enum ViewType {
  GRID = 'grid',
  LIST = 'list',
}

export type MacroFileProps = {
  /** Размер тайла. */
  tileSize: number
  /** Вид представления (сетка или список). */
  viewType?: ViewType
  /** URL-адрес изображения файла. */
  src: string
  /** Функция обработчика нажатия на кнопку. */
  onClick: (e: React.MouseEvent<HTMLTableRowElement>) => void
  /** Указывает, заблокирована ли плитка */
  isDisabled: boolean
}

export type MicroFileProps = {
  /** Размер тайла. */
  tileSize: number
  /** Вид представления (сетка или список). */
  viewType?: ViewType
  /** URL-адрес изображения файла. */
  src: string
  /** Угол поворота этикетки. */
  rotateLabel?: number
  /** URL-адрес этикетки. */
  labelUrl?: string
  /** Функция обработчика нажатия на кнопку. */
  onClick: (e: React.MouseEvent<HTMLTableRowElement>) => void
  /** Указывает, заблокирована ли плитка */
  isDisabled: boolean
}

export type UploadedFileProps = {
  /** Обработчик контекстного меню. */
  handleContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** Угол поворота этикетки. */
  rotateLabel?: number
  /** Вид таблицы. */
  viewType: ViewType
  /** Данные выбранного файла. */
  selectedFile?: IUploadedFileDTO
  /** Список файлов */
  file?: IUploadedFileDTO
}

export type TDateRange = {
  from: string | null
  to: string | null
}

export const updateDateRangeFilter = (
  prevFilters: IActiveFilter[],
  filter: ISubMenu,
  filterKey?: string,
  dateRange?: [string, string],
): IActiveFilter[] => {
  if (!dateRange) {
    return prevFilters.filter((f) => f.key !== filterKey)
  }

  const { filterType, key, title } = filter

  const filterIndex = prevFilters.findIndex((filter) => filter.key === filterKey)

  if (filterIndex !== -1) {
    const updatedFilters = [...prevFilters]
    updatedFilters[filterIndex].activeFilters = [
      {
        dateRange: { from: dateRange[0], to: dateRange[1] },
        filterName: updatedFilters[filterIndex].title,
        filterValue: true,
        key,
      },
    ]

    return updatedFilters
  }

  return [
    ...prevFilters,
    {
      activeFilters: [
        {
          dateRange: { from: dateRange[0], to: dateRange[1] },
          filterName: title,
          filterValue: true,
          key,
        },
      ],
      filterType,
      key,
      title,
    },
  ]
}
// Создание списка подфильтров

export const createUFSubMenu = <T extends string>(
  key: string,
  title: string,
  queryValues: T[],
  filterType: FilterType,
  labelCb: (it: T) => string,
): ISubMenu => ({
  filterType,
  items: queryValues?.map((queryValue, index) => ({
    key: `${queryValue}:${index}`,
    label: t(labelCb(queryValue)),
    queryValue,
  })),
  key,
  title: t(title),
})

const STORAGE_KEY = 'uploaded-file'

const getStoredSettings = (): Record<string, any> => {
  const item = localStorage.getItem(STORAGE_KEY)
  return item ? JSON.parse(item) : {}
}

const setStoredSettings = (settings: Record<string, any>) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(settings))
}

export const getLocalStorageItem = (tab: EUploadedFileTab, key: string, defaultValue: any) => {
  const settings = getStoredSettings()
  return settings[tab]?.[key] ?? defaultValue
}

export const setLocalStorageItem = (tab: EUploadedFileTab, key: string, value: any) => {
  const settings = getStoredSettings()
  if (!settings[tab]) {
    settings[tab] = {}
  }
  settings[tab][key] = value
  setStoredSettings(settings)
}

export const updateSelection = (
  currentFiles: IUploadedFileDTO[],
  newFile: IUploadedFileDTO,
  selectedFile?: IUploadedFileDTO,
) => {
  const existingIndex = currentFiles.findIndex((file) => file.uploadedFileId === newFile.uploadedFileId)

  if (existingIndex !== -1) {
    return currentFiles.filter((file) => file.uploadedFileId !== newFile.uploadedFileId)
  } else {
    const updatedFiles = selectedFile ? [...currentFiles, selectedFile] : [...currentFiles]
    return [...updatedFiles, newFile]
  }
}

const MINIMUM_INDEX_OFFSET = 1
const DEFAULT_FILES_COUNT_TO_REMOVE = 2

export const getNextOrPrevFile = (
  selectedFiles: IUploadedFileDTO[],
  selectedFile?: IUploadedFileDTO,
  files?: IListOfItemsCursorCases<IUploadedFileDTO>[],
) => {
  if (!files?.length) return

  const flattenedContent = files.flatMap((file) => file.content)
  const maxIndex = flattenedContent.length - (selectedFiles.length || DEFAULT_FILES_COUNT_TO_REMOVE)

  const getIndex = (file: IUploadedFileDTO | undefined) =>
    flattenedContent.findIndex((f) => f.uploadedFileId === file?.uploadedFileId)

  let index: number

  if (selectedFiles.length) {
    const indices = selectedFiles.map(getIndex)
    index = Math.max(...indices) + MINIMUM_INDEX_OFFSET
  } else {
    index = getIndex(selectedFile) + MINIMUM_INDEX_OFFSET
  }

  index = Math.min(index, maxIndex - MINIMUM_INDEX_OFFSET)
  return flattenedContent[index]
}

export const getNextOrPrevIndex = (
  selectedFilesLength: number,
  selectedFile?: IUploadedFileDTO,
  files?: IListOfItemsCursorCases<IUploadedFileDTO>[],
) => {
  if (!files?.length) return

  const flattenedContent = files.flatMap((file) => file.content)
  const index = flattenedContent.findIndex((file) => file.uploadedFileId === selectedFile?.uploadedFileId)
  const maxIndex = flattenedContent.length - (selectedFilesLength || DEFAULT_FILES_COUNT_TO_REMOVE)
  const newIndex = index - (selectedFilesLength || MINIMUM_INDEX_OFFSET)
  if (index >= maxIndex) {
    return maxIndex
  }
  return newIndex < 0 ? 0 : newIndex
}

export const checkUploadedFileTab = (
  selectedFiles: IUploadedFileDTO[],
  uploadedFileTab: EUploadedFileTab[],
  selectedFileContext?: IUploadedFileDTO,
): boolean => {
  // Если нет контекста и нет файлов — вернем true
  if (!selectedFileContext && !selectedFiles.length) return true

  // Если есть контекст — проверим соответствие вкладок
  if (selectedFileContext) {
    return uploadedFileTab.includes(selectedFileContext.uploadedFileTab)
  }

  // Если контекста нет — проверим вкладку среди выбранных файлов
  return selectedFiles.some((file) => uploadedFileTab.includes(file.uploadedFileTab))
}

export const ROTATION_INCREMENT = 90
