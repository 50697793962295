import { rotate } from 'ol/coordinate'

export function fromDegreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180
}

export function fromRadiansToDegrees(radians: number) {
  return (radians * 180) / Math.PI
}

//TODO: check params equals undefined
export function rotateCoordinate(coordinate: any, angle: any, anchor: any, scale = 1) {
  const coord = rotate([coordinate[0] - anchor[0], coordinate[1] - anchor[1]], fromDegreesToRadians(angle))
  return [coord[0] * scale + anchor[0], coord[1] * scale + anchor[1]]
}
