import { IIIF } from 'ol/source'
import { Options } from 'ol/source/IIIF'

export const isScaledTiles = devicePixelRatio > 1
export const TILE_PIXEL_RATIO = isScaledTiles ? 2 : 1

export const adjustScaleIfRequired = <T extends number | number[]>(data: T): T => {
  if (!isScaledTiles) return data

  return typeof data === 'number' ? ((data / TILE_PIXEL_RATIO) as T) : (data.map((it) => it / TILE_PIXEL_RATIO) as T)
}

export const scaledIIIOptions = (options: Options): Options => {
  if (!isScaledTiles) return options

  const source = new IIIF(options)

  const tileGrid = source.getTileGrid()
  const resolutions = tileGrid.getResolutions().slice()
  const tileSizes: number[][] = []

  for (let i = 0; i < resolutions.length; i++) {
    const tileSize = tileGrid.getTileSize(i)

    // Преобразуем tileSize в массив
    tileSizes[i] = Array.isArray(tileSize) ? tileSize : [tileSize, tileSize]

    // Увеличиваем размеры тайлов и разрешения, если dpi > 1
    tileSizes[i][0] *= devicePixelRatio
    tileSizes[i][1] *= devicePixelRatio
    resolutions[i] /= devicePixelRatio
  }

  const tileUrlFunction = source.tileUrlFunction.bind(source)

  source.setTileUrlFunction(tileUrlFunction)

  return {
    ...options,
    extent: tileGrid.getExtent(),
    resolutions,
    sizes: tileSizes,
  } as Options
}
export const doubleNumberInUrl = (url: string) =>
  // Используем регулярное выражение для нахождения размера тайла в канталупе
  url.replace(/\/(\d+),\/(\d+)\/default\.jpg/, (match, p1, p2) => {
    // Умножаем найденное на pixel ratio
    const doubledNumber = parseInt(p1, 10) * TILE_PIXEL_RATIO
    return `/${doubledNumber},/${p2}/default.jpg`
  })
