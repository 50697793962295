import { useThemeContext } from 'app/styled/ThemeProvider'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyElement } from 'shared/ui/kit'

import { StyledTable } from './ResTable.styled'

const MIDDLE_WIDTH_COLUMN = 70

const ResTable = (props) => {
  const theme = useThemeContext()
  const { t } = useTranslation()
  const tableRef = useRef(null)
  const columnRef = useRef(null)
  const nextColumnRef = useRef(null)

  let tableWidth = 0

  useEffect(() => {
    const el = tableRef.current
    const table = el.getElementsByTagName('table')[0]
    table.setAttribute('data-table-resizable', 'true')
    const id = 'rs_tb'
    table.id = id
    resizeable(table)
    // clearColumnsWidth(table);
  }, [])

  const resizeable = (table) => {
    const header = table.rows[0]
    const headerCell = header.cells
    const cells = Array.from(headerCell).filter((element) => !element.classList.contains('ant-table-cell-scrollbar'))
    const len = cells.length
    for (let i = 0; i < len; i++) {
      cells[i].addEventListener('mousedown', handleMousedown)
      cells[i].addEventListener('mousemove', handleMousemove, true)
    }
    table.addEventListener('mouseup', handleMouseup)
    table.addEventListener('mouseleave', handleMouseup)
  }

  const handleMousedown = (event) => {
    const target = event.currentTarget
    nextColumnRef.current = target.nextSibling
    const nextColumn = nextColumnRef.current
    columnRef.current = target
    if (event.offsetX > target.offsetWidth - 10) {
      target.mouseDown = true
      nextColumn.mouseDown = true
      target.oldX = event.x
      nextColumn.oldX = nextColumn.x
      target.oldWidth = target.offsetWidth
      nextColumn.oldWidth = nextColumn.offsetWidth
    }

    const rows = tableRef.current.rows
    if (rows && rows.length > 0) {
      tableWidth = rows[0].clientWidth
    }
  }

  const handleMousemove = (event) => {
    const target = event.currentTarget
    const parentNode = tableRef.current.querySelectorAll('td.ant-table-cell')
    const filteredTarget = tableRef.current.querySelectorAll('thead > tr')[0]?.children
    const targetNode = Array.from(filteredTarget).filter(
      (element) => !element.classList.contains('ant-table-cell-scrollbar'),
    )
    if (event.offsetX > target.offsetWidth - 10) {
      target.style.cursor = 'col-resize'
    } else {
      target.style.cursor = 'default'
    }
    if (!columnRef.current) {
      columnRef.current = target
    }
    const column = columnRef.current
    if (!nextColumnRef.current) {
      nextColumnRef.current = target.nextElementSibling
    }
    const nextColumn = nextColumnRef.current
    if (column.mouseDown) {
      column.style.cursor = 'default'

      for (let i = 0; i < targetNode.length; i++) {
        if (parentNode[i] === target) {
          targetNode[i].style.width = 'auto'
          if (targetNode[i + 1]) {
            targetNode[i + 1].style.width = 'auto'
          }
        }
        const diff = event.x - column.oldX
        if (column.oldWidth + (event.x - column.oldX) > 0) {
          if (column.oldWidth + diff < MIDDLE_WIDTH_COLUMN) return
          if (nextColumn.oldWidth - diff < MIDDLE_WIDTH_COLUMN) return
          column.width = column.oldWidth + diff
          nextColumn.width = nextColumn.oldWidth - diff
        }
      }

      const diff = event.x - column.oldX
      if (column.oldWidth + (event.x - column.oldX) > 0) {
        if (column.oldWidth + diff < MIDDLE_WIDTH_COLUMN) return
        if (nextColumn.oldWidth - diff < MIDDLE_WIDTH_COLUMN) return
        column.width = column.oldWidth + diff
        nextColumn.width = nextColumn.oldWidth - diff
      }
    }
  }

  const handleMouseup = () => {
    if (columnRef.current) {
      columnRef.current.mouseDown = false
      columnRef.current.style.cursor = 'default'
      nextColumnRef.current = null
    }
  }

  return <StyledTable locale={{ emptyText: <EmptyElement /> }} theme={theme.theme} ref={tableRef} {...props} />
}

export default ResTable
