import { Descriptions as AntDescriptions } from 'antd'
import type { DescriptionsProps } from 'antd/es/descriptions'
import type { DescriptionsItemProps } from 'antd/es/descriptions/Item'
import { FC } from 'react'
import styled from 'styled-components/macro'

type Props = {
  items?: DescriptionsItemProps[]
  // делаем ли отображение строкой
  isFlex?: boolean
  // переносим ли строку
  isBreak?: boolean
} & DescriptionsProps

const StyledDescriptions = styled(AntDescriptions)<{ isFlex?: boolean; isBreak?: boolean }>`
  ${({ isFlex }) =>
    isFlex &&
    `tbody {
      display: flex;
    }
    .ant-descriptions-row {
      flex: 1;
    }
    .ant-descriptions-item-content {
      display: block !important;
    }
  `}

  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 0px;
  }
  .ant-descriptions-item-label {
    position: relative;
    color: var(--color-text-3);
    font-size: 12px;
    line-height: 16px;
  }
  .ant-descriptions-item-content {
    display: inline;
    color: var(--color-text-1);
    font-size: 12px;
    line-height: 16px;
    white-space: pre-line;
  }
  .ant-descriptions-item-container {
    display: ${({ isBreak }) => (isBreak ? 'flex' : 'block')};
  }
`

export const Descriptions: FC<Props> = ({ children, items, ...rest }) => (
  <StyledDescriptions {...rest}>
    {items
      ? items.map((item, i) => <AntDescriptions.Item key={i.toString()} {...item} span={item.span || 3} />)
      : children}
  </StyledDescriptions>
)

export const DescriptionsItem = AntDescriptions.Item
