import { Layout } from 'antd'
import { OpenNotificationsButton } from 'features/notifications'
import { UserOptionsContainer } from 'features/user-options'
import { useTranslation } from 'react-i18next'
import useQueryParams from 'shared/lib/hooks/useQueryParams'
import { Scroll, SearchElement, TitleElement } from 'shared/ui/kit'
import Logo from 'shared/ui/logo'
import { NavigationBottom } from 'shared/ui/navigation/NavigationBottom'
import styled from 'styled-components/macro'

import DashboardNavigationType from './navigation/DashboardNavigationType'
import DashboardNavigationCasesContainer from './navigation/DbNavCasesContainer'
import DashboardNavigationMainContainer from './navigation/DbNavMainContainer'
import DbNavSecondaryTypesContainer from './navigation/DbNavSecondaryTypesContainer'
import DashboardNavigationTypesContainer from './navigation/DbNavTypesContainer'
import { PatientContentContainer } from './patient/PatientContentContainer'
import PatientHeader from './patient/PatientHeaderContainer'
import PatientListContainer from './patient/PatientListContainer'
import { SettingsContainer } from './settings/SettingsContainer'

const { Content, Header, Sider } = Layout

const PrimarySider = styled(Sider)`
  background: var(--color-bg-1);
  border-right: 1px solid var(--color-bg-3);
  & > * {
    display: flex;
    flex-direction: column;
  }
`

const PrimaryHeader = styled(Header)`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-bg-1);
  border-bottom: 1px solid var(--color-border-1);
  padding: 0 16px;
`

const LeftActions = styled.div`
  display: grid;
  grid-template-columns: auto 300px;
  grid-template-rows: 48px;
  align-items: center;
  gap: 16px;
`

const RightActions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
`

const SecondarySider = styled(Sider)`
  background: var(--color-bg-2);
`

const SecondaryHeader = styled(Header)`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background: var(--color-bg-2);
  border-bottom: 1px solid var(--color-border-1);
`

const StyledContent = styled(Content)`
  background: var(--color-bg-2);
  height: 100%;
`

const NavigationWrapper = styled(Scroll)`
  padding: 16px 0px;
  display: flex;
  gap: 24px;
  flex-direction: column;
`

const Dashboard = () => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  return (
    <Layout style={{ height: '100vh' }}>
      <PrimaryHeader>
        <LeftActions>
          <Logo />
          <SearchElement />
        </LeftActions>
        <RightActions>
          <UserOptionsContainer />
          <OpenNotificationsButton />
        </RightActions>
      </PrimaryHeader>
      <Layout>
        <PrimarySider width={224}>
          <NavigationWrapper>
            <DashboardNavigationMainContainer />
            <DashboardNavigationCasesContainer />
            <DashboardNavigationTypesContainer />
            <DbNavSecondaryTypesContainer />
          </NavigationWrapper>
          <NavigationBottom />
        </PrimarySider>
        <Layout style={{ height: '100%' }}>
          <SecondaryHeader>
            {queryParams.get('filter') === DashboardNavigationType.Settings ? (
              <TitleElement>{t('Управление')}</TitleElement>
            ) : (
              <PatientHeader />
            )}
          </SecondaryHeader>
          <Layout>
            <SecondarySider width={queryParams.get('filter') === DashboardNavigationType.Settings ? 220 : 324}>
              <Scroll>
                <PatientListContainer />
              </Scroll>
            </SecondarySider>
            <StyledContent>
              {queryParams.get('filter') === DashboardNavigationType.Settings ? (
                <SettingsContainer />
              ) : (
                <PatientContentContainer />
              )}
            </StyledContent>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Dashboard
