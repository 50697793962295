import { FormInstance } from 'antd'
import { ReportFormData } from 'features/reports/ui/ReportCreationForm'
import IReport from 'types/IReport'

export const useValidations = (initialReport: Partial<IReport>, form: FormInstance<ReportFormData>) => {
  // in initial call fields from form is not defined, so for validation we merge it with initialReport
  const getMergedFields = () => {
    const fields = form.getFieldsValue()
    return {
      ...initialReport,
      ...fields,
    }
  }
  const isValidRequiredForAll = () => {
    const { icd10, microDescription, morphologyCode, report } = getMergedFields()
    if (!form.isFieldsTouched()) {
      return Object.values(initialReport).length === 0
    }
    return (
      morphologyCode !== null &&
      morphologyCode !== undefined &&
      icd10 !== undefined &&
      report !== '' &&
      report !== undefined &&
      microDescription !== '' &&
      microDescription !== null
    )
  }
  const isValidCommonRequired = () => {
    const { icd10, report } = getMergedFields()
    return icd10 !== undefined && !!report
  }
  const isValidGemotestRequired = () => {
    const { caseMacroDescription } = getMergedFields()
    return isValidRequiredForAll() && caseMacroDescription
  }

  /** Проверяем обязательность полей для сохранинеия черновика */
  const isGemotestDraftRequired = () => {
    const { report } = getMergedFields()

    return !!report
  }

  /** Проверяем обязательность полей для подписания в ЛИС */
  const isGemotestSignRequired = () => {
    const { complexity, icd10 } = getMergedFields()

    return isGemotestDraftRequired() && complexity && icd10
  }

  return { isGemotestDraftRequired, isGemotestSignRequired, isValidCommonRequired, isValidGemotestRequired }
}
