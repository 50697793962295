import { Progress, Slider } from 'antd'
import { useSlideQuery } from 'entities/slide'
import { SEGMENTATION, useSegmentationQuery } from 'entities/slide-layers'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CheckboxElement, InputNumberElement, SpaceElement, SpinElement, TextElement } from 'shared/ui/kit'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components/macro'
import { IResultType } from 'types/ISlideMetadata'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'

const ProgressContainer = styled.div`
  .ant-progress-text {
    text-align: right;
  }
  display: flex;
  flex-direction: column;
`

const OpacityContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`

const OpacityTitle = styled.div`
  display: flex;
  align-items: center;
`

const StyledSlider = styled(Slider)`
  flex: 1;
  margin: 0 8px;
  height: 8px;
  .ant-slider-handle {
    background-color: #fff;
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }
  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`

const StyledInputNumber = styled(InputNumberElement)`
  flex: 1;
  display: flex;
  align-items: center;
`

type Props = {
  caseId: number
  slideId: number
  viewerId: TViewerId
}

const SegmentationContainer = ({ caseId, slideId, viewerId }: Props) => {
  const { isError, isLoading } = useSegmentationQuery(caseId, slideId)
  const { segmentationLayers } = useViewerMainSelector(viewerId)
  const { source } = useViewerIdSlideState(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)
  const { data: slide } = useSlideQuery({ slideId, source })
  const { t } = useTranslation()

  const handleChange = (e: any) => {
    viewerDispatch(
      viewerSlice.actions.setSegmentationLayer({ classId: e.target.id, info: { visible: e.target.checked } }),
    )
  }

  useEffect(() => {
    if (slide?.slideMetadata?.caseSpecificMetadata?.result) {
      const defaultSegmentation = Object.entries(slide?.slideMetadata?.caseSpecificMetadata.result).reduce(
        (acc, metaData) => {
          const classId = metaData[1] && metaData[1].classId

          return classId
            ? {
                ...acc,
                [classId]: {
                  visible: true,
                },
              }
            : acc
        },
        {},
      )
      viewerDispatch(viewerSlice.actions.setSegmentationLayers(defaultSegmentation))
    }
  }, [slideId])

  if (slide?.slideMetadata?.commonMetadata?.segmentationStatus === 'PROCESSING') {
    return <TextElement type="secondary">{t('Идет расчет сегментации')}</TextElement>
  }
  if (slide?.slideMetadata?.commonMetadata?.segmentationStatus === 'FAILED') {
    return <TextElement type="danger">{t('Ошибка при расчете сегментации')}</TextElement>
  }

  if (isError) {
    return <TextElement type="danger">{t('Ошибка при получении сегментации')}</TextElement>
  }

  return (
    <SpinElement spinning={isLoading}>
      {slide?.slideMetadata?.caseSpecificMetadata?.result &&
        Object.entries(slide?.slideMetadata?.caseSpecificMetadata.result).map(
          (metaData, index) =>
            metaData[1] &&
            metaData[1].classId && (
              <div key={index}>
                <ProgressContainer>
                  <CheckboxElement
                    id={String(metaData[1].classId)}
                    onChange={handleChange}
                    key={slide?.slideId + index}
                    checked={
                      segmentationLayers[metaData[1].classId] ? segmentationLayers[metaData[1].classId].visible : false
                    }
                  >
                    {SEGMENTATION[metaData[0] as IResultType]?.text}
                  </CheckboxElement>
                  <Progress
                    status="normal"
                    percent={Math.round(metaData[1].relativeValue * 100)}
                    trailColor={'transparent'}
                    strokeColor={SEGMENTATION[metaData[0] as IResultType]?.color}
                  />
                </ProgressContainer>
                <SpaceElement size={8} />
              </div>
            ),
        )}
      <OpacityContainer viewerId={viewerId} />
    </SpinElement>
  )
}
type SliderProps = {
  viewerId: TViewerId
}

const OpacityContainer = ({ viewerId }: SliderProps) => {
  const { segmentationOpacity: opacity } = useViewerMainSelector(viewerId)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const viewerDispatch = useViewerDispatch(viewerId)
  const toggleInputFocus = (value: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  const onOpacityChange = (value: number | string | undefined) => {
    viewerDispatch(viewerSlice.actions.setSegmentationOpacity(Number(value)))
  }

  return (
    <OpacityContainerWrapper onFocus={() => toggleInputFocus(true)} onBlur={() => toggleInputFocus(false)}>
      <OpacityTitle>{t('Прозрачность')}</OpacityTitle>
      <StyledSlider
        min={1}
        max={MAX_OPACITY}
        tipFormatter={(value) => `${value}%`}
        onChange={onOpacityChange}
        value={opacity}
      />
      <StyledInputNumber
        min={1}
        max={MAX_OPACITY}
        formatter={(value) => `${value}%`}
        value={opacity}
        onChange={onOpacityChange}
      />
    </OpacityContainerWrapper>
  )
}

export default SegmentationContainer
