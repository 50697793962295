import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery } from 'features/cases/api/query'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import ViewerRightPanel from 'pages/viewer/ui/common/ViewerRightPanel'
import { Center, SpinElement } from 'shared/ui/kit'

const TasksRightPanelContainer = () => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { isLoading } = useCaseQuery({ caseId, source })

  if (isLoading) {
    return (
      <Center>
        <SpinElement />
      </Center>
    )
  }
  return (
    <>
      <ViewerRightPanel
        urlSlideId={slideId}
        urlCaseId={slideId}
        source={source}
        activeTab={rightPanelType}
        viewerId={viewerId}
        activeViewerCaseId={slideId}
        activeViewerSlideId={slideId}
        activeViewerSource={source}
        type={'TASK'}
      />
    </>
  )
}

export default TasksRightPanelContainer
