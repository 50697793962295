import { Input, InputProps } from 'antd'
import { FC, ReactElement, useState } from 'react'
import styled from 'styled-components'

import { IconElement } from './IconElement'

const StyledInput = styled(Input)`
  height: 16px;
  padding: 0;
  width: 38px;
  border: 0;
  background: var(--color-bg-3) !important;
  background-color: rgb(225 216 227 / 0%) !important;
  outline: none !important;
  border-radius: 0;
  &.ant-input:focus,
  &.ant-input-focused {
    box-shadow: none !important;
  }
`
const StyledIcon = styled(IconElement)`
  color: var(--color-text-3);
  cursor: pointer;
  display: block;
  &:hover {
    color: var(--color-text-1);
  }
`
const StyledIconContainer = styled.div`
  justify-content: space-evenly;
  display: flex;
  flex-flow: column;
  height: 20px;
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: var(--color-bg-3);
  height: 24px;
  width: 72px;
  margin-right: 0px !important;
  padding-left: 4px;
`

type Props<T> = InputProps & {
  /** Функция для обновления состояния со стрелками + и - */
  onClickIcon: (value: string) => void
  /** Префикс для инпута*/
  prefixElement?: ReactElement
  /** Суффикс для инпута*/
  suffixElement?: ReactElement
  /** Флаг видимости стрелок*/
  isArrowVisible?: boolean
}
export const CustomInputElement: FC<Props<string | number>> = ({
  isArrowVisible = true,
  onClickIcon,
  prefixElement,
  suffixElement,
  ...props
}): ReactElement => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => setIsHovered(false)

  const handleFocus = () => setIsHovered(true)

  const handleChangeArrow = (operation: 'ADD' | 'SUBTRACT', maxState: number, minState: number) => {
    let nextState: number
    const parsedState = Number(props.value)

    if (operation === 'ADD') {
      nextState = parsedState + 1
    } else {
      nextState = parsedState - 1
      if (nextState < 0) {
        nextState = minState
      } else if (nextState < minState) {
        nextState = minState
      }
    }
    onClickIcon(String(nextState))
    return nextState.toString()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp') {
      handleChangeArrow('ADD', Number(props.max), Number(props.min))
    } else if (event.key === 'ArrowDown') {
      handleChangeArrow('SUBTRACT', Number(props.max), Number(props.min))
    }
  }
  return (
    <StyledContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onFocus={handleFocus}>
      {prefixElement}
      <StyledInput onKeyDown={handleKeyDown} {...props} />
      {suffixElement}
      {isHovered && isArrowVisible && (
        <StyledIconContainer>
          <StyledIcon
            onClick={() => handleChangeArrow('ADD', Number(props.max), Number(props.min))}
            size="xs"
            name="numberUp"
          />
          <StyledIcon
            onClick={() => handleChangeArrow('SUBTRACT', Number(props.max), Number(props.min))}
            size="xs"
            name="numberDown"
          />
        </StyledIconContainer>
      )}
    </StyledContainer>
  )
}
