import React from 'react'
import styled from 'styled-components'

type StackedPreviewImageProps = {
  children: React.ReactNode
}

const PhotoStack = styled.div`
  position: relative;
  width: 230px;
  height: 115px;

  & > div {
    position: absolute;
    height: 115px;
    top: 0;
    left: 0;
  }

  & > div:nth-child(1) {
    z-index: 999;
  }

  & > div:nth-child(2) {
    transform: rotate3d(0, 0, 1, 3deg);
  }

  & > div:nth-child(3) {
    transform: rotate3d(0, 0, 1, -3deg);
  }
`

const StackedPreviewImage: React.FC<StackedPreviewImageProps> = ({ children }) => <PhotoStack>{children}</PhotoStack>

export default StackedPreviewImage
