import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components'

const PatientWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`
const PatientConnectedCases = styled.p<{ done: boolean }>`
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0;
  color: var(--color-text-1);
  display: ${(props) => (!props.done ? 'none' : 'block')};
  background: var(--color-bg-3);
  border: 1px solid var(--color-bg-3);
  border-radius: 2px;
`

type TPatientProps = {
  patientName: string
  /** Количество связанных случаев пациента*/
  patientConnectedCases?: number
  key: React.Key
}

export const Patient: FC<TPatientProps> = memo(({ key, patientConnectedCases, patientName }) => {
  const { t } = useTranslation()

  return (
    <PatientWrapper data-testid={'management-patient-row'} key={key}>
      <TextElement ellipsis>{patientName}</TextElement>
      <PatientConnectedCases done={Number(patientConnectedCases) - 1 > 0} title={t('Количество связанных случаев')}>
        +{Number(patientConnectedCases) - 1}
      </PatientConnectedCases>
    </PatientWrapper>
  )
})
