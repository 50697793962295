import { Divider } from 'antd'
import { annotationsSlice } from 'features/annotations'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { PopoverWithCloseBtn } from 'shared/ui/popover'

import { AnnotationFilterForm } from './form/AnnotationFilterForm'

type AnnotationFilterWindow = {
  /** Функция закрытия окна */
  onClose: () => void
  /** Видимость окна фильтрации */
  isVisible: boolean
}

export const AnnotationFilterWindow = ({ isVisible, onClose }: AnnotationFilterWindow) => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId } = useViewerIdSlideState(viewerId)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const onResetClick = useCallback(() => {
    dispatch(annotationsSlice.actions.resetAnnotationFilter())
  }, [])

  return (
    <PopoverWithCloseBtn
      content={
        <div style={{ maxWidth: 266, width: 266 }}>
          <Divider style={{ marginTop: 0 }} />
          <AnnotationFilterForm caseId={caseId} onClose={onClose} />
        </div>
      }
      titleText={t('Фильтрация')}
      visible={isVisible}
      shareModal
      onClose={onClose}
      align={{ offset: [18, 18] }}
      customBtn={{
        icon: 'undoSmall',
        onClick: onResetClick,
      }}
    />
  )
}
