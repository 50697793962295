import axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'
import { getTokenFromStorage } from 'shared/lib/local-storage'
import { configURLHandler, workspaceURLHandler } from 'shared/lib/workspaces'

import {
  ErrorResponse,
  handleForbiddenError,
  handleNetworkError,
  handleServerError,
  handleUnauthorizedError,
  isForbiddenError,
  isNetworkError,
  isServerError,
  isUnauthorizedError,
} from './errorHandling'

type ApiRequestArgs<B = any> = {
  /** URL-адрес запроса. */
  url: string
  /** Данные, отправляемые в теле запроса. */
  data?: B
  /** Дополнительная конфигурация Axios для запроса. */
  config?: AxiosRequestConfig
  /** Флаг, игнорировать ли обработчик ошибок (если true, обработчик ошибок не вызывается). */
  ignoreCatch?: boolean
  /** Опциональный идентификатор рабочего пространства. Используется, если есть данные по рабочему пространству. */
  workspaceId?: string
}

let dispatch: any = () => {}

import('app/redux').then((module) => {
  dispatch = module.store.dispatch
})

export const getServerUrl = () =>
  //@ts-ignore
  window.APP_API_URL ? window.APP_API_URL : process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''

const SERVER_URL = getServerUrl()

export const getSocketUrl = () => `${SERVER_URL}/ws?access_token=${getTokenFromStorage()}`

const instance = axios.create({
  baseURL: SERVER_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
})

instance.interceptors.request.use((config) => ({
  ...config,
  headers: {
    Authorization: `Bearer ${getTokenFromStorage()}`,
    'Content-Type': 'application/json',
  },
}))

const handleSuccess = <T>(response: T): T => response

const handleErrorResponse = (error: ErrorResponse) => {
  if (isUnauthorizedError(error)) {
    handleUnauthorizedError()
  } else if (isServerError(error)) {
    handleServerError()
  } else if (isNetworkError(error)) {
    handleNetworkError()
  } else if (isForbiddenError(error)) {
    handleForbiddenError()
  }

  throw error
}

instance.interceptors.response.use(handleSuccess, handleErrorResponse)

export const get = async <T = any>({ config, url, workspaceId }: ApiRequestArgs) => {
  const result = await instance.get<T>(workspaceURLHandler(url, config, workspaceId), configURLHandler(config))
  return result?.data
}

export const post = async <T = any, B = any>({ config, data, ignoreCatch, url }: ApiRequestArgs<B>) => {
  const result = await instance.post<T>(
    workspaceURLHandler(url, config),
    data,
    configURLHandler({
      ...config,
      params: {
        ...config?.params,
        ignoreCatch,
      },
    }),
  )
  return result ? result.data : result
}

export const put = async <T = any>({ config, data, url }: ApiRequestArgs) => {
  const result = await instance.put<T>(workspaceURLHandler(url, config), data, configURLHandler(config))
  return result?.data
}

export const del = async <T = any>({ config, url }: ApiRequestArgs) => {
  const result = await instance.delete<T>(workspaceURLHandler(url, config), configURLHandler(config))
  return result?.data
}

export const login = async <T = any>({ config, data, url }: ApiRequestArgs) =>
  axios.post<T>(url, data, {
    ...config,
    baseURL: SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const loginWithToken = async <T = any>({ config, data, url }: ApiRequestArgs) => {
  const result = await axios.post<T>(url, data, {
    ...config,
    baseURL: SERVER_URL,
    headers: {
      Authorization: `Bearer ${getTokenFromStorage()}`,
      'Content-Type': 'application/json',
    },
  })
  return result?.data
}

export { QUERY_TYPE } from './QUERY_TYPE'
