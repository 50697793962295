import { useTypedSelector } from 'app/redux/lib/selector'
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IMarkupResultStatus, IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'

import markupTasksService from './service'

export type PaginatedTasksFilters = {
  page?: number
  size?: number
}

export const usePaginatedTasksQuery = (filters: PaginatedTasksFilters) => {
  const queryClient = useQueryClient()
  return useQuery(
    [QUERY_TYPE.TASKS, filters],
    async () => {
      const result = await markupTasksService.fetchMarkupTasks({
        ...filters,
      })
      if (!result) return
      const tasksArr: number[] = []
      result.content.forEach((task) => {
        const cacheRecord = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, task.markupTaskId])
        tasksArr.push(task.markupTaskId)
        if (!cacheRecord) {
          queryClient.setQueryData([QUERY_TYPE.TASKS, task.markupTaskId], task)
        }
      })
      queryClient.setQueryData([QUERY_TYPE.TASKS, 'currentTasks'], tasksArr)
      return result
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useTaskQuery = (taskId?: number) =>
  useQuery<IMarkupTask | undefined>(
    [QUERY_TYPE.TASKS, taskId],
    async () => {
      if (!taskId) return
      const res = await markupTasksService.fetchSingleTask(taskId)
      return res
        ? {
            ...res,
          }
        : undefined
    },
    {
      staleTime: Infinity,
    },
  )

export const useTaskSlides = (taskId?: number) => {
  const queryClient = useQueryClient()
  return useQuery(
    [QUERY_TYPE.TASKS_SLIDES, taskId],
    async () => {
      if (!taskId) return
      const res = await markupTasksService.fetchTaskSlides(taskId)
      res?.map((slide) => {
        queryClient.setQueryData([QUERY_TYPE.TASKS_SLIDE, slide.slideId], () => slide.results)
      })
      return res
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useTaskClasses = (taskId?: number) =>
  useQuery(
    [QUERY_TYPE.TASKS_CLASSES, taskId],
    async () => {
      if (!taskId) return
      return await markupTasksService.fetchTaskClasses(taskId)
    },
    {
      enabled: !!taskId,
      keepPreviousData: true,
      staleTime: Infinity,
    },
  )

export const useTaskSlideResult = (taskId?: number, taskSlideId?: number, urlSlideId?: number) => {
  const queryClient = useQueryClient()
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const slideRes = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, urlSlideId]) || []

  const query = useQuery<IMarkupSlideResult[] | undefined>(
    [QUERY_TYPE.TASKS_SLIDE, urlSlideId],
    async () => {
      if (!taskId || !taskSlideId) return
      const fetchResultHandler = async (cratedSlideResult?: IMarkupSlideResult) => {
        const result = await markupTasksService.fetchTaskSlideResult(taskId, taskSlideId)
        const cachedObject = {}
        let newRes = [...slideRes, ...result]
        // @ts-ignore
        newRes.map((item) => (cachedObject[cratedSlideResult ? item.markupSlideId : item.markupResultId] = item))
        newRes = Object.values(cachedObject)
        return newRes
      }
      const useResult = slideRes?.find((itemResult) => itemResult.markupParticipant?.userId === currentUserId)
      if (useResult) {
        return await fetchResultHandler()
      } else {
        const res = await markupTasksService.createTaskSlideResult(taskId, taskSlideId)
        if (res) {
          return await fetchResultHandler()
        }
      }
    },
    {
      enabled: !!taskId && !!taskSlideId && !!urlSlideId,
    },
  )
  return {
    ...query,
  }
}
export const setTaskSlideResult = (
  options?: UseMutationOptions<
    unknown,
    unknown,
    {
      markupResultStatus: IMarkupResultStatus
      markupResultId: number
      taskId?: number
      taskSlideId?: number
      urlSlideId?: number
    },
    IMarkupSlideResult | null
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation(async ({ markupResultId, markupResultStatus, taskId, taskSlideId, urlSlideId }) => {
    if (!taskId || !taskSlideId) return
    const currentResult = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, urlSlideId])
    const result = await markupTasksService.setTaskSlideResult(taskId, taskSlideId, markupResultId, markupResultStatus)
    const newResult = [...(currentResult?.filter((item) => item.markupResultId !== markupResultId) || []), result]
    queryClient.setQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, urlSlideId], newResult)
  }, options)
}
