import { Space } from 'antd'
import { RadioChangeEvent } from 'antd/es/radio/interface'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { Notification_Tabs, useNotificationsQuery } from 'features/notifications/api/query'
import { viewerPageSlice } from 'pages/viewer'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { InfiniteScroll } from 'shared/ui/infinite-scroll'
import { Center, EmptyElement, SegmentElement, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

import MessageContainer from './MessageContainer'

const StyledAlerts = styled.div`
  height: 100%;
`

const StyledInfiniteScroll = styled(InfiniteScroll)`
  height: 95%;
  padding: 8px;
`

const NoItems = styled(Center)``

type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

export const NoticesList = ({ notificationButton }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useThemeContext()
  const root = useRef(null)
  const [activeTab, setActiveTab] = useState<string>(Notification_Tabs.UNREAD)
  const { fetchNextPage, hasNextPage, ids, isLoading } = useNotificationsQuery()

  const isOpen = useTypedSelector((state) => state.viewerPage.rightPanelType) === SideRightPanelType.NOTIFICATION

  const onClose = () =>
    isOpen &&
    notificationButton?.current &&
    dispatch(viewerPageSlice.actions.toggleViewerRightPanel(SideRightPanelType.NOTIFICATION))

  useOnClickOutside(root, onClose, notificationButton)

  const onChange = (e: RadioChangeEvent) => setActiveTab(e.target.value)

  if (isLoading) {
    return (
      <NoItems>
        <SpinElement />
      </NoItems>
    )
  }
  if (!ids?.length) {
    return (
      <NoItems>
        <EmptyElement description={t('Оповещений нет')} />
      </NoItems>
    )
  }

  const segmentObject: { value: string; label: string }[] = [
    { label: t('Все'), value: Notification_Tabs.NOT_ALL },
    { label: t('Непрочитанные'), value: Notification_Tabs.UNREAD },
  ]

  return (
    <StyledAlerts ref={root}>
      <SegmentElement
        style={{ margin: `8px 16px` }}
        options={segmentObject}
        value={activeTab}
        onChange={onChange}
        colorTheme={theme.theme}
      />
      <StyledInfiniteScroll
        fetchMore={fetchNextPage}
        isLastPage={!hasNextPage}
        itemsLength={ids?.length || 0}
        style={{ overflowX: 'hidden' }}
      >
        <Space style={{ width: '100%' }} size={16} direction="vertical">
          {ids?.map((id) => (
            <MessageContainer key={id} notificationId={id} activeTab={activeTab} />
          ))}
        </Space>
      </StyledInfiniteScroll>
    </StyledAlerts>
  )
}
