import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerAttachmentsSlice } from 'features/attachments'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useViewerAttachmentsSelector, useViewerDispatch } from 'viewer/container'

import { useCommentQuery } from './api/query'
import commentsService from './api/service'
import { commentsSlice } from './model/commentsSlice'
import Comment from './ui/Comment'

type Props = {
  /** id комментария */
  commentId: number
  /** id кейса */
  caseId: number
  /** id слайда */
  slideId?: number
}

const CommentContainer = ({ caseId, commentId, slideId }: Props) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const editingCommentId = useTypedSelector((state) => state.comments.editingCommentId)
  const { data: comment } = useCommentQuery(commentId)
  const { newBbox } = useViewerAttachmentsSelector(viewerId)
  const attachments =
    newBbox && slideId
      ? [
          {
            metadata: {
              bbox: newBbox,
              slideId,
              type: 'VIEWPORT',
            },
          },
        ]
      : comment?.attachments
  const { mutateAsync: onEdit } = useMutation((body: string) => {
    if (attachments && attachments.length)
      viewerDispatch(viewerAttachmentsSlice.actions.selectBbox(attachments[0].metadata.bbox))
    return commentsService.updateComment(caseId, commentId, {
      attachments,
      body,
    })
  })
  const { isLoading, mutate: onRemove } = useMutation(() => commentsService.deleteComment(caseId, commentId), {
    onSuccess: () => {
      queryClient.removeQueries([QUERY_TYPE.COMMENT, { commentId }])
      queryClient.invalidateQueries([QUERY_TYPE.COMMENT, { caseId }])
      viewerDispatch(viewerAttachmentsSlice.actions.selectBbox(null))
      viewerDispatch(viewerAttachmentsSlice.actions.setAttachmentMode(false))
    },
  })
  const setEditing = (commentId: number | null) => {
    if (!commentId) {
      viewerDispatch(viewerAttachmentsSlice.actions.setAttachmentMode(false))
    }
    dispatch(commentsSlice.actions.setEditingComment(commentId))
  }
  return comment ? (
    <Comment
      comment={comment}
      onEdit={onEdit}
      onRemove={onRemove as any}
      isEditing={editingCommentId === commentId}
      setEditing={setEditing}
      withAttachments={!!slideId}
      caseId={caseId}
      isLoading={isLoading}
    />
  ) : null
}

export default CommentContainer
