import { ICasePermission, IPermissionLevel, IPermissionType } from 'entities/permissions/types'
import { del, get, post, put } from 'shared/api'
import { IUsersGroupByWorkspaceName } from 'types/IOptions'
import IShareToken from 'types/IShareToken'

const grantCasePermission = async (
  caseId: number,
  payload: { type: IPermissionType; email?: string; userId?: number },
) =>
  post<ICasePermission>({
    data: payload.email ? { ...payload, email: payload.email.trim() } : payload,
    url: `/case/${caseId}/permission`,
  })

const getCasePermission = async (caseId: number) => {
  const response = await get<{ content: ICasePermission[] }>({
    url: `/case/${caseId}/permission`,
  })
  return response?.content
}

const getUsersSharing = async () => {
  const response = await get<IUsersGroupByWorkspaceName>({
    url: `/users/sharing`,
  })
  return response
}

const getAccessRightByCasePermission = async (token: string) => {
  try {
    const response = await post<IShareToken>({
      ignoreCatch: true,
      url: `/share/${token}`,
    })
    return response
  } catch (e) {
    throw e
  }
}

const deleteCasePermission = async (caseId: number, permissionId: number) =>
  del({
    url: `/case/${caseId}/permission/${permissionId}`,
  })

const changeCasePermission = async (
  caseId: number,
  permissionId: number,
  data: { permissionLevel: IPermissionLevel },
) =>
  put({
    data,
    url: `/case/${caseId}/permission/${permissionId}`,
  })

const permissionsService = {
  changeCasePermission,
  deleteCasePermission,
  getAccessRightByCasePermission,
  getCasePermission,
  getUsersSharing,
  grantCasePermission,
}

export default permissionsService
