import ParentCaseList from 'features/cases/ParentCaseList'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyElement } from 'shared/ui/kit'
import { TCaseLinkDTO } from 'types/ICase'

type EmptyStateProps = {
  selectedRelated?: TCaseLinkDTO[] | null
}

export const EmptyCases: React.FC<EmptyStateProps> = ({ selectedRelated }) => {
  const { t } = useTranslation()
  return (
    <div>
      <EmptyElement
        style={{
          marginBottom: selectedRelated?.length ? 0 : undefined,
          padding: selectedRelated?.length ? 0 : '16px',
        }}
        data-testid={`no-images-element`}
        description={<span>{t('Изображения еще не загружены')}</span>}
      />
      {!!selectedRelated?.length && <ParentCaseList cases={selectedRelated} />}
    </div>
  )
}
