import { TopBorder } from 'shared/ui/divider'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

const PRODUCT_NAME =
  'Цифровая платформа для приема, маршрутизации и централизованного хранения данных с целью осуществления цифрового анализа и телеконсультаций прижизненных патологоанатомических исследований OneCell по ТУ 32.50.50-001-06040816-2023'
const MANUFACTURER =
  'ООО "ВАНСЕЛ", Россия, 119571, Г.Москва, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ТРОПАРЕВО-НИКУЛИНО,ПР-КТ ВЕРНАДСКОГО, Д. 96'
const RELEASE_VERSION = 'OneCell Platform_v. 2.21.0'
const ISSUE_DATE = '14.04.2023'
const RC_INFO = 'Регистрационное удостоверение № РЗН 2024/22427 от 15.04.2024'

const StyledWrapper = styled.div`
  display: grid;
  padding-top: 16px;
  row-gap: 16px;
  grid-template-columns: minmax(auto, 434px);
`

const StyledText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledIconElement = styled(IconElement)`
  color: var(--color-text-3);
`

const StyledIcon = styled(StyledIconElement)`
  flex: 0 0 24px;
  margin-right: 10px;
`

const MarkingBody = () => (
  <>
    <TopBorder />
    <StyledWrapper data-testid="marking-descr">
      <StyledText>{PRODUCT_NAME}</StyledText>
      <Row>
        <StyledIcon name="releaseVersion" />
        <StyledText>{RELEASE_VERSION}</StyledText>
      </Row>
      <Row>
        <StyledIcon name="issueDate" />
        <StyledText>{ISSUE_DATE}</StyledText>
      </Row>
      <Row>
        <StyledIcon name="manufacturer" />
        <StyledText>{MANUFACTURER}</StyledText>
      </Row>
      <Row style={{ justifyContent: 'space-between' }}>
        <StyledText>{RC_INFO}</StyledText>
        <StyledIconElement name="rcInfo" size="auto" />
      </Row>
    </StyledWrapper>
  </>
)

export default MarkingBody
