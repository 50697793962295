import { Timeline } from 'antd'
import caseService from 'features/cases/api/service'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { formatDateForDisplay } from 'shared/lib/date'
import { TopBorder } from 'shared/ui/divider'
import { SpinElement, TitleElement } from 'shared/ui/kit'
import { ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components'

export const StatusModal = styled(ModalProvider)`
  .ant-modal-header {
    padding-bottom: 8px !important;

    & .ant-modal-title {
      font-size: 12px;
    }
  }

  .ant-modal-body {
    min-height: 65px;
    max-height: calc(100vh - 46px);
    overflow-y: auto;
    width: 344px;
    padding: 0 6px 16px 16px !important;
    scrollbar-gutter: stable;

    & .ant-timeline-item {
      padding-bottom: 8px !important;

      &:last-child {
        padding-bottom: 0 !important;

        & .ant-timeline-item-tail {
          display: none;
        }
      }
    }

    & .ant-timeline-item-content {
      margin-left: 21px;
    }
  }
`

const StyledTimelineWrapper = styled.div`
  margin-top: 10px !important;
`

/** Цвета точек */
export enum EDotColors {
  BLUE = 'var(--color-blue)',
  GREEN = 'var(--color-green)',
  GREY = 'var(--color-bg-4)',
}

/** Статусы кейса */
export enum ECaseStage {
  /** Открыт */
  OPEN = 'Открыт',
  /** Ждет слайды */
  WAITING_SLIDES = 'Ожидает слайды',
  /** На распределение */
  ON_DISTRIBUTION = 'На распределении',
  /** Распределен */
  ASSIGNED = 'Распределён',
  /** Отказ */
  ON_DISTRIBUTION_DECLINED = 'Перераспределение',
  /** Завершен */
  COMPLETED = 'Подписан',
}

const TimelineDot = styled.div<{ color: string }>`
  height: 8px;
  width: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  position: relative;
  top: -2px;
`

const StyledTimelineItem = styled(Timeline.Item)`
  & .ant-timeline-item-head {
    background-color: rgba(0, 0, 0, 0);
  }

  & .ant-timeline-item-tail {
    border-left: 2px dotted var(--color-border-1);
    top: 8px;
  }
`

const CustomTimelineItem: FC<PropsWithChildren<{ color: EDotColors }>> = ({ children, color }) => (
  <StyledTimelineItem dot={<TimelineDot color={color} />}>{children}</StyledTimelineItem>
)

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledDate = styled.span`
  color: var(--color-text-3);
`

const StyledText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
`

/** Цвета для статусов */
export const dotColors = new Map([
  [ECaseStage.OPEN, EDotColors.BLUE],
  [ECaseStage.COMPLETED, EDotColors.GREY],
  [ECaseStage.ON_DISTRIBUTION_DECLINED, EDotColors.BLUE],
  [ECaseStage.WAITING_SLIDES, EDotColors.BLUE],
  [ECaseStage.ON_DISTRIBUTION, EDotColors.BLUE],
  [ECaseStage.ASSIGNED, EDotColors.GREEN],
])

type IStagesInfo = {
  /** Дата стадии. */
  date: string
  /** Статус стадии. */
  status: ECaseStage
  /** Описание или дополнительная информация о стадии. */
  text: string
  /** имя врача, на которого распределен данный кейс  */
  assignedDoctor: string
}

type Props = {
  currentCase: { caseId?: number; wsId?: number }
}
export const StatusModalBody = ({ currentCase }: Props) => {
  const [stagesInfo, setStagesInfo] = useState<IStagesInfo[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (currentCase?.caseId && currentCase?.wsId) {
      setLoading(true)
      const history = caseService.getCaseHistory(currentCase?.caseId, currentCase?.wsId)
      history.then((res) => {
        setStagesInfo(
          res.map((item) => ({
            assignedDoctor: item?.newAssignedDoctor?.fullname,
            date: formatDateForDisplay(item.stageChangedAt),
            status: ECaseStage[item.newStage],
            text: item.comment,
          })),
        )
        setLoading(false)
      })
    }
    return () => {
      setStagesInfo([])
    }
  }, [])

  return (
    <SpinElement style={{ height: 65 }} spinning={isLoading}>
      <TopBorder />
      <StyledTimelineWrapper>
        <Timeline>
          {stagesInfo.map((currentStage, index) => {
            const { assignedDoctor, date, status, text } = currentStage

            return (
              <CustomTimelineItem key={index} color={dotColors.get(status) || EDotColors.BLUE}>
                <StyledTitleWrapper>
                  <TitleElement level={3} style={{ fontWeight: 600 }}>
                    {status}
                  </TitleElement>
                  <StyledDate>{date}</StyledDate>
                </StyledTitleWrapper>
                {status === ECaseStage.ASSIGNED && <StyledText>{assignedDoctor}</StyledText>}
                {text && <StyledText>{text}</StyledText>}
              </CustomTimelineItem>
            )
          })}
        </Timeline>
      </StyledTimelineWrapper>
    </SpinElement>
  )
}
