import { SumpSlidesFilters } from 'features/sump/api/query'
import { UploadedFilesAvailableFilters } from 'features/sump/api/service'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import { EUploadedFileTab } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

import { FetchUploadedFilesParams, uploadedFileService } from './service'

/** Список файлов по табу */
export const useUploadedFilesQuery = (uploadedFileTab: string, filters: SumpSlidesFilters, size?: number) => {
  const wsId = Number(useCurrentWorkspaceId())

  const uploadedFilesQuery = useInfiniteQuery<
    IListOfItemsCursorCases<IUploadedFileDTO> | undefined,
    unknown,
    IUploadedFileDTO[]
  >(
    [QUERY_TYPE.UPLOADED_FILE, uploadedFileTab, JSON.stringify(filters), wsId, size],
    async (data) => {
      const queryParams: FetchUploadedFilesParams = filters.query?.length ? filters : { uploadedFileTab, ...filters }
      return await uploadedFileService.fetchUploadedFile(wsId, queryParams, data?.pageParam, size)
    },
    {
      getNextPageParam: (lastPage) => lastPage?.nextPageCursor,
      getPreviousPageParam: (firstPage) => firstPage?.previousPageCursor,
      select: (data): InfiniteData<IUploadedFileDTO[]> => ({
        pageParams: data.pages?.map((list) => {
          if (list) {
            const { content, ...params } = list
            return params
          }
        }),
        pages: data.pages.map((list) => list?.content ?? []),
      }),
    },
  )

  const pages = uploadedFilesQuery.data?.pages || []
  const uploadedFileList = pages.flatMap((page) => page)

  return { ...uploadedFilesQuery, uploadedFileList }
}

/** Количество элементов в табах */
export const useUploadedFilesCount = () => {
  const wsId = Number(useCurrentWorkspaceId())
  return useQuery(
    [QUERY_TYPE.UPLOADED_FILE_COUNT, wsId],
    async () => await uploadedFileService.fetchUploadedFileCounts(wsId),
  )
}

export const useUploadedFilesAvailableFiltersQuery = (wsId: number, uploadedFileTab: EUploadedFileTab) =>
  useQuery<UploadedFilesAvailableFilters | undefined, unknown>(
    [QUERY_TYPE.UPLOADED_FILES_FILTERS, uploadedFileTab, wsId],
    async () => uploadedFileService.fetchAvailableFilters(wsId, uploadedFileTab),
  )
