/* eslint-disable i18next/no-literal-string */
import { Dropdown, Menu, Space } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'
import styled from 'styled-components'

import { IconElement } from './IconElement'

const StyledMenu = styled(Menu)`
  ${() => modalBackground}
  ${() => modalShadow}

  padding: 0;

  .ant-dropdown-menu-item {
    padding: 0;
    color: var(--color-text-1);
  }
`

const Label = styled.span`
  color: var(--color-text-3);
  font-size: 14px;
  font-weight: 500;

  :hover {
    color: var(--color-text-1);
    /* background-color: var(--color-bg-3); */
  }
`

const StyledIconElement = styled(IconElement)`
  color: var(--color-text-3);
  path {
    stroke: var(--color-text-3);
  }
`

const StyledSpace = styled(Space)`
  &:hover ${Label} {
    color: var(--color-text-1);
  }

  &:hover ${StyledIconElement} {
    color: var(--color-text-1);
    path {
      stroke: var(--color-text-1);
    }
  }
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1;
  color: var(--color-text-3);
  cursor: pointer;

  &:hover {
    color: green;
    /* stroke: var(--color-text-1); */
  }
`

const StyledLanguageText = styled.span`
  color: var(--color-text-3);
`

const MenuItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 8px 8px 16px;
  line-height: 16px;

  &:hover ${StyledLanguageText} {
    color: var(--color-text-1);
  }

  &:hover ${StyledIconElement} {
    color: var(--color-text-1);
    path {
      stroke: var(--color-text-1);
    }
  }
`

export const LanguageDropdown: FC = () => {
  const { i18n } = useTranslation()
  const menu = (
    <StyledMenu>
      <StyledMenu data-testid="language-select">
        <Menu.Item key="sub_ru" onClick={() => i18n.changeLanguage(I18N_LANGUAGES.RUSSIAN)}>
          <MenuItemContainer>
            <StyledLanguageText>Русский</StyledLanguageText>
            {!!~i18n.language?.indexOf(I18N_LANGUAGES.RUSSIAN) && <StyledIconElement name="checkSmall2" size="md" />}
          </MenuItemContainer>
        </Menu.Item>
        <Menu.Item key="sub_eng" onClick={() => i18n.changeLanguage(I18N_LANGUAGES.ENGLISH)}>
          <MenuItemContainer>
            <StyledLanguageText>English</StyledLanguageText>
            {!!~i18n.language?.indexOf(I18N_LANGUAGES.ENGLISH) && <StyledIconElement name="checkSmall2" size="md" />}
          </MenuItemContainer>
        </Menu.Item>
      </StyledMenu>
    </StyledMenu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={['hover']}>
      <ItemWrapper>
        <StyledSpace size={4}>
          <StyledIconElement name="language" fill={'var(--color-bg-1)'} size="lg" />
          <Label>{~i18n.language?.indexOf('ru') ? 'Русский' : 'English'}</Label>
          <StyledIconElement name="dropDown" size="md" />
        </StyledSpace>
      </ItemWrapper>
    </Dropdown>
  )
}
