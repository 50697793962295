import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery } from 'features/cases/api/query'
import { selectAtlasViewerUrlSlideId, SideRightPanelType, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { ButtonElement, IconButtonElement, IconElement, Row, TitleElement, TooltipElement } from 'shared/ui/kit'
import { MainToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import styled from 'styled-components/macro'
import { SettingsNameEnum } from 'types/IWorkspaces'

type Props = {
  className?: string
}

const ToolbarWrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr 450px;
  align-items: center;
  padding: 8px;
`

const CenterItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const RightItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const ToolbarButton = styled(IconButtonElement)<{ active?: boolean }>`
  margin: 0 4px;
  color: ${({ active }) => (active ? 'var(--color-white) !important' : 'transparent')};
`

const ShareButton = styled(ButtonElement)`
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  height: 32px;
`

const CaseName = styled(TitleElement)`
  max-width: 100%;
  white-space: nowrap;
  & > * {
    width: 100%;
  }
`

const AtlasTopToolbarContainer = ({ className }: Props) => {
  const dispatch = useDispatch()
  const urlSlideId = useSelector(selectAtlasViewerUrlSlideId)
  const { data: urlCase } = useCaseQuery({ caseId: urlSlideId, source: 'ATLAS' })
  const leftPanel = useTypedSelector((state) => state.viewerPage.leftPanelType)
  const rightPanel = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const { t } = useTranslation()
  return (
    <ToolbarWrapper className={className}>
      <Row>
        <Link style={{ display: 'flex' }} to={'/atlas'}>
          <MainToolbarButton icon={<IconElement name="arrowBack" />} />
        </Link>
        <TooltipElement title={t('Слайды')} placement="bottom">
          <ToolbarButton
            active={leftPanel === 'SLIDES'}
            onClick={() => dispatch(viewerPageSlice.actions.toggleViewerLeftPanel('SLIDES'))}
            icon={<IconElement name="imageList" />}
          />
        </TooltipElement>
        {getBooleanOrDefaultAccess(SettingsNameEnum.AiTools, true, currentWorkspace) && (
          <TooltipElement title={t('Поиск')} placement="bottom">
            <MainToolbarButton
              active={leftPanel === 'SEARCH'}
              onClick={() => dispatch(viewerPageSlice.actions.toggleViewerLeftPanel('SEARCH'))}
              icon={<IconElement name="searchAtlas" />}
            />
          </TooltipElement>
        )}
      </Row>
      <CenterItems>
        <CaseName level={3} ellipsis>
          {urlCase?.name}
        </CaseName>
      </CenterItems>

      <RightItems>
        <TooltipElement title={t('О Случае')} placement="bottom">
          <MainToolbarButton
            active={rightPanel === SideRightPanelType.CASE}
            onClick={() => dispatch(viewerPageSlice.actions.toggleViewerRightPanel(SideRightPanelType.CASE))}
            icon={<IconElement name="Case" />}
          />
        </TooltipElement>
      </RightItems>
    </ToolbarWrapper>
  )
}

export default AtlasTopToolbarContainer
