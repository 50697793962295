import { defaultAnnotationClass } from 'features/annotations'
import { AnnotationClassesEnum } from 'features/annotations/ui/AnnotationClassSelect'
import { notices } from 'features/notices'
import { Feature, Overlay } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import { Geometry } from 'ol/geom'
import GeometryType from 'ol/geom/GeometryType'
import Polygon from 'ol/geom/Polygon'
import { Draw } from 'ol/interaction'
import { createRegularPolygon } from 'ol/interaction/Draw'
import VectorLayer from 'ol/layer/Vector'
import OverlayPositioning from 'ol/OverlayPositioning'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { StompClientContext, WsResponseKi67 } from 'processes/stomp'
import { useContext, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { SpinElement } from 'shared/ui/kit'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import { v4 as uuidv4 } from 'uuid'
import { useViewerDispatch, viewerSlice } from 'viewer/container'
import { formatKi67Area } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { dangerStyle, styleByAnnotationClass } from 'viewer/map/layers/olStyles'
import { getFeaturesFromGeoJson } from 'viewer/map/lib/utils'

import { Ki67Context } from './Ki67Context'

type Props = {
  map: IMapOl
  viewerId: TViewerId
  slideId: number
  mppX: number
}

const KI67DrawInteraction = ({ map, mppX, slideId, viewerId }: Props) => {
  const { singleRequest } = useContext(StompClientContext)
  const viewerDispatch = useViewerDispatch(viewerId)
  const { addCircle, addFeatures } = useContext(Ki67Context)
  const canFinish = useRef<boolean>(false)
  const currentDraw = useRef<Draw>()
  const { t } = useTranslation()

  useHotkeys('esc', () => {
    viewerDispatch(viewerSlice.actions.setKi67Mode(false))
  })

  useEffect(() => {
    const layer = new VectorLayer({
      source: new VectorSource({}),
      style: styleByAnnotationClass({ annotationClass: defaultAnnotationClass, isTask: false, opacity: 0 }),
      zIndex: 100,
    })
    map?.addLayer(layer)

    const draw = new Draw({
      finishCondition: (evt) => canFinish.current,
      geometryFunction: createRegularPolygon(50),
      source: layer.getSource(),

      style: (feature) => {
        const geom = feature.getGeometry()
        if (geom instanceof Geometry) {
          const circleArea = formatKi67Area(geom, mppX)

          if (circleArea !== 0) {
            canFinish.current = circleArea < 300000
            return circleArea > 300000
              ? dangerStyle
              : styleByAnnotationClass({ annotationClass: AnnotationClassesEnum.Background, isTask: false, opacity: 0 })
          }
        }
        return new Style({
          image: new CircleStyle({
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
            radius: 5,
            stroke: new Stroke({
              color: '#56a899',
            }),
          }),
          stroke: new Stroke({
            color: 'rgba(255, 255, 255, 0.0)',
            width: 2,
          }),
        })
      },
      type: GeometryType.LINE_STRING,
    })

    draw.on('drawend', async (evt) => {
      const id = uuidv4()
      const poly = evt.feature.getGeometry()
      const feature = new Feature(poly)
      addCircle(id, feature)

      viewerDispatch(viewerSlice.actions.setKi67Mode(false))
      const spinElem = document.createElement('div')
      ReactDOM.render(<SpinElement />, spinElem)

      const spinner = new Overlay({
        element: spinElem,
        position: (evt?.feature?.getGeometry() as Polygon).getInteriorPoint()?.getCoordinates(),
        positioning: OverlayPositioning.CENTER_CENTER,
      })
      map?.addOverlay(spinner)

      const format = new GeoJSON()

      const data = format.writeFeatures([feature])
      const newData = data.replace('null', '{}')
      try {
        const ki67Response = await singleRequest<WsResponseKi67>({
          payload: { regionOfInterests: newData, slideId: slideId },
          publishDest: '/app/cell-segmentation/',
          subscriptionDest: '/user/topic/cell-segmentation/',
        })
        if (!ki67Response.payload) {
          notices.error({
            key: 'ki67',
            message: t('Ошибка при вычислении'),
          })
        } else {
          const featuresCollection = getFeaturesFromGeoJson(ki67Response.payload.cellsRoisGeo)
          addFeatures(id, featuresCollection, {
            cellsNegative: ki67Response.payload.cellsNegative,
            cellsOther: ki67Response.payload.cellsOther,
            cellsPositive: ki67Response.payload.cellsPositive,
            cellsTotal: ki67Response.payload.cellsTotal,
          })
        }
        map?.removeOverlay(spinner)
      } catch (e) {
        notices.error({
          key: 'ki67',
          message: t('Ошибка при вычислении'),
        })
        map?.removeOverlay(spinner)
      }
    })
    map?.addInteraction(draw)
    currentDraw.current = draw
    return () => {
      map?.removeInteraction(draw)
      map?.removeLayer(layer)
    }
  }, [])

  return null
}

export default KI67DrawInteraction
