import { Dispatch } from '@reduxjs/toolkit'
import caseService from 'features/cases/api/service'
import { EFavoriteList } from 'features/cases-management/types/ECaseTableType'
import { TCaseFavorites } from 'features/cases-management/types/TCaseApi'
import { reportsSlice } from 'features/reports'
import React, { MouseEvent, MutableRefObject } from 'react'
import { UseMutateFunction } from 'react-query'
import { IIconElementProps } from 'shared/ui/kit'
import ICaseStatus from 'types/ICaseStatus'

export type IButtonData = {
  /** Название иконки кнопки */
  iconName: IIconElementProps['name']
  /** Функция обработчика нажатия на кнопку. */
  onClick: () => void
  /** Описание кнопки. */
  title?: string
  /** Видимость кнопки. */
  hidden?: boolean
  customButton?: React.FunctionComponent<any>
}

export const handleEditClick = (dispatch: Dispatch, caseFormModal: MutableRefObject<any>) => {
  dispatch(reportsSlice.actions.toggleDictionaryLoading(true))
  caseFormModal.current.open()
  dispatch(reportsSlice.actions.toggleDictionaryLoading(false))
}
export const handleAddImageClick = (): void => {}
export const handleShareClick = (): void => {}
export const handleDeclineClick = (declineModal: MutableRefObject<any>) => {
  declineModal?.current?.open()
}
export const handleSaveClick = (): void => {}
export const handleStatusModalClick = (statusHistoryModalRef: MutableRefObject<any>, e?: MouseEvent) => {
  statusHistoryModalRef?.current?.toggle()
  e?.stopPropagation()
}
export const reportCreationModalClick = (reportCreationModalRef: MutableRefObject<any>, e?: MouseEvent) => {
  reportCreationModalRef?.current?.toggle()
  e?.stopPropagation()
}
export const handleUrgentlyClick = async (
  workspaceId: number | undefined,
  refetchCases: () => void,
  caseId: number,
  urgent?: boolean,
) => {
  await caseService.changeUrgentCase(String(workspaceId), urgent, caseId)
  refetchCases()
}
export const handleRemoveClick = async (
  deleteCase: UseMutateFunction<any, unknown, void, unknown>,
  refetchCases: () => void,
) => {
  deleteCase(undefined, {
    onSuccess: () => refetchCases(),
  })
}
export const handleCancelClick = (): void => {}
export const handleAddReportClick = (ref: MutableRefObject<any>) => ref.current.open()
/** Изменение списка отслеживаемых */
export const handleWatchingListChange = (
  cb: UseMutateFunction<any, unknown, TCaseFavorites, unknown>,
  caseId: number,
): void => {
  cb({ caseIds: [caseId], list: EFavoriteList.WATCHING })
}

export const handleToArchiveClick = async (
  status: ICaseStatus,
  changeStatus: UseMutateFunction<any, unknown, ICaseStatus | null, unknown>,
  refetchCases: () => void,
) => {
  changeStatus(status, {
    onSuccess: () => refetchCases(),
  })
}
