import { push } from 'connected-react-router'
import { notices } from 'features/notices'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import { userService } from 'processes/user'
import userSlice from 'processes/user/model/userSlice'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { removeUserDataFromStorage, setUserDataToStorage } from 'shared/lib/local-storage'
import { PageHeader } from 'shared/ui/header'
import { SpaceElement, SpinElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { DEEP_LINK_ERROR_CODE, DEEP_LINK_ERROR_CODES_MESSAGES } from 'types/DeepLink'

const StyledLogin = styled.div`
  background-color: var(--color-bg-1);
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const DeepLinkRoute = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch<{ token: string; linkParams: string }>()
  const [caseId, setCaseId] = useState<number>(0)
  const { slideGrouped } = useSlideGrouped(caseId)
  const { t } = useTranslation()

  useEffect(() => {
    const { linkParams, token } = match.params
    const workspaceId = localStorage.getItem('workspaceId')

    if (caseId && workspaceId) {
      const firstSlideId = slideGrouped?.[0]?.slides[0]?.slideId
      dispatch(push(`/workspace/${workspaceId}/viewer/${caseId}?slideId=${firstSlideId}`))
    } else if (slideGrouped) {
      removeUserDataFromStorage()
      dispatch(userSlice.actions.setUserTokens({}))
      userService
        .deepLinkAuth(linkParams, token)
        .then((res) => {
          if (res.loginAs) {
            dispatch(userSlice.actions.setUserTokens(res.loginAs))
            setUserDataToStorage(res.loginAs)
          }
          localStorage.setItem('workspaceId', res.workspace.workspaceId)
          setCaseId(res.case.caseId)
        })
        .catch((err) => {
          const response = err?.response?.data
          const code: DEEP_LINK_ERROR_CODE = response?.error?.code || 'NOT_FOUND_CODE'
          notices.error({
            message: t(DEEP_LINK_ERROR_CODES_MESSAGES[code]),
          })
          dispatch(push(response.logout ? '/login' : `/`))
        })
    }
  }, [caseId, slideGrouped])

  return (
    <StyledLogin>
      <PageHeader />
      <SpaceElement size={100} />
      <Content>
        <>
          <SpinElement />
          <SpaceElement size={100} />
          <TitleElement level={1}>{t('Открываем случай')}</TitleElement>
        </>
      </Content>
    </StyledLogin>
  )
}
