import { FilterConfirmProps } from 'antd/es/table/interface'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement, IconElement, MaskedDatePickerElement } from 'shared/ui/kit'
import styled from 'styled-components'

const DateTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  ${() => modalBackground}
  ${() => modalShadow}
`

const DateRangeLabel = styled.div`
  flex-basis: 20%;
`

type Props = {
  setSelectedKeys: (selectedKeys: React.Key[]) => void
  selectedKeys: React.Key[]
  filtersRange: (selectedKeys: React.Key[], columnName?: string) => void
  filtersRangeColumn?: string
  clearFilters: (() => void) | undefined
  confirm: (param?: FilterConfirmProps | undefined) => void
}

const DateFilterDropdown = ({
  clearFilters,
  confirm,
  filtersRange,
  filtersRangeColumn,
  selectedKeys,
  setSelectedKeys,
}: Props) => {
  const { t } = useTranslation()
  return (
    <DateTimeContainer>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <DateRangeLabel>{t('От')}</DateRangeLabel>
        <MaskedDatePickerElement
          value={selectedKeys[0] ? new Date(selectedKeys[0]) : null}
          onChange={(e) => {
            const newKeys = [...selectedKeys]
            newKeys[0] = e ? (new Date(e).toISOString() as any) : undefined
            setSelectedKeys(newKeys)
          }}
          allowClear={true}
        />
      </div>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <DateRangeLabel>{t('До')}</DateRangeLabel>
        <MaskedDatePickerElement
          value={selectedKeys[1] ? new Date(selectedKeys[1]) : null}
          onChange={(e) => {
            const newKeys = [...selectedKeys]
            newKeys[1] = e ? (new Date(e).toISOString() as any) : undefined
            setSelectedKeys(newKeys)
          }}
          allowClear={true}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <ButtonElement
          disabled={!selectedKeys}
          onClick={async () => {
            clearFilters && (await clearFilters())
            await confirm()
          }}
        >
          Reset
        </ButtonElement>
        <ButtonElement
          onClick={async () => {
            await filtersRange(selectedKeys, filtersRangeColumn)
            await confirm()
          }}
          icon={<IconElement name="search" />}
          type="primary"
        />
      </div>
    </DateTimeContainer>
  )
}

export default DateFilterDropdown
