import { CasePlate } from 'entities/case/ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TCaseLinkDTO } from 'types/ICase'

type ParentCaseListProps = {
  cases: TCaseLinkDTO[]
}

const GridContainer = styled.div`
  display: grid;
  gap: 8px;
`

const PillContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`

const StyledDescription = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin: auto;
  color: var(--color-text-3);
`

const ParentCaseList: React.FC<ParentCaseListProps> = ({ cases }) => {
  const { t } = useTranslation()

  return (
    <GridContainer>
      <StyledDescription>{t('Можно посмотреть случай-основание')}:</StyledDescription>
      <PillContainer>
        {cases.map(({ caseId, name }) => (
          <CasePlate key={`${caseId}-${name}`} caseName={name} caseId={caseId} />
        ))}
      </PillContainer>
    </GridContainer>
  )
}

export default ParentCaseList
