import { useTypedSelector } from 'app/redux/lib/selector'
import { useEffect, useRef } from 'react'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { SettingsNameEnum } from 'types/IWorkspaces'

export const useAccessRightsMap = () => {
  const defaults = useRef([
    {
      default: false,
      key: SettingsNameEnum.EnableAiSegmentation,
    },
    {
      default: true,
      key: 'enableAiKi67',
    },
    {
      default: true,
      key: 'enableAiMitosis',
    },
    {
      default: false,
      key: 'enableAiPvProstate',
    },
    {
      default: false,
      key: 'enableAiArtefacts',
    },
    {
      default: true,
      key: 'enableAiMedicalNeuronets_crc',
    },
  ])
  const keyComparsionMap = useRef<Map<SettingsNameEnum, string>>(new Map())
  const accessRightsMap = useRef<Map<string, boolean>>(new Map())
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)

  useEffect(() => {
    /** Порядок должен совпадать с порядком при отображении.
     * Нужно для корректного выбора дефолтного значения
     */
    keyComparsionMap.current.set(SettingsNameEnum.EnableAiArtefacts, 'ARTEFACTS')
    keyComparsionMap.current.set(SettingsNameEnum.EnableAiKi67, 'KI67')
    keyComparsionMap.current.set(SettingsNameEnum.EnableAiMitosis, 'MITOSIS')
    keyComparsionMap.current.set(SettingsNameEnum.EnableAiPvProstate, 'PV_PROSTATE')
    keyComparsionMap.current.set(SettingsNameEnum.EnableAiSegmentation, 'SEGMENTATION')
    keyComparsionMap.current.set(SettingsNameEnum.EnableAiMedicalNeuronets_crc, 'MEDICALNEURONETS_CRC')
    keyComparsionMap.current.forEach((value, key) => {
      // find current default record and set right defaults for access map
      const cd = defaults.current.find((d) => d.key === key)
      const d = cd === undefined ? true : cd.default
      const access = getBooleanOrDefaultAccess(key, d, currentWorkspace)
      accessRightsMap.current.set(value, access)
    })
  }, [currentWorkspace])
  return { accessRightsMap }
}
