import { Col } from 'antd'
import { TextAreaElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: 1000px;
  overflow-y: auto;
  height: 100vh;

  .ant-form-item-label {
    padding: 0;
    margin-bottom: 4px;
  }

  .ant-form-item-label > label {
    color: var(--color-text-3);
    font-size: 12px;
    line-height: 16px;
  }
`

export const StyledTextArea = styled(TextAreaElement)`
  &.ant-input {
    font-size: 14px;
    line-height: 20px;
    min-height: 48px !important;
    //68px = 3 строки
    height: 68px;
    padding: 4px 8px;
    resize: vertical !important;
  }
`

export const Footer = styled(Col)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  min-height: auto;
  padding: 8px 16px;
  border-top: 1px solid var(--color-border-1);
`
