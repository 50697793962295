import { Feature } from 'ol'
import { createContext, FC, useCallback, useEffect, useRef, useState } from 'react'

const EMPTY_OBJECT = {}
const EMPTY_ARRAY: any[] = []

type FeaturesMap = Record<
  string,
  {
    points: Feature<any>[]
    circle: Feature<any>
    info?: {
      cellsTotal: number
      cellsPositive: number
      cellsNegative: number
      cellsOther: number
    }
    isHidden?: boolean
  }
>

export const Ki67Context = createContext<{
  addFeatures: (
    id: string,
    features: Feature<any>[],
    info: {
      cellsTotal: number
      cellsPositive: number
      cellsNegative: number
      cellsOther: number
    },
  ) => void
  addCircle: (id: string, circle: Feature<any>) => void
  removeKi67: (id: string) => void
  toggleKi67: (id: string, isHidden: boolean) => void
  data: FeaturesMap
}>({
  addCircle: (id: string, circle: Feature<any>) => {},
  addFeatures: (id: string, features: Feature<any>[]) => {},
  data: EMPTY_OBJECT,
  removeKi67: (id: string) => {},
  toggleKi67: (id: string, isHidden: boolean) => {},
})

type Props = {
  slideId: number
}

export const Ki67Provider: FC<Props> = ({ children, slideId }) => {
  const [data, setData] = useState<FeaturesMap>(EMPTY_OBJECT)
  const prevData = useRef<FeaturesMap>(EMPTY_OBJECT)

  useEffect(() => {
    prevData.current = EMPTY_OBJECT
    setData(prevData.current)
  }, [slideId])

  const addCircle = useCallback((id: string, circle: Feature<any>) => {
    const dataById = prevData.current[id]
    prevData.current = {
      ...prevData.current,
      [id]: {
        circle,
        points: dataById?.points || EMPTY_ARRAY,
      },
    }
    setData(prevData.current)
  }, [])
  const addFeatures = useCallback(
    (
      id: string,
      points: Feature<any>[],
      info: {
        cellsTotal: number
        cellsPositive: number
        cellsNegative: number
        cellsOther: number
      },
    ) => {
      const dataById = prevData.current[id]
      if (!dataById?.circle) {
        return
      }
      prevData.current = {
        ...prevData.current,
        [id]: {
          circle: dataById?.circle,
          info,
          points,
        },
      }
      setData(prevData.current)
    },
    [],
  )

  const removeKi67 = useCallback((id: string) => {
    const newData = { ...prevData.current }
    delete newData[id]
    prevData.current = newData
    setData(prevData.current)
  }, [])

  const toggleKi67 = useCallback((id: string, isHidden: boolean) => {
    const dataById = prevData.current[id]
    prevData.current = {
      ...prevData.current,
      [id]: {
        ...dataById,
        isHidden,
      },
    }
    setData(prevData.current)
  }, [])

  return (
    <Ki67Context.Provider
      value={{
        addCircle,
        addFeatures,
        data,
        removeKi67,
        toggleKi67,
      }}
    >
      {children}
    </Ki67Context.Provider>
  )
}
