import { useTypedSelector } from 'app/redux/lib/selector'
import {
  deleteAnnotationsMutation,
  IAnnotationQuery,
  QueryFlags,
  updateAnnotationsQuery,
  useAnnotationQuery,
} from 'features/annotations/api'
import { checkLocalAnnotation } from 'features/annotations/lib/helpers'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { notices } from 'features/notices'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { ISlideAnnotation } from 'types/IAnnotations'
import { IMarkupTask } from 'types/IMarkupTask'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { isObjectsCounting, ObjectsAnnotationType } from 'viewer/map/layers/annotations/lib/helpers'

import { AnnotationClassesEnum } from './AnnotationClassSelect'
import AnnotationListItem from './AnnotationListItem'

type Props = {
  annotationId: number
  annotationClass: string
  onClassSelect: (selectedClass: AnnotationClassesEnum) => void
}

const AnnotationListItemContainer = ({ annotationClass, annotationId, onClassSelect }: Props) => {
  const queryClient = useQueryClient()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { data: annotation } = useAnnotationQuery(caseId, slideId, annotationId)
  const dispatch = useDispatch()
  const viewerDispatch = useViewerDispatch(viewerId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const userData = taskData?.participants?.find((item) => item.userId === currentUserId)
  const isValidateRole = userData?.access === 'VALIDATE'
  const annotationsIsVisible = useTypedSelector((state) => state.annotations.annotationsIsVisible)
  const annotationsIds = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
  const ids = annotationsIds?.ids || []
  const { t } = useTranslation()
  // Обработка ошибки при удалении аннотации
  const onAnnotationDeleteError = () => {
    const caseData = queryClient.getQueryData<ISlideAnnotation[]>([QUERY_TYPE.ANNOTATION, { caseId }])
    const currentAnnotations = caseData?.find((item) => item.slideId === slideId)?.annotations

    queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
      date: new Date(),
      ids,
    })

    // обновляем хранилище аннотаций по кейсу (возвращаем аннотацию)
    slideId &&
      queryClient.setQueryData(
        [QUERY_TYPE.ANNOTATION, { caseId }],
        caseData?.length && caseData.find((item) => item.slideId === slideId)
          ? [
              ...caseData.map((annotationsGroup) => {
                if (annotationsGroup.slideId === slideId) {
                  return {
                    annotations: currentAnnotations ? [...currentAnnotations, annotation] : [annotation],
                    slideId: annotationsGroup.slideId,
                  }
                }
                return annotationsGroup
              }),
            ]
          : [{ annotations: [annotation], slideId }],
      )
    notices.error({
      message: t('Ошибка при удалении аннотации'),
    })
  }
  const { isLoading: isDeleting, mutate: deleteAnnotation } = deleteAnnotationsMutation(
    {
      caseId,
      currentUserId,
    },
    {
      onError: onAnnotationDeleteError,
    },
  )

  const onDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (annotation && !isDeleting) {
      const slideAnnotationId = annotation.slideAnnotationId
      if (checkLocalAnnotation(annotation) || !slideAnnotationId) return
      selectedAnnotationsIds?.includes(slideAnnotationId) &&
        viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
      deleteAnnotation({ annotations: [annotation] })
      updateAnnotationsQuery({
        caseId,
        flag: QueryFlags.MULTIDEL,
        ids: [slideAnnotationId],
        queryClient,
        slideId,
      })
    }
  }

  const selectAnnotation = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    if (!annotation?.slideAnnotationId) {
      return
    }
    if (isObjectsCounting(annotation.type)) {
      viewerDispatch(viewerSlice.actions.selectObjectId(annotation.slideAnnotationId))
      dispatch(viewerPageSlice.actions.openObjectsCounting())
      dispatch(viewerPageSlice.actions.setCountingObjectType(annotation.type as ObjectsAnnotationType))
      return
    }
    if (annotation?.slideId !== slideId) {
      !taskId && viewerDispatch(viewerSlice.actions.setSlideId(annotation.slideId))
      setTimeout(() => {
        viewerDispatch(viewerSlice.actions.selectAnnotations(annotation.slideAnnotationId))
      }, 0)
    } else {
      viewerDispatch(viewerSlice.actions.selectAnnotations(annotation.slideAnnotationId))
    }
    dispatch(annotationsSlice.actions.setDrawMode(false))
    dispatch(annotationsSlice.actions.setSelectedFromList(true))
    dispatch(annotationsSlice.actions.setAnnotationType())
  }

  const annotationsHandler = (id: number) => {
    const newAnnotations = annotationsIsVisible?.includes(id)
      ? annotationsIsVisible?.filter((item) => item !== id)
      : annotationsIsVisible
      ? [...annotationsIsVisible, id]
      : [id]
    viewerDispatch(annotationsSlice.actions.setAnnotationsIsVisible(newAnnotations))
  }

  return annotation ? (
    <AnnotationListItem
      annotation={annotation}
      annotationClass={annotationClass}
      isActive={selectedAnnotationsIds?.includes(annotation.slideAnnotationId) || false}
      onSelect={selectAnnotation}
      onDelete={onDelete}
      onClassSelect={onClassSelect}
      canDelete={annotation.userId === currentUserId || isValidateRole}
      annotationsIsVisible={annotationsIsVisible}
      setAnnotationsIsVisible={annotationsHandler}
    />
  ) : null
}

export default AnnotationListItemContainer
