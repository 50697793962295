import stubLabel from 'assets/thumbnails/stub-label.png'
import { notices } from 'features/notices'
import {
  checkUploadedFileTab,
  MacroFileProps,
  MicroFileProps,
  updateSelection,
  UploadedFileProps,
  ViewType,
} from 'features/uploaded-file/lib/common'
import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'
import { EUploadedFileType } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

import { StyledContainer, StyledImage, StyledImageWrapper } from './UploadedFilePreviewImage.styles'

const UploadedFilePreviewImage = memo(
  ({ file, handleContextMenu, rotateLabel, selectedFile, viewType }: UploadedFileProps) => {
    const { isTableMode, tileSize } = useUploadedFileContext()
    const { t } = useTranslation()
    const {
      selectedFile: selectedFileContext,
      selectedFiles,
      setSelectedFile,
      setSelectedFiles,
      setSelectedRowKeys,
    } = useUploadedFileTabContext()

    const currentFile = file || selectedFile

    const handleClick = (data: IUploadedFileDTO, event: React.MouseEvent<HTMLTableRowElement>) => {
      const { ctrlKey, metaKey } = event

      const isSelected = checkUploadedFileTab(selectedFiles, [data.uploadedFileTab], selectedFileContext)
      if (selectedFileContext?.uploadedFileId === data.uploadedFileId) return setSelectedFile(undefined)

      if (ctrlKey || metaKey) {
        if (!isSelected) {
          return notices.info({
            key: 'SelectUploadedFile',
            message: t('Нельзя одновременно выбрать файлы из разных разделов'),
            placement: 'bottom',
          })
        }

        setSelectedFiles((prevSelectedFiles) => updateSelection(prevSelectedFiles, data, selectedFileContext))
        setSelectedFile(undefined)
      } else {
        setSelectedFiles([])
        setSelectedRowKeys([])
        setSelectedFile(data)
      }
    }

    const renderFilePreview = () => {
      switch (currentFile?.fileType) {
        case EUploadedFileType.MACRO:
          return (
            <MacroFile
              onClick={(e: React.MouseEvent<HTMLTableRowElement>) => file && !isTableMode && handleClick(file, e)}
              tileSize={tileSize}
              viewType={viewType}
              src={currentFile.thumbnails.medium}
              isDisabled={!checkUploadedFileTab(selectedFiles, [currentFile.uploadedFileTab], selectedFileContext)}
            />
          )
        case EUploadedFileType.DOCUMENT:
          return (
            <DocumentFile
              onClick={(e: React.MouseEvent<HTMLTableRowElement>) => file && !isTableMode && handleClick(file, e)}
              isDisabled={!checkUploadedFileTab(selectedFiles, [currentFile.uploadedFileTab], selectedFileContext)}
            />
          )
        case EUploadedFileType.MICRO:
          return (
            <MicroFile
              onClick={(e: React.MouseEvent<HTMLTableRowElement>) => file && !isTableMode && handleClick(file, e)}
              tileSize={tileSize}
              viewType={viewType}
              src={currentFile.thumbnails.medium}
              rotateLabel={rotateLabel}
              labelUrl={currentFile.labelUrl}
              isDisabled={!checkUploadedFileTab(selectedFiles, [currentFile.uploadedFileTab], selectedFileContext)}
            />
          )
        default:
          return null
      }
    }

    const isSelected = !!(
      (viewType === ViewType.GRID && selectedFileContext?.uploadedFileId === file?.uploadedFileId) ||
      (file && selectedFiles.includes(file))
    )

    return (
      <StyledContainer
        isSelected={isSelected}
        tileSize={tileSize}
        viewType={viewType}
        onContextMenu={handleContextMenu}
      >
        {renderFilePreview()}
      </StyledContainer>
    )
  },
)

const MacroFile: FC<MacroFileProps> = ({ isDisabled, onClick, src, tileSize, viewType }) => (
  <div style={{ opacity: isDisabled ? '50%' : '100%', width: '100%' }} onClick={onClick}>
    <StyledImage style={{ width: '100%' }} tileSize={tileSize} viewType={viewType} src={src} />
  </div>
)

const DocumentFile: FC<{ onClick: (e: React.MouseEvent<HTMLTableRowElement>) => void; isDisabled: boolean }> = ({
  isDisabled,
  onClick,
}) => (
  <div style={{ opacity: isDisabled ? '50%' : '100%', width: '100%' }} onClick={onClick}>
    <IconElement name="pdfPreview" style={{ height: '100%', width: '100%' }} />
  </div>
)

const MicroFile: FC<MicroFileProps> = ({ isDisabled, labelUrl, onClick, rotateLabel, src, tileSize, viewType }) => (
  <StyledImageWrapper
    style={{ opacity: isDisabled ? '50%' : '100%' }}
    onClick={onClick}
    tileSize={tileSize}
    viewType={viewType}
  >
    <StyledImage tileSize={tileSize} viewType={viewType} src={src} />
    <StyledImage
      tileSize={tileSize}
      viewType={viewType}
      style={{ transform: rotateLabel && labelUrl ? `rotate(${rotateLabel}deg)` : 'none' }}
      src={labelUrl || stubLabel}
    />
  </StyledImageWrapper>
)

export default UploadedFilePreviewImage
