import { useSubscription } from 'processes/stomp'
import { useMemo } from 'react'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { get, post, QUERY_TYPE } from 'shared/api'
import IListOfItems from 'types/api/IListOfItems'
import ICase from 'types/ICase'
import INotificationMessage from 'types/INotificationMessage'

export enum Notification_Tabs {
  NOT_ALL = 'NOT_ALL',
  UNREAD = 'UNREAD',
}

type WsResponse = {
  timestamp: number
  payload: INotificationMessage // ID комментария
  type: 'NotificationMessage'
}

export const useNotificationsQuery = () => {
  const queryClient = useQueryClient()
  useSubscription<WsResponse>(`/user/queue/notification`, (response) => {
    const { payload } = response
    queryClient.invalidateQueries([QUERY_TYPE.NOTIFICATION, { unread: true }])
    queryClient.invalidateQueries(QUERY_TYPE.NOTIFICATION)
    queryClient.setQueryData([QUERY_TYPE.NOTIFICATION, payload.notificationId], () => payload)

    if (payload.content.type === 'NEW_COMMENT') {
      const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, payload.content.caseId])
      if (caseRecord && caseRecord?.caseId) {
        queryClient.setQueryData([QUERY_TYPE.CASE, caseRecord?.caseId], {
          ...caseRecord,
          commentInfo: {
            lastReadCommentId: caseRecord?.commentInfo?.lastReadCommentId,
            unreadCount: (caseRecord?.commentInfo?.unreadCount || 0) + 1,
          },
        })
      }
    }
  })

  const query = useInfiniteQuery<IListOfItems<INotificationMessage> | undefined, unknown, number[]>(
    QUERY_TYPE.NOTIFICATION,
    async (data) => {
      if (!data) return
      const { pageParam = 0 } = data
      const response = await get<IListOfItems<INotificationMessage>>({
        config: {
          params: {
            page: pageParam,
            size: 5,
          },
        },
        url: '/notification',
      })
      if (!response) return

      if (response.content?.length) {
        response.content.map((item) => {
          queryClient.setQueryData([QUERY_TYPE.NOTIFICATION, item.notificationId], () => item)
        })
      }
      return response
    },
    {
      getNextPageParam: (lastPage, pages) => (lastPage ? (lastPage.last ? false : lastPage.number + 1) : false),
      //@ts-ignore
      select: (data) => ({
        pageParams: data.pages?.map((list) => {
          if (list) {
            const { content, ...params } = list
            return params
          }
        }),
        // pages: data.pages.map(({ content, ...params }) => content.map((item) => item.caseId)),
        pages: data.pages.map((list) => list?.content.map((item) => item.notificationId)),
      }),
    },
  )
  const pages = query.data?.pages || []
  const ids: number[] = useMemo(() => pages.flatMap((page) => page), [pages])
  //@ts-ignore
  const total = query.data?.pageParams && query.data?.pageParams[0] ? query.data?.pageParams[0].totalElements : null
  return {
    ...query,
    ids,
    total,
  }
}

export const useNotificationQuery = (notificationId: number) => {
  const queryClient = useQueryClient()
  return useQuery<INotificationMessage | undefined>([QUERY_TYPE.NOTIFICATION, notificationId], async () =>
    queryClient.getQueryData<INotificationMessage>([QUERY_TYPE.NOTIFICATION, notificationId]),
  )
}

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(async (notificationId: number) => {
    const message = queryClient.getQueryData<INotificationMessage>([QUERY_TYPE.NOTIFICATION, notificationId])
    await post({
      url: `/notification/${notificationId}/read`,
    })
    queryClient.setQueryData([QUERY_TYPE.NOTIFICATION, notificationId], () => ({
      ...message,
      isRead: true,
    }))
    queryClient.invalidateQueries([QUERY_TYPE.NOTIFICATION, { unread: true }])
  })
}

export const useNotificationsUnreadQuery = () =>
  useQuery<number>([QUERY_TYPE.NOTIFICATION, { unread: true }], () =>
    get({
      url: `/notification/unread`,
    }),
  )
