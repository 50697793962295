import { createGlobalStyle } from 'styled-components'

export const GlobalOhivStyles = createGlobalStyle`

  #root {
    --default-color: var(--color-text-3);
  /* --default-color: #9CCEF9; */
    --hover-color: var(--color-bg-1);
    --active-color: var(--color-bg-1)
    /* --active-color: #20A5D6; */
    --ui-border-color: var(--color-text-3);
    /* --ui-border-color: #44626F; */
    /* --ui-border-color-dark: #3C5D80; */
    --ui-border-color-dark: var(--color-text-3);
    /* --ui-border-color-active: #00a4d9; */
    --ui-border-color-active: var(--color-bg-1);
    --primary-background-color:  var(--color-bg-2);
    /* --box-background-color: #3E5975; */
    --box-background-color: var(--color-text-3);
    --ui-yellow: #E29E4A;
    --ui-sky-blue: #6FBDE2;
    --ui-state-error: #FFCCCC;
    --ui-state-error-border: #993333;
    --ui-state-error-text: #661111;
    --ui-gray-light: #516873;
    --ui-gray: #263340;
    --ui-gray-dark: var(--color-text-2);
    --ui-gray-darker: #151A1F;
    --ui-gray-darkest: #14202A;
    --text-color-active: #000000;
    --text-primary-color: var(--color-bg-1);
    /* --text-secondary-color: #91b9cd; */
    --text-secondary-color: var(--color-text-3);
    /* --large-numbers-color: #6fbde2; */
    --large-numbers-color: var(--color-text-3);
    --text-disabled-color: #878787;
    --input-background-color: var(--color-bg-3);
    --input-placeholder-color--hover: #4d5a63;
    --input-placeholder-color: var(--color-text-3);
    --table-hover-color: #2c363f;
    --table-text-primary-color: var(--color-bg-1);
    /* --table-text-secondary-color: #91b9cd; */
    --table-text-secondary-color: var(--color-text-3)91b9cd;
    /* --large-numbers-color: #6FBDE2; */
    --large-numbers-color: var(--color-text-3)6FBDE2;
    --state-error: #FFCCCC;
    --state-error-border: #FFCCCC;
    --state-error-text: #FFCCCC;
    

    --top-bar-height: 0px;
  }


  .entry-header {
    display: none;
  }

  .study-browser {
    background-color: var(--color-bg-2) !important;
  }
  .ToolbarRow {
    background-color: var(--color-bg-2) !important;

  }
`
