import { IconElement, SpaceElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  color: var(--color-red);
  & > svg {
    width: 24px;
    height: 24px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

type Props = {
  message?: string
}

const ErrorNotification = ({ message }: Props) => (
  <Wrapper>
    <ThumbnailWrapper>
      <IconElement name="alert" />
    </ThumbnailWrapper>
    <SpaceElement size={10} />
    <TextWrapper>
      <TextElement data-testid="error-notification">{message}</TextElement>
    </TextWrapper>
  </Wrapper>
)

export default ErrorNotification
