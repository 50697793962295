import { Slider } from 'antd'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

const OpacityContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
  border-bottom: 1px solid var(--color-border-1);
`
const StyledIconElement = styled(IconElement)`
  flex-shrink: 0;
`
const OpacityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;
`
const OpacityTitle = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-text-1);
`
const StyledSlider = styled(Slider)`
  flex: 1;
  width: 100%;
  margin: 0 8px 0 0;
  height: 8px;

  .ant-slider-handle {
    background-color: #fff;
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }

  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`

/** Props for ColorCorrection component */
type Props = {
  /** Значение красного канала  */
  red: number
  /** Функция установки красного канала */
  setRed: (val: number) => void
  /** Значение зеленого канала  */
  green: number
  /** Функция установки зеленого канала */
  setGreen: (val: number) => void
  /** Значение синего канала  */
  blue: number
  /** Функция установки синего канала */
  setBlue: (val: number) => void
  /** Функция переключения фокуса */
  toggleInputFocus: (value: boolean) => void
}

export const ColorCorrection = ({ blue, green, red, setBlue, setGreen, setRed, toggleInputFocus }: Props) => {
  const { t } = useTranslation()

  return (
    <div onFocus={() => toggleInputFocus(true)} onBlur={() => toggleInputFocus(false)}>
      <OpacityContainer>
        <StyledIconElement name="dot" fill={'var(--color-red)'} />
        <OpacityWrapper>
          <OpacityTitle>{t('Красный')}</OpacityTitle>
          <StyledSlider min={-100} max={100} onChange={setRed} value={red} />
        </OpacityWrapper>
      </OpacityContainer>

      <OpacityContainer>
        <StyledIconElement name="dot" fill={'var(--color-green)'} />
        <OpacityWrapper>
          <OpacityTitle>{t('Зеленый')}</OpacityTitle>
          <StyledSlider min={-100} max={100} onChange={setGreen} value={green} />
        </OpacityWrapper>
      </OpacityContainer>

      <OpacityContainer style={{ borderBottom: 'none' }}>
        <StyledIconElement name="dot" fill={'var(--color-blue)'} />
        <OpacityWrapper>
          <OpacityTitle>{t('Синий')}</OpacityTitle>
          <StyledSlider min={-100} max={100} onChange={setBlue} value={blue} />
        </OpacityWrapper>
      </OpacityContainer>
    </div>
  )
}
