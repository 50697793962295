import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { TOOLBAR_BTN_OPACITY } from 'features/toolbar/lib/constants'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownElement, IconButtonElement, IconElement, TextElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components'

const StyledPanel = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledTooltipElement = styled(TooltipElement)`
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
`
//prettier-ignore
const StyledIconButtonElement = styled(IconButtonElement) <{ active?: boolean; disable?: boolean }>`
  color: ${({ active }) => (active ? 'var(--color-purple-light) !important' : 'transparent')};
  opacity: ${({ disable }) => (disable ? TOOLBAR_BTN_OPACITY : undefined)};
  & > svg > path:last-child {
    transform: ${({ active }) => (active ? 'translate(0, 4px)' : 'translate(0, 0)')};
    transition: transform 0.2s ease-in-out;
  }
  &:hover {
    & > svg > path:last-child {
      transform: translate(0, 4px);
      transition: transform 0.2s ease-in-out;
    }
  }
`

const StyledDropDown = styled.div`
  padding: 8px 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  ${() => modalBackground}
  ${() => modalShadow}
`

const StyledChooseOption = styled.div`
  cursor: pointer;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-1);
`

const DEFAULT_COREG_COLOR = 'var(--color-text-3)'
const SUCCESS_COREG_COLOR = 'var(--color-green)'
const BAD_COREG_COLOR = 'var(--color-red)'

type Props = {
  disable?: boolean
}

const CoregistrationPanelContainer = ({ disable }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = () => setOpen(!open)

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  const onVisibleChange = (visible: boolean) => {
    setTooltipVisible(!open && visible)
  }

  useEffect(() => {
    if (open) setTooltipVisible(false)
  }, [open])

  const [coregLinkColor, setCoregLinkColor] = useState(DEFAULT_COREG_COLOR)
  const [coregIcon, setCoregIcon] = useState<'coregDropDown' | 'coregError'>('coregDropDown')

  const { autoCoreg, handableCoreg, setAutoCoreg, setHandableCoreg } = useViewerPageProvided()

  useEffect(() => {
    if (autoCoreg || handableCoreg) {
      setCoregLinkColor(SUCCESS_COREG_COLOR)
    } else {
      setCoregLinkColor(DEFAULT_COREG_COLOR)
    }
  }, [autoCoreg, handableCoreg])

  const setHandByCoregActive = () => {
    setHandableCoreg(true)
    toggleOpen()
    setCoregIcon('coregDropDown')
  }
  const setCoregActiveHandle = () => {
    setAutoCoreg(true)
    toggleOpen()
    setCoregIcon('coregDropDown')
  }
  const turnOffCoregHandle = () => {
    setAutoCoreg(false)
    setHandableCoreg(false)
  }

  const onMouseEnterHandler = () => {
    setCoregIcon('coregError')
    setCoregLinkColor(BAD_COREG_COLOR)
  }
  const onMouseLeaveHandler = () => {
    setCoregIcon('coregDropDown')
    setCoregLinkColor(SUCCESS_COREG_COLOR)
  }
  const { t } = useTranslation()
  const menu = (
    <StyledDropDown>
      <TextElement type={'secondary'}>{t('Связать слайды')}</TextElement>
      <StyledChooseOption onClick={setCoregActiveHandle}>{t('Автоматически')}</StyledChooseOption>
      <StyledChooseOption onClick={setHandByCoregActive}>{t('Вручную')}</StyledChooseOption>
    </StyledDropDown>
  )

  return (
    <StyledPanel data-testid="coregistration-btn">
      {autoCoreg || handableCoreg ? (
        <StyledTooltipElement title={t('Отвязать слайды')}>
          <StyledIconButtonElement
            active={open}
            disable={disable}
            icon={<IconElement name={coregIcon} size="auto" fill={coregLinkColor} />}
            onClick={turnOffCoregHandle}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
          />
        </StyledTooltipElement>
      ) : (
        <DropdownElement visible={open} overlay={menu} trigger={['click']}>
          <StyledTooltipElement title={t('Связать слайды')} visible={tooltipVisible} onVisibleChange={onVisibleChange}>
            <StyledIconButtonElement
              active={open}
              disable={disable}
              icon={<IconElement name={'coregDropDown'} size="auto" fill={coregLinkColor} />}
              onClick={toggleOpen}
            />
          </StyledTooltipElement>
        </DropdownElement>
      )}
    </StyledPanel>
  )
}

export default CoregistrationPanelContainer
