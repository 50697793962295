import { ISlideState } from 'pages/viewer/lib/common/ViewerPageProvider'

import { MPClearPanelState, MPPaneStateDto, MPViewerState } from './types'

interface IViewerStateToString {
  (payload?: MPViewerState): string
}
/**
 * formatViewerStateToString
 * @param payload сериализованное состояние вьювера. Может быть `undefined`
 * @returns `${slideId}|${centerX}|${centerY}|${zoom}|${rotation}` или `-`
 */
export const formatViewerStateToString: IViewerStateToString = (payload) => {
  if (!payload) return '-'
  const { centerX, centerY, rotation, slideId, zoom } = payload
  return `${slideId}|${centerX.toFixed()}|${centerY.toFixed()}|${+zoom.toFixed(6)}|${rotation}`
}

interface IStringToViewerState {
  (payload: string): MPViewerState | undefined
}
/**
 * formatStringToViewerState
 * @param payload строковое состояние вьювера. Может быть `-`
 * @returns `MPViewerState` | `undefined`
 */
export const formatStringToViewerState: IStringToViewerState = (payload) => {
  if (payload === '-') return
  const [slideId, centerX, centerY, zoom, rotation] = payload.split('|').map((it) => +it)
  return {
    centerX,
    centerY,
    rotation,
    slideId,
    zoom,
  }
}

interface IClearPanelState {
  (payload: MPPaneStateDto): MPClearPanelState
}
/**
 * getClearPanelState
 * @param payload Данные об активности с сервера
 * @returns `MPClearPanelState` Данные по вьюверам в виде строк
 */
export const getClearPanelState: IClearPanelState = ({ A, B, C, D }) => ({ A, B, C, D })

interface IViewerStateToSlideState {
  (payload: MPViewerState, caseId: number): ISlideState
}
/**
 * formatSlideForSlideState
 * @param payload Данные по вьюверу
 * @param caseId Id текущего кейса
 * @returns `ISlideState` Данные по слайду для `ViewerPageProvider`
 */
export const formatSlideForSlideState: IViewerStateToSlideState = (sourceViewer, caseId) => ({
  caseId,
  slideGroupType: 'MICRO',
  slideId: sourceViewer.slideId,
  source: 'PLATFORM',
  viewerMode: 'DEFAULT',
})
