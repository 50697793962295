import qs from 'qs'
import { del, get, post, put } from 'shared/api'
import IListOfItems from 'types/api/IListOfItems'
import { AnnotationType, IAnnotation, IAnnotationTaskViewer, INewAnnotationDataObject } from 'types/IAnnotations'
import { IMarkupClass, IMarkupResultStatus, IMarkupSlide, IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'

const parseTaskResponse = (response: IAnnotationTaskViewer[], slideId?: number) =>
  response?.map(
    ({ annotation, annotationType, createdAt, createdBy, markupAnnotationId, markupResultId, updatedAt }) =>
      <IAnnotation>{
        createdAt,
        data: {
          formattedFeature: JSON.stringify({
            geometry: annotation.features ? annotation.features[0]?.geometry : annotation.geometry,
            properties: annotation.features ? annotation.features[0]?.properties : annotation.properties,
            type: 'Feature',
          }),
          type: 'ANNOTATION',
        },
        markupResultId,
        metric: 0,
        slideAnnotationId: markupAnnotationId,
        slideId: slideId,
        type: annotationType,
        updatedAt,
        userId: createdBy,
      },
  )

type FetchParams = {
  page?: number
  size?: number
}

const fetchMarkupTasks = async (params: FetchParams) =>
  get<IListOfItems<IMarkupTask>>({
    config: {
      params,
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
    },
    url: '/atlas/markup/task',
  })

const fetchSingleTask = async (taskId: number) =>
  get<IMarkupTask>({
    url: `/atlas/markup/task/${taskId}`,
  })

const fetchTaskSlides = async (taskId: number) =>
  get<IMarkupSlide[]>({
    url: `/atlas/markup/task/${taskId}/slide`,
  })

const fetchTaskClasses = async (taskId: number) =>
  get<IMarkupClass[]>({
    url: `/atlas/markup/task/${taskId}/class`,
  })

const fetchTaskSlideResult = async (taskId: number, slideId: number) =>
  get<IMarkupSlideResult[]>({
    url: `/atlas/markup/task/${taskId}/slide/${slideId}/result`,
  })

const createTaskSlideResult = (taskId?: number, slideId?: number) =>
  post<IMarkupSlideResult>({
    url: `/atlas/markup/task/${taskId}/slide/${slideId}/result`,
  })

const setTaskSlideResult = (taskId: number, slideId: number, markupResultId: number, status: IMarkupResultStatus) =>
  put<IMarkupSlideResult>({
    data: {
      status,
    },
    url: `/atlas/markup/task/${taskId}/slide/${slideId}/result/${markupResultId}`,
  })

const getTaskAnnotations = async (slideId: number, markupResultId: number, taskId?: number | string) => {
  const response = await get<IAnnotationTaskViewer[]>({
    url: `/atlas/markup/task/${taskId}/slide/${slideId}/result/${markupResultId}/annotation`,
  })
  return parseTaskResponse(response, slideId)
}

const createTaskAnnotation = async (
  markupTaskId: number | string,
  markupSlideId: number,
  markupResultId: number,
  data: INewAnnotationDataObject,
  type: AnnotationType,
) => {
  const response = await post<IAnnotationTaskViewer[]>({
    data: {
      body: [
        {
          annotation: JSON.parse(data?.formattedFeature || 'null'),
          annotationType: type,
        },
      ],
    },
    url: `/atlas/markup/task/${markupTaskId}/slide/${markupSlideId}/result/${markupResultId}/annotations`,
  })

  return parseTaskResponse(response, markupSlideId)[0]
}

const createTaskAnnotations = async (
  markupTaskId: number | string,
  markupSlideId: number,
  markupResultId: number,
  taskAnnotations: Partial<IAnnotation>[],
  slideId: number,
) => {
  const response = await post<IAnnotationTaskViewer[]>({
    data: {
      body: taskAnnotations?.map(({ data, type }) => ({
        annotation: JSON.parse(data?.formattedFeature || 'null'),
        annotationType: type,
      })),
    },
    url: `/atlas/markup/task/${markupTaskId}/slide/${markupSlideId}/result/${markupResultId}/annotations`,
  })

  return parseTaskResponse(response, slideId)
}

const updateTaskAnnotation = async (
  markupTaskId: number | string,
  markupAnnotationId: number,
  data: INewAnnotationDataObject | undefined,
  type: AnnotationType,
  metric: number,
  /** id слайда */
  slideId: number,
  markupSlideId?: number,
  markupResultId?: number,
) => {
  const response = await put<IAnnotationTaskViewer[]>({
    data: {
      body: [
        {
          annotation: { ...JSON.parse(data?.formattedFeature || 'null'), markupAnnotationId },
          annotationType: type,
          // TODO договориться с BE об удалении полей
          markupAnnotationId,
          markupResultId,
        },
      ],
    },
    url: `/atlas/markup/task/${markupTaskId}/slide/${markupSlideId}/result/${markupResultId}/annotations`,
  })
  return parseTaskResponse(response, slideId)[0]
}

const updateTaskAnnotations = async (
  markupTaskId: number | string,
  markupAnnotationId: number,
  taskAnnotations: Partial<IAnnotation>[],
  /** id слайда */
  slideId: number,
  markupSlideId?: number,
  markupResultId?: number,
) => {
  const response = await put<IAnnotationTaskViewer[]>({
    data: {
      body: taskAnnotations?.map(({ data, type }) => ({
        annotation: { ...JSON.parse(data?.formattedFeature || 'null'), markupAnnotationId },
        annotationType: type,
        markupAnnotationId,
        markupResultId,
      })),
    },
    url: `/atlas/markup/task/${markupTaskId}/slide/${markupSlideId}/result/${markupResultId}/annotations`,
  })
  return parseTaskResponse(response, slideId)
}

const deleteTaskAnnotation = async (
  markupTaskId: number | string,
  markupSlideId?: number,
  markupResultId?: number,
  markupAnnotationId?: number,
) => {
  const response = await del({
    config: { data: { body: [markupAnnotationId] } },
    url: `/atlas/markup/task/${markupTaskId}/slide/${markupSlideId}/result/${markupResultId}/annotations`,
  })
  return response as { success: boolean }
}

const deleteTaskAnnotations = async (
  markupTaskId: number | string,
  markupSlideId?: number,
  markupResultId?: number,
  markupAnnotationIds?: number[],
) => {
  const response = await del({
    config: { data: { body: markupAnnotationIds } },
    url: `/atlas/markup/task/${markupTaskId}/slide/${markupSlideId}/result/${markupResultId}/annotations`,
  })
  return response as { success: boolean }
}

const exportAnnotationsAsGeoJSON = async (markupTaskId?: number | string, markupSlideId?: number) =>
  await get<IAnnotationTaskViewer[]>({
    url: `/atlas/markup/task/${markupTaskId}/slide/${markupSlideId}/geojson`,
  })

const markupTasksService = {
  createTaskAnnotation,
  createTaskAnnotations,
  createTaskSlideResult,
  deleteTaskAnnotation,
  deleteTaskAnnotations,
  exportAnnotationsAsGeoJSON,
  fetchMarkupTasks,
  fetchSingleTask,
  fetchTaskClasses,
  fetchTaskSlideResult,
  fetchTaskSlides,
  getTaskAnnotations,
  setTaskSlideResult,
  updateTaskAnnotation,
  updateTaskAnnotations,
}
export default markupTasksService
