import { Row } from 'antd'
import CaseManagementTabContext from 'features/cases-management/ui/CaseManagementTabContext'
import { FC } from 'react'
import Logo from 'shared/ui/logo'
import { NavigationHeader } from 'shared/ui/navigation-header/NavigationHeader'
import styled from 'styled-components/macro'

/** Высота хедера */
export const PAGE_HEADER_HEIGHT = 48

const StyledPageHeader = styled(Row)`
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  min-height: ${PAGE_HEADER_HEIGHT}px;
  line-height: 16px;
`
const StyledRight = styled.div``

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`

export const PageHeader: FC = ({ children }) => (
  <StyledPageHeader style={{ justifyContent: 'space-between' }}>
    <StyledLeft>
      <Logo />
      <CaseManagementTabContext>
        <NavigationHeader />
      </CaseManagementTabContext>
    </StyledLeft>
    <StyledRight>{children}</StyledRight>
  </StyledPageHeader>
)
