import { Button } from 'antd'
import styled from 'styled-components'

export const BubbleButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 8px;
  padding-right: 5px;
  border-radius: 16px;
  font-weight: 600;
  background-color: var(--color-border-1);
  box-shadow: none;
  color: var(--color-text-1);
  border: 1px solid transparent;
  &:hover {
    background-color: var(--color-bg-3);
    color: var(--color-text-1);
    border: 1px solid transparent;
  }
  &:active {
    border: 1px solid var(--color-purple);
    background-color: var(--color-border-1);
    color: var(--color-text-1);
  }

  .cross-icon-bubble-button {
    margin-left: 10px;
    color: var(--color-text-3);
  }

  .cross-icon-bubble-button:hover {
    color: var(--color-text-1);
  }
`
