import { del, get, post } from 'shared/api'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import ICase, { ICaseDocument, ICaseDocumentData, ICaseHistory } from 'types/ICase'
import ICaseStatus from 'types/ICaseStatus'
import { DictionaryItem } from 'types/IDictionary'
import ISex from 'types/ISex'
import { ISlideGroupedRes } from 'types/ISlideGrouped'
import ISource from 'types/ISource'

// Получение менеджмент URL по workspaceId
const getManagementUrl = (workspaceId: string) => `/workspace/${workspaceId}/case/management`

export type PostCaseDatatype = {
  name?: string
  patient?: {
    fullname?: string | null
    sex?: ISex
    birthDate?: string // '2020-09-08'
  }
  site?: DictionaryItem
  materialTakeoutDate?: string // '2020-09-08'
  description?: string
  status?: ICaseStatus
  siteSpecificMetadata?: Record<string, any>
}

type FetchCasesParams = {
  rel: 'owner' | 'shared'
  status: 'open' | 'archive'
  query?: string
  cursor?: string
}

export type IFile = {
  uid: string | number
  lastModified: number
  lastModifiedDate: string
  name: string
  size: number
  type: string
  percent?: number
  originFileObj?: File
  status?: string
}

export type CaseCursors = {
  nextPageCursor: string
  previousPageCursor: string
}

export type CaseBiomaterial = {
  externalId: string
  name: string
  site: DictionaryItem
  processNature: DictionaryItem
  fragmentsCount: number
  blocksCount: number
  slidesCount: number
  /** из ЛИС пришло не справочное значение "локализации флакона" */
  siteExternalName: string
}

export type CaseReference = {
  /** Идентификатор ссылки на слайд случая. */
  caseSlideReferenceId: number
  /** Внешний идентификатор блока. */
  blockExternalId: string
  /** Внешний идентификатор слайда. */
  slideExternalId: string
  /** Информация окраски. */
  stain: {
    /** короткое имя МКБ-О */
    shortName: string
    /** идентификатор окраски */
    id: number
    /** Название окраски. */
    name: string
    /** Флаг, отслеживающий видимость окраски. */
    visible: boolean
  }
}

const fetchCases = async (params: FetchCasesParams, workspaceId?: string) =>
  get<IListOfItemsCursorCases<ICase>>({
    config: {
      params,
    },
    url: '/case',
    workspaceId,
  })

const fetchCase = (caseId?: number | string, source?: ISource) =>
  get<ICase>({
    config: {
      params: {
        source,
      },
    },
    url: `/case/${caseId}`,
  })

const fetchCaseDocs = (caseId?: number | string, source?: ISource) =>
  get<ICaseDocument[]>({
    config: {
      params: {
        source,
      },
    },
    url: `/case/${caseId}/document`,
  })

const fetchDoc = (documentId: number, caseId?: number | string) =>
  get<Blob>({
    config: {
      responseType: 'blob',
    },
    url: `/case/${caseId}/document/${documentId}`,
  })

const createCase = (data: PostCaseDatatype) =>
  post<ICase>({
    data,
    url: '/case',
  })

const updateCase = (data: PostCaseDatatype, id: string | number) =>
  post<ICase>({
    data,
    url: `/case/${id}`,
  })

const declineCase = (workspaceId: string, comment: ICase['comment'], caseId: ICase['caseId']) =>
  post<ICase>({
    data: {
      caseId,
      comment,
    },
    url: `${getManagementUrl(workspaceId)}/decline`,
  })
const changeUrgentCase = (workspaceId: string, urgent: ICase['urgent'], caseId: ICase['caseId']) =>
  post<ICase>({
    config: {
      params: {
        urgent,
      },
    },
    url: `/workspace/${workspaceId}/case/${caseId}/urgent`,
  })

const uncompleteCase = (workspaceId: string, comment: ICase['comment'], caseId: ICase['caseId']) =>
  post<ICase>({
    data: {
      caseId,
      comment,
    },
    url: `${getManagementUrl(workspaceId)}/uncomplete`,
  })

const completeCase = (workspaceId: string, comment: ICase['comment'], caseId: ICase['caseId']) =>
  post<ICase>({
    data: {
      caseId,
      comment,
    },
    url: `${getManagementUrl(workspaceId)}/complete`,
  })

const uploadDocument = (data: any, caseId?: number | string) => {
  const formData = new FormData()
  formData.append('file ', data)

  return post<ICaseDocumentData>({
    data: formData,
    url: `/case/${caseId}/document`,
  })
}

const deleteDocument = (documentId: number | string, caseId?: number | string) =>
  del<ICase>({
    url: `/case/${caseId}/document/${documentId}`,
  })

const getExternalCaseId = async (workspaceId?: string, externalCaseId?: string) =>
  get<ICase>({
    config: {
      params: {
        caseExternalId: externalCaseId ? decodeURIComponent(externalCaseId) : '',
      },
    },
    url: `/workspace/${workspaceId}/case/external`,
  })

/**
 *
 * @param caseExternalId: string - case name
 * @returns ICase
 */
const createCaseByExternalId = async (caseExternalId: string) =>
  post<ICase>({
    config: {
      params: {
        caseExternalId,
      },
    },
    url: `/case/external`,
  })

const getNextPrevCasesCursor = async (caseId: string, workspaceId: string, rel: string) =>
  get<CaseCursors>({
    config: {
      params: {
        rel,
      },
    },
    url: `/workspace/${workspaceId}/case/${caseId}/cursor`,
  })

const getCaseBioMaterials = async (caseId: number, workspaceId: number) =>
  get<CaseBiomaterial[]>({
    url: `/workspace/${workspaceId}/case/${caseId}/biomaterials`,
  })

const getCaseReference = async (caseId?: number, workspaceId?: number) =>
  get<CaseReference[]>({
    url: `/workspace/${workspaceId}/case/${caseId}/references/slide`,
  })

const fetchSlideGrouped = (caseId?: number | string) =>
  get<ISlideGroupedRes>({
    url: `/case/${caseId}/slide/grouped`,
  })

const getCaseHistory = async (caseId?: number, workspaceId?: number) =>
  get<ICaseHistory[]>({
    url: `/workspace/${workspaceId}/case/${caseId}/stage/history`,
  })

const caseService = {
  changeUrgentCase,
  completeCase,
  createCase,
  createCaseByExternalId,
  declineCase,
  deleteDocument,
  fetchCase,
  fetchCaseDocs,
  fetchCases,
  fetchDoc,
  fetchSlideGrouped,
  getCaseBioMaterials,
  getCaseHistory,
  getCaseReference,
  getExternalCaseId,
  getNextPrevCasesCursor,
  uncompleteCase,
  updateCase,
  uploadDocument,
}

export default caseService
