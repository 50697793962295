import { CSSProperties, FC, useRef } from 'react'
import styled from 'styled-components/macro'

import { useInfiniteScroll } from './useInfiniteScroll'

const Anchor = styled.div`
  width: 1px;
  height: 1px;
`

const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`

const Inner = styled.div`
  height: auto;
`

type Props = {
  fetchMore: () => Promise<any>
  itemsLength: number
  isLastPage: boolean
  className?: string
  style?: CSSProperties
}

export const InfiniteScroll: FC<Props> = ({ children, className, fetchMore, isLastPage, itemsLength, style }) => {
  const anchor = useRef<HTMLDivElement>(null)
  const scrollComponent = useRef<HTMLDivElement>(null)
  const isAnchorHidden = useInfiniteScroll({ anchor, fetchMore, isLastPage, itemsLength })
  return (
    <Column ref={scrollComponent} className={className} style={style}>
      <Inner>
        {children}
        {!isAnchorHidden && <Anchor ref={anchor} />}
      </Inner>
    </Column>
  )
}
