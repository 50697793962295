import { useTypedSelector } from 'app/redux/lib/selector'
import { useTaskClasses } from 'entities/tasks/api/query'
import { Feature } from 'ol'
import { Geometry } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DEFAULT_MPPX } from 'shared/lib/metadata'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import { useViewerAttachmentsSelector, useViewerMainSelector } from 'viewer/container'
import { useSlideGroupType } from 'viewer/container/model/viewerSlice'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'
import {
  completedMitosisSpotStyle,
  defaultStyle,
  getCustomClass,
  styleByAnnotationClass,
} from 'viewer/map/layers/olStyles'
import { removeLayersByInternalType } from 'viewer/map/lib/utils'

import { isObjectsCounting } from './lib/helpers'
import AnnotationSelectInteraction from './lib/interactions/AnnotationSelectInteraction'
import CopyPasteInteraction from './lib/interactions/CopyPasteInteraction'
import HoverInteraction from './lib/interactions/HoverInteraction'

type Props = {
  features: Feature<Geometry>[]
  map: IMapOl
  viewerId: TViewerId
  mppX: number
}

const VectorAnnotationsLayerContainer = ({ features, map, mppX, viewerId }: Props) => {
  const { gridIsVisible, isKi67DrawActive, isObjectsDrawActive, selectedAnnotationsIds } =
    useViewerMainSelector(viewerId)
  const { slideId } = useViewerIdSlideState(viewerId)
  const { isCreatingBbox } = useViewerAttachmentsSelector(viewerId)
  const { annotationType, annotationsOpacity } = useTypedSelector((state) => state.annotations)
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const slideGroupType = useSlideGroupType(viewerId)

  const { data: currTaskClasses } = useTaskClasses(taskId)
  const layer = useMemo(
    () =>
      new VectorLayer({
        updateWhileAnimating: true,
        updateWhileInteracting: true,
        zIndex: 1,
      }),
    [map],
  )
  const source = useMemo(
    () =>
      new VectorSource({
        features: features,
      }),
    [layer],
  )

  useEffect(() => {
    layer.setSource(source)
  }, [source])
  useEffect(() => {
    source.clear()
    source.addFeatures(features)
  }, [layer, features])

  useEffect(() => {
    layer
      .getSource()
      .getFeatures()
      .forEach((f) => {
        const aClass = f.get('class')
        const aType = f.get('annotation_type')

        const geometry = f?.getGeometry()
        f.setStyle(
          aClass
            ? styleByAnnotationClass({
                annotationClass: aClass,
                annotationType: aType,
                customClass: getCustomClass(aClass, aType, currTaskClasses),
                geometry,
                isSelect: selectedAnnotationsIds.includes(f.get('slideAnnotationId')),
                isTask: !!taskId,
                opacity: annotationsOpacity / MAX_OPACITY,
              })
            : isObjectsCounting(aType)
            ? completedMitosisSpotStyle
            : defaultStyle,
        )
      })
  }, [annotationsOpacity])

  useEffect(() => {
    layer.set(INTERNAL_TYPE_ID, LayerType.ANNOTATIONS)
    map.addLayer(layer)
    return () => {
      source.clear()
      removeLayersByInternalType(map, LayerType.ANNOTATIONS)
    }
  }, [slideId, layer])

  return (
    <>
      {!isKi67DrawActive && !isObjectsDrawActive && !gridIsVisible && !isCreatingBbox && (
        <HoverInteraction
          features={source.getFeatures()}
          map={map}
          mppX={mppX || DEFAULT_MPPX}
          selectedAnnotationsIds={selectedAnnotationsIds}
          slideGroupType={slideGroupType}
        />
      )}
      {!isKi67DrawActive && !isObjectsDrawActive && !gridIsVisible && !isCreatingBbox && (
        <CopyPasteInteraction
          map={map}
          viewerId={viewerId}
          featureId={selectedAnnotationsIds[0]}
          mppX={mppX || DEFAULT_MPPX}
        />
      )}
      {!annotationType && !isKi67DrawActive && !isObjectsDrawActive && !gridIsVisible && !isCreatingBbox && (
        <AnnotationSelectInteraction
          mppX={mppX || DEFAULT_MPPX}
          map={map}
          viewerId={viewerId}
          selectedAnnotationsIds={selectedAnnotationsIds}
          features={source.getFeatures()}
        />
      )}
    </>
  )
}

export default VectorAnnotationsLayerContainer
