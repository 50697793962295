import generatePicker, { PickerDateProps } from 'antd/es/date-picker/generatePicker'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_FOR_DISPLAY } from 'shared/lib/date'
import { getDatePickerLocale } from 'shared/lib/date/getDatePickerLocale'
import styled from 'styled-components/macro'

import { IconElement } from './IconElement'

export type DatePickerProps = Omit<PickerDateProps<Date>, 'picker'>

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)

const StyledDatePicker = styled(DatePicker)`
  padding: 4px 2px 4px 8px;
  .ant-picker-input > input {
    line-height: 16px;
  }
  &.ant-picker-suffix {
    color: var(--var-text-3);
  }

  .ant-picker-clear {
    margin-right: 2px;
  }
`

export const DatePickerElement = (props: DatePickerProps) => {
  const { i18n, t } = useTranslation()
  const dateLocale = getDatePickerLocale(i18n.language)

  return (
    <StyledDatePicker
      format={DATE_FORMAT_FOR_DISPLAY}
      placeholder={t('Не выбрано')}
      allowClear={false}
      inputReadOnly
      suffixIcon={<IconElement name="calendar" size="md" />}
      locale={dateLocale}
      {...props}
    />
  )
}
