import { Space } from 'antd'
import { push } from 'connected-react-router'
import { FormButton, FormTopTitle, FormWrapper } from 'features/form'
import { MobileLandscape } from 'features/mobile-control'
import { notices } from 'features/notices'
import { userService } from 'processes/user'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'shared/ui/header'
import { SpaceElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import { NewPasswordForm } from './ui'

const StyledNewPassword = styled(MobileLandscape)`
  background-color: var(--color-bg-1);
  height: 100%;
  width: 100%;
`

export const NewPasswordRoute = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { params } = useRouteMatch<{ userId: string; token: string }>()
  const onNewPassword = async (newPassword: string) => {
    try {
      const result = await userService.createNewPassword(params.userId, { newPassword, token: params.token })
      if (result.success) {
        notices.info({
          key: 'newPassword',
          message: t('Пароль успешно изменен'),
        })
        goToLogin()
      } else {
        const e = new Error(t('Ссылка для сброса пароля устарела'))
        e.name = 'oldPasswordResetLink'
        throw e
      }
    } catch (e) {
      throw e
    }
  }
  const goToLogin = useCallback(() => {
    dispatch(push('/login'))
  }, [])

  return (
    <StyledNewPassword>
      <PageHeader>
        <Space size={16}>
          <FormTopTitle level={4}>{t('Уже есть аккаунт?')}</FormTopTitle>
          <FormButton onClick={goToLogin}>{t('Войти')}</FormButton>
        </Space>
      </PageHeader>
      <SpaceElement size={98} />
      <FormWrapper>
        <TitleElement level={1}>{t('Придумайте новый пароль')}</TitleElement>
        <SpaceElement size={40} />
        <NewPasswordForm onSubmit={onNewPassword} />
      </FormWrapper>
    </StyledNewPassword>
  )
}
