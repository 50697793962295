import { PickerLocale } from 'antd/es/date-picker/generatePicker'
import en from 'antd/es/date-picker/locale/en_US'
import ruLocale from 'antd/es/date-picker/locale/ru_RU'
import enUS from 'antd/es/locale/en_US'

type CustomDatePicker = {
  lang: {
    cellYearFormat: string
    fieldDateFormat: string
    fieldDateTimeFormat: string
    yearFormat: string
  }
}

type CustomLocale = Locale & { DatePicker?: CustomDatePicker }

const enLocale: CustomLocale = {
  ...enUS,
  DatePicker: {
    ...enUS.DatePicker!,
    lang: {
      ...en.lang,
      cellYearFormat: 'BBBB',
      fieldDateFormat: 'BBBB-MM-DD',
      fieldDateTimeFormat: 'BBBB-MM-DD HH:mm:ss',
      yearFormat: 'BBBB',
    },
  },
}

ruLocale.lang.locale = 'ru'

export const getDatePickerLocale = (language: string) => (language === 'en' ? enLocale : ruLocale) as PickerLocale
