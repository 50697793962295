import { Slider } from 'antd'
import { viewerPageSlice } from 'pages/viewer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

export const MAX_OPACITY = 100

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.div`
  color: var(--color-text-1);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

const StyledSlider = styled(Slider)`
  flex: 0 0 50%;
  margin: 0 8px;
  height: 8px;

  .ant-slider-handle {
    background-color: #fff;
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }

  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`

/** Props for ToolOpacityController */
type Props = {
  /** Численное значение прозрачности полигонов [0; 100] */
  value: number
  /** Коллбэк для установки значения прозрачности */
  onChange: (value: number) => void
}

const ToolOpacityController: React.FC<Props> = ({ onChange, value }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const toggleInputFocus = (value: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))

  return (
    <Wrapper onFocus={() => toggleInputFocus(true)} onBlur={() => toggleInputFocus(false)}>
      <Label>{t('Прозрачность')}</Label>
      <StyledSlider min={0} max={MAX_OPACITY} step={1} onChange={onChange} value={value} />
    </Wrapper>
  )
}

export default ToolOpacityController
