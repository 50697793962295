import { message, Upload } from 'antd'
import { DraggerProps } from 'antd/lib/upload'
import { IFile } from 'features/cases/api/service'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DOT_WITH_SPACE } from 'shared/text-constants'
import styled from 'styled-components/macro'

import { IconElement } from './IconElement'
import { TooltipElement } from './TooltipElement'

const { Dragger } = Upload

const StyledArea = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    line-height: 16px;
    background-color: var(--color-bg-3);
    outline: 1px dashed #706f76;
    outline-offset: -4px;
    border-radius: 3px;
  }
`

const Wrapper = styled.div`
  & .ant-upload-list-item {
    height: 100%;
  }

  & .ant-upload-list-item-name {
    white-space: normal;
    color: var(--color-text-1);
  }
`

const Info = styled.p`
  color: var(--color-text-3);
  margin-bottom: 5px !important;
  padding: 4px 16px;
`
const Download = styled.p`
  color: var(--color-blue);
`

export const MAX_NAME_LENGTH = 90

type Props = {
  setCurrFiles: (data: IFile[]) => void
  defaultFileList?: IFile[]
}

const DragAndDrop = ({ defaultFileList, setCurrFiles, ...rest }: Props) => {
  const { t } = useTranslation()
  const createMessage = (name: string, addMessage: string) => {
    const finalName = name?.length >= MAX_NAME_LENGTH ? name.slice(0, MAX_NAME_LENGTH) + '...' : name
    return name?.length >= MAX_NAME_LENGTH ? (
      <TooltipElement title={name}>{finalName + ' ' + addMessage}</TooltipElement>
    ) : (
      name
    )
  }
  const props: DraggerProps = {
    accept: '.jpeg,.pdf,.jpg,.png',

    beforeUpload(file) {
      switch (file.type) {
        case 'image/png':
          break
        case 'image/jpeg':
          break
        case 'image/jpg':
          break
        case 'application/pdf':
          break
        default:
          message.error(createMessage(file.name, t('Недопустимый формат файла!')))
          return Upload.LIST_IGNORE
      }
      const isLt2M = file.size / 1024 / 1024 < 25
      if (!isLt2M) {
        message.error(createMessage(file.name, `${t('не загружен! Максимальный размер файла')} - 25${t('мб')}!`))
        return Upload.LIST_IGNORE
      }
      return file
    },

    customRequest(data: any) {
      data.onSuccess(() => ({}))
    },
    //@ts-ignore
    defaultFileList: defaultFileList,
    iconRender: (f) =>
      f.type === 'application/pdf' ? (
        <IconElement name="pdf" />
      ) : (
        <IconElement fill="var(--color-text-3)" name="jpeg" />
      ),

    multiple: true,

    name: 'file',

    onChange(info) {
      setCurrFiles(info.fileList as any)
      const { name, status } = info.file
      if (status === 'done') message.success(createMessage(name, t('Файл добавлен.')))
      else if (status === 'error') message.error(createMessage(name, t('Ошибка добавления файла.')))
    },
    ...rest,
  }
  return (
    <Wrapper>
      <StyledArea {...props}>
        <Info>
          {t('Загрузите файлы в форматах JPEG, PNG или PDF')}
          {DOT_WITH_SPACE}
          {t('Максимальный размер файла')} - 25{t('мб')}
        </Info>
        <Download>{t('Загрузить')}</Download>
      </StyledArea>
    </Wrapper>
  )
}

export default DragAndDrop
