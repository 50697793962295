import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ISimilarRegion, { ISelectedBbox } from 'types/ISimilarRegion'

type State = {
  mainSelectedSimilarRegion?: ISimilarRegion
  mainSelectedBbox?: ISelectedBbox
  /** Индикатор процесса поиска похожих слайдов */
  isSearching: boolean
}

const initialState: State = {
  isSearching: false,
}

export const searchSlice = createSlice({
  extraReducers: {
    'viewerPageSlice/resetAllViewersState': (state) => initialState,
  },
  initialState,
  name: 'searchSlice',
  reducers: {
    setIsSearching(state, { payload }: PayloadAction<boolean>) {
      state.isSearching = payload
    },
    setMainSelectedBbox(state, { payload }: PayloadAction<ISelectedBbox | undefined>) {
      state.mainSelectedBbox = payload
    },
    setMainSelectedSimilarRegion(state, { payload }: PayloadAction<ISimilarRegion | undefined>) {
      state.mainSelectedSimilarRegion = payload
    },
  },
})
