import React, { CSSProperties, useMemo } from 'react'
import { DroppableProvided } from 'react-beautiful-dnd'
import { findDOMNode } from 'react-dom'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowRenderer, Size } from 'react-virtualized'

import VirtualListItem from './VirtualListItem'

type Props = {
  leftPanel?: boolean
  defaultRowHeight: number
  total: number
  rowRenderer: ListRowRenderer
  style?: CSSProperties
  droppableProvided?: DroppableProvided
  setListRef?: (ref: List) => void
}

export const VirtualList = ({
  defaultRowHeight,
  droppableProvided,
  leftPanel,
  rowRenderer,
  setListRef,
  style = {},
  total = 0,
}: Props) => {
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: defaultRowHeight,
        fixedWidth: true,
      }),
    [defaultRowHeight],
  )

  return (
    <AutoSizer>
      {({ height, width }: Size) => (
        <List
          ref={(ref) => {
            // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
            if (ref && setListRef !== undefined) {
              setListRef(ref)
            }
            if (ref && droppableProvided) {
              // eslint-disable-next-line react/no-find-dom-node
              const droppableEl = findDOMNode(ref)
              if (droppableEl instanceof HTMLElement) {
                droppableProvided.innerRef(droppableEl)
              }
            }
          }}
          style={{ outline: 'none', paddingBottom: '8px', ...style }}
          width={width}
          height={height}
          rowCount={total}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={(args) => {
            const { index, key, parent, style } = args
            return (
              <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                {({ measure, registerChild }) => (
                  <VirtualListItem ref={registerChild as any} style={style} measure={measure} leftPanel={leftPanel}>
                    {rowRenderer(args)}
                  </VirtualListItem>
                )}
              </CellMeasurer>
            )
          }}
        />
      )}
    </AutoSizer>
  )
}
