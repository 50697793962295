import Modal, { ModalProps } from 'antd/es/modal'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import styled from 'styled-components/macro'

const StyledModal = styled(Modal)`
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
  .ant-modal-content {
    ${() => modalBackground}
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }
  .ant-modal-body {
    height: 100vh;
    overflow: auto;
  }
  .ant-modal-close {
    top: 8px;
    right: 8px;
    ${() => modalBackground}
    ${() => modalShadow}
    border-radius: 50%;
    color: var(--color-text-1);
  }
  .ant-modal-close-x {
  }
`

export const FullScreenModalElement: React.FC<ModalProps> = (props) => <StyledModal {...props} />
