import { Divider } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import icons from 'assets/icons'
import styled from 'styled-components/macro'

const CONTEXT_MENU_WIDTH = 223

export const ContextMenuWrapper = styled.div<{ thumbnailMenuPosition: number[]; position: string }>`
  min-width: ${CONTEXT_MENU_WIDTH}px;
  display: grid;
  left: ${({ thumbnailMenuPosition }) =>
    thumbnailMenuPosition.length
      ? window.innerWidth - thumbnailMenuPosition[0] < CONTEXT_MENU_WIDTH
        ? `${thumbnailMenuPosition[0] - CONTEXT_MENU_WIDTH}px`
        : `${thumbnailMenuPosition[0]}px`
      : undefined};
  top: ${({ thumbnailMenuPosition }) => thumbnailMenuPosition.length && `${thumbnailMenuPosition[1]}px`};
  z-index: 99;
  position: ${({ position }) => position || 'absolute'};
  ${() => modalBackground}
  ${() => modalShadow}
  border-radius: 5px;
  padding: 4px 0;
  gap: 4px;
`

export const ContextMenuItem = styled.div<{ name: keyof typeof icons; isDisabled: boolean }>`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  padding: 4px 16px 4px 8px;
  opacity: ${({ isDisabled }) => isDisabled && 0.4};
  color: ${({ name }) => name === 'delete' && 'var(--color-red)'};

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 8px;
  }
  &:hover {
    background-color: ${({ isDisabled }) => !isDisabled && 'var(--color-bg-3)'};
  }
`

export const StyledDivider = styled(Divider)`
  margin: 4px 0 !important;
`
