import { Feature } from 'ol'
import { Polygon } from 'ol/geom'
import { pixelsToMapProjection } from 'shared/lib/metadata'
import { IMapOl } from 'types/IMapOl'
import ISlide from 'types/ISlide'

/** Отступы от краев экрана */
const DEFAULT_PADDING = 8
/** Стандартная ширина панелей (миникарты или подсчета) */
const DEFAULT_PANEL_WIDTH = 264
/** Половина */
export const HALF = 2

export type Point2D = [number, number]

/**
 * Вычисляет и возвращает экстент текущего вьюпорта
 * @param {IMapOl} map - экземпляр карты ol
 * @param {ISlide | undefined} slide - текущий открытый слайд
 * @param {number | 8} padding - отступ от края вьюпорта
 */
export const getViewportExtent = (map: IMapOl, slide?: ISlide, padding = DEFAULT_PADDING) => {
  if (!map) return []
  const size = map.getSize() || [0, 0]
  const resolution = map?.getView()?.getResolution()
  const rotation = map?.getView()?.getRotation()
  const center = map?.getView()?.getCenter()
  if (!resolution || !center) return []
  try {
    /** Перепроецированный отступ от края вьюпорта */
    const reprojectedPadding = pixelsToMapProjection(padding, resolution, slide)
    /** Перепроецированный отступ справа, включающий ширину панели */
    const reprojectedPanelWidthWithPadding = pixelsToMapProjection(DEFAULT_PANEL_WIDTH, resolution, slide) + padding * 2
    const dx = (resolution * size[0]) / HALF
    const dy = (resolution * size[1]) / HALF
    const cos = Math.cos(rotation)
    const sin = Math.sin(rotation)
    const extent = [
      /** left bottom */
      [-dx + reprojectedPanelWidthWithPadding, -dy + reprojectedPadding],
      /** left top */
      [-dx + reprojectedPanelWidthWithPadding, dy - reprojectedPadding],
      /** right top */
      [dx - reprojectedPadding, dy - reprojectedPadding],
      /** right bottom */
      [dx - reprojectedPadding, -dy + reprojectedPadding],
    ]
    /** Применение поворота */
    for (let i = 0; i < extent.length; ++i) {
      const x = extent[i][0]
      const y = extent[i][1]
      extent[i][0] = center[0] + x * cos - y * sin
      extent[i][1] = center[1] + x * sin + y * cos
    }
    /** Закрытие LinearRing, чтобы избежать ошибок jsts */
    const newExtent = [...extent, extent[0]]
    return newExtent
  } catch (err) {
    // Почему-то при обновлении страницы падает
    console.error('Assertion error')
  }
}

export const createBboxFromExtent = (extent: Point2D[]) => ({
  x1: extent[0][0],
  x2: extent[2][0],
  y1: extent[0][1],
  y2: extent[2][1],
})

export const createBboxForPoint = (point: Point2D, padding = DEFAULT_PADDING) => ({
  x1: point[0] - padding,
  x2: point[0] + padding,
  y1: point[1] + padding,
  y2: point[1] - padding,
})

export const getFeatureByExtent = (extent: number[][]) => new Feature(new Polygon([extent]))

/** Функция сброса курсора для viewport */
export const removeViewportCursor = (map: IMapOl) => {
  map.getViewport().style.cursor = ''
}
