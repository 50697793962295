import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import styled from 'styled-components'
import { TCaseLinkDTO } from 'types/ICase'

import { AllCasesContainer } from './AllCasesContainer'

const StyledPanel = styled.div`
  position: absolute;
  overflow: auto;
  max-height: 80%;
  top: 130px;
  right: calc(100% + 8px);
  padding: 8px;
  width: 268px;
  border-radius: 5px;
  z-index: 100;
  ${() => modalBackground}
  ${() => modalShadow}
`

type Props = {
  caseRecords: TCaseLinkDTO[]
}

export const AllCasesToolPanel = ({ caseRecords }: Props) => (
  <StyledPanel>
    <AllCasesContainer caseRecords={caseRecords} />
  </StyledPanel>
)
