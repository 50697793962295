import * as Sentry from '@sentry/react'
import { RadioChangeEvent } from 'antd/es/radio/interface'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { useTaskQuery } from 'entities/tasks/api/query'
import { useAddAnnotationsMutation, useAnnotationsQuery } from 'features/annotations/api/query'
import { IAnnotationQuery } from 'features/annotations/api/type'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { notices } from 'features/notices'
// @ts-ignore
import * as FileSaver from 'file-saver'
import { debounce } from 'lodash'
import { selectTasksViewerUrlTaskId, viewerPageSlice, ViewerRightPanelType } from 'pages/viewer'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { declensionWord } from 'shared/lib/text'
import {
  ButtonElement,
  DropdownElement,
  IconElement,
  MenuElement,
  MenuItemElement,
  SegmentElement,
  TextElement,
} from 'shared/ui/kit'
import { MainToolbarButton, ToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import styled from 'styled-components/macro'
import { IAnnotation, ITaskAnnotationsImport } from 'types/IAnnotations'
import { AnnotationFilterWindow } from 'viewer/map/layers/annotations/ui/filter/AnnotationFilterWindow'

import { RightPanelMode } from './ViewerRightPanel'

/**
 * минимальное количество координат для полигона
 */
const POLYGON_MIN_COORDINATES = 4
/**
 * Буффер для обработки закрытий окна фильтрации
 */
const CLICK_DELAY = 200

const Title = styled.div<{ side: 'left' | 'right' }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > svg {
    color: #c4c4c4;
    cursor: pointer;
  }

  margin-bottom: ${({ side }) => (side === 'left' ? '8px' : '0')};
  padding-right: ${({ side }) => (side === 'left' ? '13px' : '0')};
`
const StyledButtonElement = styled(ButtonElement)`
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 8px;
  background: none;
`
type Props = {
  title: string
  side: 'left' | 'right'
  segmentState?: RightPanelMode
  setSegmentState?: (val: RightPanelMode) => void
  isCaseNotes?: boolean
  activeTab?: ViewerRightPanelType
}

const SidePanelHeaderContainer = ({ activeTab, isCaseNotes, segmentState, setSegmentState, side, title }: Props) => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const { mutateAsync: addAnnotations } = useAddAnnotationsMutation({ caseId, slideId })
  const { data: annotationsIds } = useAnnotationsQuery(caseId, slideId)
  const [isAnnotationFilterHidden, setAnnotationFilterHidden] = useState<boolean>(true)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const theme = useThemeContext()

  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const closePanel = () => {
    if (side === 'left') {
      dispatch(viewerPageSlice.actions.toggleViewerLeftPanel(undefined))
    } else {
      dispatch(viewerPageSlice.actions.toggleViewerRightPanel(undefined))
      dispatch(annotationsSlice.actions.setAnnotationType())
    }
  }
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { data: task } = useTaskQuery(taskId)
  const isPausedTask = task?.status === 'PAUSED'

  const toggleAnnotationFilter = () => {
    setAnnotationFilterHidden(!isAnnotationFilterHidden)
  }
  const debouncedToggleAnnotationFilter = debounce(toggleAnnotationFilter, CLICK_DELAY)

  const importAnnotation = () => {
    hiddenFileInput.current?.click()
  }

  const exportAnnotation = () => {
    const annotations: IAnnotation[] =
      (annotationsIds?.ids
        ?.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
        .filter((item) => item?.data) as IAnnotation[]) || []
    const file = new File([JSON.stringify(annotations)], `annotationsSlideId${slideId}TaskId${taskId}.txt`, {
      type: 'text/plain;charset=utf-8',
    })
    FileSaver.saveAs(file)
  }

  const filterValidPolygons = (files: string) =>
    JSON.parse(files).filter((key: ITaskAnnotationsImport) => {
      const formattedFeature = key?.data?.formattedFeature
      if (formattedFeature) {
        const type = JSON.parse(formattedFeature).geometry.type
        if (type !== 'POLYGON' && type !== 'Polygon') return true
        const coordinatesCount = formattedFeature ? JSON.parse(formattedFeature).geometry.coordinates[0].length : 0
        return coordinatesCount >= POLYGON_MIN_COORDINATES
      }
    })

  const handleChange: any = async ({ currentTarget }: any) => {
    const reader = new FileReader()
    reader.readAsText(currentTarget.files[0])
    reader.onloadend = async () => {
      const res: any = reader.result
      const newFile = filterValidPolygons(res)
      const jsonFileLength = JSON.parse(res).length
      /** Добавляем актуальный slideId */
      const modifySlideId = newFile.map((item: IAnnotation) => item && { ...item, slideId })
      await addAnnotations({ annotations: modifySlideId })
      const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
      dispatch(annotationsSlice.actions.setAnnotationsIsVisible([...(ids?.ids || [])]))
      if (jsonFileLength !== newFile.length) {
        const unloadedFileCount = jsonFileLength - newFile.length
        const declension = declensionWord(unloadedFileCount, [t('аннотация'), t('аннотации'), t('аннотаций')])
        const errorMessage = `${t('Не загружено')} ${unloadedFileCount} ${declension} ${t('из')} ${jsonFileLength} ${t(
          'по причине неправильной геометрической формы аннотаций',
        )}`
        Sentry.captureException(errorMessage)
        notices.error({
          message: errorMessage,
        })
      }
      currentTarget.value = ''
    }
  }

  const menu = (
    <MenuElement>
      <MenuItemElement key="1" onClick={importAnnotation}>
        {t('Загрузить разметку')}
      </MenuItemElement>
      <MenuItemElement key="2" onClick={exportAnnotation}>
        {t('Скачать разметку')}
      </MenuItemElement>
    </MenuElement>
  )

  const segmentObject: { value: string; label: string }[] = [
    { label: t('Комментарии'), value: t('Комментарии') },
    { label: t('Заметки'), value: t('Заметки') },
  ]
  const onSegmentChange = (e: RadioChangeEvent) => {
    if (setSegmentState) {
      setSegmentState(e.target.value)
    }
  }
  if (title === t('О случае')) return null

  const isAnnotations = title === t('Аннотации')

  return (
    <Title side={side}>
      {isCaseNotes ? (
        <SegmentElement
          colorTheme={theme.theme}
          style={{ width: `100%` }}
          options={segmentObject}
          value={segmentState}
          onChange={onSegmentChange}
        />
      ) : (
        <TextElement>{title}</TextElement>
      )}
      <div style={{ display: 'flex' }}>
        {isAnnotations && (
          <>
            {taskId ? (
              !isPausedTask && (
                <DropdownElement overlay={menu} trigger={['click']}>
                  <StyledButtonElement icon={<IconElement name="more" />} shape="default" size="middle" />
                </DropdownElement>
              )
            ) : (
              <MainToolbarButton
                active={!isAnnotationFilterHidden}
                onClick={debouncedToggleAnnotationFilter}
                icon={<IconElement name={'headerFilter'} />}
              />
            )}
          </>
        )}
        {activeTab !== SideRightPanelType.NOTIFICATION && (
          <ToolbarButton onClick={closePanel} icon={<IconElement name={'cross'} />} />
        )}
        {!taskId && (
          <AnnotationFilterWindow isVisible={!isAnnotationFilterHidden} onClose={debouncedToggleAnnotationFilter} />
        )}
      </div>
      <input type="file" ref={hiddenFileInput} onChange={(val) => handleChange(val)} style={{ display: 'none' }} />
    </Title>
  )
}

export default SidePanelHeaderContainer
