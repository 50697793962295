import { useSlideQuery } from 'entities/slide'
import { MapBrowserEvent } from 'ol'
import EventType from 'ol/events/EventType'
import MapBrowserEventType from 'ol/MapBrowserEventType'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect } from 'react'
import { useMapProvided } from 'shared/lib/map/ui/MapProvider'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import { shiftZoomKeys, zoomAndMoveKeys } from 'viewer/map/layers/objects-counting/lib/helpers/constants'
import ZoomControlInteraction from 'viewer/map/layers/objects-counting/lib/interactions/ZoomControlInteraction'
import {
  SCREEN_RECORD_ZOOM_LVL,
  useObjectsCountingContext,
} from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

const ZoomInteraction = () => {
  const { map, viewerId } = useMapProvided()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  const { lastCursorPositionPixel, setCursor, setLastCursorPositionPixel, updateCursor } = useObjectsCountingContext()
  const zoomControlInteraction = new ZoomControlInteraction()

  const updCursorStyle = (pixelPosition: number[]) => {
    map.once('moveend', () => {
      const resolution = map.getView().getResolution() || 0
      const zoomLvl = getResolutionByZoomLevel(resolution, slide)
      const coordinate = map.getCoordinateFromPixel(pixelPosition)

      zoomLvl >= SCREEN_RECORD_ZOOM_LVL ? updateCursor(coordinate) : setCursor('default')
    })
  }

  const onDblClickZoom = (evt: MapBrowserEvent<UIEvent>) => {
    const pixel = map.getEventPixel(evt.originalEvent)
    updCursorStyle(pixel)
  }

  const onKeyboardZoom = (evt: MapBrowserEvent<KeyboardEvent>, pixel: number[]) => {
    const { key, shiftKey } = evt.originalEvent
    if (zoomAndMoveKeys.includes(key) || (shiftKey && shiftZoomKeys.includes(key))) {
      updCursorStyle(pixel)
    }
  }

  const onZoomWheel = (evt: MapBrowserEvent<UIEvent>) => {
    const pixel = map.getEventPixel(evt.originalEvent)
    updCursorStyle(pixel)
  }

  const isKeyboardEvent = (evt: MapBrowserEvent<UIEvent>): evt is MapBrowserEvent<KeyboardEvent> =>
    evt.originalEvent instanceof KeyboardEvent

  useEffect(() => {
    zoomControlInteraction.handleEvent = (evt: MapBrowserEvent<UIEvent>) => {
      if (isKeyboardEvent(evt) && [EventType.KEYDOWN, EventType.KEYPRESS].includes(evt.type)) {
        onKeyboardZoom(evt, lastCursorPositionPixel)
      } else if (evt.type === MapBrowserEventType.DBLCLICK) {
        const resolution = map.getView().getResolution() || 0
        const zoomLvl = getResolutionByZoomLevel(resolution, slide)

        if (zoomLvl >= SCREEN_RECORD_ZOOM_LVL) return true
        onDblClickZoom(evt)
      } else if (evt.type === EventType.WHEEL) {
        onZoomWheel(evt)
      } else if (evt.type === MapBrowserEventType.POINTERMOVE) {
        const pixel = map.getEventPixel(evt.originalEvent)
        setLastCursorPositionPixel(pixel)
      }

      return true
    }

    map.addInteraction(zoomControlInteraction)
    return () => {
      map.removeInteraction(zoomControlInteraction)
    }
  }, [map, lastCursorPositionPixel])

  return null
}

export default ZoomInteraction
