import { useUserActivityContext, useUserStatusContext } from 'features/multiplayer/lib'
import { formatStringToViewerState } from 'features/multiplayer/lib/user-activity'
import { MPUserStatus } from 'features/multiplayer/lib/user-status'
import { useMapParamsProvided } from 'pages/viewer/lib/common/MapsProvider'
import { fromDegreesToRadians } from 'pages/viewer/lib/helper'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'

const EMPTY_OBJECT = {}

const useFollowActiveUser = (viewerId: TViewerId) => {
  const { panelState } = useUserActivityContext()
  const { targetUserId, unsubscribeFromUser, usersStatuses } = useUserStatusContext()
  const { setCurrentParam } = useMapParamsProvided()
  const { t } = useTranslation()

  const followedUser = useMemo(() => usersStatuses.find((it) => it.uid === targetUserId), [targetUserId, usersStatuses])

  useEffect(() => {
    const serializedPanelState = formatStringToViewerState(panelState[viewerId])
    if (!targetUserId || !serializedPanelState) return
    setCurrentParam(viewerId, 'center', [serializedPanelState.centerX, serializedPanelState.centerY])
    setCurrentParam(viewerId, 'zoom', serializedPanelState.zoom)
    setCurrentParam(viewerId, 'rotation', fromDegreesToRadians(serializedPanelState.rotation))
  }, [panelState[viewerId], targetUserId])

  useDeepCompareEffect(() => {
    if (targetUserId && (!followedUser || followedUser.sta === MPUserStatus.Idle)) {
      unsubscribeFromUser(`${t('Пользователь, за которым вы следовали, перестал быть активным')}.`)
    }
  }, [followedUser || EMPTY_OBJECT])
}

export default useFollowActiveUser
