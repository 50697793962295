/** Конвертирование из camelCase в SNAKE_CASE */
export const convertToUpperCaseWithUnderscore = (str: string) => {
  if (['ascend', 'descend'].includes(str)) return str.replace('end', '').toUpperCase()

  return str
    .replace(/([A-Z])/g, '_$1')
    .replace(/^_/, '')
    .replace(/([a-z])([0-9])/gi, '$1_$2')
    .toUpperCase()
}
