import { useTaskClasses } from 'entities/tasks/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { Feature } from 'ol'
import { always } from 'ol/events/condition'
import Transform from 'ol-ext/interaction/Transform'
import { selectTasksViewerUrlTaskId } from 'pages/viewer'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import { AnnotationType } from 'types/IAnnotations'
import { IMapOl } from 'types/IMapOl'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { setTransformStyle } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { isObjectsCounting } from 'viewer/map/layers/annotations/lib/helpers'
import {
  completedMitosisSpotStyle,
  defaultStyle,
  getCustomClass,
  styleByAnnotationClass,
} from 'viewer/map/layers/olStyles'

type Props = {
  /** Массив выбранных (select) Feature аннотаций */
  features?: Feature<any>[]
  /** Экземпляр olMap карты */
  map: IMapOl
  saveFeature: (lastFeature: Feature<any>) => void
  /** Стейт загрузки после редактирования */
  isLoading: boolean
  /** Доступность селекта аннотации в зависимости от пользователя */
  isTransformAvailable: boolean
  /** Текущая прозрачность */
  annotationsOpacity: number
}

const TransformInteraction = memo(
  ({ annotationsOpacity, features, isLoading, isTransformAvailable, map, saveFeature }: Props) => {
    const dispatch = useDispatch()
    const [isKeepAspect, setKeepAspect] = useState(false)
    const taskId = useSelector(selectTasksViewerUrlTaskId)
    const { data: currTaskClasses } = useTaskClasses(taskId)

    const handleShiftKeyDown = (event: KeyboardEvent) => event?.shiftKey && setKeepAspect(true)

    const handleShiftKeyUp = (event: KeyboardEvent) => !event.shiftKey && setKeepAspect(false)

    useEffect(() => {
      dispatch(annotationsSlice.actions.setAnnotationsLoading(isLoading))
      return () => {
        dispatch(annotationsSlice.actions.setAnnotationsLoading(false))
      }
    }, [isLoading])
    useDeepCompareEffect(() => {
      if (features?.length === 0) return
      // setTimeout для обновления стиля после срабатывания setDefaultStyle на hoveredAnnotationId
      setTimeout(() =>
        features?.forEach((f) => {
          const aClass = f.get('class')
          const aType = f.get('annotation_type')

          const geometry = f?.getGeometry()
          f.setStyle(
            aClass
              ? styleByAnnotationClass({
                  annotationClass: aClass,
                  annotationType: aType,
                  customClass: getCustomClass(aClass, aType, currTaskClasses),
                  geometry,
                  isSelect: true,
                  isTask: !!taskId,
                  opacity: annotationsOpacity / MAX_OPACITY,
                })
              : isObjectsCounting(aType)
              ? completedMitosisSpotStyle
              : defaultStyle,
          )
        }),
      )
      const isAllFeaturesPoint = !features?.find((f) => {
        const aType = f.get('annotation_type')
        return aType !== AnnotationType.POINT
      })
      const transform = new Transform({
        addCondition: () => false,
        hitTolerance: 10,
        keepAspectRatio: () => isKeepAspect,
        modifyCenter: always as any,
        rotate: false,
        selection: !isTransformAvailable,
        translate: false,
        // @ts-ignore
        translateBBox: !isLoading,
        translateFeature: isAllFeaturesPoint,
      })
      // @ts-ignore
      transform.on('scaleend', () => {
        features?.forEach((f) => saveFeature(f))
      })
      // @ts-ignore
      transform.on('translateend', (e) => {
        // @ts-ignore
        const oldCoordinates = e?.oldgeom?.flatCoordinates[0]
        // @ts-ignore
        const newCoordinates = e?.feature?.values_?.geometry?.flatCoordinates[0]
        if (oldCoordinates === newCoordinates) return
        features?.map((f) => f.set('changed', true))
        features?.forEach((f) => {
          if (f?.get('changed')) saveFeature(f)
        })
      })

      document.addEventListener('keydown', handleShiftKeyDown)

      document.addEventListener('keyup', handleShiftKeyUp)

      map?.addInteraction(transform)
      setTransformStyle(transform)
      try {
        features?.forEach((f) => {
          f instanceof Feature && transform?.select(f, true)
        })
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return () => {
        transform && map?.removeInteraction(transform)
        document.removeEventListener('keydown', handleShiftKeyDown)
        document.removeEventListener('keyup', handleShiftKeyUp)
      }
    }, [features, isLoading, isKeepAspect])

    return null
  },
)

export default TransformInteraction
