import { Divider } from 'antd'
import { CommentAttachmentContainer } from 'features/attachments'
import AnnotationButtonContainer from 'features/comments/AnnotationButtonContainer'
import { viewerPageSlice } from 'pages/viewer'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { formatDateTimeForDisplay } from 'shared/lib/date'
import {
  AvatarElement,
  ButtonElement,
  ParagraphElement,
  SmallIconButtonElement,
  SpaceElement,
  SpinElement,
  TextAreaElement,
  TextElement,
  TitleElement,
} from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IComment } from 'types/IComment'

const Header = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas: 'avatar fullname actions' 'avatar time edited';
  column-gap: 8px;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
`

const Actions = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  & > * {
    visibility: hidden;
  }
`

const EditCommentButtons = styled.div<{ withAttachments: boolean }>`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: ${({ withAttachments }) => (withAttachments ? '1fr 1fr auto' : '1fr 1fr')};
  & > button {
    width: 100%;
  }
`

const StyledComment = styled.div`
  margin: 8px 0;
  &:hover {
    ${Actions} {
      & > * {
        visibility: visible;
      }
    }
  }
`

type Props = {
  /** объект комментария */
  comment: IComment
  /** колбэк удаления */
  onRemove: () => Promise<any>
  /** колбэк редактирования */
  onEdit: (body: string) => Promise<any>
  /** состояние поля ввода комментария */
  isEditing?: boolean
  /** установка состояния поля ввода комментария */
  setEditing: (commentId: number | null) => void
  /** прикреплена ли ссылка на область */
  withAttachments: boolean
  /** id кейса */
  caseId: number
  /** состояние загрузки */
  isLoading: boolean
}

const Comment = ({ caseId, comment, isEditing, isLoading, onEdit, onRemove, setEditing, withAttachments }: Props) => {
  const dispatch = useDispatch()
  const commentRef = useRef<HTMLDivElement>(null)
  const [body, setBody] = useState(comment.body)
  const { t } = useTranslation()
  const onCommentSave = async () => {
    if (!body || body.length > 10240) {
      return
    }
    await onEdit(body)
    setEditing(null)
  }

  const toggleInputFocus = (payload: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(payload))

  const onClickEdit = () => {
    commentRef.current?.scrollIntoView({
      block: 'nearest',
    })
    setEditing(isEditing ? null : comment.commentId)
  }

  return (
    <StyledComment ref={commentRef}>
      <SpinElement spinning={isLoading}>
        {comment.user && (
          <Header>
            <AvatarElement
              numberValue={comment.user.userId}
              fullname={comment.user.fullname}
              style={{ gridArea: 'avatar' }}
            />
            <TitleElement type="secondary" level={4} style={{ gridArea: 'fullname' }} ellipsis>
              {comment.user.fullname}
            </TitleElement>

            <TextElement type="secondary" ellipsis style={{ gridArea: 'time' }}>
              {formatDateTimeForDisplay(new Date(comment.updatedAt || comment.createdAt))}
            </TextElement>
            <SpaceElement size={4} />
            {comment.edited && (
              <TextElement type="secondary" style={{ gridArea: 'edited' }}>
                {t('Изменен')}
              </TextElement>
            )}

            {comment.isOwner && (
              <Actions style={{ gridArea: 'actions' }}>
                <SmallIconButtonElement title={t('Изменить')} icon="edit" onClick={onClickEdit} />
                <SmallIconButtonElement title={t('Удалить')} icon="delete" onClick={onRemove} />
              </Actions>
            )}
          </Header>
        )}
        {isEditing ? (
          <div>
            <TextAreaElement
              onFocus={() => toggleInputFocus(true)}
              onBlur={() => toggleInputFocus(false)}
              autoSize={{ maxRows: 10, minRows: 1 }}
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
            {body.length > 10240 && (
              <TextElement type="danger">
                {t('Максимум 10240 символов')} ({body.length})
              </TextElement>
            )}
            <SpaceElement size={8} />
            <EditCommentButtons withAttachments={withAttachments}>
              <ButtonElement onClick={() => setEditing(null)}>{t('Отменить')}</ButtonElement>
              <ButtonElement type="primary" onClick={onCommentSave}>
                {t('Сохранить')}
              </ButtonElement>
              {withAttachments ? <AnnotationButtonContainer linkedComment={comment.commentId} /> : null}
            </EditCommentButtons>
          </div>
        ) : (
          <div>
            <ParagraphElement>{comment.body}</ParagraphElement>
            {comment.attachments?.length ? (
              <CommentAttachmentContainer attachment={comment.attachments[0]} caseId={caseId} />
            ) : null}
          </div>
        )}
        <Divider />
      </SpinElement>
    </StyledComment>
  )
}

export default Comment
