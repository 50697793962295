export enum LayerType {
  GRID = 'GRID',
  SEGMENTATION = 'SEGMENTATION',
  RESULT_BBOX = 'RESULT_BBOX',
  HEATMAP = 'HEATMAP',
  CURRENT_USER_ANNOTATIONS = 'CURRENT_USER_ANNOTATIONS',
  OTHER_USERS_ANNOTATIONS = 'OTHER_USERS_ANNOTATIONS',
  ANNOTATIONS = 'ANNOTATIONS',
  KI_67 = 'KI_67',
  MITOSIS = 'MITOSIS',
  MITOSIS_POINTS = 'MITOSIS_POINTS',
  MITOSIS_VIEWED_TRACK = 'MITOSIS_VIEWED_TRACK',
  OV_VIEW = 'OV_VIEW',
  PV_PROSTATE = 'PV_PROSTATE',
  ARTEFACTS = 'ARTEFACTS',
  MEDICALNEURONETS_CRC = 'MEDICALNEURONETS_CRC',
}

export const INTERNAL_TYPE_ID = 'INTERNAL_TYPE'
export const ANNOTATION_ID = 'ANNOTATION_ID'
export const WEBGL_ANNOTATION_POS = 'WEBGL_ANNOTATION_POS'
export const WEBGL_ANNOTATION_NEG = 'WEBGL_ANNOTATION_NEG'
export const ANNOTATION_IS_UPDATED = 'ANNOTATION_IS_UPDATED'
export const OV_VIEW_RESOLUTION = 'OV_VIEW_RESOLUTION'
