import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUploadingFile } from 'processes/tus/types'

type State = {
  byId: Record<string, IUploadingFile>
  listByCase: Record<number, string[]>
  queue: string[]
}

const initialState: State = {
  byId: {},
  listByCase: {},
  queue: [],
}

export const tusFilesSlice = createSlice({
  initialState,
  name: 'tusFilesSlice',
  reducers: {
    addUploadingFile(state, { payload }: PayloadAction<IUploadingFile>) {
      state.byId[payload.uuid] = payload
      const list = state.listByCase[payload.caseId] || []
      state.listByCase[payload.caseId] = [...list, payload.uuid]
    },
    pushFileToQueue(state, { payload }: PayloadAction<string>) {
      state.queue.push(payload)
    },
    removeUploadingFile(state, { payload }: PayloadAction<{ uuid: string; caseId: number }>) {
      delete state.byId[payload.uuid]
      state.listByCase[payload.caseId] = (state.listByCase[payload.caseId] || []).filter((id) => id !== payload.uuid)
    },
    shiftFileFromQueue(state) {
      state.queue.shift()
    },
    updateUploadingFile(state, { payload }: PayloadAction<IUploadingFile>) {
      state.byId[payload.uuid] = {
        ...state.byId[payload.uuid],
        ...payload,
      }
    },
  },
})
