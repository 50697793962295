import { ICasePermission, IPermissionActionType, PermissionData } from 'entities/permissions/types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IOptions } from 'types/IOptions'

import permissionsService from './service'

export const usePermissionsQuery = (caseId: number) =>
  useQuery([QUERY_TYPE.PERMISSION, { caseId }], async () => {
    const permissions = await permissionsService.getCasePermission(caseId)
    return permissions
  })

export const useUsersQuery = (workspaceId?: string) =>
  useQuery([QUERY_TYPE.USERS, { workspaceId }], async () => {
    const users = await permissionsService.getUsersSharing()
    const options: IOptions = {
      isAutocompleteSource: false,
      usersGroupByWorkspaceName: users.usersGroupByWorkspaceName,
    }
    return options
  })

export const useDeletePermissionMutation = (caseId: number) => {
  const queryClient = useQueryClient()
  return useMutation(async (permissionId: number) => {
    await permissionsService.deleteCasePermission(caseId, permissionId)
    const permissions = queryClient.getQueryData<ICasePermission[]>([QUERY_TYPE.PERMISSION, { caseId }])
    queryClient.setQueryData([QUERY_TYPE.PERMISSION, { caseId }], () =>
      permissions?.filter((p) => p.casePermissionId !== permissionId),
    )
  })
}

export const useChangePermissionMutation = (caseId: number) => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ permissionId, permissionLevel }: PermissionData) => {
      const result = await permissionsService.changeCasePermission(caseId, permissionId, { permissionLevel })
      return result
    },
    {
      onSuccess: (result) => result.success && queryClient.invalidateQueries([QUERY_TYPE.PERMISSION, { caseId }]),
    },
  )
}

export const useGrantPermission = (caseId: number) => {
  const queryClient = useQueryClient()
  return useMutation(async (payload: { permission: IPermissionActionType }) => {
    await permissionsService.grantCasePermission(caseId, payload.permission)
    queryClient.invalidateQueries([QUERY_TYPE.PERMISSION, { caseId }])
  })
}
