import { goBack } from 'connected-react-router'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconButtonElement, IconElement, SpinElement } from 'shared/ui/kit'
import Logo from 'shared/ui/logo'
import styled from 'styled-components/macro'

import { loadViewer } from './lib'

const Top = styled.div`
  height: 48px;
  background-color: var(--color-bg-1);
  padding: 0 8px;
  border-bottom: 1px solid var(--color-border-1);
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
`
const Content = styled.div`
  height: calc(100% - 48px);
`
const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
`
const BASE_DICOM_PATH = 'https://dicom.cloud.onecell.ai'
// const BASE_DICOM_PATH = 'https://dicom.dev.onecell.ru'
//dicom.cloud.onecell.ai/dicom-web/

const containerId = 'ohif'

// TODO: create feature
export const OhivViewerRoute = () => {
  const dispatch = useDispatch()
  const onBack = () => {
    dispatch(goBack())
  }
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    //@ts-ignore
    window.config = {
      routerBasename: '/ohiv',
      servers: {
        dicomWeb: [
          {
            imageRendering: 'wadors',
            name: 'Orthanc',
            qidoRoot: BASE_DICOM_PATH + '/dicom-web',
            qidoSupportsIncludeField: false,
            thumbnailRendering: 'wadors',
            wadoRoot: BASE_DICOM_PATH + '/dicom-web',
            wadoUriRoot: BASE_DICOM_PATH + '/wado',
          },
        ],
      },
    }
    localStorage.setItem('i18nextLng', 'ru')
    Cookies.set('i18next', 'ru')
    loadViewer(() => {
      //@ts-ignore
      window.OHIFViewer.installViewer(
        {
          routerBasename: '/ohiv',
          servers: {
            dicomWeb: [
              {
                imageRendering: 'wadors',
                name: 'DCM4CHEEa',
                qidoRoot: BASE_DICOM_PATH + '/dicom-web',
                qidoSupportsIncludeField: false,
                thumbnailRendering: 'wadors',
                wadoRoot: BASE_DICOM_PATH + '/dicom-web',
              },
            ],
          },
        },
        containerId,
        () => {
          console.log('OHIF Viewer rendered/updated')
          //@ts-ignore
          setLoading(false)
          localStorage.setItem('i18nextLng', 'ru')
          Cookies.set('i18next', 'ru')
          setTimeout(() => {
            Array.from(document.getElementsByClassName('bottomLabel')).forEach((el) => {
              if (el.innerHTML === 'Measurements') {
                // eslint-disable-next-line i18next/no-literal-string
                el.innerHTML = 'Измерения'
              }
            })
          }, 0)
        },
      )
    })
  }, [])

  return (
    <SpinElement spinning={isLoading}>
      <Wrapper>
        <Top>
          <IconButtonElement icon={<IconElement name="arrowBack" />} onClick={onBack} />
          {/* <Link style={{ display: 'flex' }} to="/dashboard?filter=new"></Link> */}

          <Logo />
        </Top>
        <Content>
          <ContentWrapper id={containerId} />
        </Content>
      </Wrapper>
    </SpinElement>
  )
}

export { GlobalOhivStyles } from './ui'
