import { get } from 'shared/api'
import { DictionaryId, DictionaryItem, DictionaryVersion } from 'types/IDictionary'

const baseUrl = '/dictionary'

export enum DictionaryEndpoints {
  ICD_10 = '/icd-10',
  MORPHOLOGY_CODE = '/morphologies',
  SERVICE = '/services',
  MATERIAL_TYPE = '/material-types',
  DIAGNOSTIC_PROCEDURE_TYPE = '/diagnostic-procedure-types',
  CASE_RESEARCH_TYPE = '/case-research-types',
  SITE = '/sites',
  PROCESS_NATURE = '/process-natures',
  STAIN = '/stains',
  MODALITY = '/modalities',
  TOPOGRAPHY_CODE = '/topographies ',
}

export enum DictionaryNS {
  ICD_10 = 'ICD_10',
  MORPHOLOGY_CODE = 'MORPHOLOGY_CODE',
  DIAGNOSTIC_PROCEDURE_TYPE = 'DIAGNOSTIC_PROCEDURE_TYPE',
  CASE_RESEARCH_TYPE = 'CASE_RESEARCH_TYPE',
  SITE = 'SITE',
  STAIN = 'STAIN',
}

const getDictionaryInfo = async (workspaceId?: string) => {
  const res = await get<DictionaryVersion[]>({
    url: `${baseUrl}/info`,
    workspaceId,
  })
  return res
}

const getDictionaryItems = async (identifier: DictionaryId) => {
  const url = baseUrl + DictionaryEndpoints[identifier]
  const res = await get<DictionaryItem[]>({
    url,
  })
  return res
}

export const dictionaryService = { getDictionaryInfo, getDictionaryItems }
