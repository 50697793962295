import { get } from 'shared/api'
import { ECaseLinkFilter, TCaseLinkDTO } from 'types/ICase'

const getCaseLink = async (caseId: number, workspaceId: number, caseLinkType: ECaseLinkFilter) =>
  get<TCaseLinkDTO[]>({
    config: {
      params: {
        type: caseLinkType,
      },
    },
    url: `/workspace/${workspaceId}/case/${caseId}/link`,
  })

export const caseInfoService = {
  getCaseLink,
}
