import { useDrag } from '@use-gesture/react'
import thumb from 'assets/icons/thumb.svg'
import { DEFAULT_MAXIN, DEFAULT_MININ, MAX_MIDIN, MIN_MIDIN } from 'pages/viewer/model/slideMapViewSlice'
import { convertToFractionalRange } from 'shared/lib/range'
import { InputNumberElement } from 'shared/ui/kit'
import styled from 'styled-components'

const Slider = styled.div`
  position: relative;
  padding: 4px;
  width: 256px;
  height: 8px;
  background: linear-gradient(90deg, #000000 0%, #ffffff 100%);
  border-radius: 2px;
`

const Handle = styled.div`
  cursor: pointer;
  position: absolute;
  background: url(${thumb});
  width: 16px;
  height: 16px;
  transform: translateX(-50%);
  touch-action: pan-x;
`

const LevelsInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  & > * {
    flex: 0 0 60px;
  }
`

const Wrapper = styled.div`
  max-width: 256px;
  margin-top: 8px;
`

const OpacityContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
  border-bottom: 1px solid var(--color-border-1);
`

/** Ограничение наложения ползунков друг на друга */
const OUTER_RANGE_PADDING = 15
const INNER_RANGE_PADDING = 10

/** Нужно небольшое смещение, тк при округлении
 * пропорционального смещения ползунка гаммы может получится одно и то же значение.
 * В результате этого ползунок остается на месте.
 */
const RANDOM_SMALL_OFFSET = 0.0001

type HandleType = 'min' | 'mid' | 'max'

/** Props for GammaCorrection component */
type Props = {
  /** Смещение крайнего левого ползунка (точка черного) */
  minin: number
  /** Числовое значение гаммы. Отображается в поле для ввода */
  midin: number
  /** Смещение крайнего правого ползунка (точка белого) */
  maxin: number
  /** Отношение смещения среднего ползунка (гамма) */
  mid: number
  /** Функция установки положения крайнего левого ползунка */
  setMinin: any
  /** Функция установки числового значения гаммы */
  setMidin: any
  /** Функция установки положения крайнего правого ползунка */
  setMaxin: any
  /** Функция установки отношения смещения среднего ползунка */
  setMid: any
  /** Функция переключения фокуса */
  toggleInputFocus: (value: boolean) => void
}

export const GammaCorrection = ({
  maxin,
  mid,
  midin,
  minin,
  setMaxin,
  setMid,
  setMidin,
  setMinin,
  toggleInputFocus,
}: Props) => {
  const bindMin = useDrag(({ delta }) => {
    /** Проверка на пограничные значения */
    setMinin((prev: number) => Math.min(Math.max(prev + delta[0], DEFAULT_MININ), maxin - OUTER_RANGE_PADDING))
  })

  const bindMid = useDrag(({ delta, ...rest }) => {
    /** Текущее смещение среднего ползунка */
    const offset = (maxin - minin) * mid + minin
    /** Текущее смещение среднего ползунка с учетом его перемещения */
    const value = Math.min(Math.max(offset + delta[0], minin + INNER_RANGE_PADDING), maxin - INNER_RANGE_PADDING)
    /** Новое значение отношения смещения среднего ползунка внутри диапазона крайних ползунков */
    const newMid = (value - minin) / (maxin - minin)
    setMid(newMid)
    setMidin(+convertToFractionalRange(newMid, MIN_MIDIN, MAX_MIDIN).toFixed(2))
  })

  const bindMax = useDrag(({ delta }) => {
    /** Проверка на пограничные значения */
    setMaxin((prev: number) => Math.min(Math.max(prev + delta[0], minin + OUTER_RANGE_PADDING), DEFAULT_MAXIN))
  })

  const onChange = (val: string | number, type: HandleType) => {
    switch (type) {
      case 'min':
        setMinin(+val)
        break
      case 'mid':
      case 'max':
        setMaxin(+val)
        break
    }
  }

  return (
    <OpacityContainer>
      <Wrapper>
        <Slider>
          <Handle role="slider" {...bindMin()} style={{ left: `${minin}px` }} />
          <Handle role="slider" {...bindMid()} style={{ left: `${(maxin - minin) * mid + minin}px` }} />
          <Handle role="slider" {...bindMax()} style={{ left: `${maxin}px` }} />
        </Slider>
        <LevelsInput onFocus={() => toggleInputFocus(true)} onBlur={() => toggleInputFocus(false)}>
          <InputNumberElement
            value={minin.toFixed(0)}
            min={DEFAULT_MININ}
            max={maxin - OUTER_RANGE_PADDING}
            onChange={(val) => onChange(val, 'min')}
            step={1}
          />
          <InputNumberElement
            value={midin}
            min={MIN_MIDIN}
            max={MAX_MIDIN}
            onChange={(val) => onChange(val, 'mid')}
            step={0.1}
            disabled
          />
          <InputNumberElement
            value={maxin.toFixed(0)}
            min={minin + OUTER_RANGE_PADDING}
            max={DEFAULT_MAXIN}
            onChange={(val) => onChange(val, 'max')}
            step={1}
          />
        </LevelsInput>
      </Wrapper>
    </OpacityContainer>
  )
}
