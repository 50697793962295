import { useTypedSelector } from 'app/redux/lib/selector'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { Collection, Feature, MapBrowserEvent } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import ModifyFeature from 'ol-ext/interaction/ModifyFeature'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import { AnnotationType, IAnnotation, IAnnotationStack } from 'types/IAnnotations'
import { IMapOl } from 'types/IMapOl'
import { IMarkupClass } from 'types/IMarkupSlide'
import TViewerId from 'types/TViewerId'
import { useViewerMainSelector } from 'viewer/container'
import { MAXIMUM_ANNOTATIONS_STACK_SIZE } from 'viewer/map/layers/annotations/lib/constants'
import { isRightClick } from 'viewer/map/layers/olEvents'
import { getArrowStyle, getCustomClass, styleByAnnotationClass, verticesStyle } from 'viewer/map/layers/olStyles'

type Props = {
  features?: Feature<any>[]
  viewerId: TViewerId
  map: IMapOl
  isLoading: boolean
}

const ChangeInteraction = ({ features, isLoading, map, viewerId }: Props) => {
  const { slideId } = useViewerIdSlideState(viewerId)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const { annotationsOpacity } = useTypedSelector((state) => state.annotations)

  useEffect(() => {
    if (features?.length === 0) {
      return
    }
    // setTimeout для обновления стиля ПОСЛЕ срабатывания setDefaultStyle на hoveredAnnotationId
    setTimeout(
      () =>
        features?.forEach((item) => {
          item.setStyle((feature) => {
            const annotationType = feature.get('annotation_type')
            const isArrow = annotationType === AnnotationType.ARROW
            const geometry = feature.getGeometry()
            const customClass = getCustomClass(item.get('class'), item.get('annotation_type'), currTaskClasses)
            return [
              ...styleByAnnotationClass({
                annotationClass: item.get('class'),
                annotationType: item.get('annotation_type'),
                customClass,
                isTask: !!taskId,
                opacity: annotationsOpacity / MAX_OPACITY,
              }),
              ...(isArrow ? getArrowStyle(geometry, '#4099F7', -12) : []),
              verticesStyle,
            ]
          })
        }),
      0,
    )
    dispatch(annotationsSlice.actions.setChangeMode(true))
    const polygonFeatures = features?.filter((feature) => {
      const type = feature?.get('annotation_type')
      return (
        type === AnnotationType.POLYGON ||
        type === AnnotationType.PEN ||
        type === AnnotationType.ARROW ||
        type === AnnotationType.RULER
      )
    })
    //collection length must be === 1
    const collection = new Collection(polygonFeatures)
    const arrowTransformHandler = (clickEvent: MapBrowserEvent<any>, polygonFeatures?: Feature<any>[]) => {
      if (!polygonFeatures || polygonFeatures.length === 0) return false
      let enable = true
      polygonFeatures.forEach((feature) => {
        const type = feature?.get('annotation_type')
        if (type === AnnotationType.ARROW || type === AnnotationType.RULER) {
          enable = false
        }
      })
      return enable
    }
    // @ts-ignore
    const transform = new ModifyFeature({
      condition: () => !isLoading,

      // @ts-ignore
      deleteCondition: (e) => !isLoading && isRightClick(e),

      features: collection,
      insertVertexCondition: (e) => arrowTransformHandler(e, polygonFeatures),
      pixelTolerance: 5,
    })
    // @ts-ignore
    transform.once('modifyend', (e) => {
      polygonFeatures?.forEach((f) => {
        f.set('slideId', slideId)
        f.set('changed', true)
      })
    })
    /**
     * Событие нужно для отслеживания редактирования аннотаций.
     * Оно изменяет типы аннотаций, если квадрат или круг сдвинут пользователем - изменён набор координат
     * по точкам - он становится полигоном. Каждое изменение координат записывается в историю
     */
    // @ts-ignore
    transform.on('modifystart', (e) => {
      const currentStack = queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, slideId]) || []
      if (selectedAnnotationsIds.length > 1 || features === undefined) return
      const selectedAnnotationId = selectedAnnotationsIds[0]
      // @ts-ignore
      const editingAnnotation = e.target.currentFeature
      const cachedAnnotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, selectedAnnotationId])
      if (!editingAnnotation || !cachedAnnotation) return
      const type = editingAnnotation?.get('annotation_type')
      const updatedType = type === AnnotationType.ARROW || type === AnnotationType.RULER ? type : AnnotationType.POLYGON
      if (type !== AnnotationType.POLYGON) {
        editingAnnotation?.set('annotation_type', updatedType)
      }
      queryClient.setQueryData(
        [QUERY_TYPE.ANNOTATIONS_STACK, slideId],
        [
          ...currentStack.slice(
            currentStack.length !== MAXIMUM_ANNOTATIONS_STACK_SIZE ? 0 : 1,
            MAXIMUM_ANNOTATIONS_STACK_SIZE,
          ),
          {
            annotation: {
              ...cachedAnnotation,
              data: {
                // @ts-ignore
                formattedFeature: new GeoJSON().writeFeature(e.features[0]),

                type: 'ANNOTATION',
              },
              type: updatedType,
            },
            type: 'edit',
          },
        ],
      )
    })
    map.addInteraction(transform as any)
    return () => {
      features?.forEach((item) => {
        item.setStyle(
          styleByAnnotationClass({
            annotationClass: item.get('class'),
            annotationType: item.get('annotation_type'),
            customClass: getCustomClass(item.get('class'), item.get('annotation_type'), currTaskClasses),
            isTask: !!taskId,
            opacity: annotationsOpacity / MAX_OPACITY,
          }),
        )
      })
      dispatch(annotationsSlice.actions.setChangeMode(false))
      transform && map.removeInteraction(transform as any)
    }
  }, [features, map, isLoading])

  return null
}

export default ChangeInteraction
