import { useTypedSelector } from 'app/redux/lib/selector'

import ThumbnailProgress from './ui/ThumbnailProgress'

type Props = {
  tusFileId: string
}

const TusFileLoaderContainer = ({ tusFileId }: Props) => {
  const file = useTypedSelector((state) => state.tusFiles.byId[tusFileId || ''])
  if (file) return <ThumbnailProgress percent={file.percent} />
  return null
}

export default TusFileLoaderContainer
