import i18next from 'shared/lib/i18n/i18n'

import ICase from './ICase'
import IUserData from './IUserData'
import { IWorkspace } from './IWorkspaces'

const t = i18next.t

export type DEEP_LINK_ERROR_CODE =
  | 'AUTH_USER_FORBIDDEN'
  | 'AUTH_USER_NOT_FOUND'
  | 'CASE_NOT_FOUND'
  | 'LINK_ALREADY_USED'
  | 'LINK_EXPIRED'
  | 'LINK_MALFORMED'
  | 'SERVER_ERROR'
  | 'SIGNATURE_INVALID'
  | 'WORKSPACE_NOT_CONFIGURED'
  | 'NOT_FOUND_CODE'

export const DEEP_LINK_ERROR_CODES_MESSAGES = {
  AUTH_USER_FORBIDDEN: t('Аутентификация пользователя запрещена'),
  AUTH_USER_NOT_FOUND: t('Не найден пользователь из ссылки'),
  CASE_NOT_FOUND: t('Не найден кейс из ссылки'),
  LINK_ALREADY_USED: t('Ссылка была использована '),
  LINK_EXPIRED: t('Срок действия ссылки истек'),
  LINK_MALFORMED: t('Ссылка неправильно сформирована'),
  NOT_FOUND_CODE: t('Не найден код ошибки'),
  SERVER_ERROR: t('Произошла ошибка сервера'),
  SIGNATURE_INVALID: t('Подпись невалидна'),
  WORKSPACE_NOT_CONFIGURED: t('Не найден воркспейс из ссылки'),
}

/** Параметры action. Для различных action в будущем будут приходить разные DTO. Нужно для бэка */
export enum DEEP_LINK_ACTIONS {
  /** action открытия вьювера */
  OPEN_VIEWER = 'open_viewer',
}

export type DeepLinkDTO = {
  /** Тип action */
  action: DEEP_LINK_ACTIONS
  /** Случай, который шарится в ссылке */
  case: ICase
  /** Статус запроса */
  ok: boolean
  /** Workspace авторизованного пользователя */
  workspace: IWorkspace
  /** Данные пользователя для авторизации (если активная чужая учетная запись) */
  loginAs?: IUserData
}
