import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useTaskClasses, useTaskQuery, useTaskSlideResult, useTaskSlides } from 'entities/tasks/api/query'
import { useViewerRoute } from 'pages/viewer/lib/common/hooks/useViewerRoute'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import NoSlides from 'pages/viewer/ui/common/NoSlides'
import TopToolbarContainer from 'pages/viewer/ui/common/TopToolbarContainer'
import ViewerLayout from 'pages/viewer/ui/common/ViewerLayout'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTasksSlideData, setTasksSlideData } from 'shared/lib/local-storage'
import { Center, SpinElement } from 'shared/ui/kit'
import { ILocalStorageTasksSlide } from 'types/IMarkupSlide'

import TasksLeftPanelContainer from './tree/TasksLeftPanelContainer'
import TasksRightPanelContainer from './tree/TasksRightPanelContainer'

const TasksViewerContainer = () => {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const { isNoSlide } = useViewerRoute()
  const { activeViewerId: viewerId, changeViewerSlide } = useViewerPageProvided()
  const userId = useTypedSelector((state) => state.user?.user?.userId)
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { data: currTaskClasses } = useTaskClasses(taskId)
  const isTaskViewer = !!useSelector(selectTasksViewerUrlTaskId) && currTaskClasses
  const taskData = useTaskQuery(taskId).data
  const { data: currentSlidesArr } = useTaskSlides(taskId)
  const { slideId } = useViewerIdSlideState('A')
  const currSlide = currentSlidesArr?.find((item) => item.slideId === slideId) || currentSlidesArr?.[0]
  const { data: useTasksSlideResult } = useTaskSlideResult(
    currSlide?.markupTaskId,
    currSlide?.markupSlideId,
    currSlide?.slideId,
  )
  useEffect(() => {
    dispatch(viewerPageSlice.actions.setLiteContextMenuVisibility(false))
    return () => {
      dispatch(viewerPageSlice.actions.resetAllViewersState())
      dispatch(viewerPageSlice.actions.setLiteContextMenuVisibility(true))
    }
  }, [])

  useEffect(() => {
    if (currSlide && (!slideId || isNaN(slideId))) {
      const firstSlideId = currSlide?.slideId
      dispatch(push(`/tasks-viewer/${taskId}?slideId=${firstSlideId}`))
      changeViewerSlide(viewerId || 'A', {
        caseId: NaN,
        slideGroupType: 'MACRO',
        slideId: firstSlideId,
        source: 'ATLAS',
        viewerMode: 'DEFAULT',
      })
    } else {
      const prevTasksSlideData: ILocalStorageTasksSlide[] | [] = JSON.parse(getTasksSlideData() || '[]')
      prevTasksSlideData.find((item) => item.task === taskId && item.userId === userId)
        ? setTasksSlideData(
            prevTasksSlideData?.map((item) =>
              item.task === taskId && item.userId === userId ? { ...item, slide: slideId } : item,
            ),
          )
        : setTasksSlideData([...prevTasksSlideData, { slide: slideId, task: taskId, userId: userId }])
    }
  }, [slideId, currSlide?.slideId])

  if (isNoSlide) {
    return <NoSlides onClick={() => {}} />
  }
  if (!taskData || !useTasksSlideResult) {
    return (
      <Center>
        <SpinElement />
      </Center>
    )
  }
  return isTaskViewer ? (
    <ViewerLayout
      leftRef={ref.current}
      topSlot={<TopToolbarContainer />}
      leftSlot={<TasksLeftPanelContainer leftRef={ref} />}
      rightSlot={<TasksRightPanelContainer />}
    />
  ) : null
}

export default TasksViewerContainer
