import { get } from 'shared/api'
import { ICacher, ISlideCashData } from 'types/ISlideCache'

// Кешеры для платформы и для датасета доступны по разным URL
const fetchCachers = (isAtlas: boolean, workspaceId?: string) => {
  const url = isAtlas ? '/atlas/slide-cachers' : `/workspace/${workspaceId}/slide-cacher`
  return get<ICacher[]>({
    url,
  })
}
const fetchSlideCacheStatus = (url: string, slideId: number) => {
  const res = get<ISlideCashData>({
    url: `${url}/v1/cache/${slideId}`,
  })
  return res
}
const cacheService = {
  fetchCachers,
  fetchSlideCacheStatus,
}

export default cacheService
