import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerPageSlice } from 'pages/viewer'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { VirtualList } from 'shared/ui/infinite-scroll'
import { ButtonElement, SpaceElement, SpinElement, TextElement } from 'shared/ui/kit'
import styled, { css } from 'styled-components/macro'
import { ISelectedBbox, ISimilarSlide } from 'types/ISimilarRegion'

import { SimilarSlidesContext } from './lib/SimilarSlidesProvider'
import SimilarThumbnail from './ui/SimilarThumbnail'

type ItemsProps = {
  clickHandler: (similar: ISimilarSlide, bbox: ISelectedBbox) => void
}

const Wrapper = styled.div`
  margin-bottom: 8px;
`

const disabledCss = css`
  pointer-events: none;
  opacity: 0.5;
`

const Disabled = styled.div<{ isDisabled: boolean }>`
  ${({ isDisabled }) => isDisabled && disabledCss}
  height: 100%;
`

const SearchInfo = styled.div`
  padding-right: 13px;
`
const SearcherInfo = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  gap: 8px;
`

const MENU_HEIGHT = 155

const SimilarSlidesListContainer = ({ clickHandler }: ItemsProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { cancelSearch, isLoading, searcher, similarSlides, totalSearchers } = useContext(SimilarSlidesContext)
  const { activeViewerId } = useOpenViewers()
  const selectedBBoxes = useTypedSelector((state) =>
    Object.fromEntries(
      Object.entries(state.viewers).map(([key, reducer]) => [reducer?.viewer?.selectedBbox?.bboxId, key]),
    ),
  )
  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>, slide: ISelectedBbox) => {
    e.preventDefault()
    const windowHeight = window.innerHeight
    const y = e.clientY > windowHeight - MENU_HEIGHT ? e.clientY - MENU_HEIGHT : e.clientY
    dispatch(viewerPageSlice.actions.setThumbnailMenuPosition([e.clientX, y]))
    dispatch(viewerPageSlice.actions.setSimilarThumbnailHover(slide))
  }

  const bboxes = (
    similarSlides?.flatMap((similar) =>
      similar.bboxes.map((bb) => ({ ...bb, bboxId: `${similar.slideId}-${bb.bboxId}`, similar })),
    ) || []
  ).sort((a, b) => b.score - a.score)
  return similarSlides?.length === 0 && searcher === totalSearchers ? (
    <TextElement>{t('Поиск не дал результатов, попробуйте снова.')}</TextElement>
  ) : (
    <>
      <div style={{ height: '100%' }}>
        {isLoading && (
          <SearchInfo>
            <SearcherInfo>
              <SpinElement />
              <TextElement>{`${t(`Поиск`)}...`}</TextElement>
            </SearcherInfo>
            <ButtonElement block onClick={cancelSearch}>
              {t('Остановить')}
            </ButtonElement>
            <SpaceElement size={16} />
          </SearchInfo>
        )}
        <TextElement>{t('Похожие')}</TextElement>
        <SpaceElement size={8} />
        <Disabled isDisabled={isLoading}>
          <VirtualList
            total={bboxes.length}
            defaultRowHeight={80}
            style={{ paddingRight: '13px' }}
            rowRenderer={({ index }) => {
              const element = bboxes[index]
              return (
                <Wrapper onContextMenu={(e) => handleContextMenu(e, element)}>
                  <SimilarThumbnail
                    activeViewerId={activeViewerId}
                    similar={element.similar}
                    key={element.bboxId}
                    bbox={element}
                    clickHandler={clickHandler}
                    selectedByViewer={selectedBBoxes[element.bboxId]}
                  />
                </Wrapper>
              )
            }}
          />
        </Disabled>
      </div>
    </>
  )
}

export default SimilarSlidesListContainer
