import { Tag, TagProps } from 'antd'
import styled from 'styled-components/macro'

const StyledGroupElemet = styled(Tag)`
  &.ant-tag {
    color: var(--color-text-1);
    background-color: var(--color-bg-3);
  }

  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
    text-align: center;
    color: var(--color-text-3);
    box-shadow: inset 0 0 0 2px var(--color-bg-3);
  }
  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: var(--color-bg-4);
    border-radius: 5px;
    box-shadow: inset 0 0 0 2px var(--color-bg-3);
  }
  .ant-radio-button-wrapper:not(:first-child)::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--color-bg-3);
    width: 5px;
    left: -3px;
  }

  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--color-text-1);
    font-weight: 500;
  }
`

export type RadioGroupElementsProps = TagProps

export const TagElement = (props: RadioGroupElementsProps) => <StyledGroupElemet {...props} />
