type Props = {
  rotate: string
}

const ExpandArrow = (props: Props) => (
  <svg
    transform={props.rotate}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.59997 9.46667C7.79997 9.73333 8.19997 9.73333 8.39997 9.46667L10.4 6.8C10.6472 6.47038 10.412 6 9.99999 6H5.99999C5.58797 6 5.35278 6.47038 5.59999 6.8L7.59997 9.46667Z"
      fill="#99989f"
    />
  </svg>
)

export const ExpandArrowV2 = (props: Props) => (
  <svg
    transform={props.rotate}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7071 11.7071C18.0976 11.3166 18.0976 10.6834 17.7071 10.2929C17.3166 9.90237 16.6834 9.90237 16.2929 10.2929L17.7071 11.7071ZM12 16L11.2929 16.7071C11.6834 17.0976 12.3166 17.0976 12.7071 16.7071L12 16ZM7.70711 10.2929C7.31658 9.90237 6.68342 9.90237 6.29289 10.2929C5.90237 10.6834 5.90237 11.3166 6.29289 11.7071L7.70711 10.2929ZM16.2929 10.2929L11.2929 15.2929L12.7071 16.7071L17.7071 11.7071L16.2929 10.2929ZM12.7071 15.2929L7.70711 10.2929L6.29289 11.7071L11.2929 16.7071L12.7071 15.2929Z"
      fill="#99989F"
    />
  </svg>
)

export default ExpandArrow
