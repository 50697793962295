import { modalBackground } from 'app/styled/GlobalStyles'
import { calculateTotalAnnotationsCount } from 'pages/tasks/help'
import React from 'react'
import { IconElement, ImageElement, RouterLink } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IMarkupSlide } from 'types/IMarkupSlide'

type Props = {
  /** данные слайда */
  slideData: IMarkupSlide
  /** флаг, указывающий на доступ к аннотациям */
  canSeeOtherResult?: boolean
  /** идентификатор пользователя */
  userId?: number
}

const SlidePreview = styled.div`
  width: 200px;
  height: 200px;
  margin-bottom: 7px;
  position: relative;
`

const GreenCheckMark = styled(IconElement)`
  fill: var(--color-green);
  & path {
    fill: white;
  }
`
const BlueCheckMark = styled(IconElement)`
  fill: var(--color-blue);
  & path {
    fill: white;
  }
`

const StatusesWrapper = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`
const StyledImageElement = styled(ImageElement)`
  border-radius: 6px;
`
const LayerCounter = styled.div`
  ${() => modalBackground}
  display: flex;
  border-radius: 5px;
  position: absolute;
  height: 20px;
  bottom: 4px;
  left: 4px;

  padding: 2px 4px 2px 2px;
  align-items: center;
  justify-content: center;
`
const TaskSlideItem = ({ canSeeOtherResult, slideData, userId }: Props) => {
  const results = slideData.results
  const approved = slideData?.stats?.approved
  const done = slideData?.stats?.done
  const layerCount = calculateTotalAnnotationsCount(results, userId, canSeeOtherResult)
  const imgSrc =
    slideData.slide?.iiif2BaseUrl +
    '/full/!128,128/0/default.jpg' +
    (slideData.slide?.iiif2AuthToken ? `?access_token=${slideData.slide?.iiif2AuthToken}` : '')

  return (
    <SlidePreview>
      <StatusesWrapper>
        {[...Array(approved)].map((item, i) => (
          <GreenCheckMark key={i} name="checkmark" />
        ))}
        {[...Array(done)].map((item, i) => (
          <BlueCheckMark key={i} name="checkmark" />
        ))}
      </StatusesWrapper>
      <RouterLink to={`/tasks-viewer/${slideData.markupTaskId}?slideId=${slideData.slideId}`}>
        <StyledImageElement src={imgSrc} />
      </RouterLink>
      <LayerCounter>
        <IconElement size={'md'} name="layers" />
        {layerCount}
      </LayerCounter>
      <div>{slideData?.slide?.caseExternalId || slideData?.slideId || '-'}</div>
    </SlidePreview>
  )
}

export default TaskSlideItem
