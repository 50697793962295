import React from 'react'
import { useTranslation } from 'react-i18next'
import { timeFromNow } from 'shared/lib/date'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'
import { IconElement, TextElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { ICaseRelation } from 'types/ICase'
import { IWorkspace } from 'types/IWorkspaces'

const Title = styled.div`
  grid-area: title;
  padding-right: 8px;
`
const CreatedTime = styled.div`
  grid-area: createdTime;
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
`

const StyledButton = styled(IconElement)`
  display: none;
  background-color: var(--color-bg-3);
  border-radius: 2px;
  cursor: pointer;
  path {
    fill: #b2222200;
  }
  &:active {
    background-color: var(--color-purple);
    path {
      stroke: var(--color-text-1);
    }
  }
  &:hover {
    path {
      stroke: var(--color-text-1);
    }
  }
`

const StyledAlertMessage = styled.div<{ canOpenCase?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-areas: 'icon title' '_ createdTime';
  grid-template-columns: 16px 95%;
  column-gap: 8px;
  padding: 8px;
  border-radius: 5px;

  &:hover {
    cursor: ${({ canOpenCase }) => (canOpenCase ? 'pointer !important' : 'default !important')};
    background-color: var(--color-bg-3);
    .click-read {
      display: block;
    }
  }
`
const StyledLink = styled.a<{ canOpenCase?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-areas: 'icon title' '_ createdTime';
  grid-template-columns: 16px 95%;
  column-gap: 8px;
  color: var(--color-text-1);
  padding: 8px;
  border-radius: 5px;

  &:hover {
    cursor: ${({ canOpenCase }) => (canOpenCase ? 'pointer !important' : 'default !important')};
    color: var(--color-text-1);
    background-color: ${({ canOpenCase }) => (canOpenCase ? 'var(--color-bg-3)' : 'var(--color-bg-1)')};
    .click-read {
      display: block;
    }
  }
`

const InfoText = styled(TextElement)`
  overflow-wrap: normal;
`

const Shape = styled.div<{ isRead?: boolean }>`
  height: 16px;
  width: 16px;
  grid-area: icon;
  border-radius: 50%;
  color: ${({ isRead }) => (isRead ? 'var(--color-text-1)' : 'var(--color-white)')};
  background-color: ${({ isRead }) => (isRead ? 'var(--color-bg-4)' : 'var(--color-purple)')};
`
type Props = {
  userName: string
  caseId: number
  caseName: string
  isRead?: boolean
  notificationId: number
  createdAt: string
  onRead: (notificationId: number) => void
  onClick: (caseId: number, sharedWorkspaceId?: number, link?: string, canAcquirePermission?: boolean) => void
  isHover: boolean
  handleMouseEnter: () => void
  handleMouseLeave: () => void
  token?: string
  workspaceId?: number
  canOpenCase?: boolean
  canAcquirePermission?: boolean
  caseRelation?: ICaseRelation
  workspaces?: IWorkspace[]
  srcWorkspaceName?: string
  srcWorkspaceId?: number
}

export const NewCommentMessage = ({
  canOpenCase,
  caseId,
  caseName,
  caseRelation,
  createdAt,
  handleMouseEnter,
  handleMouseLeave,
  isHover,
  isRead,
  notificationId,
  onClick,
  onRead,
  userName,
  workspaceId,
  workspaces,
}: Props) => {
  const { i18n, t } = useTranslation()
  const newWorkspace = workspaces?.find((item) => +item.workspaceId === workspaceId)
  const disable = caseRelation === ICaseRelation.OWNER && canOpenCase && !newWorkspace

  const handleRead = () => onRead(notificationId)

  return (
    <StyledAlertMessage
      onClick={() => !isHover && canOpenCase && !disable && onClick(caseId, workspaceId)}
      canOpenCase={canOpenCase && !disable}
    >
      <Shape isRead={isRead}>
        <IconElement name="commentSmall" size="md" />
      </Shape>
      <Title>
        <TextElement strong>{userName}&nbsp;</TextElement>
        <InfoText>
          <TextElement>
            {t('оставил(а) комментарий')} {t('к случаю')} {caseName}
          </TextElement>
        </InfoText>
      </Title>
      <CreatedTime>
        <TextElement type="secondary">
          {timeFromNow(createdAt, true, i18n.language !== I18N_LANGUAGES.RUSSIAN)}
        </TextElement>
        {!isRead && (
          <TooltipElement title={t('Отметить как прочитанное')}>
            <div onBlur={handleMouseLeave} onMouseEnter={handleMouseEnter}>
              <StyledButton onClick={handleRead} className="click-read" name="checkSmall" size="md" />
            </div>
          </TooltipElement>
        )}
      </CreatedTime>
    </StyledAlertMessage>
  )
}

export const NewSharedCaseMessage = ({
  canOpenCase,
  caseId,
  caseName,
  createdAt,
  handleMouseEnter,
  handleMouseLeave,
  isHover,
  isRead,
  notificationId,
  onClick,
  onRead,
  srcWorkspaceId,
  srcWorkspaceName,
  userName,
  workspaceId,
}: Props) => {
  const { i18n, t } = useTranslation()
  const handleRead = () => onRead(notificationId)

  return (
    <StyledAlertMessage
      onClick={() => !isHover && canOpenCase && onClick(caseId, workspaceId)}
      canOpenCase={canOpenCase}
    >
      <Shape isRead={isRead}>
        <IconElement name="shareSmall" size="md" />
      </Shape>
      <Title>
        <TextElement strong>
          {userName}
          {srcWorkspaceName && srcWorkspaceId !== workspaceId && ` (${srcWorkspaceName})`}
          &nbsp;
        </TextElement>
        <InfoText>
          <TextElement>
            {t('поделился(-лась) с вами случаем')} {caseName}
          </TextElement>
        </InfoText>
      </Title>
      <CreatedTime>
        <TextElement type="secondary">
          {timeFromNow(createdAt, true, i18n.language !== I18N_LANGUAGES.RUSSIAN)}
        </TextElement>
        {!isRead && (
          <TooltipElement title={t('Отметить как прочитанное')}>
            <div onBlur={handleMouseLeave} onMouseEnter={handleMouseEnter}>
              <StyledButton onClick={handleRead} className="click-read" name="checkSmall" size="md" />
            </div>
          </TooltipElement>
        )}
      </CreatedTime>
    </StyledAlertMessage>
  )
}

export const NewSharedCaseLinkMessage = ({
  canAcquirePermission,
  canOpenCase,
  caseId,
  caseName,
  createdAt,
  handleMouseEnter,
  handleMouseLeave,
  isHover,
  isRead,
  notificationId,
  onClick,
  onRead,
  srcWorkspaceId,
  srcWorkspaceName,
  token,
  userName,
  workspaceId,
}: Props) => {
  const { i18n, t } = useTranslation()
  const handleRead = () => onRead(notificationId)
  return (
    <StyledLink
      href={canOpenCase && canAcquirePermission ? `/invite/${token}` : undefined}
      target={canOpenCase && canAcquirePermission ? '_blank' : '_self'}
      onClick={() => !isHover && canOpenCase && onClick(caseId, workspaceId, token, canAcquirePermission)}
      canOpenCase={canOpenCase}
    >
      <Shape isRead={isRead}>
        <IconElement name="shareSmall" size="md" />
      </Shape>
      <Title>
        <TextElement strong>
          {userName}
          {srcWorkspaceName && srcWorkspaceId !== workspaceId && ` (${srcWorkspaceName})`}
          &nbsp;
        </TextElement>

        <InfoText>
          <TextElement>
            {t('поделился(-лась) с вами случаем')} {caseName}
          </TextElement>
        </InfoText>
      </Title>
      <CreatedTime>
        <TextElement type="secondary">
          {timeFromNow(createdAt, true, i18n.language !== I18N_LANGUAGES.RUSSIAN)}
        </TextElement>
        {!isRead && (
          <TooltipElement title={t('Отметить как прочитанное')}>
            <div onBlur={handleMouseLeave} onMouseEnter={handleMouseEnter}>
              <StyledButton onClick={handleRead} className="click-read" name="checkSmall" size="md" />
            </div>
          </TooltipElement>
        )}
      </CreatedTime>
    </StyledLink>
  )
}

export const NewReportMessage = ({
  canOpenCase,
  caseId,
  caseName,
  createdAt,
  handleMouseEnter,
  handleMouseLeave,
  isHover,
  isRead,
  notificationId,
  onClick,
  onRead,
  userName,
  workspaceId,
}: Props) => {
  const { i18n, t } = useTranslation()
  const handleRead = () => onRead(notificationId)

  return (
    <StyledAlertMessage
      onClick={() => !isHover && canOpenCase && onClick(caseId, workspaceId)}
      canOpenCase={canOpenCase}
    >
      <Shape isRead={isRead}>
        <IconElement name="commentSmall" size="md" />
      </Shape>
      <Title>
        <TextElement strong>{userName}&nbsp;</TextElement>
        <InfoText>
          <TextElement>
            {t('создал(-а) новое заключение')} {t('к случаю')} {caseName}
          </TextElement>
        </InfoText>
      </Title>
      <CreatedTime>
        <TextElement type="secondary">
          {timeFromNow(createdAt, true, i18n.language !== I18N_LANGUAGES.RUSSIAN)}
        </TextElement>
        {!isRead && (
          <TooltipElement title={t('Отметить как прочитанное')} color="var(--color-purple)">
            <div onBlur={handleMouseLeave} onMouseEnter={handleMouseEnter}>
              <StyledButton onClick={handleRead} className="click-read" name="checkSmall" size="md" />
            </div>
          </TooltipElement>
        )}
      </CreatedTime>
    </StyledAlertMessage>
  )
}
