enum DashboardNavigationType {
  New = 'new',
  AddExam = 'addExam',
  Comment = 'comment',
  MDT = 'mdt',
  Pause = 'pause',
  Favorites = 'favorites',

  CT = 'ct',
  MRT = 'mrt',
  UZI = 'uzi',
  Radio = 'radio',
  Mammology = 'mammology',
  Macro = 'macro',
  Micro = 'micro',
  Pathology = 'pathology',
  Surg = 'surg',
  Gen = 'gen',

  Stats = 'stats',
  History = 'history',
  Settings = 'settings',
}

export default DashboardNavigationType
