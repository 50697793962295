import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  margin: 4px 8px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export type OnCaseRemoveNotificationProps = {
  id?: number
  name: string
  onRestore: (caseId: number) => Promise<void>
  restoreDisabled?: boolean
}

const OnCaseRemoveNotification = ({ id, name, onRestore, restoreDisabled }: OnCaseRemoveNotificationProps) => {
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()
  const onRestoreClick = useCallback(async () => {
    try {
      setLoading(true)
      id && !restoreDisabled && !isLoading && (await onRestore(id))
    } catch (e) {
      setLoading(false)
      throw e
    }
  }, [id, restoreDisabled, isLoading])

  return (
    <Wrapper data-testid={'case-restore-block'}>
      <TextWrapper>
        <TitleElement level={4} ellipsis>
          {t('Случай')} {name}
        </TitleElement>
        <TitleElement level={4} style={{ margin: 0 }}>
          {t('удален')}
        </TitleElement>
        <LinkElement onClick={onRestoreClick} disabled={restoreDisabled || isLoading}>
          {t('Восстановить')}
        </LinkElement>
      </TextWrapper>
    </Wrapper>
  )
}

export default OnCaseRemoveNotification
