import { Dropdown, Menu, Row, Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { push } from 'connected-react-router'
import { clearDictionaries } from 'features/dictionaries/lib/handlers'
import { logout, userService } from 'processes/user'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'
import { AvatarElement, IconElement, TitleElement } from 'shared/ui/kit'
import { ThemeSwitcher } from 'shared/ui/kit/ui/ThemeSwitcher'
import styled from 'styled-components/macro'
import IUser from 'types/IUser'
import MarkingItemModal from 'widgets/markingModal/ui/MarkingModalMenuItem'

import UserWorkspaces from './UserWorkspaces'

const UserName = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 300px;
`

const StyledMenu = styled(Menu)`
  ${() => modalBackground}
  ${() => modalShadow}

  top: 6px;
  width: 190px;

  .ant-dropdown-menu-item {
    box-sizing: border-box;

    color: var(--color-text-1);
    padding: 0px;
    line-height: 16px;

    border: none;
    background-color: transparent;

    :hover {
      color: var(--color-text-1);
      background-color: var(--color-bg-3);
    }
  }

  .ant-dropdown-menu-submenu-title {
    padding: 0;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;

    :hover {
      background-color: transparent;
    }
  }

  .ant-dropdown-menu-item-active {
    color: var(--color-text-1);

    :hover {
      background-color: var(--color-bg-3);
    }
  }

  .ant-dropdown-menu-submenu-expand-icon {
    top: 12px;
  }
`

type Props = {
  menuItems: {
    id: string
    title: string
    onClick: () => void
  }[]
  user: IUser
  noInteract?: boolean
}

const LanguageLabel = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  align-items: center;
  width: 100%;
`

export const Text = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`

const ColoredText = styled.span`
  color: var(--color-text-3);
`

export const MenuIconItem = styled.div`
  display: flex;
  gap: 4px;
  height: 100%;
  padding: 0 8px 0 17px;
`

export const StyledMenuItem = styled(Menu.Item)`
  height: 32px;
  padding: 9px 8px 9px 17px;
`

export const MenuSubItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 8px 8px 16px;

  &:hover {
    background-color: var(--color-bg-3);
  }
`

const MenuItemWrapper = styled.li`
  padding: 4px 0;
  border-bottom: 1px solid var(--color-bg-3);

  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`

const StyledDropdown = styled(Dropdown)`
  top: 40px;
`

export const PlaceKeeper = styled.div`
  height: 16px;
  width: 16px;
`

export const StyledSubMenu = styled(Menu.SubMenu)`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 4px 0;
  border-bottom: 1px solid var(--color-bg-3);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-dropdown-menu-submenu-title {
    margin: 0;
    height: 32px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--color-bg-3);
    }
  }
`

const UserOptions = ({ menuItems, noInteract, user }: Props) => {
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()
  const userId = useTypedSelector((state) => state.user.user?.userId)
  const manualOpen = {
    href: 'https://notion.onecell.ru/OneCell-e283d1b0c7aa4f42978526d701da86cb ',
    rel: 'noreferrer',
    target: '_blank',
  }

  /** Функция смены языка */
  const changeLanguage = async (lang: I18N_LANGUAGES) => {
    userId && (await userService.changeUserInfo(userId, { preferredLanguage: lang }))
    await i18n.changeLanguage(lang)
  }

  const menu = (
    <StyledMenu expandIcon={<IconElement name="sectionIsClose" fill={'var(--color-text-3)'} size="md" />}>
      <MenuItemWrapper>
        <StyledMenuItem key="themeItem">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '9px 8px 9px 17px',
            }}
          >
            <Text>{t('Тема оформления')}</Text>
            <ThemeSwitcher />
          </div>
        </StyledMenuItem>
      </MenuItemWrapper>
      <StyledSubMenu
        key="langItem"
        title={
          <LanguageLabel>
            <MenuIconItem>
              <IconElement name="languageSmall" fill={'var(--color-text-3)'} size="md" />
              <Text>{t('Язык')}</Text>
            </MenuIconItem>
            <ColoredText>
              <Text>{i18n.language === I18N_LANGUAGES.RUSSIAN ? 'Русский' : 'English'}</Text>
            </ColoredText>
          </LanguageLabel>
        }
      >
        <Menu.Item key="sub_ru" style={{ margin: 0, padding: 0 }}>
          <MenuSubItemWrapper onClick={() => changeLanguage(I18N_LANGUAGES.RUSSIAN)}>
            <Text>Русский</Text>
            {i18n.language === I18N_LANGUAGES.RUSSIAN ? <IconElement name="checkSmall2" size="md" /> : <PlaceKeeper />}
          </MenuSubItemWrapper>
        </Menu.Item>
        <Menu.Item key="sub_eng" style={{ margin: 0, padding: 0 }}>
          <MenuSubItemWrapper onClick={() => changeLanguage(I18N_LANGUAGES.ENGLISH)}>
            <Text>English</Text>
            {i18n.language === I18N_LANGUAGES.ENGLISH ? <IconElement name="checkSmall2" size="md" /> : <PlaceKeeper />}
          </MenuSubItemWrapper>
        </Menu.Item>
      </StyledSubMenu>
      <MenuItemWrapper>
        <StyledMenuItem key="guide">
          <a {...manualOpen} style={{ color: 'inherit', textDecoration: 'none' }}>
            <MenuIconItem style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                <IconElement name="guide" fill={'var(--color-text-3)'} size={'auto'} />
                <Text>{t('Руководство')}</Text>
              </div>
              <IconElement name="linkSmall" fill={'var(--color-text-3)'} style={{ height: '10px', width: '10px' }} />
            </MenuIconItem>
          </a>
        </StyledMenuItem>
        {i18n.language === I18N_LANGUAGES.RUSSIAN && <MarkingItemModal />}
      </MenuItemWrapper>
      <UserWorkspaces />
      <MenuItemWrapper>
        <StyledMenuItem
          key="logoutItem"
          onClick={() => {
            try {
              dispatch(logout())
              dispatch(push('/login'))
              clearDictionaries()
            } catch (e) {
              dispatch(push('/login'))
              console.log(e)
            }
          }}
        >
          <MenuIconItem data-testid={'logout'} style={{ paddingBottom: 8, paddingTop: 6 }}>
            <IconElement name="logoutSmall" fill={'var(--color-text-3)'} size="md" />
            <Text>{t('Выйти')}</Text>
          </MenuIconItem>
        </StyledMenuItem>
      </MenuItemWrapper>
    </StyledMenu>
  )
  return (
    <Row data-testId={'user-name-row'}>
      <Space size={8} direction="horizontal">
        <AvatarElement numberValue={user?.userId} fullname={user?.fullname} noInteract={noInteract} />
        <StyledDropdown overlay={menu} trigger={noInteract ? [] : ['click']} placement="bottomRight">
          <UserName onClick={(e) => e.preventDefault()}>
            <TitleElement
              ellipsis
              tooltipPlacement={'bottom'}
              level={4}
              data-testid={'user-name-label'}
              style={{ maxWidth: menuItems.length > 0 ? '200px' : '130px', transition: 'none' }}
            >
              {user?.fullname}
            </TitleElement>
            {menuItems.length > 0 && <IconElement name="dropDown" size="md" fill="var(--color-text-1)" />}
          </UserName>
        </StyledDropdown>
      </Space>
    </Row>
  )
}

export default UserOptions
