import { useTypedSelector } from 'app/redux/lib/selector'
import { SlideMapViewKey } from 'pages/viewer/model/slideMapViewSlice'
import TViewerId from 'types/TViewerId'

import useViewerDispatch from './useViewerDispatch'

//TODO: create optimized selectors
export const useViewerRootSelector = (id: TViewerId) => useTypedSelector((state) => state.viewers[id])

export const useViewerHelpSelector = (id: TViewerId) => useTypedSelector((state) => state.viewers[id].viewerHelp)

export const useViewerAttachmentsSelector = (id: TViewerId) =>
  useTypedSelector((state) => state.viewers[id].viewerAttachments)

export const useViewerMainSelector = (id: TViewerId) => useTypedSelector((state) => state.viewers[id].viewer)

export const useViewerToolsSelector = (id: TViewerId) =>
  useTypedSelector((state) => state.viewers[id].viewerToolsGridSlice)

export const useSlideMapViewSelector = (key: SlideMapViewKey) =>
  useTypedSelector((state) => state.slideMapView[`${key.slideId}-${key.viewerId}`] || {})

export const useViewerCompleteSelector = (id: TViewerId) => {
  const { viewer, viewerAttachments, viewerHelp } = useTypedSelector((state) => state.viewers[id])
  return {
    ...viewer,
    ...viewerAttachments,
    ...viewerHelp,
  }
}

export { useViewerDispatch }
