import { MenuIconItem, StyledMenuItem, Text } from 'features/user-options/ui/UserOptions'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'

import MarkingBody from './MarkingBody'
import { StyledModalProvider } from './MarkingModal'

const MarkingItemModal = () => {
  const markingModal = useRef<any>(null)
  const onOpen = () => markingModal.current?.open()
  const onClose = () => markingModal.current?.close()
  const { t } = useTranslation()

  return (
    <>
      <StyledMenuItem data-testid={'marking-menu-item'} key="marking" onClick={onOpen}>
        <MenuIconItem>
          <IconElement name="markingSmall" fill={'var(--color-text-3)'} size={'auto'} />
          <Text>{t('Маркировка')}</Text>
        </MenuIconItem>
      </StyledMenuItem>
      <StyledModalProvider
        ref={markingModal}
        title={t('Маркировка')}
        onCancel={onClose}
        width={'fit-content'}
        footer={null}
        destroyOnClose
        centered
      >
        <MarkingBody />
      </StyledModalProvider>
    </>
  )
}

export default MarkingItemModal
