import { SelectElement } from 'shared/ui/kit'
import { IMarkupClass } from 'types/IMarkupSlide'

export enum AnnotationClassesEnum {
  Default = 'Без класса',
  Tissue = 'Tissue',
  Background = 'Background',
  Tumor = 'Tumor',
  Stroma = 'Stroma',
  Mitosis = 'Mitosis',
}

export const defaultAnnotationClass = AnnotationClassesEnum.Default

export const platformAnnotationClassRgb = '114, 133, 233'

export const AnnotationClasses = [
  {
    name: AnnotationClassesEnum.Default,
    rgb: '64, 153, 247',
  },
  {
    name: AnnotationClassesEnum.Tissue,
    rgb: '220, 82, 76',
  },
  {
    name: AnnotationClassesEnum.Background,
    rgb: '82, 168, 153',
  },
  {
    name: AnnotationClassesEnum.Tumor,
    rgb: '123, 184, 111',
  },
  {
    name: AnnotationClassesEnum.Stroma,
    rgb: '248, 205, 118',
  },
  {
    name: AnnotationClassesEnum.Mitosis,
    rgb: '1, 60, 212',
  },
]

type Props = {
  /** Колбэк выбора класса аннотации */
  onSelect: (selectedClass: AnnotationClassesEnum) => void
  /** Название выбранного класса */
  value: string
  /** Может ли пользователь менять класс аннотации */
  enable?: boolean
  /** Классы аннотаций в таске */
  classes?: IMarkupClass[]
}

export const AnnotationClassSelect = ({ classes, enable, onSelect, value }: Props) => (
  <SelectElement
    disabled={!enable}
    style={{ minWidth: '100%' }}
    onClick={(e) => e.stopPropagation()}
    defaultValue={defaultAnnotationClass}
    options={((classes && classes.length ? classes : AnnotationClasses) as IMarkupClass[]).map((c: IMarkupClass) => ({
      label: c.name,
      value: c.name,
    }))}
    value={value}
    onSelect={(value: AnnotationClassesEnum) => {
      onSelect(value)
    }}
  />
)
