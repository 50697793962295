import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import Polygon from 'ol/geom/Polygon'
import { viewerPageSlice } from 'pages/viewer'
import { createContext, useContext, useEffect, useState } from 'react'
import { DataLayerTypes } from 'types/ISlideDataLayer'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch } from 'viewer/container'
import { useCurrentDataLayer, useSlideDataLayers } from 'viewer/tools/api/query'

/** Контекст Детекции артефактов  */
type IPathVisionSegmentation = {
  /** Массив features из GeoJSON-а dataLayer ARTEFACTS */
  features: Feature<Polygon>[]
  /** Уровень прозрачность полигонов на карте */
  opacity: number
  /** Коллбэк для установки уровня прозрачности полигонов на карте */
  setOpacity: (val: number) => void
  /** Класс подсвечиваемой фичи */
  hoveredClass?: string
  /** Коллбэк для установки класса подсвечиваемой фичи */
  setHoveredClass: (val?: string) => void
}

/** Начальный уровень прозрачности полигонов */
const DEFAULT_OPACITY = 50

const ArtefactsContext = createContext<IPathVisionSegmentation>({
  features: [],
  opacity: DEFAULT_OPACITY,
  setHoveredClass: () => {},
  setOpacity: () => {},
})

export const useArtefactsContext = () => useContext(ArtefactsContext)

/** Props for ArtefactsProvider */
type Props = {
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
  /** Идентификатор текущего случая */
  caseId: number
  /** Идентификатор текущего слайда */
  slideId: number
  /** Флаг, отслеживающий видимость панели */
  isVisible: boolean
}

export const ArtefactsProvider: React.FC<Props> = ({ caseId, children, isVisible, slideId, viewerId }) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const [features, setFeatures] = useState<Feature<Polygon>[]>([])
  const [opacity, setOpacity] = useState(DEFAULT_OPACITY)
  const [hoveredClass, setHoveredClass] = useState<string | undefined>()

  const { data: dataLayers } = useSlideDataLayers({ caseId, slideId })
  const artefactsDataLayer = dataLayers?.find((dataLayer) => isVisible && dataLayer.type === DataLayerTypes.ARTEFACTS)

  const { data: artefactsGeoJSON } = useCurrentDataLayer({
    caseId,
    slideDataLayerId: artefactsDataLayer?.slideDataLayerId,
    slideId,
  })
  useEffect(() => {
    viewerDispatch(viewerPageSlice.actions.setTool({ tool: 'ARTEFACTS', value: false }))
  }, [slideId])

  useEffect(() => {
    if (artefactsGeoJSON) {
      const features = new GeoJSON().readFeatures(artefactsGeoJSON)
      setFeatures(features)
    }
  }, [artefactsGeoJSON])

  return (
    <ArtefactsContext.Provider value={{ features, hoveredClass, opacity, setHoveredClass, setOpacity }}>
      {children}
    </ArtefactsContext.Provider>
  )
}
