import React, { useState } from 'react'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import ArtefactsContainer from './ArtefactsContainer'

/** Props for ArtefactsToolPanel */
type Props = {
  /** Ссылка на DOM-элемент панели Детекция артефактов */
  artefactsRef: React.RefObject<HTMLDivElement>
  /** Координаты начального расположения панели */
  initPosition: number[]
  /** Коллбэк для установки координат расположения панели */
  onPositionChange: (position: number[]) => void
}

const ArtefactsToolPanel = ({ artefactsRef, initPosition, onPositionChange }: Props) => {
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  return (
    <ViewerInfoToolPanelContainer
      id={'ARTEFACTS'}
      panelRef={artefactsRef}
      type={'ARTEFACTS'}
      data-testid="aftefacts_menu-in-ai"
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      position={position}
      setPosition={setPosition}
    >
      <ArtefactsContainer />
    </ViewerInfoToolPanelContainer>
  )
}

export default ArtefactsToolPanel
