import React, { useState } from 'react'
import TViewerId from 'types/TViewerId'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import ValidationButtonsContainer from './ValidationButtonsContainer'

type Props = {
  /** Ссылка на элемент*/
  validationRef: React.RefObject<HTMLDivElement>
  /** Id вьювера */
  viewerId: TViewerId
  /** Обработка смены координат окна */
  onPositionChange: (position: number[]) => void
  /** Начальные координаты окна */
  initPosition: number[]
}

const AtlasValidationToolPanel = ({ initPosition, onPositionChange, validationRef, viewerId }: Props) => {
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  return (
    <ViewerInfoToolPanelContainer
      id="VALIDATION"
      panelRef={validationRef}
      type={'VALIDATION'}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      position={position}
      setPosition={setPosition}
    >
      <ValidationButtonsContainer viewerId={viewerId} />
    </ViewerInfoToolPanelContainer>
  )
}

export default AtlasValidationToolPanel
