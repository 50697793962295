import { Space } from 'antd'
import { push } from 'connected-react-router'
import { FormButton, FormButtonElement, FormTopTitle, FormWrapper } from 'features/form'
import { MobileLandscape } from 'features/mobile-control'
import { isChatAccess, SupportChat } from 'features/support-chat'
import { fetchUserData } from 'processes/user'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAvailableMenuTabs } from 'shared/lib/workspaces'
import { PageHeader } from 'shared/ui/header'
import { SpaceElement, TitleElement } from 'shared/ui/kit'
import { LanguageDropdown } from 'shared/ui/kit/ui/LanguageDropdown'
import styled from 'styled-components/macro'
import { MarkingButtonModal } from 'widgets'

import { LoginForm } from './ui'

const StyledLogin = styled(MobileLandscape)`
  background-color: var(--color-bg-1);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const StyledChatContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 8px;
  width: fit-content;
`

const LoginFormWrapper = styled(FormWrapper)`
  margin-top: 8%;
`

const LanguageSelectWrapper = styled.div`
  position: absolute;
  right: 12px;
  bottom: 12px;
`

export const LoginRoute = () => {
  const { PASSWORD_RECOVERY_ENABLED, REGISTRATION_ENABLED } = global.window
  const dispatch = useDispatch()
  const location = useLocation<{ referrer?: Location }>()
  const visibleTabs = useAvailableMenuTabs()

  const onLogin = async (payload: { login: string; password: string; captchaToken?: string }) => {
    await dispatch(fetchUserData(payload))
    if (location.state?.referrer) {
      const redirect = location.state?.referrer?.pathname + location.state?.referrer?.search
      dispatch(push(redirect || `/management/${visibleTabs[0]}`))
    }
  }
  const goToRegistration = useCallback(() => {
    dispatch(push('/registration'))
  }, [])

  const goToReset = useCallback(() => {
    dispatch(push('/request-reset'))
  }, [])

  const { t } = useTranslation()

  return (
    <StyledLogin>
      <PageHeader>
        {REGISTRATION_ENABLED && (
          <Space size={16}>
            <FormTopTitle level={4}>{t('Нет аккаунта?')}</FormTopTitle>
            <FormButton data-testid="go-to-registration-btn" onClick={goToRegistration}>
              {t('Зарегистрироваться')}
            </FormButton>
          </Space>
        )}
      </PageHeader>
      <LoginFormWrapper>
        <TitleElement level={1}>{t('Вход')}</TitleElement>
        <SpaceElement size={40} />
        <LoginForm
          isActiveDirectoryConfigured={!REGISTRATION_ENABLED && !PASSWORD_RECOVERY_ENABLED}
          onSubmit={onLogin}
        />
        {PASSWORD_RECOVERY_ENABLED && (
          <div>
            <FormButtonElement data-testid={'password-recovery-btn'} type="link" onClick={goToReset}>
              {t('Восстановить пароль')}
            </FormButtonElement>
          </div>
        )}
      </LoginFormWrapper>
      <StyledChatContainer>
        <MarkingButtonModal />
        {isChatAccess() && <SupportChat />}
      </StyledChatContainer>
      <LanguageSelectWrapper data-testid={'language-select-login'}>
        <LanguageDropdown />
      </LanguageSelectWrapper>
    </StyledLogin>
  )
}
