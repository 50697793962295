import { Table, TableProps } from 'antd'
import styled from 'styled-components/macro'

const { Column, ColumnGroup } = Table
export { Column }

type Props<RecordType> = TableProps<RecordType>

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: var(--color-text-1);
    width: 100%;

    background: var(--color-bg-3);
    border-bottom: 0px solid var(--color-border-1);
  }

  .ant-table-tbody > tr > td {
    color: var(--color-text-1);
    background: var(--color-bg-2);
    border-bottom: 1px solid var(--color-border-1);
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: var(--color-bg-4);
  }

  .ant-pagination-total-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--color-bg-4);
  }

  a.ant-typography {
    color: #4099f7;
  }

  @media (max-width: 1110px) {
    .ant-table-tbody > tr > td {
      padding: 1%;
    }

    .ant-table-thead > tr > th {
      padding: 1%;
    }
  }
`

function TableComponent<RecordType extends Record<string, unknown> = any>({ ...props }: TableProps<any>) {
  return <StyledTable {...props} />
}

export default TableComponent
