import React from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IAnnotation } from 'types/IAnnotations'

import AnnotationByGroupListItem from './AnnotationByGroupListItem'

type Props = {
  groupName: string
  annotationsIds: number[]
  icon: React.ReactNode
  setAnnotationsIsVisibleByClass: (name: string) => void
  isVisible: boolean
  disableTotalArea?: boolean
}

const AnnotationByGroupListItemContainer = ({
  annotationsIds,
  disableTotalArea = false,
  groupName,
  icon,
  isVisible,
  setAnnotationsIsVisibleByClass,
}: Props) => {
  const queryClient = useQueryClient()

  const annotations =
    (annotationsIds
      ?.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
      .filter((item) => item?.data) as IAnnotation[]) || []

  const totalArea = annotations.reduce((acc, annotation) => acc + annotation.metric, 0)

  return (
    <AnnotationByGroupListItem
      groupName={groupName}
      numberOfAnnotations={annotations.length}
      totalArea={disableTotalArea ? 0 : totalArea}
      icon={icon}
      setAnnotationsIsVisibleByClass={setAnnotationsIsVisibleByClass}
      isVisible={isVisible}
    />
  )
}

export default AnnotationByGroupListItemContainer
