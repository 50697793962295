import { Avatar, Image, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'shared/lib/hooks'
import { COLON_WITH_SPACE } from 'shared/text-constants'
import { CheckboxElement, ImageElement, InputElement, Scroll, SpaceElement, TitleElement } from 'shared/ui/kit'
import { SelectElement } from 'shared/ui/kit/ui/SelectElement'
import { TagElement } from 'shared/ui/kit/ui/TagElement'
import styled from 'styled-components'

import chart from './chart.png'
import { allPeoples, avatars } from './data'

const StyledContent = styled(Content)`
  background: var(--color-bg-2);
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`

const PeopleInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

const PeopleData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledInputElement = styled(InputElement)`
  width: 328px;
`

const StyledTimeInputElement = styled(InputElement)`
  width: 54px;
`

const StyledText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
const Tagss = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledCheckboxElement = styled(CheckboxElement)`
  margin-left: 0px;
`

const DaysOfWeekContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

const StyledDay = styled.div<{ gray?: boolean }>`
  width: 40px;
  height: 40px;
  background: #4e63d2;
  background: ${({ gray }) => (gray ? 'var(--color-bg-3)' : '#4e63d2')};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledImageElement = styled(ImageElement)`
  height: inherit;
  background-color: transparent;
  min-width: 430px;
  width: 430px;
`

const StyledAvatar = styled(Avatar)`
  min-width: 100px;
`

const ChartContainer = styled.div`
  position: relative;
`

const LoadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const MedianBlock = styled.div`
  position: absolute;
  left: -100px;
  top: 30px;
`

export const PeopleCard = () => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const daysOfWeek = [t('Пн'), t('Вт'), t('Ср'), t('Чт'), t('Пт'), t('Сб'), t('Вс')]
  const [hidedDays, setHidedDays] = useState(['Сб', 'Вс'])
  const qName = queryParams.get('p') || ''
  const name = allPeoples.find((p) => p.id === qName)?.name
  const handleDayClick = (day: string) => {
    if (hidedDays.includes(day)) {
      setHidedDays(hidedDays.filter((d) => d !== day))
    } else {
      setHidedDays([...hidedDays, day])
    }
  }

  return (
    <Scroll>
      <Layout style={{ height: '100%' }}>
        <StyledContent>
          <PeopleInfo>
            <StyledAvatar
              size={100}
              icon={<Image src={avatars.find((a) => a.name === qName)?.src} preview={false} width={100} height={100} />}
            />
            <PeopleData>
              <StyledInputElement value={name} />
              <Tagss>
                <TagElement closable>{t('Щитовидная железа')}</TagElement>
                <TagElement closable>{t('Молочная железа')}</TagElement>
                <TagElement>+</TagElement>
              </Tagss>
              <StyledCheckboxElement defaultChecked>
                <StyledText>{t('Доступен для консультаций')}</StyledText>
              </StyledCheckboxElement>
              <StyledCheckboxElement defaultChecked>
                <StyledText>{t('Доступен для контроля качества')}</StyledText>
              </StyledCheckboxElement>

              <SpaceElement size={20} />
              <div>
                <TitleElement level={2}>{t('График работы')}</TitleElement>
              </div>
              <DaysOfWeekContainer>
                {daysOfWeek.map((d, i) => (
                  <StyledDay key={i} gray={hidedDays.includes(d)} onClick={() => handleDayClick(d)}>
                    {d}
                  </StyledDay>
                ))}
              </DaysOfWeekContainer>
              <StyledCheckboxElement defaultChecked>
                <StyledText>{t('Одинаковое время каждый день')}</StyledText>
              </StyledCheckboxElement>
              <span>
                <StyledTimeInputElement value={'9:00'} />
                &nbsp;—&nbsp;
                <StyledTimeInputElement value={'18:00'} />
              </span>

              <SpaceElement size={20} />
              <LoadContainer>
                <div>
                  <TitleElement level={2}>{t('Загрузка')}</TitleElement>
                </div>
                <SelectElement
                  options={[{ label: t('Сентябрь') + ', 2021', value: 'TEST' }]}
                  value={t('Сентябрь') + ', 2021'}
                />
              </LoadContainer>
              <ChartContainer>
                <MedianBlock>{t('Сред') + COLON_WITH_SPACE + t('20 случаев')}</MedianBlock>
                <StyledImageElement src={chart} />
              </ChartContainer>
            </PeopleData>
          </PeopleInfo>
        </StyledContent>
      </Layout>
    </Scroll>
  )
}
