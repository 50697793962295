import { memo, useState } from 'react'
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component'
import { SkeletonImageElement } from 'shared/ui/kit/ui/SkeletonImageElement'
import styled from 'styled-components/macro'

import { FailedImageElement } from './FailedImageElement'

const StyledThumbnail = styled(LazyLoadImage)`
  height: 100%;
  width: 100%;
  background-color: var(--color-bg-3);
  object-fit: contain;
  border-radius: 3px;
`

const ThumbnailSkeleton = styled(SkeletonImageElement)<{ className?: string }>`
  border-radius: 3px;
  background-color: var(--color-bg-3);
  svg {
    height: ${({ className }) => className === 'panel-preview' && '80px'};
  }
`

const ThumbnailFailed = styled(FailedImageElement)`
  border-radius: 3px;
  background-color: var(--color-bg-3);
`

type Props = {
  className?: string
} & Partial<LazyLoadImageProps>

export const ImageElement = memo((props: Props) => {
  const [isError, setError] = useState(false)
  return props.src === 'FAILED_SLIDE' ? (
    <ThumbnailFailed className={props.className} />
  ) : isError || !props.src ? (
    <ThumbnailSkeleton className={props.className} />
  ) : (
    <StyledThumbnail onError={() => setError(true)} {...(props as any)} />
  )
})
