import { useTypedSelector } from 'app/redux/lib/selector'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import AnnotationsTypePanelContainer from 'features/annotations/ui/AnnotationsTypePanelContainer'
import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import TViewerId from 'types/TViewerId'
import { useSlideGroupType } from 'viewer/container/model/viewerSlice'
import { useKeyUp } from 'viewer/map/layers/annotations/lib/hooks/useKeyUp'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

const StyledContextMenu = styled.div`
  position: fixed;
  border-radius: 5px;
  z-index: 25;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  ${() => modalBackground}
  ${() => modalShadow}
`

type Props = {
  viewerId: TViewerId
  openContext: { x: number; y: number } | null
  setOpenContext: any
  annotationsDisabled: boolean
}

const SmallAnnotationsContextMenu = ({ annotationsDisabled, openContext, setOpenContext, viewerId }: Props) => {
  const root = useRef<HTMLDivElement | null>(null)
  useKeyUp(13, () => setOpenContext(null))
  useOnClickOutside(root, () => setOpenContext(null))
  const slideGroupType = useSlideGroupType(viewerId)
  const isMacro = slideGroupType === 'MACRO'
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)

  return (
    <StyledContextMenu style={{ left: openContext?.x, top: openContext?.y }} ref={root}>
      <AnnotationsTypePanelContainer
        isMacro={isMacro}
        disabled={annotationsDisabled || MITOSIS.isVisible}
        openContextMenu
      />
    </StyledContextMenu>
  )
}

export default SmallAnnotationsContextMenu
