import { useCallback } from 'react'

export const useThrottle = () =>
  useCallback((func, limit) => {
    let lastFunc: number
    let lastRan: number

    return (...args: any[]) => {
      if (!lastRan) {
        func(...args)
        lastRan = Date.now()
      } else {
        clearTimeout(lastFunc)
        lastFunc = +setTimeout(() => {
          if (Date.now() - lastRan >= limit) {
            //@ts-ignore
            func(...args)
            lastRan = Date.now()
          }
        }, limit - (Date.now() - lastRan))
      }
    }
  }, [])
