import Button, { ButtonProps } from 'antd/es/button/button'
import { FC, RefAttributes } from 'react'
import styled from 'styled-components/macro'

export const StyledButtonElement = styled(Button)`
  line-height: 16px;
  font-weight: 500;
  padding: 4px 8px;
  box-shadow: none;

  &.ant-btn-default {
    color: var(--color-text-1);
    background: var(--color-bg-3);
  }

  &.ant-btn-primary {
    color: white;
    background: var(--color-purple);
    border: none;
    text-shadow: none;
    box-shadow: none;
  }

  &.ant-btn-primary[disabled] {
    opacity: 0.5;
    pointer-events: auto;
  }

  &.ant-btn-dangerous {
    color: var(--color-red);
  }

  &.ant-btn-icon-only {
    color: var(--color-text-3);
    padding: 0;
    background: none;
  }

  &.ant-btn-circle.ant-btn-lg {
    background: var(--color-bg-3);
    padding: 4px 0;
  }

  &.ant-btn:not(.ant-btn-icon-only):hover {
    opacity: 0.7;
  }

  &.ant-btn-icon-only:hover {
    color: var(--color-text-1);
  }

  &.ant-btn-link {
    color: var(--color-text-1);
  }
`
export type ButtonElementProps = ButtonProps & RefAttributes<HTMLElement>
export const ButtonElement: FC<ButtonElementProps> = (props) => <StyledButtonElement {...props} />
