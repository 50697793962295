import { RegistrationFormDataType } from 'pages/registration/ui'
import { login, loginWithToken, post } from 'shared/api'
import { DeepLinkDTO } from 'types/DeepLink'
import IRegistrationData from 'types/IRegistrationData'
import IUser from 'types/IUser'
import IUserData from 'types/IUserData'

const fetchLogin = async (payload: Partial<IUser>) => {
  try {
    const response = await login<IUserData>({
      data: payload,
      url: '/user/login',
    })
    return response.data
  } catch (e) {
    throw e
  }
}

const register = async (data: RegistrationFormDataType) => {
  try {
    const response = await login<IRegistrationData>({
      data,
      url: '/user',
    })
    return response.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const refreshToken = async () => {
  try {
    const response = await loginWithToken<IUserData>({
      url: '/user/login',
    })

    return response
  } catch (e) {
    throw e
  }
}

const confirmRegistration = async (token: string) => {
  try {
    const response = await login<IUserData>({
      data: {
        token,
      },
      url: '/user/confirm',
    })
    return response.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const logout = async () => {
  try {
    const response = await post({
      url: '/user/logout',
    })
    return response
  } catch (e) {
    console.log(e)
    throw e
  }
}

const requestReset = async (email: string) => {
  try {
    const response = await post({
      data: {
        email,
      },
      url: '/user/reset-password',
    })
    return response
  } catch (e) {
    console.log(e)
    throw e
  }
}

const createNewPassword = async (userId: number | string, data: { newPassword: string; token: string }) => {
  try {
    const response = await post<{ success: boolean }>({
      data,
      url: `/user/${userId}/password`,
    })
    return response
  } catch (e) {
    console.log(e)
    throw e
  }
}

/** Запрос на смену информации о юзере */
const changeUserInfo = async (userId: number | string, data: Partial<IUser>) => {
  try {
    const response = await post<{ success: boolean }>({
      data,
      url: `/user/${userId}`,
    })
    return response
  } catch (e) {
    console.log(e)
    throw e
  }
}

const deepLinkAuth = async (params: string, key: string) => {
  try {
    return await post<DeepLinkDTO>({
      data: { key, params },
      url: '/deep-link',
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

const userService = {
  changeUserInfo,
  confirmRegistration,
  createNewPassword,
  deepLinkAuth,
  fetchLogin,
  logout,
  refreshToken,
  register,
  requestReset,
}

export default userService
