import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { KeyHint } from 'features/hotkeys/ui/KeyHint'
import { MouseHint } from 'features/hotkeys/ui/MouseHint'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerHelpSelector, useViewerMainSelector } from 'viewer/container/lib/useViewerSelector'
import { viewerHelpSlice } from 'viewer/help/model/viewerHelpSlice'

const HelpMessage = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 5px;
  ${() => modalBackground}
  ${() => modalShadow}

  & > span {
    white-space: nowrap;
  }
`

const MessageContainer = styled.div`
  position: absolute;
  bottom: 16px;
  display: flex;
  justify-content: center;
  z-index: 1000;
  left: 50%;
  right: 50%;
`
const StyledIconCross = styled(IconElement)`
  margin-left: 10px;
`

const KeyHintContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  & > * {
    margin: 0px 4px;
  }
  & > *:first-child {
    margin-left: 0px;
  }
`
type Props = {
  viewerId: TViewerId
}

const ViewerHelpContainer = ({ viewerId }: Props) => {
  const { isHelpShown, text } = useViewerHelpSelector(viewerId)
  const { isObjectsDrawActive } = useViewerMainSelector(viewerId)
  const { isFastTravel } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { hideHelpMessage } = viewerHelpSlice?.actions || {}

  const handleClose = () => viewerDispatch(hideHelpMessage(false))

  const theme = useThemeContext()
  const { t } = useTranslation()
  return (
    <MessageContainer>
      {(isHelpShown || isFastTravel) && (
        <HelpMessage>
          {isFastTravel ? (
            <TextElement ellipsis style={{ fontSize: 14, fontWeight: 400 }}>
              {text}
            </TextElement>
          ) : (
            <TextElement ellipsis>{text}</TextElement>
          )}
          {isFastTravel && (
            <KeyHintContainer>
              <KeyHint key1={'F2'} />
              {t('или')}
              <MouseHint name={theme.theme == 'dark' ? 'mouseCenter' : 'mouseCenterLight'} />
            </KeyHintContainer>
          )}
          {isObjectsDrawActive && <StyledIconCross onClick={handleClose} name={'cross'} />}
        </HelpMessage>
      )}
    </MessageContainer>
  )
}

export default ViewerHelpContainer
