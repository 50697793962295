import { format, formatDistanceToNowStrict, formatISO } from 'date-fns'
import en from 'date-fns/locale/en-US'
import ru from 'date-fns/locale/ru'

export const DATE_FORMAT_FOR_DISPLAY = 'dd.MM.yyyy'
export const DATE_TIME_FORMAT_FOR_DISPLAY = 'dd.MM.yyyy HH:mm'
export const DATE_TIME_FORMAT_FOR_DISPLAY_SHORT = 'dd.MM HH:mm'
const DATE_FORMAT_TEXT_MONTH = 'dd MMM yyyy'

export const formatDateForDisplay = (date: string) => {
  const formattedISO_8601_Date = date.includes('+') ? date.split('+')[0] : date
  return format(new Date(formattedISO_8601_Date), DATE_FORMAT_FOR_DISPLAY, {
    locale: ru,
  })
}
export const timeFromNow = (date: string, addSuffix?: boolean, isEnglish?: boolean) =>
  formatDistanceToNowStrict(new Date(date), {
    addSuffix,
    locale: isEnglish ? en : ru,
    roundingMethod: 'round',
  })
export const formatDateTimeForDisplay = (date: Date, short?: boolean) =>
  format(date, short ? DATE_TIME_FORMAT_FOR_DISPLAY_SHORT : DATE_TIME_FORMAT_FOR_DISPLAY, {
    locale: ru,
  })
export const formatDateForServer = (date: Date) =>
  formatISO(date, {
    format: 'extended',
    representation: 'date',
  })
export const formatDateTimeISO = (date: Date, timeDay: string) => {
  const fromDate = date.setHours(0, 0, 0, 0)
  const endDate = date.setHours(23, 59, 59, 999)
  return formatISO(timeDay === 'start' ? fromDate : endDate, {
    format: 'extended',
  })
}
export const formatDateWithWithTextMonth = (date: Date) =>
  format(date, DATE_FORMAT_TEXT_MONTH, {
    locale: ru,
  })

export const formatAge = (date: string, isEnglish: boolean) =>
  `${formatDateForDisplay(date)} / ${timeFromNow(date, false, isEnglish)}`

export const formatDateWithNoTime = (date: Date) =>
  formatISO(date, {
    format: 'extended',
  }).split('T')[0]

export const formatDateStringForDisplay = (date: string) => date.split('-').reverse().join('.')
