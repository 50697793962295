import { Space } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'

import SelectedSite from './SelectedSite'
import SiteSelectElement from './SiteSelectElement'

type Props = {
  sites: DictionaryItem[]
  selectedSites?: DictionaryItem[]
  onAddSite: (site: DictionaryItem) => void | Promise<void>
  onDeleteSite: (site: DictionaryItem) => void | Promise<void>
  title?: string
}

const SiteMultiSelect = ({ onAddSite, onDeleteSite, selectedSites, sites, title }: Props) => {
  const [isLoading, setLoading] = useState(false)
  const [isSelectMode, setSelectMode] = useState(false)

  const onSelect = async (siteId: number) => {
    try {
      setLoading(true)
      const site = sites.find((item) => item.id === siteId)
      site && (await onAddSite(site))
    } finally {
      setLoading(false)
      setSelectMode(false)
    }
  }

  const onDelete = async (site: DictionaryItem) => {
    try {
      setLoading(true)
      await onDeleteSite(site)
    } finally {
      setLoading(false)
    }
  }
  const { t } = useTranslation()
  return (
    <SpinElement spinning={isLoading}>
      <Space
        size={8}
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        <SelectedItemsWrapper
          size={8}
          direction="vertical"
          style={{
            width: '100%',
          }}
        >
          {selectedSites?.map((site) => (
            <SelectedSite site={site} onDeleteSite={onDelete} key={site.id} />
          ))}
        </SelectedItemsWrapper>
        {isSelectMode ? (
          <SiteSelectElement
            style={{
              width: '100%',
            }}
            // eslint-disable-next-line i18next/no-literal-string
            placeholder={t('Поиск по названию или коду') + '…'}
            defaultOpen
            onBlur={() => setSelectMode(false)}
            autoFocus
            onChange={onSelect}
            sites={sites}
          />
        ) : (
          <ButtonElement
            style={{
              width: '100%',
            }}
            onClick={() => setSelectMode(true)}
          >
            {title ?? t('Добавить тип опухоли')}
          </ButtonElement>
        )}
      </Space>
    </SpinElement>
  )
}

const SelectedItemsWrapper = styled(Space)`
  max-height: 200px;
  overflow-y: scroll;
`

export default SiteMultiSelect
