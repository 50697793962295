import { Dropdown, Menu } from 'antd'
import { reportsSlice } from 'features/reports'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { formatDateForDisplay } from 'shared/lib/date'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'
import IReport from 'types/IReport'

type Props = {
  /** Id выбранного отчета */
  selectedReportId?: number
  /** Полный список отчетов */
  reports: IReport[]
}

const StyledMenu = styled(Menu)`
  padding: 4px 0;
  display: grid;
  row-gap: 4px;

  .ant-dropdown-menu-item {
    padding: 0;
    color: var(--color-text-1);

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`

const MenuItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 16px 4px 8px;
  height: 24px;

  &:hover {
    background-color: var(--color-bg-3);
  }
`

const StyledDropdownButton = styled(Dropdown.Button)`
  height: 18px;
  width: 18px;
  margin: 2px 0 0 5px;
  border-radius: 3px !important;

  .ant-btn {
    &-default {
      padding: 0;
      width: 18px;
      height: 18px;
      border-radius: 3px !important;

      &:first-child {
        visibility: hidden;
      }
    }

    &-icon-only {
      padding: 0;
      background-color: unset;
    }
  }

  &:hover {
    svg {
      fill: var(--color-text-1);
    }
  }
`

const SelectReportDropdown = ({ reports, selectedReportId }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isReportSelected, setReportSelected] = useState(false)

  const onSelectReport = ({ caseId, medicalReportId }: IReport) => {
    dispatch(
      reportsSlice.actions.setSelectedReportIndex({
        caseId: Number(caseId),
        index: reports.findIndex((report) => report.medicalReportId === medicalReportId),
      }),
    )
    setReportSelected(false)
  }

  const selectableReports = selectedReportId
    ? reports.filter((report) => report.medicalReportId !== selectedReportId)
    : reports

  const selectionMenu = (
    <StyledMenu>
      {selectableReports.map((report) => (
        <Menu.Item key={report.medicalReportId} onClick={() => onSelectReport(report)}>
          <MenuItemContainer>{`${t('Заключение')} ${formatDateForDisplay(report?.createdAt)}`}</MenuItemContainer>
        </Menu.Item>
      ))}
    </StyledMenu>
  )

  const onVisibleChange = (visible: boolean) => {
    setReportSelected(visible)
  }

  return (
    <StyledDropdownButton
      trigger={['click']}
      overlay={selectionMenu}
      placement="bottomLeft"
      onVisibleChange={onVisibleChange}
      overlayClassName="ant-dropdown-menu-blurry"
      overlayStyle={{
        borderRadius: 5,
        minWidth: 'max-content',
      }}
      icon={
        <StyledIconElement
          fill={isReportSelected ? 'var(--color-text-1)' : 'var(--color-text-3)'}
          selectReport={isReportSelected}
          name="navigateRightDouble"
        />
      }
    />
  )
}

export default SelectReportDropdown

const StyledIconElement = styled(IconElement)<{ selectReport: boolean }>`
  height: 18px;
  width: 18px;
  border-radius: 3px;

  background-color: ${({ selectReport }) => selectReport && 'var(--color-bg-3)'};
`
