import { FC } from 'react'
import { ButtonElement, ButtonElementProps } from 'shared/ui/kit'
import styled from 'styled-components'

const StyledButtonElement = styled(ButtonElement)`
  color: var(--color-blue) !important;
  margin-left: 2px;
  padding-left: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`

const DuplicateShowBtn: FC<ButtonElementProps> = ({ children, ...props }) => (
  <StyledButtonElement {...props}>{children}</StyledButtonElement>
)

export default DuplicateShowBtn
