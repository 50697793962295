import { useTypedSelector } from 'app/redux/lib/selector'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { Feature } from 'ol'
import { Geometry } from 'ol/geom'
import Hover from 'ol-ext/interaction/Hover'
import Tooltip from 'ol-ext/overlay/Tooltip'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import usePrevious from 'shared/lib/hooks/usePrevious'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { IMapOl } from 'types/IMapOl'
import { IMarkupClass } from 'types/IMarkupSlide'
import ISlide, { IGroupType } from 'types/ISlide'
import { useViewerHelpSelector } from 'viewer/container'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'
import { getDescriptionRawHTML, getMitosisData, isObjectsCounting } from 'viewer/map/layers/annotations/lib/helpers'
import { getCustomClass, setDefaultStyle, styleByAnnotationClass } from 'viewer/map/layers/olStyles'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

type Props = {
  /** map - экземпляр olMap карты */
  map: IMapOl
  /** mppX - разрешение слайда */
  mppX: number
  /** selectedAnnotationsIds - массив id выбранных аннотаций */
  selectedAnnotationsIds: number[]
  /** features - массив Feature аннотаций */
  features: Feature<Geometry>[]
  /** slideGroupType - строка с типом слайда MACRO | MICRO */
  slideGroupType: IGroupType
}

const HoverInteraction = ({ features, map, mppX, selectedAnnotationsIds, slideGroupType }: Props) => {
  const queryClient = useQueryClient()
  const { activeViewerId: viewerId } = useOpenViewers()
  const { slideId } = useViewerIdSlideState(viewerId)
  const { clickCondition, extendedContextMenuPosition, hoveredAnnotationId } = useTypedSelector(
    (state) => state.annotations,
  )
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const hoveredFeature = useRef<Feature<any>>()
  const currentTooltip = useRef<Tooltip>()
  const currentHover = useRef<Hover>()
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const isVisibleAreaLabel = !!slide?.slideMetadata?.commonMetadata?.mppX
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const { descriptionsVisibility } = useTypedSelector((state) => state.viewerPage)
  const { isHelpShown } = useViewerHelpSelector(viewerId)
  const dispatch = useDispatch()
  const annotationsOpacity = useTypedSelector((state) => state.annotations.annotationsOpacity)
  const prevHoveredAnnotationId = usePrevious(hoveredAnnotationId)

  /**
   * Обновляет стиль аннотации по указанному идентификатору.
   *
   * @param {number} hoveredId - Идентификатор аннотации, для которой нужно обновить стиль.
   * @param {boolean} highlight - Флаг, указывающий, следует ли выделить аннотацию (по умолчанию: false).
   * @returns {void}
   */
  const updateAnnotationStyle = (hoveredId?: number, highlight?: boolean): void => {
    setDefaultStyle(
      !!taskId,
      annotationsOpacity / MAX_OPACITY,
      hoveredFeature.current,
      currTaskClasses,
      !!highlight,
      !!hoveredId && selectedAnnotationsIds.includes(hoveredId),
    )
  }

  useEffect(() => {
    if (clickCondition === 'dbClick') return
    let hoveredId: number
    if (
      (selectedAnnotationsIds?.length > 0 && extendedContextMenuPosition === null) ||
      (selectedAnnotationsIds?.length > 1 && extendedContextMenuPosition !== null)
    ) {
      currentHover.current && map.removeInteraction(currentHover.current as any)
      currentHover.current = undefined
    } else {
      //@ts-ignore
      const hover = new Hover({
        cursor: 'pointer',
        hitTolerance: 10,
        layerFilter: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.ANNOTATIONS,
      })
      // @ts-ignore
      hover.on('enter', (evt: any) => {
        if (evt.dragging) return
        if (!hoveredFeature.current) {
          hoveredFeature.current = evt.feature
        } else {
          // clear prev style
          updateAnnotationStyle()
          // and reset
          hoveredFeature.current = evt.feature
        }
        hoveredId = hoveredFeature?.current?.get('slideAnnotationId')
        updateAnnotationStyle(hoveredId, true)

        dispatch(annotationsSlice.actions.setHoveredAnnotationId(hoveredId))
      })
      // @ts-ignore
      hover.on('leave', (evt: any) => {
        if (evt.dragging) return
        updateAnnotationStyle(hoveredId)

        dispatch(annotationsSlice.actions.setHoveredAnnotationId(undefined))
        hoveredFeature.current = undefined
      })

      map.addInteraction(hover as any)
      currentHover.current = hover
    }

    return () => {
      if (!currentHover.current?.getMap()?.getTargetElement()) return
      // @ts-ignore
      currentHover?.current?.setMap(null)
      hoveredFeature?.current?.setStyle(
        styleByAnnotationClass({
          annotationClass: hoveredFeature.current.get('class'),
          annotationType: hoveredFeature.current.get('annotation_type'),
          customClass: getCustomClass(
            hoveredFeature.current.get('class'),
            hoveredFeature.current.get('annotation_type'),
            currTaskClasses,
          ),
          isTask: !!taskId,
          opacity: annotationsOpacity / MAX_OPACITY,
        }),
      )
      hoveredFeature.current = undefined
      dispatch(annotationsSlice.actions.setHoveredAnnotationId(undefined))
      currentHover.current && map.removeInteraction(currentHover.current as any)
    }
  }, [selectedAnnotationsIds?.length, extendedContextMenuPosition, slideId, annotationsOpacity])

  const createTooltip = () => {
    // @ts-ignore
    const tooltip = new Tooltip({
      getHTML: (feature: Feature<Geometry>) => {
        const annotationId = feature?.get('slideAnnotationId')
        const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, annotationId])
        const mitosisData = getMitosisData(annotation)
        const type = feature?.get('annotation_type') as AnnotationType
        const description: string =
          taskId && annotation
            ? getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)?.get('description')
            : feature?.get('description') || ''

        if (
          (descriptionsVisibility && description && !taskId) ||
          type === undefined ||
          ((isObjectsCounting(type) || type === AnnotationType.RULER) && descriptionsVisibility) ||
          (type === AnnotationType.POINT && description?.length === 0) ||
          (slideGroupType === 'MACRO' && description?.length === 0)
        )
          return
        const geometry = feature?.getGeometry()
        return getDescriptionRawHTML(
          slideGroupType === 'MACRO' ? undefined : geometry,
          isVisibleAreaLabel,
          type,
          description,
          mppX,
          mitosisData,
        )
      },
      maximumFractionDigits: 2,
      offsetBox: 20,
      popupClass: 'custom-ol-draw-tooltip',
      positionning: 'auto',
    })
    map.addOverlay(tooltip as any)
    currentTooltip.current = tooltip
  }

  const deleteTooltip = () => {
    if (!currentTooltip.current?.getMap()?.getTargetElement()) return
    // @ts-ignore
    currentTooltip.current?.setMap(null)
    currentTooltip.current && map.removeOverlay(currentTooltip.current as any)
    dispatch(annotationsSlice.actions.setHoveredAnnotationId(undefined))
    currentTooltip.current?.removeFeature()
    currentTooltip.current?.hide()
    currentTooltip.current = undefined
  }

  useEffect(() => {
    deleteTooltip()
    setTimeout(createTooltip)
    return () => {
      deleteTooltip()
    }
  }, [slideGroupType, descriptionsVisibility, slideId])

  useEffect(() => {
    if (isHelpShown) {
      currentTooltip.current?.removeFeature()
      currentTooltip.current?.hide()
    } else if (hoveredAnnotationId) {
      hoveredFeature.current && currentTooltip.current?.addPopupClass('custom-ol-draw-tooltip-two')
      hoveredFeature.current && currentTooltip.current?.setPositioning('auto')
      hoveredFeature.current && currentTooltip.current?.setFeature(hoveredFeature.current)
      !hoveredAnnotationId && dispatch(annotationsSlice.actions.setHoveredAnnotationId(hoveredAnnotationId))
    } else {
      currentTooltip.current?.removeFeature()
      currentTooltip.current?.hide()
    }
  }, [hoveredAnnotationId, isHelpShown, slideId])

  useEffect(() => {
    /** Эффект для контроля ховер стиля при наведении на аннотацию в списке в правой панели */
    let hoveredId: number
    if (!hoveredAnnotationId || hoveredAnnotationId !== prevHoveredAnnotationId) {
      hoveredId = hoveredFeature?.current?.get('slideAnnotationId')
      updateAnnotationStyle(hoveredId)
      hoveredFeature.current = undefined
    }

    if (hoveredAnnotationId) {
      hoveredFeature.current = features.find((feature) => feature.get('slideAnnotationId') === hoveredAnnotationId)
      hoveredId = hoveredFeature?.current?.get('slideAnnotationId')
      updateAnnotationStyle(hoveredId, true)
    }
  }, [hoveredAnnotationId])

  useEffect(() => {
    if (selectedAnnotationsIds?.length > 0) {
      currentTooltip.current?.removeFeature()
      currentTooltip.current?.hide()
    }
  }, [selectedAnnotationsIds?.length])
  return null
}

export default HoverInteraction
