import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PVProstateTissueChild } from 'types/IPVProstate'
import { usePathVisionSegmentationContext } from 'viewer/map/layers/path-vision-segmentation/PathVisionSegmentationContext'

import TissueClassElement from './TissueClassElement'

const Wrapper = styled.div`
  margin-bottom: 4px;
`

export const GroupHeader = styled.div`
  cursor: pointer;
  padding: 5px 4px;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--color-bg-3);
    border-radius: 5px;
  }

  & > * {
    margin-right: 4px !important;
  }
`

const StyledFieldName = styled.div`
  color: var(--color-text-3);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`

const StyledFieldValue = styled.div`
  color: var(--color-text-1);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`

const Marker = styled.div`
  width: 14px;
  height: 14px;
  background: var(--color-bg-3);
  border: 1px solid var(--color-bg-3);
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
`

/** Props for TissueGroup */
type Props = {
  /** Идентификатор столбика */
  tissue: number
  /** Глисон скор */
  gleason: string
  /** Группа */
  group: number
  /** Массив дочерних элементов (классов) столбика */
  classes: PVProstateTissueChild[]
  /** Название класса столбика */
  class_name: string
  /** Коллбэк для события onClick */
  onSelectElementHandle: (val: string) => void
}

const TissueGroup: React.FC<Props> = ({ class_name, classes, gleason, group, onSelectElementHandle, tissue }) => {
  const { setHoveredClassId } = usePathVisionSegmentationContext()
  const { t } = useTranslation()
  const onMouseEnterHandle = (class_name: string) => setHoveredClassId(`${tissue}-${class_name}`)
  const onMouseLeaveHandle = () => setHoveredClassId()
  const onClickHandle = (class_name: string) => onSelectElementHandle(`${tissue}-${class_name}`)

  return (
    <Wrapper>
      <GroupHeader onClick={() => onClickHandle(class_name)}>
        <Marker>{tissue + 1}</Marker>
        <StyledFieldName>{t('Глисон')}</StyledFieldName>
        <StyledFieldValue>{gleason}</StyledFieldValue>
        <StyledFieldName>{t('Группа')}</StyledFieldName>
        <StyledFieldValue>{group}</StyledFieldValue>
      </GroupHeader>

      {classes.map((it) => (
        <TissueClassElement
          key={it.class_id}
          {...it}
          onMouseEnterHandle={onMouseEnterHandle}
          onMouseLeaveHandle={onMouseLeaveHandle}
          onClickHandle={onClickHandle}
        />
      ))}

      <Divider />
    </Wrapper>
  )
}

export default TissueGroup
