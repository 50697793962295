import { useTypedSelector } from 'app/redux/lib/selector'
import { useCasesQuery } from 'features/cases/api/query'
import { useEffect, useRef, useState } from 'react'
import { ScrollParams } from 'react-virtualized'
import { useQueryParams } from 'shared/lib/hooks'
import { isGuestHandler } from 'shared/lib/local-storage'
import { VirtualInfiniteScroll } from 'shared/ui/infinite-scroll'
import { Center, EmptyElement, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import CaseCardContainer from './CaseCardContainer'

type Props = {
  relation: 'owner' | 'shared'
  status: 'open' | 'archive'
  searchQuery?: string
  selectedCaseId?: number
  workspaceId?: string
}

const CaseWrapper = styled.div`
  padding: 8px 0;
  width: 100%;
  display: grid;
  place-items: center;
  & > div {
    width: 626px;
  }
`

export const CasesListContainer = ({ relation, searchQuery, selectedCaseId, status }: Props) => {
  const caseId = useQueryParams().get('caseId')
  const workspaceId = useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isGuest = isGuestHandler(authorities)

  const scrollToIndexOn = useRef(true)
  const [firstRender, setFirstRender] = useState(true)

  const { data, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, ids, isLoading, remove } =
    useCasesQuery({
      caseId,
      relation: isGuest ? 'shared' : relation,
      searchQuery,
      status,
      workspaceId,
    })

  useEffect(
    () => () => {
      remove()
    },
    [searchQuery],
  )
  useEffect(() => {
    if (
      firstRender &&
      hasPreviousPage &&
      data?.pageParams &&
      data?.pageParams?.length > 0 &&
      //@ts-ignore
      data?.pageParams[0]?.previousPageCursor
    ) {
      setFirstRender(false)
      fetchPreviousPage()
    }
    // @ts-ignore
    ref?._child?.parentNode?.children &&
      data?.pageParams &&
      data?.pageParams?.length > 0 &&
      // @ts-ignore
      data?.pageParams[0]?.previousPageCursor &&
      // @ts-ignore
      ref?._child?.parentNode?.children[2]?.scrollIntoView({
        block: 'start',
      })
  }, [data])

  const [ref, setRef] = useState<any>(null)
  const topTrigger = useRef<HTMLDivElement>(null)
  const scrollTop = useRef(0)
  const scrollDirection = useRef('down')

  const onListScroll = (e: ScrollParams) => {
    if (!e.clientHeight) return
    if (scrollToIndexOn.current) {
      scrollToIndexOn.current = false
    }
    if (e.scrollTop !== scrollTop.current) {
      scrollDirection.current = e.scrollTop > scrollTop.current ? 'down' : 'up'
      scrollTop.current = e.scrollTop
    }
    if (scrollTop.current === topTrigger.current?.scrollTop && scrollDirection.current === 'up') {
      if (
        hasPreviousPage &&
        data?.pageParams &&
        data?.pageParams?.length > 0 &&
        //@ts-ignore
        data?.pageParams[0]?.previousPageCursor
      ) {
        fetchPreviousPage()
      }
    }
  }

  if (isLoading) {
    return (
      <Center>
        <SpinElement />
      </Center>
    )
  }
  if (!ids.length) {
    return (
      <Center>
        <EmptyElement />
      </Center>
    )
  }
  return (
    <>
      <div ref={topTrigger} />
      <VirtualInfiniteScroll
        key={relation + status + searchQuery}
        defaultRowHeight={250}
        fetchMore={hasNextPage ? fetchNextPage : async () => null}
        list={ids}
        onScroll={onListScroll}
        total={ids.length + 1}
        scrollToIndex={scrollToIndexOn.current ? ids.findIndex((it) => it === Number(caseId)) : undefined}
        setRef={setRef}
        rowRenderer={({ index, isVisible }) => (
          <CaseWrapper>
            <CaseCardContainer relations={relation} id={ids[index]} selected={ids[index] === selectedCaseId} />
          </CaseWrapper>
        )}
      />
    </>
  )
}
