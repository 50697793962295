import { MitosisAnnotation } from 'assets/icons/mitosisAnnotation'
import { ANNOTATIONS_ICONS_MAP } from 'features/annotations/ui/AnnotationsTypePanelContainer'
import { HoverWrapper } from 'features/annotations/ui/HoverWrapper'
import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { areEqual } from 'react-window'
import { formatDateTimeForDisplay } from 'shared/lib/date'
import { IconElement, SmallIconButtonElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { createTextAreaLabel, getPolygonArea } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import {
  createObjectsCaption,
  getMitosisData,
  isObjectsCounting,
  ObjectsAnnotationType,
} from 'viewer/map/layers/annotations/lib/helpers'
import { getFeaturesFromGeoJson } from 'viewer/map/lib/utils'

type Props = {
  /** isVisibleArea - флаг, который отслеживает видимость размеров аннотаций  */
  isVisibleArea: boolean
  /** annotation - текущая аннотация */
  annotation: IAnnotation
  /** isActive - выбрана ли текущая аннотация */
  isActive: boolean
  /** onSelect - колбэк, вызывающийся при клике на аннотацию в списке */
  onSelect: React.MouseEventHandler<HTMLDivElement>
  /** onDelete - колбэк, вызывающийся при клике на аннотацию в списке */
  onDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  /** canDelete - может ли пользователь удалить аннотацию */
  canDelete: boolean
  /** annotationsIsVisible - массив id показываемых аннотаций. null если скрыты все аннотации */
  annotationsIsVisible: number[] | null
  /** setAnnotationsIsVisible - колбэк, вызывающийся при изменении видимости текущей аннотации */
  setAnnotationsIsVisible: (annotations: number) => void
  /** caption - страка с подписью аннотации */
  caption?: string
  /** mppX - разрешение слайда */
  mppX: number
  /** Идет процесс запроса на удаление аннотации */
  isDeleting: boolean
}

const UserName = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: var(--color-text-3);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const UserDate = styled(UserName)`
  font-weight: 600;
`

const DescriptonInfo = styled.span`
  color: var(--color-text-1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`

const AnnotationInfo = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 16px 1fr auto;
  gap: 8px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledIconElement = styled(IconElement)<{ color?: string }>`
  color: ${({ color }) => color};
`

const StyledText = styled.div`
  line-height: 16px;
  color: var(--color-text-1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const PlatformAnnotationListItem = memo(
  ({
    annotation,
    annotationsIsVisible,
    canDelete,
    caption,
    isActive,
    isDeleting,
    isVisibleArea,
    mppX,
    onDelete,
    onSelect,
    setAnnotationsIsVisible,
  }: Props) => {
    const { t } = useTranslation()
    const currDate = new Date(annotation.updatedAt)
    const currYear = new Date().getFullYear()
    const isCurrYear = currDate.getFullYear() === currYear

    const annotationArea = useMemo(() => {
      let metric = 0
      if (!annotation) return 0
      if (!annotation?.data?.formattedFeature) return annotation?.metric
      const annotationF = getFeaturesFromGeoJson(annotation?.data?.formattedFeature)[0]
      const geom = annotationF.getGeometry()
      if (geom) {
        metric = getPolygonArea(geom, mppX)
      }
      return metric || 0
    }, [annotation, mppX])

    const createCaption = () => {
      if (annotation.type === AnnotationType.POINT || !isVisibleArea) return caption || ''
      return `${caption ? caption + ', ' : ''} ${createTextAreaLabel(
        annotationArea,
        annotation.type === AnnotationType.ARROW || annotation.type === AnnotationType.RULER ? 'line' : 'area',
      )}`
    }

    const mitosisData = getMitosisData(annotation)

    return (
      <HoverWrapper onSelect={onSelect} id={annotation.slideAnnotationId} type={annotation.type} isActive={isActive}>
        <UserInfo>
          <UserDate>{formatDateTimeForDisplay(new Date(annotation.updatedAt), isCurrYear)}</UserDate>
          <UserName>{annotation.user}</UserName>
        </UserInfo>
        <AnnotationInfo>
          <IconContainer>
            {annotation.type === AnnotationType.MITOSIS ? (
              <MitosisAnnotation />
            ) : (
              <StyledIconElement
                color="var(--color-purple-light)"
                size="md"
                name={ANNOTATIONS_ICONS_MAP[annotation.type]}
              />
            )}
          </IconContainer>
          {isObjectsCounting(annotation?.type) ? (
            <StyledText>
              {createObjectsCaption(annotation.type as ObjectsAnnotationType, mitosisData, caption)}
            </StyledText>
          ) : (
            <DescriptonInfo>{createCaption()}</DescriptonInfo>
          )}
          <div style={{ display: 'flex', gap: '4px' }}>
            <SmallIconButtonElement
              icon={annotationsIsVisible?.includes(annotation.slideAnnotationId) ? 'eye' : 'eyeClose'}
              style={{ color: 'var(--color-text-3)' }}
              onClick={(e) => {
                e.stopPropagation()
                setAnnotationsIsVisible(annotation.slideAnnotationId)
              }}
            />
            {canDelete && (
              <SmallIconButtonElement disabled={isDeleting} title={t('Удалить')} icon="delete" onClick={onDelete} />
            )}
          </div>
        </AnnotationInfo>
      </HoverWrapper>
    )
  },
  areEqual,
)

export default PlatformAnnotationListItem
