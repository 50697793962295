import { checkIntegration } from 'entities/lis-integration'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { useTranslation } from 'react-i18next'
import { formatDateStringForDisplay } from 'shared/lib/date'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IPatientDTO } from 'types/IPatient'

type Props = {
  patient: IPatientDTO
}

const PatientInfo = ({ patient }: Props) => {
  const lisMode = useLisMode()
  const { t } = useTranslation()

  return (
    <div>
      {patient ? (
        checkIntegration('patient', 'fullname', lisMode) && (
          <TitleElement level={3} style={{ marginBottom: '7px' }}>
            <span data-testid={`patient-name-viewer`}> {patient.fullname || t('Имя пациента скрыто')}</span>
          </TitleElement>
        )
      ) : (
        <TitleElement level={3} style={{ marginBottom: '7px' }}>
          <span data-testid={`patient-name-viewer`}>{t('Имя пациента не указано')}</span>
        </TitleElement>
      )}

      <StyledDescription colon={false} isFlex>
        {/* ID пациента */}
        {checkIntegration('patient', 'externalId', lisMode) && patient?.externalId && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid={`patient-externalId-viewer`}>{t('ID пациента')}</span>}
            span={3}
          >
            <span data-testid="patient-externalId-content-viewer">{patient?.externalId}</span>
          </DescriptionsItem>
        )}
        {/* Дата рождения */}
        {checkIntegration('patient', 'birthDate', lisMode) && patient?.birthDate && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid={`patient-birthDate-viewer`}>{t('Дата рождения')}</span>}
            span={3}
          >
            <span data-testid="patient-birthDate-content-viewer">{formatDateStringForDisplay(patient.birthDate)}</span>
          </DescriptionsItem>
        )}
        {/* Пол */}
        {checkIntegration('patient', 'sex', lisMode) && patient?.sex && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={<span data-testid={`patient-sex-viewer`}>{t('Пол')}</span>}
            span={3}
          >
            <span data-testid="patient-sex-content-viewer">
              {patient?.sex === 'FEMALE' ? t('Женщина') : t('Мужчина')}
            </span>
          </DescriptionsItem>
        )}
      </StyledDescription>
    </div>
  )
}

export default PatientInfo

const StyledDescription = styled(Descriptions)`
  tbody {
    gap: 8px;
  }
`
