import { notification, Row } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { SentryRoute } from 'app/sentry'
import { OpenNotificationsButton } from 'features/notifications'
import { isChatAccess } from 'features/support-chat'
import { UserOptionsContainer } from 'features/user-options'
import { useWorkspacesQuery, workspacesSlice } from 'features/workspace'
import { AtlasRoute } from 'pages/atlas'
import { CreateRoute } from 'pages/cases/create'
import { CasesRoute } from 'pages/cases/list'
import { SearchCase } from 'pages/cases/list/SearchCase'
import { SumpRoute } from 'pages/sump'
import { TasksRoute } from 'pages/tasks'
import TaskRoute from 'pages/tasks/task/ui/TaskRoute'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { ORIGIN_CASE_DATA_KEY, ORIGIN_CASES_DATA_KEY } from 'shared/lib/common/storageKeys'
import { PageHeader } from 'shared/ui/header'
import { IconElement, TooltipElement } from 'shared/ui/kit'
import { MainToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import IUserRole from 'types/IUserRole'

import PrimaryLayout from './PrimaryLayout'

const CasesRoutes = () => {
  const dispatch = useDispatch()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const { data: workspaces } = useWorkspacesQuery()
  const location = useLocation<{ referrer?: Location }>()
  const isChatAccessWorkspace = isChatAccess(currentWorkspace)

  useEffect(() => {
    localStorage.removeItem(ORIGIN_CASE_DATA_KEY)
    localStorage.removeItem(ORIGIN_CASES_DATA_KEY)
    notification.close('otherCase')
  }, [])

  const chatClose = () => window.ReplainAPI && window.ReplainAPI('close')

  useEffect(() => {
    if (!isChatAccessWorkspace && window.ReplainAPI) {
      chatClose()
    }
  }, [isChatAccessWorkspace])

  // needs to got it only from redux cause of proper working external case links
  const workspaceId = useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId

  if (!currentWorkspace && workspaceId && workspaces) {
    const newWorkspace = workspaces?.find((workspace) => workspace.workspaceId === workspaceId) || workspaces[0]
    if (newWorkspace !== undefined) {
      dispatch(workspacesSlice.actions.setCurrentWorkspace(newWorkspace))
    }
  }

  const [activeSearch, setActiveSearch] = useState<boolean>(false)

  const authorities = useTypedSelector((state) => state.user.authorities)
  const isAtlasAvailable = authorities?.includes(IUserRole.ROLE_ATLAS_VALIDATION)
  const isTaskAvailable = authorities?.includes(IUserRole.ROLE_MARKUP_USER)

  const { t } = useTranslation()

  const notificationButton = useRef<HTMLDivElement | null>(null)

  return (
    <PrimaryLayout
      header={
        <PageHeader>
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <UserOptionsContainer />

            {activeSearch && <SearchCase setActive={setActiveSearch} active={activeSearch} />}
            <MainToolbarButton
              active={activeSearch}
              onClick={() => {
                setActiveSearch(!activeSearch)
              }}
              icon={<IconElement name="searchCase" />}
            />
            <TooltipElement title={t('Уведомления')} placement="bottom">
              <div ref={notificationButton} style={{ height: 24, margin: '0 4px' }}>
                <OpenNotificationsButton />
              </div>
            </TooltipElement>
          </Row>
        </PageHeader>
      }
    >
      {workspaceId && (
        <Switch>
          <SentryRoute exact={true} path={`/workspace`}>
            <CasesRoute notificationButton={notificationButton} />
          </SentryRoute>
          <SentryRoute path={['/workspace/:workspaceId/cases', '/workspace/:workspaceId/archive']}>
            <CasesRoute notificationButton={notificationButton} />
          </SentryRoute>
          <SentryRoute path="/workspace/:workspaceId/create" key="create">
            <CreateRoute notificationButton={notificationButton} />
          </SentryRoute>
          {isAtlasAvailable && (
            <SentryRoute path="/atlas" key="atlas">
              <AtlasRoute notificationButton={notificationButton} />
            </SentryRoute>
          )}
          {isTaskAvailable && (
            <SentryRoute exact={true} path="/tasks" key="tasks">
              <TasksRoute notificationButton={notificationButton} />
            </SentryRoute>
          )}
          {isTaskAvailable && (
            <SentryRoute path="/tasks/:taskId">
              <TaskRoute notificationButton={notificationButton} />
            </SentryRoute>
          )}
          <SentryRoute exact={true} path={`/workspace/${workspaceId}/warehouse`} key="sump">
            <SumpRoute notificationButton={notificationButton} />
          </SentryRoute>
          <SentryRoute path="/" key={'home'}>
            <Redirect to={location?.state?.referrer ?? `/workspace/${workspaceId}/cases`} />
          </SentryRoute>
        </Switch>
      )}
    </PrimaryLayout>
  )
}

export default CasesRoutes
