import { useTypedSelector } from 'app/redux/lib/selector'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { pluralText } from 'shared/lib/text'
import { COLON_WITH_SPACE } from 'shared/text-constants'
import { Row, Scroll, SmallIconButtonElement } from 'shared/ui/kit'
import styled from 'styled-components'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { useAnnotationAuthorValidation } from 'viewer/map/layers/objects-counting/lib/hooks/useAnnotationAuthorValidation'
import { useObjectsCountingContext } from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

import { countingTypesText } from './countingTypes'
import Spot from './Spot'

/** Props for MitosisCard component */
type Props = {
  /** viewerId - id текущего вьювера */
  viewerId: TViewerId
  /** currentSlide - текущий слайд */
  currentSlide?: ISlide
}

const ScrollWrapper = styled(Scroll)`
  flex: 1 1 0;
`

const TotalObjects = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-1);
`

const StyledRow = styled(Row)`
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: 8px;
  flex: 0 0 0;
`

const ObjectsCard: React.FC<Props> = memo(({ currentSlide, viewerId, ...props }) => {
  const { data, getTotalMitosisCount, removeSpot } = useObjectsCountingContext()
  const isAuthorValid = useAnnotationAuthorValidation()
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType) || 'OBJECTS'
  const onRemove = () => {
    if (!isAuthorValid) return
    const [spotId, value] = Object.entries(data)[0]
    value?.mitosis?.forEach((it) => it.set('isHidden', false))
    removeSpot(spotId)
  }
  const { t } = useTranslation()
  const text = pluralText(getTotalMitosisCount(), countingTypesText[countingObjectType].pluralText)

  return (
    <>
      <StyledRow>
        {Object.keys(data).length ? (
          <TotalObjects>
            {t('Всего')}
            {COLON_WITH_SPACE}
            {getTotalMitosisCount()} {t(text)}
          </TotalObjects>
        ) : null}
        {Object.keys(data).length === 1 && (
          <SmallIconButtonElement disabled={!isAuthorValid} icon="delete" onClick={onRemove} fill="#DC524C" />
        )}
      </StyledRow>
      {Object.entries(data).length > 0 && (
        <ScrollWrapper>
          {Object.entries(data).map(([id, record]) => (
            <Spot key={id} spot={record.spot} spotId={id} slide={currentSlide} />
          ))}
        </ScrollWrapper>
      )}
    </>
  )
})

export default ObjectsCard
