import { Form } from 'antd'
import { FormControl } from 'features/form'
import { notices } from 'features/notices'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { parseErrorsFromServerForm } from 'shared/lib/form'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'
import { IconElement, InputElement, InputPasswordElement, SpaceElement } from 'shared/ui/kit'

const { Item: FormItem, useForm } = Form

export type RegistrationFormDataType = {
  adminPassword?: string
  email: string
  fullname: string
  password: string
  /** Язык для сохранения у пользователя */
  preferredLanguage: I18N_LANGUAGES
}

type Props = {
  onSubmit: (payload: RegistrationFormDataType) => void
}

export const RegistrationForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation()
  const adminPasswordVisible = useMemo((): boolean => {
    //@ts-ignore
    if (typeof window['ADMIN_PASSWORD_VISIBLE'] === 'boolean') return window['ADMIN_PASSWORD_VISIBLE']
    else return false
  }, [])
  const [isLoading, setLoading] = useState(false)
  const [form] = useForm()
  const { i18n } = useTranslation()

  const onFinish = useCallback(
    async (payload: RegistrationFormDataType) => {
      try {
        setLoading(true)
        form.setFields([
          {
            errors: [],
            name: 'fullname',
          },
          {
            errors: [],
            name: 'email',
          },
          {
            errors: [],
            name: 'password',
          },
          {
            errors: [],
            name: 'adminPassword',
          },
        ])
        // Код языка, очищенный от не-строчных символов
        const language = i18n.resolvedLanguage?.replace(/[^a-z]/g, '')
        await onSubmit({
          ...payload,
          email: payload.email?.trim(),
          preferredLanguage: (language as I18N_LANGUAGES) || I18N_LANGUAGES.RUSSIAN,
        })
      } catch (e: any) {
        if (e.response?.status === 409) {
          form.setFields([
            {
              errors: [t('Пользователь с таким email уже существует')],
              name: 'email',
            },
          ])
          return
        }
        if (e.response?.status === 400) {
          form.setFields(parseErrorsFromServerForm(e.response.data.errors))
        }
        if (e.response?.status === 418) {
          notices.error({
            message: t('Превышено максимальное количество пользователей в рамках лицензии'),
          })
        }
        if (e.response?.data?.errors) form.setFields(parseErrorsFromServerForm(e.response.data.errors))
      } finally {
        setLoading(false)
      }
    },
    [onSubmit],
  )

  return (
    <Form
      onFinish={onFinish as any}
      form={form}
      initialValues={{
        password: '',
      }}
    >
      <FormItem name="fullname">
        <FormControl as={InputElement} prefix={<IconElement name="person" />} placeholder={t('Имя')} />
      </FormItem>
      <SpaceElement size={8} />
      <FormItem
        data-testid="email-form-item"
        name="email"
        validateTrigger={'onBlur'}
        rules={[
          {
            message: t('Неверный формат'),
            transform: (value) => value?.trim(),
            type: 'email',
            validateTrigger: 'onBlur',
          },
          {
            //pattern: emailRegExpPattern,
            message: 'email содержит недопустимые символы',
            transform: (value) => value?.trim(),
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <FormControl as={InputElement} prefix={<IconElement name="mail" />} placeholder={t('Электронная почта')} />
      </FormItem>
      <SpaceElement size={8} />
      <FormItem name="password">
        <FormControl as={InputPasswordElement} prefix={<IconElement name="password" />} placeholder={t('Пароль')} />
      </FormItem>
      {adminPasswordVisible && (
        <>
          <SpaceElement size={8} />
          <FormItem name="adminPassword">
            <FormControl
              as={InputPasswordElement}
              prefix={<IconElement name="password" />}
              placeholder={t('Пароль администратора')}
            />
          </FormItem>
        </>
      )}
      <SpaceElement size={32} />
      <FormItem>
        <FormControl type="primary" htmlType="submit" loading={isLoading}>
          {t('Зарегистрироваться')}
        </FormControl>
      </FormItem>
    </Form>
  )
}
