import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useTypedSelector } from 'app/redux/lib/selector'
import { IPermissionLevel } from 'entities/permissions'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CheckboxElement, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Options = styled.div`
  height: 100%;
  border-top: 1px solid var(--color-border-1);
`

const StyledBoardHelp = styled.div`
  background: var(--color-bg-3);
  color: var(--color-text-1);
  font-size: 11px;
  line-height: 12px;
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0px;
  width: 100%;
`
const StyledTitle = styled.div`
  color: var(--color-text-1);
`

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCheckboxElement = styled(CheckboxElement)`
  margin-top: 16px !important;

  &:first-child {
    margin-top: 0px;
  }
`

type isCheckedType = {
  annotations: boolean
  scaleLine: boolean
}

type Props = {
  isChecked: isCheckedType | any
  setIsChecked: Dispatch<SetStateAction<{ annotations: boolean; scaleLine: boolean }>>
  annotationsId: number[] | undefined
  /** уровень доступа */
  permissionLevel?: IPermissionLevel
  labelUrl?: string | null
}

const OptionsContainer = ({ annotationsId, isChecked, labelUrl, permissionLevel, setIsChecked }: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const OVERVIEW = useTypedSelector((state) => state.viewerPage.tools?.OVERVIEW)
  const SLIDE_LABEL = useTypedSelector((state) => state.viewerPage.tools?.SLIDE_LABEL)
  const { t } = useTranslation()

  const checkHandler = async (time: number, keyState: string, e: CheckboxChangeEvent) => {
    await setIsLoading(true)
    switch (keyState) {
      case 'annotations':
        setIsChecked((prevState: isCheckedType) => ({
          ...prevState,
          annotations: e.target.checked,
        }))
        break
      case 'miniMap':
        await dispatch(viewerPageSlice.actions.toggleTool('OVERVIEW'))
        break
      case 'label':
        await dispatch(viewerPageSlice.actions.toggleTool('SLIDE_LABEL'))
        break
      case 'scaleLine':
        setIsChecked((prevState: isCheckedType) => ({
          ...prevState,
          scaleLine: e.target.checked,
        }))
        break
    }
    await setTimeout(() => {
      setIsLoading(false)
    }, time)
  }

  return (
    <Options>
      <SpinElement spinning={isLoading}>
        <CheckboxesContainer>
          <StyledCheckboxElement
            onChange={(e) => checkHandler(700, 'annotations', e)}
            checked={annotationsId?.length ? isChecked.annotations : false}
            disabled={!annotationsId?.length}
          >
            {t('Аннотации')}
          </StyledCheckboxElement>
          {!annotationsId?.length && <StyledBoardHelp>{t('На выбранном участке нет аннотаций')}</StyledBoardHelp>}
          <StyledCheckboxElement
            onChange={(e) => checkHandler(3000, 'miniMap', e)}
            checked={OVERVIEW.isVisible}
            style={{ margin: 0 }}
          >
            {t('Обзор слайда')}
          </StyledCheckboxElement>
          <StyledCheckboxElement
            onChange={(e) => checkHandler(700, 'scaleLine', e)}
            checked={isChecked.scaleLine}
            style={{ margin: 0 }}
          >
            {t('Масштабная линейка')}
          </StyledCheckboxElement>
          {labelUrl && (
            <StyledCheckboxElement
              disabled={permissionLevel === 'READ_ONLY'}
              onChange={(e) => checkHandler(700, 'label', e)}
              checked={SLIDE_LABEL.isVisible}
              style={{ margin: 0 }}
            >
              {t('Этикетка')}
            </StyledCheckboxElement>
          )}
        </CheckboxesContainer>
      </SpinElement>
    </Options>
  )
}

export default OptionsContainer
