import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import Polygon from 'ol/geom/Polygon'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { DatalayerStatus, DataLayerTypes } from 'types/ISlideDataLayer'
import TViewerId from 'types/TViewerId'
import { useCurrentDataLayer, useSlideDataLayers } from 'viewer/tools/api/query'
import { ML_TOOLS_MAP } from 'viewer/tools/ui/modal/MlToolsMap'

/** Контекст Детекции метастазов колоректального рака в лимфоузлах  */
type IMedicalNeuronets = {
  /** Массив features из GeoJSON-а dataLayer MEDICALNEURONETS_CRC */
  features: Feature<Polygon>[]
  /** Уровень прозрачность полигонов на карте */
  opacity: number
  /** Коллбэк для установки уровня прозрачности полигонов на карте */
  setOpacity: (val: number) => void
  /** Класс подсвечиваемой фичи */
  hoveredClass?: string
  /** Коллбэк для установки класса подсвечиваемой фичи */
  setHoveredClass: (val?: string) => void
  /** Индикатор загрузки всех слоев или конкретного */
  isLoading: boolean
  /** Текст сообщения в панели при недоступности GeoJSON-а */
  infoMessage: string
}

/** Начальный уровень прозрачности полигонов */
const DEFAULT_OPACITY = 50

const MedicalNeuronetsContext = createContext<IMedicalNeuronets>({
  features: [],
  infoMessage: '',
  isLoading: false,
  opacity: DEFAULT_OPACITY,
  setHoveredClass: () => {},
  setOpacity: () => {},
})

export const useMedicalNeuronetsContext = () => useContext(MedicalNeuronetsContext)

/** Props for MedicalNeuronetsProvider */
type Props = {
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
  /** Идентификатор текущего случая */
  caseId: number
  /** Идентификатор текущего слайда */
  slideId: number
  /** Флаг, отслеживающий видимость панели */
  isVisible: boolean
}

export const MedicalNeuronetsProvider: React.FC<Props> = ({ caseId, children, isVisible, slideId, viewerId }) => {
  const [features, setFeatures] = useState<Feature<Polygon>[]>([])
  const [opacity, setOpacity] = useState(DEFAULT_OPACITY)
  const [hoveredClass, setHoveredClass] = useState<string | undefined>()

  const { data: dataLayers, isLoading: allLayersLoading } = useSlideDataLayers({ caseId, slideId })
  const medicalNeuronetsDataLayer = dataLayers?.find(
    (dataLayer) => isVisible && dataLayer.type === DataLayerTypes.MEDICALNEURONETS_CRC,
  )
  const { data: medicalNeuronetsGeoJSON, isLoading: currentLayerLoading } = useCurrentDataLayer({
    caseId,
    slideDataLayerId: medicalNeuronetsDataLayer?.slideDataLayerId,
    slideId,
  })

  const { openViewerIds } = useOpenViewers()

  const getToolInfoText = useMemo(() => {
    const isLayerInProgress = medicalNeuronetsDataLayer && medicalNeuronetsDataLayer.inProgress
    const isLayerEmptyResult = medicalNeuronetsDataLayer && medicalNeuronetsDataLayer.emptyResult
    const isLayerStatusFailed =
      medicalNeuronetsDataLayer && medicalNeuronetsDataLayer?.status === DatalayerStatus.FAILED
    const { infoDisabledText, infoEmptyResultText, infoInProgressText, infoMaxViewCountText, infoStatusFailedText } =
      ML_TOOLS_MAP['MEDICALNEURONETS_CRC']

    if (openViewerIds.length !== 1) {
      return infoMaxViewCountText
    }
    if (isLayerInProgress) {
      return infoInProgressText
    }
    if (isLayerStatusFailed) {
      return infoStatusFailedText
    }
    if (isLayerEmptyResult) {
      return infoEmptyResultText
    }
    if (!medicalNeuronetsDataLayer) {
      return infoDisabledText
    }
  }, [openViewerIds.length, medicalNeuronetsDataLayer])

  useEffect(() => {
    if (medicalNeuronetsGeoJSON) {
      const features = new GeoJSON().readFeatures(medicalNeuronetsGeoJSON)
      setFeatures(features)
    } else {
      setFeatures([])
    }
  }, [medicalNeuronetsGeoJSON])

  return (
    <MedicalNeuronetsContext.Provider
      value={{
        features,
        hoveredClass,
        infoMessage: getToolInfoText || '',
        isLoading: allLayersLoading || currentLayerLoading,
        opacity,
        setHoveredClass,
        setOpacity,
      }}
    >
      {children}
    </MedicalNeuronetsContext.Provider>
  )
}
