import { useUserStatusContext } from 'features/multiplayer/lib'
import { useMemo } from 'react'
import { getUserColorStyles } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledFollowedUserLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 4px 8px;
`

const FollowedUserLabel = () => {
  const { targetUserId, usersStatuses } = useUserStatusContext()

  const followedUser = useMemo(() => usersStatuses.find((it) => it.uid === targetUserId), [targetUserId, usersStatuses])

  if (!followedUser) return null

  return (
    <StyledFollowedUserLabel style={getUserColorStyles(followedUser.uid)}>{followedUser.name}</StyledFollowedUserLabel>
  )
}

export default FollowedUserLabel
