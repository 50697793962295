import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { permissionsService } from 'entities/permissions'
import { notices } from 'features/notices'
import { useWorkspacesQuery } from 'features/workspace'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { HttpStatus } from 'shared/api/httpStatusCode'
import { setInviteTokenToStorage } from 'shared/lib/local-storage'
import { PageHeader } from 'shared/ui/header'
import { SpaceElement, SpinElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import IShareToken from 'types/IShareToken'

const StyledLogin = styled.div`
  background-color: var(--color-bg-1);
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

enum AccessStatus {
  ALREADY_HAVE_ACCESS = 'ALREADY_HAVE_ACCESS',
  CAN_BE_ACTIVATED = 'CAN_BE_ACTIVATED',
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  DISABLED = 'DISABLED',
}
export const InviteRoute = () => {
  const [inviteToken, setToken] = useState<IShareToken | null>(null)
  const userToken = useTypedSelector((state) => state.user.token)
  const { params } = useRouteMatch<{ token: string }>()
  const dispatch = useDispatch()
  const currentLocation = useLocation()
  const { data: workspaces } = useWorkspacesQuery()
  const workspaceId = useCurrentWorkspaceId()

  const { t } = useTranslation()

  useEffect(() => {
    if (!userToken) {
      setInviteTokenToStorage(params.token)
      notices.info({
        key: 'UnauthorizedInvite',
        message: t('Пожалуйста, авторизуйтесь для просмотра случая.'),
        placement: 'topRight',
      })
      dispatch(
        push('/login', {
          referrer: currentLocation,
        }),
      )
      return
    }
    if (!workspaceId) return
    permissionsService
      .getAccessRightByCasePermission(params.token)
      .then((res) => {
        res && setToken(res)
        return res
      })
      .then(({ caseId, status }) => {
        if (status === AccessStatus.ALREADY_HAVE_ACCESS) {
          setTimeout(() => {
            dispatch(push(`/workspace/${workspaceId}/viewer/${caseId}`))
          }, 1500)
        }
        if ([AccessStatus.EXPIRED, AccessStatus.DISABLED, AccessStatus.INVALID].includes(status as AccessStatus)) {
          setTimeout(() => {
            dispatch(push(`/workspace/${workspaceId}/cases/`))
          }, 1500)
        }
      })
      .catch((error) => {
        const errorStatus = error?.response?.data?.status
        if (errorStatus === HttpStatus.UNAUTHORIZED) {
          notices.info({
            key: 'UnauthorizedInvite',
            message: t('Пожалуйста, авторизуйтесь для просмотра случая.'),
            placement: 'topRight',
          })
        } else {
          notices.error({
            message: t('Ссылка-приглашение в случай устарела'),
          })
        }
        setTimeout(() => {
          dispatch(push(`/workspace/${workspaceId}/cases`))
        }, 1500)
      })
  }, [dispatch, params.token, userToken, workspaceId, workspaces])

  const statusTextMapping = {
    [AccessStatus.ALREADY_HAVE_ACCESS]: t('Открываем случай'),
    [AccessStatus.DISABLED]: t('У вас нет разрешения на просмотр этого случая'),
    [AccessStatus.EXPIRED]: t('Этим приглашением уже воспользовался другой пользователь'),
    [AccessStatus.INVALID]: t('У вас ссылка невалидна'),
  }

  const inviteTokenStatus = inviteToken?.status
  const contentToRender =
    inviteTokenStatus && inviteTokenStatus !== AccessStatus.CAN_BE_ACTIVATED && statusTextMapping[inviteTokenStatus]

  return (
    <StyledLogin>
      <PageHeader />
      <SpaceElement size={100} />
      <Content>
        {contentToRender && (
          <>
            <SpinElement />
            <SpaceElement size={100} />
            <TitleElement level={1}>{contentToRender}</TitleElement>
          </>
        )}
      </Content>
    </StyledLogin>
  )
}
