import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'
import { COLON_WITH_SPACE } from 'shared/text-constants'
import Highlighted from 'shared/ui/highlighted'
import { IconElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

type Props = {
  /** Объект кейса */
  option: ICase
  /** Строка, по которой осуществляется поиск кейса */
  searchValue: string
  /** Обработчик клика на кейс */
  onClick: (option: ICase) => void
}

export const SearchOption = ({ onClick, option, searchValue }: Props) => {
  const { biomaterial, name, orderNumber, patient, status } = option || {}
  const { birthDate, fullname } = patient || {}
  const localisationNames = option.casePathologicalInfo?.sites?.map((item) => item?.site?.name)

  const { t } = useTranslation()

  return (
    <StyledOption onClick={() => onClick(option)}>
      <StyledOptionContainer>
        <StyledRow>
          {status === 'ARCHIVE' && (
            <IconWrapper>
              <IconElement size={'md'} name="archiveSmall" />
              &nbsp;
            </IconWrapper>
          )}
          <Highlighted text={name} search={searchValue} />
          &nbsp; &nbsp;
          {birthDate && <TextElement type="secondary">{formatDateForDisplay(birthDate)}</TextElement>}
        </StyledRow>
        {orderNumber && (
          <StyledRow>
            {t('Номер заказа')}
            {COLON_WITH_SPACE}
            <Highlighted text={orderNumber} search={searchValue} />
          </StyledRow>
        )}
        {biomaterial && (
          <StyledRow data-testid="search-case-field-biomaterial">
            {t('Номер пробы (МИС)')}
            {COLON_WITH_SPACE}
            <Highlighted data-testid="search-case-field-biomaterial-value" text={biomaterial} search={searchValue} />
          </StyledRow>
        )}
        <StyledRow>
          <PatientInfo>
            {patient ? (
              <PatientName>
                <Highlighted text={fullname || t('Имя пациента скрыто')} search={searchValue} />
              </PatientName>
            ) : (
              <PatientName>{t('Имя пациента не указано')}</PatientName>
            )}
            {localisationNames && localisationNames.length > 0 && (
              <>
                <PatientName>∙</PatientName>
                <PatientName>{localisationNames.join(', ')}</PatientName>
              </>
            )}
          </PatientInfo>
        </StyledRow>
      </StyledOptionContainer>
    </StyledOption>
  )
}

const StyledOption = styled.div`
  cursor: pointer;
  padding: 8px;
  font-size: 12px;
  margin-bottom: 12px;
  color: var(--color-text-3);

  &:hover {
    background-color: var(--color-bg-3);
    border-radius: 4px;
  }
`
const StyledOptionContainer = styled.span`
  display: flex;
  align-items: center;
  flex-flow: wrap;
`
const StyledRow = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
`
const IconWrapper = styled.div`
  min-width: 19px;
  height: 19px;
  color: var(--color-text-3);
`

const PatientInfo = styled.div`
  display: flex;
  gap: 8px;

  .ant-typography {
    line-height: 14px;
  }
`
const PatientName = styled(TextElement)`
  height: 15px;
  overflow: hidden;
  word-wrap: break-word;
  color: var(--color-text-3) !important;
`
