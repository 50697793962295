import { useTypedSelector } from 'app/redux/lib/selector'
import mitosisZoomHelp from 'assets/thumbnails/mitisisZoomHelp.svg'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageElement } from 'shared/ui/kit'
import styled from 'styled-components'
import {
  SCREEN_RECORD_ZOOM_LVL,
  useObjectsCountingContext,
} from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

import { countingTypesText } from './countingTypes'

const StyledImageElement = styled(ImageElement)`
  height: 88px;
  background: none;
  margin-bottom: 16px;
`
const HintText = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--color-text-1);
  max-width: 232px;
`

const Wrapper = styled.div`
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 0;
`

const ZoomHelp: React.FC = memo(() => {
  const { data } = useObjectsCountingContext()
  const { t } = useTranslation()
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType) || 'OBJECTS'

  const isAnySpot = Object.keys(data).length > 0

  return (
    <Wrapper>
      {!isAnySpot && <StyledImageElement src={mitosisZoomHelp} />}
      <HintText>
        {`
        ${
          !isAnySpot
            ? t(countingTypesText[countingObjectType].textToStart)
            : t(countingTypesText[countingObjectType].textToContinue)
        } ${SCREEN_RECORD_ZOOM_LVL}×`}
      </HintText>
    </Wrapper>
  )
})

export default ZoomHelp
