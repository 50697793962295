import { AdaptiveThumbnailListContainer } from 'features/thumbnails'
import LeftPanelWrapper from 'pages/viewer/ui/common/LeftPanelWrapper'
import { ForwardedRef } from 'react'
import { ICase } from 'types/ICase'

type Props = {
  caseResult?: ICase
  leftRef: ForwardedRef<HTMLDivElement>
}

const LeftPanelContainer = ({ caseResult, leftRef }: Props) => (
  <LeftPanelWrapper leftRef={leftRef}>
    {!!caseResult?.caseId && <AdaptiveThumbnailListContainer caseId={caseResult.caseId} />}
  </LeftPanelWrapper>
)

export default LeftPanelContainer
