import { useTypedSelector } from 'app/redux/lib/selector'
import { Feature } from 'ol'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import React, { useEffect, useRef } from 'react'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import { useViewerMainSelector } from 'viewer/container/lib/useViewerSelector'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'
import { defaultStyle } from 'viewer/map/layers/olStyles'

import Ki67HoverInteraction from './HoverInteraction'
import Ki67SelectInteraction from './SelectInteraction'

type Props = {
  features: Feature<any>[]
  map: IMapOl
  viewerId: TViewerId
}

const VectorKi67Layer = ({ features, map, viewerId }: Props) => {
  const layer = useRef<VectorLayer<any>>(
    new VectorLayer({
      source: new VectorSource({}),
      style: [defaultStyle],
      zIndex: 10000,
    }),
  )
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)

  useEffect(() => {
    layer.current.set(INTERNAL_TYPE_ID, LayerType.KI_67)
    map.addLayer(layer.current)
    return () => {
      map.removeLayer(layer.current)
    }
  }, [])

  useEffect(() => {
    const source = new VectorSource({
      features,
    })
    layer.current?.setSource(source)
  }, [features])
  return map && selectedAnnotationsIds?.length === 0 && !annotationType ? (
    <>
      <Ki67HoverInteraction map={map} />
      <Ki67SelectInteraction viewerId={viewerId} features={features} layer={layer.current} map={map} />
    </>
  ) : null
}

export default VectorKi67Layer
