import { useViewerRoute } from 'pages/viewer/lib/common/hooks/useViewerRoute'
import { selectAtlasViewerUrlSlideId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import NoSlides from 'pages/viewer/ui/common/NoSlides'
import ViewerLayout from 'pages/viewer/ui/common/ViewerLayout'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AtlasLeftPanelContainer from './tree/AtlasLeftPanelContainer'
import AtlasRightPanelContainer from './tree/AtlasRightPanelContainer'
import AtlasTopToolbarContainer from './tree/AtlasTopToolbarContainer'

const AtlasViewerContainer = () => {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const isAtlasViewer = !!useSelector(selectAtlasViewerUrlSlideId)
  const { isNoSlide } = useViewerRoute()
  useEffect(
    () => () => {
      dispatch(viewerPageSlice.actions.resetAllViewersState())
    },
    [],
  )
  if (isNoSlide) {
    return <NoSlides onClick={() => {}} />
  }
  return isAtlasViewer ? (
    <ViewerLayout
      leftRef={ref.current}
      topSlot={<AtlasTopToolbarContainer />}
      leftSlot={<AtlasLeftPanelContainer leftRef={ref} />}
      rightSlot={<AtlasRightPanelContainer />}
    />
  ) : null
}

export default AtlasViewerContainer
