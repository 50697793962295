import { notices } from 'features/notices'
import * as jsts from 'jsts'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { Geometry, LinearRing, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import OpenLayersMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import { fromRadiansToDegrees } from 'pages/viewer/lib/helper'
import i18next from 'shared/lib/i18n/i18n'
import { AnnotationType, IAnnotation, INewAnnotationDataObject } from 'types/IAnnotations'
import { formBorderGeometry, MIN_POLYGON_COORDINATES } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'

import { INTERNAL_TYPE_ID, LayerType } from './MapConstants'

const t = i18next.t
export const getFeaturesFromGeoJson = (geoJson: any = '') => new GeoJSON().readFeatures(geoJson)
export const getSingleFeatureFromGeoJson = (geoJson: any = '') => new GeoJSON().readFeature(geoJson)

export const removeLayersByInternalType = (map: OpenLayersMap, type: LayerType) => {
  const layers = [...map.getLayers().getArray()]
  layers.forEach((layer) => {
    if (layer && layer.get(INTERNAL_TYPE_ID) === type) {
      map.removeLayer(layer)
    }
  })
}
export const getAnnotationLayers = (map: OpenLayersMap) => {
  const layers = [...map.getLayers().getArray()]
  const res: VectorLayer<any>[] = []
  layers.forEach((layer) => {
    if (layer && layer.get(INTERNAL_TYPE_ID) === LayerType.ANNOTATIONS) {
      res.push(layer as any)
    }
  })
  return res
}

export const getCenterOfExtent = (Extent: any) => {
  const X = Extent[0] + (Extent[2] - Extent[0]) / 2
  const Y = Extent[1] + (Extent[3] - Extent[1]) / 2
  return [X, Y]
}

export const isFeaturesIncludePolygon = (features?: Feature<any>[]) => {
  const polygonFeature = features?.find(
    (item) =>
      item.get('annotation_type') === AnnotationType.POLYGON ||
      item.get('annotation_type') === AnnotationType.PEN ||
      item.get('annotation_type') === AnnotationType.ARROW ||
      item.get('annotation_type') === AnnotationType.RULER,
  )
  return !!polygonFeature
}
export const isFeaturesIncludeArrowOrRuler = (features?: Feature<any>[]) => {
  const polygonFeature = features?.find(
    (item) =>
      item.get('annotation_type') === AnnotationType.ARROW || item.get('annotation_type') === AnnotationType.RULER,
  )
  return !!polygonFeature
}
export const isAnnotationIncludePolygon = (annotation?: IAnnotation) => {
  const isInclude =
    annotation?.type === AnnotationType.POLYGON ||
    annotation?.type === AnnotationType.PEN ||
    annotation?.type === AnnotationType.ARROW ||
    annotation?.type === AnnotationType.RULER
  return isInclude
}
export const getClearFeature = (data?: INewAnnotationDataObject) => {
  const formattedFeature = data?.formattedFeature

  if (!formattedFeature) {
    return { type: 'ANNOTATION' }
  }

  const feature = getFeaturesFromGeoJson(formattedFeature)[0]
  feature?.set(`annotation_type`, undefined)
  return {
    formattedFeature: new GeoJSON().writeFeature(feature),
    type: 'ANNOTATION',
  }
}
export const getArrowPositionFromDegree = (degrees: number) => {
  if (0 < degrees && degrees <= 90) return 'top-left'
  else if (90 < degrees && degrees <= 180) return 'top-right'
  else if (-180 < degrees && degrees <= -90) return 'bottom-right'
  else if (-90 < degrees && degrees <= 0) return 'bottom-left'
  return 'auto'
}
export const coordinatesHandler = (geometry: Geometry, isArrow?: boolean) =>
  isArrow
    ? // @ts-ignore
      [geometry.flatCoordinates[0], geometry.flatCoordinates[1]]
    : getCenterOfExtent(geometry.getExtent())
export const getArrowDegree = (geometry: Geometry) => {
  // @ts-ignore
  const res_1 = geometry.flatCoordinates[0] - geometry.flatCoordinates[2]
  // @ts-ignore
  const res_2 = geometry.flatCoordinates[1] - geometry.flatCoordinates[3]

  const output = Math.atan2(res_2, res_1)
  return -fromRadiansToDegrees(output)
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min)) + min
}

export const drawPolygonOnMap = (map: OpenLayersMap, coordinates: any, layer: VectorLayer<any>) => {
  if (layer.getSource()) {
    const features = layer.getSource().getFeatures()
    features.forEach((feature: any) => {
      layer.getSource().removeFeature(feature)
    })
  }
  const polygonCoordinates = [
    [Number(coordinates.x1), Number(coordinates.y1)],
    [Number(coordinates.x2), Number(coordinates.y1)],
    [Number(coordinates.x2), Number(coordinates.y2)],
    [Number(coordinates.x1), Number(coordinates.y2)],
    [Number(coordinates.x1), Number(coordinates.y1)],
  ]

  const polygonFeature = new Feature(new Polygon([polygonCoordinates]))
  const geometry = polygonFeature.getGeometry()

  const source = new VectorSource({
    features: [polygonFeature],
  })

  const style = new Style({
    fill: new Fill({
      color: 'rgba(64, 153, 247, 0.1)',
    }),
    stroke: new Stroke({
      color: '#4099F7',
      width: 3,
    }),
  })

  layer.setSource(source)
  layer.setStyle(style)

  layer.setMap(map)
  window.setTimeout(() => {
    if (geometry)
      map.getView().fit(geometry.getExtent(), {
        duration: 500,
        padding: [100, 100, 100, 100],
      })
  }, 0)
  const resolution = map.getView().getResolution()
  if (resolution) map.getView().setResolution(resolution + 1)
  if (geometry)
    map.getView().animate({
      center: getCenterOfExtent(geometry.getExtent()),
      duration: 500,
    })
}

/** Является ли аннотация полигоном */
export const isPolygonAnnotation = (annotationOrType?: IAnnotation | IAnnotation['type']): boolean =>
  annotationOrType === AnnotationType.POLYGON ||
  annotationOrType === AnnotationType.PEN_POLYGON ||
  annotationOrType === AnnotationType.PEN ||
  (typeof annotationOrType === 'object' && annotationOrType.type === AnnotationType.POLYGON)
/** Является ли аннотация мультполигоном */
export const isMultiPolygonAnnotation = (formattedFeature?: string): boolean => {
  const feature = getSingleFeatureFromGeoJson(formattedFeature)
  if (getCoordinatesLength(feature.getGeometry()) <= MIN_POLYGON_COORDINATES) return false
  return ['MultiLineString', 'MultiPolygon'].includes(feature.getGeometry().getType())
}
/** Имеет ли аннотация вложенную геометрию */
export const isNestedGeometryAnnotation = (formattedFeature?: string): boolean => {
  const parser = new jsts.io.OL3Parser()
  //@ts-ignore
  parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)

  const feature = getSingleFeatureFromGeoJson(formattedFeature)

  if (getCoordinatesLength(feature.getGeometry()) <= MIN_POLYGON_COORDINATES) return false
  const jstsGeometry = parser.read(feature.getGeometry())
  const geometryN = jstsGeometry.getGeometryN(0)
  // @ts-ignore
  return geometryN?._holes ? !!geometryN.getNumInteriorRing() : false
}
export const getDisunionGeom = (JstsGeom: jsts.geom.Geometry[]) =>
  JstsGeom.reduce((acc) => {
    // @ts-ignore
    if (!acc._geometries) {
      notices.error({
        message: t('Не мультиполигон'),
      })
      throw Error
    }
    // @ts-ignore
    return acc._geometries
  }, JstsGeom[0])

export const getUnionGeom = (featuresJstsGeom: jsts.geom.Geometry[]) =>
  featuresJstsGeom.reduce((acc, feature, i) => {
    acc = i > 0 ? acc.union(feature) : acc
    return acc
  }, featuresJstsGeom[0])

export const getInteriorGeom = (featuresJstsGeom: jsts.geom.Geometry[]) => {
  const parser = new jsts.io.OL3Parser()
  //@ts-ignore
  parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)
  return featuresJstsGeom.map((acc) => {
    //Получаем количество внутренних контуров
    // @ts-ignore
    const numberOfInteriors: number = acc.getGeometryN(0).getNumInteriorRing()
    if (!numberOfInteriors) {
      notices.error({
        message: t('Нет внутренних контуров'),
      })
      throw Error
    }
    const arrOfNumbers = Array.from(Array(numberOfInteriors).keys())
    // Получаем фигуру внешнего контура
    // @ts-ignore
    const outerLinearRing = acc.getGeometryN(0).getExteriorRing(0)
    // Получаем массив фигур внутреннего контура
    const arrOfInnerLinearRing = arrOfNumbers.map((item) => {
      // @ts-ignore
      const innerLinearRing = acc.getGeometryN(0).getInteriorRingN(item)
      return formBorderGeometry(innerLinearRing, parser)
    })
    return [formBorderGeometry(outerLinearRing, parser), ...arrOfInnerLinearRing]
  }, featuresJstsGeom[0])
}
export const getExcludeGeom = (featuresJstsGeom: jsts.geom.Geometry[]) =>
  featuresJstsGeom.reduce((acc, feature, i) => {
    acc = i > 0 ? acc.symDifference(feature) : acc
    return acc
  }, featuresJstsGeom[0])

export const simplifyJstsGeom = (mergeRes: jsts.geom.Geometry | jsts.geom.Geometry[][], tolerance: number) => {
  const parser = new jsts.io.OL3Parser()
  //@ts-ignore
  parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)
  return (mergeRes as Array<jsts.geom.Geometry[]>).flat().map((item) => {
    const localGeom = parser.write(item)
    const coordinates =
      localGeom.getCoordinates().length === 1 ? localGeom.getCoordinates()[0] : localGeom.getCoordinates()
    const linerString = new LineString(coordinates)
    return linerString.simplify(tolerance)
  })
}
export const simplifyOperation = (tolerance: number, annotation: IAnnotation | string) => {
  const parser = new jsts.io.OL3Parser()
  //@ts-ignore
  parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)
  const formattedFeature = typeof annotation === 'string' ? annotation : annotation.data?.formattedFeature
  const updatedFeature = getSingleFeatureFromGeoJson(formattedFeature)
  const featuresJstsGeom = [updatedFeature].map((feature) => parser.read(feature.getGeometry()))
  const geometry = updatedFeature.getGeometry()

  if (tolerance === 0) {
    /** Возвращаем исходную геометрию **/
    return geometry
  } else if (isMultiPolygonAnnotation(formattedFeature)) {
    /** Разъединяем мультиполигон **/
    const mergeRes = getDisunionGeom(featuresJstsGeom)
    /** Упрощаем **/
    const simplifyGeomArray = simplifyJstsGeom(mergeRes, tolerance)
    const simplifiedJstsGeom = simplifyGeomArray.map((geom: any) => parser.read(geom))
    /** Объединяем **/
    const simplifiedMergeRes = getUnionGeom(simplifiedJstsGeom)
    return parser.write(simplifiedMergeRes)
  } else if (isNestedGeometryAnnotation(formattedFeature)) {
    /** Разъединяем вложенную геометрию **/
    const mergeRes = getInteriorGeom(featuresJstsGeom)
    /** Упрощаем **/
    const newGeomArray = simplifyJstsGeom(mergeRes, tolerance)
    const simplifiedJstsGeom = newGeomArray.map((geom: any) => parser.read(geom))
    /** Вычитаем **/
    const simplifiedMergeRes = getExcludeGeom(simplifiedJstsGeom)
    return parser.write(simplifiedMergeRes)
  } else {
    /** Получаем геометрию полигона **/
    const coordinates: number[] =
      geometry instanceof LineString ? geometry.getCoordinates() : geometry.getCoordinates()[0]
    const linerString = new LineString(coordinates)
    /** Упрощаем **/
    const newGeometry = linerString.simplify(tolerance)
    const newCoordinates = (newGeometry as MultiPoint).getCoordinates()
    /** Формируем полигон из новой геометрии */
    const polygon = new Feature(new Polygon([newCoordinates]))
    return polygon.getGeometry()
  }
}
export const getCoordinatesLength = (feature?: Polygon) => {
  if (!feature) return 0
  const coordinates =
    feature.getType() === AnnotationType.MULTIPOLYGON ? feature.getCoordinates()[0][0] : feature.getCoordinates()[0]
  return coordinates.length
}

export const getFormattedFeature = (sketch: Feature<Polygon>, simplifyMode?: boolean) => {
  const formattedFeature = new GeoJSON().writeFeature(sketch)
  if (!simplifyMode) return formattedFeature
  const newGeom: Polygon = simplifyOperation(1, formattedFeature)
  sketch.setGeometry(newGeom)
  return new GeoJSON().writeFeature(sketch)
}
