import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

import { KeyHint } from './KeyHint'
import { MouseHint } from './MouseHint'

type Props = {
  icon?: any
  title: string
  key1?: string
  key2?: string
  key3?: string
  text1?: string
  text2?: string
  mouse?: any
}

const HotkeyRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 32px;
  padding: 0 4px;
  border-radius: 5px;

  &:hover {
    background: var(--color-bg-3);
  }
`

const Title = styled.div`
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-1);
`

export const HotkeyRow = ({ icon, key1, key2, key3, mouse, text1, text2, title }: Props) => (
  <HotkeyRowWrapper>
    {icon && <IconElement fill={'var(--color-text-3)'} name={icon} />}
    <Title>{title}</Title>
    <KeyHint key1={key1} key2={key2} key3={key3} text1={text1} text2={text2} />
    {mouse && <MouseHint name={mouse} />}
  </HotkeyRowWrapper>
)
