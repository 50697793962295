import InputNumber, { InputNumberProps } from 'antd/es/input-number'
import styled from 'styled-components/macro'

import { IconElement } from './IconElement'

const StyledInputNumber = styled(InputNumber)`
  background: var(--color-bg-3);
  color: var(--color-text-1);

  .ant-input-number-input {
    padding: 4px 8px;
    line-height: 16px;
    height: auto;
    border-radius: 0px;
  }
  .ant-input-number-no-outrange-recolor input {
    color: var(--color-text-1);
  }
  .ant-input-number-handler-wrap {
    background: transparent;
  }
  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 50%;
  }
  .ant-input-number-handler-down:hover,
  .ant-input-number-handler-up:hover {
    height: 50% !important;
  }
  .ant-input-number-handler-up-inner {
    top: 8px;
  }
  .ant-input-number-handler-down-inner {
    top: 5px;
  }
  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    color: var(--color-text-3);
    height: 8px;
  }
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: var(--color-text-1);
  }
  .ant-input-number-handler:active {
    background: transparent;
  }
  &.ant-input-number:focus,
  &.ant-input-number-focused {
    box-shadow: 0 0 0 1px var(--color-purple);
  }
`

export const InputNumberElement = (props: InputNumberProps<string | number>) => (
  <StyledInputNumber
    controls={{
      downIcon: <IconElement size="xs" name="numberDown" />,
      upIcon: <IconElement size="xs" name="numberUp" />,
    }}
    {...props}
  />
)
export const InputPasswordElement = (props: InputNumberProps) => <StyledInputNumber {...props} />
