import { useTypedSelector } from 'app/redux/lib/selector'
import { ModalComponent } from 'shared/ui/modal'
import styled from 'styled-components'
import { IPatientDTO } from 'types/IPatient'

import { PatientForm } from './PatientForm'

type Props = {
  closeHandler: () => void
  onSuccess?: (patient: IPatientDTO) => void
}

const StyledModal = styled(ModalComponent)`
  .ant-modal-body {
    width: 592px;
  }
  & .ant-modal-content .ant-modal-body {
    padding: 16px;
  }
`

export const PatientFormModal = ({ closeHandler, onSuccess }: Props) => {
  const patientModal = useTypedSelector((state) => state.cases.patientModal)
  return (
    <StyledModal visible={patientModal} footer={null} closable={false} destroyOnClose>
      <PatientForm
        onSuccess={(patient) => {
          if (patient !== undefined && onSuccess !== undefined) {
            onSuccess(patient)
          }
          closeHandler()
        }}
        initialValues={{ sex: 'MALE' }}
      />
    </StyledModal>
  )
}
