import { useCaseLinkQuery } from 'features/case-info/api/query'
import FileUploadContainer from 'features/new-preview-panel/panel-preview-components/FileUploadContainer'
import PanelPreviewContainer from 'features/new-preview-panel/panel-preview-components/PanelPreviewContainer'
import WarningPreview from 'features/new-preview-panel/WarningPreview'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import React, { useEffect } from 'react'
import i18next from 'shared/lib/i18n/i18n'
import { EmptyCases } from 'shared/ui/empty-case/EmptyCases'
import { Center, SpinElement } from 'shared/ui/kit'
import { ECaseLinkFilter } from 'types/ICase'
import ISlide, { SlideState } from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'

const t = i18next.t

/** Возвращает слайды имеющие статус PROCESSING и не имеющие блока */
function getProcessingSlides(slideGrouped?: ISlideGrouped[]): ISlide[] {
  return (
    slideGrouped?.reduce<ISlide[]>((acc, item) => {
      if (!item.block) {
        const processingSlides = item.slides.filter((slide) => slide.state === SlideState.PROCESSING)
        return acc.concat(processingSlides)
      }
      return acc
    }, []) || []
  )
}

const ImagesTabContent = ({ caseId, declinementComment }: { declinementComment?: string; caseId: number }) => {
  const { filesList, isLoadingCaseGrouped, slideGrouped } = useSlideGrouped(caseId)
  const processingSlides = getProcessingSlides(slideGrouped)
  const {
    data: selectedRelated,
    isLoading: isLoadingCaseLink,
    refetch: refetchCasesLink,
  } = useCaseLinkQuery({ caseId, caseLinkType: ECaseLinkFilter.PARENT })

  useEffect(() => {
    refetchCasesLink()
  }, [caseId])

  if (isLoadingCaseGrouped || isLoadingCaseLink) {
    return (
      <Center>
        <SpinElement style={{ margin: 32 }} />
      </Center>
    )
  }

  return (
    <div>
      {declinementComment && <WarningPreview title={declinementComment} />}
      <div style={{ display: 'flex', flexWrap: 'wrap', margin: `0 16px` }}>
        {(!!filesList.length || !!processingSlides?.length) && (
          <FileUploadContainer filesList={filesList} processingSlides={processingSlides} />
        )}
        {!slideGrouped?.length ? (
          <Center>
            <EmptyCases selectedRelated={selectedRelated} />
          </Center>
        ) : (
          slideGrouped?.map((item, index) => <PanelPreviewContainer key={index} blocks={item.slides} caseId={caseId} />)
        )}
      </div>
    </div>
  )
}

export default ImagesTabContent
