import { Row } from 'antd'
import { useEffect, useState } from 'react'
import { AvatarElement, CheckboxElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  /** Id пользователя */
  value: number
  /** Имя пользователя */
  fullname: string
  /** Обработчик клика по пользователю */
  onClick?: () => void
  /** Обработчик выбора пользователя */
  onSelect: (value: number) => void
  /** Признак выбора */
  checked?: boolean
}

export const UserInfoSelectItem = ({ checked = false, fullname, onClick, onSelect, value }: Props) => {
  const [isSelected, setSelected] = useState<boolean>(checked)
  const onUserSelect = () => {
    setSelected(!isSelected)
    onSelect(value)
  }

  useEffect(() => {
    setSelected(checked)
  }, [checked])

  return (
    <Item onClick={onClick}>
      <TooltipElement title={fullname}>
        <UserWrapper>
          <UserAvatar numberValue={value} fullname={fullname} size={'small'} />
          <UserPrimaryRow>{fullname}</UserPrimaryRow>
        </UserWrapper>
      </TooltipElement>
      <StyledRow>
        <Actions>
          <CheckboxElement checked={isSelected} onClick={onUserSelect} />
        </Actions>
      </StyledRow>
    </Item>
  )
}

export const Item = styled.div`
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

const UserPrimaryRow = styled.span`
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-wrap: nowrap;
  color: var(--color-text-1);
  text-overflow: ellipsis;
`

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex-shrink: 1;
  min-width: 0;
`

const UserAvatar = styled(AvatarElement)`
  vertical-align: middle;
  margin-right: 8px;
  flex-shrink: 0;
`

const StyledRow = styled(Row)`
  flex-shrink: 0;
`
export const State = styled.div`
  display: flex;
  align-items: center;
`
export const Actions = styled.div`
  display: grid;
  place-items: center;
`
