export const getExternalIdUrl = (url: string) => {
  const newUrl = url.split('?')[0]
  const parsedUrl = newUrl.split('/')
  let index
  parsedUrl?.find((item, i) => {
    if (item === 'external') index = i
  })
  if (!index) return
  return parsedUrl.slice(index + 1).join('/')
}
export const getManagementUrl = (url: string) => {
  const parsedUrl = url.split('/')
  let index
  parsedUrl?.find((item, i) => {
    if (item === 'management') index = i
  })
  if (!index) return
  return '/' + parsedUrl.slice(index).join('/')
}
