import { Row } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { push, replace } from 'connected-react-router'
import { CasesListContainer } from 'features/cases'
import { useCaseQuery } from 'features/cases/api/query'
import CasesRightPanelContainer from 'features/cases/CasesRightPanelContainer'
import { NoticesList } from 'features/notifications/NotificationsList'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import { RefObject, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { useDebounce, useQueryParams } from 'shared/lib/hooks'
import { isGuestHandler } from 'shared/lib/local-storage'
import { ContentHeaderElement, IconElement, SpaceElement, StyledText, TextElement, TitleElement } from 'shared/ui/kit'
import styled, { css } from 'styled-components/macro'

const Content = styled.div`
  flex: 3;
  min-width: 650px;
`

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
`
const activeCss = css`
  background: var(--color-bg-3);
  color: var(--color-text-1);
  ${StyledText} {
    color: var(--color-text-1);
  }
`
const MenuItem = styled.div<{ active: boolean }>`
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: var(--color-text-3);
  transition: color 0.3s;
  ${StyledText} {
    transition: color 0.3s;
    color: var(--color-text-3);
    margin-left: 12px;
  }
  padding: 4px 16px 4px 8px;
  ${(props) => props.active && activeCss}
  &:hover {
    cursor: pointer;
    color: var(--color-text-1);
    ${StyledText} {
      color: var(--color-text-1);
    }
  }
`

type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

export const CasesRoute = ({ notificationButton }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const workspaceId = useCurrentWorkspaceId()
  const { url } = useRouteMatch()
  const authorities = useTypedSelector((state) => state.user.authorities)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const isGuest = isGuestHandler(authorities)
  const caseId = queryParams.get('caseId')
  const relation = queryParams.get('rel') as 'owner' | 'shared'

  const path = useMemo(() => {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }, [url])

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 1000)
  const { data } = useCaseQuery(
    { caseId: Number(caseId), source: 'PLATFORM' },
    {
      onError: () => {
        dispatch(replace(`/workspace/${workspaceId}/${path}?rel=${relation || 'owner'}`))
      },
      onSuccess: (data) => {
        const relation = queryParams.get('rel') as 'owner' | 'shared'
        const path = data?.status === 'OPEN' ? 'cases' : 'archive'
        if (!data?.status) return
        dispatch(replace(`/workspace/${workspaceId}/${path}?caseId=${caseId}&rel=${relation}`))
      },
    },
  )

  const onNavigationClick = (rel: typeof relation) => {
    dispatch(push(`/workspace/${workspaceId}/archive?rel=${rel}`))
  }

  useEffect(() => {
    const route = path !== 'archive' ? 'cases' : 'archive'
    if (isGuest && relation === 'owner') {
      dispatch(replace(`/workspace/${workspaceId}/${route}?rel=shared`))
    } else if (!caseId && !relation && workspaceId) {
      dispatch(replace(`/workspace/${workspaceId}/${route}?rel=${isGuest ? 'shared' : 'owner'}`))
    }
  }, [relation, caseId])

  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
      header={
        <ContentHeaderElement>
          <Row justify="space-between" style={{ rowGap: 8 }}>
            {path === 'archive' ? (
              <Row>
                <TitleElement level={1}>{t('Архив')}</TitleElement>
                <SpaceElement size={24} />
                {!isGuest && (
                  <MenuItem
                    data-testid="case-owner-nav-item"
                    active={relation === 'owner'}
                    onClick={() => onNavigationClick('owner')}
                  >
                    <IconElement name="Case" />
                    <TextElement>{t('Мои случаи')}</TextElement>
                  </MenuItem>
                )}
                <SpaceElement size={16} />
                <MenuItem
                  data-testid="shared-case-nav-item"
                  active={relation === 'shared'}
                  onClick={() => onNavigationClick('shared')}
                >
                  <IconElement name="inbox" />
                  <TextElement>{t('Входящие случаи')}</TextElement>
                </MenuItem>
              </Row>
            ) : (
              <TitleElement level={1}>{relation === 'owner' ? t('Мои случаи') : t('Входящие')}</TitleElement>
            )}
          </Row>
        </ContentHeaderElement>
      }
    >
      <ContentWrapper>
        <Content>
          <CasesListContainer
            relation={relation}
            selectedCaseId={data?.caseId}
            status={path !== 'archive' ? 'open' : 'archive'}
            searchQuery={debouncedSearchQuery}
            workspaceId={String(workspaceId)}
          />
        </Content>
        {Number(caseId) ? <CasesRightPanelContainer caseId={Number(caseId)} /> : null}
      </ContentWrapper>
    </ContentLayout>
  )
}
