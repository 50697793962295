import icons from 'assets/icons'
import styled, { css } from 'styled-components/macro'

export type ISize = 'xs' | 'md' | 'xm' | 'm' | 'lg' | 'xl' | 'xxl' | 'auto'

export type IIconElementProps = {
  size?: ISize
  name: keyof typeof icons
  onClick?: (event: React.MouseEvent) => void
  className?: string
  fill?: string
  /** Оставить дефотную заливку */
  defaultFill?: boolean
  /** Стиль иконки */
  style?: any
  hoverColor?: string
}

const styles = {
  auto: css`
    width: auto;
    height: auto;
  `,
  lg: css`
    width: 24px;
    height: 24px;
  `,
  m: css`
    width: 40px;
    height: 40px;
  `,
  md: css`
    width: 16px;
    height: 16px;
  `,
  xl: css`
    width: 60px;
    height: 60px;
  `,
  xm: css`
    width: 32px;
    height: 32px;
  `,

  xs: css`
    width: 8px;
    height: 8px;
  `,
  xxl: css`
    width: 80px;
    height: 80px;
  `,
}

const StyledIcon = styled.div<{ size: ISize; fill?: string; defaultFill?: boolean; hoverColor?: string }>`
  ${({ size }) => styles[size]}
  & > path {
    fill: ${({ defaultFill, fill }) => (!defaultFill ? fill || 'currentColor' : undefined)};
  }
  & > circle {
    fill: ${({ fill }) => fill || 'currentColor'};
  }
  &:hover {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : 'currentColor')};
  }
`

export const IconElement = ({
  className,
  defaultFill,
  fill,
  hoverColor,
  name,
  onClick,
  size = 'lg',
  style,
}: IIconElementProps) => {
  const Icon = icons[name]
  return (
    <StyledIcon
      style={style}
      size={size}
      as={Icon}
      onClick={onClick}
      className={className}
      fill={fill}
      defaultFill={defaultFill}
      hoverColor={hoverColor}
    />
  )
}
