import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const TextWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const OnSumpSlideRemoveNotification = () => {
  const { t } = useTranslation()
  return <TextWrapper>{t('Выбранные изображения были удалены')}</TextWrapper>
}

export default OnSumpSlideRemoveNotification
