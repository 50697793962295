import React, { useEffect } from 'react'
import { IMapOl } from 'types/IMapOl'
import ISlide from 'types/ISlide'

import { getFeatureByExtent, getViewportExtent } from './lib/helpers/helpers'
import { useObjectsCountingContext } from './ui/ObjectsCountingContext'

type Props = {
  map: IMapOl
  slide?: ISlide
}

const ViewedTrackingLayer: React.FC<Props> = ({ map, slide }) => {
  const { addViewedTrack } = useObjectsCountingContext()
  const changeCenterHandle = () => {
    const newExtent = getViewportExtent(map, slide, 0)
    if (!newExtent) return
    addViewedTrack(getFeatureByExtent(newExtent))
  }

  useEffect(() => {
    changeCenterHandle()
    map?.on('moveend', changeCenterHandle)
    return () => {
      map?.un('moveend', changeCenterHandle)
    }
  }, [])
  return null
}

export default ViewedTrackingLayer
