type TBrowserInfo = {
  /** Иконка браузера (Должна быть экспортирована в assets/icons/index.ts) */
  icon: 'yandexBrowser' | 'googleChrome'
  /** Ссылки на страницу скачивания русская и английская локализация */
  link: {
    en: string
    ru: string
  }
  /** Названия браузера для отображения */
  name: string
  /** Текст для отображения */
  text?: string
}

export const downloadBrowserInfo: TBrowserInfo[] = [
  {
    icon: 'yandexBrowser',
    link: {
      en: 'https://browser.yandex.com/old/?lang=en&banerid=6400000000',
      ru: 'https://browser.yandex.ru/',
    },
    name: 'Яндекс Браузер',
  },
  {
    icon: 'googleChrome',
    link: {
      en: 'https://www.google.com/intl/en/chrome/',
      ru: 'https://www.google.com/intl/ru/chrome/',
    },
    name: 'Chrome',
  },
]
