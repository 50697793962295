import { useTypedSelector } from 'app/redux/lib/selector'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { SettingsNameEnum } from 'types/IWorkspaces'

import SearchLeftPanelContainer from './SearchLeftPanelContainer'

const SearchContainer = () => {
  const { activeViewerId } = useOpenViewers()
  const { caseId, slideId } = useViewerIdSlideState(activeViewerId)
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  return getBooleanOrDefaultAccess(SettingsNameEnum.EnableAiSearch, true, currentWorkspace) ? (
    <SearchLeftPanelContainer caseId={caseId} slideId={slideId} />
  ) : null
}

export default SearchContainer
