import i18next from 'i18next'
import jsts from 'jsts'
import Feature from 'ol/Feature'
import { Geometry, LineString, MultiPoint, MultiPolygon, Polygon, SimpleGeometry } from 'ol/geom'
import GeometryType from 'ol/geom/GeometryType'
import Draw, { createRegularPolygon, GeometryFunction } from 'ol/interaction/Draw'
import { getArea, getLength } from 'ol/sphere'
import { Fill, RegularShape, Stroke, Style } from 'ol/style'
import Transform from 'ol-ext/interaction/Transform'
import { AnnotationType } from 'types/IAnnotations'
import { IMapOl } from 'types/IMapOl'
import { getCenterOfExtent } from 'viewer/map/lib/utils'

const THOUSAND = 1000

// eslint-disable-next-line import/no-named-as-default-member
const t = i18next.t

export const getPolygonArea = (geom: Geometry, mppX: number, isLine?: boolean) => {
  if (geom instanceof Polygon || geom instanceof MultiPolygon) {
    if (geom.getFlatCoordinates()?.length === 0) return 0
    const area = getArea(geom)
    return area * Math.pow(mppX, 2)
  } else if (geom instanceof LineString || isLine) {
    const length = getLength(geom)
    return length * mppX
  }
  return 0
}

//TODO ts-ignore из за ошибки приватности поля в ol. Удалить после поднятия версии ol
/** получаем количество точек аннотации при рисовании */
//@ts-ignore
export const getCoordinatesCount = (draw?: Draw) => (draw?.sketchLineCoords_ || []).length

export const MIN_POLYGON_COORDINATES = 3

export const isInvalidGeometry = (polygon?: Draw, isSketch?: boolean) => {
  /** При получении геометрии из события drawend, мы имеем фактическое количество вершин, без дублирующейся замыкающей координаты */
  /** Соответственно для корректной валидации геометрии необходимо увеличение на count на 1*/
  const coordinatesCount = getCoordinatesCount(polygon) + (isSketch ? 1 : 0)
  return coordinatesCount <= MIN_POLYGON_COORDINATES
}
type IGeometryFunction = {
  coordinates: any
  geometry: SimpleGeometry
  map: IMapOl
}

type ILinerRingCoordinates = {
  /** координаты по x */
  x: number
  /** координаты по y */
  y: number
  /** координаты по z */
  z: number | null
}[]
export const formBorderGeometry = (geometry: jsts.geom.Geometry, parser: jsts.io.OL3Parser) => {
  const innerCoords: ILinerRingCoordinates = geometry.getCoordinates()
  const parsedInnerCoordinates = innerCoords.map((item) => [item.x, item.y])
  const innerFeature = new Feature(new Polygon([parsedInnerCoordinates]))
  const innerGeom = innerFeature.getGeometry()
  return parser.read(innerGeom)
}
export const createPolygonAreaLabel = (area: number, type: 'area' | 'line' | 'size', withPostfix = true) => {
  if (typeof area !== 'number' || Number.isNaN(area)) {
    return '0'
  }
  let calc = 0
  if (type !== 'line') {
    if (area > THOUSAND * THOUSAND) {
      calc = Math.ceil((area / THOUSAND / THOUSAND) * 100) / 100
    }
    calc = Math.ceil((area / THOUSAND / THOUSAND) * 100) / 100
  } else {
    if (area > THOUSAND) {
      calc = Math.ceil((area / THOUSAND) * 100) / 100
    }
    calc = Math.ceil((area / THOUSAND) * 100) / 100
  }
  if (withPostfix === true) {
    return `${calc} ${type === 'area' ? t('мм²') : t('мм')}`
  } else return `${calc}`
}

export const createTextAreaLabel = (area: number, type: 'area' | 'line' | 'size', withPostfix = true) => {
  if (typeof area !== 'number' || Number.isNaN(area)) {
    return '0'
  }
  let calc = 0
  if (type !== 'line') {
    if (area > THOUSAND * THOUSAND) {
      calc = Math.ceil((area / THOUSAND / THOUSAND) * 100) / 100
    }
    calc = Math.ceil((area / THOUSAND / THOUSAND) * 100) / 100
  } else {
    if (area > THOUSAND) {
      calc = Math.ceil((area / THOUSAND) * 100) / 100
    }
    calc = Math.ceil((area / THOUSAND) * 100) / 100
  }
  if (withPostfix === true) {
    return `${calc} ${type === 'area' ? t('мм²') : t('мм')}`
  } else return `${calc}`
}

export const formatKi67Area = function (polygon: Geometry, mppX: number) {
  const area = getArea(polygon)
  const output = Math.round(area * 100 * Math.pow(mppX, 2)) / 100
  return output
}

const scaleToFixed = (PADDING: number) =>
  function (input: number[], output: number[], dimension: number) {
    for (let i = 0, ii = input.length; i < ii; i += dimension) {
      if (i === 0) {
        output[i] = input[i] - PADDING
        output[i + 1] = input[i + 1] - PADDING
      } else if (i === 2) {
        output[i] = input[i] - PADDING
        output[i + 1] = input[i + 1] + PADDING
      } else if (i === 4) {
        output[i] = input[i] + PADDING
        output[i + 1] = input[i + 1] + PADDING
      } else if (i === 6) {
        output[i] = input[i] + PADDING
        output[i + 1] = input[i + 1] - PADDING
      } else {
        output[i] = input[i] - PADDING
        output[i + 1] = input[i + 1] - PADDING
      }
    }
    // console.log(output)
  }

export const findPADDING = (extent: number[]) => {
  const gip = Math.sqrt(Math.pow(extent[2] - extent[0], 2) + Math.pow(extent[3] - extent[1], 2))
  return Math.round(gip * 0.1)
}

const circleImageStyle = new RegularShape({
  fill: new Fill({ color: [255, 255, 255, 1] }),
  points: 20,
  radius: 5,
  stroke: new Stroke({ color: '#4099F7', width: 1 }),
})

const squareImageStyle = new RegularShape({
  angle: Math.PI / 4,
  fill: new Fill({ color: [255, 255, 255, 1] }),
  points: 4,
  radius: 8,
  stroke: new Stroke({ color: '#4099F7', width: 1 }),
})

// Исходники в которых описано, почему надо так задавать стили
// https//github.com/Viglino/ol-ext/blob/master/src/interaction/Transform.js#L159

// this.style = {
//   default: createStyle(bigpt, strokedash, fill0),
//   translate: createStyle(bigpt, stroke, fill),
//   rotate: createStyle(circle, stroke, fill),
//   rotate0: createStyle(bigpt, stroke, fill),
//   scale: createStyle(bigpt, stroke, fill),
//   scale1: createStyle(bigpt, stroke, fill),
//   scale2: createStyle(bigpt, stroke, fill),
//   scale3: createStyle(bigpt, stroke, fill),
//   scalev: createStyle(smallpt, stroke, fill),
//   scaleh1: createStyle(smallpt, stroke, fill),
//   scalev2: createStyle(smallpt, stroke, fill),
//   scaleh3: createStyle(smallpt, stroke, fill),
// }

export const setTransformStyle = (transform: Transform) => {
  const PADDING = 0
  // @ts-ignore
  transform.setStyle('default', [
    new Style({
      geometry: (feature) => {
        const geom = feature.getGeometry()
        if (geom instanceof Geometry) {
          const newGeom = geom.clone()
          //@ts-ignore
          newGeom.applyTransform(scaleToFixed(PADDING))
          return newGeom
        }
        return geom
      },
      stroke: new Stroke({
        color: '#319FD3',
        width: 1,
      }),
    }),
  ])

  //// BOTTOM LEFT POINT ////
  // @ts-ignore
  transform.setStyle('scale', [
    new Style({
      image: squareImageStyle,
    }),
  ])

  //// TOP LEFT POINT ////
  // @ts-ignore
  transform.setStyle('scale1', [
    new Style({
      image: squareImageStyle,
    }),
  ])

  //// TOP RIGHT POINT ////
  // @ts-ignore
  transform.setStyle('scale2', [
    new Style({
      image: squareImageStyle,
    }),
  ])

  //// BOTTOM RIGHT POINT ////
  // @ts-ignore
  transform.setStyle('scale3', [
    new Style({
      image: squareImageStyle,
    }),
  ])

  // @ts-ignore
  transform.setStyle('scalev', [
    new Style({
      image: squareImageStyle,
    }),
  ])

  // @ts-ignore
  transform.setStyle('scaleh1', [
    new Style({
      image: squareImageStyle,
    }),
  ])

  // @ts-ignore
  transform.setStyle('scalev2', [
    new Style({
      image: squareImageStyle,
    }),
  ])

  // @ts-ignore
  transform.setStyle('scaleh3', [
    new Style({
      image: squareImageStyle,
    }),
  ])
  // @ts-ignore
  transform.setStyle('translate', [
    new Style({
      image: circleImageStyle,
    }),
  ])
}

const customGeometryFunctions = ({ coordinates, geometry, map }: IGeometryFunction) => {
  if (!geometry) {
    geometry = new Polygon([])
  }

  const firstCorner = coordinates[0]
  const thirdCorner = coordinates[1]

  const currentViewRotation = map.getView().getRotation()

  let secondCorner = [thirdCorner[0], firstCorner[1]]
  let forthCorner = [firstCorner[0], thirdCorner[1]]

  if (currentViewRotation != 0) {
    const verticesToRotate = new MultiPoint([secondCorner, forthCorner])

    const anchor = getCenterOfExtent(verticesToRotate.getExtent())
    verticesToRotate.rotate(2 * currentViewRotation, anchor)
    secondCorner = verticesToRotate.getCoordinates()[0]
    forthCorner = verticesToRotate.getCoordinates()[1]
  }

  const newCoordinates = [firstCorner, secondCorner, thirdCorner, forthCorner, firstCorner]
  geometry.setCoordinates([newCoordinates])
  return geometry
}

export const getOptionsByAnnotationType = (type: AnnotationType, map: IMapOl) => {
  let drawType = GeometryType.LINE_STRING
  let freehand = false
  let maxPoints: number | undefined = undefined
  let geometryFunction: GeometryFunction | undefined = undefined
  switch (type) {
    case AnnotationType.RULER:
      drawType = GeometryType.LINE_STRING
      freehand = false
      maxPoints = 2
      break
    case AnnotationType.ARROW:
      drawType = GeometryType.LINE_STRING
      freehand = false
      maxPoints = 2
      break
    case AnnotationType.POLYGON:
      drawType = GeometryType.POLYGON
      break
    case AnnotationType.PEN:
      drawType = GeometryType.POLYGON
      freehand = true
      break
    case AnnotationType.RECTANGLE:
      drawType = GeometryType.CIRCLE
      freehand = false
      maxPoints = 2
      geometryFunction = (coordinates, geometry) => customGeometryFunctions({ coordinates, geometry, map })
      break
    case AnnotationType.CIRCLE:
      drawType = GeometryType.CIRCLE
      freehand = false
      geometryFunction = createRegularPolygon(50)
      break
    case AnnotationType.POINT:
      drawType = GeometryType.POINT
      freehand = false
      break
  }
  return { drawType, freehand, geometryFunction, maxPoints }
}
