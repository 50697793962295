import { Feature } from 'ol'
import { Point } from 'ol/geom'
import React from 'react'
import styled from 'styled-components'
import ISlide from 'types/ISlide'

import ObjectsItemContainer from './ObjectsItemContainer'

type Props = {
  points: Feature<Point>[]
  slide?: ISlide
}

const PreviewField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
`

const ObjectsPreviewField: React.FC<Props> = ({ points, slide }) => (
  <PreviewField>
    {points.map((point) => (
      <ObjectsItemContainer key={point.get('id')} point={point} slide={slide} />
    ))}
  </PreviewField>
)

export default ObjectsPreviewField
