// /**
//  * @module ol/interaction/DoubleClickZoom
//  */
import { easeOut } from 'ol/src/easing.js'
import Interaction from 'ol/src/interaction/Interaction.js'
import MapBrowserEventType from 'ol/src/MapBrowserEventType.js'

/**
 * @typedef {Object} Options
 * @property {number} [duration=250] Animation duration in milliseconds.
 * @property {number} [delta=1] The zoom delta applied on each double click.
 * @property {function} [onEventProcessing] Callback for emitting map events by external modules

/**
 * @classdesc
 * Allows the user to zoom by double-clicking on the map.
 * @api
 */
class DoubleClickZoom extends Interaction {
  /**
   * @param {Options} [opt_options] Options.
   */
  constructor(opt_options) {
    super()

    const options = opt_options ? opt_options : {}

    /**
     * @private
     * @type {number}
     */
    this.delta_ = options.delta ? options.delta : 1

    /**
     * @private
     * @type {number}
     */
    this.duration_ = options.duration !== undefined ? options.duration : 250

    /**
     * @public
     * cause externals need to can change behavior of its functions inside annotations and comments and etc modules event prevent conditions
     * @type {function}
     */
    this.onEventProcessing = options.onEventProcessing ? options.onEventProcessing : () => {}
  }

  /**
   * Handles the {@link module:ol/MapBrowserEvent map browser event} (if it was a
   * doubleclick) and eventually zooms the map.
   * @param {import("../MapBrowserEvent.js").default} mapBrowserEvent Map browser event.
   * @return {boolean} `false` to stop event propagation.
   */
  handleEvent(mapBrowserEvent) {
    let stopEvent = false
    if (mapBrowserEvent.type == MapBrowserEventType.DBLCLICK) {
      const browserEvent = /** @type {MouseEvent} */ (mapBrowserEvent.originalEvent)
      const map = mapBrowserEvent.map
      const delta = browserEvent.shiftKey ? -this.delta_ : this.delta_
      const view = map.getView()
      const currentZoom = view.getZoom()
      if (currentZoom === undefined) {
        return
      }
      const newZoom = view.getConstrainedZoom(currentZoom + delta)
      const newResolution = view.getResolutionForZoom(newZoom)
      if (view.getAnimating()) {
        view.cancelAnimations()
      }
      view.animate({
        anchor: mapBrowserEvent.coordinate,
        duration: this.duration_,
        easing: easeOut,
        resolution: newResolution,
      })
      const interval = setInterval(() => {
        this.onEventProcessing()
      }, 40)
      setTimeout(() => {
        clearInterval(interval)
      }, this.duration_)
      browserEvent.preventDefault()
      stopEvent = true
    }
    return !stopEvent
  }
}

export default DoubleClickZoom
