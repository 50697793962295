import { push } from 'connected-react-router'
import { ISlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { fromDegreesToRadians } from 'pages/viewer/lib/helper'
import { createContext, Dispatch, useCallback } from 'react'
import { _EventBus } from 'shared/lib/EventBus'
import { IMapOl } from 'types/IMapOl'
import { IMacroSlides } from 'types/ISlide'
import TViewerId from 'types/TViewerId'

export { MapContext, MapProvider } from './ui/MapProvider'

export const MouseOverMapContext = createContext<{
  over: boolean
  setMouseOver: (over: boolean) => void
}>({
  over: false,
  setMouseOver: () => {},
})

type NextSlideHandlerProps = {
  isNext: boolean
  currentSlides: number[]
  currSlidePosition: number
  dispatch: Dispatch<any>
  changeViewerSlide: (TViewerId: TViewerId, ISlideState: ISlideState) => void
  taskId?: number
  workspaceId?: number | string
  caseId?: number
  activeViewerId?: TViewerId
  macroSlidesIds?: IMacroSlides[]
}

// use only inside map provider!
export const useMapChangeHandlers = (map: IMapOl, bus: _EventBus, viewerId: TViewerId) => {
  const view = map.getView()
  const handleChangeZoom = useCallback(
    (zoom: number) => {
      view.setResolution(zoom)
      bus.$emit('afterMapZoom', viewerId)
    },
    [viewerId, view],
  )
  const handleChangeRotation = useCallback(
    (degree: number) => {
      view.setRotation(fromDegreesToRadians(Number(degree)))
      bus.$emit('afterMapRotation', viewerId)
    },
    [viewerId, view],
  )
  return { handleChangeRotation, handleChangeZoom }
}

export const nextSlideHandler = ({
  activeViewerId,
  caseId,
  changeViewerSlide,
  currSlidePosition,
  currentSlides,
  dispatch,
  isNext,
  macroSlidesIds,
  taskId,
  workspaceId,
}: NextSlideHandlerProps) => {
  const nexSlideId = currentSlides[currSlidePosition + (isNext ? +1 : -1)]
  const slideGroupType = macroSlidesIds?.filter((item) => item.slideId === nexSlideId)?.length ? 'MACRO' : 'MICRO'

  if (!nexSlideId) return
  if (taskId) {
    dispatch(push(`/tasks-viewer/${taskId}?slideId=${nexSlideId}`))
    changeViewerSlide(activeViewerId || 'A', {
      caseId: NaN,
      slideGroupType: 'MICRO',
      slideId: nexSlideId,
      source: 'ATLAS',
      viewerMode: 'DEFAULT',
    })
  } else if (workspaceId && caseId) {
    nexSlideId && dispatch(push(`/workspace/${workspaceId}/viewer/${caseId}?slideId=${nexSlideId}`))
    changeViewerSlide(activeViewerId || 'A', {
      caseId: caseId,
      slideGroupType,
      slideId: nexSlideId,
      source: 'PLATFORM',
      viewerMode: 'DEFAULT',
    })
  }
}
