import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createViewerExtraReducers from 'viewer/container/lib/createViewerExtraReducers'

type CommentsState = {
  editingCommentId: number | 'new' | null
  isAttachmentMode: boolean
}

const initialState: CommentsState = {
  editingCommentId: null,
  isAttachmentMode: false,
}

export const commentsSlice = createSlice({
  extraReducers: {
    ...createViewerExtraReducers<typeof initialState, PayloadAction<boolean>>(
      'viewerAttachmentsSlice/setAttachmentMode',
      (state, { payload, type }) => {
        state.isAttachmentMode = payload
      },
    ),
  },
  initialState,
  name: 'commentsSlice',
  reducers: {
    setAttachmentMode(state, { payload }: PayloadAction<CommentsState['isAttachmentMode']>) {
      state.isAttachmentMode = payload
    },
    setEditingComment(state, { payload }: PayloadAction<CommentsState['editingCommentId']>) {
      state.editingCommentId = payload
    },
  },
})
