import { Typography } from 'antd'
import type { TooltipPlacement } from 'antd/es/tooltip'
import type { TitleProps } from 'antd/es/typography/Title'
import { FC, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'

import { TooltipElement } from './TooltipElement'

const { Title } = Typography

export const GlobalAntTitleStyle = createGlobalStyle`
  .ant-typography h1, h1.ant-typography {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--color-text-1)
  }
  .ant-typography h2, h2.ant-typography {
    font-size: 18px;
    line-height: 22px;
    color: var(--color-text-1)
  }
  .ant-typography h3, h3.ant-typography {
    font-size: 13px;
    line-height: 18px;
    color: var(--color-text-1)
  }
  .ant-typography h4, h4.ant-typography {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-1)
  }
`

const StyledTitle = styled(Title)<{ isellipsis: boolean }>`
  &.ant-typography {
    overflow: ${({ isellipsis }) => isellipsis && 'hidden'};
    text-overflow: ${({ isellipsis }) => isellipsis && 'ellipsis'};
    font-weight: 500;
    margin: 0;
  }
  &.ant-typography.ant-typography-secondary {
    overflow: ${({ isellipsis }) => isellipsis && 'hidden'};
    text-overflow: ${({ isellipsis }) => isellipsis && 'ellipsis'};
    color: var(--color-text-3);
  }
  &.ant-typography.ant-typography-danger {
    overflow: ${({ isellipsis }) => isellipsis && 'hidden'};
    text-overflow: ${({ isellipsis }) => isellipsis && 'ellipsis'};
    color: var(--color-red);
  }
`

type Props = {
  tooltipPlacement?: TooltipPlacement
} & TitleProps

export const TitleElement: FC<Props> = ({ children, ellipsis, tooltipPlacement, ...props }) => {
  const [truncated, setTruncated] = useState(false)
  if (!ellipsis) {
    return (
      <StyledTitle isellipsis={false} {...props}>
        {children}
      </StyledTitle>
    )
  }
  const ellipsisConfig = typeof ellipsis === 'boolean' ? {} : ellipsis
  return (
    <TooltipElement title={truncated ? children : undefined} placement={tooltipPlacement}>
      <StyledTitle {...props} ellipsis={{ ...ellipsisConfig, onEllipsis: setTruncated }} isellipsis={!!ellipsis}>
        <>{children}</>
      </StyledTitle>
    </TooltipElement>
  )
}
