import { notification } from 'antd'
import { workspacesSlice } from 'features/workspace/model/workspacesSlice'
import { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IWorkspace } from 'types/IWorkspaces'

const Wrapper = styled.div`
  width: 322px;
`
const WorkspaceSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & > span {
    white-space: nowrap;
  }
  justify-content: center;
`

const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

const WorkspacesButton = styled(ButtonElement)`
  margin-top: 8px;
  height: 24px;
  width: 100%;
  background: var(--color-bg-3);
  border-radius: 5px;
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`
type Props = {
  workspaces?: IWorkspace[]
  dispatch: Dispatch<any>
  callback: () => void
}

const ViewerWorkspaceSelectContainer = ({ callback, dispatch, workspaces }: Props) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <WorkspaceSelectContainer>
        <Title>{t('Выберите куда сохранить случай')}</Title>
        {workspaces?.map((item) => (
          <WorkspacesButton
            key={item.workspaceId}
            onClick={() => {
              localStorage.setItem('workspaceId', item.workspaceId)
              dispatch(workspacesSlice.actions.setCurrentWorkspace(item))
              notification.close('WorkspaceNotification')
              callback()
            }}
          >
            {item.name}
          </WorkspacesButton>
        ))}
      </WorkspaceSelectContainer>
    </Wrapper>
  )
}
export default ViewerWorkspaceSelectContainer
