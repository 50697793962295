import { Form, RadioChangeEvent } from 'antd'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { Icd10SelectContainer } from 'entities/icd-10'
import { checkIntegration } from 'entities/lis-integration'
import { TumorSelectContainer } from 'entities/tumor-type'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { debounce } from 'lodash'
import { viewerPageSlice } from 'pages/viewer'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLisModeAsGemotest } from 'shared/lib/workspaces'
import { SegmentElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'
import IReport from 'types/IReport'
import useDeepCompareEffect from 'use-deep-compare-effect'

import ActionConfirmationModal from './ActionConfirmationModal'
import { Inner, Outer, StyledTextArea } from './styled'
import { CommonFooter } from './tree/CommonFooter'
import { GemotestFooter } from './tree/GemotestFooter'

const { Item: FormItem, useForm } = Form

/**
 * Длина строки ввода в TextArea
 */
const TEXT_AREA_LINE_HEIGHT = 20
/**
 * Максимальная высота для автоматического изменения. 8 строк, одна строка 20px
 */
const MAX_TEXTAREA_AUTO_SIZE = 168
/**
 * Длина метрики пикселей в стилях
 */
const STYLE_PIXEL_LENGTH = 2
/**
 * Буффер для обработки ресайза поля TextArea
 */
const RESIZE_DELAY = 300

const ErrorText = styled.div`
  background-color: var(--color-red);
  border-radius: 5px;
  margin: 4px 18px 0 16px;
  padding: 0 8px;
`

const FormInner = styled(Inner)`
  padding: 16px 16px 0 16px;
  gap: 0;
`

export type ReportFormData = {
  /** Признак блокировки для отмены подписания */
  locked?: boolean
  /** Признак подписи, для отправки в заключения в ЛИС*/
  signed?: boolean
  icd10?: DictionaryItem
  morphologyCode?: DictionaryItem
  report?: string
  comment?: string
  microDescription?: string
  caseMacroDescription?: string
  complexity?: number
}

export type ReportCreationFormProps = {
  /** Ошибка при сохранении заключения */
  saveError: string
  /** Функция, вызываемая при сохранении заключения. */
  onSave: (payload: ReportFormData) => Promise<void>
  /** Функция, вызываемая для удаления заключения. */
  onDelete: (reportId: number) => void
  /** Функция, вызываемая для удаления заключения. */
  onCancel: () => void
  /** Флаг, указывающий, виден ли модальное окно действия. */
  isActionModalVisible: boolean
  /** Функция для изменения видимости модального окна действия. */
  setIsActionModalVisible: Dispatch<SetStateAction<boolean>>
  /** Функция, вызываемая при закрытии модального окна. */
  onCloseModal: () => void
  /** Опциональное начальное заключение, которое может быть частично заполнено и используется для предзаполнения формы. */
  initialReport?: Partial<IReport>
  /** Состояние сохранения заключения */
  isSaving: boolean
  /** Флаг, указывающий, что заключение в данный момент удаляется. */
  isRemoveLoading: boolean
  /** Статус ошибки, при котором заключение можно создать 1 раз, а он уже существует */
  isConflictError: boolean
}

export type TextAreaSize = {
  /** Ширина поля */
  width: number
  /** Высота поля */
  height: number
}

/** Тип для значений компонента выбора категории сложности */
export type IComplexityOption = {
  /** Лейбл значения категории сложности */
  label: string
  /** Значение категории сложности */
  value: number
}

const ReportCreationForm = ({
  initialReport = {},
  isActionModalVisible,
  isConflictError,
  isRemoveLoading,
  isSaving,
  onCancel,
  onCloseModal,
  onDelete,
  onSave,
  saveError,
  setIsActionModalVisible,
}: ReportCreationFormProps) => {
  const dispatch = useDispatch()
  const lisMode = useLisMode()
  const isGemotest = useLisModeAsGemotest()
  const [form] = useForm<ReportFormData>()
  const theme = useThemeContext()
  const [complexityValue, setComplexityValue] = useState<number | undefined>()
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [formInnerHeight, setFormInnerHeight] = useState<number>(0)
  const innerFormRef = useRef<HTMLDivElement>(null)

  const toggleInputFocus = (payload: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(payload))

  const { t } = useTranslation()
  const onSaveClick = (signed?: boolean) => {
    const fields = form.getFieldsValue()
    fields.complexity = complexityValue
    fields.signed = signed
    fields.locked = false
    onSave(fields)
  }

  const onSign = () => {
    setIsSigning(true)
    form.validateFields().then(() => {
      onSaveClick(true)
    })
  }

  const onSaveDraft = () => {
    setIsSigning(false)
    const fields = form.getFieldsValue()
    fields.complexity = complexityValue

    form.validateFields().then(() => {
      onSave(fields)
    })
  }

  const formFieldsValue = form.getFieldsValue()

  useDeepCompareEffect(() => {
    const { complexity, icd10, report } = form.getFieldsValue()
    if (!report || !icd10 || (isGemotest && !complexity)) {
      setHasErrors(true)
    } else {
      setHasErrors(false)
    }
  }, [formFieldsValue, isGemotest])

  /** Обертка над радиогруппой. Делаем деселект для элементов */
  const onSegmentedClick = (e: React.BaseSyntheticEvent) => {
    if (
      // @ts-ignore
      e?.target?.className?.includes('ant-radio-button-checked') ||
      // @ts-ignore
      e?.target?.parentElement?.className?.includes('ant-radio-button-wrapper-checked')
    ) {
      e.preventDefault()
      form.setFieldValue('complexity', undefined)
      setComplexityValue(undefined)
    }
  }

  /** Обработчкик для контроллируемой смены значения категории сложности */
  const onSegmentedChange = (event: RadioChangeEvent) => {
    const value = event.target.value
    form.setFieldValue('complexity', value)
    setComplexityValue(value)
  }

  const complexityOptions: IComplexityOption[] = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
  ]

  const { caseMacroDescription, comment, customInfo, icd10, microDescription, morphologyCode, report } = initialReport
  const gemotestReport = customInfo || {}
  const { complexity } = gemotestReport

  const validateMessages = {
    required: t('Поле не должно быть пустым'),
  }

  const checkSelectEmpty = (_: any, value: any) => {
    if (!isSigning || value?.id) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(validateMessages.required))
  }

  const checkStringEmpty = (_: any, value: string) => {
    if (!isSigning || value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(validateMessages.required))
  }

  /** Мемомизированное значение суммы вертикальных паддингов формы  */
  const formInnerPaddingHeight = useMemo(() => {
    const inner = innerFormRef.current
    const innerStyle = inner ? window.getComputedStyle(inner) : null

    return formInnerHeight && innerStyle
      ? Number(innerStyle.getPropertyValue('padding-top').slice(0, -STYLE_PIXEL_LENGTH)) +
          Number(innerStyle.getPropertyValue('padding-bottom').slice(0, -STYLE_PIXEL_LENGTH))
      : 0
  }, [formInnerHeight])

  /** Мемомизированое значение суммы вертикальных марджинов элементов формы */
  const formItemsMarginHeight = useMemo(() => {
    const inner = innerFormRef.current
    const items = inner?.children

    let marginHeight = 0
    if (formInnerHeight && inner && items?.length) {
      for (let i = 0; i < items.length; i++) {
        const itemStyle = inner ? window.getComputedStyle(items[i]) : null

        if (itemStyle) {
          marginHeight +=
            Number(itemStyle.getPropertyValue('margin-top').slice(0, -STYLE_PIXEL_LENGTH)) +
            Number(itemStyle.getPropertyValue('margin-bottom').slice(0, -STYLE_PIXEL_LENGTH))
        }
      }
    }

    return marginHeight
  }, [formInnerHeight])

  /** Обработчик обновления максимальной высоты для каждого элемента формы TextArea */
  const updateTextAreaMaxHeight = () => {
    const inner = innerFormRef.current
    const innerHeight = Number(inner?.clientHeight) - formInnerPaddingHeight
    const items = inner?.children

    let childrenHeight = formItemsMarginHeight
    if (innerHeight > 0 && items?.length) {
      for (let i = 0; i < items.length; i++) {
        childrenHeight += items[i]?.clientHeight
      }

      const freeHeight = innerHeight - childrenHeight

      for (let i = 0; i < items.length; i++) {
        const textArea = items[i]?.getElementsByTagName('textarea')[0]

        if (textArea) {
          if (freeHeight >= 0) {
            const maxHeight = textArea.clientHeight + freeHeight
            textArea.style.height = `${textArea.clientHeight}px`
            textArea.style.maxHeight = `${maxHeight}px`
          } else {
            const negativeHeight = freeHeight / items.length
            textArea.style.height = `${textArea.clientHeight + negativeHeight}px`
            textArea.style.maxHeight = `${textArea.clientHeight + negativeHeight}px`
          }
        }
      }
    }
  }

  const debouncedUpdateTextAreaMaxHeight = debounce(updateTextAreaMaxHeight, RESIZE_DELAY)

  const getTextAreaMaxHeight = (fieldName: string) => {
    const target = document.getElementById(fieldName)
    return target ? Number(target.style.maxHeight.slice(0, -STYLE_PIXEL_LENGTH)) : 0
  }

  /** Обработчкик события ресайза TextArea */
  const resizeTextArea = (size: TextAreaSize, fieldName: string) => {
    const maxHeight = getTextAreaMaxHeight(fieldName)
    const height = size.height
    localStorage.setItem(`reportForm.${fieldName}.height`, String(height > maxHeight ? maxHeight : height))
    updateTextAreaMaxHeight()
  }

  /** Дебаунс обработчкика ресайза TextArea */
  const debouncedResizeTextArea = debounce(resizeTextArea, RESIZE_DELAY)

  /** Метод получения сохраненной высоты TextArea */
  const getTextAreaLocalStorageHeight = (fieldName: string) => {
    const height = localStorage.getItem(`reportForm.${fieldName}.height`)

    return height ? Number(height) : undefined
  }

  /** Обработчкик события изменения текста TextArea */
  const onChangeTextArea = (e: React.BaseSyntheticEvent) => {
    const target = e.target
    const height = target.offsetHeight
    const scrollHeight = target.scrollHeight
    const maxHeight = getTextAreaMaxHeight(target.id)

    // ничего не делаем, когда уже достигли максимальной высоты или поле больше 8 строк
    if (height === maxHeight || height > MAX_TEXTAREA_AUTO_SIZE) {
      return
    }

    // увеличиваем поле, когда есть свободное пространтсво
    if (scrollHeight > height) {
      let autoHeight = scrollHeight >= MAX_TEXTAREA_AUTO_SIZE ? MAX_TEXTAREA_AUTO_SIZE : scrollHeight

      if (autoHeight >= maxHeight) {
        autoHeight = maxHeight
      }

      target.style.height = `${autoHeight}px`
    }
    updateTextAreaMaxHeight()
  }

  /** TODO возмоно убрать
   * Обработчкик события клика на Enter TextArea */
  const onPressEnterTextArea = (e: React.BaseSyntheticEvent) => {
    const target = e.target
    const height = target.offsetHeight
    if (height < MAX_TEXTAREA_AUTO_SIZE) {
      target.style.height = `${height + TEXT_AREA_LINE_HEIGHT}px`
    }
    updateTextAreaMaxHeight()
  }

  useEffect(() => {
    // исключим null из типа
    setComplexityValue(complexity || undefined)
  }, [complexity])

  useEffect(() => {
    setFormInnerHeight(Number(innerFormRef?.current?.clientHeight))
    debouncedUpdateTextAreaMaxHeight()
  }, [innerFormRef?.current?.clientHeight])

  useEffect(() => {
    toggleInputFocus(true)
    return () => {
      toggleInputFocus(false)
    }
  }, [])

  return (
    <>
      {isActionModalVisible && (
        <ActionConfirmationModal
          hasErrors={hasErrors}
          title={hasErrors ? t('Не все обязательные поля заполнены') : t('Остались несохраненные изменения')}
          onCancel={() => setIsActionModalVisible(false)}
          onDiscard={onCloseModal}
          isLisMode={isGemotest}
          isSaving={isSaving}
          onSave={!isGemotest ? onSaveClick : onSaveDraft}
        />
      )}
      <Form
        form={form}
        layout={'vertical'}
        initialValues={{
          caseMacroDescription,
          comment,
          complexity,
          icd10,
          microDescription,
          morphologyCode,
          report,
        }}
        validateMessages={validateMessages}
      >
        <Outer>
          <FormInner ref={innerFormRef}>
            {/* МКБ-10 */}
            <div style={{ display: 'flex', gap: 16 }}>
              <FormItem
                style={{ flex: 1, flexGrow: 1, minWidth: 0 }}
                label={t('МКБ-10')}
                name="icd10"
                rules={[{ required: true, validator: checkSelectEmpty }]}
              >
                <Icd10SelectContainer multiline={false} placeholder="" />
              </FormItem>
              {/* Морфологический код */}
              <FormItem
                style={{ flex: 1, flexGrow: 1, minWidth: 0 }}
                label={t('Морфологический код (МКБ-0)')}
                name="morphologyCode"
              >
                <TumorSelectContainer multiline={false} />
              </FormItem>
              {/* Категория сложности */}
              <FormItem
                label={t('Категория сложности')}
                name="complexity"
                style={{ maxWidth: '100%' }}
                rules={[{ required: isGemotest, validator: isGemotest ? checkStringEmpty : undefined }]}
              >
                <span onClick={onSegmentedClick}>
                  <SegmentElement
                    options={complexityOptions}
                    style={{ maxWidth: '250px' }}
                    colorTheme={theme.theme}
                    value={complexityValue}
                    onChange={onSegmentedChange}
                  />
                </span>
              </FormItem>
            </div>
            {/* Макроописание */}
            <FormItem label={t('Макроописание')} name="caseMacroDescription">
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('caseMacroDescription') }}
                onResize={(size) => debouncedResizeTextArea(size, 'caseMacroDescription')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
              />
            </FormItem>
            {/* Микроописание */}
            <FormItem label={t('Микроописание')} name="microDescription">
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('microDescription') }}
                onResize={(size) => debouncedResizeTextArea(size, 'microDescription')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
              />
            </FormItem>
            <FormItem label={t('Заключение')} name="report" rules={[{ required: true }]}>
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('report') }}
                onResize={(size) => debouncedResizeTextArea(size, 'report')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
              />
            </FormItem>
            {/* Комментарий / Доп. замечания и рекомендации */}
            <FormItem
              label={
                checkIntegration('report', 'comment', lisMode) ? t('Доп замечания и рекомендации') : t('Комментарий')
              }
              name="comment"
            >
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('comment') }}
                onResize={(size) => debouncedResizeTextArea(size, 'comment')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
              />
            </FormItem>
          </FormInner>
          {!!saveError && (
            <FormItem>
              <ErrorText>{saveError}</ErrorText>
            </FormItem>
          )}
          <FormItem
            noStyle
            dependencies={[
              'report',
              'comment',
              'morphologyCode',
              'icd10',
              'caseMacroDescription',
              'microDescription',
              'complexity',
            ]}
          >
            {() =>
              !isGemotest ? (
                <CommonFooter
                  initialReport={initialReport}
                  form={form}
                  onSave={onSaveClick}
                  onDelete={onDelete}
                  onCancel={onCancel}
                  isSaving={isSaving}
                  isRemoveLoading={isRemoveLoading}
                />
              ) : (
                <GemotestFooter
                  isConflictError={isConflictError}
                  onSign={onSign}
                  onSaveDraft={onSaveDraft}
                  isDraftSaving={isSaving && !isSigning}
                  isSigning={isSaving && isSigning}
                  onCancel={onCancel}
                />
              )
            }
          </FormItem>
        </Outer>
      </Form>
    </>
  )
}

export default ReportCreationForm
