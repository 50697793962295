import { Space } from 'antd'
import DashboardNavigationType from 'features/dashboard/navigation/DashboardNavigationType'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'shared/lib/hooks'
import { SelectElement, TitleElement } from 'shared/ui/kit'
import NavigationBody from 'shared/ui/navigation/NavigationBody'
import styled from 'styled-components/macro'
import IPatient from 'types/IPatient'

import PatientListItem from './PatientListItem'

const StyledList = styled(Space)`
  height: 100%;
  padding: 16px 8px 8px;
  border-right: 1px solid var(--color-border-1);
  width: 100%;
`

const GroupTitle = styled(TitleElement)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`

type PatientListProps = {
  patients: IPatient[]
  onClick: (patient: IPatient) => void
  patient?: IPatient
  filter: DashboardNavigationType
}

const PatientList = ({ filter, onClick, patient, patients }: PatientListProps) => {
  const half = Math.ceil(patients.length / 2)
  const start = patients.slice(0, half)
  const tail = patients.slice(half)
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  if (filter === DashboardNavigationType.Settings) {
    const navItems = [
      {
        disabled: false,
        icon: '',
        isActive: () => queryParams.get('subpage') === 'mash',
        path: `/dashboard?filter=${DashboardNavigationType.Settings}&subpage=mash`,
        title: t('Маршрутизация'),
      },
      {
        disabled: true,
        icon: '',
        isActive: () => false,
        path: `/dashboard?filter=${DashboardNavigationType.Settings}w`,
        title: t('Контроль качества'),
      },
      {
        disabled: true,
        icon: '',
        isActive: () => false,
        path: `/dashboard?filter=${DashboardNavigationType.Settings}d`,
        title: t('Медицинские учреждения'),
      },
      {
        disabled: false,
        icon: '',
        isActive: () => queryParams.get('subpage') === 'people',
        path: `/dashboard?filter=${DashboardNavigationType.Settings}&subpage=people&p=p1`,
        title: t('Специалисты'),
      },
      {
        disabled: true,
        icon: '',
        isActive: () => false,
        path: `/dashboard?filter=${DashboardNavigationType.Settings}f`,
        title: t('Типы исследований'),
      },
      {
        disabled: false,
        icon: '',
        isActive: () => queryParams.get('subpage') === 'tmpl',
        path: `/dashboard?filter=${DashboardNavigationType.Settings}&subpage=tmpl`,
        title: t('Шаблоны заключений'),
      },
    ]
    return <NavigationBody style={{ padding: '8px' }} navItems={navItems} />
  }

  if (filter !== DashboardNavigationType.MDT) {
    const filteredPatients = filterPatients(patients, filter)
    return (
      <StyledList direction="vertical" size={8}>
        <GroupTitle level={2}>
          {t('Пациенты')}
          <SelectElement
            defaultValue="new"
            options={[
              {
                label: t('Сначала новые'),
                value: 'new',
              },
            ]}
          />
        </GroupTitle>
        {filteredPatients.map((p, i) => (
          <PatientListItem
            fullname={p.fullname || ''}
            type={p.siteName || t('Разное')}
            color={getColor(i)}
            key={p.fullname}
            onClick={() => onClick(p)}
            isActive={patient?.fullname === p.fullname}
          />
        ))}
      </StyledList>
    )
  }

  return (
    <StyledList direction="vertical" size={8}>
      <GroupTitle level={2}>{t('Сегодня')}</GroupTitle>
      {start.map((p, i) => (
        <PatientListItem
          fullname={p.fullname || ''}
          type={p.siteName || t('Разное')}
          color={getColor(i)}
          key={p.fullname}
          onClick={() => onClick(p)}
          isActive={patient?.fullname === p.fullname}
        />
      ))}
      <GroupTitle level={2}>{t('Завтра')}</GroupTitle>
      {tail.map((p, i) => (
        <PatientListItem
          fullname={p.fullname || ''}
          type={p.siteName || t('Разное')}
          color={getColor(i)}
          key={p.fullname}
          onClick={() => onClick(p)}
          isActive={patient?.fullname === p.fullname}
        />
      ))}
    </StyledList>
  )
}

const getColor = (index: number) => {
  if (index % 2) return 'var(--color-green)'
  if (index % 3) return 'var(--color-red)'
  return 'var(--color-yellow)'
}

const filterPatients = (patients: IPatient[], filter: DashboardNavigationType) => {
  switch (filter) {
    case DashboardNavigationType.Surg: {
      return patients.filter((p) => p.diagnosticTypes?.find((t) => t === 'SURGICAL'))
    }
    case DashboardNavigationType.Mammology: {
      return patients.filter((p) => p.diagnosticTypes?.find((t) => t === 'MAMMOGRAPHY'))
    }
    case DashboardNavigationType.Macro: {
      return patients.filter(
        (p) => p.fullname === 'Иванова Марина Владимировна' || p.fullname === 'Петрова Анна Юрьевна',
      )
    }
    case DashboardNavigationType.Micro: {
      return patients.filter((p) => p.diagnosticTypes?.find((t) => t === 'BIOPSY' || t === 'SURGICAL'))
    }
    case DashboardNavigationType.MRT: {
      return patients.filter((p) => p.diagnosticTypes?.find((t) => t === 'MRT'))
    }
    case DashboardNavigationType.UZI: {
      return patients.filter((p) => p.diagnosticTypes?.find((t) => t === 'UZI'))
    }
    case DashboardNavigationType.CT: {
      return patients.filter((p) => p.diagnosticTypes?.find((t) => t === 'CT'))
    }
    case DashboardNavigationType.New:
    case DashboardNavigationType.AddExam: {
      return patients
    }
    case DashboardNavigationType.Pause:
    case DashboardNavigationType.Favorites: {
      return patients?.length ? [patients[0]] : []
    }
    case DashboardNavigationType.Comment: {
      return patients?.length ? [patients[patients?.length - 1]] : []
    }
    default: {
      return []
    }
  }
}

export default PatientList
