import { UploadedFilesAvailableFilters } from 'features/sump/api/service'
import i18next from 'i18next'
import { UserFilter } from 'pages/sump/lib/types'
import { EUploadedFileAttachmentState, EUploadedFileType } from 'types/IUploadedFile'

const getBarcodeFailText = (reason: EUploadedFileAttachmentState) => {
  switch (reason) {
    case EUploadedFileAttachmentState.SUCCESS:
      return '-'
    case EUploadedFileAttachmentState.CAN_NOT_PARSE_BARCODE_DATA:
      return t('Штрихкод не соответствует маске')
    case EUploadedFileAttachmentState.WORKSPACE_HAS_NO_MASK_TO_PARSE_BARCODE:
      return t('Отсутствуют маски для распознавания штрихкода')
    case EUploadedFileAttachmentState.BARCODE_NOT_RECOGNIZED:
      return t('Штрихкод не распознан')
    case EUploadedFileAttachmentState.LABEL_NOT_EXIST:
      return t('Отсутствует этикетка')
    case EUploadedFileAttachmentState.CONVERSION_IS_FAILED:
      return t('Ошибка конвертации')
    case EUploadedFileAttachmentState.CAN_NOT_SELECT_CASE_SEARCH_STRATEGY:
    case EUploadedFileAttachmentState.CAN_NOT_SELECT_FILE_ATTACH_STRATEGY:
      return t('Не удалось привязать слайд к случаю')
    case EUploadedFileAttachmentState.AWAITING_CASE:
      return t('Ожидает загрузки случая из ЛИС')
    case EUploadedFileAttachmentState.LICENSE_CASE_EXCEEDED:
      return t('Превышено количество допустимых случаев')
    case EUploadedFileAttachmentState.DUPLICATE:
      return t('Дубль')
    default:
      return ''
  }
}

// eslint-disable-next-line import/no-named-as-default-member
const t = i18next.t

const getFileTypeText = (fileType: EUploadedFileType) => {
  switch (fileType) {
    case EUploadedFileType.DOCUMENT:
      return t('Документ')
    case EUploadedFileType.MACRO:
      return t('Макро')
    case EUploadedFileType.MICRO:
      return t('Микро')
  }
}

type FilterName = 'states' | 'fileTypes' | 'users'

const getStates = (states: EUploadedFileAttachmentState[]) => {
  // Для этих проблем выводится один фильтр,
  // который отправит несколько значений
  const strategySelectReasons: EUploadedFileAttachmentState[] = [
    EUploadedFileAttachmentState.CAN_NOT_SELECT_CASE_SEARCH_STRATEGY,
    EUploadedFileAttachmentState.CAN_NOT_SELECT_FILE_ATTACH_STRATEGY,
  ]
  const strategySelectKey = strategySelectReasons.join('|')

  const withStrategySelectReason = strategySelectReasons.some((r) => states.includes(r))

  const barcodeResult = states
    .filter((item) => !strategySelectReasons.includes(item))
    .map((item) => ({
      text: getBarcodeFailText(item),
      value: item,
    }))
  return withStrategySelectReason
    ? [...barcodeResult, { text: t('Не удалось привязать слайд к случаю'), value: strategySelectKey }]
    : barcodeResult
}

const getFileTypes = (fileTypes: EUploadedFileType[]) =>
  fileTypes.map((item) => ({ text: getFileTypeText(item), value: item }))

const getUsers = (users: UserFilter[]) =>
  [
    { text: t('Автоматически'), value: -1 },
    users.map((item) => ({
      text: item.fullname,
      value: item.userId,
    })),
  ].flat()

const getAvailableFilter = (filterName: FilterName, availableFilters?: UploadedFilesAvailableFilters) => {
  if (!availableFilters) return []
  switch (filterName) {
    case 'fileTypes':
      return getFileTypes(availableFilters?.fileTypes)
    case 'states':
      return getStates(availableFilters?.states)
    case 'users':
      return getUsers(availableFilters?.users)
  }
}

const getFileMimeType = (fileName: string) => {
  const ext = fileName.split('.').pop()
  if (ext === 'pdf') return 'application/pdf'
  return 'image/' + ext
}

export { getAvailableFilter, getBarcodeFailText, getFileMimeType, getFileTypeText }
