import Layout, { Content } from 'antd/lib/layout/layout'
import DashboardNavigationType from 'features/dashboard/navigation/DashboardNavigationType'
import { useQueryParams } from 'shared/lib/hooks'
import NavigationBody from 'shared/ui/navigation/NavigationBody'
import styled from 'styled-components'

import { allPeoples, avatars } from './data'
import { PeopleCard } from './PeopleCard'

const NavigationBodyWrapper = styled.div`
  padding: 8px;
  overflow-y: auto;
  border-right: 1px solid var(--color-bg-3);
`

const StyledContent = styled(Content)`
  background: var(--color-bg-2);
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  border-left: 1px solid var(--color-border-1);
`

export const PeopleContainer = () => {
  const queryParams = useQueryParams()

  const navItems = allPeoples.map((p) => ({
    disabled: false,
    image: avatars.find((a) => a.name === p.id)?.src,
    isActive: () => queryParams.get('p') === p.id,
    path: `/dashboard?filter=${DashboardNavigationType.Settings}&subpage=people&p=${p.id}`,
    title: p.name,
  }))

  return (
    <Layout style={{ height: '100%' }}>
      <StyledContent>
        <NavigationBodyWrapper>
          <NavigationBody navItems={navItems} />
        </NavigationBodyWrapper>
        <PeopleCard />
      </StyledContent>
    </Layout>
  )
}
