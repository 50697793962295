import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import {
  deleteAnnotationMutation,
  IAnnotationQuery,
  QueryFlags,
  updateAnnotationsQuery,
} from 'features/annotations/api'
import { checkLocalAnnotation } from 'features/annotations/lib/helpers'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { notices } from 'features/notices'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { areEqual } from 'react-window'
import { QUERY_TYPE } from 'shared/api'
import { getSlideMppx } from 'shared/lib/metadata'
import { IAnnotation, ISlideAnnotation } from 'types/IAnnotations'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { isObjectsCounting, ObjectsAnnotationType } from 'viewer/map/layers/annotations/lib/helpers'
import { getFeaturesFromGeoJson } from 'viewer/map/lib/utils'

import PlatformAnnotationListItem from './PlatformAnnotationListItem'

type Props = {
  annotation: IAnnotation
}

export const PlatformAnnotationListItemContainer = memo(({ annotation }: Props) => {
  const { activeViewerId: viewerId, changeViewerSlide } = useViewerPageProvided()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const dispatch = useDispatch()
  const viewerDispatch = useViewerDispatch(viewerId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const annotationsIsVisible = useTypedSelector((state) => state.annotations.annotationsIsVisible)
  const { data: slide } = useSlideQuery({ caseId, slideId: annotation?.slideId, source: 'PLATFORM' })
  const isVisibleArea = !!slide?.slideMetadata?.commonMetadata?.mppX
  const queryClient = useQueryClient()
  const annotationsIds = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
  const ids = annotationsIds?.ids || []
  const { liteContextMenuVisibility } = useTypedSelector((state) => state.viewerPage)
  const [isLiteContextFirstRenderVisible] = useState<boolean>(liteContextMenuVisibility)
  const mppX = getSlideMppx(slide)
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const { selectedObjectId } = useTypedSelector((state) => state.viewers[viewerId].viewer)
  const { t } = useTranslation()
  const { unsubscribeFromUser } = useUserStatusContext()

  // Обработка ошибки при удалении аннотации
  const onAnnotationDeleteError = () => {
    queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
      date: new Date(),
      ids,
    })
    const caseData = queryClient.getQueryData<ISlideAnnotation[]>([QUERY_TYPE.ANNOTATION, { caseId }])
    const currentAnnotations = caseData?.find((item) => item.slideId === slideId)?.annotations
    // обновляем хранилище аннотаций по кейсу (возвращаем аннотацию)
    slideId &&
      queryClient.setQueryData(
        [QUERY_TYPE.ANNOTATION, { caseId }],
        caseData?.length && caseData.find((item) => item.slideId === slideId)
          ? [
              ...caseData.map((annotationsGroup) => {
                if (annotationsGroup.slideId === slideId) {
                  return {
                    annotations: currentAnnotations ? [...currentAnnotations, annotation] : [annotation],
                    slideId: annotationsGroup.slideId,
                  }
                }
                return annotationsGroup
              }),
            ]
          : [{ annotations: currentAnnotations ? [...currentAnnotations, annotation] : [annotation], slideId }],
      )
    notices.error({
      message: t('Ошибка при удалении аннотации'),
    })
  }
  const { isLoading: isDeleting, mutate: deleteAnnotation } = deleteAnnotationMutation(
    {
      caseId,
      currentUserId,
    },
    {
      onError: onAnnotationDeleteError,
      onSuccess: () => {
        isLiteContextFirstRenderVisible && dispatch(viewerPageSlice.actions.setLiteContextMenuVisibility(true))
        isObjectsCounting(annotation?.type) &&
          annotation.slideAnnotationId === selectedObjectId &&
          dispatch(viewerPageSlice.actions.setTool({ tool: 'MITOSIS', value: false }))
      },
    },
  )

  const onDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (isDeleting) return
    e.stopPropagation()
    const slideAnnotationId = Number(annotation.slideAnnotationId)
    updateAnnotationsQuery({
      caseId,
      flag: QueryFlags.MULTIDEL,
      ids: [slideAnnotationId],
      queryClient,
      slideId,
    })
    if (checkLocalAnnotation(annotation)) return
    if (annotation?.slideAnnotationId && selectedAnnotationsIds?.includes(annotation?.slideAnnotationId)) {
      liteContextMenuVisibility && dispatch(viewerPageSlice.actions.setLiteContextMenuVisibility(false))
      viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
    }
    if (isObjectsCounting(annotation?.type) && slideAnnotationId === selectedObjectId) {
      // Для того, чтобы не сохранять удаляемый подсчет
      dispatch(viewerPageSlice.actions.setIsObjectsDeleting(true))
    }
    annotation && deleteAnnotation({ annotation })
  }

  const openMitosOrSelectAnnotation = (annotation: IAnnotation) => {
    viewerDispatch(viewerSlice.actions.selectAnnotations(annotation.slideAnnotationId))

    if (MITOSIS.isVisible) {
      dispatch(viewerPageSlice.actions.setWhetherToOpenObjectsContext(false))
      setTimeout(() => dispatch(viewerPageSlice.actions.setTool({ tool: 'MITOSIS', value: false })))
    }

    if (isObjectsCounting(annotation?.type)) {
      setTimeout(() => {
        viewerDispatch(viewerSlice.actions.selectObjectId(annotation.slideAnnotationId))
        dispatch(viewerPageSlice.actions.setCountingObjectType(annotation.type as ObjectsAnnotationType))
        dispatch(viewerPageSlice.actions.openObjectsCounting())
      }, 100)
      return
    }
  }

  const selectAnnotation = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    unsubscribeFromUser()

    if (!annotation?.slideAnnotationId) return
    /** Запрещаем повторное нажатие на MITOSIS/OBJECTS */
    if (annotation?.slideAnnotationId === selectedObjectId && isObjectsCounting(annotation?.type)) return
    dispatch(annotationsSlice.actions.setObjectsMitosisContextMenuVisible({ visible: false }))

    if (annotation?.slideId !== slideId) {
      changeViewerSlide(viewerId, {
        caseId,
        slideGroupType: slide?.groupType || 'MICRO',
        slideId: annotation?.slideId,
        source: 'PLATFORM',
        viewerMode: 'DEFAULT',
      })
      setTimeout(() => {
        openMitosOrSelectAnnotation(annotation)
        dispatch(annotationsSlice.actions.setSelectedFromList(true))
      }, 1000)
    } else {
      openMitosOrSelectAnnotation(annotation)
    }
    dispatch(annotationsSlice.actions.setDrawMode(false))
    dispatch(annotationsSlice.actions.setSelectedFromList(true))
    dispatch(annotationsSlice.actions.setAnnotationType())
  }

  const annotationsHandler = (id: number) => {
    const newAnnotations = annotationsIsVisible?.includes(id)
      ? annotationsIsVisible?.filter((item) => item !== id)
      : annotationsIsVisible
      ? [...annotationsIsVisible, id]
      : [id]
    viewerDispatch(annotationsSlice.actions.setAnnotationsIsVisible(newAnnotations))
  }

  return (
    <PlatformAnnotationListItem
      annotation={annotation}
      isVisibleArea={isVisibleArea}
      isActive={selectedAnnotationsIds?.includes(annotation.slideAnnotationId) || false}
      onSelect={selectAnnotation}
      onDelete={onDelete}
      canDelete={annotation.userId === currentUserId && annotation.slideId === slideId}
      annotationsIsVisible={annotationsIsVisible}
      setAnnotationsIsVisible={annotationsHandler}
      isDeleting={isDeleting}
      caption={
        annotation.caption ||
        (annotation.data?.formattedFeature
          ? getFeaturesFromGeoJson(annotation.data.formattedFeature)[0]?.get('description')
          : '')
      }
      mppX={mppX}
    />
  )
}, areEqual)
