import { Collapse as AntCollapse } from 'antd'
import type { CollapseProps } from 'antd/es/collapse/Collapse'
import type { CollapsePanelProps } from 'antd/es/collapse/CollapsePanel'
import ExpandArrow from 'assets/icons/ExpandArrow'
import { FC, ReactNode } from 'react'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const { Panel } = AntCollapse

const StyledAntCollapse = styled(AntCollapse)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`

type Props = {
  items?: Array<
    {
      content: ReactNode
    } & CollapsePanelProps
  >
} & CollapseProps

export const CollapseComponent: FC<Props> = ({ children, items, ...rest }) => (
  <StyledAntCollapse
    expandIcon={({ isActive }) => <ExpandArrow rotate={isActive ? 'rotate(0)' : 'rotate(-90)'} />}
    {...rest}
  >
    {items
      ? items.map(({ content, key, ...panelProps }) => (
          <CollapsePanel key={key} {...panelProps}>
            {content}
          </CollapsePanel>
        ))
      : children}
  </StyledAntCollapse>
)
export const CollapsePanel: FC<CollapsePanelProps> = ({ ...props }) => {
  const header = typeof props.header === 'string' ? <TextElement ellipsis>{props.header}</TextElement> : props.header
  return <Panel {...props} header={header} />
}
