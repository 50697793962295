import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { ButtonElement, Scroll, SpaceElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components'

const StyledContent = styled(Content)`
  background: var(--color-bg-2);
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  border-left: 1px solid var(--color-border-1);
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const TemplatesList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 900px;
`

const TemplatesListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 10px;
  width: 198px;
  min-width: 198px;
  height: 100px;
  background: var(--color-bg-3);
  border-radius: 10px;
  text-align: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;
`
export const TemplateContainer = () => {
  const { t } = useTranslation()
  const TemplatesNames1 = [
    t('Молочная железа'),
    t('Молочная железа, неоадьювант'),
    t('Предстательная железа, аденокарцинома'),
  ]

  const TemplatesNames2 = [
    t('Не специфический биопсия'),
    t('Не специфический операционный'),
    t('Легкие'),
    t('Молочная железа'),
    t('Мужские половые органы'),
    t('Женские половые органы'),
    t('Кожа'),
    t('Голова и шея'),
    t('Мягкие ткани'),
    t('Кости'),
    t('Колоректальный'),
    t('Не специфический операционный'),
  ]

  return (
    <Scroll>
      <Layout style={{ height: '100%' }}>
        <StyledContent>
          <div>
            <TitleElement level={2}>{t('Мои шаблоны')}</TitleElement>
          </div>
          <Buttons>
            <ButtonElement type="primary" size="large">
              {t('Создать новый')}
            </ButtonElement>
            <ButtonElement size="large">{t('Загрузить')}</ButtonElement>
          </Buttons>
          <TemplatesList>
            {TemplatesNames1.map((val, i) => (
              <TemplatesListItem key={i}>{t(val)}</TemplatesListItem>
            ))}
          </TemplatesList>

          <SpaceElement size={20} />
          <div>
            <TitleElement level={2}>{t('Готовые')}</TitleElement>
          </div>
          <TemplatesList>
            {TemplatesNames2.map((t, i) => (
              <TemplatesListItem key={i}>{t}</TemplatesListItem>
            ))}
          </TemplatesList>
        </StyledContent>
      </Layout>
    </Scroll>
  )
}
