import { Spin, TableProps } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useThemeContext } from 'app/styled/ThemeProvider'
import icons from 'assets/icons'
import { CaseForm, CaseFormDataType, useMutateCase } from 'features/cases'
import { useCaseDocumentsQuery } from 'features/cases/api/query'
import caseService, { IFile } from 'features/cases/api/service'
import { useCasesTableQuery } from 'features/cases-management/api/query'
import caseManagementService from 'features/cases-management/api/service'
import { PreviewPanel } from 'features/cases-management/CaseInfoBox'
import { formatDate, getQueryParams, refetchPages, setSortOrder } from 'features/cases-management/lib/helpers'
import { ECaseCursor, ECaseTableType, EDistributionTabType } from 'features/cases-management/types/ECaseTableType'
import { ECaseSortBy } from 'features/cases-management/types/TCasePagination'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { DoctorNameCellTable } from 'features/cases-management/ui/doctor-list/DoctorListItem'
import { handleStatusModalClick } from 'features/new-preview-panel/common'
import ReportCreationModalContainer from 'features/reports/ReportCreationModalContainer'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { TusContext } from 'processes/tus'
import React, { FC, memo, MouseEvent, RefObject, useContext, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { QUERY_TYPE } from 'shared/api'
import i18next from 'shared/lib/i18n/i18n'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { DropDown } from 'shared/ui/dropdown/DropDown'
import { TextAreaElement, TextElement } from 'shared/ui/kit'
import { ModalHandle, ModalProvider } from 'shared/ui/modal'
import { Column } from 'shared/ui/table'
import { useCustomVt, useTableSort } from 'shared/ui/table/lib/hooks'
import {
  DeclineModalProvider,
  ICaseTableProps,
  SpinnerWrapper,
  StyledCasesTable,
  StyledDateItem,
  StyledTableWrapper,
  StyledTag,
  WrapperResTable,
} from 'shared/ui/table/ui/Table.styled'
import { ECaseStageQuery, IAssignedDoctor, ICaseDTO } from 'types/ICase'
import { IPatientDTO } from 'types/IPatient'
import { TCasesManagementTabs } from 'types/TTab'
import { VtOpts } from 'virtualizedtableforantd4/dist/esm/vt'

import { CaseName } from './CaseName'
import { dotColors, ECaseStage, StatusModal, StatusModalBody } from './modal/StatusModal'
import { Patient } from './Patient'
import { useCustomTableEffects } from './useCustomTableEffects'

export const t = i18next.t

const HOT_KEYS_TABLE_NAVIGATION_UP = 'up, j, о'
const HOT_KEYS_TABLE_NAVIGATION_DOWN = 'down, k, л'
const HOT_KEYS_CASE_INFO_BOX_OPEN = 'enter, o'
const HOT_KEY_TABLE_CHECKBOX_TOGGLE = 'x'
const REASON_OF_REFUSE_MIN_CHAR = 3
const TEXTAREA_ROWS_QUANTITY = 3
const TEXTAREA_MAX_LENGTH = 270
const TEXTAREA_MIN_LENGTH = 3
export const SEC_TO_MINUTES = 60
export const ONE_MINUTE_IN_MS = 60000
const SCROLL_Y = '100%'
/** Оффсет количества элементов, где нужно начинать подгрузку следующей страницы */
const NEXT_PAGE_DOWNLOAD_OFFSET = 5
/** Оффсет количества элементов, на которые скролится предыдущая страница после загрузки */
const UP_SCROLL_OFFSET = 3
/** Высота строки в таблице */
export const ROW_HEIGHT = 44
/** Ширина панели врачей */
export const DOCTORS_PANEL_WIDTH = 240
/** Дефолтное поле для сортировки */
export const DEFAULT_SORT_FIELD = 'caseDate'
/** Дефолтное направление для сортировки */
export const DEFAULT_SORT_DIRECTION = 'ascend'
const ENTER_KEY = 'enter'

enum SortDirection {
  Up = 'up',
  Down = 'down',
}

interface CasesTableProps {
  tabType: TCasesManagementTabs
  /** Флаг показывающий распределён ли случай*/
  isCaseAssigned: boolean
  /** Установка флага что случай был распределён */
  setIsCaseAssigned: (arg: boolean) => void
}

export type CaseData = { caseId?: number; name?: string | null; wsId?: number }

type TTagItemProps = {
  tag: ECaseStageQuery
  onClick?: (e: MouseEvent) => void
}

type TSelectedCase = { record: ICaseDTO | null; index: number | null }

export const TagItem: FC<TTagItemProps> = memo(({ onClick, tag }) => {
  const colorTheme = useThemeContext()
  return (
    <StyledTag
      onClick={onClick}
      color={dotColors.get(ECaseStage[tag])}
      theme={colorTheme.theme}
      data-testid={'stage-color-tag'}
    >
      {ECaseStage[tag]}
    </StyledTag>
  )
})
export const DateItem = memo(({ date, isExpired }: { date?: string; isExpired?: boolean }) => (
  <StyledDateItem>
    <TextElement>{date ? formatDate(date) : t('Не указано')}</TextElement>
    {isExpired && <icons.exclamationMark data-testid={'management-cito-table-icon'} title={'Срочно!'} />}
  </StyledDateItem>
))

export const CasesTable: React.FC<CasesTableProps> = ({ isCaseAssigned, setIsCaseAssigned, tabType }) => {
  const firstRender = useRef(true)
  const queryClient = useQueryClient()
  const { search } = useLocation()
  const [cursor, setCursor] = useState<string | ECaseCursor>()
  const workspaceId = Number(useCurrentWorkspaceId())
  const { filterParams, setVisibleRowsCount, visibleRowsCount } = useCaseManagementContext()
  const { openPanel, setOpenPanel } = useContext(TusContext)
  const { selectedCases, selectedRowKeys, setSelectedCases, setSelectedRowKeys, setTabsQueryParams, tabsQueryParams } =
    useCaseManagementTabContext()
  const { handleSortChange, sortConfig } = useTableSort<ICaseDTO>({
    field: DEFAULT_SORT_FIELD,
    order: DEFAULT_SORT_DIRECTION,
  })
  const { currentTab, menuTab } = useCaseManagementRouteParam()

  const tableContainerRef: RefObject<HTMLDivElement> = useRef(null)
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { casesList, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, isFetching, isLoading, refetch } =
    useCasesTableQuery(
      menuTab,
      isCaseRouting,
      {
        tab: currentTab,
        ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting),
      },
      visibleRowsCount,
      filterParams,
      cursor,
    )

  /** Запрос курсора предыдущей страницы (страница может быть от 1 до 20 элементов) */
  const fetchPrevCursor = async (caseId: string) => {
    const { previousPageCursor } = await caseManagementService.getNextPrevCasesCursor(Number(workspaceId), menuTab, {
      caseId: Number(caseId),
      sortBy: getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting)?.sortBy,
      sortDir: getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting)?.sortDir,
    })

    return previousPageCursor
  }

  /** Перзапрос списка кейсов и их количества для таба */
  const refetchCases = () => {
    refetchPages(
      queryClient,
      menuTab,
      { tab: tabType, ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting) },
      filterParams,
      workspaceId,
      visibleRowsCount,
    )
    queryClient.invalidateQueries([QUERY_TYPE.CASE_MANAGEMENT_CASES_COUNT, menuTab])
  }

  /** Параметры поиска кейса в url*/
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  /** CaseId из url*/
  const selectedCaseId = searchParams.get('caseId')

  const declineModal = useRef<ModalHandle | null>(null)
  const caseFormModal = useRef<ModalHandle | null>(null)
  const statusHistoryModalRef: RefObject<ModalHandle> = useRef(null)
  const reportCreationModalRef = useRef<ModalHandle | null>(null)
  const history = useHistory()
  const [selectedCase, setSelectedCase] = useState<TSelectedCase | null>(null)
  const [selectedContextCase, setSelectedContextCase] = useState<ICaseDTO | null>(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [declineComment, setDeclineComment] = useState<string>('')
  const { isPanelPreviewVisible, panelPreviewHeight, setPanelPreviewVisible } = useCaseManagementContext()
  const [declineLoading, setDeclineLoading] = useState<boolean>(false)
  const [currentCase, setCurrentCase] = useState<CaseData>({})
  const prevSelectedRowIndexRef = useRef<number | null>(null)
  const isRefuseButtonDisabled = declineComment.trim().length < REASON_OF_REFUSE_MIN_CHAR || declineLoading
  const selectedRecord = selectedCase?.record
  const targetCase = selectedContextCase || selectedRecord
  const { mutateAsync } = useMutateCase(targetCase?.caseId || 0)
  const { data: caseRecordDocs } = useCaseDocumentsQuery({
    caseId: targetCase?.caseId || 0,
    source: 'PLATFORM',
  })
  const { caseId, casePathologicalInfo, caseType, materialTakeoutDate, name, patient } = targetCase || {}
  const onCaseEditSave = async (data: CaseFormDataType, newFiles: IFile[], deletedFiles?: IFile[]) => {
    if (!targetCase) return
    await mutateAsync(
      { data, deletedFiles, newFiles },
      {
        onSuccess: () => {
          refetchPages(
            queryClient,
            menuTab,
            { tab: tabType, ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting) },
            filterParams,
            workspaceId,
            visibleRowsCount,
          )

          if (selectedCase) {
            const updatedRecord = queryClient.getQueryData([QUERY_TYPE.CASE, selectedCase?.record?.caseId]) as ICaseDTO

            updatedRecord &&
              setSelectedCase({
                index: selectedCase.index,
                record: updatedRecord,
              })
          }
        },
      },
    )
    caseFormModal.current?.close()
  }

  const onDecline = async () => {
    if (declineComment && targetCase) {
      setDeclineLoading(true)
      await caseService
        .declineCase(String(workspaceId), declineComment, targetCase.caseId)
        .then(() => {
          setDeclineComment('')
          declineModal.current?.close()
          refetchCases()
          setSelectedContextCase(null)
        })
        .catch(() => setDeclineLoading(false))
    }
    setDeclineLoading(false)
  }
  const onDeclineClose = () => {
    declineModal.current?.close()
    setDeclineComment('')
  }

  const handleHotKey = (direction: string, event: KeyboardEvent) => {
    event.preventDefault()
    setSelectedRowIndex((prevIndex) => {
      let newIndex

      if (prevIndex === null) {
        // Выбираем первую строку при нажатии 'down', если до этого ничего не выбранно
        newIndex = direction === SortDirection.Down ? 0 : null
      } else {
        // Обычная навигация, когда строка уже выбрана. Так же это проверка граничных условий, чтоб не влетать в несуществующие индексы.
        newIndex =
          direction === SortDirection.Up ? Math.max(0, prevIndex - 1) : Math.min(casesList.length - 1, prevIndex + 1)
      }

      if (newIndex !== null) {
        setSelectedCase({ index: newIndex, record: casesList[newIndex] })
        setPanelPreviewVisible(true)
      }

      return newIndex
    })
  }
  const getRowClassName = (_: ICaseDTO, index: number) => (index === selectedRowIndex ? 'selected-row' : '')
  const resetSelection = () => {
    // Если пользователь кликает снова на уже выбранную запись, закрываем инфобокс
    history.push({
      search: `?${new URLSearchParams({ tab: currentTab })}`,
    })
    setSelectedRowIndex(null)
    setSelectedCase(null)
    setPanelPreviewVisible(false)
  }

  const getPageNumber = (index?: number | null) => Math.floor((index || 0) / visibleRowsCount)

  const handleRowClick = (record: ICaseDTO, index: number | null) => {
    const caseId = record?.caseId

    if (selectedRecord?.caseId === record?.caseId && isPanelPreviewVisible) {
      // Если пользователь кликает снова на уже выбранную запись, закрываем инфобокс
      resetSelection()
    } else {
      history.push({
        search: `?${new URLSearchParams({ caseId: String(caseId), tab: currentTab })}`,
      })
      setSelectedRowIndex(index ?? null)
      setSelectedCase({ index, record })
      setPanelPreviewVisible(true)
    }
  }

  const onRowSelection = (selectedRowKeys: React.Key[], selectedRows: ICaseDTO[]) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedCases(selectedRows)
  }

  const rowSelection: TableProps<ICaseDTO>['rowSelection'] = {
    onChange: onRowSelection,
    selectedRowKeys: selectedRowKeys,
  }

  const checkScrollDirection = (): string => {
    let scrollDirection = 'down'
    if (prevSelectedRowIndexRef.current !== null && selectedRowIndex !== null) {
      scrollDirection = selectedRowIndex > prevSelectedRowIndexRef.current ? 'down' : 'up'
    }
    return scrollDirection
  }
  const tusFileId = useTypedSelector((state) => state.tusFiles.queue[0])
  const tusFileIds = useTypedSelector((state) => state.tusFiles.queue).length
  const file = useTypedSelector((state) => state.tusFiles.byId[tusFileId || ''])
  const fileCaseId = file?.caseId

  useHotkeys(HOT_KEYS_TABLE_NAVIGATION_UP, (event) => handleHotKey('up', event), [selectedRowIndex])
  useHotkeys(HOT_KEYS_TABLE_NAVIGATION_DOWN, (event) => handleHotKey('down', event), [selectedRowIndex])
  useHotkeys(
    HOT_KEYS_CASE_INFO_BOX_OPEN,
    () => {
      if (!isPanelPreviewVisible && selectedRowIndex !== null) {
        setSelectedCase({ index: selectedRowIndex, record: casesList[selectedRowIndex] })
        setPanelPreviewVisible(true)
      }
    },
    [isPanelPreviewVisible, selectedRowIndex, casesList],
  )
  useHotkeys(
    HOT_KEY_TABLE_CHECKBOX_TOGGLE,
    () => {
      if (selectedRowIndex !== null) {
        const key = casesList[selectedRowIndex].caseId
        setSelectedRowKeys((prevKeys) => {
          const isKeySelected = prevKeys.includes(key)
          return isKeySelected ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
        })
      }
    },
    [selectedRowIndex, casesList, selectedRowKeys],
  )

  const closeCaseInfoBox = () => {
    history.push({
      search: `?${new URLSearchParams({ tab: currentTab })}`,
    })
    setSelectedRowIndex(null)
    setPanelPreviewVisible(false)
  }

  const onTableUpScroll = async () => {
    const tableOverFlowWrapper = document.querySelector('.ant-table-body') as HTMLElement | null
    if (hasPreviousPage) {
      await fetchPreviousPage()
      tableOverFlowWrapper?.scrollTo({ behavior: 'smooth', top: ROW_HEIGHT * UP_SCROLL_OFFSET })
    }
  }

  const onTableDownScroll = () => {
    hasNextPage && fetchNextPage()
  }

  const onTableScroll: VtOpts['onScroll'] = async ({ isEnd, top }) => {
    if (!isFetching) {
      !top ? onTableUpScroll() : isEnd && onTableDownScroll()
    }
  }

  const [tableBodyHeight, setTableBodyHeight] = useState(0)

  const [vt] = useCustomVt(
    { onScroll: onTableScroll, scroll: { y: tableBodyHeight - ROW_HEIGHT * NEXT_PAGE_DOWNLOAD_OFFSET } },
    [isFetching, tableBodyHeight],
  )

  const onRow = (record: ICaseDTO, index?: number) => ({
    key: index,
    onClick: () => handleRowClick(record, index ?? null),
    onContextMenu: () => setSelectedContextCase(record),
  })
  const onCloseContextMenu = () => setSelectedContextCase(null)

  useHotkeys(
    ENTER_KEY,
    () => {
      !declineLoading && onDecline()
    },
    [declineLoading],
  )

  /** Обработчкик события клика на Enter TextArea */
  const onPressEnterTextArea = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    !declineLoading && onDecline()
  }

  /** Эффекты */
  useCustomTableEffects({
    casesList,
    checkScrollDirection,
    cursor,
    fetchNextPage,
    fetchPrevCursor,
    fileCaseId,
    filterParams,
    firstRender,
    handleRowClick,
    hasNextPage,
    isCaseAssigned,
    isCaseRouting,
    isFetching,
    isLoading,
    isPanelPreviewVisible,
    menuTab,
    openPanel,
    prevSelectedRowIndexRef,
    queryClient,
    refetch,
    resetSelection,
    searchParams,
    selectedCase,
    selectedCaseId,
    selectedCases,
    selectedContextCase,
    selectedRecord,
    selectedRowIndex,
    setCursor,
    setIsCaseAssigned,
    setOpenPanel,
    setPanelPreviewVisible,
    setSelectedCase,
    setSelectedCases,
    setSelectedRowIndex,
    setTableBodyHeight,
    setTabsQueryParams,
    setVisibleRowsCount,
    sortConfig,
    tabType,
    tableContainerRef,
    tabsQueryParams,
    tusFileIds,
    visibleRowsCount,
    workspaceId,
  })

  return (
    <StyledTableWrapper
      data-testid={'table-item'}
      ref={tableContainerRef}
      panelPreviewHeight={panelPreviewHeight}
      isPanelPreviewVisible={isPanelPreviewVisible}
      isDoctorsPanelVisible={menuTab === ECaseTableType.ROUTING}
    >
      <DropDown
        setCurrentCase={setCurrentCase}
        caseFormModal={caseFormModal}
        refetchCases={refetchCases}
        caseData={selectedContextCase}
        declineModal={declineModal}
        statusHistoryModal={statusHistoryModalRef}
        reportCreationModal={reportCreationModalRef}
      >
        <WrapperResTable>
          <StyledCasesTable<FC<ICaseTableProps>>
            scroll={{ y: tableBodyHeight || SCROLL_Y }}
            components={vt}
            rowClassName={getRowClassName}
            rowSelection={tabType in EDistributionTabType ? rowSelection : undefined}
            dataSource={casesList}
            showSorterTooltip={false}
            pagination={false}
            tableLayout="fixed"
            onRow={onRow}
            onChange={handleSortChange}
            loading={false}
            rowKey={({ caseId }, index) => {
              const pageNumber = getPageNumber(index)
              return `${pageNumber}-${index}-${caseId}`
            }}
          >
            <Column
              className="has-divider"
              dataIndex="patient"
              key="patient"
              render={(patient: IPatientDTO | null) => {
                if (!patient) return t('Неизвестно')
                if (!patient?.fullname) return t('Скрыто')
                return (
                  <Patient
                    key={patient.patientProfileId}
                    patientName={patient.fullname}
                    patientConnectedCases={patient.availableCasesCount}
                  />
                )
              }}
              title={t('Пациент')}
            />
            <Column
              className="has-divider"
              dataIndex="name"
              key="caseName"
              render={(text: string, record: ICaseDTO) => {
                const { caseId, slideStats, status } = record
                return (
                  <CaseName
                    isArchive={status === 'ARCHIVE'}
                    key={caseId}
                    countDone={slideStats.slidesCount}
                    countTotal={slideStats.referencesCount}
                    caseName={text}
                  />
                )
              }}
              title={t('Случай')}
            />
            {menuTab === ECaseTableType.ROUTING && tabType !== EDistributionTabType.NOT_ASSIGNED && (
              <Column
                className="has-divider"
                dataIndex="assignedDoctor"
                key="assignedDoctor"
                render={(doctor: IAssignedDoctor | null) => {
                  if (!doctor || !doctor?.fullname) return t('Не назначен')
                  const { fullname, userId } = doctor
                  return <DoctorNameCellTable doctor={fullname} key={userId} />
                }}
                title={t('Врач')}
              />
            )}
            <Column
              className="has-divider"
              dataIndex="diagnosticProcedureType"
              key="diagnosticProcedureType"
              render={(_, record: ICaseDTO) => {
                const { casePathologicalInfo } = record
                if (!casePathologicalInfo || !casePathologicalInfo.diagnosticProcedureType)
                  return <TextElement ellipsis>{t('Неизвестно')}</TextElement>

                const { diagnosticProcedureType } = casePathologicalInfo
                return <TextElement ellipsis>{diagnosticProcedureType.name}</TextElement>
              }}
              sorter={true}
              title={t('Способ получения')}
              sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.DIAGNOSTIC_PROCEDURE_TYPE)}
            />
            <Column
              className="twoRows has-divider"
              dataIndex="incomingIcd10"
              key="incomingIcd10"
              render={(_, record: ICaseDTO) => {
                const { casePathologicalInfo } = record
                if (!casePathologicalInfo || !casePathologicalInfo.incomingIcd10)
                  return <TextElement ellipsis>{t('Неизвестно')}</TextElement>

                const { incomingIcd10 } = casePathologicalInfo
                return (
                  <TextElement ellipsis>
                    {incomingIcd10.shortName} {incomingIcd10.name}
                  </TextElement>
                )
              }}
              sorter={true}
              title={t('МКБ-10')}
              sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.INCOMING_ICD_10)}
            />
            <Column
              width={120}
              className="has-divider"
              dataIndex="caseDate"
              key="caseDate"
              render={(date: string, record: ICaseDTO) => <DateItem date={date} isExpired={record.urgent} />}
              sorter={true}
              title={t('Дата')}
              sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.CASE_DATE)}
            />
            {isCaseRouting && (
              <Column
                width={130}
                dataIndex="stage"
                key="stage"
                render={(tag: ECaseStageQuery, record: ICaseDTO) => <TagItem tag={tag} />}
                sorter={true}
                title={t('Статус')}
                sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.STAGE)}
              />
            )}
          </StyledCasesTable>
          {(isLoading || isFetching) && (
            <>
              <SpinnerWrapper>
                <Spin spinning />
              </SpinnerWrapper>
            </>
          )}
        </WrapperResTable>
      </DropDown>
      {isPanelPreviewVisible && (
        <PreviewPanel
          setCurrentCase={setCurrentCase}
          declineModal={declineModal}
          refetch={refetchCases}
          caseFormModal={caseFormModal}
          selectedCase={selectedRecord || null}
          statusHistoryModal={statusHistoryModalRef}
          onClose={closeCaseInfoBox}
          closeContextMenu={onCloseContextMenu}
          reportCreationModalRef={reportCreationModalRef}
        />
      )}
      <ModalProvider ref={caseFormModal} centered={true} footer={null} closable={false} destroyOnClose>
        <CaseForm
          onSave={onCaseEditSave}
          isEdit={true}
          onCancel={caseFormModal.current?.close}
          initialValues={{
            caseId,
            casePathologicalInfo,
            caseType,
            materialTakeoutDate: materialTakeoutDate ? new Date(materialTakeoutDate) : undefined,
            name: name || '',
            patient,
          }}
          caseRecordDocs={caseRecordDocs}
        />
      </ModalProvider>
      <DeclineModalProvider
        confirmLoading={declineLoading}
        ref={declineModal}
        onOk={onDecline}
        okText={t('Отказаться')}
        onCancel={onDeclineClose}
        centered={true}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: isRefuseButtonDisabled, style: { marginLeft: 0, width: '100%' } }}
        destroyOnClose
        title={t('Укажите причину отказа')}
      >
        <TextAreaElement
          rows={TEXTAREA_ROWS_QUANTITY}
          value={declineComment}
          maxLength={TEXTAREA_MAX_LENGTH}
          minLength={TEXTAREA_MIN_LENGTH}
          onChange={(e) => setDeclineComment(e.target.value)}
          onPressEnter={onPressEnterTextArea}
        />
      </DeclineModalProvider>
      <StatusModal
        title={currentCase.name || ''}
        ref={statusHistoryModalRef}
        destroyOnClose
        onCancel={(e) => {
          setCurrentCase({})
          handleStatusModalClick(statusHistoryModalRef, e)
        }}
        footer={null}
        centered={true}
      >
        <StatusModalBody currentCase={currentCase} />
      </StatusModal>
      {targetCase && (
        <ReportCreationModalContainer
          modalRef={reportCreationModalRef}
          caseId={targetCase?.caseId}
          source={'PLATFORM'}
        />
      )}
    </StyledTableWrapper>
  )
}
