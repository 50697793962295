import { get } from 'shared/api'

const getWorkspaces = async () => {
  const response = await get({
    url: `/workspace`,
  })
  return response
}

const workspacesService = {
  getWorkspaces,
}

export default workspacesService
