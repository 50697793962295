import { ReactComponent as Ki67Help } from 'assets/thumbnails/ki67Help.svg'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { Ki67Context } from 'viewer/map/layers/ki67/Ki67Context'

import Ki67ItemContainer from './Ki67ItemContainer'

const KiStyled = styled.div`
  background-color: var(--color-bg-3);
  border-radius: 5px;
  padding: 8px;
  display: grid;
  gap: 12px;
  grid-template-columns: 48px auto;
`

const InfoText = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: var(--color-text-3);
`

const KI67Wrapper = styled.div`
  display: grid;
  column-gap: 16px;
  row-gap: 8px;
  grid-template-rows: auto auto;
  width: 100%;
`

type Props = {
  viewerId: TViewerId
}

const Ki67Container = ({ viewerId }: Props) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const { t } = useTranslation()
  const { data, toggleKi67 } = useContext(Ki67Context)
  const activate = () => {
    viewerDispatch(viewerSlice.actions.selectKi67Id(undefined))
    viewerDispatch(viewerSlice.actions.setKi67Mode(true))
  }
  const { selectedKi67Id } = useViewerMainSelector(viewerId)

  const isLoading = !!Object.values(data).find((item) => item.points.length === 0)

  const onSelect = (id: string) => {
    viewerDispatch(viewerSlice.actions.selectKi67Id(id))
    toggleKi67(id, false)
  }

  const text = t('Выделите область, в которой будет произведена оценка экспрессии ядерных биомаркеров')

  if (Object.keys(data).length > 0) {
    return (
      <KI67Wrapper>
        {Object.keys(data).map((key) => (
          <Ki67ItemContainer
            id={key}
            key={key}
            info={data[key].info}
            isActive={selectedKi67Id === key}
            onSelect={onSelect}
            onHide={toggleKi67}
            isHidden={!!data[key].isHidden}
          />
        ))}
        {Object.keys(data).length < 4 && (
          <ButtonElement onClick={activate} disabled={isLoading}>
            {t('Добавить область')}
          </ButtonElement>
        )}
      </KI67Wrapper>
    )
  }
  return (
    <KI67Wrapper>
      <KiStyled>
        <Ki67Help />
        <InfoText>{text}</InfoText>
      </KiStyled>
      <ButtonElement onClick={activate}>{t('Добавить область')}</ButtonElement>
    </KI67Wrapper>
  )
}

export default Ki67Container
