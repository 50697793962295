import styled from 'styled-components/macro'

const RotatedBanner = styled.div`
  display: block;
  position: absolute;
  top: 80px;
  right: 0px;
  width: 60px;
  height: 30px;
  line-height: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: white;
  pointer-events: none;
  background-color: var(--color-purple);
  z-index: 500;
`
type Props = {
  className?: string
}

export const BetaBanner = ({ className }: Props) => <RotatedBanner className={className}>Beta</RotatedBanner>
