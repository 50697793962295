import { reportsSlice } from 'features/reports/model/reportsSlice'
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import IReport from 'types/IReport'
import ISource from 'types/ISource'

import reportsService from './service'

// TODO сделать результат хука из хука useReportsDataQuery
export const useReportsQuery = (caseId: number, source: ISource) => {
  const queryClient = useQueryClient()
  return useQuery(
    [QUERY_TYPE.REPORT, { caseId }],
    async () => {
      const results = await reportsService.getReports(caseId, source)
      if (!results) return
      results.forEach((report) => {
        queryClient.setQueryData([QUERY_TYPE.REPORT, report.medicalReportId], report)
      })
      return results.map((item) => item.medicalReportId)
    },
    { staleTime: Infinity },
  )
}

export const useReportsDataQuery = (caseId?: number, source?: ISource) => {
  const queryClient = useQueryClient()
  return useQuery(
    [QUERY_TYPE.REPORT_DATA, { caseId }],
    async () => {
      if (!caseId || !source) return
      const results = await reportsService.getReports(caseId, source)
      if (!results) return
      results.forEach((report) => {
        queryClient.setQueryData([QUERY_TYPE.REPORT, report.medicalReportId], report)
      })
      return results
    },
    {
      staleTime: Infinity,
    },
  )
}

export const useReportQuery = (caseId?: number, reportId?: number, source?: ISource) =>
  useQuery(
    [QUERY_TYPE.REPORT, reportId],
    () => {
      if (!caseId || !source || !reportId || reportId === -1) return
      if (reportId) return reportsService.getReport(caseId, reportId, source)
      return
    },
    {
      enabled: reportId !== -1,
      staleTime: Infinity,
    },
  )

export const useReportEditMutation = (
  options?:
    | UseMutationOptions<
        void,
        unknown,
        { caseId: number | string; report: Partial<IReport>; reportId: number | string }
      >
    | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({
      caseId,
      report,
      reportId,
    }: {
      caseId: number | string
      report: Partial<IReport>
      reportId: number | string
    }) => {
      const result = await reportsService.editReport(caseId, reportId, report)
      queryClient.setQueryData([QUERY_TYPE.REPORT, reportId], result)
    },
    {
      ...options,
      onSuccess: (data, variables) => queryClient.invalidateQueries([QUERY_TYPE.CASE, variables.caseId]),
    },
  )
}

export const useReportCreateMutation = (
  options?:
    | UseMutationOptions<void, unknown, { caseId: number | string; report: Partial<IReport> }, unknown>
    | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ caseId, report }: { caseId: number | string; report: Partial<IReport> }) => {
      const result = await reportsService.createReport(caseId, report)
      queryClient.setQueryData([QUERY_TYPE.REPORT, result.medicalReportId], result)
      queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }], { exact: true })
    },
    {
      ...options,
      onSuccess: (data, variables) => queryClient.invalidateQueries([QUERY_TYPE.CASE, variables.caseId]),
    },
  )
}

export const useLisReportCreateMutation = (
  options?:
    | UseMutationOptions<void, unknown, { caseId: number | string; report: Partial<IReport> }, unknown>
    | undefined,
) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  return useMutation(
    async ({ caseId, report }: { caseId: number | string; report: Partial<IReport> }) => {
      const createResult = await reportsService.createReport(caseId, report)
      if (!createResult.medicalReportId) return
      queryClient.setQueryData([QUERY_TYPE.REPORT, createResult.medicalReportId], createResult)
      dispatch(reportsSlice.actions.setInitialReport(createResult))
      const editResult = await reportsService.editReportToLis(caseId, createResult.medicalReportId, report)
      queryClient.setQueryData([QUERY_TYPE.REPORT, editResult.medicalReportId], editResult)
    },
    {
      ...options,
      onError: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId: variables.caseId }], { exact: true })
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId: variables.caseId }], { exact: true })
        queryClient.invalidateQueries([QUERY_TYPE.CASE, variables.caseId])
      },
    },
  )
}

export const useLisReportEditMutation = (
  options?:
    | UseMutationOptions<
        void,
        unknown,
        { caseId: number | string; report: Partial<IReport>; reportId: number | string },
        unknown
      >
    | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({
      caseId,
      report,
      reportId,
      source,
    }: {
      caseId: number | string
      source: ISource
      report: Partial<IReport>
      reportId: number | string
    }) => {
      const actualReport = await reportsService.getReport(caseId, reportId, source)
      if (actualReport.signed === true) {
        queryClient.setQueryData([QUERY_TYPE.REPORT, reportId], actualReport)
        queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }], { exact: true })
        return
      }
      const editResult = await reportsService.editReportToLis(caseId, reportId, report)
      queryClient.setQueryData([QUERY_TYPE.REPORT, reportId], editResult)
      queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }], { exact: true })
    },
    {
      ...options,
      onSuccess: (data, variables, context) => queryClient.invalidateQueries([QUERY_TYPE.CASE, variables.caseId]),
    },
  )
}

export const useReportRemoveMutation = (
  options?:
    | UseMutationOptions<void, unknown, { caseId: number | string; reportId: number | string }, unknown>
    | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(async ({ caseId, reportId }: { caseId: number | string; reportId: number | string }) => {
    await reportsService.deleteReport(caseId, reportId)
    queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }], { exact: true })
  }, options)
}

export const useReportCacheData = (reportId?: number) => {
  const queryClient = useQueryClient()
  return queryClient.getQueryData<IReport>([QUERY_TYPE.REPORT, reportId])
}
/** Обработчик ошибки при отмене заключения */
export const onSaveReportError = (error: any, caseId: number) => {
  const queryClient = useQueryClient()
  const data = error?.response?.data
  const isLocked = !data?.canRevokeSignature

  isLocked && queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }])
}
