import i18next from 'shared/lib/i18n/i18n'
import { ModalComponent } from 'shared/ui/modal'
import styled from 'styled-components'

const t = i18next.t

type Props = {
  /** Заголовок модалки */
  title?: string
  /** Видимость модалки */
  visible: boolean
  /** Видимость кнопки повторного действия */
  repeatBtnHidden?: boolean
  /** Видимость кнопки повторного действия */
  cancelBtnHidden?: boolean
  /** Текст кнопки повторения действия */
  repeatBtnText?: string
  /** Текст кнопки отмены действия */
  cancelBtnText?: string
  /** Обработчик кнопки повторной действия */
  onRepeatBtnClick?: () => void
  /** Обработчик кнопки отмены действия */
  onCancelBtnClick?: () => void
}
const StyledModalComponent = styled(ModalComponent)`
  .ant-modal-content {
    min-width: 300px;
  }

  .ant-modal-title {
    text-align: center;
    word-break: break-word;
    border-bottom: ${() => '1px solid rgba(255, 255, 255, 0.09)'};
    padding-bottom: 16px;
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 16px 16px 16px;

    button {
      margin-top: 8px;
      margin-left: 0 !important;
    }
  }
`

/**
 * Компонент модалки бед реквеста при сохрании аннотаций
 */
export const AnnotationSaveErrorModal = ({
  cancelBtnHidden,
  cancelBtnText = t('Удалить аннотацию'),
  onCancelBtnClick,
  onRepeatBtnClick,
  repeatBtnHidden,
  repeatBtnText = t('Попробовать сохранить еще раз'),
  title = t('Произошла ошибка при сохранении аннотации'),
  visible,
}: Props) => (
  <StyledModalComponent
    title={title}
    centered
    visible={visible}
    closable={false}
    onCancel={onCancelBtnClick}
    onOk={onRepeatBtnClick}
    cancelButtonProps={{ style: { display: cancelBtnHidden ? 'none' : 'block', width: '100%' } }}
    okButtonProps={{ style: { display: repeatBtnHidden ? 'none' : 'block', width: '100%' } }}
    cancelText={cancelBtnText}
    okText={repeatBtnText}
  />
)
