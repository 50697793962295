import { Button } from 'antd'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

type CasePlateProps = {
  caseName: string
  caseId: number
}

const Plate = styled(Button)<{ clickable: boolean }>`
  height: 36px;
  padding: 8px 16px;
  background-color: var(--color-bg-3);
  color: var(--color-blue);
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border-radius: 5px;
  &:hover {
    background-color: var(--color-border-1);
    color: var(--color-blue);
  }
  &:active {
    color: var(--color-blue);
  }
`

export const CasePlate: React.FC<CasePlateProps> = ({ caseId, caseName }) => {
  const history = useHistory()
  const workspaceId = useCurrentWorkspaceId()

  const handleClick = () => caseId && history.push(`/workspace/${workspaceId}/viewer/${caseId}`)

  return (
    <Plate disabled={!caseId} clickable={!!caseId} onClick={handleClick}>
      {caseName}
    </Plate>
  )
}
