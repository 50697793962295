import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { localizeDictionary } from 'shared/lib/common'
import i18next from 'shared/lib/i18n/i18n'
import { DictionaryItem } from 'types/IDictionary'

import StainSelectElement from './ui/StainSelectElement'

const t = i18next.t

type Props = {
  isDisabled?: boolean
  onChange?: (stain: DictionaryItem) => void
  value?: DictionaryItem
  placeholder?: string
  width?: number | string
  onFocus?: () => void
  onBlur?: () => void
}

export const StainSelectContainer = ({
  isDisabled,
  onBlur,
  onChange,
  onFocus,
  placeholder = t('Поиск по названию или коду'),
  value,
  width = '100%',
}: Props) => {
  useTranslation(DictionaryNS.STAIN)
  const dictionary = useLiveQuery(() => getDataFromDB('STAIN')) as TDict

  const { data, updatedAt } = { ...dictionary }

  const dictionaryLocales: DictionaryItem[] = localizeDictionary(data, DictionaryNS.STAIN)

  const onSelect = useCallback(
    (id: number) => {
      if (dictionaryLocales) {
        const stain = dictionaryLocales.find((item) => item.id === id)
        if (onChange && stain) {
          onChange(stain)
        }
      }
    },
    [updatedAt],
  )

  // const setStainVisible = async (stainId: number) => {
  //   //@ts-ignore
  //   const stains = await db.dictionaries.where('id').equals('STAIN')
  //   console.log('__STAINS__ ', stains)
  //   dispatch(dictionariesSlice.actions.setStainVisible({ stainId }))
  // }

  // useEffect(() => {
  //   if (data && value && !value?.visible && value.id) {
  //     setStainVisible(value?.id)
  //   }
  // }, [value, updatedAt])

  return dictionaryLocales ? (
    <StainSelectElement
      style={{ width }}
      value={value?.id || value?.name || undefined}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onSelect as any}
      isDisabled={isDisabled}
      stains={Array.from(dictionaryLocales.values())}
      stain={value?.id ? value : undefined}
    />
  ) : null
}
