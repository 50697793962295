import { Space } from 'antd'
import React from 'react'
import { IMarkupParticipant } from 'types/IMarkupTask'

import { MAX_PARTICIPANT_COUNT, overButton } from './columnRenderers'
import { Participant } from './Participant'

export const Participants: React.FC<{ open: boolean; toggleOpen: () => void; participants?: IMarkupParticipant[] }> = ({
  open,
  participants,
  toggleOpen,
}) => {
  if (!participants?.length) return null
  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      {participants
        ?.filter((participant, index) => open || index <= MAX_PARTICIPANT_COUNT - 1)
        ?.map((participant) => (
          <Participant key={participant.userId} user={participant.user} />
        ))}
      {(participants.length > MAX_PARTICIPANT_COUNT || (participants.length > MAX_PARTICIPANT_COUNT && open)) &&
        overButton(open, toggleOpen, participants.length - MAX_PARTICIPANT_COUNT)}
    </Space>
  )
}
