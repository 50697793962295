import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  /** Текст причины отказа  */
  title: string
}

const WarningPreview = ({ title }: Props) => {
  const { t } = useTranslation()
  return (
    <StyledContainer>
      <StyledIcon name={'infoSmall'} size={'md'} />
      <div style={{ color: 'var(--color-text-3)', marginRight: 4 }}>{t('Причина отказа')}</div>
      <div>{title}</div>
    </StyledContainer>
  )
}

export default WarningPreview

const StyledIcon = styled(IconElement)`
  margin-right: 8px;
  color: var(--color-text-3);
`
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 16px 16px 8px;
  background-color: var(--color-bg-3);
  border-radius: 3px;
`
