/** Погрешность при наведении курсора на митоз */
export const MITOSIS_POINTS_HIT_TOLERANCE = 0

/**
 * Кнопки для зума
 */
export const zoomKeys = ['-', '+', '=']

/** Кнопки для зума и движения по карте с помощью клавиатуры */
export const zoomAndMoveKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', ...zoomKeys]

/** Кнопки для дефолтных зумов 2x, 5x, 10x, 20x, 40x, 80x, 1:1 */
export const shiftZoomKeys = [')', '!', '@', '#', '$', '%', '^', '&']
