import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { StompClientContext, WsResponseCoregistration } from 'processes/stomp'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { usePrevious } from 'shared/lib/hooks'
import { ICoregistrationAlgorithm, ICoregistrationPayload } from 'types/ICoregistrationPayload'
import ICoregistrationSlide, { IAffine } from 'types/ICoregistrationSlide'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'
import { v4 as uuidv4 } from 'uuid'

import CoregistrationEventController from './EventController'
import { HandleModCoregistrationEventController } from './HandleModEventController'

type ICoregistrationViewingContext = {
  corViewingState: ICoregistrationViewingState
  postCorSync: (viewerId?: TViewerId) => void
  postCorUpdate: (viewerId: TViewerId) => void
  /**Возвращает координаты BBox'а референсного слайда*/
  getReferenceMapBBox: () => { x1: number; y1: number; x2: number; y2: number }
  /**Возвращает аффинные преобразования всех слайдов*/
  getAffineForAllSlides: () => {
    affine: IAffine
    slideId: number
    viewerId: TViewerId
  }[]
}
export type ICoregistrationStateItem = {
  slideId: number
  affine: IAffine
  error?: boolean
  loading: boolean
}
type ICoregistrationViewingState = {
  [key in TViewerId]?: ICoregistrationStateItem
}

type ICorSubscriptions = Map<TViewerId, string | null>
type ICorViewProp = 'loading' | 'error' | 'affine'

type IPayloadSlide = {
  slideId: number
  source: ISource
  affine: IAffine | null
}

export const DEFAULT_AFFINE = { angle: 0, dx: 0, dy: 0, rotX: 0, rotY: 0, scale: 1.0 }

const CoregistrationContext = createContext<ICoregistrationViewingContext>({
  corViewingState: {},
  getAffineForAllSlides: () => [
    {
      affine: DEFAULT_AFFINE,
      slideId: NaN,
      viewerId: 'A',
    },
  ],
  getReferenceMapBBox: () => ({ x1: 0, x2: 0, y1: 0, y2: 0 }),
  postCorSync: () => {},
  postCorUpdate: () => {},
})
type Props = {
  /** Вложенный элемент */
  children: ReactNode
}
const CoregistrationProvider = ({ children }: Props) => {
  const workspaceId = useCurrentWorkspaceId()
  const { publish, subscribe, unsubscribe } = useContext(StompClientContext)
  const { autoCoreg, handableCoreg, setHandableCoreg, viewingState } = useViewerPageProvided()
  const _setCorViewStateProp = (vid: TViewerId, prop: ICorViewProp, value: any) => {
    setCorViewingState((prevState) => ({
      ...prevState,
      [vid]: {
        ...prevState[vid],
        [prop]: value,
      },
    }))
  }
  const setCorLoading = (vid: TViewerId, value: any) => {
    _setCorViewStateProp(vid, 'loading', value)
  }
  const setCorError = (vid: TViewerId, value: any) => {
    _setCorViewStateProp(vid, 'error', value)
  }
  const setCorAffine = (vid: TViewerId, value: any) => {
    _setCorViewStateProp(vid, 'affine', value)
  }
  const [corViewingState, setCorViewingState] = useState<ICoregistrationViewingState>({
    A: {
      affine: DEFAULT_AFFINE,
      error: false,
      loading: false,
      slideId: NaN,
    },
  })
  const subscriptions = useRef<ICorSubscriptions>(new Map([['A', null]]))
  const _checkAndRemoveOldSubscription = (vid: TViewerId) => {
    const current = _getSubscription(vid)
    if (typeof current === 'string') unsubscribe(`/user/topic/slide/coregistration/${current}`)
  }
  const _getSubscription = (vid: TViewerId): string | undefined | null => subscriptions.current.get(vid)
  const _setSubscription = (vid: TViewerId, value: string | null = null) => {
    _checkAndRemoveOldSubscription(vid)
    subscriptions.current.set(vid, value)
  }
  const _removeSubscription = (vid: TViewerId) => {
    _checkAndRemoveOldSubscription(vid)
    subscriptions.current.delete(vid)
  }
  const _initSubscription = (vid: TViewerId, isUpdate?: boolean, isOnHandFirstSlideChangeCase = false) => {
    const uuid = uuidv4()
    _setSubscription(vid, uuid)
    subscribe<WsResponseCoregistration>(
      `/user/topic/slide/coregistration/${uuid}`,
      ({ payload }) => {
        if (payload === null) {
          setCorError(vid, true)
          return
        }
        updateCorViewingStateBySlideAffines(vid, payload.slides)
        // обнуление подписки после получения данных
        _setSubscription(vid)
        setCorLoading(vid, false)
      },
      { 'x-oc-workspace-id': String(workspaceId || '') },
    )
    setCorLoading(vid, true)
    setTimeout(() =>
      publish<ICoregistrationPayload>(
        `/app/slide/coregistration/${uuid}`,
        /**
         * это условие - часть логики обработки кейса замены 1го слайда в ручной корегистрации.
         * В данный момент математика с этим решением не дружит - требуются глубокие доработки функций применения affine.
         */
        isOnHandFirstSlideChangeCase === true
          ? collectOnHandFirstSlideChangeCasePayload()
          : collectCommonPayload(vid, isUpdate),
      ),
    )
  }

  const prevAutoCoreg = usePrevious(autoCoreg)
  const prevCorViewingState = usePrevious(corViewingState)
  /**
   * Обеспечиваем жёсткую связь между стейтами.
   * Этот эффект должен оперативно создавать подобие общего стейта
   * для своей роли в корегистрации. Он работает всегда, чтоб в момент включения
   * нам не потребовалось ни создавать его изначально, ни вмешиваться в common viewing state.
   * Этот эффект необратимо выполнит следующий за ним, который обработает часть логики,
   * связанной непосредственно с алгоритмом обновления состояний корегистрации
   */
  useEffect(() => {
    const newCorViewingState: ICoregistrationViewingState = {}
    Object.keys(viewingState).forEach((id) => {
      const vid = id as TViewerId
      setCorLoading(vid, true)
      const vState = viewingState[vid]
      if (vState === undefined) return
      /** Собираем новый cState ориентируясь на slideId */
      let cState
      const keys = Object.keys(corViewingState) as TViewerId[]
      for (const key of keys) {
        if (vState.slide.slideId === corViewingState[key]?.slideId) {
          cState = corViewingState[key]
        }
      }
      const affine = cState === undefined ? DEFAULT_AFFINE : cState.affine
      newCorViewingState[vid] = {
        affine,
        error: false,
        loading: false,
        slideId: vState.slide.slideId,
      }
    })
    /** Используем Timeout, чтобы механизм обновления, следящий за полем isLoading, увидел изменение CorViewingState*/
    setTimeout(() => setCorViewingState(newCorViewingState))
  }, [viewingState])

  useEffect(() => {
    if (prevCorViewingState === undefined) return
    const corViewingStateKeys = Object.keys(corViewingState)
    for (let i = 0; i < corViewingStateKeys.length; i++) {
      const vid = corViewingStateKeys[i] as TViewerId
      const cState = corViewingState[vid]
      if (cState === undefined) continue
      const prevcState = prevCorViewingState[vid]
      if (prevcState === undefined) {
        if (autoCoreg || handableCoreg) _initSubscription(vid)
      } else {
        if (autoCoreg) {
          if (cState.slideId !== prevcState.slideId) {
            if (vid === 'A') {
              postCorSync()
              return
            } else _initSubscription(vid)
          }
        } else if (handableCoreg) {
          if (cState.slideId !== prevcState.slideId) {
            if (vid === 'A') {
              /**
               * Временное решение - отключить ручную корегистрацию при замене первого слайда
               * Правильное - обработать кейс. Отослать запрос к ml, в котором реф - закрытый слайд, а подстраиваемый - новый открытый
               * Проблема в том, что в данный момент математические функции применения affine не рассчитаны на установку affine
               * при их инициализации на первый слайд. Это уникальный для корегистрации кейс.
               * Частично он обработан в виде нового collect, вызываемого через 3й аргумент - _initSubscription(vid, false, true)
               */
              setHandableCoreg(false)
            } else {
              _initSubscription(vid)
            }
          }
        }
      }
    }
  }, [corViewingState])

  const updateCorViewingStateBySlideAffines = (vid: TViewerId, slides: ICoregistrationSlide[]) => {
    const vState = viewingState[vid]
    if (vState === undefined) return
    const slide = slides.find((item) => item.slideId === vState.slide.slideId)
    if (slide === undefined) {
      setCorError(vid, true)
      return
    }
    setCorAffine(vid, slide.affine)
    _removeSubscription(vid)
  }

  const getReferenceMapBBox = useCallback(() => {
    const refState = viewingState['A']
    if (refState === undefined) return { x1: 0, x2: 0, y1: 0, y2: 0 }
    const map = refState.map
    const size = map.getSize()
    let boxcoords
    try {
      const extent = map.getView().calculateExtent(size)
      boxcoords = { x1: extent[0], x2: extent[2], y1: extent[1], y2: extent[3] }
    } catch (e: any) {
      console.log('Map error: ', e)
      boxcoords = { x1: 0, x2: 0, y1: 0, y2: 0 }
    }
    return boxcoords
  }, [viewingState, corViewingState])

  const collectCommonPayload = (viewerId: TViewerId, isUpdate?: boolean) => {
    const refState = corViewingState['A']
    if (refState === undefined)
      return {
        algorithm: 'CORR' as ICoregistrationAlgorithm,
        referenceRoi: getReferenceMapBBox(),
        referenceSlideId: NaN,
        referenceSlideSource: 'PLATFORM' as ISource,
        slides: [],
      }
    const slides: IPayloadSlide[] = []
    const vState = Object.keys(viewingState)
    vState.forEach((item) => {
      const vitem = item as TViewerId
      const state = viewingState[vitem]
      const cvState = corViewingState[vitem]
      if (vitem !== viewerId || state === undefined) return
      slides.push({
        affine: isUpdate ? (cvState !== undefined ? cvState.affine : DEFAULT_AFFINE) : null,
        slideId: state.slide.slideId,
        source: 'PLATFORM' as ISource,
      })
    })
    return {
      algorithm: 'CORR' as ICoregistrationAlgorithm,
      referenceRoi: getReferenceMapBBox(),
      referenceSlideId: refState.slideId,
      referenceSlideSource: 'PLATFORM' as ISource,
      slides,
    }
  }

  const collectOnHandFirstSlideChangeCasePayload = () => {
    const ref = corViewingState['A']
    if (prevCorViewingState === undefined)
      return {
        algorithm: 'CORR' as ICoregistrationAlgorithm,
        referenceRoi: getReferenceMapBBox(),
        referenceSlideId: ref === undefined ? NaN : ref.slideId,
        referenceSlideSource: 'PLATFORM' as ISource,
        slides: [],
      }
    const prevRef = prevCorViewingState['A']
    return {
      algorithm: 'CORR' as ICoregistrationAlgorithm,
      referenceRoi: getReferenceMapBBox(),
      referenceSlideId: prevRef === undefined ? NaN : prevRef.slideId,
      referenceSlideSource: 'PLATFORM' as ISource,
      slides: [
        {
          affine: null,
          slideId: ref === undefined ? NaN : ref.slideId,
          source: 'PLATFORM' as ISource,
        },
      ],
    }
  }

  const postCorSync = (vid?: TViewerId) => {
    if (vid !== undefined) {
      _initSubscription(vid)
    } else {
      const keys = Object.keys(corViewingState)
      for (const key of keys) {
        const vid = key as TViewerId
        if (vid === 'A') continue
        _initSubscription(vid)
      }
    }
  }
  const postCorUpdate = (viewerId: TViewerId) => {
    _initSubscription(viewerId, true)
  }

  useEffect(() => {
    if (autoCoreg === true && prevAutoCoreg === false) postCorSync()
  }, [autoCoreg])

  useEffect(
    () => () => {
      subscriptions.current.forEach((value, key) => _checkAndRemoveOldSubscription(key))
    },
    [],
  )

  const getAffineForAllSlides = () =>
    Object.keys(corViewingState).map((viewerId) => {
      const state = corViewingState[viewerId as TViewerId]
      return {
        affine: state?.affine ?? DEFAULT_AFFINE,
        slideId: state?.slideId ?? NaN,
        viewerId: viewerId as TViewerId,
      }
    })

  return (
    <CoregistrationContext.Provider
      value={{
        corViewingState,
        getAffineForAllSlides,
        getReferenceMapBBox,
        postCorSync,
        postCorUpdate,
      }}
    >
      {!!handableCoreg && <HandleModCoregistrationEventController />}
      {!!autoCoreg && <CoregistrationEventController />}
      {children}
    </CoregistrationContext.Provider>
  )
}

const useCoregistrationProvided = () => useContext(CoregistrationContext)

const useViewerCorState = (viewerId: TViewerId) => {
  const { corViewingState } = useCoregistrationProvided()
  return useMemo(() => corViewingState[viewerId], [corViewingState, viewerId])
}

export { CoregistrationProvider, useCoregistrationProvided, useViewerCorState }
