import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'shared/lib/hooks'
import NavigationBody from 'shared/ui/navigation/NavigationBody'

import DashboardNavigationType from './DashboardNavigationType'

const DashboardNavigationTypesContainer = () => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const navItems = [
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.UZI,
      path: `/dashboard?filter=${DashboardNavigationType.UZI}`,
      title: t('УЗИ'),
    },
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Radio,
      path: `/dashboard?filter=${DashboardNavigationType.Radio}`,
      title: t('Рентгенография'),
    },
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Mammology,
      path: `/dashboard?filter=${DashboardNavigationType.Mammology}`,
      title: t('Маммография'),
    },
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.CT,
      path: `/dashboard?filter=${DashboardNavigationType.CT}`,
      title: t('КТ'),
    },
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.MRT,
      path: `/dashboard?filter=${DashboardNavigationType.MRT}`,
      title: t('МРТ'),
    },
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Pathology,
      path: `/dashboard?filter=${DashboardNavigationType.Pathology}`,
      title: t('ПЕТ'),
    },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Surg}`,
    //   title: 'Хирургические вмешательства',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Surg,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Gen}`,
    //   title: 'Генетические исследования',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Gen,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Macro}`,
    //   title: 'Макроскопия',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Macro,
    // },
  ]
  return <NavigationBody navItems={navItems} title={t('Лучевая диагностика')} />
}

export default DashboardNavigationTypesContainer
