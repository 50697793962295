import styled from 'styled-components/macro'

import { LEFT_PADDING_PIXELS } from './constants'

export const DraggableWrapper = styled.div`
  outline: none;
  height: 100%;
`

export const StyledCollapseTitle = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 0 14px ${LEFT_PADDING_PIXELS}px;

  & > div {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
`

export const AnnotationListContainer = styled.div`
  padding: 0 8px;
  height: 100%;
`
