import { AxiosError } from 'axios'
import { push } from 'connected-react-router'
import { notices } from 'features/notices'
import { logout, refreshToken } from 'processes/user'
import { AUTHORITIES_KEY } from 'shared/lib/common/storageKeys'
import IUserRole from 'types/IUserRole'

import { HttpStatus } from './httpStatusCode'

let dispatch: any = () => {}

import('app/redux').then((module) => {
  dispatch = module.store.dispatch
})

enum ERROR_MESSAGES {
  FORBIDDEN = 'Нет доступа',
  NETWORK = 'Ошибка в работе сервера',
  NO_ACCESS = 'У вас нет доступа к платформе. Пожалуйста, обратитесь к администратору',
  SERVER = 'Ошибка в работе сервера',
}

export interface ErrorResponse extends AxiosError {
  config: {
    params?: {
      ignoreCatch?: boolean
    }
  }
}

/**
 * Проверяет, является ли ошибка ошибкой авторизации.
 *
 * @param {ErrorResponse} error - Объект ошибки.
 * @returns {boolean} Возвращает true, если ошибка связана с отсутствием авторизации.
 */
export const isUnauthorizedError = (error: ErrorResponse) => error?.response?.status === HttpStatus.UNAUTHORIZED

/**
 * Проверяет, является ли ошибка серверной ошибкой.
 *
 * @param {ErrorResponse} error - Объект ошибки.
 * @returns {boolean} Возвращает true, если ошибка связана с внутренней серверной ошибкой.
 */
export const isServerError = (error: ErrorResponse) =>
  error?.response?.status && error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR

/**
 * Проверяет, является ли ошибка сетевой ошибкой.
 *
 * @param {ErrorResponse} error - Объект ошибки.
 * @returns {boolean} Возвращает true, если ошибка связана с сетевой ошибкой 'Network Error'.
 */
export const isNetworkError = (error: ErrorResponse) =>
  typeof error?.toJSON === 'function' && (error?.toJSON() as { message?: string })?.message === 'Network Error'

/**
 * Проверяет, является ли ошибка запрещенным запросом.
 *
 * @param {ErrorResponse} error - Объект ошибки.
 * @returns {boolean} Возвращает true, если ошибка связана с отказом в доступе (HTTP 403).
 */
export const isForbiddenError = (error: ErrorResponse) =>
  error?.response?.status === HttpStatus.FORBIDDEN && !error?.config?.params?.ignoreCatch

export const handleUnauthorizedError = () => dispatch(refreshToken())

export const handleServerError = () => showErrorMessage(ERROR_MESSAGES.SERVER)

export const handleNetworkError = () => showErrorMessage(ERROR_MESSAGES.NETWORK)

export const handleForbiddenError = () => {
  const authorities = localStorage.getItem(AUTHORITIES_KEY)

  if (authorities?.includes(IUserRole.ROLE_USER)) {
    handleRoleAccessError('/cases?rel=owner')
  } else if (authorities?.includes(IUserRole.ROLE_GUEST)) {
    handleRoleAccessError('/cases?rel=shared')
  } else {
    handleNoAccessError()
  }
}

export const showErrorMessage = (message: string) => {
  notices.error({
    message,
  })
}

export const handleRoleAccessError = (redirectPath: string) => {
  showErrorMessage(ERROR_MESSAGES.FORBIDDEN)
}

export const handleNoAccessError = () => {
  dispatch(logout())
  dispatch(push('/login'))
  showErrorMessage(ERROR_MESSAGES.NO_ACCESS)
}
