import { del, get, post, put } from 'shared/api'
import IReport from 'types/IReport'
import ISource from 'types/ISource'

const getReports = (caseId: number | string, source: ISource) =>
  get<IReport[]>({
    config: {
      params: {
        source,
      },
    },
    url: `/case/${caseId}/medical-reports`,
  })
const getReport = (caseId: number | string, reportId: number | string, source: ISource) =>
  get<IReport>({
    config: {
      params: {
        source,
      },
    },
    url: `/case/${caseId}/medical-reports/${reportId}`,
  })
const deleteReport = (caseId: number | string, reportId: number | string) =>
  del({
    url: `/case/${caseId}/medical-reports/${reportId}`,
  })
const editReport = (caseId: number | string, reportId: number | string, data: Partial<IReport>) =>
  put<IReport>({
    data,
    url: `/case/${caseId}/medical-reports/${reportId}`,
  })
const createReport = (caseId: number | string, data: Partial<IReport>) =>
  post<Partial<IReport>>({
    data,
    url: `/case/${caseId}/medical-reports`,
  })
const editReportToLis = (caseId: number | string, reportId: number | string, data: Partial<IReport>) =>
  post<Partial<IReport>>({
    data,
    url: `/case/${caseId}/medical-reports/${reportId}/lis`,
  })

const reportsService = {
  createReport,
  deleteReport,
  editReport,
  editReportToLis,
  getReport,
  getReports,
}

export default reportsService
