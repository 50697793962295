import { useTypedSelector } from 'app/redux/lib/selector'
import React, { useState } from 'react'
import { css } from 'styled-components'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import MedicalNeuronetsContainer from './MedicalNeuronetsContainer'

/** Props for MedicalNeuronetsToolPanel */
type Props = {
  /** Ссылка на DOM-элемент панели Детекция артефактов */
  ref: React.RefObject<HTMLDivElement>
  /** Координаты начального расположения панели */
  initPosition: number[]
  /** Коллбэк для установки координат расположения панели */
  onPositionChange: (position: number[]) => void
}

const MedicalNeuronetsToolPanel = ({ initPosition, onPositionChange, ref }: Props) => {
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const isMagnetize = useTypedSelector((state) => state.viewerPage.isMagnetize['MEDICALNEURONETS_CRC'])

  return (
    <ViewerInfoToolPanelContainer
      id={'MEDICALNEURONETS_CRC'}
      panelRef={ref}
      type={'MEDICALNEURONETS_CRC'}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      position={position}
      setPosition={setPosition}
      style={css`
        max-height: ${isMagnetize ? '' : 'calc(100% - 96px)'};
        overflow: hidden auto;
      `}
    >
      <MedicalNeuronetsContainer />
    </ViewerInfoToolPanelContainer>
  )
}

export default MedicalNeuronetsToolPanel
