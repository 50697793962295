import {
  isChrome,
  isChromium,
  isDesktop,
  isEdgeChromium,
  isIE,
  isLegacyEdge,
  isOpera,
  isYandex,
} from 'react-device-detect'
import { UNSUPPORTED_BROWSER_ACCEPTED, UNSUPPORTED_DEVICE_ACCEPTED } from 'shared/lib/common/storageKeys'

/** Черный список браузеров */
const isBlackListBrowsers = isIE || isLegacyEdge || (isOpera && !isChromium)
/** Серый список браузеров */
export const isGreyListBrowsers = (isOpera && isChromium) || isEdgeChromium || (!isIE && !isChrome && !isYandex)
/** Белый список браузеров */
const isWhiteListBrowsers = isChrome || isYandex

/** Получаем из localStorage, согласен ли пользователь, что в его устройстве платформа может работать некорректно */
export const isUserAcceptedUnsupportedDevice = () => localStorage.getItem(UNSUPPORTED_DEVICE_ACCEPTED) === 'true'
/** Получаем из localStorage, согласен ли пользователь, что в его браузере платформа может работать некорректно */
export const isUserAcceptedUnsupportedBrowser = () => localStorage.getItem(UNSUPPORTED_BROWSER_ACCEPTED) === 'true'
/** Доступность платформы для пользователя с учетом браузера и устройства */
export const isBrowserAccess =
  (isDesktop && isWhiteListBrowsers) ||
  (isDesktop && isGreyListBrowsers && isUserAcceptedUnsupportedBrowser()) ||
  (!isDesktop && isUserAcceptedUnsupportedDevice())
