import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useSearchGridQuery } from 'entities/slide-layers'
import annotationsService from 'features/annotations/api/service'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { notices } from 'features/notices'
import { AdaptiveThumbnail } from 'features/thumbnails'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ELLIPSIS } from 'shared/text-constants'
import { ImageElement, ParagraphElement, SpaceElement, SpinElement, TextElement } from 'shared/ui/kit'
import { BetaBanner } from 'shared/ui/kit/ui/BetaBanner'
import styled from 'styled-components/macro'
import { ISelectedBbox, ISimilarSlide } from 'types/ISimilarRegion'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'

import SimilarSlidesListContainer from './SimilarSlidesListContainer'
import SearchButton from './ui/SearchButton'

const StyledDivider = styled(Divider)`
  margin: 16px 0 !important;
`
const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Info = styled.div`
  padding-right: 13px;
`

const Error = styled(TextElement)`
  padding-right: 8px;
`

const StyledBetaBanner = styled(BetaBanner)`
  top: -35px;
  left: 40px;
  transform: scale(0.65);
`

type Props = {
  caseId: number
  slideId: number
}

const SearchLeftPanelContainer = ({ caseId, slideId }: Props) => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { source } = useViewerIdSlideState(viewerId)
  const { gridIsVisible } = useViewerMainSelector(viewerId)
  const { changeViewerSlide } = useViewerPageProvided()
  const { isError, isLoading } = useSearchGridQuery(caseId, slideId, source)
  const { t } = useTranslation()
  const initialBbox = useTypedSelector((state) => state.search.mainSelectedBbox)
  const mainSelectedSimilarRegion = useTypedSelector((state) => state.search.mainSelectedSimilarRegion)

  const { targetUserId } = useUserStatusContext()

  const clickHandler = useCallback(
    async (similar: ISimilarSlide, bbox: ISelectedBbox) => {
      //check slide available
      const res = await annotationsService.getAnnotations(similar.caseId, similar.slideId).catch((e) => {
        if (e?.response?.status === 403) {
          notices.error({
            message: t('Нет доступа к слайду'),
          })
          return null
        }
      })
      if (!res) return
      viewerDispatch(viewerSlice.actions.setGridIsVisible(false))
      viewerDispatch(viewerSlice.actions.setSelectedBbox(bbox))
      slideId !== similar.slideId &&
        changeViewerSlide(viewerId, {
          caseId: similar.caseId,
          slideGroupType: 'MICRO',
          slideId: similar.slideId,
          source: similar.source || 'PLATFORM',
          viewerMode: 'SEARCH',
        })
    },
    [viewerDispatch],
  )

  const resetSelection = () => {
    if (mainSelectedSimilarRegion && mainSelectedSimilarRegion.slideId !== slideId) {
      viewerDispatch(viewerSlice.actions.setSelectedBbox(initialBbox))
      changeViewerSlide(viewerId, {
        caseId: mainSelectedSimilarRegion.caseId,
        slideGroupType: 'MICRO',
        slideId: mainSelectedSimilarRegion.slideId,
        source: mainSelectedSimilarRegion.source,
        viewerMode: 'DEFAULT',
      })
    }
  }

  useEffect(() => () => viewerDispatch(viewerSlice.actions.setGridIsVisible(false)), [])

  if (targetUserId !== undefined) {
    return (
      <Info>
        <ParagraphElement type="secondary">{t('Чтобы воспользоваться поиском отключите следование.')}</ParagraphElement>
      </Info>
    )
  }
  if (!mainSelectedSimilarRegion) {
    return (
      <SpinElement spinning={isLoading}>
        <Info>
          <ParagraphElement type="secondary">
            {t('Найдите случаи с похожей морфологией и уже поставленным диагнозом')}
          </ParagraphElement>
          <StyledBetaBanner />
          <StyledDivider />
          <SearchButton
            slideId={slideId}
            caseId={caseId}
            isVisible={gridIsVisible}
            source={source}
            setVisible={() => {
              viewerDispatch(viewerPageSlice.actions.openTool('SEARCH_MORPHOLOGY'))
              viewerDispatch(viewerSlice.actions.setGridIsVisible(true))
            }}
            text={`${t('Найти')}${ELLIPSIS}`}
          />
        </Info>
      </SpinElement>
    )
  }

  return (
    <Column>
      <Info>
        <TextElement>{t('Оригинал')}</TextElement>
        {slideId && initialBbox && (
          <AdaptiveThumbnail activeCursor={viewerId}>
            <ImageElement src={initialBbox.thumbnailUrl} onClick={resetSelection} />
          </AdaptiveThumbnail>
        )}

        <>
          <SpaceElement size={8} />
          {isError ? (
            <Error type="danger">{t('Ошибка при получении сетки поиска.')}</Error>
          ) : (
            <SearchButton
              isVisible={gridIsVisible}
              caseId={caseId}
              source={source}
              setVisible={() => {
                resetSelection()
                viewerDispatch(viewerPageSlice.actions.openTool('SEARCH_MORPHOLOGY'))
                viewerDispatch(viewerSlice.actions.setGridIsVisible(true))
              }}
              text={`${t('Найти снова')}${ELLIPSIS}`}
              slideId={slideId}
            />
          )}
          <SpaceElement size={8} />
          <Divider />
          <SpaceElement size={8} />
        </>
      </Info>
      <SimilarSlidesListContainer clickHandler={clickHandler} />
    </Column>
  )
}

export default SearchLeftPanelContainer
