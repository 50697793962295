import caseService from 'features/cases/api/service'
import { notices } from 'features/notices'
import { useAttachUploadedFiles, useDeleteUploadFiles, useMoveToTrash, useRestoreBinTrash } from 'features/sump'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { declensionWord } from 'shared/lib/text'
import { ButtonElement } from 'shared/ui/kit'
import { PopoverComponent, PopoverWithCloseBtn } from 'shared/ui/popover'
import styled from 'styled-components'
import ICase from 'types/ICase'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

import CaseCreationAlertPanel from './CaseCreationAlertPanel'
import LinkingSlidePanel from './LinkingSlidePanel'
import { SUMP_TAB } from './SumpTopActions'

const SelectedCount = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

type Props = {
  activeTab: SUMP_TAB
  selectedRows: IUploadedFileDTO[]
  setSelectedRows: (rows: IUploadedFileDTO[]) => void
  setSelectedRowKeys: (Keys: React.Key[]) => void
  /** Функция для проброса стейта наверх. После выделения строки пробрасываем тип выделенной ячейки для отображения экшенов */
  updateSelectedTypeTab: (type?: SUMP_TAB) => void
}

const SumpActions: React.FC<Props> = ({
  activeTab,
  selectedRows,
  setSelectedRowKeys,
  setSelectedRows,
  updateSelectedTypeTab,
}) => {
  const { t } = useTranslation()
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(false)
  const [isAlertPanelOpened, setIsAlertPanelOpened] = useState<boolean>(false)
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const { mutate: moveToTrash } = useMoveToTrash()
  const { mutate: restoreBinTrash } = useRestoreBinTrash()
  const { mutate: deleteFiles } = useDeleteUploadFiles()
  const { mutateAsync: attachFiles } = useAttachUploadedFiles()

  const toggleIsPanelOpened = () => {
    setSearchValue('')
    setIsPanelOpened(!isPanelOpened)
  }
  const toggleIsAlertPanelOpened = () => {
    setIsPanelOpened(false)
    setIsAlertPanelOpened(!isAlertPanelOpened)
  }

  const clearSelected = () => {
    setSelectedRows([])
    setSelectedRowKeys([])
    updateSelectedTypeTab()
  }

  const clearAll = () => {
    setIsPanelOpened(false)
    setIsAlertPanelOpened(false)
    setIsLoadingRequest(false)
    setSearchValue('')
    clearSelected()
  }

  const onMoveFile = async (targetCase?: ICase) => {
    setIsLoadingRequest(true)
    let newCase = null
    if (!targetCase && searchValue) {
      try {
        newCase = await caseService.createCaseByExternalId(searchValue)
      } catch (e: any) {
        setIsLoadingRequest(false)
        if (e.response.status === 409) {
          notices.error({
            key: 'existing-case-error',
            message: t('Случай уже существует.'),
          })
        }
        if (e?.response?.status === 418) {
          notices.error({
            message: t('Превышено максимальное количество медицинских случаев в рамках лицензии'),
          })
        }
      }
    }
    const slidesIds = selectedRows.map((slide) => slide.uploadedFileId)

    const payload = {
      targetCaseId: targetCase?.caseId || newCase?.caseId || 0,
      uploadedFileIds: slidesIds,
    }

    if (payload.targetCaseId) {
      await attachFiles(payload, {
        onError: clearAll,
        onSuccess: clearAll,
      })
    }
  }

  const onMoveToTrash = () => {
    moveToTrash({ uploadedFileIds: selectedRows.map((slide) => slide.uploadedFileId) })
    clearSelected()
  }

  const onRestoreBinTrash = () => {
    restoreBinTrash({ uploadedFileIds: selectedRows.map((slide) => slide.uploadedFileId) })
    clearSelected()
  }

  const onDelete = () => {
    deleteFiles({ uploadedFileIds: selectedRows.map((slide) => slide.uploadedFileId) })
    clearSelected()
  }

  const updateBtn = useMemo(() => {
    switch (activeTab) {
      case 'AWAITING_CASE':
      case 'NOT_ATTACHED_TO_CASE':
        return <ButtonElement onClick={toggleIsPanelOpened}>{t('Привязать к случаю')}</ButtonElement>
      case 'ATTACHED_TO_CASE':
        return <ButtonElement onClick={toggleIsPanelOpened}>{t('Изменить случай')}</ButtonElement>
      case 'DELETED':
        return <ButtonElement onClick={onRestoreBinTrash}>{t('Восстановить')}</ButtonElement>
    }
  }, [activeTab, selectedRows.length])

  const deleteBtn = useMemo(() => {
    switch (activeTab) {
      case 'AWAITING_CASE':
      case 'NOT_ATTACHED_TO_CASE':
        return <ButtonElement onClick={onMoveToTrash}>{t('В корзину')}</ButtonElement>
      case 'ATTACHED_TO_CASE':
      case 'DELETED':
        return <ButtonElement onClick={onDelete}>{t('Удалить')}</ButtonElement>
    }
  }, [activeTab, selectedRows.length])

  return (
    <>
      <SelectedCount>
        {selectedRows.length}{' '}
        {declensionWord(selectedRows.length, [t('Изображение'), t('Изображения'), t('Изображений')])}
      </SelectedCount>
      <div>
        {updateBtn}
        <PopoverWithCloseBtn
          titleText={t('Привязка слайдов к случаю')}
          onClose={toggleIsPanelOpened}
          placement="bottom"
          visible={isPanelOpened}
          content={
            <LinkingSlidePanel
              isLoadingRequest={isLoadingRequest}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              toggleIsAlertPanelOpened={toggleIsAlertPanelOpened}
              onAttachUploadFiles={onMoveFile}
            />
          }
        />
        <PopoverComponent
          visible={isAlertPanelOpened}
          content={
            <CaseCreationAlertPanel
              search={searchValue}
              onClose={toggleIsAlertPanelOpened}
              onMoveUploadFiles={onMoveFile}
            />
          }
          placement="bottom"
        />
      </div>
      {deleteBtn}
    </>
  )
}

export default SumpActions
