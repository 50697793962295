import _ from 'lodash'
import { QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { post, QUERY_TYPE } from 'shared/api'
import IListOfItemsCursor from 'types/api/IListOfItemsCursor'
import { IAtlasSlide, IAtlasValidationStatus } from 'types/IAtlasSlide'

import atlasService from './service'

export type AtlasSlidesFilters = {
  clientCode?: string[]
  stainCode?: string[]
  uploadedDateFrom?: string
  uploadedDateTo?: string
  validationStatus?: boolean[]
}

export type AtlasDownloadData = {
  caseId: number
  slideId: number
  type: 'CONVERTED_BY_VIPS' | 'ORIGINAL'
}

export type PaginatedAtlasSlidesFilters = {
  after?: number
  before?: number
  direction?: 'next' | 'prev'
} & AtlasSlidesFilters

type UnusedSlidesProps = {
  /** Все данные по всем страницам атласа */
  slides: IListOfItemsCursor<IAtlasSlide>
  /** queryClient */
  queryClient: QueryClient
}
export const setUnusedSlidesSlidesQuery = ({ queryClient, slides }: UnusedSlidesProps) => {
  const unusedSlides: number[] | undefined = queryClient.getQueryData([QUERY_TYPE.ATLAS, 'unusedSlides'])
  const resultIds: number[] = slides.content.map((item) => item.slideId)
  queryClient.setQueryData([QUERY_TYPE.ATLAS, 'unusedSlides'], _.union(unusedSlides, resultIds))
}
export const useAtlasSlides = (filters: PaginatedAtlasSlidesFilters, slideId?: string) => {
  const queryClient = useQueryClient()
  const { clientCode, stainCode } = { ...filters }
  const query = useInfiniteQuery(
    [QUERY_TYPE.ATLAS, clientCode, stainCode],
    async (data) => {
      if (!data) return
      let after, before
      if (!data.pageParam) {
        after = slideId
        before = undefined
      } else {
        const { cursor, direction } = data.pageParam
        after = direction === 'next' ? cursor : undefined
        before = direction === 'prev' ? cursor : undefined
      }
      const result = await atlasService.fetchAtlasSlides({
        ...filters,
        after,
        before,
        direction: undefined,
      })
      if (!result) return
      setUnusedSlidesSlidesQuery({ queryClient, slides: result })
      result.content.forEach((atlasSlide) => {
        const cacheRecord = queryClient.getQueryData<IAtlasSlide>([QUERY_TYPE.ATLAS, atlasSlide.slideId])
        if (!cacheRecord) {
          queryClient.setQueryData([QUERY_TYPE.ATLAS, atlasSlide.slideId], atlasSlide)
        }
      })
      return result
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage?.last
          ? false
          : {
              cursor: lastPage?.nextCursor,
              direction: 'next',
            },
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage?.first
          ? false
          : { cursor: firstPage?.previousCursor || allPages[1]?.previousCursor, direction: 'prev' },
      keepPreviousData: true,
    },
  )

  const pages = query.data?.pages || []
  const ids = pages.flatMap((page) => page?.content.map((slide) => slide.slideId))
  return {
    ...query,
    ids,
  }
}

export const usePaginatedAtlasSlidesQuery = (filters: PaginatedAtlasSlidesFilters) => {
  const queryClient = useQueryClient()
  return useQuery(
    [QUERY_TYPE.ATLAS, filters],
    async () => {
      const result = await atlasService.fetchAtlasSlides({
        ...filters,
        after: filters?.direction === 'next' ? filters.after : undefined,
        before: filters?.direction === 'prev' ? filters.before : undefined,
        direction: undefined,
      })
      if (!result) return
      setUnusedSlidesSlidesQuery({ queryClient, slides: result })
      return result
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useAtlasSlideQuery = (slideId: number) => {
  const queryClient = useQueryClient()
  return useQuery([QUERY_TYPE.ATLAS, slideId], () => queryClient.getQueryData<IAtlasSlide>([QUERY_TYPE.ATLAS, slideId]))
}

export const useChangeValidationStatusMutation = (slideId: number) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (validationStatus: IAtlasValidationStatus) => {
      await post({
        config: {
          params: {
            validationStatus,
          },
        },
        url: `/atlas/slide/${slideId}/validation`,
      })
      return validationStatus
    },

    {
      onSuccess: (res: IAtlasValidationStatus) => {
        const slide = queryClient.getQueryData<IAtlasSlide>([QUERY_TYPE.ATLAS, slideId])
        slide &&
          queryClient.setQueryData<IAtlasSlide>([QUERY_TYPE.ATLAS, slideId], {
            ...slide,
            validationStatus: res,
          })
      },
    },
  )
}
export const DownloadAtlasSlides = async (data: AtlasDownloadData[]) => {
  try {
    const res = await post({
      data,
      url: `/slide/download/atlas`,
    })
    return res
  } catch (e) {
    throw e
  }
}
