import { Menu } from 'antd'
import type { MenuProps } from 'antd/es/menu'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { FC } from 'react'
import styled from 'styled-components/macro'

const StyledMenu = styled(Menu)`
  .ant-menu-item-danger.ant-menu-item {
    color: var(--color-red);
  }

  &.ant-menu-root.ant-menu-vertical {
    display: grid;
    padding: 8px 0;
    ${() => modalBackground}
    ${() => modalShadow}
    border-radius: 5px;
  }

  &.ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    padding: 0 16px;
    margin: 0;
    height: 32px;
    line-height: 24px;
  }

  &.ant-menu-vertical .ant-menu-item:hover,
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background: var(--color-bg-3);
  }

  &.ant-menu-item-danger.ant-menu-item:hover,
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: var(--color-red);
  }

  &.ant-menu-vertical .ant-menu-item:not(.ant-menu-item-danger.ant-menu-item):hover {
    color: var(--color-text-1);
  }

  &.ant-menu-item .ant-menu-item-icon + span {
    margin-left: 4px;
  }
`

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  color: var(--color-text-1);
`

export const MenuElement: FC<MenuProps> = (props) => <StyledMenu {...props} />

export const MenuItemElement = StyledMenuItem
