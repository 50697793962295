import { InputRef } from 'antd'
import Input, { InputProps } from 'antd/es/input'
import { forwardRef } from 'react'
import styled from 'styled-components/macro'

const StyledInput = styled(Input)`
  padding: 4px 8px;
  line-height: 16px;
  background: var(--color-bg-3);
  color: var(--color-text-1);

  ::selection {
    background: var(--color-purple);
    color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .ant-input {
    background: var(--color-bg-3);
    color: var(--color-text-1);
  }

  &.ant-input:focus,
  &.ant-input-focused {
    box-shadow: 0 0 0 1px var(--color-purple) !important;
  }

  &::placeholder {
    -webkit-text-fill-color: var(--color-text-3);
  }

  &.ant-input-affix-wrapper > input.ant-input::placeholder {
    -webkit-text-fill-color: var(--color-text-3);
  }

  &.ant-input-affix-wrapper .input.ant-input::placeholder {
    color: var(--color-text-3);
  }

  .ant-input-prefix {
    color: var(--color-text-3);
  }
`

export const InputElement = forwardRef<InputRef, InputProps>((props, ref) => (
  <StyledInput ref={ref} autoComplete="off" {...props} />
))
export const InputPasswordElement = (props: InputProps) => <StyledInput {...props} as={Input.Password} />
