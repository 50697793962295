import { Feature } from 'ol'
import { IAnnotation } from 'types/IAnnotations'
/**
 * @param annotation - Аннотация
 * @returns Сохранена ли аннотация только локально
 */
export const checkLocalAnnotation = (annotation: IAnnotation): boolean => annotation.slideAnnotationId < 0

/**
 * @param feature - Фича аннотации
 * @returns Сохранена ли аннотация только локально
 */
export const checkLocalAnnotationFromFeature = (feature: Feature<any>): boolean => feature.get('slideAnnotationId') < 0

export const mergeAnnotations = (existingAnnotations: IAnnotation[], newAnnotations: IAnnotation[]): IAnnotation[] => {
  const annotationMap = new Map<number, IAnnotation>()

  // Добавление существующих аннотаций в Map
  existingAnnotations.forEach((annotation) => {
    annotationMap.set(annotation.slideAnnotationId, annotation)
  })

  // Добавление новых аннотаций в Map
  newAnnotations.forEach((annotation) => {
    annotationMap.set(annotation.slideAnnotationId, annotation)
  })

  // Возвращаем массив аннотаций с перезаписыванными новыми аннотациями
  return Array.from(annotationMap.values())
}
