import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import { AnnotationFilter, AnnotationType, IClickConditionType } from 'types/IAnnotations'
import createViewerExtraReducers from 'viewer/container/lib/createViewerExtraReducers'

type State = {
  // позиция контекстного меню
  extendedContextMenuPosition: { x: number; y: number } | null
  // тип выбранной аннотации
  annotationType?: AnnotationType
  // масиив Ids которые видимы
  annotationsIsVisible: number[] | null
  // массив доступных классов для текущей аннотации
  currentAnnotationByClass: string[] | null
  // тип клика
  clickCondition: IClickConditionType
  // массив пользователей,которым доступна аннотация
  currentAnnotationUsers: string[] | null
  // настройка прозрачности
  annotationsOpacity: number
  // предыдущая настройка прозрачности
  prevAnnotationsOpacity: number
  // выбранный класс, для таск вьвера
  currentClass: string | null
  // активность режима рисования
  drawMode: boolean
  // активность режима изменения
  changeMode: boolean
  // Id аннотации, на котрую навели мышь
  hoveredAnnotationId?: number
  // сосстояние загрузки аннотаций
  annotationsLoading: boolean
  // выбрана ли аннотация из списка праой панели
  selectedFromList?: boolean
  // применненный фильтр для аннотаций
  annotationFilter?: AnnotationFilter | null
  // флаг применения параметров фильтрации
  isAnnotationsFiltered?: boolean
  // флаг процесса ввода значений для фильтрации, до его применения
  isAnnotationsFiltering?: boolean
  // позиция контекстного меню для митозов
  objectsMitosisContextMenu?: { visible: boolean; objectId?: number; slideId?: number }
  // флаг применения упрощенной геометрии
  penSimplifyMode?: boolean
}

const initialState: State = {
  annotationFilter: null,
  annotationType: undefined,
  annotationsIsVisible: null,
  annotationsLoading: false,
  annotationsOpacity: 0,
  changeMode: false,
  clickCondition: 'default',
  currentAnnotationByClass: null,
  currentAnnotationUsers: null,
  currentClass: null,
  drawMode: false,
  extendedContextMenuPosition: null,
  isAnnotationsFiltered: false,
  objectsMitosisContextMenu: { visible: false },
  prevAnnotationsOpacity: 0,
}

export const annotationsSlice = createSlice({
  extraReducers: {
    ...createViewerExtraReducers<typeof initialState, PayloadAction<boolean>>(
      'viewerAttachmentsSlice/setAttachmentMode',
      (state) => {
        state.annotationType = undefined
        state.extendedContextMenuPosition = null
      },
    ),
  },
  initialState,
  name: 'annotationsSlice',
  reducers: {
    addAnnotationsToVisible(state, { payload }: PayloadAction<number | number[]>) {
      const isArray = Array.isArray(payload)
      const visibleIds = isArray ? payload : [payload]

      if (!state.annotationsIsVisible) {
        state.annotationsIsVisible = visibleIds
      } else if (Array.isArray(state.annotationsIsVisible)) {
        state.annotationsIsVisible = uniq([...state.annotationsIsVisible, ...visibleIds])
      }
    },
    resetAnnotationFilter(state) {
      state.annotationFilter = null
      state.isAnnotationsFiltered = false
      state.isAnnotationsFiltering = false
    },
    setAnnotationFilter(state, { payload }: PayloadAction<AnnotationFilter>) {
      state.annotationFilter = payload
      state.isAnnotationsFiltered =
        !!payload?.userIds?.length || !!payload?.period?.fromDate || !!payload?.period?.toDate
    },
    setAnnotationFiltering(state, { payload }: PayloadAction<boolean>) {
      state.isAnnotationsFiltering = payload
    },
    setAnnotationType(state, { payload }: PayloadAction<AnnotationType | undefined>) {
      state.annotationType = payload
    },
    setAnnotationsIsVisible(state, { payload }: PayloadAction<number[] | null>) {
      if (payload === null) {
        state.annotationType = undefined
      }
      // предотвращаем появление пустого массива в ячейке для облегчения проверок состояния в компонентах
      if (Array.isArray(payload) && payload.length === 0) {
        state.annotationsIsVisible = null
        return
      }
      state.annotationsIsVisible = payload
    },
    setAnnotationsIsVisibleByClass(state, { payload }: PayloadAction<string[] | null>) {
      if (payload === null) {
        state.annotationType = undefined
      }
      state.currentAnnotationByClass = payload
    },
    setAnnotationsIsVisibleByUser(state, { payload }: PayloadAction<string[] | null>) {
      if (payload === null) {
        state.annotationType = undefined
      }
      state.currentAnnotationUsers = payload
    },
    setAnnotationsLoading(state, { payload }: PayloadAction<boolean>) {
      state.annotationsLoading = payload
    },
    setAnnotationsOpacity(state, { payload }: PayloadAction<number>) {
      state.annotationsOpacity = payload
    },
    setChangeMode(state, { payload }: PayloadAction<boolean>) {
      state.changeMode = payload
    },
    setClickCondition(state, { payload }: PayloadAction<IClickConditionType>) {
      state.clickCondition = payload
    },
    setCurrentClass(state, { payload }: PayloadAction<string | null>) {
      if (!payload) {
        state.currentClass = null
      }
      state.currentClass = payload
    },
    setDrawMode(state, { payload }: PayloadAction<boolean>) {
      state.drawMode = payload
    },
    setExtendedContextMenuPosition(state, { payload }: PayloadAction<{ x: number; y: number } | null>) {
      state.extendedContextMenuPosition = payload
    },
    setHoveredAnnotationId(state, { payload }: PayloadAction<number | undefined>) {
      if (!payload) {
        state.hoveredAnnotationId = undefined
      }
      state.hoveredAnnotationId = payload
    },
    setInitialState(state) {
      return {
        ...initialState,
        annotationsOpacity: state.annotationsOpacity,
        prevAnnotationsOpacity: state.prevAnnotationsOpacity,
      }
    },
    setObjectsMitosisContextMenuVisible(
      state,
      { payload }: PayloadAction<{ visible: boolean; objectId?: number; slideId?: number }>,
    ) {
      state.objectsMitosisContextMenu = payload
    },
    setPenSimplifyMode(state, { payload }: PayloadAction<boolean>) {
      state.penSimplifyMode = payload
    },
    setPrevAnnotationsOpacity(state, { payload }: PayloadAction<number>) {
      state.prevAnnotationsOpacity = payload
    },
    setSelectedFromList(state, { payload }: PayloadAction<boolean | undefined>) {
      state.selectedFromList = payload
    },
    toggleAnnotationType(state, { payload }: PayloadAction<AnnotationType | undefined>) {
      state.annotationType = state.annotationType !== payload ? payload : undefined
    },
  },
})
