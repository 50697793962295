import { Footer } from 'features/reports/ui/styled'
import { useTranslation } from 'react-i18next'
import { ButtonElement } from 'shared/ui/kit'

type Props = {
  /** Сохранить в черновик */
  onSaveDraft: (signed?: boolean) => void
  /** Подписать в ЛИС */
  onSign: () => void
  /** Закрыть фому */
  onCancel?: () => void
  /** Состояние сохранения черновика заключения */
  isDraftSaving: boolean
  /** Состояние сохранения подписания заключения */
  isSigning: boolean
  /** Статус ошибки, при котором заключение можно создать 1 раз, а он уже существует */
  isConflictError: boolean
}

export const GemotestFooter = ({ isConflictError, isDraftSaving, isSigning, onSaveDraft, onSign }: Props) => {
  const { t } = useTranslation()
  return (
    <Footer>
      <ButtonElement
        style={{ gridColumn: 5 }}
        onClick={() => onSaveDraft()}
        loading={isDraftSaving}
        disabled={isConflictError}
      >
        {t('Сохранить черновик')}
      </ButtonElement>
      <ButtonElement
        type="primary"
        style={{ gridColumn: 6 }}
        onClick={onSign}
        loading={isSigning}
        disabled={isConflictError}
      >
        {t('Подписать')}
      </ButtonElement>
    </Footer>
  )
}
