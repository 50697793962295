import { useTypedSelector } from 'app/redux/lib/selector'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components'
import { AnnotationType } from 'types/IAnnotations'
import TViewerId from 'types/TViewerId'
import { useObjectsCountingContext } from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import ObjectsContainer from './ObjectsContainer'

/** Props for ObjectsToolPanel component */
type Props = {
  /** id текущего вьювера */
  viewerId: TViewerId
  /** ссылка на элемент панели митозов */
  mitosisRef: React.RefObject<HTMLDivElement>
  /** начальные координаты положения панели митозов */
  initPosition: number[]
  /** коллбэк для изменения позиции панели митозов */
  onPositionChange: (position: number[]) => void
}

const ObjectsToolPanel = ({ initPosition, mitosisRef, onPositionChange, viewerId }: Props) => {
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType)
  const { isMitosisInitialized } = useObjectsCountingContext()
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const { t } = useTranslation()

  const OPTIONS = {
    MITOSIS: t('Детекция митозов'),
    OBJECTS: t('Подсчет клеток'),
  }
  return (
    <ViewerInfoToolPanelContainer
      type={AnnotationType.MITOSIS}
      panelRef={mitosisRef}
      id={AnnotationType.MITOSIS}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      style={css`
        flex-grow: 1;
      `}
      position={position}
      setPosition={setPosition}
      title={countingObjectType ? OPTIONS[countingObjectType] : ''}
    >
      {isMitosisInitialized && <ObjectsContainer viewerId={viewerId} />}
    </ViewerInfoToolPanelContainer>
  )
}
export default ObjectsToolPanel
