import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useQuery } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { ECaseLinkFilter } from 'types/ICase'

import { caseInfoService } from './service'

export const useCaseLinkQuery = ({
  caseId,
  caseLinkType = ECaseLinkFilter.ALL,
  isMarkup,
}: {
  caseId: number
  caseLinkType?: ECaseLinkFilter
  isMarkup?: boolean
}) => {
  const workspaceId = Number(useCurrentWorkspaceId())

  return useQuery([QUERY_TYPE.CASE_LINK, caseId, caseLinkType, workspaceId, isMarkup], async () => {
    if (!isMarkup && caseId) {
      return await caseInfoService.getCaseLink(caseId, workspaceId, caseLinkType)
    }
    return null
  })
}
