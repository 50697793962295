import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { clearDictionaries } from 'features/dictionaries/lib/handlers'
import { logout } from 'processes/user'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import UserOptions from './ui/UserOptions'

export const UserOptionsContainer = () => {
  const dispatch = useDispatch()
  const user = useTypedSelector((state) => state.user.user)
  const { t } = useTranslation()

  const menuItems = useMemo(
    () => [
      {
        id: 'cancel-btn',
        onClick: () => {
          try {
            dispatch(logout())
            dispatch(push('/login'))
            clearDictionaries()
          } catch (e) {
            dispatch(push('/login'))
            console.log(e)
          }
        },
        title: t('Выйти'),
      },
    ],
    [t],
  )
  return user ? <UserOptions user={user} menuItems={menuItems} /> : null
}
