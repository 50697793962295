import { useCasesCountQuery } from 'features/cases-management/api/query'
import {
  EAllCasesTabType,
  ECaseTableType,
  EDistributionTabType,
  EMyCasesTabType,
} from 'features/cases-management/types/ECaseTableType'
import CaseManagementTabContext from 'features/cases-management/ui/CaseManagementTabContext'
import { CasesTableTabs } from 'features/cases-management/ui/cases-table/CasesTableTabs'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'

type TTabInfo = {
  /** Название таба */
  label: string
  tabType: EAllCasesTabType | EMyCasesTabType | EDistributionTabType
  color?: string
  /** Видимость таба, дефолт = true */
  visible?: boolean
}

const CasesTabsContainer = () => {
  const { t } = useTranslation()
  const { menuTab } = useCaseManagementRouteParam()
  const { data } = useCasesCountQuery(menuTab)
  const {
    isCaseRouting,
    isLisMode,
    isRemoveVisible,
    isRoutingVisible,
    isWorklistVisible,
    isWorkspaceVisible,
    roles: { isRoleUser },
  } = useSettingsAndUserRoles()

  const tabs: Record<ECaseTableType, { visible?: boolean; tabs: TTabInfo[] }> = {
    [ECaseTableType.ROUTING]: {
      tabs: [
        { label: t('Все без заключения'), tabType: EDistributionTabType.NOT_COMPLETED },
        { color: 'var(--color-green)', label: t('Распределено'), tabType: EDistributionTabType.ASSIGNED },
        {
          color: 'var(--color-blue)',
          label: t('Не распределено'),
          tabType: EDistributionTabType.NOT_ASSIGNED,
        },
        {
          color: 'var(--color-red)',
          label: t('Перераспределение'),
          tabType: EDistributionTabType.DECLINED,
        },
      ],
      visible: isRoutingVisible,
    },
    [ECaseTableType.WORKLIST]: {
      tabs: [
        {
          color: 'var(--color-text-1)',
          label: t('Рабочий список'),
          tabType: EMyCasesTabType.ASSIGNED_TO_ME,
          visible: isRoleUser,
        },
        {
          color: 'var(--color-text-1)',
          label: t('Входящие консультации'),
          tabType: EMyCasesTabType.INCOMING,
        },
        {
          color: 'var(--color-text-1)',
          label: t('Отслеживаемые случаи'),
          tabType: EMyCasesTabType.FAVORITES,
          visible: isRoleUser && isCaseRouting,
        },
      ],
      visible: isWorklistVisible,
    },
    [ECaseTableType.WORKSPACE]: {
      tabs: [
        { label: t('Все статусы'), tabType: EAllCasesTabType.ALL_STATUSES },
        {
          color: 'var(--color-text-1)',
          label: t('Открытые'),
          tabType: EAllCasesTabType.OPENED,
        },
        {
          color: 'var(--color-text-1)',
          label: isLisMode ? t('Подписанные') : t('Завершённые'),
          tabType: EAllCasesTabType.COMPLETED,
        },
        {
          color: 'var(--color-text-1)',
          label: t('Удалённые случаи'),
          tabType: EAllCasesTabType.DELETED,
          visible: isRemoveVisible,
        },
      ],
      visible: isWorkspaceVisible,
    },
  }

  const visibleMenuTabs = Object.fromEntries(
    Object.entries(tabs).filter(([_, value]) => value.visible === undefined || value.visible),
  )

  const tabInfo = visibleMenuTabs[menuTab]?.tabs || [
    {
      color: 'var(--color-text-1)',
      label: t('Входящие консультации'),
      tabType: EMyCasesTabType.INCOMING,
    },
  ]

  const tabsItems = tabInfo
    .filter(({ visible }) => visible === undefined || visible)
    .map(({ color, label, tabType }, index) => ({
      color: color || 'var(--color-text-1)',
      count: data?.[tabType].total,
      id: (index + 1).toString(),
      label: t(label),
      specialCount:
        !isCaseRouting && tabType === EMyCasesTabType.ASSIGNED_TO_ME
          ? data?.[EAllCasesTabType.OPENED]?.total
          : undefined,
      tabType,
    }))

  return (
    <CaseManagementTabContext>
      <CasesTableTabs tabsItems={tabsItems} />
    </CaseManagementTabContext>
  )
}

export default CasesTabsContainer
