import { Resizable } from 're-resizable'
import { Direction } from 're-resizable/lib/resizer'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'
import { MARGIN_BORDER, MIN_WIDTH_PANEL, RESIZABLE_OFFSET } from 'viewer/tools/ui/lib/constans'
import { IGNORE_IN_SCREENSHOT_ID } from 'viewer/tools/ui/screenshot/ScreenShotModal'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import SlideInfoContainer from './SlideInfoContainer'

const StyledResizable = styled(Resizable)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

// начальное состояние
const INITIAL_SIZES = [232, 0]

type Props = {
  viewerId: TViewerId
  slideInfoRef: React.RefObject<HTMLDivElement>
  slideId: number
  caseId: number
  source: ISource
  size?: [number, number]

  initPosition: number[]
  onPositionChange: (position: number[]) => void
}
const SlideInfoToolPanel = ({
  caseId,
  initPosition,
  onPositionChange,
  size,
  slideId,
  slideInfoRef,
  source,
  viewerId,
}: Props) => {
  /** Временное решение проблемы */
  const [minWidth, setMinWidth] = useState(MIN_WIDTH_PANEL)

  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const [backgroundSizes, setBackgroundSizes] = useState<number[]>()
  const getParentBlock = () => slideInfoRef?.current?.parentElement?.parentElement?.parentElement?.parentElement
  const [maxHeight, setMaxHeight] = useState<number>(minWidth)
  const [maxWidth, setMaxWidth] = useState<number>(0)
  const parentBlockBottomCoordinate = getParentBlock()?.getBoundingClientRect().bottom || 0
  const parentBlockRightCoordinate = getParentBlock()?.getBoundingClientRect().right || 0

  const resizeSlideInfoTool = (el?: HTMLElement) => {
    const editBoxElement = document.getElementsByClassName('edit-box')[0]
    const toolElement = el || editBoxElement?.parentElement
    // @ts-ignore
    const { height, left, top, width } = toolElement.getBoundingClientRect()
    const bottomCoordinate = height + top
    const rightCoordinate = width + left

    if (
      (bottomCoordinate > parentBlockBottomCoordinate - MARGIN_BORDER ||
        rightCoordinate > parentBlockRightCoordinate - MARGIN_BORDER) &&
      toolElement?.style.width &&
      toolElement?.style.height
    ) {
      const newWidth = +toolElement?.style?.width.slice(0, -2)
      const newHeight = +toolElement?.style?.height.slice(0, -2)
      setBackgroundSizes([newWidth, newHeight])
    } else {
      setBackgroundSizes([width, height])
    }
  }
  const onResizeHandler = (w: MouseEvent | TouchEvent, direction: Direction, el: HTMLElement) => resizeSlideInfoTool(el)

  const getParentBlockSizes = () => {
    const parentBlock = getParentBlock()

    return [parentBlock?.getBoundingClientRect().width || 0, parentBlock?.getBoundingClientRect().height || 0]
  }
  const parentBlockHeight = getParentBlockSizes()[1]
  const parentBlockWidth = getParentBlockSizes()[0]

  useEffect(() => {
    setMaxWidth(parentBlockWidth - RESIZABLE_OFFSET)
    setMaxHeight(parentBlockHeight - RESIZABLE_OFFSET)
  }, [parentBlockWidth, parentBlockHeight])

  useEffect(() => {
    setMinWidth(MIN_WIDTH_PANEL)
  }, [])

  useEffect(() => {
    setBackgroundSizes(INITIAL_SIZES)
  }, [slideId])

  return (
    <ViewerInfoToolPanelContainer
      id={IGNORE_IN_SCREENSHOT_ID}
      panelRef={slideInfoRef}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      type={'SLIDE_INFO'}
      position={position}
      mapSizes={backgroundSizes}
      setPosition={setPosition}
    >
      <StyledResizable
        data-testid={'data-info-panel'}
        minWidth={minWidth}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        lockAspectRatio={true}
        size={{
          height: 'auto',
          width: backgroundSizes ? backgroundSizes[0] : '',
        }}
        handleStyles={{ bottomRight: { bottom: -16, right: -16, zIndex: 10 } }}
        onResize={onResizeHandler}
        enable={{ bottomRight: true }}
      >
        <SlideInfoContainer
          backGroundSizes={backgroundSizes}
          viewerId={viewerId}
          slideId={slideId}
          caseId={caseId}
          size={size}
          source={source}
        />
      </StyledResizable>
    </ViewerInfoToolPanelContainer>
  )
}

export default SlideInfoToolPanel
