import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useSlideCache } from 'entities/slide'
import slideService from 'entities/slide/api/service'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { ImageElement } from 'shared/ui/kit'
import IUserRole from 'types/IUserRole'
import { SettingsNameEnum } from 'types/IWorkspaces'

import Thumbnail from './ui/Thumbnail'
import ThumbnailSpin, { ThumbnailRemoveSpin } from './ui/ThumbnailSpin'

type Access = {
  fieldName: string
  value: boolean
}

type Props = {
  slideId: number
  caseId: number
  withDeleteButton?: boolean
  isOwner?: boolean
  refetch: () => void
}

const ThumbnailContainer = ({ caseId, isOwner, refetch, slideId, withDeleteButton = false }: Props) => {
  const workspaceId = useCurrentWorkspaceId()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace) || null
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isImageManager = authorities?.includes(IUserRole.ROLE_IMAGE_MANAGER)

  const notificationKey = caseId + '' + slideId
  const dispatch = useDispatch()
  const slide = useSlideCache(slideId)

  const { isLoading: isDeleting, mutateAsync: mutateDeleteSlide } = useMutation(
    async () => {
      await slideService.deleteSingleSlide(caseId, slideId)
    },
    {
      onSuccess: refetch,
    },
  )
  const { mutateAsync: mutateRestoreSlide } = useMutation(() => slideService.restoreSingleSlide(caseId, slideId), {
    onSuccess: refetch,
  })
  const onClick = useCallback(() => {
    dispatch(push(`/workspace/${workspaceId}/viewer/${caseId}?slideId=${slideId}`))
  }, [caseId, slideId])

  const onRestore = async () => {
    try {
      await mutateRestoreSlide()
      notices.close(notificationKey)
    } catch (e) {
      console.log(e)
    }
  }

  const onDelete = async () => {
    await mutateDeleteSlide()
    notices.openOnSlideDeleteNotification({
      caption: slide?.slideMetadata?.commonMetadata?.caption,
      id: slide?.slideId,
      key: notificationKey,
      onRestore,
      restoreDisabled: slide?.state !== 'AVAILABLE',
      src: slide?.thumbnails.small,
    })
  }

  const deleteCondition = !!(
    !isDeleting &&
    withDeleteButton &&
    isOwner &&
    (slide?.state === 'AVAILABLE' || slide?.state === 'FAILED')
  )

  if (slide) {
    if (slide.state === 'PROCESSING') {
      return (
        <Thumbnail>
          <ThumbnailSpin />
        </Thumbnail>
      )
    }
    return (
      <Thumbnail
        onDelete={
          isImageManager &&
          getBooleanOrDefaultAccess(SettingsNameEnum.CanRemoveImage, true, currentWorkspace) &&
          deleteCondition
            ? onDelete
            : undefined
        }
      >
        <ThumbnailRemoveSpin spinning={isDeleting}>
          <ImageElement src={slide.thumbnails.small} onClick={onClick} />
        </ThumbnailRemoveSpin>
      </Thumbnail>
    )
  }
  return null
}

export default ThumbnailContainer
