import { Select } from 'antd'
import React, { useState } from 'react'
import { createCapitalString } from 'shared/lib/metadata'
import { SelectElement, SelectElementProps, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'

const { Option } = Select

const SiteLabel = styled.div`
  display: grid;
  column-gap: 16px;
  width: 100%;
  white-space: normal;
  text-overflow: initial;
  padding-top: 2px;
`

type Props = {
  sites: DictionaryItem[]
} & Omit<SelectElementProps, 'options' & 'groups'>

const SiteSelectElement = ({ sites, ...rest }: Props) => {
  const [searchValue, onSearch] = useState('')

  return (
    <SelectElement
      {...rest}
      onSearch={onSearch}
      searchValue={searchValue}
      showSearch
      filterOption={false}
      onSelect={() => onSearch('')}
    >
      {sites?.map((site) => createOption(site, searchValue))}
    </SelectElement>
  )
}

const createOption = (site: DictionaryItem, searchValue: string) => {
  if (searchValue && !findQueryInSite(searchValue, site)) {
    return null
  }
  return (
    <Option value={site.id} key={site.shortName}>
      <SiteLabel>
        <TextElement ellipsis>{createCapitalString(site?.name)}</TextElement>
      </SiteLabel>
    </Option>
  )
}

const findQueryInSite = (query: string, site: DictionaryItem) =>
  site.name.toLowerCase().includes(query.toString().toLowerCase()) ||
  site.shortName.toLowerCase().includes(query.toString().toLowerCase())

export default SiteSelectElement
