import { useSlideQuery } from 'entities/slide'
import { notices } from 'features/notices'
import { Feature, MapBrowserEvent } from 'ol'
import { Circle } from 'ol/geom'
import GeometryType from 'ol/geom/GeometryType'
import { fromCircle } from 'ol/geom/Polygon'
import { Draw } from 'ol/interaction'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { memo, useCallback, useEffect, useRef } from 'react'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import { v4 as uuidv4 } from 'uuid'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import {
  SCREEN_RECORD_ZOOM_LVL,
  useObjectsCountingContext,
} from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

type Props = {
  map: IMapOl
  viewerId: TViewerId
  mppX: number
}

const DrawInteraction = memo(({ map, mppX, viewerId }: Props) => {
  const { addSpot, updateCursor } = useObjectsCountingContext()
  const { objectsCountingMethod } = useViewerMainSelector(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)
  const clickCoords = useRef([0, 0])
  const drawRef = useRef<Draw>()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const { data: slide } = useSlideQuery({ caseId, slideId, source })

  const closeDrawing = useCallback(() => {
    viewerDispatch(viewerSlice.actions.setObjectsMode({ active: false }))
  }, [])

  const singleclickHandle = (evt: MapBrowserEvent<UIEvent>) => {
    clickCoords.current = evt.coordinate
    drawRef.current?.abortDrawing()
  }

  const abortDrawingHandle = (evt: any) => {
    const area = objectsCountingMethod === 'one_mm' ? 1000000 / Math.pow(mppX, 2) : 2000000 / Math.pow(mppX, 2)
    const geometry = fromCircle(new Circle(clickCoords.current, Math.sqrt(area / Math.PI)))
    const feature = new Feature(geometry)

    const resolution = map.getView().getResolution() || 0
    const zoomLvl = getResolutionByZoomLevel(resolution, slide)
    const id = uuidv4()
    feature.set('id', id)
    feature.set('type', 'circle')
    try {
      addSpot(feature)

      if (zoomLvl >= SCREEN_RECORD_ZOOM_LVL) {
        updateCursor(clickCoords.current)
      }
    } catch (err: any) {
      notices.error({
        message: err.message,
      })
    }
    closeDrawing()
  }

  useEffect(() => {
    const draw = new Draw({
      source: new VectorSource(),
      style: new Style({
        image: new CircleStyle({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          radius: 5,
          stroke: new Stroke({
            color: '#56a899',
          }),
        }),
      }),
      type: GeometryType.CIRCLE,
    })
    map?.addInteraction(draw)
    map?.on('singleclick', singleclickHandle)

    drawRef.current = draw
    return () => {
      map?.removeInteraction(draw)
      map?.un('singleclick', singleclickHandle)
    }
  }, [objectsCountingMethod])

  useEffect(() => {
    drawRef?.current?.on('drawabort', abortDrawingHandle)
    return () => {
      drawRef?.current?.un('drawabort', abortDrawingHandle)
    }
  }, [drawRef, objectsCountingMethod])

  return null
})

export default DrawInteraction
