import { Feature } from 'ol'
import { Point } from 'ol/geom'
import { Heatmap } from 'ol/layer'
import VectorSource from 'ol/source/Vector'
import React, { useEffect, useRef } from 'react'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import { IMapOl } from 'types/IMapOl'
import ISlide from 'types/ISlide'

/** Props for VectorMitosisHeatMapLayer component */
type Props = {
  /** Объект ol карты */
  map: IMapOl
  /** Массив точек для тепловой карты */
  features: Feature<Point>[]
  /** Текущий слайд */
  slide?: ISlide
  /** Текущий уровень зума */
  zoomLvl?: number
}

const DEFAULT_BLUR = 28
const DEFAULT_RADIUS = 12

const MIN_ZOOM_LVL = 0.5
const MAX_ZOOM_LVL = 1.5

const VectorHeatMapLayer: React.FC<Props> = ({ features, map, slide, zoomLvl }) => {
  const layer = useRef(
    new Heatmap({
      minResolution: getResolutionByZoomLevel(15, slide),
      source: new VectorSource({}),
      zIndex: 10000,
    }),
  )

  useEffect(() => {
    if (zoomLvl) {
      const k = zoomLvl < MIN_ZOOM_LVL ? MIN_ZOOM_LVL : zoomLvl > MAX_ZOOM_LVL ? MAX_ZOOM_LVL : zoomLvl
      layer.current.setBlur(DEFAULT_BLUR)
      layer.current.setRadius(DEFAULT_RADIUS * k)
    }
  }, [zoomLvl])

  useEffect(() => {
    map.addLayer(layer.current)
    return () => {
      map.removeLayer(layer.current)
    }
  }, [])

  useEffect(() => {
    layer.current.getSource().clear()
    layer.current.getSource().addFeatures(features)
  }, [features.length])
  return null
}

export default VectorHeatMapLayer
