import { Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useAtlasSlideQuery, useChangeValidationStatusMutation } from 'entities/atlas'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { InfiniteData, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import styled from 'styled-components/macro'
import IListOfItemsCursor from 'types/api/IListOfItemsCursor'
import { IAtlasSlide } from 'types/IAtlasSlide'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, viewerSlice } from 'viewer/container'

const ValidationButton = styled.div`
  height: 40px;
  width: 100%;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  cursor: pointer;
`

const Good = styled(ValidationButton)<{ isActive: boolean }>`
  border: ${({ isActive }) => !isActive && '2px solid var(--color-green)'};
  background-color: ${({ isActive }) => isActive && 'var(--color-green)'};
`
const Bad = styled(ValidationButton)<{ isActive: boolean }>`
  border: ${({ isActive }) => !isActive && '2px solid var(--color-red)'};
  background-color: ${({ isActive }) => isActive && 'var(--color-red)'};
`
const Default = styled(ValidationButton)<{ isActive: boolean }>`
  border: ${({ isActive }) => !isActive && '2px solid var(--color-bg-4)'};
  background-color: ${({ isActive }) => isActive && 'var(--color-bg-4)'};
`

const Wrapper = styled(Space)`
  width: 100%;
`

type Props = {
  viewerId: TViewerId
}
const ValidationButtonsContainer = ({ viewerId }: Props) => {
  const { slideId } = useViewerIdSlideState(viewerId)
  const { status } = useValidationButtons(viewerId)
  const { mutate } = useChangeValidationStatusMutation(slideId)
  const { activeViewerId } = useOpenViewers()
  const { changeViewerSlide } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(activeViewerId)
  const onSuccess = () => {
    const atlasSlidesData: InfiniteData<IListOfItemsCursor<IAtlasSlide>> | undefined = queryClient.getQueryData([
      QUERY_TYPE.ATLAS,
      clientCode,
      stainCode,
    ])
    const allPagesContent = atlasSlidesData?.pages.map((item) => item.content).flat()
    const currSlides = allPagesContent?.map((item) => item.slideId)
    const unusedSlides: number[] | undefined = queryClient.getQueryData([QUERY_TYPE.ATLAS, 'unusedSlides'])
    if (status && currSlides) {
      const nextSlideId = currSlides[currSlides?.indexOf(slideId) + 1] || currSlides[0]
      queryClient.setQueryData(
        [QUERY_TYPE.ATLAS, 'unusedSlides'],
        unusedSlides?.filter((i) => i !== slideId),
      )
      if (nextSlideId) {
        dispatch(push(`/atlas-viewer/${nextSlideId}`))
        changeViewerSlide(activeViewerId, {
          caseId: NaN,
          slideGroupType: 'MICRO',
          slideId: nextSlideId,
          source: 'ATLAS',
          viewerMode: 'DEFAULT',
        })
        viewerDispatch(viewerSlice.actions.setSelectedBbox())
      }
    }
  }
  useHotkeys(
    'shift+G',
    () => {
      mutate('VALID', { onSuccess })
    },
    [slideId],
  )
  useHotkeys(
    'shift+B',
    () => {
      mutate('INVALID', { onSuccess })
    },
    [slideId],
  )
  useHotkeys(
    'shift+D',
    () => {
      mutate('NOT_VIEWED', { onSuccess })
    },
    [slideId],
  )

  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const filters = useTypedSelector((state) => state.viewerPage.atlasFilters)
  const { clientCode, stainCode } = { ...filters }
  useEffect(() => {}, [status])
  const { t } = useTranslation()

  return (
    <Wrapper direction="vertical" size={8}>
      <Default isActive={status === 'NOT_VIEWED'} onClick={() => mutate('NOT_VIEWED', { onSuccess })}>
        {t('Не просмотрен')}(Shift + D)
      </Default>
      <Good isActive={status === 'VALID'} onClick={() => mutate('VALID', { onSuccess })}>
        {t('Хороший')}(Shift + G)
      </Good>
      <Bad isActive={status === 'INVALID'} onClick={() => mutate('INVALID', { onSuccess })}>
        {t('Плохой')}(Shift + B)
      </Bad>
    </Wrapper>
  )
}

const useValidationButtons = (viewerId: TViewerId) => {
  const { slideId } = useViewerIdSlideState(viewerId)
  const { data } = useAtlasSlideQuery(slideId)
  const status = data?.validationStatus
  return { status }
}

export default ValidationButtonsContainer
