import './scale-ruler.less'

import { useGesture } from '@use-gesture/react'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useTaskQuery } from 'entities/tasks/api/query'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import olMap from 'ol/Map'
import { selectTasksViewerUrlTaskId, selectUrlSlideId } from 'pages/viewer'
import { useMapParamsProvided } from 'pages/viewer/lib/common/MapsProvider'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  DEFAULT_MAXIN,
  DEFAULT_MININ,
  MAX_MIDIN,
  MIN_MIDIN,
  useColorsIsActive,
  useGammaIsActive,
} from 'pages/viewer/model/slideMapViewSlice'
import {
  createContext,
  Dispatch,
  FC,
  MouseEvent,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import useContextMenu from 'shared/lib/hooks/useContextMenu'
import { MouseOverMapContext, nextSlideHandler } from 'shared/lib/map'
import { convertToAnotherRange, convertToFractionalRange } from 'shared/lib/range'
import { ViewerLayoutContext } from 'shared/lib/viewer'
import { getWorkspaceIdUrl } from 'shared/lib/workspaces'
import styled, { css } from 'styled-components/macro'
import ICase from 'types/ICase'
import TViewerId from 'types/TViewerId'
import { useSlideMapViewSelector, useViewerMainSelector } from 'viewer/container'
import { ExtendedAnnotationContextMenu } from 'viewer/map/layers/annotations/ui/ExtendedAnnotationContextMenu'
import { ObjectsAnnotationContextMenu } from 'viewer/map/layers/annotations/ui/ObjectsAnnotationContextMenu'
import SmallAnnotationsContextMenu from 'viewer/map/layers/annotations/ui/SmallAnnotationsContextMenu'

export type IViewParams = {
  center: number[]
  zoom: number
  rotation: number
}
type IBboxCoords = {
  x1: number
  y1: number
  x2: number
  y2: number
}

type IMapContext = {
  viewerId: TViewerId
  mapBbox: IBboxCoords
  setMapBbox: Dispatch<SetStateAction<IBboxCoords>>
  map: olMap
}

type Props = {
  viewerId: TViewerId
  mppX: number
  viewerGridRef: RefObject<HTMLDivElement>
  map: olMap
}
const StyledMapProvider = styled.div<{ annotationsLoading: boolean; id: TViewerId }>`
  .custom-ol-draw-sm-popup .ol-popup-content,
  .custom-ol-draw-tooltip .ol-popup-content {
    background: rgba(247, 245, 246, 0.72);
    backdrop-filter: blur(70px) saturate(5);
    border-radius: 3px;
    color: black;
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    padding: 4px 6px;
  }
  ${(props) => css`
    canvas {
      filter: url(${'#rgb' + props.id}) url(${'#gamma' + props.id}) !important;
    }
  `}
`
const StyledSvg = styled.svg`
  position: absolute;
  width: 0px !important;
  height: 0px !important;
`

const DEFAULT_BOX = { x1: 0, x2: 0, y1: 0, y2: 0 }

const TARGET_MAX_RANGE = 10

/** Ширина окна редактирования аннотации */
const CONTEXT_MENU_WIDTH = 290
/** Высота окна редактирования аннотации */
const CONTEXT_MENU_HEIGHT = 460
/** Ширина окна выбора аннотации */
const SMALL_CONTEXT_MENU_WIDTH = 270
/** Высота окна выбора аннотации */
const SMALL_CONTEXT_MENU_HEIGHT = 54
/** Отступы окон контекстного меню от края экрана */
const CONTEXT_MENU_MARGIN = 15

export const NON_CONTEXT_ID = 'non-context'

export const MapContext = createContext<IMapContext>({
  map: new olMap({
    interactions: [],
    maxTilesLoading: 10,
  }),
  mapBbox: Object.assign({}, DEFAULT_BOX),
  setMapBbox: () => {},
  viewerId: 'A',
})

export const useMapProvided = () => useContext(MapContext)

export const MapProvider: FC<Props> = ({ children, map, mppX, viewerGridRef, viewerId }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { changeViewerSlide, isAfterFastTravel, isFastTravel, setIsAfterFastTravel } = useViewerPageProvided()
  const { setCurrentParam } = useMapParamsProvided()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { data: task } = useTaskQuery(taskId)
  const urlSlideId = useSelector(selectUrlSlideId)
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const isPausedTask = task?.status === 'PAUSED'
  const annotationsDisabled = isPausedTask || caseRecord?.permissionLevel === 'READ_ONLY'
  const { activeViewerId, openViewerIds } = useOpenViewers()
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const { setMouseOver } = useContext(MouseOverMapContext)
  const { layoutVersion } = useContext(ViewerLayoutContext)
  const colorsIsActive = useColorsIsActive({ slideId, viewerId })
  const gammaIsActive = useGammaIsActive({ slideId, viewerId })
  const { slideGrouped } = useSlideGrouped(caseId)
  const currentSlides = slideGrouped?.flatMap((item) => item.slides.map((slide) => slide.slideId)) || []
  const macroSlidesIds = slideGrouped?.filter((item) => item.type === 'MACRO').flatMap((item) => item.slides)
  const currSlidePosition = urlSlideId ? currentSlides?.indexOf(urlSlideId) : 0
  const workspaceId =
    useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId ||
    getWorkspaceIdUrl(window.location.href)
  const { clickCondition, hoveredAnnotationId, objectsMitosisContextMenu } = useTypedSelector(
    (state) => state.annotations,
  )
  const annotationsLoading = useTypedSelector((state) => state.annotations.annotationsLoading)
  const { colorParams, viewCenter, viewRotation, viewZoom } = useSlideMapViewSelector({ slideId, viewerId })
  const {
    MITOSIS,
    annotationsContext,
    contextMenuHandler,
    drawMode,
    extendedContextMenuPosition,
    handleSetExtendedContextMenuPosition,
    isContextMenu,
    isTypePanelVisible,
  } = useContextMenu({
    caseId,
    map,
    menuMargin: CONTEXT_MENU_MARGIN,
    selectedAnnotationsIds,
    slideId,
    viewerId,
  })
  const { targetUserId } = useUserStatusContext()
  const [mapBbox, setMapBbox] = useState<IBboxCoords>(Object.assign({}, DEFAULT_BOX))

  /** ONLY ON MOUNT!
   * Setted params from redux to provider.
   * set actions for redux view params should be use only there for better performance
   */

  useEffect(() => {
    if (targetUserId === undefined) return
    if (viewCenter !== undefined) {
      setCurrentParam(viewerId, 'center', viewCenter)
      map.getView().setCenter(viewCenter)
    }
    if (viewZoom !== undefined) {
      setCurrentParam(viewerId, 'zoom', viewZoom)
      map.getView().setResolution(viewZoom)
    }
    if (viewRotation !== undefined) {
      setCurrentParam(viewerId, 'rotation', viewRotation)
      map.getView().setRotation(viewRotation)
    }
  }, [targetUserId, viewCenter, viewZoom, viewRotation])

  useLayoutEffect(() => {
    if (!map) {
      return
    }
    map.setTarget(viewerId)
    map.updateSize()
  }, [map])

  useEffect(() => {
    map.updateSize()
  }, [openViewerIds.length, layoutVersion])

  useHotkeys(
    'z, x',
    (_, handler) => {
      const isNext = handler.key === 'x'
      !isFastTravel &&
        currentSlides &&
        urlSlideId &&
        nextSlideHandler({
          activeViewerId,
          caseId,
          changeViewerSlide,
          currSlidePosition,
          currentSlides,
          dispatch,
          isNext,
          macroSlidesIds,
          workspaceId,
        })
    },
    [currentSlides, isFastTravel],
  )

  /** Transform intensity of color from 0 - 255 to 0 - 1  */
  const fromIntensityToPercent = (value?: number) => {
    if (!value) return 1
    if (value < 0) {
      return 1 - Math.abs(+(value / 100).toFixed(2))
    }
    if (value > 0) {
      return 1 + +(value / 100).toFixed(2)
    }
  }

  useGesture(
    {
      onPinch: ({ ...props }) => {},
    },
    {
      target: viewerGridRef,
    },
  )

  const handleContextMenu = (
    e: MouseEvent,
    selectedAnnotationsIds: number[],
    drawMode: boolean,
    isAfterFastTravel?: boolean,
    hoveredAnnotationId?: number,
  ) => {
    if (
      clickCondition === 'dbClick' ||
      (e.target as HTMLElement).id === NON_CONTEXT_ID ||
      // TODO: Возможно есть лучшее решение
      (e.target as HTMLElement).classList.contains('ant-select-selector') ||
      (e.target as HTMLElement).closest('.rc-virtual-list') ||
      (e.target as HTMLElement).closest(`#${NON_CONTEXT_ID}`)
    ) {
      e.preventDefault()
      return
    }

    const { right } = e.currentTarget.getBoundingClientRect()
    const menuWidth = !selectedAnnotationsIds.length ? SMALL_CONTEXT_MENU_WIDTH : CONTEXT_MENU_WIDTH
    const menuHeight = !selectedAnnotationsIds.length ? SMALL_CONTEXT_MENU_HEIGHT : CONTEXT_MENU_HEIGHT
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    let x = e.clientX + SMALL_CONTEXT_MENU_WIDTH > right ? e.clientX - SMALL_CONTEXT_MENU_WIDTH : e.clientX
    let y = e.clientY
    if (x + menuWidth > windowWidth) {
      x = windowWidth - menuWidth
    }
    if (x < menuWidth) {
      x = x + CONTEXT_MENU_MARGIN * 2
    }
    if (y + menuHeight > windowHeight) {
      y = windowHeight - menuHeight
    }
    if (!drawMode && !document.pointerLockElement && !isAfterFastTravel) {
      contextMenuHandler(x, y, hoveredAnnotationId)
    }
    setIsAfterFastTravel(false)
    if (!drawMode) e.preventDefault()
  }

  const gammaCorrectionProps = {
    amplitude: DEFAULT_MAXIN / (colorParams?.white || DEFAULT_MAXIN),
    exponent: convertToAnotherRange(colorParams?.black || 0, [DEFAULT_MININ, DEFAULT_MAXIN], [1, TARGET_MAX_RANGE]),
    offset: colorParams?.gamma ? -1 * (convertToFractionalRange(colorParams.gamma, MIN_MIDIN, MAX_MIDIN) - 1) : 0,
    type: 'gamma',
  }

  return (
    <StyledMapProvider
      className="map"
      id={viewerId}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onContextMenu={(e: MouseEvent) =>
        handleContextMenu(e, selectedAnnotationsIds, drawMode, isAfterFastTravel, hoveredAnnotationId)
      }
      annotationsLoading={annotationsLoading}
      onClick={(e) => {
        annotationsLoading && e.preventDefault()
      }}
    >
      <MapContext.Provider
        value={{
          map,
          mapBbox,
          setMapBbox,
          viewerId,
        }}
      >
        {isContextMenu &&
          !isPausedTask &&
          viewerId === activeViewerId &&
          (annotationsContext?.type !== 'OBJECTS' ? (
            <ExtendedAnnotationContextMenu
              annotationsDisabled={annotationsDisabled}
              viewerId={viewerId}
              mppX={mppX}
              openContext={extendedContextMenuPosition}
              setOpenContext={handleSetExtendedContextMenuPosition}
              annotation={annotationsContext}
            />
          ) : (
            <ObjectsAnnotationContextMenu
              editDisable={annotationsDisabled}
              viewerId={viewerId}
              openContext={extendedContextMenuPosition}
              setOpenContext={handleSetExtendedContextMenuPosition}
              annotation={annotationsContext}
              isTypePanelVisible={isTypePanelVisible}
            />
          ))}
        {extendedContextMenuPosition &&
          viewerId === activeViewerId &&
          !selectedAnnotationsIds.length &&
          !isContextMenu &&
          !drawMode &&
          !objectsMitosisContextMenu?.visible &&
          !MITOSIS.isVisible && (
            <SmallAnnotationsContextMenu
              annotationsDisabled={annotationsDisabled}
              viewerId={viewerId}
              openContext={extendedContextMenuPosition}
              setOpenContext={handleSetExtendedContextMenuPosition}
            />
          )}
        {children}
      </MapContext.Provider>
      {colorsIsActive && (
        <StyledSvg xmlns="http://www.w3.org/2000/svg">
          <filter id={`rgb${viewerId}`}>
            <feColorMatrix
              type="matrix"
              values={`
                ${fromIntensityToPercent(colorParams?.red)} 0 0 0 0
                0 ${fromIntensityToPercent(colorParams?.green)} 0 0 0
                0 0 ${fromIntensityToPercent(colorParams?.blue)} 0 0
                0 0 0 1 0`}
            />
          </filter>
        </StyledSvg>
      )}
      {gammaIsActive && (
        <StyledSvg xmlns="http://www.w3.org/2000/svg">
          <filter id={`gamma${viewerId}`}>
            <feComponentTransfer>
              <feFuncR {...gammaCorrectionProps} />
              <feFuncG {...gammaCorrectionProps} />
              <feFuncB {...gammaCorrectionProps} />
            </feComponentTransfer>
          </filter>
        </StyledSvg>
      )}
    </StyledMapProvider>
  )
}
