import { useSlideQuery } from 'entities/slide'
import { useCaseQuery } from 'features/cases/api/query'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useMemo } from 'react'
import { logFileStoragePath } from 'shared/lib/log'
import TViewerId from 'types/TViewerId'
import { VIEWER_COLORS_HEX } from 'viewer/container/ui/VIEWER_COLORS'

import { useViewerDispatch, viewerSlice } from '..'

export const useViewer = (viewerId: TViewerId) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const {
    data: slide,
    isError: isSlideError,
    isLoading: isSlideLoading,
  } = useSlideQuery({
    caseId,
    slideId,
    source,
  })
  const { data: caseRecord, isLoading: isCaseLoading } = useCaseQuery({ caseId, source })

  useEffect(
    () => () => {
      viewerDispatch(viewerSlice.actions.resetViewer())
    },
    [],
  )

  useLogSlidePathEffect(viewerId)

  const isAvailable: boolean = useMemo(() => {
    if (source === 'PLATFORM') {
      return !isSlideError && !!caseRecord && slide?.state === 'AVAILABLE'
    } /** ATLAS */ else {
      return !isSlideError && slide?.state === 'AVAILABLE'
    }
  }, [isSlideError, slide, caseRecord])

  const isLoading = isCaseLoading || isSlideLoading

  return { isAvailable, isLoading }
}

const useLogSlidePathEffect = (viewerId: TViewerId) => {
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  useEffect(() => {
    slide && logFileStoragePath(slide.slideId, slide.thumbnails.large, viewerId, VIEWER_COLORS_HEX[viewerId])
  }, [slide])
}
