import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useRef } from 'react'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'

import { useMedicalNeuronetsContext } from './MedicalNeuronetsContext'

const HUNDRED = 100

/** Props for MedicalNeuronetsLayer */
type Props = {
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
  /** Идентификатор текущего слайда */
  slideId: number
}

const MedicalNeuronetsLayer: React.FC<Props> = ({ slideId, viewerId }) => {
  const map = useViewerIdMap(viewerId)
  const { features, hoveredClass, opacity } = useMedicalNeuronetsContext()

  const fillLayer = useRef(
    new VectorLayer({
      opacity: opacity / HUNDRED,
      source: new VectorSource({ features }),
      style: (f) =>
        new Style({
          fill: new Fill({
            color: f.get('color'),
          }),
        }),
      visible: true,
      zIndex: 1,
    }),
  )

  const strokeLayer = useRef(
    new VectorLayer({
      source: new VectorSource({ features }),
      style: (f) =>
        new Style({
          stroke: new Stroke({
            color: f.get('color'),
            width: 2,
          }),
        }),
      visible: true,
      zIndex: 1,
    }),
  )

  useEffect(() => {
    fillLayer.current.setOpacity(opacity / HUNDRED)
  }, [opacity])

  useEffect(() => {
    strokeLayer.current.setStyle(
      (f) =>
        new Style({
          stroke:
            hoveredClass === f.get('class_name')
              ? new Stroke({
                  color: f.get('color'),
                  width: 2,
                })
              : undefined,
        }),
    )
  }, [hoveredClass])

  useEffect(() => {
    fillLayer.current.set(INTERNAL_TYPE_ID, LayerType.MEDICALNEURONETS_CRC)
    map?.addLayer(fillLayer.current)
    map?.addLayer(strokeLayer.current)
    return () => {
      map?.removeLayer(fillLayer.current)
      map?.removeLayer(strokeLayer.current)
    }
  }, [slideId])

  useDeepCompareEffect(() => {
    fillLayer.current.getSource().clear()
    fillLayer.current.getSource().addFeatures(features)
    strokeLayer.current.getSource().clear()
    strokeLayer.current.getSource().addFeatures(features)
  }, [features, slideId])

  return null
}

export default MedicalNeuronetsLayer
