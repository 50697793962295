import { Typography } from 'antd'
import { FC } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled from 'styled-components/macro'

const { Link } = Typography

export const LinkElement = styled(Link)`
  & {
    font-size: 12px;
    line-height: 16px;
  }
`

type RouterLinkProps = {
  to: string
  target?: string
}

export const RouterLink: FC<RouterLinkProps> = ({ children, target, to }) => (
  <ReactRouterLink to={to} target={target}>
    <LinkElement>{children}</LinkElement>
  </ReactRouterLink>
)
