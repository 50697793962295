import p1 from './avatars/1.png'
import p2 from './avatars/2.png'
import p3 from './avatars/3.png'
import p4 from './avatars/4.png'
import p5 from './avatars/5.png'
import p6 from './avatars/6.png'
import p7 from './avatars/7.png'
import p8 from './avatars/8.png'
import p9 from './avatars/9.png'

export const allPeoples = [
  { id: 'p1', name: 'Иванова Мария Петровна' },
  { id: 'p2', name: 'Петрова Анна Владимировна' },
  { id: 'p3', name: 'Сидорова Валентина Сергеевна' },
  { id: 'p4', name: 'Лоскутова Таисия Яновна' },
  { id: 'p5', name: 'Петрова Анна Владимировная' },
  { id: 'p6', name: 'Чупракова Кира Евлампиевна' },
  { id: 'p7', name: 'Сергей Золотников' },
  { id: 'p8', name: 'Иванова Мария Петровна' },
  { id: 'p9', name: 'Петрова Анна Владимировна' },
]

export const avatars = [
  { name: 'p1', src: p1 },
  { name: 'p2', src: p2 },
  { name: 'p3', src: p3 },
  { name: 'p4', src: p4 },
  { name: 'p5', src: p5 },
  { name: 'p6', src: p6 },
  { name: 'p7', src: p7 },
  { name: 'p8', src: p8 },
  { name: 'p9', src: p9 },
]
