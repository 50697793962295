import { Layout } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Content } from 'antd/lib/layout/layout'
import { useThemeContext } from 'app/styled/ThemeProvider'
import DashboardNavigationType from 'features/dashboard/navigation/DashboardNavigationType'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  CheckboxElement,
  IconElement,
  InputElement,
  Scroll,
  SegmentElement,
  SpaceElement,
  TitleElement,
} from 'shared/ui/kit'
import { TagElement } from 'shared/ui/kit/ui/TagElement'
import styled from 'styled-components/macro'

const StyledContent = styled(Content)`
  background: var(--color-bg-2);
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  border-left: 1px solid var(--color-border-1);
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InfoBlock = styled.div`
  width: 296px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 12px 12px;

  background: var(--color-bg-3);
  border-radius: 5px;
  gap: 16px;
  padding-left: 42px;
`

const StyledCheckboxElement = styled(CheckboxElement)`
  margin-left: 0px;
`

const StyledText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
const Tagss = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledSegmentElement = styled(SegmentElement)`
  max-width: 300px;
`

const StyledInputElement = styled(InputElement)`
  width: 50px;
`

const MuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
`

const StyledIconElement = styled(IconElement)`
  position: absolute;
  left: 10px;
  width: 24px;
  height: 24px;
`

export const RoutingContainer = () => {
  const [isSecond, setSecond] = useState(true)
  const { t } = useTranslation()
  const theme = useThemeContext()

  const seconCheckHandler = (e: CheckboxChangeEvent) => {
    setSecond(e.target.checked)
  }

  const secondOpt = [
    {
      label: t('Автоматически'),
      value: 'MALE',
    },
    {
      label: t('Вручную'),
      value: 'FEMALE',
    },
  ]

  return (
    <Scroll>
      <Layout style={{ height: '100%' }}>
        <StyledContent>
          <div>
            <TitleElement level={2}>{t('Внутри МУ')}</TitleElement>
          </div>
          <MuContainer>
            <CheckboxContainer>
              <StyledCheckboxElement defaultChecked={true}>
                <StyledText>{t('По загрузке специалистов')}</StyledText>
              </StyledCheckboxElement>
              <StyledCheckboxElement defaultChecked={true}>
                <StyledText>{t('По локализации')}</StyledText>
              </StyledCheckboxElement>
              <StyledCheckboxElement>
                <StyledText>{t('По сложности случая')}</StyledText>
              </StyledCheckboxElement>
            </CheckboxContainer>
            <InfoBlock>
              <StyledIconElement name="info" size="md" />
              {t('Для корректной работы маршрутизации у специалистов должны быть указаны приоритетные локализации')}
              <Link to={`/dashboard?filter=${DashboardNavigationType.Settings}&subpage=people&p=p1`}>
                {t('Перейти к специалистам')}
              </Link>
            </InfoBlock>
          </MuContainer>

          <SpaceElement size={8} />
          <div>
            <TitleElement level={2}>{t('Между МУ')}</TitleElement>
          </div>
          <StyledCheckboxElement checked={isSecond} onChange={seconCheckHandler}>
            <StyledText>{t('Отправлять на второе мнение')}</StyledText>
          </StyledCheckboxElement>
          <StyledSegmentElement colorTheme={theme.theme} options={secondOpt} defaultValue="MALE" />
          {isSecond && (
            <>
              <StyledCheckboxElement defaultChecked={true}>
                <StyledText>{t('По локализации')}</StyledText>
              </StyledCheckboxElement>
              <Tagss>
                <TagElement closable>{t('Щитовидная железа')}</TagElement>
                <TagElement closable>{t('Молочная железа')}</TagElement>
                <TagElement>+</TagElement>
              </Tagss>
              <StyledCheckboxElement defaultChecked={true}>
                <StyledText>{t('По сложности случая')}</StyledText>
              </StyledCheckboxElement>
            </>
          )}
          <StyledCheckboxElement defaultChecked={true}>
            <StyledText>{t('Оказывать второе мнение')}</StyledText>
          </StyledCheckboxElement>
          <StyledText>
            {t('Не более')} &nbsp; <StyledInputElement defaultValue="200" />
            &nbsp; {t('случаев в месяц')}
          </StyledText>

          <SpaceElement size={8} />
          <div>
            <TitleElement level={2}>{t('Контроль качества')}</TitleElement>
          </div>
          <StyledCheckboxElement defaultChecked={true}>
            <StyledText>{t('Отправлять на пересмотр')}</StyledText>
          </StyledCheckboxElement>
          <StyledSegmentElement colorTheme={theme.theme} options={secondOpt} defaultValue="MALE" />
          <StyledText>
            <StyledInputElement defaultValue="10 %" />
            &nbsp; {t('Случаев')}
          </StyledText>
        </StyledContent>
      </Layout>
    </Scroll>
  )
}
