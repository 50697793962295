import slideService from 'entities/slide/api/service'
import mlService from 'entities/slide-layers/api/service'
import Feature from 'ol/Feature'
import { Geometry } from 'ol/geom'
import { StompClientContext, WsResponseIHeatmap } from 'processes/stomp'
import { useContext } from 'react'
import { QueryKey, useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import ICase from 'types/ICase'
import { ISearchHeatmap } from 'types/IHeatmap'
import ISlide from 'types/ISlide'
import ISource from 'types/ISource'

export const useSegmentationQuery = (
  caseId: number,
  slideId: number,
  options?: UseQueryOptions<Feature<Geometry>[], unknown, Feature<Geometry>[], QueryKey> | undefined,
) => {
  const queryClient = useQueryClient()
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const query = useQuery<Feature<Geometry>[]>(
    [QUERY_TYPE.SEGMENTATION, slideId],
    () => mlService.getSegmentationGeoJSON(caseId, slideId),
    {
      cacheTime: Infinity,
      enabled: slide?.slideMetadata?.commonMetadata?.segmentationStatus === 'AVAILABLE',
      staleTime: Infinity,
      ...options,
    },
  )
  return query
}

export const useSearchGridQuery = (
  caseId: number,
  slideId: number,
  source: ISource,
  options?: UseQueryOptions<any, unknown, any, (string | number)[]> | undefined,
) => {
  const queryClient = useQueryClient()
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const defaultModel = slide?.searchModels?.find((m) => m.default)

  return useQuery([QUERY_TYPE.SEARCH_GRID, slideId], () => mlService.getGrid(caseId, slideId, source), {
    cacheTime: Infinity,
    enabled: defaultModel?.status === 'AVAILABLE',
    staleTime: Infinity,
    ...options,
  })
}
export const useIiifConfigQuery = (
  slideId: number,
  options?: UseQueryOptions<any, unknown, any, (string | number)[]> | undefined,
) => {
  const queryClient = useQueryClient()
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const query = useQuery([QUERY_TYPE.IIIF_CONFIG, slideId], () => slideService.getIiifConfig(slide?.iiif2Url), {
    cacheTime: Infinity,
    enabled: !!slide,
    staleTime: Infinity,
    ...options,
  })

  return query
}

export const useHeatmapQuery = (payload: ISearchHeatmap) => {
  const { singleRequest } = useContext(StompClientContext)
  return useQuery(
    [QUERY_TYPE.HEAT_MAP, payload],
    async () => {
      const response = await singleRequest<WsResponseIHeatmap>({
        payload,
        publishDest: '/app/similarity-heatmap/',
        subscriptionDest: '/user/topic/similarity-heatmap/',
      })
      return response?.payload
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
}

export const useSlideViewedQuery = (
  caseId: number,
  slideId: number,
  options?: UseQueryOptions<any, unknown, any, (string | number)[]> | undefined,
) => {
  const queryClient = useQueryClient()
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])

  return useQuery([QUERY_TYPE.SLIDE_VIEWED, slideId], () => slideService.getSlideViewed(caseId, slideId), {
    cacheTime: Infinity,
    enabled: caseRecord && caseRecord.source === 'PLATFORM' && caseRecord.relation !== 'RESTRICTED',
    ...options,
  })
}

export const useMutateSlideViewed = (caseId: number, slideId: number) => {
  const queryClient = useQueryClient()
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const { relation, source } = caseRecord || {}
  if (isNaN(caseId)) return { mutate: () => {} }
  const mutation = useMutation(
    [QUERY_TYPE.SLIDE_VIEWED, slideId],
    async (formattedFeatures: any) => {
      if (caseId && slideId && source === 'PLATFORM' && relation !== 'RESTRICTED') {
        await slideService.updateSlideViewed(caseId, slideId, formattedFeatures)
      }
    },
    { onSuccess: () => queryClient.invalidateQueries([QUERY_TYPE.SLIDE_VIEWED, slideId]) },
  )
  return mutation
}
