import { useTypedSelector } from 'app/redux/lib/selector'
import stubLabel from 'assets/thumbnails/stub-label.png'
import PreviewPanelThumbnailProgress from 'features/thumbnails/ui/PreviewPanelThumbnailProgress'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageElement, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISlide from 'types/ISlide'

import { StyledContainer, TitleBlock } from './PanelPreviewContainer'
import { MICRO_SLIDE_IMAGE_SIZE } from './PreviewImageContainer'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 2px solid var(--color-border-1);
  border-radius: 3px;
  margin-right: 4px;
  overflow: hidden;

  &:hover {
    background: var(--color-purple);
  }
`

type TProps = {
  /** Слайды в загрузке */
  filesList: string[]
  /** Слайды на конвертации */
  processingSlides?: ISlide[]
}

/** Загрузка: Загружается, на конвертации*/
type TUploadState = 'progress' | 'processing'

const FileUploadProgress: FC<{ uploadStateType: TUploadState; percent?: number }> = ({ percent, uploadStateType }) => (
  <StyledWrapper>
    {uploadStateType === 'progress' ? (
      <div style={{ height: MICRO_SLIDE_IMAGE_SIZE, width: MICRO_SLIDE_IMAGE_SIZE }}>
        <PreviewPanelThumbnailProgress percent={percent || 0} />
      </div>
    ) : (
      <div style={{ height: MICRO_SLIDE_IMAGE_SIZE, width: MICRO_SLIDE_IMAGE_SIZE }}>
        <StyledSpin />
      </div>
    )}

    <ImageElement
      style={{
        background: 'white',
        borderRadius: 0,
        height: MICRO_SLIDE_IMAGE_SIZE,
        width: MICRO_SLIDE_IMAGE_SIZE,
      }}
      src={stubLabel}
    />
  </StyledWrapper>
)

const PreviewProgressContainer = ({ tusFileId }: { tusFileId: string }) => {
  const file = useTypedSelector((state) => state.tusFiles.byId[tusFileId || ''])

  return file ? <FileUploadProgress uploadStateType="progress" percent={file.percent} /> : null
}

const FileUploadContainer: FC<TProps> = ({ filesList, processingSlides }) => {
  const { t } = useTranslation()

  return (
    <StyledContainer style={{ display: 'block' }}>
      <div>
        <TitleBlock index={0} hasTitleBlock={true}>
          {t('Загрузка')}
        </TitleBlock>
      </div>
      <div style={{ display: 'flex' }}>
        {filesList?.map((id) => <PreviewProgressContainer tusFileId={id} key={id} />).reverse()}
        {processingSlides
          ?.map(({ slideId }) => <FileUploadProgress uploadStateType="processing" key={slideId} />)
          .reverse()}
      </div>
    </StyledContainer>
  )
}

export default FileUploadContainer

const StyledSpin = styled(SpinElement)`
  background: var(--color-bg-4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &.ant-spin-spinning {
    height: 100%;
    width: 100%;
  }
`
