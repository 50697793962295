import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

const Outer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`

export const Center: FC<HTMLAttributes<HTMLDivElement>> = (props) => <Outer {...props} />
