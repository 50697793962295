import { Space } from 'antd'
import { push } from 'connected-react-router'
import { FormButton, FormTopTitle, FormWrapper } from 'features/form'
import { MobileLandscape } from 'features/mobile-control'
import { userService } from 'processes/user'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { PageHeader } from 'shared/ui/header'
import { SpaceElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import { RegistrationForm, RegistrationFormDataType } from './ui'

const StyledLogin = styled(MobileLandscape)`
  background-color: var(--color-bg-1);
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  h1 {
    @media (max-width: 576px) {
      padding: 0 10px;
      font-size: 1.25rem !important;
    }
  }
`

export const RegistrationRoute = () => {
  const [isMessage, setMessage] = useState(false)
  const dispatch = useDispatch()
  const onLogin = useCallback(async (payload: RegistrationFormDataType) => {
    try {
      await userService.register(payload)
      setMessage(true)
    } catch (e) {
      throw e
    }
  }, [])

  const goToLogin = useCallback(() => {
    dispatch(push('/login'))
  }, [])

  useEffect(() => {
    const { REGISTRATION_ENABLED } = global.window
    if (!REGISTRATION_ENABLED) dispatch(push('/login'))
  }, [])

  const { t } = useTranslation()

  if (isMessage) {
    return (
      <StyledLogin>
        <Content>
          <TitleElement level={1}>{t('На указанный email отправлено письмо с подтверждением')}</TitleElement>
          <SpaceElement size={16} />
          <TitleElement level={1}>
            {t('Воспользуйтесь ссылкой, указанной в письме, чтобы завершить регистрацию')}
          </TitleElement>
        </Content>
      </StyledLogin>
    )
  }

  return (
    <StyledLogin>
      <PageHeader>
        <Space size={16}>
          <FormTopTitle level={4}>{t('Уже есть аккаунт?')}</FormTopTitle>
          <FormButton onClick={goToLogin}>{t('Войти')}</FormButton>
        </Space>
      </PageHeader>
      <SpaceElement size={98} />
      <FormWrapper>
        <TitleElement level={1}>{t('Создание аккаунта')}</TitleElement>
        <SpaceElement size={40} />
        <RegistrationForm onSubmit={onLogin} />
      </FormWrapper>
    </StyledLogin>
  )
}
