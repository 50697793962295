import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

export const REPORT_PETROVA = {
  caseId: 999999903,
  createdAt: '2021-05-18T11:56:57.108',

  createdBy: {
    email: 'test+doctor1@onecell.ai',
    fullname: 'Семен Семенович Собран',
    userId: 1,
  },
  medicalReportId: 999901,
  report:
    'На фоне постоперационных МР-картина кистозно-солидного образования в области купола культи влагалища (более вероятно, рецидив). МР-данных за наличие mts поражения лимфоузлов не получено.',
  source: 'PLATFORM',
}

export const REPORT_IVANOVA = {
  caseId: 999999902,
  createdAt: '2021-05-18T11:56:57.108',
  createdBy: {
    email: 'test+doctor1@onecell.ai',
    fullname: 'Семен Семенович Собран',
    userId: 1,
  },
  medicalReportId: 999902,
  report:
    'Са левой молочной железы (мультифокальный рост). Фиброаденоматоз молочных желёз на фоне инволютивных изменений. Кисты, фиброаденома правой молочной железы. Категория правой молочной железы 3 по BI-RADS; Категория левой молочной железы 5 по BI-RADS ',
  source: 'PLATFORM',
}

export const dashboardMockReports = [REPORT_PETROVA, REPORT_IVANOVA]

export const useInitMockReports = () => {
  const queryClient = useQueryClient()
  useEffect(() => {
    dashboardMockReports.forEach((report) => {
      queryClient.setQueryData(['reports', report.medicalReportId], () => report)
    })
  }, [])
}
