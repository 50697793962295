import Feature from 'ol/Feature'
import { Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect } from 'react'
import { MapContext } from 'shared/lib/map'
import { ISelectedBbox } from 'types/ISimilarRegion'
import { getCenterOfExtent } from 'viewer/map/lib/utils'

type Props = {
  selectedBbox: ISelectedBbox['bbox']
}
const SelectedAttachment = ({ selectedBbox }: Props) => {
  const { viewerId } = useContext(MapContext)
  const map = useViewerIdMap(viewerId)
  useEffect(() => {
    const polygonCoordinates = [
      [Number(selectedBbox.x1), Number(selectedBbox.y1)],
      [Number(selectedBbox.x2), Number(selectedBbox.y1)],
      [Number(selectedBbox.x2), Number(selectedBbox.y2)],
      [Number(selectedBbox.x1), Number(selectedBbox.y2)],
      [Number(selectedBbox.x1), Number(selectedBbox.y1)],
    ]
    const polygonFeature = new Feature(new Polygon([polygonCoordinates]))
    const _layer = new VectorLayer({
      source: new VectorSource({
        features: [polygonFeature],
      }),
      style: new Style({
        fill: new Fill({
          color: 'rgba(64, 153, 247, 0.1)',
        }),
        stroke: new Stroke({
          color: '#4099F7',
          width: 3,
        }),
      }),
      zIndex: 100,
    })

    map.addLayer(_layer)
    setTimeout(() => {
      const geometry = polygonFeature.getGeometry()
      if (geometry && map.isRendered()) {
        map.getView().fit(geometry.getExtent(), {
          duration: 500,
          padding: [100, 100, 100, 100],
        })
        map.getView().animate({
          center: getCenterOfExtent(geometry.getExtent()),
          duration: 500,
        })
      }
    }, 400)
    return () => {
      map.removeLayer(_layer)
    }
  }, [selectedBbox, map.isRendered()])
  return null
}

export default SelectedAttachment
