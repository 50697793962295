import { useTranslation } from 'react-i18next'
import { IconElement, SpaceElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  color: var(--color-blue);
  & > svg {
    width: 24px;
    height: 24px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

type Props = {
  message?: string
}

const InfoNotification = ({ message }: Props) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <ThumbnailWrapper>
        <IconElement name="info" />
      </ThumbnailWrapper>
      <SpaceElement size={10} />
      <TextWrapper>
        <TextElement data-testid={'info-notification'}>{message ? t(message) : ''}</TextElement>
      </TextWrapper>
    </Wrapper>
  )
}

export default InfoNotification
