/**
 * Переводит переданное число из одного диапазона в другой
 * Например, число 10 из диапозона [0, 100] -> [0, 10] -> 1.
 * @param {number} value - Число, которое нужно перевести в другой диапазон
 * @param {[number, number]} oldRange - Изначальный диапазон, в котором находится число value
 * @param {[number, number]} newRange - Диапазон, в котом нужно определить число
 */
export const convertToAnotherRange = (value: number, oldRange: [number, number], newRange: [number, number]) => {
  const oldDif = oldRange[1] - oldRange[0]
  const newDif = newRange[1] - newRange[0]
  return ((value - oldRange[0]) * newDif) / oldDif + newRange[0]
}

// experimental
export const convertToFractionalRange = (value: number, min: number, max: number) =>
  Math.min(Math.max(Math.pow(max, 2 * value - 1), min), max)
