import { useSlideQuery } from 'entities/slide'
import slideService from 'entities/slide/api/service'
import { useMutation, useQueryClient } from 'react-query'
import { post, QUERY_TYPE } from 'shared/api'
import { DictionaryItem } from 'types/IDictionary'
import ISource from 'types/ISource'

const setPlatformStains = async (caseId: number, slideId: number, stain?: DictionaryItem) => {
  try {
    const res = await post({
      data: { ...stain },
      url: `/case/${caseId}/slide/${slideId}/stain`,
    })
    return res
  } catch (e) {
    throw e
  }
}

const setAtlasStains = async (slideId: number, stain?: DictionaryItem) => {
  try {
    const res = await post({
      data: '',
      url: `/atlas/slide/${slideId}/stain?stainCode=${stain?.shortName}`,
    })
    return res
  } catch (e) {
    throw e
  }
}

export const useMutateSlideStain = (caseId: number, slideId: number, source: ISource) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (stain?: DictionaryItem) => {
      if (caseId && slideId) {
        if (source === 'ATLAS') {
          await setAtlasStains(slideId, stain)
        } else {
          await setPlatformStains(caseId, slideId, stain)
        }
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_TYPE.SLIDE, slideId]),
    },
  )
  return mutation
}

export const useMutateSlideBlock = (caseId: number, slideId: number, source: ISource) => {
  const queryClient = useQueryClient()
  const { data: slide } = useSlideQuery({ slideId, source })
  return useMutation(
    async (slideBlock: { block: string | null }) => {
      if (caseId && slideId) {
        await slideService.updateSingleSlide(caseId, slideId, {
          block: slideBlock?.block,
        })
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_TYPE.SLIDE, slideId]),
    },
  )
}
export const useMutateSlideContainer = (caseId: number, slideId: number, source: ISource) => {
  const queryClient = useQueryClient()
  const { data: slide } = useSlideQuery({ slideId, source })
  return useMutation(
    async (slideContainer: { container: string | null }) => {
      if (caseId && slideId) {
        await slideService.updateSingleSlide(caseId, slideId, {
          container: slideContainer?.container,
        })
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_TYPE.SLIDE, slideId]),
    },
  )
}
