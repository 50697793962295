import { post } from 'shared/api'
import ICoregistration from 'types/ICoregistration'
import { ICoregistrationAlgorithm } from 'types/ICoregistrationPayload'
import { IAffine } from 'types/ICoregistrationSlide'

/*Корегистрация одного слайда*/
export type CoregistrationDataValue = {
  badCoregistration: boolean
  stateBeforeCoregistration?: ICoregistration['referenceRoi'] | null
  stateOnFeedback?: ICoregistration['referenceRoi']
  slideId: number
  affine?: IAffine
}

/*Ключи для вьюверов открытых в сплит-вью*/
export type SlideKey = 'paneASlide' | 'paneBSlide' | 'paneCSlide' | 'paneDSlide'

/*Корегистрация каждого слайда + дата и алгоритм*/
export type ICoregistrationFeedback = {
  [K in SlideKey]: CoregistrationDataValue
} & {
  slideCoregistrationDate: string
  algorithm: ICoregistrationAlgorithm
}

export const sendCoregistrationFeedback = (data: ICoregistrationFeedback, caseId?: number, workspaceId?: number) =>
  post({
    data,
    url: `/workspace/${workspaceId}/case/${caseId}/slide/coregistration/feedback`,
  })
