// import './wdyr'
import 'react-hot-loader'
import 'antd/dist/antd.less'

import { initSentry } from 'app/sentry'
import ReactDOM from 'react-dom'
import { hot } from 'react-hot-loader/root'
import { initFilteredConsoleError } from 'shared/lib/log'

import { Root } from './root'
import * as serviceWorker from './serviceWorker'

const HotRoot = hot(Root)

//TODO: make this function works. Nice to make process.env[propetry] mutate to another value
// initEnvFromWindow()
initFilteredConsoleError()

initSentry()

ReactDOM.render(<HotRoot />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
