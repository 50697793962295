import { MitosisAnnotation } from 'assets/icons/mitosisAnnotation'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { formatDateTimeForDisplay } from 'shared/lib/date'
import { COLON_WITH_SPACE } from 'shared/text-constants'
import { IconElement, SmallIconButtonElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { IMarkupClass } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'
import { getMitosisData } from 'viewer/map/layers/annotations/lib/helpers'
import { getCustomColor } from 'viewer/map/layers/olStyles'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

import { AnnotationClasses, AnnotationClassesEnum, AnnotationClassSelect } from './AnnotationClassSelect'
import { ANNOTATIONS_ICONS_MAP } from './AnnotationsTypePanelContainer'
import { HoverWrapper } from './HoverWrapper'

type Props = {
  annotation: IAnnotation
  isActive: boolean
  annotationClass: string
  onSelect: React.MouseEventHandler<HTMLDivElement>
  onDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClassSelect: (selectedClass: AnnotationClassesEnum) => void
  canDelete: boolean
  annotationsIsVisible: number[] | null
  setAnnotationsIsVisible: (annotations: number) => void
}

const UserName = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: var(--color-text-3);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const UserDate = styled(UserName)`
  font-weight: 600;
`

const DescriptonInfo = styled.span`
  color: var(--color-text-1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`

const AnnotationInfo = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 16px 1fr auto;
  gap: 8px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledIconElement = styled(IconElement)<{ color?: string }>`
  color: ${({ color }) => color};
`

const StyledText = styled.div`
  line-height: 16px;
  color: var(--color-text-1);
`

const AnnotationListItem = ({
  annotation,
  annotationClass,
  annotationsIsVisible,
  canDelete,
  isActive,
  onClassSelect,
  onDelete,
  onSelect,
  setAnnotationsIsVisible,
}: Props) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const task = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const isPausedTask = task?.status === 'PAUSED'
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const customAnnotationRgb = currTaskClasses?.find(
    (aClass: IMarkupClass) => aClass.name === annotationClass && annotation.type === aClass.annotationType,
  )?.colorAsRGBA
  const annotationRgb = customAnnotationRgb
    ? getCustomColor(customAnnotationRgb)
    : taskId && currTaskClasses && currTaskClasses?.length > 0
    ? undefined
    : AnnotationClasses.find((aClass) => aClass.name === annotationClass)?.rgb
  const annotationColor = annotationRgb ? `rgba(${annotationRgb}, 1)` : ''
  const feature = getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)
  const user = feature.get('user')
  const description = useMemo(() => feature.get('description'), [feature])
  const mitosisData = useMemo(() => getMitosisData(annotation), [annotation])
  const currTaskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const filteredCurrTaskClasses = currTaskClasses?.filter((item) => item?.annotationType === annotation.type)
  const currDate = new Date(annotation.updatedAt)
  const currYear = new Date().getFullYear()
  const isCurrYear = currDate.getFullYear() === currYear
  const { t } = useTranslation()
  return (
    <HoverWrapper
      onSelect={onSelect}
      id={annotation.slideAnnotationId}
      type={annotation.type}
      isActive={isActive}
      hoverColor={annotationRgb}
    >
      {description ? <DescriptonInfo>{description}</DescriptonInfo> : null}
      <UserInfo>
        <UserDate>{formatDateTimeForDisplay(new Date(annotation.updatedAt), isCurrYear)}</UserDate>
        {annotation.user ? (
          // <UserName ellipsis>{annotation.user}</UserName>
          <UserName>{annotation.user}</UserName>
        ) : (
          <UserDate>
            {user || currTaskData?.participants?.find((item) => item.userId === annotation.userId)?.user?.fullname}
          </UserDate>
        )}
      </UserInfo>

      <AnnotationInfo>
        <IconContainer>
          {annotation.type === AnnotationType.MITOSIS ? (
            <MitosisAnnotation />
          ) : (
            <StyledIconElement color={annotationColor} size="md" name={ANNOTATIONS_ICONS_MAP[annotation.type]} />
          )}
        </IconContainer>
        {annotation.type === AnnotationType.MITOSIS ? (
          <StyledText>
            {t('Митозы')}
            {COLON_WITH_SPACE} {mitosisData?.mitosis} {t('на')} {mitosisData?.targetArea}
            {t('мм²')}
          </StyledText>
        ) : (
          <AnnotationClassSelect
            enable={canDelete && !isPausedTask}
            onSelect={onClassSelect}
            value={annotationClass}
            classes={filteredCurrTaskClasses}
          />
        )}

        <div style={{ display: 'flex', gap: '4px' }}>
          <SmallIconButtonElement
            icon={annotationsIsVisible?.includes(annotation.slideAnnotationId) ? 'eye' : 'eyeClose'}
            style={{ color: 'var(--color-text-3)' }}
            onClick={(e) => {
              e.stopPropagation()
              setAnnotationsIsVisible(annotation.slideAnnotationId)
            }}
          />
          {canDelete && !isPausedTask && (
            <SmallIconButtonElement title={t('Удалить')} icon="delete" onClick={onDelete} />
          )}
        </div>
      </AnnotationInfo>
    </HoverWrapper>
  )
}

export default AnnotationListItem
