import { ReactComponent as FailedImage } from 'assets/thumbnails/failedSlide.svg'
import { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

const FailedSlideWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: var(--color-bg-2);
  object-fit: contain;
`

export const FailedImageElement = (props: HTMLAttributes<any>) => (
  <FailedSlideWrapper {...props}>
    <FailedImage />
  </FailedSlideWrapper>
)
