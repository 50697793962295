import * as Sentry from '@sentry/react'
import IUser from 'types/IUser'

export const setUserToSentry = (user?: IUser) => {
  if (!user) {
    Sentry.setUser(null)
  }
  Sentry.setUser({
    email: user?.email,
    id: user?.userId?.toString(),
    // eslint-disable-next-line i18next/no-literal-string
    ip_address: '{{auto}}',

    username: user?.fullname,
  })
}
