import { Slider } from 'antd'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButtonElement, IconElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { ToolOption } from 'viewer/tools/model/viewerToolsSlice'

import { ViewerInfoToolPanelContainer } from './ViewerInfoToolPanelContainer'

type Props = {
  heatMapRef: React.RefObject<HTMLDivElement>

  viewerId: TViewerId
  HEAT_MAP: ToolOption

  initPosition: number[]
  onPositionChange: (position: number[]) => void
}

const StyledSlider = styled(Slider)`
  flex: 1;
  margin: 0 8px;
  height: 8px;
  .ant-slider-handle {
    background-color: #fff;
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }
  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeatMapOpacityToolPanel = ({ HEAT_MAP, heatMapRef, initPosition, onPositionChange, viewerId }: Props) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const { heatmapOpacity } = useViewerMainSelector(viewerId)
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const onOpacityChange = (value: number | string | undefined) => {
    viewerDispatch(viewerSlice.actions.setHeatmapOpacity(Number(value)))
  }
  const { t } = useTranslation()
  return (
    <ViewerInfoToolPanelContainer
      id={'HEAT_MAP'}
      panelRef={heatMapRef}
      type={'HEAT_MAP'}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      position={position}
      setPosition={setPosition}
    >
      <Wrapper>
        <TooltipElement title={HEAT_MAP.isVisible ? t('Скрыть маску похожести') : t('Показать маску похожести')}>
          <IconButtonElement
            style={{ color: HEAT_MAP.isVisible ? 'var(--color-text-1)' : undefined }}
            onClick={() => viewerDispatch(viewerPageSlice.actions.toggleTool('HEAT_MAP'))}
            icon={<IconElement name="similar" />}
          />
        </TooltipElement>
        <StyledSlider
          min={1}
          max={100}
          tipFormatter={(value) => `${value}%`}
          onChange={onOpacityChange}
          value={heatmapOpacity}
          disabled={!HEAT_MAP.isVisible}
        />
      </Wrapper>
    </ViewerInfoToolPanelContainer>
  )
}

export default HeatMapOpacityToolPanel
