import { useTypedSelector } from 'app/redux/lib/selector'
import { reportsSlice, selectedReportByCaseSelector } from 'features/reports/model/reportsSlice'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { RefObject, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ModalHandle } from 'shared/ui/modal'
import ISource from 'types/ISource'

import { useReportQuery, useReportsQuery } from './api/query'
import ReportsWidgetHeader from './ui/ReportsWidgetHeader'

type Props = {
  caseId: number
  isCollapsed?: boolean
  source: ISource
  reportCreationModalRef: RefObject<ModalHandle>
  /** Возможность копировать заключение */
  copyDisable: boolean
  /** Флаг определяющий relation */
  isRestricted: boolean
}

const ReportsWidgetHeaderContainer = ({
  caseId,
  copyDisable,
  isCollapsed,
  isRestricted,
  reportCreationModalRef,
  source,
}: Props) => {
  const dispatch = useDispatch()
  const index = useTypedSelector(selectedReportByCaseSelector(caseId))
  const currentUser = useTypedSelector((state) => state.user.user)
  const { data } = useReportsQuery(caseId, source)
  const lisMode = useLisMode()
  const selectedReportId = (data && data[index]) || -1
  const { data: selectedReport } = useReportQuery(caseId, selectedReportId, source)
  const next = useCallback(() => {
    const reportsLength = data?.length || 0
    if (index + 1 < reportsLength) dispatch(reportsSlice.actions.setSelectedReportIndex({ caseId, index: index + 1 }))
  }, [data?.length, index])
  const back = useCallback(() => {
    if (index - 1 >= 0) dispatch(reportsSlice.actions.setSelectedReportIndex({ caseId, index: index - 1 }))
  }, [data?.length, index])
  const copyReport = () => {
    if (selectedReport) {
      reportCreationModalRef.current?.open()
      dispatch(
        reportsSlice.actions.setInitialReport({
          ...selectedReport,
          medicalReportId: undefined,
        }),
      )
    }
  }

  const editReport = () => {
    if (selectedReport) {
      reportCreationModalRef.current?.open()
      dispatch(reportsSlice.actions.setInitialReport(selectedReport))
    }
  }

  /** *Временно(нужно будет перенести во время реализации функционала для кнопок) */
  if (!data?.length || isCollapsed) return null

  return (
    <ReportsWidgetHeader
      selectedReport={selectedReport}
      reportsLength={data.length}
      index={index}
      next={next}
      back={back}
      copyReport={copyReport}
      isRestricted={isRestricted}
      editReport={editReport}
      copyDisable={copyDisable}
      isEditable={
        (lisMode === 'gemotest' && !selectedReport?.signed) ||
        (lisMode === 'none' && selectedReport?.createdBy?.userId === currentUser?.userId)
      }
    />
  )
}

export default ReportsWidgetHeaderContainer
