import { TOOLBAR_BTN_OPACITY } from 'features/toolbar/lib/constants'
import { ViewerLeftPanelType, ViewerRightPanelType } from 'pages/viewer'
import styled from 'styled-components'

import { StyledButtonElement } from './ButtonElement'

type IButton = ViewerLeftPanelType | ViewerRightPanelType

type IToolbarButton = {
  /** указывает, активна ли кнопка. */
  active?: boolean
  /** указывает, отключена ли кнопка. */
  disable?: boolean
  /** тип кнопки */
  typeButton?: IButton
}

export const MainToolbarButton = styled(StyledButtonElement)<IToolbarButton>`
  margin: ${({ typeButton }) => (typeButton === 'MENU' ? 0 : `0 6px`)};
  color: ${({ active }) => (active ? 'var(--color-purple-light) !important' : 'transparent')};
  opacity: ${({ disable }) => (disable ? TOOLBAR_BTN_OPACITY : undefined)};

  ${({ active, typeButton }) =>
    typeButton === 'MENU' &&
    active !== undefined &&
    `
    & > svg > path:last-child {
      transform: ${active ? 'translate(0, 4px)' : 'translate(0, 0)'};
      transition: transform 0.2s ease-in-out;
    }
    
    &:hover {
      & > svg > path:last-child {
        transform: translate(0, 4px);
        transition: transform 0.2s ease-in-out;
      }
    }
  `}
`

export const ToolbarButton = styled(StyledButtonElement)<{ active?: boolean; disable?: boolean }>`
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  color: ${({ active }) => (active ? 'var(--color-purple-light) !important' : 'transparent')};
  opacity: ${({ disable }) => (disable ? TOOLBAR_BTN_OPACITY : undefined)};
`
