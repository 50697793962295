import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const TextWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const Wrapper = styled.div`
  padding: 6px;
`
export type RedirectToWorkspaceNotificationProps = {
  workspaceName?: string
}

const RedirectToWorkspaceNotification = ({ workspaceName }: RedirectToWorkspaceNotificationProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      {workspaceName ? (
        <TextWrapper>
          {t('Вы были перенаправлены к персональному пространству')} <br /> {workspaceName}
        </TextWrapper>
      ) : (
        <TextWrapper>{t('Исходный воркспейс неизвестен')}</TextWrapper>
      )}
    </Wrapper>
  )
}

export default RedirectToWorkspaceNotification
