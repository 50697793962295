import { get } from 'shared/api'
import INote from 'types/INotes'

const fetchNotes = async (caseId: number) =>
  get<INote[]>({
    url: `/case/${caseId}/notes`,
  })
const notesService = {
  fetchNotes,
}
export default notesService
