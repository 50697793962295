import icons from 'assets/icons'
import { MutableRefObject } from 'react'
import ICase from 'types/ICase'
import { ISelectedBbox } from 'types/ISimilarRegion'
import ISlide from 'types/ISlide'

export enum MenuItemAction {
  AddToSplitScreen = 'addToSplitScreen',
  RotateLabel = 'rotateLabel',
  RotateAllLabels = 'rotateAllLabels',
  Download = 'download',
  Delete = 'delete',
}

type ClickHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => void

export type ContextMenuItemComponentProps = {
  /** Название иконки */
  name: keyof typeof icons
  /** Функция клика */
  onClick: ClickHandler
  /** название кнопок */
  text: string
  /** флаг, определяющий, доступна ли кнопка */
  isDisabled: boolean
}

export type Props = {
  /** позиция меню */
  thumbnailMenuPosition: number[]
  /** ссылка на меню */
  menuRef: MutableRefObject<HTMLDivElement> | MutableRefObject<null>
  /** данные превью при наведении*/
  slideThumbnailHover?: ISlide
  /** Данные слайдов в результате поиска похожих */
  similarThumbnailHover?: ISelectedBbox
  /** функция для закрытия меню */
  onCloseMenu: () => void
  /** Стиль для позиционирования меню */
  position?: string
  /** Отключаем кнопку добавления на разделенный экран */
  addToSplitScreenDisable?: boolean
  /** Слайды текущего кейса */
  slides?: ISlide[]
  /** Идентификатор случая. */
  caseId?: ICase['caseId']
}
