import { Typography } from 'antd'
import styled from 'styled-components/macro'

const { Paragraph } = Typography

export const ParagraphElement = styled(Paragraph)`
  white-space: pre-wrap;
  line-height: 16px;

  &.ant-typography {
    color: var(--color-text-1);
    margin-bottom: 0;
  }
  &.ant-typography.ant-typography-secondary {
    color: var(--color-text-3);
  }
`
