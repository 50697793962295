export const loadViewer = (callback: () => void) => {
  const existingScript = document.getElementById('ohiv')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = `${process.env.PUBLIC_URL}/ohiv_viewer_4.9.15.js`
    // script.src = 'https://unpkg.com/@ohif/viewer@4.9.15/dist/index.umd.js'
    script.id = 'ohiv'
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}
