import i18n from 'shared/lib/i18n/i18n'

const t = i18n.t

export enum ArtefactClasses {
  fold = 'fold',
  marker = 'marker',
  bubble_dry = 'bubble_dry',
  bubble_fresh = 'bubble_fresh',
  bubble_dry_no_focus = 'bubble_dry_no_focus',
  /** спецкласс для объединения */
  artefact_union = 'artefact_union',
}

export const ARTEFACT_CLASS_NAME: Record<ArtefactClasses, string> = {
  /** спецкласс для объединения */
  artefact_union: '',
  bubble_dry: t('Пузыри сухие'),
  bubble_dry_no_focus: t('Пузыри сухие не фокус'),
  bubble_fresh: t('Пузыри свежие'),
  fold: t('Складки'),
  marker: t('Маркер'),
}

export const ARTEFACT_CLASS_COLOR: Record<ArtefactClasses, string> = {
  /** спецкласс для объединения */
  artefact_union: '',
  bubble_dry: '#6967E3',
  bubble_dry_no_focus: '#807ee0',
  bubble_fresh: '#EFBF2E',
  fold: '#7CCEBA',
  marker: '#D078ED',
}

export type Artefact = {
  /** zindex полигона */
  annotationZindex: number
  /** тип геометрии */
  annotation_type: string
  /** площадь пересечения артефакта и tissue */
  area_tissue: number
  /** площадь артефакта в микрометрах */
  area_total: number
  /** класс артефакта */
  class: ArtefactClasses
  /** часть tissue, занятая артефактом */
  tissue_percentage: number
}
