import 'antd/dist/antd.less'

import App from 'app/App'
import CustomQueryClientProvider from 'app/providers/CustomQueryClientProvider'
import RouterProvider from 'app/providers/RouterProvider'
import { ReduxProvider } from 'app/redux'
import { SentryErrorBoundary } from 'app/sentry'
import { ThemeProvider } from 'app/styled'
import { StompClientProvider } from 'processes/stomp'
import { TusFileProvider } from 'processes/tus'
import { StrictMode } from 'react'

export const Root = () => (
  <StrictMode>
    <ReduxProvider>
      <StompClientProvider>
        <SentryErrorBoundary>
          <CustomQueryClientProvider>
            <RouterProvider>
              <ThemeProvider>
                <TusFileProvider>
                  <App />
                </TusFileProvider>
              </ThemeProvider>
            </RouterProvider>
          </CustomQueryClientProvider>
        </SentryErrorBoundary>
      </StompClientProvider>
    </ReduxProvider>
  </StrictMode>
)
