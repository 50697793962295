import { CancelTokenSource } from 'axios'
import { Feature } from 'ol'
import { Geometry } from 'ol/geom'
import { get } from 'shared/api'
import ISource from 'types/ISource'
import { getFeaturesFromGeoJson } from 'viewer/map/lib/utils'

let cancelSegmentationToken: CancelTokenSource
let cancelGridToken: CancelTokenSource

const getSegmentationGeoJSON = async (caseId: number | string, slideId: number): Promise<Feature<Geometry>[]> => {
  //TODO: move out cancelation logic
  // if (typeof cancelSegmentationToken != typeof undefined) {
  //   // cancelToken.cancel('Operation canceled due to new request.')
  //   cancelSegmentationToken.cancel()
  // }

  // cancelSegmentationToken = axios.CancelToken.source()

  // const source = CancelToken.source()

  // const promise = axios.get('/todos', {
  //   // Pass the source token to your request
  //   cancelToken: source.token,
  // })

  // // Cancel the request if React Query calls the `promise.cancel` method
  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query')
  // }
  const response = await get<any>({
    url: `/case/${caseId}/slide/${slideId}/segmentation`,
    // config: { cancelToken: cancelSegmentationToken.token },
  })
  return response && getFeaturesFromGeoJson(response)
}

const getSegmentationProcessing = async (caseId: number | string, slideId: number) => {
  const response = await get({
    url: `/case/${caseId}/slide/${slideId}/segmentation/processing`,
  })
  return response
}

const getGrid = async (caseId: number | string, slideId: number, source: ISource): Promise<Feature<Geometry>[]> => {
  // if (typeof cancelGridToken != typeof undefined) {
  //   // cancelToken.cancel('Operation canceled due to new request.')
  //   cancelGridToken.cancel()
  // }

  // cancelGridToken = axios.CancelToken.source()

  const response = await get({
    config: {
      params: {
        source,
      },
    },
    url: `/case/${caseId}/slide/${slideId}/grid`,
    // config: { cancelToken: cancelGridToken.token },
  })
  return response && getFeaturesFromGeoJson(response)
}

const mlService = {
  getGrid,
  getSegmentationGeoJSON,
  getSegmentationProcessing,
}

export default mlService
