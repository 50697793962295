import { useTypedSelector } from 'app/redux/lib/selector'
import { useAvailableFiltersQuery } from 'features/sump'
import ResTable from 'pages/atlas/ui/ResTable'
import { FileToPreview } from 'pages/sump/lib/types'
import BarcodeContainer from 'pages/sump/ui/BarcodeContainer'
import DateFilterDropdown from 'pages/sump/ui/DateFilterDropdown'
import RowActions from 'pages/sump/ui/RowActions'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import { getAvailableFilter, getBarcodeFailText, getFileMimeType } from 'pages/uploaded-file/lib/renderHandlers'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FullScreenModalElement, TextElement } from 'shared/ui/kit'
import { PdfViewer } from 'shared/ui/pdf'
import { Column, dateTimeRenderer } from 'shared/ui/table'
import styled from 'styled-components'
import IListOfItems from 'types/api/IListOfItems'
import { EUploadedFileTab } from 'types/IUploadedFile'
import { IBarcode, IUploadedFileDTO } from 'types/IUploadedFileDTO'

import { useListFilterRange } from './useListFilterRange'
import { useListOnChange } from './useListOnChange'
import { useSortState } from './useSortState'

const StyledTable = styled(ResTable)<{ activeTab: SUMP_TAB }>`
  .ant-table-container {
    height: calc(100vh - 100px);
    overflow: auto;
  }
`
const FileItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 5px 8px 5px 0;
  white-space: nowrap;
  cursor: pointer;

  & > svg {
    color: white;
    flex-shrink: 0;
  }
`
type Props = {
  /** Список выделенных keys */
  selectedRowKeys: React.Key[]
  /** Базавая структура выделения строк для проброса состояний наверх  */
  rowSelection: {
    /** Массив выделенных строк */
    selectedRows: IUploadedFileDTO[]
    /** Хендлер смены выделений */
    onChange: (newSelectedRowKeys: React.Key[], newSelectedRows: IUploadedFileDTO[]) => void
  }
  /** Список сладов в таблице  */
  filesList: IListOfItems<IUploadedFileDTO> | undefined
  /** Состояние загрузки */
  isFetching: boolean
  /** Функция для проброса стейта наверх. После выделения строки пробрасываем тип выделенной ячейки для отображения экшенов */
  updateSelectedTypeTab: (type?: SUMP_TAB) => void
  /** Тип таблицы*/
  listType: 'AWAITING' | 'ERRORS' | 'FIXED' | 'BASKET' | 'SEARCH' | 'DUPLICATE'
  /** Ключ фильтрации таблицы для onChange */
  prevFiltersKey: SUMP_TAB
}

/**
 * Таблица для общего поиска в отстойнике (Загрузка слайдов)
 */
const SearchFilesList = ({
  filesList,
  isFetching,
  listType,
  prevFiltersKey,
  rowSelection,
  selectedRowKeys,
  updateSelectedTypeTab,
}: Props) => {
  const getDataSource = useCallback(
    () => filesList?.content?.map((item) => ({ ...item, key: item.uploadedFileId })),
    [filesList],
  )
  const isSearch = listType === 'SEARCH'
  const [selectedTypeTab, setSelectedTypeTab] = useState<EUploadedFileTab>()
  const [fileToPreview, setFileToPreview] = useState<FileToPreview>()
  const filtersClose = useTypedSelector((state) => state.viewerPage.sumpFilters.SEARCH)
  const prevFilters = useTypedSelector((state) => state.viewerPage.sumpFilters[prevFiltersKey])
  const onChange = useListOnChange({ listType, prevFilters })
  const sortOrderState = useSortState(prevFilters)
  const { data: availableFilters } = useAvailableFiltersQuery()
  const filtersRange = useListFilterRange({ prevFilters, prevFiltersKey })

  useEffect(() => {
    if (!selectedRowKeys.length && selectedTypeTab) {
      setSelectedTypeTab(undefined)
    }
  }, [selectedRowKeys])

  useEffect(() => {
    updateSelectedTypeTab(selectedTypeTab)
  }, [selectedTypeTab])

  const { t } = useTranslation()

  return (
    <>
      <StyledTable
        tableLayout={'fixed'}
        dataSource={getDataSource()}
        scroll={{ y: `calc(100vh - 165px)` }}
        loading={isFetching}
        rowSelection={{
          hideSelectAll: true,
          preserveSelectedRowKeys: true,
          selectedRowKeys,
          type: 'checkbox',
          ...rowSelection,
          getCheckboxProps: (record: IUploadedFileDTO) => ({
            disabled: selectedTypeTab && record.uploadedFileTab !== selectedTypeTab,
          }),
          onSelect: (record: IUploadedFileDTO, selected: boolean, selectedRows?: IUploadedFileDTO[]) => {
            if (selected) {
              !selectedTypeTab && setSelectedTypeTab(record.uploadedFileTab as EUploadedFileTab)
            } else {
              !selectedRows?.length && setSelectedTypeTab(undefined)
            }
          },
        }}
        onChange={onChange}
        pagination={false}
      >
        <Column
          title={t('Имя файла')}
          dataIndex="fileName"
          key="fileName"
          render={(value, record: IUploadedFileDTO) => {
            if (record.fileType === 'DOCUMENT') {
              return (
                <FileItem
                  onClick={() => {
                    const mimeType = getFileMimeType(record.fileName)
                    if (record.documentUrl) setFileToPreview({ mimeType, url: record.documentUrl })
                  }}
                >
                  <TextElement style={{ color: 'var(--color-purple-light)' }} ellipsis>
                    {value}
                  </TextElement>
                </FileItem>
              )
            }
            return value
          }}
        />
        <Column title={t('Имя случая')} dataIndex="caseName" key="caseName" render={(caseName) => caseName} />
        <Column
          title={t('Время загрузки')}
          dataIndex="createdAt"
          key="created_at"
          render={dateTimeRenderer}
          width={130}
          showSorterTooltip={false}
          sorter={isSearch}
          sortOrder={isSearch && filtersClose?.sortBy === 'CREATED_AT' ? sortOrderState : null}
          filterDropdown={
            isSearch
              ? ({ clearFilters, confirm, selectedKeys, setSelectedKeys }) => (
                  <DateFilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    filtersRange={filtersRange}
                    filtersRangeColumn={'created_at'}
                    clearFilters={clearFilters}
                    confirm={confirm}
                  />
                )
              : undefined
          }
          filteredValue={
            isSearch
              ? filtersClose?.createdDateFrom || filtersClose?.createdDateTo
                ? [filtersClose?.createdDateFrom || false, filtersClose?.createdDateTo || false]
                : undefined
              : undefined
          }
        />
        <Column
          title={t('Путь')}
          dataIndex="path"
          key="path"
          render={(path) => path}
          width={250}
          showSorterTooltip={false}
          sorter={isSearch}
          sortOrder={isSearch && filtersClose?.sortBy === 'PATH' ? sortOrderState : null}
        />
        <Column
          title={t('Штрихкод')}
          dataIndex="barcodes"
          render={(barcodes: IBarcode[]) =>
            barcodes ? barcodes.map((item) => <BarcodeContainer key={item.barcode} barcode={item} />) : '-'
          }
          width={160}
        />
        {isSearch && (
          <Column
            title={t('Статус')}
            filters={getAvailableFilter('states', availableFilters)}
            filteredValue={
              filtersClose?.uploadedFileAttachmentStates
                ? [filtersClose?.uploadedFileAttachmentStates].join().split(',')
                : null
            }
            dataIndex="state"
            key="state"
            width={200}
            showSorterTooltip={false}
            sorter={true}
            sortOrder={filtersClose?.sortBy === 'STATE' ? sortOrderState : null}
            render={(barcodeFailureReason, record: IUploadedFileDTO) => {
              switch (record.uploadedFileTab) {
                case 'AWAITING_CASE':
                  return t(`Ожидает`)
                case 'NOT_ATTACHED_TO_CASE':
                  return `${t('Ошибки')}: ${getBarcodeFailText(record.state)}`
                case 'ATTACHED_TO_CASE':
                  return `${t('Разобрано')}: ${record?.attachedToCaseByUser?.fullname || t('Автоматически')}`
                case 'DELETED':
                  return t('Корзина')
                case 'DUPLICATE':
                  return t('Дубль')
              }
            }}
          />
        )}
        <Column
          title=""
          dataIndex="labelUrl"
          key="labelUrl"
          width={90}
          render={(labelUrl, record: IUploadedFileDTO) => <RowActions labelUrl={labelUrl} record={record} />}
        />
      </StyledTable>
      <FullScreenModalElement
        visible={Boolean(fileToPreview)}
        onCancel={() => setFileToPreview(undefined)}
        footer={null}
      >
        {fileToPreview?.mimeType === 'application/pdf' ? (
          <PdfViewer file={fileToPreview?.url} />
        ) : (
          <div style={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
            <img src={fileToPreview?.url} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </div>
        )}
      </FullScreenModalElement>
    </>
  )
}

export default SearchFilesList
