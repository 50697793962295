import { Divider, Slider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useTaskSlideResult } from 'entities/tasks/api/query'
import { AnnotationClasses, annotationsSlice } from 'features/annotations'
import { useAnnotationsQuery } from 'features/annotations/api'
import AnnotationByGroupListItemContainer from 'features/annotations/ui/AnnotationByGroupListItemContainer'
import { AnnotationList } from 'features/annotations/ui/AnnotationsList'
import { IntersectPanel } from 'features/annotations/ui/InteractPanel'
import * as _ from 'lodash'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import { QUERY_TYPE } from 'shared/api'
import { AvatarElement, CheckboxElement, InputNumberElement, Scroll } from 'shared/ui/kit'
import { TabPaneComponent, TabsComponent } from 'shared/ui/tabs'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components/macro'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { IMarkupClass, IMarkupSlide, IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { useSlideGroupType } from 'viewer/container/model/viewerSlice'
import { MIN_SELECTED_ANNOTATIONS_TO_MERGE } from 'viewer/map/layers/annotations/lib/helpers'
import {
  getSingleFeatureFromGeoJson,
  isMultiPolygonAnnotation,
  isNestedGeometryAnnotation,
  isPolygonAnnotation,
} from 'viewer/map/lib/utils'

export interface ISizeInfo {
  height?: number
  width?: number
}

const StyledAnnotationContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-collapse-header {
    padding: 16px 10px !important;
  }
`

const AnnotationsGroupsContainer = styled.div`
  padding: 0 10px;
`

const GappedCheckbox = styled(CheckboxElement)`
  padding: 8px 10px 10px 10px;
  margin-left: 0 !important;
`

const Square = styled.div<{ rgb?: string }>`
  background: ${({ rgb }) => rgb};
  border-radius: 3px;
  width: 16px;
  height: 16px;
`

const OpacityContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 16px 10px;
`

const OpacityTitle = styled.div`
  display: flex;
  align-items: center;
`

const StyledInputNumber = styled(InputNumberElement)`
  flex: 1;
  display: flex;
  align-items: center;
`

const StyledSlider = styled(Slider)`
  flex: 1;
  margin: 0 8px;
  height: 8px;

  .ant-slider-handle {
    background-color: #fff;
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }

  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`

type Props = {
  /** viewerId - id текущего вьювера */
  viewerId: TViewerId
  /** slideId - id текущего слайда */
  slideId: number
  /** caseId - id текущего случая */
  caseId: number
  /** source - источниик слайда */
  source: ISource
}

type Tabs = 'LAYERS' | 'CLASSES' | 'USERS'

const AnnotationsTasksContainer = ({ caseId, slideId, viewerId }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const viewerDispatch = useViewerDispatch(viewerId)
  const annotationsIsVisible = useTypedSelector((state) => state.annotations.annotationsIsVisible)
  const currentAnnotationByClass = useTypedSelector((state) => state.annotations.currentAnnotationByClass)
  const currentAnnotationUsers = useTypedSelector((state) => state.annotations.currentAnnotationUsers)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  const selectedAnnotationId = useTypedSelector((state) => state.viewers[viewerId].viewer.selectedAnnotationsIds[0])
  const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, selectedAnnotationId])
  const slideResults = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, slideId])
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currentSlidesArr = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
  const currSlide = currentSlidesArr?.find((item) => item.slideId === slideId)
  const { data: useTasksSlideResult } = useTaskSlideResult(
    currSlide?.markupTaskId,
    currSlide?.markupSlideId,
    currSlide?.slideId,
  )
  const { data: annotationsIds } = useAnnotationsQuery(caseId, slideId, slideResults, useTasksSlideResult)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const userData = taskData?.participants?.find((item) => item.userId === currentUserId)
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const slideGroupType = useSlideGroupType(viewerId)
  const { descriptionsVisibility } = useTypedSelector((state) => state.viewerPage)
  const annotations: IAnnotation[] =
    (annotationsIds?.ids
      ?.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
      .filter((item) => item?.data) as IAnnotation[]) || []
  const [activeTab, setActiveTab] = useState<Tabs>('LAYERS')
  const currentUserResults = slideResults?.filter((item) => item.markupParticipant?.userId === currentUserId)
  const localStorageAllHiddenName = `isAllAnnotationTasksHidden-${caseId}`
  const [isAllAnnotationsHidden, setIsAllAnnotationsHidden] = useState<boolean>(
    !!Number(localStorage.getItem(localStorageAllHiddenName)),
  )
  const isPolygonAnnotationSelected = isPolygonAnnotation(annotation)
  const isMultiPolygonAnnotationSelected =
    isPolygonAnnotationSelected && isMultiPolygonAnnotation(annotation?.data?.formattedFeature)
  const isNestedGeometryAnnotationSelected =
    isPolygonAnnotationSelected && isNestedGeometryAnnotation(annotation?.data?.formattedFeature)
  const { t } = useTranslation()

  useEffect(() => {
    if (annotationsIds?.ids?.length) {
      const isHidden = !annotationsIsVisible?.length
      setIsAllAnnotationsHidden(isHidden)
      localStorage.setItem(localStorageAllHiddenName, isHidden ? '1' : '0')
    }
  }, [annotationsIsVisible, annotationsIds, localStorageAllHiddenName])

  useEffect(() => {
    setIsAllAnnotationsHidden(!!Number(localStorage.getItem(localStorageAllHiddenName)))
  }, [caseId])

  const getAnnotationsByClass = (annotations: IAnnotation[]) =>
    Object.entries(
      _.groupBy(annotations, (annotation) => {
        const feature = getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)
        return feature.get('class')
      }),
    )
  const getAnnotationsByUser = (annotations: IAnnotation[]) =>
    Object.entries(
      _.groupBy(annotations, (annotation) => {
        if (annotation.user) return annotation.user
        else
          return userData?.canSeeOtherResults
            ? taskData?.participants?.find((item) => item.userId === annotation.userId)?.user?.fullname
            : annotation.userId === userData?.userId &&
                taskData?.participants?.find((item) => item.userId === annotation.userId)?.user?.fullname
      }),
    )
  const filteredByUser = useMemo(() => getAnnotationsByUser(annotations), [annotations])
  const filteredByClass = useMemo(() => getAnnotationsByClass(annotations), [annotations])
  useEffect(() => {
    /** При обновлении списка аннотация сбрасываем фильтры */
    dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByUser(filteredByUser?.map((item) => item[0])))
    dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByClass(filteredByClass?.map((item) => item[0])))
  }, [annotationsIds])

  const toggleAnnotations = useCallback(
    (isHidden: boolean) => {
      if (!annotationsIsVisible?.length) {
        setIsAllAnnotationsHidden(isHidden)
        localStorage.setItem(localStorageAllHiddenName, isHidden ? '1' : '0')
      }
      dispatch(annotationsSlice.actions.setAnnotationsIsVisible(isHidden ? null : annotationsIds?.ids || []))
      if (isHidden) {
        viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
        viewerDispatch(viewerSlice.actions.setBuffer(undefined))
      }
    },
    [annotationsIsVisible, viewerDispatch, annotationsIds],
  )

  useEffect(() => {
    selectedAnnotationsIds?.length && setActiveTab('LAYERS')
  }, [selectedAnnotationsIds?.length])

  useEffect(() => {
    const ids = annotationsIds?.ids

    // после мутаций с аннотациями (например объединение), обновляем выделенные id
    selectedAnnotationsIds?.length &&
      viewerDispatch(
        viewerSlice.actions.setSelectedAnnotationsIds(selectedAnnotationsIds.filter((id) => ids?.includes(id))),
      )
  }, [annotationsIds?.ids, annotationType])

  const itemsByClass = useMemo(() => {
    const setAnnotationsIsVisibleByClass = (annotationClass: string) => {
      const newAnnotationsIsVisibleByClass = currentAnnotationByClass?.includes(annotationClass)
        ? currentAnnotationByClass?.filter((item) => item !== annotationClass)
        : currentAnnotationByClass
        ? [...currentAnnotationByClass, annotationClass]
        : [annotationClass]
      dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByClass(newAnnotationsIsVisibleByClass))
    }
    return (
      <AutoSizer defaultHeight={1} defaultWidth={1}>
        {({ height, width }: ISizeInfo) => (
          <AnnotationsGroupsContainer style={{ height, width }}>
            {filteredByClass?.map(([annotationClass, annotationsList]) => {
              const annotationRgb = currTaskClasses?.find((aClass) => aClass.name === annotationClass)?.colorAsRGBA
              const name = annotationClass === 'undefined' ? t('Не указан') : annotationClass || t('Не указан')
              return (
                <AnnotationByGroupListItemContainer
                  key={annotationClass}
                  groupName={name}
                  annotationsIds={annotationsList.map((a) => a.slideAnnotationId)}
                  icon={
                    <Square
                      rgb={
                        AnnotationClasses.find((aClass) => aClass.name === annotationClass)?.rgb
                          ? `rgba(${annotationRgb}, 1)`
                          : annotationRgb
                      }
                    />
                  }
                  setAnnotationsIsVisibleByClass={setAnnotationsIsVisibleByClass}
                  isVisible={currentAnnotationByClass ? currentAnnotationByClass.includes(name) : true}
                  disableTotalArea={slideGroupType === 'MACRO'}
                />
              )
            })}
          </AnnotationsGroupsContainer>
        )}
      </AutoSizer>
    )
  }, [annotations, currentAnnotationByClass])

  const itemsByUser = useMemo(() => {
    const setAnnotationsIsVisibleByName = (annotationName: string) => {
      const newAnnotationsIsVisibleByClass = currentAnnotationUsers?.includes(annotationName)
        ? currentAnnotationUsers?.filter((item) => item !== annotationName)
        : currentAnnotationUsers
        ? [...currentAnnotationUsers, annotationName]
        : [annotationName]
      dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByUser(newAnnotationsIsVisibleByClass))
    }
    return (
      <AutoSizer defaultHeight={1} defaultWidth={1}>
        {({ height, width }: ISizeInfo) => (
          <AnnotationsGroupsContainer style={{ height, width }}>
            {filteredByUser?.map(([user, annotationsList], i) => {
              const userId = annotationsList[0] ? annotationsList[0].userId : i
              return (
                <AnnotationByGroupListItemContainer
                  key={user}
                  groupName={user}
                  annotationsIds={annotationsList.map((a) => a.slideAnnotationId)}
                  icon={<AvatarElement numberValue={userId} fullname={user} />}
                  setAnnotationsIsVisibleByClass={setAnnotationsIsVisibleByName}
                  isVisible={currentAnnotationUsers ? currentAnnotationUsers.includes(user) : true}
                  disableTotalArea={slideGroupType === 'MACRO'}
                />
              )
            })}
          </AnnotationsGroupsContainer>
        )}
      </AutoSizer>
    )
  }, [annotations, currentUserResults, slideResults])

  const annotationsOpacity = useTypedSelector((state) => state.annotations.annotationsOpacity)
  const { isFastTravel } = useViewerPageProvided()

  const onOpacityChange = (value: number | string | undefined) => {
    viewerDispatch(annotationsSlice.actions.setPrevAnnotationsOpacity(Number(annotationsOpacity)))
    viewerDispatch(annotationsSlice.actions.setAnnotationsOpacity(Number(value)))
  }
  const disableInteractions = useMemo(
    () =>
      !!annotations
        .filter((it) => selectedAnnotationsIds.includes(it.slideAnnotationId))
        .find(
          (it) =>
            it.type === AnnotationType.POINT || it.type === AnnotationType.RULER || it.type === AnnotationType.ARROW,
        ),
    [selectedAnnotationsIds],
  )

  const isIntersectPanelVisible =
    taskData?.status !== 'PAUSED' &&
    (selectedAnnotationsIds?.length >= MIN_SELECTED_ANNOTATIONS_TO_MERGE ||
      isMultiPolygonAnnotationSelected ||
      isNestedGeometryAnnotationSelected)

  useHotkeys(
    'H',
    () => {
      !isFastTravel && toggleAnnotations(!isAllAnnotationsHidden)
    },
    [isAllAnnotationsHidden, isFastTravel],
  )

  const toggleInputFocus = (value: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))

  return (
    <StyledAnnotationContainer>
      <TabsComponent activeKey={activeTab} style={{ height: '-webkit-fill-available' }} onChange={setActiveTab as any}>
        <TabPaneComponent tab={t('Слои')} key="LAYERS">
          {annotations.length > 0 && <AnnotationList annotations={annotations} slideId={slideId} caseId={caseId} />}
        </TabPaneComponent>
        <TabPaneComponent tab={t('Классы')} key="CLASSES">
          <Scroll>{itemsByClass}</Scroll>
        </TabPaneComponent>
        <TabPaneComponent tab={t('Пользователи')} key="USERS">
          <Scroll>{itemsByUser}</Scroll>
        </TabPaneComponent>
      </TabsComponent>

      <Divider />
      <GappedCheckbox
        style={{ paddingBottom: 0 }}
        checked={!descriptionsVisibility}
        onChange={(e) => {
          dispatch(viewerPageSlice.actions.setDescriptionsVisibility(!e.target.checked))
        }}
      >
        {t('Скрыть подписи к аннотациям')}
      </GappedCheckbox>
      <GappedCheckbox checked={isAllAnnotationsHidden} onChange={(e) => toggleAnnotations(e.target.checked)}>
        {t('Скрыть все аннотации')}
      </GappedCheckbox>
      <OpacityContainerWrapper onFocus={() => toggleInputFocus(true)} onBlur={() => toggleInputFocus(false)}>
        <OpacityTitle>{t('Прозрачность')}</OpacityTitle>
        <StyledSlider
          min={0}
          max={MAX_OPACITY}
          tipFormatter={(value) => `${value}%`}
          onChange={onOpacityChange}
          value={annotationsOpacity}
        />
        <StyledInputNumber
          min={0}
          max={MAX_OPACITY}
          formatter={(value) => `${value}%`}
          value={annotationsOpacity}
          onChange={onOpacityChange}
        />
      </OpacityContainerWrapper>
      {isIntersectPanelVisible && (
        <IntersectPanel
          annotationsIds={selectedAnnotationsIds}
          isMultiPolygonAnnotation={isMultiPolygonAnnotationSelected}
          isNestedGeometryAnnotation={isNestedGeometryAnnotationSelected}
          caseId={caseId}
          slideId={slideId}
          disunion={isPolygonAnnotationSelected && selectedAnnotationsIds?.length === 1}
          disabled={disableInteractions}
        />
      )}
    </StyledAnnotationContainer>
  )
}

export default AnnotationsTasksContainer
