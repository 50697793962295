import { selectAtlasViewerUrlSlideId } from 'pages/viewer'
import LeftPanelWrapper from 'pages/viewer/ui/common/LeftPanelWrapper'
import React, { ForwardedRef } from 'react'
import { useSelector } from 'react-redux'

import AtlasSlidesListContainer from './AtlasSlidesListContainer'

type Props = {
  leftRef: ForwardedRef<HTMLDivElement>
}

const AtlasLeftPanelContainer = ({ leftRef }: Props) => {
  const urlAtlasSlideId = useSelector(selectAtlasViewerUrlSlideId)
  return (
    <LeftPanelWrapper style={{ overflow: 'unset', padding: '0px 0px 0px 8px' }} leftRef={leftRef}>
      <AtlasSlidesListContainer selectedSlideId={Number(urlAtlasSlideId)} />
    </LeftPanelWrapper>
  )
}

export default AtlasLeftPanelContainer
