import styled from 'styled-components/macro'

export const FormWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  margin: auto;
  grid-template-columns: minmax(auto, 400px);
  padding: 0 16px;
  text-align: center;

  & > * {
    width: 100%;
  }

  @media screen and (orientation: landscape) {
    padding-bottom: 16px;
  }
`
