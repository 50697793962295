import { useFilesCountQuery } from 'features/sump'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useQueryParams } from 'shared/lib/hooks'
import { NavigationItem } from 'shared/ui/navigation/NavigationBody'
import SumpMenuItem from 'shared/ui/navigation/SumpMenuItem'

const SumpNavigation: React.FC = memo(() => {
  const lisIntegration = useLisMode()
  const workspaceId = useCurrentWorkspaceId()
  const { pathname } = useLocation()
  const queryParams = useQueryParams()
  const { data: counts } = useFilesCountQuery()
  const { t } = useTranslation()

  const navItems: NavigationItem[] = [
    {
      isActive: () => pathname.includes('/warehouse') && queryParams.get('tab') === 'NOT_ATTACHED_TO_CASE',
      path: `/workspace/${workspaceId}/warehouse?tab=NOT_ATTACHED_TO_CASE`,
      title: `${t('Ошибки')} ${counts?.notAttachedCount ?? ''}`,
    },
    {
      isActive: () => pathname.includes('/warehouse') && queryParams.get('tab') === 'ATTACHED_TO_CASE',
      path: `/workspace/${workspaceId}/warehouse?tab=ATTACHED_TO_CASE`,
      title: `${t('Разобрано')} ${counts?.attachedCount ?? ''}`,
    },
    {
      isActive: () => pathname.includes('/warehouse') && queryParams.get('tab') === 'DELETED',
      path: `/workspace/${workspaceId}/warehouse?tab=DELETED`,
      title: `${t('Корзина')} ${counts?.deletedCount ?? ''}`,
    },
  ]

  lisIntegration !== 'none' &&
    navItems.unshift({
      isActive: () => pathname.includes('/warehouse') && queryParams.get('tab') === 'AWAITING_CASE',
      path: `/workspace/${workspaceId}/warehouse?tab=AWAITING_CASE`,
      title: t('Ожидает ') + (counts?.awaitedCaseCount ?? ''),
    })
  return (
    <>
      {navItems.map((item) => (
        <SumpMenuItem key={item.path} path={item.path} title={item.title} active={item.isActive()} />
      ))}
    </>
  )
})

export default SumpNavigation
