import { Badge } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerPageSlice } from 'pages/viewer'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import { useDispatch } from 'react-redux'
import { IconElement, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import { useNotificationsQuery, useNotificationsUnreadQuery } from './api/query'

const IconWrapper = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? 'var(--color-purple)' : 'var(--color-text-3)')};
  display: grid;
  place-items: center;
  &:hover {
    color: var(--color-text-1);
    cursor: pointer;
  }
`

const StyledBadge = styled(Badge)`
  & .ant-badge-count {
    color: var(--color-white);
  }
`

export const OpenNotificationsButton = () => {
  const dispatch = useDispatch()
  const { isLoading } = useNotificationsQuery()
  const { data: unreadCount } = useNotificationsUnreadQuery()
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const isActive = rightPanelType === SideRightPanelType.NOTIFICATION

  const handleClick = () => dispatch(viewerPageSlice.actions.toggleViewerRightPanel(SideRightPanelType.NOTIFICATION))

  if (isLoading) {
    return (
      <SpinElement>
        <IconWrapper isActive={isActive}>
          <IconElement name="notification" />
        </IconWrapper>
      </SpinElement>
    )
  }
  return (
    <>
      <StyledBadge count={unreadCount} size="small">
        <IconWrapper isActive={isActive} onClick={handleClick}>
          <IconElement name="notification" />
        </IconWrapper>
      </StyledBadge>
    </>
  )
}
