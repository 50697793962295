import { del, get, post } from 'shared/api'
import ICase from 'types/ICase'
import { IPatientDTO } from 'types/IPatient'

const fetchPatients = async () =>
  get<IPatientDTO[]>({
    url: '/patient',
  })

const createPatient = async (data: IPatientDTO) =>
  post({
    data,
    url: '/patient',
  })

const fetchPatientCases = async (patientProfileId: number) =>
  get<ICase[]>({
    url: `/patient/${patientProfileId}/case`,
  })
const updatePatientCases = async (patientProfileId: number) =>
  get<IPatientDTO>({
    url: `/patient/${patientProfileId}`,
  })
const deletePatientCases = async (patientProfileId: number) =>
  del({
    url: `/patient/${patientProfileId}case`,
  })

const patientService = {
  createPatient,
  deletePatientCases,
  fetchPatientCases,
  fetchPatients,
  updatePatientCases,
}

export default patientService
