import { MapBrowserEvent } from 'ol'
import { Condition as EventsConditionType } from 'ol/events/condition'

export const isRightClick: EventsConditionType = (e) =>
  //@ts-ignore
  e.type === 'pointerdown' && e.originalEvent.button === 2

export const isDoubleClick = (e: MapBrowserEvent<any>, prevE?: MapBrowserEvent<any>) => {
  if (e.pixel[0] === prevE?.pixel[0] && e.pixel[1] === prevE?.pixel[1]) return true
}

export const isMiddleClick: EventsConditionType = (e) => e.type === 'pointerdown' && e.originalEvent.button === 1
export const isMiddleDrag: EventsConditionType = (e) => e.type === 'pointerdrag' && e.originalEvent.buttons === 4
export const isLeftClick: EventsConditionType = (e) => e.type === 'pointerdown' && e.originalEvent.button === 0
