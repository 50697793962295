import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  rightPanelTab: 'INFO' | 'COMMENTS'
  patientModal: boolean
}

const initialState: State = {
  patientModal: false,
  rightPanelTab: 'INFO',
}

export const casesSlice = createSlice({
  initialState,
  name: 'casesSlice',
  reducers: {
    setPatientModal(state, { payload }: PayloadAction<boolean>) {
      state.patientModal = payload
    },
    setRightPanelTab(state, { payload }: PayloadAction<'INFO' | 'COMMENTS'>) {
      state.rightPanelTab = payload
    },
  },
})
