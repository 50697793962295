import { FC } from 'react'
import styled from 'styled-components/macro'

import { ButtonElement, ButtonElementProps } from './ButtonElement'

const StyledButtonElement = styled(ButtonElement)`
  background: inherit;

  &:hover,
  :focus {
    background: none;
  }
`

export const IconButtonElement: FC<ButtonElementProps> = (props) => <StyledButtonElement {...props} />
