import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { createContext } from 'react'

import { UserActivityProvider } from './user-activity'
import { UserStatusProvider } from './user-status'

export const MultiplayerContext = createContext({})

const MultiplayerProvider: React.FC = ({ children }) => {
  const { activeViewerId } = useOpenViewers()
  const { caseId, slideId } = useViewerIdSlideState(activeViewerId)

  return (
    <MultiplayerContext.Provider value={{}}>
      {caseId ? (
        <UserStatusProvider caseId={caseId} slideId={slideId}>
          <UserActivityProvider caseId={caseId}>{children}</UserActivityProvider>
        </UserStatusProvider>
      ) : (
        children
      )}
    </MultiplayerContext.Provider>
  )
}

export default MultiplayerProvider
