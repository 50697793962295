import { useTranslation } from 'react-i18next'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

type Props = {
  numberCase: string
}

const OnCaseOpenOtherTopic = ({ numberCase }: Props) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <TextWrapper>
        <TextElement>
          {t('Случай')} {numberCase} {t('больше')} <br /> {t('недоступен из этого раздела')}
        </TextElement>
      </TextWrapper>
    </Wrapper>
  )
}

export default OnCaseOpenOtherTopic
