import { Divider } from 'antd'
import React, { useState } from 'react'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import GleasonContainer from './GleasonContainer'
import SegmentationContainer from './SegmentationContainer'

type Props = {
  caseId: number
  segmentationRef: React.RefObject<HTMLDivElement>
  slideId: number
  viewerId: TViewerId
  source: ISource

  initPosition: number[]
  onPositionChange: (position: number[]) => void
}

const SegmentationToolPanel = ({
  caseId,
  initPosition,
  onPositionChange,
  segmentationRef,
  slideId,
  source,
  viewerId,
}: Props) => {
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  return (
    <ViewerInfoToolPanelContainer
      id={'SEGMENTATION'}
      panelRef={segmentationRef}
      type={'SEGMENTATION'}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      position={position}
      setPosition={setPosition}
    >
      <SegmentationContainer slideId={slideId} caseId={caseId} viewerId={viewerId} />
      <Divider />
      <GleasonContainer slideId={slideId} caseId={caseId} source={source} />
    </ViewerInfoToolPanelContainer>
  )
}

export default SegmentationToolPanel
