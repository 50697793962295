import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  DEFAULT_MAXIN,
  DEFAULT_MID,
  DEFAULT_MININ,
  MAX_MIDIN,
  MIN_MIDIN,
  slideMapViewSlice,
} from 'pages/viewer/model/slideMapViewSlice'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getIfUndefined } from 'shared/lib/common'
import { convertToFractionalRange } from 'shared/lib/range'
import { IconButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { useSlideMapViewSelector, useViewerDispatch } from 'viewer/container'

import { ColorCorrection } from './ColorCorrection'
import { GammaCorrection } from './GammaCorrection'

const ContextMenu = styled.div`
  position: absolute;
  top: 44px;
  left: -108px;
  ${() => modalBackground}
  ${() => modalShadow}
  border-radius: 5px;
  z-index: 11;

  width: 272px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const IconButton = styled(IconButtonElement)`
  & svg {
    color: var(--color-text-3);
    & path {
      fill: var(--color-text-3);
    }
  }
`

const IconBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > *:first-child {
    padding-top: 4px;
  }
`

const HeaderBlock = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border-1);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`

const TitleElement = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`

export const ColorChangerModal = () => {
  const dispatch = useDispatch()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const { slideId } = useViewerIdSlideState(viewerId)
  const { colorParams } = useSlideMapViewSelector({ slideId, viewerId })
  const viewerDispatch = useViewerDispatch(viewerId)
  const { t } = useTranslation()
  const [red, setRed] = useState<number>(getIfUndefined(0, colorParams?.red))
  const [green, setGreen] = useState<number>(getIfUndefined(0, colorParams?.green))
  const [blue, setBlue] = useState<number>(getIfUndefined(0, colorParams?.blue))
  const [minin, setMinin] = useState<number>(DEFAULT_MININ)
  const [midin, setMidin] = useState<number>(1)
  const [maxin, setMaxin] = useState<number>(DEFAULT_MAXIN)
  const [mid, setMid] = useState<number>(DEFAULT_MID)

  const changeColors = () => {
    dispatch(
      slideMapViewSlice.actions.setColorsParams({
        colorParams: {
          ...colorParams,
          black: minin,
          blue,
          gamma: mid,
          green,
          red,
          white: maxin,
        },
        slideId,
        viewerId,
      }),
    )
  }

  const resetColorCorrection = () => {
    setRed(0)
    setGreen(0)
    setBlue(0)
    setMinin(DEFAULT_MININ)
    setMidin(1)
    setMaxin(DEFAULT_MAXIN)
    setMid(DEFAULT_MID)
  }

  const closeColorCorrection = () => {
    viewerDispatch(viewerPageSlice.actions.setColorsModal(false))
  }

  useEffect(() => {
    changeColors()
  }, [red, green, blue, minin, mid, maxin])

  useEffect(() => {
    setRed(getIfUndefined(0, colorParams?.red))
    setGreen(getIfUndefined(0, colorParams?.green))
    setBlue(getIfUndefined(0, colorParams?.blue))
    setMinin(getIfUndefined(DEFAULT_MININ, colorParams?.black))
    setMid(getIfUndefined(DEFAULT_MID, colorParams?.gamma))
    setMidin(
      +convertToFractionalRange(getIfUndefined(DEFAULT_MID, colorParams?.gamma), MIN_MIDIN, MAX_MIDIN).toFixed(2),
    )
    setMaxin(getIfUndefined(DEFAULT_MAXIN, colorParams?.white))
  }, [viewerId, slideId])

  const toggleInputFocus = (value: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))

  return (
    <ContextMenu>
      <HeaderBlock>
        <TitleElement>{t('Цветокоррекция')}</TitleElement>
        <IconBlock>
          <IconButton icon={<IconElement name="undoSmall" size={'md'} />} onClick={resetColorCorrection} />
          <IconButton icon={<IconElement name="cross16" size={'md'} />} onClick={closeColorCorrection} />
        </IconBlock>
      </HeaderBlock>

      <GammaCorrection
        maxin={maxin}
        mid={mid}
        midin={midin}
        minin={minin}
        setMaxin={setMaxin}
        setMid={setMid}
        setMidin={setMidin}
        setMinin={setMinin}
        toggleInputFocus={toggleInputFocus}
      />

      <ColorCorrection
        toggleInputFocus={toggleInputFocus}
        blue={blue}
        green={green}
        red={red}
        setBlue={setBlue}
        setGreen={setGreen}
        setRed={setRed}
      />
    </ContextMenu>
  )
}
