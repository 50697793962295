import { CaseInfoPanel } from 'features/new-preview-panel/CaseInfoPanel'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ModalHandle } from 'shared/ui/modal'
import styled from 'styled-components'
import { ICaseDTO } from 'types/ICase'

const CaseInfoBoxWrapper = styled.div`
  position: relative;
  background-color: var(--color-bg-2);
  color: var(--color-text-1);
`
type CaseInfoBoxProps = {
  selectedCase: ICaseDTO | null
  setCurrentCase: Dispatch<SetStateAction<{ caseId?: number; wsId?: number }>>
  onClose: () => void
  refetch: () => void
  caseFormModal: React.MutableRefObject<ModalHandle | null>
  declineModal: React.MutableRefObject<ModalHandle | null>
  statusHistoryModal: React.MutableRefObject<ModalHandle | null>
  reportCreationModalRef: React.MutableRefObject<ModalHandle | null>
  closeContextMenu: () => void
}

const HOT_KEYS_CASE_INFO_BOX_CLOSE = 'esc, o'

export const PreviewPanel: FC<CaseInfoBoxProps> = ({
  caseFormModal,
  closeContextMenu,
  declineModal,
  onClose,
  refetch,
  reportCreationModalRef,
  selectedCase,
  setCurrentCase,
  statusHistoryModal,
}) => {
  useHotkeys(HOT_KEYS_CASE_INFO_BOX_CLOSE, onClose)
  return (
    <CaseInfoBoxWrapper>
      {selectedCase?.caseId && (
        <CaseInfoPanel
          setCurrentCase={setCurrentCase}
          caseFormModal={caseFormModal}
          closeContextMenu={closeContextMenu}
          caseId={selectedCase?.caseId}
          declineModal={declineModal}
          onClose={onClose}
          refetch={refetch}
          statusHistoryModal={statusHistoryModal}
          reportCreationModalRef={reportCreationModalRef}
        />
      )}
    </CaseInfoBoxWrapper>
  )
}
