import styled from 'styled-components/macro'

import { MenuItem } from './MenuItem'

export type NavigationItem = {
  icon?: any // IIconElementProps['name']
  image?: any
  path: string
  title: string
  disabled?: boolean
  isActive: () => boolean
  split?: boolean
}

type NavigationMenuComponentProp = {
  navItems: NavigationItem[]
  title?: string
  space?: boolean
  style?: any
}

const NavigationBody = ({ navItems, style, title }: NavigationMenuComponentProp) => (
  <StyledNavigationBody style={style}>
    {title && <Title>{title}</Title>}

    {navItems.map((item) => (
      <>
        {item.split && <div style={{ backgroundColor: 'var(--color-border-1)', height: 1, margin: '16px 0' }} />}
        <MenuItem
          key={item.path}
          path={item.path}
          icon={item.icon}
          image={item.image}
          title={item.title}
          active={item.isActive()}
          disabled={item.disabled}
        />
      </>
    ))}
  </StyledNavigationBody>
)

export default NavigationBody

const StyledNavigationBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  padding: 0 16px;
`
const Title = styled.p`
  color: var(--color-text-1);
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 4px;
`
