import React, { useState } from 'react'
import TViewerId from 'types/TViewerId'
import Ki67Container from 'viewer/tools/ui/ki67/Ki67Container'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

type Props = {
  viewerId: TViewerId
  ki67Ref: React.RefObject<HTMLDivElement>

  initPosition: number[]
  onPositionChange: (position: number[]) => void
}

const Ki67ToolPanel = ({ initPosition, ki67Ref, onPositionChange, viewerId }: Props) => {
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  return (
    <ViewerInfoToolPanelContainer
      id={'KI67'}
      panelRef={ki67Ref}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      type={'KI67'}
      position={position}
      setPosition={setPosition}
    >
      <Ki67Container viewerId={viewerId} />
    </ViewerInfoToolPanelContainer>
  )
}

export default Ki67ToolPanel
