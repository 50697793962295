import styled from 'styled-components/macro'

const ProgressWrapper = styled.div`
  background-color: var(--color-bg-4);
  height: 100%;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
`

const StyledProgress = styled.div<{ percent: number }>`
  height: 2px;
  width: 100%;
  background: linear-gradient(
    90deg,
    var(--color-purple) 0%,
    var(--color-purple) ${({ percent }) => percent || 0}%,
    var(--color-bg-2) ${({ percent }) => percent || 0}%,
    var(--color-bg-2) 100%
  );
  margin-top: 4px;
`

const InfoText = styled.div`
  font-size: 11px;
  line-height: 12px;
  color: var(--color-text-1);
`

type Props = {
  percent: number
}

const PreviewPanelThumbnailProgress = ({ percent }: Props) => (
  <ProgressWrapper>
    <InfoText>{percent}%</InfoText>
    <StyledProgress percent={percent} />
  </ProgressWrapper>
)

export default PreviewPanelThumbnailProgress
