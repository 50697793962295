import React, { FC, HTMLAttributes, memo, useCallback } from 'react'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const ThumbnailInner = styled.div`
  position: absolute;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  z-index: 1;
`
const DeleteButton = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  & path {
    fill: var(--color-text-3);
  }

  &:hover {
    & path {
      fill: var(--color-red);
    }
  }
`

const StyledThumbnail = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  &:hover {
    ${ThumbnailInner} {
      box-shadow: 0 0 0 2px inset var(--color-purple);
    }
    ${DeleteButton} {
      display: flex;
    }
  }
`

const ThumbnailContent = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 3px;
`

type Props = {
  onDelete?: () => Promise<void>
} & HTMLAttributes<HTMLDivElement>

const Thumbnail: FC<Props> = memo(({ children, onDelete, ...rest }) => {
  const onDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      onDelete && onDelete()
    },
    [onDelete],
  )
  return (
    <StyledThumbnail {...rest}>
      {onDelete && (
        <DeleteButton onClick={onDeleteClick} data-testid="delete-thumbnail-btn">
          <IconElement name="delete" size={'md'} />
        </DeleteButton>
      )}
      <ThumbnailInner />
      <ThumbnailContent>{children}</ThumbnailContent>
    </StyledThumbnail>
  )
})

export default Thumbnail
