import { notification } from 'antd'
import { IconElement } from 'shared/ui/kit'

export const initNotices = () => {
  notification.config({
    bottom: 0,
    closeIcon: <IconElement name="cross" />,
    placement: 'bottomLeft',
  })
}
