import { useTranslation } from 'react-i18next'
import { ImageElement, LinkElement, SpaceElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
`

const ThumbnailWrapper = styled(ImageElement)`
  min-width: 48px;
  max-height: 48px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 160px;
`

export type OnSlideDuplicateNotificationProps = {
  src?: string
  id?: number
  uploadUuid?: string
  caption?: string
  onRetry: () => void
}

const OnSlideDuplicateNotification = ({ caption, id, onRetry, src, uploadUuid }: OnSlideDuplicateNotificationProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div>
        <ThumbnailWrapper src={src} />
      </div>
      <SpaceElement size={10} />
      <TextWrapper>
        <TitleElement level={4} ellipsis>
          {t('Слайд')} {caption || id}
        </TitleElement>
        <TitleElement level={4} style={{ margin: 0 }}>
          {t('уже был загружен')}
        </TitleElement>
        <LinkElement onClick={onRetry}>{t('Все равно загрузить')}</LinkElement>
      </TextWrapper>
    </Wrapper>
  )
}

export default OnSlideDuplicateNotification
