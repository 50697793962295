import useQueryParams from 'shared/lib/hooks/useQueryParams'

import { PeopleContainer } from './PeopleContainer'
import { RoutingContainer } from './RoutingContainer'
import { TemplateContainer } from './TemplateContainer'

export const SettingsContainer = () => {
  const queryParams = useQueryParams()
  if (queryParams.get('subpage') === 'mash') {
    return <RoutingContainer />
  }
  if (queryParams.get('subpage') === 'tmpl') {
    return <TemplateContainer />
  }
  if (queryParams.get('subpage') === 'people') {
    return <PeopleContainer />
  }
  return null
}
