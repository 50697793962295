import { ViewType } from 'features/uploaded-file/lib/common'
import { ImageElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledContainer = styled.div<{ tileSize: number; viewType?: string; isSelected?: boolean }>`
  display: flex;
  flex: ${({ viewType }) => (viewType === ViewType.GRID ? undefined : '0 1 115px')};
  height: ${({ tileSize, viewType }) => (viewType === ViewType.GRID ? `${tileSize / 2}px` : undefined)};
  width: ${({ tileSize, viewType }) => (viewType === ViewType.GRID ? `${tileSize}px` : '230px')};
  border: 2px solid var(--color-border-1);
  border-radius: 3px;
  margin-right: 4px;
  overflow: hidden;
  ${({ isSelected }) =>
    isSelected &&
    `
  border: 4px solid var(--color-purple);
  border-radius: 1.5px`};
`

const StyledImage = styled(ImageElement)<{ tileSize: number; fileType?: string; viewType?: string }>`
  background-color: var(--color-white);
  border-radius: 0;
  height: ${({ fileType, tileSize, viewType }) =>
    fileType ? '100%' : viewType === ViewType.GRID ? `${tileSize / 2}px` : '115px'};
  width: ${({ fileType, tileSize, viewType }) =>
    fileType ? '100%' : viewType === ViewType.GRID ? `${tileSize / 2}px` : '115px'};
`
const StyledImageWrapper = styled.div<{ tileSize: number; viewType?: string }>`
  height: ${({ tileSize, viewType }) => (viewType === ViewType.GRID ? `${tileSize / 2}px` : '115px')};
  width: ${({ tileSize, viewType }) => (viewType === ViewType.GRID ? `${tileSize}px` : '230px')};
  display: flex;
`

export { StyledContainer, StyledImage, StyledImageWrapper }
