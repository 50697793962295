import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { NoticesList } from 'features/notifications/NotificationsList'
import { isChatAccess } from 'features/support-chat'
import { SupportChatNew } from 'features/support-chat/ui/SupportChat'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import React, { FC, RefObject } from 'react'

import { TabsContentWrapper } from './Table.styled'

type TProps = {
  notificationButton?: RefObject<HTMLDivElement | null>
}

const TableLayout: FC<TProps> = ({ children, notificationButton }) => {
  const currenWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isChatVisible = isChatAccess(currenWorkspace)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { filterHeight, isFiltersPicked } = useCaseManagementContext()

  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
    >
      <TabsContentWrapper isFiltersPicked={isFiltersPicked} filterHeight={filterHeight}>
        {children}
      </TabsContentWrapper>
      {isChatVisible && <SupportChatNew />}
    </ContentLayout>
  )
}

export default TableLayout
