import { annotationsSlice } from 'features/annotations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  padding: 4px 8px;
  background: #ffffff17;
  border-radius: 5px;
  margin: 4px 8px 0px 8px;
  align-items: center;
  .ant-btn-link {
    color: #4099f7;
  }
`

export const AnnotationFilterNotificationBlock = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const onResetAnnotationFilter = () => {
    dispatch(annotationsSlice.actions.resetAnnotationFilter())
  }

  return (
    <StyledWrapper>
      <IconElement defaultFill name="infoSmall" size={'md'} />
      <div>
        <div style={{ padding: '0 8px' }}>{t('Часть аннотаций отфильтрована')}</div>
        <ButtonElement type="link" onClick={onResetAnnotationFilter}>
          {t('Показать все')}
        </ButtonElement>
      </div>
    </StyledWrapper>
  )
}
