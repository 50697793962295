import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as _ from 'lodash'
import TViewerId from 'types/TViewerId'
import { useSlideMapViewSelector } from 'viewer/container/lib/useViewerSelector'
import { ColorParams } from 'viewer/container/model/viewerSlice'

type State = {
  [key: string]: {
    viewZoom?: number
    viewRotation?: number
    viewCenter?: number[]
    colorParams?: ColorParams
  }
}

export type SlideMapViewKey = {
  /** id слайда во вьювере */
  slideId: number
  /** id вьювера */
  viewerId: TViewerId
}

const initialState: State = {}

export const DEFAULT_MAXIN = 255
export const DEFAULT_MID = 0.5
export const DEFAULT_MININ = 0
export const MIN_MIDIN = 0
export const MAX_MIDIN = 2

export const createSlideMapViewSlice = () =>
  createSlice({
    initialState,
    name: 'slideMapViewSlice',
    reducers: {
      setCenter(state, { payload }: PayloadAction<SlideMapViewKey & { center: number[] | undefined }>) {
        _.set(state, `${payload.slideId}-${payload.viewerId}.viewCenter`, payload.center)
      },
      setColorsParams(state, { payload }: PayloadAction<SlideMapViewKey & { colorParams: ColorParams | undefined }>) {
        _.set(state, `${payload.slideId}-${payload.viewerId}.colorParams`, payload.colorParams)
      },
      setInitialState() {
        return initialState
      },
      setRotation(state, { payload }: PayloadAction<SlideMapViewKey & { rotation: number | undefined }>) {
        _.set(state, `${payload.slideId}-${payload.viewerId}.viewRotation`, payload.rotation)
      },
      setZoom(state, { payload }: PayloadAction<SlideMapViewKey & { zoom: number | undefined }>) {
        _.set(state, `${payload.slideId}-${payload.viewerId}.viewZoom`, payload.zoom)
      },
    },
  })

export const useColorsIsActive = (key: SlideMapViewKey) => {
  const { colorParams } = useSlideMapViewSelector(key)
  return colorParams && (colorParams?.red !== 0 || colorParams?.green !== 0 || colorParams?.blue !== 0)
}

export const useGammaIsActive = (key: SlideMapViewKey) => {
  const { colorParams } = useSlideMapViewSelector(key)
  return Boolean(
    colorParams?.black ||
      (colorParams?.white && colorParams.white !== DEFAULT_MAXIN) ||
      (colorParams?.gamma && colorParams.gamma !== DEFAULT_MID),
  )
}

export const useColorCorrectionActive = (key: SlideMapViewKey) => {
  const colorsIsActive = useColorsIsActive(key)
  const gammaIsActive = useGammaIsActive(key)
  return colorsIsActive || gammaIsActive
}

export const slideMapViewSlice = createSlideMapViewSlice()
