import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { viewerPageSlice } from 'pages/viewer'
import { StompClientContext, useSubscription } from 'processes/stomp'
import { WsResponseCasesList } from 'processes/stomp/types'
import React, { RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { EmptyElement, IconElement, InputElement } from 'shared/ui/kit'
import { ModalComponent } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import IUserRole from 'types/IUserRole'
import { v4 as uuidv4 } from 'uuid'

import { SearchOption } from './SearchOption'

const REQUEST_DELAY = 300
const INIT_UUID = uuidv4()

type Props = {
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  active: boolean
}

export const SearchCase = ({ active, setActive }: Props) => {
  const dispatch = useDispatch()
  const { publish } = useContext(StompClientContext)
  const workspaceId = useCurrentWorkspaceId()
  const authorities = useTypedSelector((state) => state.user.authorities)
  const [searchQuery, setSearchQuery] = useState('')
  const [activeList, setActiveList] = useState<boolean>(true)
  const [options, setOptions] = useState<ICase[]>([])
  const listBoardRef = useRef(null)
  const debouncedSearchQuery = useDebounce(searchQuery, REQUEST_DELAY)
  /** Минимальная длина текста для запуска поиска c дебаунсом */
  const isDebouncedSearchMinLength = debouncedSearchQuery.length > 1
  /** Идентификатор для создания подписки на топик WS. */
  /** TODO Делаем переподписку после смены ввода значения поиска. Убрать после доработки бека по обработке очередей */
  const uuid: string = useMemo(() => (isDebouncedSearchMinLength ? uuidv4() : INIT_UUID), [debouncedSearchQuery])

  const { t } = useTranslation()

  const onSearch = (query: string) => {
    setSearchQuery(query)
    setActiveList(true)
  }

  const onSelect = (option?: ICase) => {
    if (option && workspaceId) {
      setActive(false)
      window.open(`/workspace/${workspaceId}/viewer/${option.caseId}`, '_blank')
    }
    if (!option) {
      setActiveList(false)
    }
  }

  const useOnClickOutside = (ref: RefObject<HTMLDivElement>, handler: any) => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    useEffect(() => {
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  useOnClickOutside(listBoardRef, () => setActiveList(false))

  useSubscription<WsResponseCasesList>(`/user/topic/autocomplete/cases/${uuid}`, (result) => {
    setOptions(result?.payload)
  })

  useEffect(() => {
    isDebouncedSearchMinLength &&
      publish(`/app/workspace/${workspaceId}/case/search/${uuid}`, {
        query: debouncedSearchQuery,
        searchArchivedCases: true,
        searchOwnCases: authorities?.includes(IUserRole.ROLE_USER),
        searchSharedCases: true,
      })
  }, [debouncedSearchQuery])

  useEffect(() => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(true))
    return () => {
      dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(false))
    }
  }, [])

  return (
    <StyledModal
      title={
        <div style={{ display: 'flex' }}>
          <IconElement name="search" fill={'var(--color-text-3)'} />
          <StyledInput
            autoFocus={activeList}
            onFocus={() => setActiveList(true)}
            onBlur={() => !searchQuery.length && setActive(false)}
            value={searchQuery}
            onChange={(e) => onSearch(e?.target?.value)}
            placeholder={t('Поиск по имени случая или пациенту')}
            data-testid="search-case-input"
          />
        </div>
      }
      bodyStyle={{
        paddingBottom: isDebouncedSearchMinLength ? 16 : 4,
      }}
      footer={null}
      visible={active}
      onCancel={() => setActive(false)}
      centered={false}
    >
      {isDebouncedSearchMinLength && (
        <>
          <Divider style={{ marginTop: 0 }} />
          <div style={{ minHeight: 262 }} ref={listBoardRef}>
            {activeList ? (
              <ListBoard data-testid="search-case-list">
                <OptionsWrapper>
                  {options.map((option) => (
                    <SearchOption key={option.caseId} option={option} searchValue={searchQuery} onClick={onSelect} />
                  ))}
                </OptionsWrapper>
                {!options.length && <EmptyElement />}
              </ListBoard>
            ) : (
              <TextWrapper>
                <div>{t('Поиск по всем случаям')}</div>
              </TextWrapper>
            )}
          </div>
        </>
      )}
    </StyledModal>
  )
}

const StyledModal = styled(ModalComponent)`
  padding-bottom: 0;
  top: 54px;

  .ant-modal-body {
    width: 464px;
    max-height: calc(100vh - 158px);
    overflow-y: auto;
  }
  & .ant-modal-content .ant-modal-body {
    padding: 0 16px 16px 16px;
  }
`

const StyledInput = styled(InputElement)`
  background: none;
  font-size: 14px;
  &.ant-input:focus,
  &.ant-input-focused {
    box-shadow: none !important;
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  height: 262px;
`
const ListBoard = styled.div`
  overflow: auto;
  align-items: center;
`

const OptionsWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow-y: auto;
`
