import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery } from 'features/cases/api/query'
// @ts-ignore
import { selectAtlasViewerUrlSlideId } from 'pages/viewer'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import ViewerRightPanel from 'pages/viewer/ui/common/ViewerRightPanel'
import { useSelector } from 'react-redux'
import { Center, SpinElement } from 'shared/ui/kit'

const AtlasRightPanelContainer = () => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const urlAtlasSlideId = useSelector(selectAtlasViewerUrlSlideId)
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { isLoading } = useCaseQuery({ caseId, source })

  if (isLoading) {
    return (
      <Center>
        <SpinElement />
      </Center>
    )
  }

  if (!urlAtlasSlideId || rightPanelType !== SideRightPanelType.CASE) {
    return null
  }

  return (
    <>
      <ViewerRightPanel
        urlSlideId={urlAtlasSlideId}
        urlCaseId={urlAtlasSlideId}
        source={source}
        activeTab={rightPanelType}
        viewerId={viewerId}
        activeViewerCaseId={urlAtlasSlideId}
        activeViewerSlideId={slideId}
        activeViewerSource={source}
        type={'ATLAS'}
      />
    </>
  )
}

export default AtlasRightPanelContainer
