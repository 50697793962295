import { viewerAttachmentsSlice } from 'features/attachments'
import Feature from 'ol/Feature'
import { Geometry, Polygon } from 'ol/geom'
import GeometryType from 'ol/geom/GeometryType'
import { Draw } from 'ol/interaction'
import { createBox } from 'ol/interaction/Draw'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, RegularShape, Stroke, Style } from 'ol/style'
import Transform from 'ol-ext/interaction/Transform'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect } from 'react'
import { MapContext } from 'shared/lib/map'
import { useViewerDispatch } from 'viewer/container'

const AttachmentDrawInteraction = () => {
  const { viewerId } = useContext(MapContext)
  const map = useViewerIdMap(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)

  useEffect(() => {
    const layer = new VectorLayer({
      source: new VectorSource({}),

      style: [
        new Style({
          fill: new Fill({
            color: 'rgba(64, 153, 247, 0.1)',
          }),
          stroke: new Stroke({
            color: '#4099F7',
            width: 3,
          }),
        }),
      ],
      zIndex: 100,
    })
    map.addLayer(layer)
    const style = [
      new Style({
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
        stroke: new Stroke({
          color: '#4099F7',
          width: 4,
        }),
      }),
    ]
    // @ts-ignore
    const transform = new Transform({
      layers: [layer],
      rotate: false,
      scale: true,
      selection: false,
      translate: true,
    })

    const draw = new Draw({
      geometryFunction: createBox(),
      source: layer.getSource(),
      style,
      type: GeometryType.CIRCLE,
    })
    map.addInteraction(draw)

    draw.on('drawend', (evt: { feature: Feature<Geometry> }) => {
      map.removeInteraction(draw)
      map.addInteraction(transform as any)
      transform.select(evt.feature, true)
      // @ts-ignore
      transform.setStyle('default', [new Style({ stroke: undefined })])
      const circleStyleConfig = {
        fill: new Fill({ color: [255, 255, 255, 1] }),
        points: 20,
        radius: 7,
        stroke: new Stroke({ color: '#4099F7', width: 1 }),
      }
      transform.setStyle(
        // @ts-ignore
        'scale1',
        new Style({
          image: new RegularShape(circleStyleConfig),
        }),
      )
      transform.style.scale = transform.style.scale1
      transform.style.scale2 = transform.style.scale1
      transform.style.scale3 = transform.style.scale1
      transform.setStyle(
        // @ts-ignore
        'scalev',
        new Style({
          image: new RegularShape({
            ...circleStyleConfig,
            radius: 5,
          }),
        }),
      )
      transform.style.scaleh1 = transform.style.scalev
      transform.style.scalev2 = transform.style.scalev
      transform.style.scaleh3 = transform.style.scalev

      const getBboxFromFeature = (feature: Feature<any>) => {
        const coord = (feature.getGeometry() as Polygon)?.getCoordinates()[0]
        if (!coord) return null
        return { x1: coord[0][0], x2: coord[2][0], y1: coord[0][1], y2: coord[2][1] }
      }

      viewerDispatch(viewerAttachmentsSlice.actions.setNewBbox(getBboxFromFeature(evt.feature)))
      // @ts-ignore
      transform.on('scaleend', (e: any) =>
        viewerDispatch(viewerAttachmentsSlice.actions.setNewBbox(getBboxFromFeature(e.feature))),
      )
      // @ts-ignore
      transform.on('translateend', (e: any) =>
        viewerDispatch(viewerAttachmentsSlice.actions.setNewBbox(getBboxFromFeature(e.feature))),
      )
    })
    return () => {
      viewerDispatch(viewerAttachmentsSlice.actions.setNewBbox(null))
      map.removeInteraction(draw)
      map.removeInteraction(transform as any)
      map.removeLayer(layer)
    }
  }, [])
  return null
}

export default AttachmentDrawInteraction
