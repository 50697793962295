import { FormButton } from 'features/form'
import { BrowserDownloadButton, downloadBrowserInfo } from 'features/placeholder'
import { SupportChat } from 'features/support-chat'
import { ChatContainer } from 'features/support-chat/ui/ChatContainer'
import { isGreyListBrowsers } from 'pages/placeholder/lib/helper'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UNSUPPORTED_BROWSER_ACCEPTED } from 'shared/lib/common/storageKeys'
import { IconElement } from 'shared/ui/kit'
import Logo from 'shared/ui/logo'
import styled from 'styled-components/macro'

const PlaceholderContainer = styled.div`
  background-color: var(--color-bg-2);
  padding: 20px 16px;
  height: 100%;
  position: relative;
`

const PlaceholderWrapperBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-top: 15vh;
`

const StyledTextElement = styled.div`
  color: var(--color-text-1);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 30.8rem;
  margin-top: 16px;
`

const StyledTitleElement = styled.div`
  color: var(--color-text-1);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 24px;
`

const BrowserDownloadWrapper = styled.div`
  margin-top: 74px;
  display: grid;
  grid-template: 72px / repeat(2, 248px);
  column-gap: 21px;
`

type Props = {
  setIsAccessBrowser: (access: boolean) => void
}

export const BrowserPlaceholder: FC<Props> = ({ setIsAccessBrowser }) => {
  const { i18n, t } = useTranslation()

  const onConfirmUnsupportedBrowser = () => {
    localStorage.setItem(UNSUPPORTED_BROWSER_ACCEPTED, 'true')
    setIsAccessBrowser(true)
  }

  return (
    <PlaceholderContainer>
      <Logo />
      <PlaceholderWrapperBody>
        <IconElement name="sadEmoji" size="xxl" />
        <StyledTitleElement>
          {t(
            isGreyListBrowsers ? 'Сайт может работать некорректно в вашем браузере' : 'Мы не поддерживаем ваш браузер',
          )}
        </StyledTitleElement>
        <StyledTextElement>
          {t(
            isGreyListBrowsers
              ? 'Для корректной работы, пожалуйста, установите любой из следующих браузеров:'
              : 'Чтобы пользоваться веб-приложением OneCell, пожалуйста, установите любой из следующих браузеров:',
          )}
        </StyledTextElement>
        <BrowserDownloadWrapper>
          {downloadBrowserInfo.map(({ icon, link, name }, index) => (
            <BrowserDownloadButton
              key={index}
              name={name}
              icon={icon}
              link={i18n.language === 'ru' ? link.ru : link.en}
            />
          ))}
        </BrowserDownloadWrapper>
        {isGreyListBrowsers && (
          <FormButton style={{ marginTop: '50px' }} onClick={onConfirmUnsupportedBrowser}>
            {t('Все равно продолжить')}
          </FormButton>
        )}
      </PlaceholderWrapperBody>
      <ChatContainer>
        <SupportChat />
      </ChatContainer>
    </PlaceholderContainer>
  )
}
