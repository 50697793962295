enum IUserRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_ATLAS_VALIDATION = 'ROLE_ATLAS_VALIDATION',
  ROLE_SLIDE_DOWNLOAD_ATLAS = 'ROLE_SLIDE_DOWNLOAD_ATLAS',
  ROLE_TEST_USER = 'ROLE_TEST_USER',
  ROLE_UNSORTED_IMAGE_MANAGE = 'ROLE_UNSORTED_IMAGE_MANAGE',
  ROLE_GUEST = 'ROLE_GUEST',
  ROLE_MARKUP_USER = 'ROLE_MARKUP_USER',
  ROLE_IMAGE_MANAGER = 'ROLE_IMAGE_MANAGER',
  ROLE_ARCHIVE_MANAGER = 'ROLE_ARCHIVE_MANAGER',
  /** Ответственный за распределение */
  ROLE_CASE_ASSIGNMENT = 'ROLE_CASE_ASSIGNMENT',
}

/**  Наличие ролей у юзера */
export type IRoleStatus = {
  isRoleCaseAssignment?: boolean
  isRoleArchiveManager?: boolean
  isRoleAtlasValidation?: boolean
  isRoleGuest?: boolean
  isRoleImageManager?: boolean
  isRoleMarkupUser?: boolean
  isRoleSlideDownloadAtlas?: boolean
  isRoleTestUser?: boolean
  isRoleUnsortedImageManage?: boolean
  isRoleUser?: boolean
}

export default IUserRole
