import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { useInfiniteScroll } from './useInfiniteScroll'

const Anchor = styled.div`
  width: 1px;
  height: 1px;
`

const Reverse = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0 16px;
`

const Inner = styled.div`
  height: auto;
`

type Props = {
  fetchMore: () => Promise<any>
  itemsLength: number
  isLastPage: boolean
}

export const ReverseInfiniteScroll: FC<Props> = ({ children, fetchMore, isLastPage, itemsLength }) => {
  const anchor = useRef<HTMLDivElement>(null)
  const [direction, setDirection] = useState<'column-reverse' | 'column'>('column-reverse')
  const scrollComponent = useRef<HTMLDivElement>(null)
  const isAnchorHidden = useInfiniteScroll({ anchor, fetchMore, isLastPage, itemsLength })
  const checkScroll = () => {
    if (scrollComponent.current && children && itemsLength) {
      return scrollComponent.current?.scrollHeight <= scrollComponent.current?.clientHeight
    }
  }
  useEffect(() => {
    setDirection(!checkScroll() ? 'column-reverse' : 'column')
  }, [children, scrollComponent.current])

  return (
    <Reverse ref={scrollComponent} style={{ flexDirection: direction }}>
      <Inner>
        {!isAnchorHidden && <Anchor ref={anchor} />}
        {children}
      </Inner>
    </Reverse>
  )
}
