import { useTypedSelector } from 'app/redux/lib/selector'
import { useAnnotationsQuery } from 'features/annotations/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import styled from 'styled-components'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { IMarkupClass } from 'types/IMarkupSlide'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch } from 'viewer/container'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

import { AnnotationClassSelect } from './AnnotationClassSelect'

type Props = {
  viewerId: TViewerId
  taskId?: number
  hotkeyIsActive: boolean
  setHotkeyIsActive: (value: boolean) => void
  caseId: number
  slideId: number
}

const ClassSelector = styled.div`
  padding: 0 6%;
  margin-top: 8px;

  div {
    width: 100%;
  }
`

const TasksAnnotationClassSelect: React.FC<Props> = ({
  caseId,
  hotkeyIsActive,
  setHotkeyIsActive,
  slideId,
  taskId,
  viewerId,
}) => {
  const queryClient = useQueryClient()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { data } = useAnnotationsQuery(caseId, slideId)
  const lastAnnotationId = data?.ids.at(-1)
  const lastAnnotation =
    lastAnnotationId && queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, lastAnnotationId])

  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  const currentClass = useTypedSelector((state) => state.annotations.currentClass)

  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])

  const filteredCurrTaskClasses = currTaskClasses?.filter(
    (item) =>
      item?.annotationType === (annotationType === AnnotationType.PEN ? AnnotationType.POLYGON : annotationType),
  )

  const setCurrClass = (val?: string) => {
    viewerDispatch(annotationsSlice.actions.setCurrentClass(val || null))
  }

  useEffect(() => {
    if (!currTaskClasses) return
    if (hotkeyIsActive) return setHotkeyIsActive(false)
    const localCurrentAnnotation = annotationType === AnnotationType.PEN ? AnnotationType.POLYGON : annotationType
    if (!lastAnnotation) {
      setCurrClass(currTaskClasses?.find((item) => item.annotationType === localCurrentAnnotation)?.name)
    } else {
      const prevAnnotationFeature = getSingleFeatureFromGeoJson(lastAnnotation.data?.formattedFeature)
      const prevClass: AnnotationType | undefined = prevAnnotationFeature
        ? prevAnnotationFeature.get('class')
        : undefined
      const prevCustomClass = currTaskClasses.find(
        (item) => item.name === prevClass && item.annotationType === localCurrentAnnotation,
      )
      setCurrClass(
        prevCustomClass
          ? prevCustomClass.name
          : currTaskClasses.find((item) => item.annotationType === localCurrentAnnotation)?.name,
      )
    }
  }, [annotationType])
  return currentClass ? (
    <ClassSelector>
      <AnnotationClassSelect onSelect={setCurrClass} value={currentClass} classes={filteredCurrTaskClasses} enable />
    </ClassSelector>
  ) : null
}

export default TasksAnnotationClassSelect
