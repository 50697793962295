import { Divider } from 'antd'
import { ContextMenuItem, ContextMenuWrapper } from 'features/thumbnails/ui/ContextThumbnail/ContextThumbnail.styles'
import React, { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'

type Props = {
  /** Массив из двух чисел, определяющий позицию контекстного меню. */
  menuPosition: number[]
  /** Функция, вызываемая при клике на кнопку поворота этикетки. */
  handleRotateLabel: () => void
  /** ссылка на меню */
  menuRef?: MutableRefObject<HTMLDivElement> | MutableRefObject<null>
  /** Функция для удаления файла. */
  onDelete?: () => void
  /** Функция, вызываемая при клике на кнопку поворота всех этикеток. */
  handleRotateAllLabels?: () => void
  /** флаг, определяющий наличие url этикетки */
  isLabelUrl?: boolean
}

const ContextMenuUploadedFile = ({
  handleRotateAllLabels,
  handleRotateLabel,
  isLabelUrl = true,
  menuPosition,
  menuRef,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  return (
    <ContextMenuWrapper ref={menuRef} thumbnailMenuPosition={menuPosition} position={'fixed'}>
      {isLabelUrl && (
        <ContextMenuItem onClick={handleRotateLabel} isDisabled={false} name={'rotate90Small'}>
          <IconElement size={'md'} name={'rotate90Small'} />
          {`${t('Повернуть этикетку на')} 90°`}
        </ContextMenuItem>
      )}
      {!!handleRotateAllLabels && isLabelUrl && (
        <ContextMenuItem onClick={handleRotateAllLabels} isDisabled={false} name={'rotate90AllSmall'}>
          <IconElement size={'md'} name={'rotate90AllSmall'} />
          {`${t('Повернуть все этикетки на')} 90°`}
        </ContextMenuItem>
      )}
      {isLabelUrl && !!onDelete && <Divider style={{ margin: 0 }} />}
      {!!onDelete && (
        <ContextMenuItem onClick={onDelete} isDisabled={false} name={'delete'}>
          <IconElement size={'md'} name={'delete'} />
          {t('Удалить')}
        </ContextMenuItem>
      )}
    </ContextMenuWrapper>
  )
}

export default ContextMenuUploadedFile
