import { useTypedSelector } from 'app/redux/lib/selector'
import stubLabel from 'assets/thumbnails/stub-label.png'
import { push } from 'connected-react-router'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { viewerPageSlice } from 'pages/viewer'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LAST_MANAGEMENT_LOCATION } from 'shared/lib/common/storageKeys'
import { getManagementUrl } from 'shared/lib/externalId'
import { addToLocalStorage } from 'shared/lib/local-storage'
import { ImageElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISlide, { SlideState } from 'types/ISlide'

const StyledContainer = styled.div`
  flex: 0 1 264px; /* ширина превью */
  border: 2px solid var(--color-border-1);
  border-radius: 3px;
  display: inline-grid;
  margin-right: 4px;
  overflow: hidden;
  max-width: min-content;

  &:hover {
    background: var(--color-purple);
  }
`

type ImageType = {
  /** Идентификатор случая. */
  caseId: number
  /** Данные о слайде */
  slide: ISlide
}
const MENU_HEIGHT = 155
/** Размер превью макро слайда */
const MACRO_SLIDE_IMAGE_SIZE = 160 // height, width
/** Размер превью микро слайда */
export const MICRO_SLIDE_IMAGE_SIZE = 80 // height, width

const PreviewImageContainer = ({ caseId, slide }: ImageType) => {
  const dispatch = useDispatch()
  const slideId = slide.slideId
  const workspaceId = useCurrentWorkspaceId()
  const label = useTypedSelector((state) => state.viewerPage.label)
  const labelRotate = label[slideId]?.rotate
  const labelUrl = label[slideId]?.labelUrl || slide?.labelUrl || stubLabel
  const [currentPathname, setCurrentPathname] = useState('')
  const isMacroSlide = slide?.groupType === 'MACRO'
  const imageSize = isMacroSlide ? MACRO_SLIDE_IMAGE_SIZE : MICRO_SLIDE_IMAGE_SIZE
  const { pathname, search } = useLocation()

  useEffect(() => {
    const pathname = getManagementUrl(window.location.href)
    pathname && setCurrentPathname(pathname)
  }, [caseId])
  const onClick = () => {
    addToLocalStorage(LAST_MANAGEMENT_LOCATION, currentPathname)
    dispatch(push(`/workspace/${workspaceId}/viewer/${caseId}?slideId=${slideId}`, { from: `${pathname}${search}` }))
  }

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (slide?.state === SlideState.FAILED) return
    const windowHeight = window.innerHeight
    const y = e.clientY > windowHeight - MENU_HEIGHT ? e.clientY - MENU_HEIGHT : e.clientY
    dispatch(viewerPageSlice.actions.setThumbnailMenuPosition([e.clientX, y]))
    dispatch(viewerPageSlice.actions.setSlideThumbnailHover(slide))
  }

  return (
    <StyledContainer key={slide?.slideId} onContextMenu={handleContextMenu} onClick={onClick}>
      <ImageElement
        className={'panel-preview'}
        style={{
          background: 'white',
          borderRadius: 0,
          height: imageSize,
          width: imageSize,
        }}
        src={slide?.thumbnails.medium}
      />
      {!isMacroSlide && (
        <ImageElement
          style={{
            background: 'white',
            borderRadius: 0,
            height: '80px',
            transform: labelUrl && labelUrl !== stubLabel ? `rotateZ(${labelRotate || 0}deg)` : undefined,
            width: '80px',
          }}
          src={labelUrl}
        />
      )}
    </StyledContainer>
  )
}

export default PreviewImageContainer
