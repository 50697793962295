import * as Sentry from '@sentry/react'

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
  actionTransformer: (action: any) => {
    if (action.type === 'persist/REHYDRATE') return null
    return action
  },

  stateTransformer: (state: any) => ({
    ...state,
    dictionaries: null,
  }),
})
