import { IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupParticipant } from 'types/IMarkupTask'

export const findCanSeeOtherResults = (markupParticipant?: IMarkupParticipant[], currUserId?: number) => {
  const result = markupParticipant?.find((item) => item.userId === currUserId)
  return result?.canSeeOtherResults
}

export const calculateTotalAnnotationsCount = (
  results: IMarkupSlideResult[],
  currentUserId?: number,
  canSeeOtherResult?: boolean,
) =>
  results.reduce((total, item) => {
    /** Проверяем, есть ли участник разметки и количество аннотаций */
    if (item.markupParticipant && item.markupParticipant.userId && item.annotationsCount) {
      const isCurrentUser = item.markupParticipant.userId === currentUserId
      const isAccessible = canSeeOtherResult || isCurrentUser

      if (isAccessible) {
        return total + item.annotationsCount
      }
    }

    return total
  }, 0)
