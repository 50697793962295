import { getLocalStorageItem, setLocalStorageItem } from 'features/uploaded-file/lib/common'
import { useUploadedFileQueryParams } from 'features/uploaded-file/lib/hooks'
import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'

const MIN_TILE_SIZE = 160

const LOCAL_STORAGE_KEYS = {
  IS_TABLE_MODE: 'isTableMode',
  TILE_SIZE: 'tileSize',
}

type TUploadedFileContextProps = {
  /** Размер плитки для отображения файлов. */
  tileSize: number
  /** Режим отображения (таблица или плитка). */
  isTableMode: boolean
  /** Строка поиска для фильтрации файлов. */
  searchString: string
  /** Функция для обновления строки поиска */
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  /** Функция для обновления размера плитки. */
  setTileSize: React.Dispatch<React.SetStateAction<number>>
  /** Функция для обновления режима отображения. */
  setTableMode: React.Dispatch<React.SetStateAction<boolean>>
  /** Список для автозаполнения при вводе строки поиска. */
  autocompleteSearchList: string[]
  /** Функция для обновления списка автозаполнения. */
  setAutocompleteSearchList: React.Dispatch<React.SetStateAction<string[]>>
}

const initialUploadedFileContext: TUploadedFileContextProps = {
  autocompleteSearchList: [],
  isTableMode: true,
  searchString: '',
  setAutocompleteSearchList: () => {},
  setSearchString: () => {},
  setTableMode: () => {},
  setTileSize: () => {},
  tileSize: MIN_TILE_SIZE,
}

const UploadedFileContext = createContext<TUploadedFileContextProps>(initialUploadedFileContext)

export const useUploadedFileContext = () => useContext(UploadedFileContext)

const UploadedFileProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { currentTab } = useUploadedFileQueryParams()
  const [searchString, setSearchString] = useState('')
  const [autocompleteSearchList, setAutocompleteSearchList] = useState<string[]>([])
  const [tileSize, setTileSize] = useState<number>(() =>
    getLocalStorageItem(currentTab, LOCAL_STORAGE_KEYS.TILE_SIZE, MIN_TILE_SIZE),
  )
  const [isTableMode, setTableMode] = useState<boolean>(() =>
    getLocalStorageItem(currentTab, LOCAL_STORAGE_KEYS.IS_TABLE_MODE, true),
  )

  useEffect(() => {
    setTileSize(getLocalStorageItem(currentTab, LOCAL_STORAGE_KEYS.TILE_SIZE, MIN_TILE_SIZE))
    setTableMode(getLocalStorageItem(currentTab, LOCAL_STORAGE_KEYS.IS_TABLE_MODE, true))
  }, [currentTab])

  useEffect(() => {
    setLocalStorageItem(currentTab, LOCAL_STORAGE_KEYS.TILE_SIZE, tileSize)
  }, [tileSize])

  useEffect(() => {
    setLocalStorageItem(currentTab, LOCAL_STORAGE_KEYS.IS_TABLE_MODE, isTableMode)
  }, [isTableMode])

  return (
    <UploadedFileContext.Provider
      value={{
        autocompleteSearchList,
        isTableMode,
        searchString,
        setAutocompleteSearchList,
        setSearchString,
        setTableMode,
        setTileSize,
        tileSize,
      }}
    >
      {children}
    </UploadedFileContext.Provider>
  )
}

export default UploadedFileProvider
