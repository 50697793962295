import stubLabel from 'assets/thumbnails/stub-label.png'
import { useLabelImageQuery } from 'entities/slide'
import { useState } from 'react'
import { Center, IconElement, ImageElement, SpinElement } from 'shared/ui/kit'
import { PopoverComponent } from 'shared/ui/popover'
import styled from 'styled-components/macro'
import ISource from 'types/ISource'

const Image = styled(ImageElement)`
  width: 256px;
  height: 256px;
`
const PreviewContent = styled.div`
  cursor: pointer;
`

type Props = {
  slideId: number
  caseId: number
  source: ISource
}

const LabelImagePreviewPopup = ({ caseId, slideId, source }: Props) => {
  const [isVisible, setVisible] = useState(false)
  const { data, isLoading } = useLabelImageQuery(
    { caseId, slideId, source },
    {
      enabled: isVisible,
    },
  )
  const popoverContent = data ? (
    <Image src={data} />
  ) : isLoading ? (
    <Image as={Center}>
      <SpinElement />
    </Image>
  ) : (
    <Image src={stubLabel} />
  )
  return (
    <PopoverComponent trigger="hover" placement="leftBottom" content={popoverContent} onVisibleChange={setVisible}>
      <PreviewContent>
        <IconElement name={'dot'} />
      </PreviewContent>
    </PopoverComponent>
  )
}
export default LabelImagePreviewPopup
