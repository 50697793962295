import { Table } from 'antd'
import { TMode } from 'app/styled/ThemeProvider'
import styled from 'styled-components/macro'

export const StyledTable = styled(Table)<{ theme: TMode }>`
  .ant-table {
    background: var(--color-bg-2);
  }

  .ant-checkbox-inner {
    background-color: var(--color-bg-4);
    width: 12px;
    height: 12px;
    border-radius: 3px;
    box-shadow: none;
  }

  .ant-checkbox-disabled {
    opacity: 0.3;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-purple);
    border: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(0.75) translate(-90%, -60%);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: var(--color-purple);
    border: none;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    height: 2px;
    background-color: #ffffff;
  }
  .ant-table-thead th {
    background-color: ${({ theme }) => (theme === 'light' ? 'rgb(237 237 237)' : 'rgb(48 48 50)')} !important;
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
  }

  .ant-checkbox + span {
    padding-left: 4px;
    line-height: 16px;
  }

  .ant-checkbox {
    display: flex;
    top: 2px;
  }

  .ant-table-thead > tr > th {
    color: var(--color-text-3);
    line-height: 16px;
    font-weight: 600;
    padding: 16px 16px 12px;
    background: var(--color-bg-3);
    border-bottom: 1px solid var(--color-bg-2);
  }

  .ant-table-tbody > tr > td {
    color: var(--color-text-1);
    background: var(--color-bg-2);
    border-bottom: 1px solid var(--color-bg-3);
    line-height: 16px;
    height: 24px;
    padding: 8px 16px;
    word-wrap: break-word;
    word-break: break-word;
  }

  .ant-table-row-selected .ant-table-cell {
    background: var(--color-bg-3);
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: var(--color-bg-3);
  }

  table tr td.ant-table-selection-column,
  table tr th.ant-table-selection-column {
    padding: 8px 0 8px 16px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--color-bg-4);
  }

  a.ant-typography {
    color: var(--color-blue);
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: var(--color-bg-3);
  }
  .ant-table-column-sorters {
    cursor: pointer;
  }

  @media (max-width: 1110px) {
    .ant-table-tbody > tr > td {
      padding: 1%;
    }

    .ant-table-thead > tr > th {
      padding: 1%;
    }
  }
`
