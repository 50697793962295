import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  [key: string]: string | null
}

const initialState: State = {
  10000: 'macroSlidesIds',
  9999: 'emptyBlocks',
}
type BlockKeys = {
  key: number
  block: string | null
}
export const slideBlocksSlice = createSlice({
  initialState,
  name: 'slideBlocksSlice',
  reducers: {
    setInitialState() {
      return initialState
    },

    setSlideBlockState(state, { payload }: PayloadAction<BlockKeys>) {
      state[payload.key] = payload.block
    },
  },
})
