import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'shared/lib/hooks'
import NavigationBody from 'shared/ui/navigation/NavigationBody'

import DashboardNavigationType from './DashboardNavigationType'

const DbNavSecondaryTypesContainer = () => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const navItems = [
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Micro,
      path: `/dashboard?filter=${DashboardNavigationType.Micro}`,
      title: t('Микроскопические исследования'),
    },
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Macro,
      path: `/dashboard?filter=${DashboardNavigationType.Macro}`,
      title: t('Макроскопические исследования'),
    },
    {
      isActive: () => queryParams.get('filter') === DashboardNavigationType.Gen,
      path: `/dashboard?filter=${DashboardNavigationType.Gen}`,
      title: t('Генетические  исследования'),
    },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Radio}`,
    //   title: 'Ренгенография',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Radio,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Mammology}`,
    //   title: 'Маммография',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Mammology,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.CT}`,
    //   title: 'КТ',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.CT,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Pathology}`,
    //   title: 'ПЕТ',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Pathology,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Surg}`,
    //   title: 'Хирургические вмешательства',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Surg,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Gen}`,
    //   title: 'Генетические исследования',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Gen,
    // },
    // {
    //   path: `/dashboard?filter=${DashboardNavigationType.Macro}`,
    //   title: 'Макроскопия',
    //   isActive: () => queryParams.get('filter') === DashboardNavigationType.Macro,
    // },
  ]
  return <NavigationBody navItems={navItems} title={t('Патология')} />
}

export default DbNavSecondaryTypesContainer
