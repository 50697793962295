import { IOptions } from 'types/IOptions'

type SearchCriteria = 'userId' | 'fullname'

type WorkspaceByUserType = {
  /** воркспейсы доступные для шеринга */
  options: IOptions
  /** Значение, по которому происходит поиск. */
  searchKey: string | number
  /** Критерий, по которому выполняется поиск (userId или fullname). */
  criteria: SearchCriteria
}

/**
 * Осуществляет поиск пользователя в объекте options по его userId или fullname и возвращает название воркспейса, в котором найден пользователь.
 * @param arg - Объект с параметрами для поиска.
 * @returns Название воркспейса, если пользователь найден, в противном случае возвращает null.
 */
export const findWorkspaceByUser = (arg: WorkspaceByUserType) => {
  const { criteria, options, searchKey } = arg
  const { usersGroupByWorkspaceName } = options

  for (const workspaceName in usersGroupByWorkspaceName) {
    const usersInWorkspace = usersGroupByWorkspaceName[workspaceName]
    for (const user of usersInWorkspace) {
      if (user[criteria] && user[criteria].toString() === searchKey.toString()) {
        return workspaceName
      }
    }
  }
  return null
}

export const findUserByCriteria = (arg: WorkspaceByUserType) => {
  const { criteria, options, searchKey } = arg
  const { usersGroupByWorkspaceName } = options

  for (const workspaceName in usersGroupByWorkspaceName) {
    const usersInWorkspace = usersGroupByWorkspaceName[workspaceName]
    for (const user of usersInWorkspace) {
      if (user[criteria] && user[criteria].toString() === searchKey.toString()) {
        return user
      }
    }
  }
  return null
}
