import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { useContext, useEffect } from 'react'
import { IMapOl } from 'types/IMapOl'
import { negativeStyle, otherStyle, positiveStyle } from 'viewer/map/layers/olStyles'

import { Ki67Context } from './Ki67Context'

type Props = {
  map: IMapOl
}

const PointsLayer = ({ map }: Props) => {
  const { data } = useContext(Ki67Context)

  const features = Object.values(data)
    .filter((item) => !item.isHidden)
    .map((item) => item.points)
  useEffect(() => {
    const layers = features.map(
      (features) =>
        new VectorLayer({
          source: new VectorSource({ features }),
          style: (feature) => {
            if (feature.get('positive') === 1) {
              return positiveStyle
            }
            if (feature.get('negative') === 1) {
              return negativeStyle
            }

            if (feature.get('other') === 1) {
              return otherStyle
            }
            return otherStyle
          },
          zIndex: 100000,
        }),
    )
    layers.map((l) => map.addLayer(l))
    return () => {
      layers.map((l) => map.removeLayer(l))
    }
  }, [features])

  return null
}

export default PointsLayer
