import { post } from 'shared/api'
import ITrackUploadFile, { ITrackUploadFileGroup } from 'types/ITrackUploadFile'

type Payload = {
  uploadId: string
  fileId: string
  data: ITrackUploadFile
}

type GroupPayload = {
  uploadId: string
  caseId: number
  userAgent: string
  files: Partial<ITrackUploadFile>[]
}

const updateFileAttempt = async ({ data, fileId, uploadId }: Payload) => {
  try {
    const res = await post<{ success: boolean }, ITrackUploadFile>({
      data,
      url: `/track/upload/${uploadId}/file/${fileId}`,
    })
    return res
  } catch (e) {
    throw e
  }
}
const createFileGroup = async ({ caseId, files, uploadId, userAgent }: GroupPayload) => {
  try {
    const res = await post<{ success: boolean }, ITrackUploadFileGroup>({
      data: {
        caseId,
        files,
        userAgent,
      },
      url: `/track/upload/${uploadId}/group`,
    })
    return res
  } catch (e) {
    throw e
  }
}

const trackUploadService = {
  createFileGroup,
  updateFileAttempt,
}

export default trackUploadService
