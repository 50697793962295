import { SpecialMorphologyCode } from 'entities/tumor-type/types'
import { flatten, isNumber, uniq } from 'lodash'
import i18next from 'shared/lib/i18n/i18n'
import ICase from 'types/ICase'
import ISlide from 'types/ISlide'
import { ICaseSpecificMetadata } from 'types/ISlideMetadata'
import { adjustScaleIfRequired } from 'viewer/map/layers/slide/helpers/scaledIIIOptions'

const t = i18next.t

export const DEFAULT_MPPX = 0.5
export const DEFAULT_ADAPTER_LENS_POWER = 1

export const getSlideGleasonData = (caseSpecificMetadata?: ICaseSpecificMetadata) => {
  if (!caseSpecificMetadata) {
    return {
      gGroup: null,
      gPrimary: null,
      gSecondary: null,
    }
  }
  const gPrimary = isNumber(caseSpecificMetadata.userGleasonPatternPrimary)
    ? caseSpecificMetadata.userGleasonPatternPrimary
    : caseSpecificMetadata.gleasonPatternPrimary
  const gSecondary = isNumber(caseSpecificMetadata.userGleasonPatternSecondary)
    ? caseSpecificMetadata.userGleasonPatternSecondary
    : caseSpecificMetadata.gleasonPatternSecondary
  const gGroup =
    isNumber(caseSpecificMetadata.userGleasonGroup) &&
    caseSpecificMetadata.userGleasonPatternSecondary &&
    caseSpecificMetadata.userGleasonPatternPrimary
      ? caseSpecificMetadata.userGleasonGroup
      : caseSpecificMetadata.gleasonGroup

  return { gGroup, gPrimary, gSecondary }
}

export const getCaseGleasonGroup = (siteMetaData: ICase['siteSpecificMetadata']) =>
  isNumber(siteMetaData?.gleasonGroupManual) ? siteMetaData?.gleasonGroupManual : siteMetaData?.gleasonGroupCalculated

export const getAllMorphologies = (slides: ISlide[]) => uniq(flatten(slides.map(getMorphologiesFromSlide)))

export const getMorphologiesFromSlide = (slide: ISlide) => slide.slideMetadata?.morphologies || []

export const findSpecialMorphology = (slide: ISlide) => {
  const morphs = getMorphologiesFromSlide(slide)
  return morphs.find((m) => m.shortName === '8140/3')?.shortName as SpecialMorphologyCode | undefined
}

export const getSlideName = (slide?: ISlide) =>
  slide?.slideMetadata?.commonMetadata?.caption || `${t('Номер слайда')}: ${slide?.slideId}`

export const getSlideBarcode = (slide?: ISlide) => slide?.barcode || slide?.slideMetadata?.commonMetadata?.caption
export const getSlideGroupType = (slide?: ISlide) => slide?.groupType

export const getResolutionByZoomLevel = (resolution: number, slide?: ISlide) => {
  const objectivePower = getObjectivePower(slide)
  const adapterLensPower = getAdapterLensPower(slide)

  const result = (objectivePower * adapterLensPower) / resolution

  return adjustScaleIfRequired(result)
}

const zoomLevelColors: any = {
  10: '#F8CD76',
  2: '#706f76',
  20: '#7BB86F',
  40: '#4099F7',
  5: '#DC524C',
  80: 'var(--color-text-1)',
}

const serverValues20 = [20, 10, 5, 2]
const clientValues20 = [80, 40]

const serverValues40 = [40, 20, 10, 5, 2]
const clientValues40 = [80]

const getOptionByValue = (av: number, slide?: ISlide) => {
  const value = getResolutionByZoomLevel(av, slide)

  return {
    color: zoomLevelColors[av],
    key: String(value),
    label: `${av}×`,
    value: value,
  }
}

export const getSlideZoomLevelsOptionGroups = (slide?: ISlide) => {
  const objectivePower = slide?.slideMetadata?.commonMetadata?.objectivePower
  const allowedServerValues = objectivePower === 40 ? serverValues40 : serverValues20
  const allowedClientValues = objectivePower === 40 ? clientValues40 : clientValues20

  const serverOptions = allowedServerValues.map((av) => getOptionByValue(av, slide))

  const clientOptions = allowedClientValues.map((av) => getOptionByValue(av, slide))

  return [
    {
      key: 'client',
      label: t('цифровой'),
      options: clientOptions,
    },
    {
      key: 'server',
      label: t('исходный'),
      options: serverOptions,
    },
  ]
}

export const createCapitalString = (str: string) =>
  str ? str[0].toLocaleUpperCase() + str.slice(1).toLocaleLowerCase() : ''

export const getSlideMppx = (slide?: ISlide) => slide?.slideMetadata?.commonMetadata?.mppX || DEFAULT_MPPX

export const getAdapterLensPower = (slide?: ISlide) =>
  slide?.slideMetadata?.commonMetadata?.adapterLensPower || DEFAULT_ADAPTER_LENS_POWER

export const getObjectivePower = (slide?: ISlide) => {
  if (slide?.slideMetadata?.commonMetadata?.objectivePower) {
    return slide?.slideMetadata?.commonMetadata?.objectivePower
  }
  const DEFAULT_OBJECTIVEPOWER = 10 / getSlideMppx(slide) / getAdapterLensPower(slide)
  return DEFAULT_OBJECTIVEPOWER
}

/**
 * Преобразует значение в пикселях в значение координат на карте ol
 * @param {number} pixels - число пикселей для перевода
 * @param {number} resolution - текущее разрешение на карте
 * @param {ISlide | undefined} slide - текущий открытый слайд
 */
export const pixelsToMapProjection = (pixels: number, resolution: number, slide?: ISlide) => {
  const objectivePower = getObjectivePower(slide)
  const adapterLensPower = getAdapterLensPower(slide)
  const zoomLvl = (objectivePower * adapterLensPower) / resolution
  return pixels * ((objectivePower / zoomLvl) * adapterLensPower)
}
