import { selectUrlSlideId } from 'pages/viewer'
import LeftPanelWrapper from 'pages/viewer/ui/common/LeftPanelWrapper'
import React, { ForwardedRef } from 'react'
import { useSelector } from 'react-redux'

import TasksSlidesListContainer from './TasksSlidesListContainer'

type Props = {
  leftRef: ForwardedRef<HTMLDivElement>
}
const TasksLeftPanelContainer = ({ leftRef }: Props) => {
  const urlTaskSlideId = useSelector(selectUrlSlideId)
  return (
    <LeftPanelWrapper style={{ overflow: 'unset', padding: '0px 0px 0px 8px' }} leftRef={leftRef}>
      <TasksSlidesListContainer selectedSlideId={Number(urlTaskSlideId)} />
    </LeftPanelWrapper>
  )
}

export default TasksLeftPanelContainer
