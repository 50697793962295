import i18n from 'i18next'
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector'
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const i18nInstance = i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .use(LanguageDetector)

i18nInstance.init<HttpBackendOptions | DetectorOptions>({
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  detection: {
    order: ['localStorage', 'navigator'],
  },

  fallbackLng: 'ru',
  // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  interpolation: {
    escapeValue: false, // react already safes from xss
  },

  // lng: 'ru', // if you're using a language detector, do not define the lng option
  load: 'languageOnly',

  react: { useSuspense: false },
})

export default i18nInstance
