import { useQuery } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import INote from 'types/INotes'

import notesService from './service'

export const useNotesQuery = (caseId: number, isTaskOrAtlas: boolean) => {
  const query = useQuery<INote[]>([QUERY_TYPE.NOTE, { caseId }], async () => {
    if (isTaskOrAtlas) return []
    const response = await notesService.fetchNotes(caseId)
    return response
  })
  return {
    ...query,
  }
}
