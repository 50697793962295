import { StompHeaders } from '@stomp/stompjs'
import { DependencyList, useContext, useEffect } from 'react'

import { StompClientContext } from './StompClientProvider'

export function useSubscription<Message>(
  destination: string,
  callback: (message: Message, unsubscribe: () => void) => void,
  deps: DependencyList = [],
  headers?: StompHeaders,
) {
  const { isConnected, subscribe, unsubscribe } = useContext(StompClientContext)
  useEffect(() => {
    if (isConnected) {
      subscribe<Message>(destination, callback, headers)
    }
    return () => {
      unsubscribe(destination)
    }
  }, [isConnected, destination, ...deps])
}
